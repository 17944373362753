import { FacilitySystem } from 'src/app/facilities/models/facility-system.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { getEmptyPagingToken, PagingToken } from 'src/app/shared/models/paging-token';

export interface FacilitiesSystemsQueryState extends GridSearchQuery {}

const getInitialGridState = (): FacilitiesSystemsQueryState => ({
    sort: [{ field: 'hospitalSystemName', dir: SortTypes.ASC }],
    take: 25,
    skip: 0
});

export interface FacilitiesSystemListState {
    loadingState: LoadingTypes;
    systemsList: PagingToken<FacilitySystem[]>;
    gridSearchQuery: FacilitiesSystemsQueryState;
    error: string;
}

export const getInitialFacilitiesSystemsListState = (): FacilitiesSystemListState => ({
    loadingState: LoadingTypes.INIT,
    systemsList: getEmptyPagingToken<FacilitySystem>(),
    gridSearchQuery: getInitialGridState(),
    error: ''
});
