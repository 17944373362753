import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class UnsubscribeOnDestroy implements OnDestroy {
    protected d$: Subject<void>;

    constructor() {
        this.d$ = new Subject<void>();
    }

    ngOnDestroy() {
        this.d$.next();
        this.d$.complete();
    }
}
