import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { UnitLookup } from 'src/app/shared/models/lookups/unit-lookup.model';
import { SystemLookup } from 'src/app/shared/models/lookups/system-lookup.model';
import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import {
    loadSecurityLookups,
    loadSecurityLookupsSuccess,
    loadSecurityLookupsFail
} from 'src/app/dashboard-new/store/actions';
import { ListItem } from 'src/app/shared/models/list-item';

export interface SecurityLookupsState {
    facilities: Array<FacilityLookup>;
    systems: Array<SystemLookup>;
    units: Array<UnitLookup>;
    ranges: Array<ListItem>;
    loading: LoadingTypes;
    error: string;
}

export const initialSecurityLookupsState: SecurityLookupsState = {
    facilities: [],
    systems: [],
    units: [],
    ranges: [
        { id: null, name: 'All Future Shifts' },
        { id: 7, name: 'In the next 7 days' },
        { id: 14, name: 'In the next 14 days' },
        { id: 30, name: 'In the next 30 days' }
    ],
    loading: LoadingTypes.INIT,
    error: ''
};

export function securityLookupsReducer(state: SecurityLookupsState, action: Action) {
    return createReducer(
        initialSecurityLookupsState,
        on(loadSecurityLookups, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadSecurityLookupsSuccess, (state, action) => ({
            ...state,
            facilities: action.facilities,
            systems: action.systems,
            units: action.units,
            loading: LoadingTypes.LOADED
        })),

        on(loadSecurityLookupsFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: SecurityLookupsState | undefined, action: Action) {
    return securityLookupsReducer(state, action);
}
