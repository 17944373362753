import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubmittalsComponent } from './components/submittals/submittals.component';
import { SubmittalDetailsComponent } from './components/submittal-details/submittal-details.component';
import { SubmittalDetailsInternalComponent } from './components/submittal-details-internal/submittal-details-internal.component';
import { SubmittalDocumentViewerComponent } from './components/submittal-document-viewer/submittal-document-viewer.component';
import { SubmittalOfferDetailsComponent } from 'src/app/submittals/components/submittal-details/submittal-offer-details/submittal-offer-details.component';
import { SubmittalDetailsRoutesHelperService } from 'src/app/submittals/components/submittal-details/submittal-details-routes-helper-service';

const routes: Routes = [
    {
        path: 'client/submittals',
        component: SubmittalsComponent
    },
    {
        path: 'client/submittals/:id',
        component: SubmittalDetailsComponent,
        children: [
            {
                path: SubmittalDetailsRoutesHelperService.offerDetails,
                component: SubmittalOfferDetailsComponent
            }
        ]
    },
    {
        path: 'client/submittals/:id/openoffermodal',
        component: SubmittalDetailsComponent
    },
    {
        path: 'client/submittals/:id/internal',
        component: SubmittalDetailsInternalComponent
    },
    {
        path: 'client/submittals/:id/attachments',
        component: SubmittalDocumentViewerComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SubmittalsRoutingModule {}
