import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { PermJobsGridQueryState } from 'src/app/root-store/perm-jobs/reducers/perm-jobs-grid-query.reducer';
import { PermJobsUrls } from '../models/perm-jobs-url.enum';
import { PermJobsGridResponse } from '../models/perm-jobs-grid.response';
import { interpolateFetchArguments2Query } from 'src/app/core/utils/interpolate-fetch-arguments2';
import { GridLookup } from 'src/app/shared/grid/models';
import { interpolateSearch } from 'src/app/core/utils';

@Injectable({
    providedIn: 'root'
})
export class PermanentJobService {
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {}

    getFilteredPermJobs(query: PermJobsGridQueryState): Observable<PermJobsGridResponse> {
        let queryString = '';
        queryString = interpolateFetchArguments2Query(
            query,
            [{ sortField: 'searchFacilityIds', sortOverride: 'facilityName' }],
            ['searchFacilityIds', 'specialty']
        );

        const url = `${this._settings.CORE}${PermJobsUrls.PermJobsFiltered}`;

        return this.http.get<PermJobsGridResponse>(`${url}?${queryString}`);
    }

    getSpecialtyLookups(): Observable<GridLookup[]> {
        const url = `${this._settings.CORE}${PermJobsUrls.PermJobsSpecialtyLookups}`;
        return this.http.get<GridLookup[]>(url);
    }

    exportFilteredPermJobs(query: PermJobsGridQueryState): Observable<HttpResponse<Blob>> {
        const params = interpolateSearch(query);
        const url = `${this._settings.CORE}${PermJobsUrls.ExportPermJobs}`;

        return this.http.get(`${url}`, {
            observe: 'response',
            responseType: 'blob',
            params: params
        });
    }
}
