<dialog-layout [title]="data?.title" data-qa-id="ModalDialogContent">
    <div mat-dialog-content class="form-group">
        <ng-container *ngIf="data?.isHtml; then htmlContent; else textContent">
        </ng-container>

        <ng-container>
            <div [formGroup]="form" class="d-flex align-items-start">
                <label class="mr-4">
                    {{data.inputLabel}}
                    <span class="k-required" *ngIf="data.isInputRequired">*</span>
                </label>
                <ng-container *ngIf="!data.displayRedErrorOnInputBorder">
                    <textarea class="k-textarea flex-fill" formControlName="input" [placeholder]="data.inputPlaceholder" rows="3"
                        data-qa-id="confirmation-reason-dialog-input"
                        [maxlength]="data.limitInputToMaxLength ? data.inputMaxLength : ''">
                    </textarea>
                    <div class="input-counter" *ngIf="data.inputMaxLength">
                        {{inputLength}}/{{data.inputMaxLength}}</div>
                </ng-container>
                <ng-container *ngIf="data.displayRedErrorOnInputBorder">
                    <mat-form-field appearance="outline" class="full-width">
                        <textarea
                            class="no-resize"
                            data-qa-id="confirmation-reason-dialog-input"
                            matInput
                            formControlName="input"
                            rows="3"
                            [maxlength]="data.limitInputToMaxLength ? data.inputMaxLength : ''"
                        ></textarea>
                        <mat-error *ngIf="form.controls.input?.errors?.required && form.controls.input.touched">
                            {{data.inputRequiredMessage}}
                        </mat-error>
                    </mat-form-field>
                    <div class="input-counter negative-margin-top" *ngIf="data.inputMaxLength">
                        {{inputLength}}/{{data.inputMaxLength}}</div>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <ng-template #htmlContent>
        <div [innerHtml]="data?.text"></div>
    </ng-template>

    <ng-template #textContent>
        <div>{{data?.text}}</div>
    </ng-template>

    <div mat-dialog-actions>
        <button mat-flat-button [color]="confirmButtonColor" type="submit" (click)="onConfirm()"
            [disabled]="isSaveDisabled" data-qa-id="confirmation-reason-dialog-confirm-button">
            {{confirmButtonText}}
        </button>
        <button mat-stroked-button color="primary" (click)="onCancel()"
            data-qa-id="confirmation-reason-dialog-confirm-cancel">
            {{cancelButtonText}}
        </button>
    </div>
</dialog-layout>
