import { parseBoolean } from 'src/app/shared/utilities';
import {
    FeatureSettingItem,
    FeatureInputTypes,
    FeatureSettingItemInteger,
    FeatureSettingItemDecimal,
    FeatureSettingItemDate,
    FeatureSettingItemBoolean
} from '../models/feature-features.models';

export class FacilitySettingsHelper {
    static prepareSettings(settings: FeatureSettingItem[]): FeatureSettingItem[] {
        return settings.map((setting) => {
            setting = { ...setting };
            switch (setting.valueTypeId) {
                case FeatureInputTypes.DECIMAL:
                case FeatureInputTypes.INTEGER:
                    setting = this.prepareNumberSetting(setting);
                    break;
                case FeatureInputTypes.BOOLEAN:
                    setting = this.prepareBooleanSetting(setting);
                    break;
                case FeatureInputTypes.DATETIME:
                    setting = this.prepareDateSetting(setting);
                    break;
            }

            if (setting.childFeatures != null) {
                setting.childFeatures = this.prepareSettings(setting.childFeatures);
            }

            return setting;
        });
    }

    static prepareNumberSetting(setting: FeatureSettingItemInteger | FeatureSettingItemDecimal) {
        setting = { ...setting };
        if (setting.value != null && !isNaN(setting.value)) {
            setting.value = Number(setting.value);
        }
        if (setting.min != null && !isNaN(setting.value)) {
            setting.min = Number(setting.min);
        }
        if (setting.max != null && !isNaN(setting.value)) {
            setting.max = Number(setting.max);
        }

        return setting;
    }

    static prepareDateSetting(setting: FeatureSettingItemDate) {
        setting = { ...setting };
        if (setting.value != null) {
            setting.value = new Date(setting.value);
        }
        if (setting.min != null) {
            setting.min = new Date(setting.min);
        }
        if (setting.max != null) {
            setting.max = new Date(setting.max);
        }

        return setting;
    }

    static prepareBooleanSetting(setting: FeatureSettingItemBoolean) {
        setting = { ...setting };
        if (setting.value != null) {
            setting.value = parseBoolean(setting.value);
        }

        return setting;
    }
}
