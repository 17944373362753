import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { StaffingAdvisory } from 'src/app/travelers/models/staffing-advisory.model';
import * as StaffingAdvisoryActions from 'src/app/travelers/state/actions/staffing-advisory.actions';

export interface StaffingAdvisoryState {
    staffingAdvisories: Array<StaffingAdvisory>;
    error: string;
    loading: LoadingTypes;
}

export const initialState: StaffingAdvisoryState = {
    staffingAdvisories: null,
    error: '',
    loading: LoadingTypes.INIT
};

export function staffingAdvisoryReducer(state: StaffingAdvisoryState, action: Action) {
    return createReducer(
        initialState,
        on(StaffingAdvisoryActions.loadStaffingAdvisories, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(StaffingAdvisoryActions.loadStaffingAdvisoriesSuccess, (state, action) => ({
            ...state,
            staffingAdvisories: action.staffingAdvisories,
            loading: LoadingTypes.LOADED
        })),

        on(StaffingAdvisoryActions.loadStaffingAdvisoriesFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        })),

        on(StaffingAdvisoryActions.clearStaffingAdvisory, (state, action) => ({
            ...initialState
        }))
    )(state, action);
}

export function reducer(state: StaffingAdvisoryState | undefined, action: Action) {
    return staffingAdvisoryReducer(state, action);
}
