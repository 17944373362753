<dialog-layout [title]="dialogData.title">
    <div mat-dialog-content [formGroup]="form">
        <p *ngIf="dialogData.description">
            {{ data.description }}
        </p>

        <div [ngSwitch]="dialogData.inputType">
            <div *ngSwitchCase="'lookahead'">
                <div class="mb-3">
                    <kendo-combobox app-kendo-autocomplete-fix
                                    class="full-width-field"
                                    formControlName="input"
                                    [data]="data.options"
                                    textField="name"
                                    valueField="id"
                                    [valuePrimitive]="true"
                                    [kendoDropDownFilter]="filterSettings"
                                    placeholder="Begin typing to select...">
                    </kendo-combobox>
                </div>
            </div>
            <div *ngSwitchDefault>
                <ng-container>
                    <p *ngIf="dialogData.hint">
                        {{ dialogData.hint }}
                    </p>
                </ng-container>
                <ng-container>
                    <mat-form-field class="full-width-field" appearance="outline">
                        <mat-label>
                            {{ data.inputLabel }} {{ data.inputRequire ? '*required' : '(optional)'}}
                        </mat-label>
                        <input formControlName="input"
                               matInput
                               #input
                               maxlength="{{data.maxLength}}"
                               (input)="inputLength =  input.value.length">
                    </mat-form-field>
                    <ng-container *ngIf="data.maxLength">
                        <mat-hint>{{data.maxLength - inputLength >= 0 ? data.maxLength - inputLength : data.maxLength}} characters remaining</mat-hint>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-flat-button color="accent" (click)="submit()" cdkFocusInitial [disabled]="form.invalid">
            {{ dialogData.okText }}
        </button>
        <button mat-stroked-button color="primary" (click)="dismiss()">
            {{ dialogData.cancelText }}
        </button>
    </div>
</dialog-layout>
