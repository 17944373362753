import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToasterService } from 'src/app/core/services';
import * as TravelerDetailsActions from '../actions/travelers-details.actions';
import { TravelersService } from 'src/app/travelers/services/travelers.service';
import { Store } from '@ngrx/store';
import { selectDepartments, selectTraveler } from '..';
import * as TravelerExtensionActions from 'src/app/travelers/state/actions/travelers-extension.actions';

@Injectable()
export class TravelersDetailsEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly travelersService$: TravelersService,
        private readonly toaster: ToasterService,
        private readonly store$: Store<{}>
    ) {}

    loadTraveler$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                TravelerDetailsActions.loadTraveler,
                TravelerDetailsActions.travelerJobSaved,
                TravelerDetailsActions.travelerJobRemoved,
                TravelerDetailsActions.travelerPrismaPositionSaved,
                TravelerDetailsActions.travelerPrismaPositionRemoved,
                TravelerDetailsActions.travelerBjcWorkerIdSaved,
                TravelerDetailsActions.travelerBjcWorkerIdRemoved,
                TravelerExtensionActions.requestExtensionSuccess
            ),
            switchMap((action) =>
                this.travelersService$.getTraveler(action.id).pipe(
                    map((traveler) => TravelerDetailsActions.travelerLoadSuccess({ traveler: traveler })),
                    catchError((error) => of(TravelerDetailsActions.travelerLoadError({ error: error })))
                )
            )
        )
    );

    loadSclDepartments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.loadSclDepartments),
            withLatestFrom(this.store$.select(selectTraveler)),
            switchMap(([action, traveler]) =>
                this.travelersService$.getDepartments(action.employeeId).pipe(
                    map((departments) => {
                        const travelerDepartment = departments.find((x) => x.code == traveler.sclDepartmentCode);
                        const department = travelerDepartment === undefined ? departments[0] : travelerDepartment;
                        return TravelerDetailsActions.sclDepartmentsLoadSuccess({
                            departments: departments,
                            travelerDepartment: department
                        });
                    }),
                    catchError((error) => of(TravelerDetailsActions.sclDepartmentsLoadError({ error: error })))
                )
            )
        )
    );

    updateSclDepartment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.updateSclDepartment),
            withLatestFrom(this.store$.select(selectDepartments)),
            switchMap(([action, departments]) => {
                const department = departments.find((x) => x.code === action.departmentCode);
                return of(TravelerDetailsActions.sclDepartmentUpdated({ department: department }));
            })
        )
    );

    loadSclSupervisors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.loadSclSupervisors),
            switchMap((action) =>
                this.travelersService$.getSupervisors().pipe(
                    switchMap((supervisors) => [
                        TravelerDetailsActions.loadSclDepartments({ employeeId: action.sclSupervisorId }),
                        TravelerDetailsActions.sclSupervisorsLoadSuccess({ supervisors: supervisors })
                    ]),
                    catchError((error) => of(TravelerDetailsActions.sclSupervisorsLoadError({ error: error })))
                )
            )
        )
    );

    saveTraveler$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.saveTravelerProfile),
            mergeMap((action) =>
                this.travelersService$.saveTraveler(action.traveler).pipe(
                    map((traveler) => {
                        return TravelerDetailsActions.travelerProfileSaveSuccess();
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerProfileSaveError()))
                )
            )
        )
    );

    saveTravelerNyu$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.saveTravelerProfileNyu),
            mergeMap((action) =>
                this.travelersService$.saveTravelerNyu(action.traveler).pipe(
                    map((traveler) => {
                        return TravelerDetailsActions.travelerProfileSaveNyuSuccess();
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerProfileSaveNyuError({ error: error })))
                )
            )
        )
    );

    updateTraveler$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                TravelerDetailsActions.travelerProfileSaveSuccess,
                TravelerDetailsActions.travelerProfileSaveNyuSuccess
            ),
            withLatestFrom(this.store$.select(selectTraveler)),
            map(([action, traveler]) => TravelerDetailsActions.loadTraveler({ id: traveler.id }))
        )
    );

    loadTravelerJobs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.loadTravelerJobs),
            switchMap((action) =>
                this.travelersService$.getTravelerJobs(action.request).pipe(
                    mergeMap((response) => {
                        return [
                            TravelerDetailsActions.travelerJobsLoadSuccess({
                                jobs: response.jobs
                            }),
                            TravelerDetailsActions.updateMspFieldsVisibility({
                                showPrismaFields: response.showPrisma,
                                showBjcFields: response.showBjc
                            })
                        ];
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerJobsLoadError({ error: error })))
                )
            )
        )
    );

    filterTravelerJobs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.travelerJobFilterRequest),
            switchMap((action) =>
                this.travelersService$.filterTravelerJobs(action.state, action.jobs).pipe(
                    map((jobs) => {
                        return TravelerDetailsActions.travelerJobFilterSuccess({ filteredJobs: jobs });
                    })
                )
            )
        )
    );

    saveTravelerJob$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.saveTravelerJob),
            mergeMap((action) =>
                this.travelersService$.saveTravelerJob(action.job).pipe(
                    map((traveler) => {
                        return TravelerDetailsActions.travelerJobSaved({ id: action.id });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerJobSaveError({ error: error })))
                )
            )
        )
    );

    saveTravelerJobSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerJobSaved),
                tap((action) => this.toaster.success(`Job for traveler saved `))
            ),
        { dispatch: false }
    );

    saveTravelerProfileSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerProfileSaveSuccess),
                tap((action) => this.toaster.success(`Traveler profile saved `))
            ),
        { dispatch: false }
    );

    saveTravelerProfileNyuSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerProfileSaveNyuSuccess),
                tap((action) => this.toaster.success(`Traveler Nyu fields saved `))
            ),
        { dispatch: false }
    );

    saveTravelerJobError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerJobSaveError),
                tap((action) => this.toaster.fail(`Error saving job for traveler: ${action.error}`))
            ),
        { dispatch: false }
    );

    saveTravelerProfileError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerProfileSaveError),
                tap((action) => this.toaster.fail(`Error saving traveler profile `))
            ),
        { dispatch: false }
    );

    saveTravelerProfileNyuError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerProfileSaveNyuError),
                tap((action) => this.toaster.fail(`Error saving traveler Nyu fields `))
            ),
        { dispatch: false }
    );

    removeTravelerJob$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.removeTravelerJob),
            mergeMap((action) =>
                this.travelersService$.removeTravelerJob(action.id).pipe(
                    map((traveler) => {
                        return TravelerDetailsActions.travelerJobRemoved({ id: action.id });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerJobRemoveError({ error: error })))
                )
            )
        )
    );

    removeTravelerJobSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerJobRemoved),
                tap((action) => this.toaster.success(`Job for traveler removed successfully`))
            ),
        { dispatch: false }
    );

    removeTravelerJobError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerJobRemoveError),
                tap((action) => this.toaster.fail(`Error removing job for traveler: ${action.error}`))
            ),
        { dispatch: false }
    );

    saveTravelerPrismaPosition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.saveTravelerPrismaPosition),
            mergeMap((action) =>
                this.travelersService$.saveTravelerPrismaPositionId(action.prismaPosition).pipe(
                    map(() => {
                        return TravelerDetailsActions.travelerPrismaPositionSaved({ id: action.id });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerPrismaPositionSaveError({ error: error })))
                )
            )
        )
    );

    saveTravelerPrismaPositionSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerPrismaPositionSaved),
                tap((action) => this.toaster.success(`Prisma Position ID saved`))
            ),
        { dispatch: false }
    );

    saveTravelerPrismaPositionError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerPrismaPositionSaveError),
                tap((action) => this.toaster.fail(`Error saving Prisma Position ID: ${action.error}`))
            ),
        { dispatch: false }
    );

    removeTravelerPrismaPosition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.removeTravelerPrismaPosition),
            mergeMap((action) =>
                this.travelersService$.removeTravelerPrismaPositionId(action.travelerId).pipe(
                    map(() => {
                        return TravelerDetailsActions.travelerPrismaPositionRemoved({ id: action.id });
                    }),
                    catchError((error) =>
                        of(TravelerDetailsActions.travelerPrismaPositionRemoveError({ error: error }))
                    )
                )
            )
        )
    );

    removeTravelerPrismaPositionSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerPrismaPositionRemoved),
                tap((action) => this.toaster.success(`Prisma Position ID removed`))
            ),
        { dispatch: false }
    );

    removeTravelerPrismaPositionError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerPrismaPositionRemoveError),
                tap((action) => this.toaster.fail(`Error removing Prisma Position ID: ${action.error}`))
            ),
        { dispatch: false }
    );

    saveTravelerBjcWorkerId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.saveTravelerBjcWorkerId),
            mergeMap((action) =>
                this.travelersService$.saveTravelerBjcWorkerId(action.bjcWorkerId, action.userId).pipe(
                    map(() => {
                        return TravelerDetailsActions.travelerBjcWorkerIdSaved({ id: action.contractId });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerBjcWorkerIdSaveError({ error: error })))
                )
            )
        )
    );

    saveTravelerBjcWorkerIdSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerBjcWorkerIdSaved),
                tap((action) => this.toaster.success(`BJC Worker ID saved`))
            ),
        { dispatch: false }
    );

    saveTravelerBjcWorkerIdError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerBjcWorkerIdSaveError),
                tap((action) => this.toaster.fail(`Error saving BJC Worker ID: ${action.error}`))
            ),
        { dispatch: false }
    );

    removeTravelerBjcWorkerId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.removeTravelerBjcWorkerId),
            mergeMap((action) =>
                this.travelersService$.removeTravelerBjcWorkerId(action.userId).pipe(
                    map(() => {
                        return TravelerDetailsActions.travelerBjcWorkerIdRemoved({ id: action.contractId });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerBjcWorkerIdRemoveError({ error: error })))
                )
            )
        )
    );

    removeTravelerBjcWorkerIdSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerBjcWorkerIdRemoved),
                tap((action) => this.toaster.success(`BJC Worker ID removed`))
            ),
        { dispatch: false }
    );

    removeTravelerBjcWorkerIdError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDetailsActions.travelerBjcWorkerIdRemoveError),
                tap((action) => this.toaster.fail(`Error removing BJC Worker ID: ${action.error}`))
            ),
        { dispatch: false }
    );

    loadBuildingLookups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.loadBuildingLookups),
            switchMap((action) =>
                this.travelersService$.getBuildingLookups().pipe(
                    map((buildings) => {
                        return TravelerDetailsActions.buildingLookupsLoadSuccess({ buildingLookups: buildings });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerLoadError({ error: error })))
                )
            )
        )
    );

    loadFloorLookups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.loadFloorLookups),
            switchMap((action) =>
                this.travelersService$.getFloorLookups(action.buildingId).pipe(
                    map((floors) => {
                        return TravelerDetailsActions.floorLookupsLoadSuccess({ floorLookups: floors });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerLoadError({ error: error })))
                )
            )
        )
    );

    loadDepartmentLookups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.loadDepartmentLookups),
            switchMap((action) =>
                this.travelersService$.getDepartmentLookups(action.businessUnitId).pipe(
                    map((departments) => {
                        return TravelerDetailsActions.departmentLookupsLoadSuccess({ departmentLookups: departments });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerLoadError({ error: error })))
                )
            )
        )
    );

    loadPayrollLocationLookups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.loadPayrollLocationLookups),
            switchMap((action) =>
                this.travelersService$.getPayrollLocationLookups(action.businessUnitId).pipe(
                    map((payrollLocations) => {
                        return TravelerDetailsActions.payrollLocationLookupsLoadSuccess({
                            payrollLocationLookups: payrollLocations
                        });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerLoadError({ error: error })))
                )
            )
        )
    );

    loadBusinessUnitLookups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDetailsActions.loadBusinessUnitLookups),
            switchMap((action) =>
                this.travelersService$.getBusinessUnitLookups(action.systemId).pipe(
                    map((businessUnits) => {
                        return TravelerDetailsActions.businessUnitLookupsLoadSuccess({
                            businessUnitLookups: businessUnits.map((item) => {
                                item.description = item.businessUnitName;
                                return item;
                            })
                        });
                    }),
                    catchError((error) => of(TravelerDetailsActions.travelerLoadError({ error: error })))
                )
            )
        )
    );
}
