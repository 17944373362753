import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger
} from '@angular/material/legacy-autocomplete';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

interface MappedOption {
    name: string;
    value: unknown;
}

@Component({
    selector: 'aya-input-dropdown',
    templateUrl: 'input-dropdown-combo.component.html',
    styleUrls: ['./input-dropdown-combo.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputDropdownComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger?: MatAutocompleteTrigger;
    @ViewChild('controlInput') controlInput?: ElementRef;
    @ViewChild(MatAutocomplete) autoComplete?: MatAutocomplete;
    @Input() controlName = '';
    @Input() placeholder = 'Select option';
    @Input() options: unknown[] | null = null;
    @Input() requiredMessage = 'Please select an option.';
    @Input() showClearButton = false;
    mappedOptions: MappedOption[] = [];
    formGroup: UntypedFormGroup = new UntypedFormGroup({});
    selectedOption: unknown = null;
    showRequiredError = false;
    private _originalValue: unknown = null;

    constructor(public readonly parentForm: FormGroupDirective) {
        super();
    }

    ngOnInit(): void {
        this.formGroup = this.parentForm.form;

        if (this.formGroup) {
            const originalValue = this.formGroup.get(this.controlName)?.value;
            this.selectedOption = originalValue;
            this._originalValue = originalValue;
        }

        if (this.options && this.options.length > 0) {
            this.mappedOptions = this.options?.map((value: unknown): MappedOption => {
                return { name: this.displayName(value), value };
            });
        } else {
            console.error('Options are empty for autocomplete drop-down.');
        }

        this.watchForInputChanges();
    }

    clearValue(): void {
        this.formGroup.get(this.controlName).setValue(null);
        this.selectedOption = null;
    }

    onBlur(): void {
        // Timer is required so it runs AFTER onAddOption() instead of before
        timer(100)
            .pipe(takeUntil(this.d$))
            .subscribe(() => {
                const control = this.formGroup.get(this.controlName);
                const hasRequiredValidation = control.hasError('required');
                this.showRequiredError = this.selectedOption === null && hasRequiredValidation;

                control.setValue(this.selectedOption, {
                    emitEvent: this.selectedOption !== this._originalValue || !this.formGroup.pristine
                });
                control.setErrors({ invalidInput: null });
                control.updateValueAndValidity();
            });
    }

    openAndFocus() {
        // Timer needed or race conditions occur where menu does not open
        this.clearValue();
        timer(1)
            .pipe(takeUntil(this.d$))
            .subscribe(() => {
                this.autocompleteTrigger?.openPanel();
                this.controlInput?.nativeElement?.focus();
            });
    }

    onFocus(): void {
        // isOpen check here since mat-autocomplete re-triggers focus when an option is selected
        if (!this.autoComplete.isOpen) {
            this.selectedOption = this.formGroup.get(this.controlName).value;
            this.formGroup.get(this.controlName)?.setValue(null, { onlySelf: true, emitEvent: false });
            this.autocompleteTrigger?.openPanel();
        }
    }

    watchForInputChanges(): void {
        this.formGroup
            .get(this.controlName)
            ?.valueChanges.pipe(takeUntil(this.d$))
            .subscribe((value: unknown) => {
                if (typeof value === 'string') {
                    this.formGroup.get(this.controlName)?.markAsPristine();
                    this.formGroup.get(this.controlName)?.setErrors({ invalidInput: true });
                }
            });
    }

    onAddOption(selected: MappedOption): void {
        this.selectedOption = selected;
        this.controlInput?.nativeElement?.blur();
    }

    displayName(value: string | unknown): string {
        if (!value) {
            return '';
        } else if (typeof value === 'string') {
            return value;
        } else if (typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'name')) {
            const name: unknown = value['name' as keyof typeof value];

            if (typeof name === 'string') {
                return name;
            }
        }

        return '';
    }
}
