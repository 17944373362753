<section class="migrated-section-container">
    <fieldset>
        <legend>Phones</legend>

        <div>
            <form [formGroup]="form">
                <mat-accordion formArrayName="phones">
                    <ng-container *ngFor="
                            let phoneForm of phonesFormArray.controls;
                            let i = index
                        ">
                        <mat-expansion-panel class="phones-expansion-panel mat-elevation-z0" [formGroup]="phoneForm">
                            <mat-expansion-panel-header class="phone-header">
                                <mat-panel-title>
                                    {{
                                    phoneForm.get("phoneNumber").value
                                    | vendorPhoneNumber
                                    : phoneForm.get("phoneTypeId")
                                    .value
                                    : phoneTypes
                                    }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="container-content">
                                <div class="field-row">
                                    <div class="phone-type">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-select formControlName="phoneTypeId">
                                                <mat-option *ngFor="
                                                        let phone of phoneTypes
                                                    " [value]="phone.id">
                                                    {{ phone.type }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="phone-input">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input matInput class="form-input" type="tel" formControlName="phoneNumber" placeholder="Phone number"
                                                [specialCharacters]="['(',')',' ','-','e','x','t','.']" mask="(000) 000-0000||(000) 000-0000 ext. 9999" />
                                        </mat-form-field>
                                    </div>

                                    <button class="delete-button" mat-icon-button matTooltip="Remove phone" (click)="removePhone(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>

                <div class="actions">
                    <button class="vendor-add-button" mat-flat-button color="accent" matTooltip="Add new phone" (click)="addPhone()">
                        <mat-icon>add</mat-icon>
                        Add Phone
                    </button>
                </div>
            </form>
        </div>
    </fieldset>
</section>