<section class="migrated-section-container">
    <fieldset>
        <legend>Email Addresses</legend>

        <div>
            <form fxLayout="column" fxLayoutGap="8px" [formGroup]="form">
                <mat-accordion formArrayName="emails">
                    <ng-container *ngFor="let emailForm of emailsFormArray.controls; let i = index">
                        <mat-expansion-panel class="emails-expansion-panel mat-elevation-z0" [formGroup]="emailForm">
                            <mat-expansion-panel-header class="email-header">
                                <mat-panel-title>
                                    {{
                                        emailForm.get("emailAddress").value
                                            | vendorEmailAddress
                                                : emailForm.get(
                                                      "vendorEmailTypeId"
                                                  ).value
                                                : emailTypes
                                    }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="container-content">
                                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px">
                                    <div fxFlex="15">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-select formControlName="vendorEmailTypeId">
                                                <mat-option *ngFor="let emailType of emailTypes" [value]="emailType.id">
                                                    {{ emailType.type }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="65">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input matInput type="email" formControlName="emailAddress" placeholder="Email" />
                                            <mat-error *ngIf="emailForm.get('emailAddress').hasError('email')">
                                                Invalid email address
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="20">
                                        <button class="delete-button" mat-icon-button matTooltip="Remove email" (click)="removeEmail(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>

                <div fxLayout="row" fxLayoutAlign="end center">
                    <button
                        class="vendor-add-button"
                        mat-flat-button
                        color="accent"
                        matTooltip="Add Email"
                        (click)="addEmail()">
                        <mat-icon>add</mat-icon>
                        Add Email
                    </button>
                </div>
            </form>
        </div>
    </fieldset>
</section>
