import { Action, createReducer, on } from '@ngrx/store';
import * as TravelersListActions from '../actions/travelers-list.actions';
import { GridDataQuery, Travelers, TravelersLookup } from '../../travelers.models';
import { LoadingState } from '..'; // changed loading type to be string
import { SortTypes } from 'src/app/shared/models';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ListItem } from 'src/app/shared/models/list-item';

const initialGridState: GridDataQuery = {
    sort: [{ field: 'startDate', dir: SortTypes.DESC }],
    take: 10,
    skip: 0
};

export interface TravelersListState {
    error: string;
    lookups: TravelersLookup;
    travelers: Travelers[];
    travelersLoadStatus: LoadingState;
    travelersQuery: GridDataQuery;
    travelersLookupLoadStatus: LoadingState;
}

export const initialTravelersListState: TravelersListState = {
    error: '',
    lookups: null,
    travelers: [],
    travelersLoadStatus: LoadingState.INIT,
    travelersQuery: initialGridState,
    travelersLookupLoadStatus: LoadingState.INIT
};

const reducer = createReducer(
    initialTravelersListState,
    on(TravelersListActions.loadTravelersLookup, (state) => ({
        ...state,
        travelersLookupLoadStatus: LoadingState.LOADING
    })),
    on(TravelersListActions.travelersLookupLoaded, (state, { lookups }) => ({
        ...state,
        lookups: lookups,
        travelersLookupLoadStatus: LoadingState.LOADED
    })),
    on(TravelersListActions.loadTravelers, (state) => ({ ...state, travelersLoadStatus: LoadingState.LOADING })),
    on(TravelersListActions.loadPdf, (state) => ({ ...state, travelersLoadStatus: LoadingState.LOADING })),
    on(TravelersListActions.pdfLoaded, (state) => ({ ...state, travelersLoadStatus: LoadingState.LOADED })),
    on(TravelersListActions.travelersLoaded, (state, { travelers }) => ({
        ...state,
        travelers: travelers,
        travelersLoadStatus: LoadingState.LOADED,
        error: ''
    })),
    on(TravelersListActions.travelersLoadError, (state, { error }) => ({
        ...state,
        travelers: [],
        travelersLoadStatus: LoadingState.LOADED,
        error: error
    })),
    on(TravelersListActions.setTravelersSearchQuery, (state, { travelersQuery }) => ({
        ...state,
        travelersQuery: travelersQuery,
        travelersLoadStatus: LoadingState.LOADING
    })),
    on(TravelersListActions.setDashboardTravelersQuery, (state, action) => {
        const mapFields = {
            facilityId: 'facilityId',
            unitId: 'unitSearch',
            statusId: 'statusId',
            isStartingSoon: 'isStartingSoon',
            hasPendingWoc: 'hasPendingWOC',
            hasExtensionAvailableOrOffered: 'hasExtensionAvailableOrOffered',
            endDate: 'endDate'
        };

        const filters: any = Object.keys(mapFields)
            .filter((key) => key in action && action[key] !== null)
            .map((key) => ({
                field: mapFields[key],
                operator: 'eq',
                value: [action[key]]
            }));

        if (action.lineOfBusinessId > 0)
            filters.push({
                field: 'lineOfBusinessId',
                operator: 'eq',
                value: action.lineOfBusinessId
            });

        if (action.lineOfBusinessIds?.length > 0) {
            filters.push({
                field: 'lineOfBusinessIds',
                operator: 'eq',
                value: action.lineOfBusinessIds
            });
        }

        if (action.facilityIds?.length > 0) {
            filters.push({
                field: 'searchFacilityIds',
                operator: 'eq',
                value: action.facilityIds
            });
        }

        if (action.specialtyIds?.length > 0) {
            filters.push({
                field: 'searchExpertiseIds',
                operator: 'eq',
                value: action.specialtyIds
            });
        }

        if (action.professionIds?.length > 0) {
            filters.push({
                field: 'searchProfessionIds',
                operator: 'eq',
                value: action.professionIds
            });
        }

        return {
            ...state,
            travelersQuery: {
                sort: [{ field: 'startDate', dir: SortTypes.DESC }],
                take: 10,
                skip: 0,
                filter: {
                    logic: 'and',
                    filters: filters
                } as CompositeFilterDescriptor
            },
            travelersLoadStatus: LoadingState.LOADING
        };
    }),
    on(TravelersListActions.exportTravelers, (state, action) => ({
        ...state,
        travelersLookupLoadStatus: LoadingState.LOADING
    })),
    on(TravelersListActions.travelersExported, (state, action) => ({
        ...state,
        travelersLookupLoadStatus: LoadingState.LOADED
    })),
    on(TravelersListActions.travelersExportError, (state, action) => ({
        ...state,
        travelersLookupLoadStatus: LoadingState.LOADED
    }))
);

export function travelersListReducer(state: TravelersListState | undefined, action: Action) {
    return reducer(state, action);
}
