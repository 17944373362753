import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Brands } from 'src/app/shared/models';
import { BrandingService } from 'src/app/shared/services/branding.service';
import { readBlob } from 'src/app/core/utils';
import * as coreActions from '../actions';
import { CoreActionTypes, LoadFeaturedLogoSuccess, LoadSystemIdSuccess } from '../core.actions';
import { CoreStoreFacade } from 'src/app/core/state/core-store.facade';

@Injectable()
export class CoreBrandingEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly injector: Injector,
        private readonly coreStoreFacade: CoreStoreFacade
    ) {}

    branding$ = createEffect(() =>
        this.actions$.pipe(
            ofType(coreActions.loadBranding),
            switchMap(() =>
                this.coreStoreFacade.getClientSharedData().pipe(
                    map((response) => {
                        switch (response?.systemId) {
                            case 913:
                                return coreActions.enableBranding({ brand: Brands.NYU });
                            default:
                                return null;
                        }
                    }),
                    filter((a) => a != null)
                )
            )
        )
    );

    featuredLogo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CoreActionTypes.LoadFeaturedLogoSuccess),
                //TODO: hidden feature
                filter((action) => false),
                map((action: LoadFeaturedLogoSuccess) => action),
                filter((action) => action.featuredLogo != null),
                switchMap((action) => {
                    return readBlob(action.featuredLogo).pipe(
                        tap((logo) => {
                            const branding = this.injector.get(BrandingService);

                            branding.updateLogo(logo);
                        })
                    );
                })
            ),
        { dispatch: false }
    );

    clearBranding$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CoreActionTypes.Logout),
            map((action: LoadSystemIdSuccess) => {
                return coreActions.disableBranding();
            })
        )
    );

    enableBranding$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(coreActions.enableBranding),
                tap((action) => {
                    const branding = this.injector.get(BrandingService);

                    branding.enable(action.brand);
                })
            ),
        { dispatch: false }
    );

    disableBranding$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(coreActions.disableBranding),
                tap((action) => {
                    const branding = this.injector.get(BrandingService);

                    branding.disable();
                })
            ),
        { dispatch: false }
    );
}
