import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateParams, interpolateUrl } from 'src/app/core/utils';
import { FileAttachment, FileValidationResult } from 'src/app/shared/models/attachment';
import { ListItem } from 'src/app/shared/models/list-item';
import { SecurityLookup } from 'src/app/shared/models/lookups/security-lookup.model';
import { StaffingAdvisoryEditModel } from 'src/app/shared/models/travelers/staffing-advisory-edit.model';

enum StaffingAdvisoryUrls {
    STAFFING_ADVISORY = '/AyaConnect/Client/Traveler/staffing-advisory',
    STAFFING_ADVISORY_ATTACHMENTS = '/AyaConnect/Client/Traveler/staffing-advisory/{staffingAdvisoryId}/attachments',
    STAFFING_ADVISORY_LOOKUPS = '/AyaConnect/Dashboard/security-lists'
}

@Injectable({
    providedIn: 'root'
})
export class StaffingAdvisoryService {
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings
    ) {}

    addStaffingAdvisory(staffingAdvisory: StaffingAdvisoryEditModel): Observable<number> {
        return this.http.post<number>(
            `${this.settings.CORE}${StaffingAdvisoryUrls.STAFFING_ADVISORY}`,
            staffingAdvisory
        );
    }

    deleteStaffingAdvisory(id: number): Observable<Object> {
        return this.http.delete(`${this.settings.CORE}${StaffingAdvisoryUrls.STAFFING_ADVISORY}/${id}`);
    }

    getReasons(): Observable<ListItem[]> {
        return this.http.get<ListItem[]>(`${this.settings.CORE}${StaffingAdvisoryUrls.STAFFING_ADVISORY}/reasons`);
    }

    getCategories(): Observable<ListItem[]> {
        return this.http.get<ListItem[]>(`${this.settings.CORE}${StaffingAdvisoryUrls.STAFFING_ADVISORY}/categories`);
    }

    getRestrictionLevels(): Observable<ListItem[]> {
        return this.http.get<ListItem[]>(
            `${this.settings.CORE}${StaffingAdvisoryUrls.STAFFING_ADVISORY}/restriction-levels`
        );
    }

    getSecurityLookups(): Observable<SecurityLookup> {
        return this.http.get<SecurityLookup>(`${this.settings.CORE}${StaffingAdvisoryUrls.STAFFING_ADVISORY_LOOKUPS}`);
    }

    /** Attachments */
    getAttachments(staffingAdvisoryIds: number[]): Observable<FileAttachment[]> {
        const request = {
            staffingAdvisoryId: staffingAdvisoryIds
        };
        const params = interpolateParams({
            ...request
        });
        const url = `${this.settings.CORE}/${StaffingAdvisoryUrls.STAFFING_ADVISORY}/attachments`;
        return this.http.get<any>(url, { params });
    }

    postAttachment(staffingAdvisoryId: number, fileToUpload: any): Observable<FileValidationResult> {
        const url = interpolateUrl(StaffingAdvisoryUrls.STAFFING_ADVISORY_ATTACHMENTS, { staffingAdvisoryId });
        const input = new FormData();
        input.append('file', fileToUpload);
        return this.http.post<FileValidationResult>(`${this.settings.CORE}${url}`, input);
    }

    getPreviewAttachmentBlob(staffingAdvisoryId: number, fileId: number, pageNum: number): Observable<Blob> {
        const url = `${this.settings.CORE}/${StaffingAdvisoryUrls.STAFFING_ADVISORY}/${staffingAdvisoryId}/attachments/${fileId}/preview?page=${pageNum}`;

        return this.http.get(url, { responseType: 'blob' });
    }

    getTotalNumOfDoc(staffingAdvisoryId: number, fileId: number): Observable<number> {
        const url = `${this.settings.CORE}/${StaffingAdvisoryUrls.STAFFING_ADVISORY}/${staffingAdvisoryId}/attachments/${fileId}/page-count`;

        return this.http.get<number>(url);
    }

    getAttachment(staffingAdvisoryId: number, fileId: number): Observable<Blob> {
        const url = `${this.settings.CORE}/${StaffingAdvisoryUrls.STAFFING_ADVISORY}/${staffingAdvisoryId}/attachments/${fileId}`;

        return this.http.get(url, { responseType: 'blob' });
    }
}
