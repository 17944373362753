import { createAction, props } from '@ngrx/store';
import { GridDataQuery, Travelers, TravelersLookup } from '../../travelers.models';

export const setTravelersSearchQuery = createAction(
    '[Travelers] Set Travelers Search Query',
    props<{ travelersQuery: GridDataQuery }>()
);
export const loadTravelersLookup = createAction('[Travelers] Do Travelers Lookup');
export const travelersLookupLoaded = createAction(
    '[Travelers] Travelers Lookup Loaded',
    props<{ lookups: TravelersLookup }>()
);
export const loadTravelers = createAction('[Travelers] Load Travelers');
export const travelersLoaded = createAction('[Travelers] Travelers Loaded', props<{ travelers: Travelers[] }>());
export const travelersLoadError = createAction('[Travelers] Traveler Load Error', props<{ error: any }>());
export const exportTravelers = createAction('[Travelers] Export Travelers');
export const travelersExported = createAction(
    '[Travelers] Travelers Exported',
    props<{ blob: Blob; filename: string }>()
);
export const travelersExportError = createAction('[Travelers] Travelers Export Error', props<{ error: any }>());
export const loadPdf = createAction('[Travelers] Load PDF');
export const pdfLoaded = createAction('[Travelers] PDF Loaded');

export const setDashboardTravelersQuery = createAction(
    '[Dashboard Component] Set Travelers Search Query',
    props<{
        lineOfBusinessId?: number;
        lineOfBusinessIds?: number[];
        facilityId?: number;
        facilityIds?: number[];
        unitId?: number;
        professionIds?: number[];
        specialtyIds?: number[];
        statusId?: number;
        isStartingSoon?: boolean;
        hasPendingWoc?: boolean;
        endDate?: string;
    }>()
);
