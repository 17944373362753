import { LoadingTypes } from 'src/app/shared/models';
import {
    downloadSubmittalsApplication,
    downloadSubmittalsApplicationSuccess,
    downloadSubmittalsApplicationFail
} from 'src/app/submittals/store/submittals.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface SubmittalApplicationState {
    loading: LoadingTypes[];
}

const initialState: SubmittalApplicationState = {
    loading: []
};

export function submittalApplicationReducer(state: SubmittalApplicationState, action: Action) {
    const setLoading = function (appState: SubmittalApplicationState, submittalId: number, loading: LoadingTypes) {
        appState.loading[submittalId] = loading;
        return appState.loading;
    };

    return createReducer(
        initialState,
        on(downloadSubmittalsApplication, (state, action) => ({
            ...state,
            loading: setLoading(state, action.submittalId, LoadingTypes.LOADING)
        })),

        on(downloadSubmittalsApplicationSuccess, (state, action) => ({
            ...state,
            loading: setLoading(state, action.submittalId, LoadingTypes.LOADED)
        })),

        on(downloadSubmittalsApplicationFail, (state, action) => ({
            ...state,
            loading: setLoading(state, action.submittalId, LoadingTypes.FAIL)
        }))
    )(state, action);
}

export function reducer(state: SubmittalApplicationState | undefined, action: Action) {
    return submittalApplicationReducer(state, action);
}
