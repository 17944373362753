<loading-indicator *ngIf="isLoading$ | async"></loading-indicator>

<div class="top-toolbar">
    <button mat-flat-button color="accent" data-qa-id="ad_vendor_cand_refresh_button" class="migrated-btn-info" (click)="refreshGrid()">
        <mat-icon>refresh</mat-icon>
        Refresh
    </button>
    <button mat-flat-button color="accent" data-qa-id="ad_vendor_cand_export_button" class="migrated-btn-info" (click)="exportExcel()">
        <mat-icon>arrow_downward</mat-icon>
        Export
    </button>
    <button mat-flat-button color="accent" data-qa-id="ad_vendor_cand_add_new_button" class="migrated-btn-info" (click)="addNewCandidate()">
        <mat-icon>add</mat-icon>
        Add Candidate
    </button>
</div>

<portal-grid
  class="portal-grid-scroll-auto highlighted-portal-grid"
  data-title="Vendor Candidates"
  [data]="vendorCandidates$ | async"
  [query]="vendorCandidatesQuery$ | async"
  (dataStateChange)="onDataStateChange($event)"
  (rowClicked)="onRowClicked($event)" >

    <ng-template #header let-title="title" let-total="total">
        <div class="title">
            {{ title }} ({{ filtered }} / {{ total }})
        </div>
    </ng-template>

    <portal-grid-quick-filter defaultText="Select state..." field="stateId" label="State:" filterType="combobox" width="250" [filterOptions]="vendorStates$ | async">
    </portal-grid-quick-filter>

    <portal-grid-quick-filter defaultText="Select specialty..." field="expertiseId" label="Specialty:" filterType="combobox" width="250" [filterOptions]="vendorExpertises$ | async">
    </portal-grid-quick-filter>

    <portal-grid-column
        title="Name"
        field="fullName"
        sortField="fullName"
        filterType="text"
        [defaultText]="'Name'"
        [style]="{'width': '15%'}"
        [headerStyle]="{'width': '15%'}">
        <ng-template #cell let-dataItem="dataItem">
            <span>
                {{dataItem.fullName}}
            </span>
            <span class="icon icon-sm icon-shifts-app ml-2"
                *ngIf="dataItem.inviteSent">
            </span>
        </ng-template>
    </portal-grid-column>

    <portal-grid-column
        title="Email"
        filterType="text"
        field="email"
        [defaultText]="'Email'">
    </portal-grid-column>

    <portal-grid-column
        title="Vendor"
        field="vendorId"
        filterType="combobox"
        [filterOptions]="vendors$ | async">
        <ng-template #cell let-dataItem="dataItem">
            {{ dataItem.vendor }}
        </ng-template>
    </portal-grid-column>

    <portal-grid-column
        title="Profession"
        field="professionId"
        filterType="combobox"
        [filterOptions]="vendorProfessions$ | async">
        <ng-template #cell let-dataItem="dataItem">
            {{ dataItem.profession }}
        </ng-template>
    </portal-grid-column>

</portal-grid>
