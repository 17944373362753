import { Injectable } from '@angular/core';
import { SubmittalStatusType } from 'src/app/shared/models/submittals/enums/submittal-status-type.enum';

export interface SubmittalStatusConfig {
    statusId: number;
    statusName: string;
    color: string;
}

@Injectable({
    providedIn: 'root'
})
export class SubmittalStatusService {
    private readonly configs: SubmittalStatusConfig[] = [
        {
            statusId: 1,
            statusName: 'Pending Review',
            color: '#f3be42'
        },
        {
            statusId: 2,
            statusName: 'Qualified',
            color: '#48927a'
        },
        {
            statusId: 3,
            statusName: 'Interview',
            color: '#e67a3a'
        },
        {
            statusId: 4,
            statusName: 'Offer',
            color: '#4690b0'
        },
        {
            statusId: 5,
            statusName: 'Declined',
            color: '#e04f4f'
        },
        {
            statusId: 6,
            statusName: 'Declined',
            color: '#e04f4f'
        },
        {
            statusId: 7,
            statusName: 'Interview',
            color: '#e67a3a'
        }
    ];

    getConfig(statusId: number): SubmittalStatusConfig {
        return this.configs.find((c) => c.statusId === statusId);
    }

    getColor(statusId: number): string | null {
        const confing = this.getConfig(statusId);

        return confing && confing.color;
    }

    canShowDecline(statusId: number) {
        switch (statusId) {
            case SubmittalStatusType.DECLINED:
                return false;
            default:
                return true;
        }
    }

    canShowOffer(statusId: number) {
        switch (statusId) {
            case SubmittalStatusType.OFFER:
            case SubmittalStatusType.DECLINED:
                return false;
            default:
                return true;
        }
    }
}
