import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { VendorContactEmailComponent } from './vendor-contact-email/vendor-contact-email.component';
import { VendorContactPhoneComponent } from './vendor-contact-phone/vendor-contact-phone.component';
import { VendorContactProfileComponent } from './vendor-contact-profile/vendor-contact-profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { VendorContactService } from 'src/app/admin/vendor-details/services';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        FlexLayoutModule,
        SharedModule
    ],
    exports: [VendorContactEmailComponent, VendorContactPhoneComponent, VendorContactProfileComponent],
    declarations: [VendorContactEmailComponent, VendorContactPhoneComponent, VendorContactProfileComponent],
    providers: [VendorContactService]
})
export class VendorContactsModule {}
