import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import {
    Candidate,
    CandidateProfileTabDetails,
    ContractRequirementDocument,
    ContractRequirements,
    ContractRequirementUpload
} from 'src/app/shared/models/candidate';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { ShiftListItem } from 'src/app/shifts/models/shift-list-item.model';
import { setSelectedShifts } from 'src/app/vendor-perdiemscheduler/store';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions/vendor-candidate-details.actions';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors';
import { VendorCandidateContractService } from '../../services/contract.service';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { FileService } from '../../services/file.service';
import { LoadingTypes } from 'src/app/shared/models';

@Component({
    selector: 'ayac-vendor-candidate-details',
    templateUrl: 'vendor-candidate-details.component.html',
    styleUrls: ['./vendor-candidate-details.component.scss']
})
export class VendorCandidateDetailsComponent extends UnsubscribeOnDestroy implements OnInit {
    contractRequirements$: Observable<ContractRequirements>;
    hasContractAtPerDiemHospital$: Observable<boolean>;
    isNewSubmittal$: Observable<boolean>;
    saveCalled = new EventEmitter<void>();
    isLoading = true;
    hasChanges = false;
    isFormValid = true;
    vendorId = 0;
    candidateId = 0;
    candidate: Candidate;
    isNewCandidateLoading = true;
    additionalReqsFeatureFlag = false;
    isNewSubmittal = false;
    accountManagerId = 0;
    jobId = 0;
    submittalId = '';
    isNpiNameClearedInvalid = false;
    featureFlag = FeatureFlag;
    firstLoadCheck = false;
    isContractRequirementsLoading$: Observable<boolean>;
    isLoadingDataFromStore$: Observable<LoadingTypes>;
    loadingTypes = LoadingTypes;
    hasAccessShiftsApp$: Observable<boolean>;
    isProfileTabDataLoading$: Observable<boolean>;

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _dialog: DialogService,
        private readonly store: Store,
        private readonly _identityService: IdentityService,
        private readonly _vendorCandidateContractService: VendorCandidateContractService,
        private readonly _fileService: FileService
    ) {
        super();
    }

    ngOnInit(): void {
        // CNW-17343 - Always do this lookup for proper display of shifts app tab.
        this.hasAccessShiftsApp$ = this.store.select(selectors.selectVendorCandidateHasAccessShiftsApp);
        this.handleDataForInit();
    }

    handleDataForInit() {
        this.vendorId = this._identityService.vendorId;
        this._route.params
            .pipe(
                map((params: Params) => params.id),
                takeUntil(this.d$)
            )
            .subscribe((candidateId: string) => {
                //Had set a delay here to account for a new candidate route change to when saved so child components can be destroyed and reloaded.
                setTimeout(() => {
                    if (candidateId === 'new') {
                        this.isLoading = false;
                        this.isNewCandidateLoading = false;
                    } else {
                        this.candidateId = Number(candidateId);
                        this.loadContractRequirements();
                        this.loadHasContractAtPerDiemHospital();
                        this.isNewCandidateLoading = false;
                    }
                }, 1);
            });

        this.isNewSubmittal$ = this._route.queryParamMap.pipe(
            map((params: ParamMap) => {
                this.isNewSubmittal = params.has('submittalId') && params.get('submittalId') === 'new';
                this.jobId = params.has('jobId') ? Number(params.get('jobId')) : 0;
                this.accountManagerId = params.has('accountManagerId') ? Number(params.get('accountManagerId')) : 0;
                this.submittalId = params.has('submittalId') ? params.get('submittalId') : '';
                return this.isNewSubmittal;
            })
        );

        this.store
            .select(selectors.selectVendorCandidate)
            .pipe(takeUntil(this.d$))
            .subscribe((candidate) => {
                if (candidate) {
                    this.candidate = candidate ?? { ...candidate, vendorId: this.vendorId };
                }
            });
    }

    loadHasContractAtPerDiemHospital(): void {
        this.hasContractAtPerDiemHospital$ = this.store.select(selectors.selectHasContractAtPerDiemHospital);
        this.store.dispatch(actions.getHasContractAtPerDiemHospital({ candidateId: this.candidateId }));
    }

    loadContractRequirements(): void {
        this.contractRequirements$ = this.store.select(selectors.selectContractRequirements);
        this.store.dispatch(actions.getContractRequirements({ candidateId: this.candidateId }));
    }

    back(): void {
        this._router.navigateByUrl('/vendor/candidates');
    }

    save(): void {
        this.saveCalled.emit();
    }

    onIsSavingChanged(isSaving: boolean): void {
        this.isLoading = isSaving;
    }

    onChanges(candidateProfileTabDetails: CandidateProfileTabDetails): void {
        this.candidate = candidateProfileTabDetails.candidate;
        this.hasChanges = true;
    }

    onProfileIsFormValidChanged(isValid: boolean): void {
        this.isFormValid = isValid;
    }

    onProfileIsNpiClearedChanged(isInvalid: boolean): void {
        this.isNpiNameClearedInvalid = isInvalid;
    }

    onSaveSuccess(event: { candidateId: number; isNew: boolean }): void {
        this._openSuccessDialog(`${event.isNew ? 'Created' : 'Saved'} Successfully!`);
        this.hasChanges = false;
        this.isLoading = false;
        this.candidateId = event.candidateId;
        if (event.isNew) {
            this.isNewCandidateLoading = true;
            this._router.navigateByUrl(`/vendor/candidate/${event.candidateId}`);
        }
    }

    onSaveError(): void {
        this._dialog.openMigratedSnackBarError(`Error occurred trying to process your request.`);
        this.isLoading = false;
    }

    onViewShift(shiftItem: ShiftListItem): void {
        const shifts: ShiftListItem[] = [shiftItem as ShiftListItem];
        this.store.dispatch(setSelectedShifts({ shifts }));

        this._router.navigateByUrl(`/vendor/perdiemscheduler/detail`);
    }

    onCandidateLoaded(candidate: Candidate): void {
        this._fileService.setCandidate(candidate);
        this.candidate = candidate;
        this.isLoading = false;
    }

    canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
        return this.hasChanges && !this.isLoading ? this._openChangedNotSavedDialog() : true;
    }

    uploadDocuments(event: { document: ContractRequirementDocument; files: File[] }): void {
        event.document.isUploadInProgress = true;

        const uploadData: ContractRequirementUpload = {
            documentTypeId: event.document.documentTypeId,
            candidateId: this.candidate.oldUserId,
            originalFileName: event.files.length > 1 ? 'Multiple Files' : event.files[0].name,
            contractId: event.document.contractId,
            files: event.files
        };

        this._vendorCandidateContractService
            .documentUpload(uploadData)
            .pipe(takeUntil(this.d$))
            .subscribe({
                next: (result) => {
                    result?.forEach((x) =>
                        x.isValid
                            ? this._openSuccessDialog(`Document '${x.name}' have been uploaded successfully.`)
                            : this._openErrorDialog(`Failed to upload document '${x.name}'. ${x.message}`)
                    );

                    this.loadContractRequirements();
                    event.document.isUploadInProgress = false;
                },
                error: () => {
                    this._openErrorDialog('Failed to upload document(s).');
                    event.document.isUploadInProgress = false;
                }
            });
    }

    completeSubmittal(): void {
        this.store.dispatch(
            actions.checkCandidateFiles({
                candidateId: this.candidateId,
                jobId: this.jobId,
                submittalId: this.submittalId,
                accountManagerId: this.accountManagerId
            })
        );
    }

    private _openChangedNotSavedDialog(): Promise<boolean> {
        return this._dialog.openConfirmationDialog({
            data: {
                title: 'Candidate Details',
                text: 'You have unsaved changes! Do you want to leave?',
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel'
            }
        });
    }

    private _openSuccessDialog(message: string): void {
        this._dialog.openMigratedSnackBarSuccess(message);
    }

    private _openErrorDialog(message: string): void {
        this._dialog.openMigratedSnackBarError(message);
    }
}
