<div [formGroup]="certForm">
    <input hidden formControlName="certificationId">

    <div>
        <div [hidden]="!certification?.canExpire" [ngClass]="{ 'has-error' : dpkExpiration?.invalid}"
            class="flex-row-container">
            <label *ngIf="!(this.certification.requiresExpiration)"
                for="dpkExpiration{{certification.id}}" class="m5 p05em flex25">Expiration:</label>
            <label *ngIf="this.certification.requiresExpiration"
                for="dpkExpiration{{certification.id}}" class="m5 p05em flex25">Expiration: <span
                    class="k-required">*</span></label>
                    <ayac-form-control-wrapper class="expidation-date-control">
                        <mat-form-field appearance="outline">
                            <input matInput [matDatepicker]="picker" formControlName="dpkExpiration" id="dpkExpiration{{certification.id}}"
                                required data-qa-id="dpkExpiration" (dateChange)="certificateUpdated()" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="certForm.get('dpkExpiration').hasError('required')">Expiration date is required.</mat-error>
                            <mat-error *ngIf="certForm.get('dpkExpiration').hasError('InvalidExpDate')">Expiration date must match
                                MM/DD/YYYY, between 1/1/1753 and 12/31/9999.</mat-error>
                        </mat-form-field>
                    </ayac-form-control-wrapper>
        </div>

        <div *ngIf="certification.hasLocation" [ngClass]="{ 'has-error' : ddlLevel?.invalid}"
            class="flex-row-container">
            <label for="ddlLevel{{certification?.id}}" class="m5 p05em flex25">Level:</label>
            <mat-form-field appearance="outline" class="field-width">
                <mat-select formControlName="ddlLevel" placeholder="Select a value" id="ddlLevel{{certification.id}}"
                    (selectionChange)="certificateUpdated()" [required]="certification.requiresLocation">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let locationLevel of locationLevels" [value]="locationLevel.id">
                        {{locationLevel.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex-row-container"
            *ngIf="certification?.hasLocation && vendorCertification?.level && getSelectedLevel()?.hasLocationText"
            [ngClass]="{ 'has-error' : txtLocation?.invalid}">
            <label for="txtLocation{{certification?.id}}" class="m5 p05em flex25">Location:</label>
            <mat-form-field appearance="outline" class="field-width">
                <input type="text" placeholder="Location" matInput formControlName="txtLocation" id="txtLocation{{certification?.id}}"
                    (change)="certificateUpdated()" maxlength="200"
                    [required]="getSelectedLevel()?.requiresLocationText" />
                <mat-error *ngIf="certForm?.get('txtLocation')?.hasError('required')">
                    Location is required.
                </mat-error>
                <mat-error *ngIf="certForm?.get('txtLocation')?.hasError('maxlength')">
                    Location must not exceed 200 characters.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex-row-container">
            <label for="txtOrganization{{certification?.id}}" class="m5 p05em flex25">Certificate Organization:</label>
            <mat-form-field appearance="outline" class="field-width">
                <input type="text" placeholder="Certificate Organization" matInput formControlName="txtOrganization"
                    id="txtOrganization{{certification?.id}}" (change)="certificateUpdated()" maxlength="500" />
            </mat-form-field>

        </div>

        <div *ngIf="certification?.id === 2 || certification?.id === 3"
                [ngClass]="{ 'has-error' : ddlEthnicity?.invalid}" class="flex-row-container">
                <label for="ddlEthnicity{{certification?.id}}" class="m5 p05em flex25">Ethnicity:</label>
                <mat-form-field appearance="outline" class="field-width">
                    <mat-select placeholder="Select a value" formControlName="ddlEthnicity" id="ddlEthnicity{{certification.id}}"
                        (selectionChange)="certificateUpdated()">
                        <mat-option>Not Specified</mat-option>
                        <mat-option *ngFor="let ethnicityType of ethnicityTypes"
                            [value]="ethnicityType.certificationEthnicityTypeId">
                            {{ethnicityType.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        <div class="flex-row-container" *ngIf="isDiversity">
            <input type="file" style="display: none" (change)="onFilesSelected($event)" #fileUpload multiple>

            <label class="m5 p05em flex25">Certificate:</label>
            <div class="field-width">
                <div *ngFor="let file of vendorCertification?.files" class="file-container">
                    <a (click)="downloadFile.emit(file.id)" matTooltip="Download file">{{file.name}}{{file.type}}</a>
                    <button class="delete-button" mat-icon-button matTooltip="Remove file" (click)="removeFile(file.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div *ngFor="let file of vendorCertification?.uploadFiles" class="file-container">
                    {{file.name}}
                    <button class="delete-button" mat-icon-button matTooltip="Remove file" (click)="removeUploadFile(file)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div><button mat-raised-button color="accent" class="upload-button" (click)="fileUpload.click()">Upload</button></div>
            </div>

        </div>

    </div>
</div>
