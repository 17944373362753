import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateParams } from 'src/app/core/utils';
import { FacilityAssociation } from 'src/app/facilities/models/facility-association.model';
import { SortTypes } from 'src/app/shared/models';
import { FacilityAssociationsUrls } from 'src/app/shared/models/facilities/enums/facility-associations-urls.enum';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { HospitalAssociationFacilitiesGridItem } from 'src/app/facilities/models/hospital-association-facilities-grid-item.model';
import { HospitalAssociationVendorsGridItem } from 'src/app/facilities/models/hospital-association-vendors-grid-item.model';
import { renameKeys } from 'src/app/core/utils/rename-keys';
import { FacilitiesVendorStatusUpdate } from '../models';

@Injectable({
    providedIn: 'root'
})
export class FacilityAssociationsService {
    constructor(
        private readonly _http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {}

    getFacilitySystems(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<PagingToken<FacilityAssociation[]>> {
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgs
        });

        return this._http
            .get<PagingToken<FacilityAssociation[]>>(
                `${this._settings.CORE}${FacilityAssociationsUrls.FACILITY_ASSOCIATIONS_LIST}`,
                {
                    params
                }
            )
            .pipe(
                tap((result) =>
                    result.data.forEach((item) => (item.hospitalSystemName = item.hospitalSystemName.toUpperCase()))
                )
            );
    }

    updateVendorFacilityStatus(
        pagination: {
            pageSize: number;
            skip: number;
        },
        filterArgs: {
            facilityStatusIds: number[];
            vendorIds: number[];
            hospitalSystemId: number | null;
        },
        params: FacilitiesVendorStatusUpdate
    ): void {
        const filterArgsObj = renameKeys(filterArgs, 'filter');

        const filters = interpolateParams({
            ...pagination,
            ...filterArgsObj
        });

        this._http
            .put(`${this._settings.CORE}${FacilityAssociationsUrls.FACILITY_ASSOCIATION_VENDOR_STATUS}?${filters}`, {
                ...params
            })
            .subscribe();
    }

    updateVendorFacilityStatusAll(params: FacilitiesVendorStatusUpdate): void {
        this._http
            .put(`${this._settings.CORE}${FacilityAssociationsUrls.FACILITY_ASSOCIATION_VENDOR_STATUS_ALL}`, {
                ...params
            })
            .subscribe();
    }

    getFacilityAssociation(id: number): Observable<FacilityAssociation> {
        return this._http.get<FacilityAssociation>(
            `${this._settings.CORE}${FacilityAssociationsUrls.FACILITY_ASSOCIATIONS_LIST}/${id}`
        );
    }

    getFacilityAssociationFacilities(
        pagination: {
            pageSize: number;
            skip: number;
        },
        filterArgs: {
            facilityStatusIds: number[];
            vendorIds: number[];
            hospitalSystemId: number | null;
        }
    ): Observable<PagingToken<HospitalAssociationFacilitiesGridItem[]>> {
        const filterArgsObj = renameKeys(filterArgs, 'filter');

        const params = interpolateParams({
            ...pagination,
            ...filterArgsObj
        });

        return this._http.get<PagingToken<HospitalAssociationFacilitiesGridItem[]>>(
            `${this._settings.CORE}${FacilityAssociationsUrls.FACILITY_ASSOCIATION_FACILITIES}`,
            { params }
        );
    }

    getAllFacilityAssociationFacilities(
        pagination: {
            pageSize: number;
            skip: number;
        },
        filterArgs: {
            hospitalSystemId: number | null;
            statusId: number;
        }
    ): Observable<PagingToken<HospitalAssociationFacilitiesGridItem[]>> {
        const filterArgsObj = renameKeys(filterArgs, 'filter');

        const params = interpolateParams({
            ...pagination,
            ...filterArgsObj
        });

        return this._http.get<PagingToken<HospitalAssociationFacilitiesGridItem[]>>(
            `${this._settings.CORE}${FacilityAssociationsUrls.FACILITY_ASSOCIATION_FACILITIES_ALL}`,
            { params }
        );
    }

    getFacilityAssociationVendors(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<PagingToken<HospitalAssociationVendorsGridItem[]>> {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgsObj
        });

        return this._http.get<PagingToken<HospitalAssociationVendorsGridItem[]>>(
            `${this._settings.CORE}${FacilityAssociationsUrls.FACILITY_ASSOCIATION_VENDORS}`,
            { params }
        );
    }

    exportAllVendors(
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...sortArgsObj,
            ...matchArgsObj
        });

        return this._http.get(`${this._settings.CORE}${FacilityAssociationsUrls.FACILITY_ASSOCIATION_VENDORS_EXPORT}`, {
            params,
            responseType: 'blob'
        });
    }

    exportSelectedVendors(
        hospitalSystemId: number,
        vendorIds: number[],
        isActiveForContractLine: boolean
    ): Observable<Blob> {
        const params = interpolateParams({
            hospitalSystemId,
            vendorIds,
            isActiveForContractLine
        });
        return this._http.get(
            `${this._settings.CORE}${FacilityAssociationsUrls.FACILITY_ASSOCIATION_VENDORS_EXPORT_SELECTED}`,
            {
                params,
                responseType: 'blob'
            }
        );
    }
}
