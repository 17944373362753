<dialog-layout title='Please Select Cancel Reason' *ngIf="canSelectReason">
    <div mat-dialog-content class="row cancel-reason" [formGroup]="selectionForm">
        <span>Cancel Reason</span>
        <mat-form-field appearance="outline">
            <mat-select formControlName="reason" data-qa-id="CancelReason">
                <mat-option *ngFor="let reason of cancelReasons$ | async" [value]="reason">
                    {{ reason }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div mat-dialog-actions class="modal-footer-right">
        <button mat-flat-button color="accent" (click)="onRemoveCandidate()" [disabled]="!selectedReason">Yes</button>
        <button mat-stroked-button color="accent" (click)="dialogRef.close(false)">No</button>
    </div>
</dialog-layout>
