<kendo-popup
	(click)="popupClicked($event)"
	[anchor]="popupAnchor"
	[ngClass]="uniqueFilterClass"
	(anchorViewportLeave)="isOpened=false" 				
	*ngIf="isOpened"
	[style.width]="width + 'px'"
	(open)="onPopupOpen($event)"
	(close)="onPopupClose($event)">
		<ng-content></ng-content>
		<div class="k-action-buttons">
			<button (click)="onResetClicked($event)" class="k-button" type="reset" [attr.disabled]="disableClear ? 'disabled' : null" >Clear</button>
			<button (click)="onFilterClicked($event)" [attr.disabled]="!isFilterActive ? 'disabled' : null" class="k-button k-primary">Filter</button>
		</div>
</kendo-popup>
<div class="k-grid-filter" [class.k-state-active]="isFilterActive">
	<div #popupAnchor (click)="filterToggled()" class="k-icon k-i-filter"></div>
</div>