import { Pipe, PipeTransform } from '@angular/core';
import { CustomSystemFieldLookup } from 'src/app/core/models/system-custom-field.model';

@Pipe({
    name: 'customFieldFind'
})
export class CustomFieldFindPipe implements PipeTransform {
    transform(customSystemFieldLookups?: CustomSystemFieldLookup[], fieldName?: string): CustomSystemFieldLookup {
        if (!customSystemFieldLookups || !fieldName) {
            return null;
        }
        return customSystemFieldLookups.find((cf) => cf.name.toLowerCase() === fieldName.toLowerCase());
    }
}
