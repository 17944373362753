import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';

@Injectable({
    providedIn: 'root'
})
export class LaunchDarklyService {
    public createClient(launchDarklyClientSdkKey: string, user: LDClient.LDUser): LDClient.LDClient {
        return LDClient.initialize(launchDarklyClientSdkKey, user);
    }
}
