export enum ApplicationPermissions {
    ViewInvoices = 52,
    ViewJobs = 60,
    ViewShifts = 66,
    ConnectReportsView = 79,
    ViewTimecards = 97,
    ViewSubmittals = 90,
    ViewWorkers = 104,
    ViewInternalPool = 371,
    BillRateView = 5582,
    ManageLinkedProfiles = 6621,
    ImpersonateUsers = 8000,
    LocumsTerminology = 9306,
    ReportingAnalyticsDemoView = 9315,
    ReportingAnalyticsView = 9308,
    ReportingNPSAnalyticsDemoView = 9467,
    ReportingNPSAnalyticsView = 9468,
    ReportingFinanceAnalyticsDemoView = 9470,
    ReportingFinanceAnalyticsView = 9469,
    ReportingMarketTrendsView = 9472,
    ReportingLocumsAnalyticsDemoView = 9316,
    ReportingLocumsAnalyticsView = 9483
}
