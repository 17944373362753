import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap, map, catchError, exhaustMap } from 'rxjs/operators';
import { InvoicesRepositoryService } from 'src/app/invoices/services/invoices-repository.service';
import { InvoicesState } from '../invoices.state';
import {
    loadInvoiceAttachmentsEnhancedFail,
    loadInvoiceAttachmentsEnhanced,
    loadInvoiceAttachmentsEnhancedSuccess,
    loadInvoiceAttachmentPreview,
    loadInvoiceAttachmentPreviewSuccess,
    loadInvoiceAttachmentPreviewFailure,
    downloadInvoiceAttachment,
    downloadInvoiceAttachmentFailure,
    loadInvoiceAttachmentPreviewTotalPages,
    loadInvoiceAttachmentPreviewTotalPagesSuccess,
    loadInvoiceAttachmentPreviewTotalPagesFailure,
    downloadInvoiceAttachmentSuccess
} from '../invoices.actions';
import { of } from 'rxjs';
import { uriFormat } from '../../../shared/utilities';
import { FileAttachment } from 'src/app/shared/models/attachment';
import { BaseEffect } from '../../../shared/store/base-effect';
import { ToasterService } from '../../../core/services';
import { downloadBlob } from '../../../core/utils';

@Injectable()
export class InvoiceAttachmentsEnhancedEffect extends BaseEffect {
    getInvoiceAttachments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadInvoiceAttachmentsEnhanced),
            switchMap((action) => {
                return this.invoicesRepository.getInvoiceAttachments(action.id).pipe(
                    map((response) =>
                        loadInvoiceAttachmentsEnhancedSuccess({
                            invoiceFileAttachments: response.map((r) => {
                                r.fileName = uriFormat(r.fileName);
                                return {
                                    id: r.id,
                                    fileName: decodeURI(r.fileName),
                                    userFullName: r.createdBy,
                                    uploadDate: r.updatedDate,
                                    metadata: { invoiceDisputeId: r.invoiceDisputeId } as Record<string, number>
                                } as FileAttachment;
                            })
                        })
                    ),
                    catchError((error) => of(loadInvoiceAttachmentsEnhancedFail({ error })))
                );
            })
        )
    );

    getInvoiceAttachmentPreview$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadInvoiceAttachmentPreview),
            exhaustMap((action) => {
                return this.invoicesRepository.getInvoiceAttachmentPreview(action.invoiceAttachmentId).pipe(
                    map((response) => loadInvoiceAttachmentPreviewSuccess({ file: response })),
                    catchError((error: unknown) => of(loadInvoiceAttachmentPreviewFailure({ error })))
                );
            })
        );
    });

    downloadInvoiceAttachment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(downloadInvoiceAttachment),
            switchMap((action) => {
                return this.invoicesRepository.downloadFile(action.invoiceAttachmentId).pipe(
                    map((response) =>
                        downloadInvoiceAttachmentSuccess({
                            file: response
                        })
                    ),
                    catchError((error: unknown) => of(downloadInvoiceAttachmentFailure({ error })))
                );
            })
        );
    });

    getInvoiceAttachmentPreviewTotalPages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadInvoiceAttachmentPreviewTotalPages),
            switchMap((action) => {
                return this.invoicesRepository.getInvoiceAttachmentPreviewTotalPages(action.invoiceAttachmentId).pipe(
                    map((response) =>
                        loadInvoiceAttachmentPreviewTotalPagesSuccess({
                            totalPages: response
                        })
                    ),
                    catchError((error: unknown) => of(loadInvoiceAttachmentPreviewTotalPagesFailure({ error })))
                );
            })
        );
    });

    downloadApplicationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(downloadInvoiceAttachmentSuccess),
                map((action) => {
                    const fileName = this.getFileNameFromHttpResponse(action.file);
                    downloadBlob(action.file.body as Blob, fileName as string);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<InvoicesState>,
        private readonly invoicesRepository: InvoicesRepositoryService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
