import { createReducer, Action, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as actions from 'src/app/powerbi/store/powerbi-report.actions';
import { PowerBiExportStatus } from 'src/app/shared/models/powerbi/powerbi-export-status.model';

export interface PowerBiReportExportState {
    loading: LoadingTypes;
    status: PowerBiExportStatus;
}

export const initialPowerBiReportExportState: PowerBiReportExportState = {
    loading: LoadingTypes.INIT,
    status: null
};

export function powerBiReportExportReducer(state: PowerBiReportExportState, action: Action) {
    return createReducer(
        initialPowerBiReportExportState,

        on(
            actions.exportPowerBiReport,
            actions.getPowerBiReportExportStatus,
            actions.downloadPowerBiReportFile,
            (state, action) => ({
                ...state,
                loading: LoadingTypes.LOADING
            })
        ),

        on(actions.exportPowerBiReportSuccess, actions.getPowerBiReportExportStatusSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,
            status: action.status
        })),

        on(actions.exportPowerBiReportFail, actions.getPowerBiReportExportStatusFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            status: null
        })),

        on(actions.downloadPowerBiReportFileSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,
            status: null
        })),

        on(actions.downloadPowerBiReportFileFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL
        })),

        on(actions.clearPowerBiReport, (state, action) => ({
            ...initialPowerBiReportExportState
        }))
    )(state, action);
}
