import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as internalPoolActions from 'src/app/internal-pool/store/actions';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';

export interface ResourceDetailsState {
    resource: Resource;
    loading: LoadingTypes;
    saving: LoadingTypes;
    checkingUsername: LoadingTypes;
    suggestedUsername: string;
}

export const initialDetailsState: ResourceDetailsState = {
    resource: null,
    loading: LoadingTypes.INIT,
    saving: LoadingTypes.INIT,
    checkingUsername: LoadingTypes.INIT,
    suggestedUsername: null
};

export function resourceDetailsReducer(state: ResourceDetailsState, action: Action) {
    return createReducer(
        initialDetailsState,
        on(internalPoolActions.loadResourceDetails, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(internalPoolActions.loadSystemIdWithLookupsAndResourceDetails, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(internalPoolActions.loadResourceDetailsSuccess, (state, action) => ({
            ...state,
            resource: action.resource,
            loading: LoadingTypes.LOADED
        })),

        on(internalPoolActions.loadResourceDetailsFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL
        })),

        on(internalPoolActions.updateResource, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADING
        })),

        on(internalPoolActions.updateResourceSuccess, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADED
        })),

        on(internalPoolActions.updateResourceFail, (state, action) => ({
            ...state,
            saving: LoadingTypes.FAIL
        })),

        on(internalPoolActions.addResource, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADING
        })),

        on(internalPoolActions.addResourceSuccess, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADED
        })),

        on(internalPoolActions.addResourceFail, (state, action) => ({
            ...state,
            saving: LoadingTypes.FAIL
        })),

        on(internalPoolActions.resendInvitationEmail, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADING
        })),

        on(internalPoolActions.resendInvitationEmailSuccess, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADED
        })),

        on(internalPoolActions.resendInvitationEmailFail, (state, action) => ({
            ...state,
            saving: LoadingTypes.FAIL
        })),
        on(internalPoolActions.archiveWorker, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADING
        })),
        on(internalPoolActions.archiveWorkerSuccess, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADED
        })),
        on(internalPoolActions.archiveWorkerFail, (state, action) => ({
            ...state,
            saving: LoadingTypes.FAIL
        })),
        on(internalPoolActions.checkResourceUsername, (state) => ({
            ...state,
            checkingUsername: LoadingTypes.LOADING,
            suggestedUsername: null
        })),
        on(internalPoolActions.checkResourceUsernameSuccess, (state, action) => ({
            ...state,
            checkingUsername: LoadingTypes.LOADED,
            suggestedUsername: action.username
        })),
        on(internalPoolActions.checkResourceUsernameFail, (state) => ({
            ...state,
            checkingUsername: LoadingTypes.FAIL,
            suggestedUsername: null
        })),
        on(
            internalPoolActions.clearResourceDetails,
            internalPoolActions.initializeResourceDetails,
            (state, action) => ({
                ...initialDetailsState
            })
        )
    )(state, action);
}

export function reducer(state: ResourceDetailsState | undefined, action: Action) {
    return resourceDetailsReducer(state, action);
}
