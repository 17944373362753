<ng-container matColumnDef="selected">
    <mat-accordion>
        <div class="contacts-container" *ngFor="let contact of vendorContacts">
            <mat-expansion-panel class="mat-elevation-z0" (click)="selectContact(contact)" [expanded]="selectedContact?.id === contact.id">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{contact.firstName}} {{contact.lastName}} (id: {{contact.id > 0 ? contact.id : 'New'}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <mat-tab-group animationDuration="0ms" [disableRipple]="true">
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <span class="glyphicon glyphicon-bell"></span>
                                <span>Profile</span>
                            </ng-template>
                            <aya-vendor-contact-profile #profile class="tab-content" 
                                [contact]="contact" [contactTags]="contactTags"
                                [mainEmail]="checkMainEmailChanged(contact)"
                                [states]="states" (contactChanged)="contactInfoChanged($event)">
                            </aya-vendor-contact-profile>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <span class="glyphicon glyphicon-bell"></span>
                                <span>Phones</span>
                            </ng-template>
                            <ayac-vendor-contact-phone class="tab-content" [contact]="contact" [phoneTypes]="phoneTypes"
                                (contactChanged)="contactInfoChanged($event)"></ayac-vendor-contact-phone>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <span class="glyphicon glyphicon-bell"></span>
                                <span>Email Addresses</span>
                            </ng-template>
                            <ayac-vendor-contact-email class="tab-content" [contact]="contact" [emailTypes]="emailTypes"
                                (contactChanged)="contactInfoChanged($event, profile)"></ayac-vendor-contact-email>
                        </mat-tab>
                    </mat-tab-group>
                </ng-template>
            </mat-expansion-panel>

            <ng-container *ngIf="contact.hasVendorSubmittals || contact.hasVendorCandidates; else enabledBtn">
                <span matTooltip="Cannot delete as contact has a submittal or candidate reference">
                    <button class="delete-button" disabled mat-icon-button (click)="deleteContact(contact)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </span>
            </ng-container>

            <ng-template #enabledBtn>
                <button class="delete-button" mat-icon-button (click)="deleteContact(contact)" matTooltip="Delete">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </div>
    </mat-accordion>

    <mat-paginator #paginator [length]="vendorContactsTotal" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page" (page)="updateServerSidePaginatedContacts($event)"></mat-paginator>

    <div class="actions">
        <button mat-flat-button color="accent" class="migrated-icon-button" (click)="addContact()" matTooltip="Add new contact">
            <mat-icon>add</mat-icon>
            Add Contact
        </button>
    </div>
</ng-container>