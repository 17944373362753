<loading-indicator *ngIf="isLoading$ | async"></loading-indicator>
<ayac-widget>
    <div class="content" terminology>
        <portal-grid #grid
            data-title="Internal Pool"
            [data]="resources$ | async"
            [query]="resourcesQuery$ | async"
            (rowClicked)="onRowClicked($event)"
            (dataStateChange)="onDataStateChange($event)"
            [selectable]="false"
            class="main-grid-internalpool">

            <ng-container>
                <portal-grid-quick-filter
                    field="facilityIds"
                    label="Facility"
                    filterType="multiselect"
                    defaultText="Select facilities"
                    [search]="true"
                    selectAll="All facilities (# facilities)"
                    [filterOptions]="facilityFilters$ | async"
                    width="348"
                    [required]="false">
                </portal-grid-quick-filter>
            </ng-container>

            <ng-template #toolbar >
                <button mat-flat-button
                   *ngIf="showAddWorker$ | async"
                    data-testid="btn_add_worker"
                    color="accent"
                    (click)="onAddInternalPoolClick()">
                    <mat-icon>add</mat-icon>
                    <span>Add Worker</span>
                </button>
            </ng-template>

            <ng-template #header let-title="title" let-total="total">
                <span class="mat-headline-1 mb-0">{{title}}</span>
                <span>&nbsp;</span>
                <ayac-total-header-toggler
                    [totals]="resourceTotals$ | async"
                    [query]="resourcesQuery$ | async"
                    (dataStateChange)="onDataStateChange($event)">
                </ayac-total-header-toggler>
                <small class="mat-subtitle-1 mb-0 pl-2">(Total: {{total}})</small>
            </ng-template>

            <portal-grid-column
                title="Name"
                field="name"
                filterType="text">
                <ng-template #cell let-dataItem="dataItem">
                    <a (click)="onRowClicked(dataItem)">{{dataItem.name}}</a>

                    <ng-container [ngSwitch]="resourceStatus(dataItem)">
                        <ng-container *ngSwitchCase="'application'">
                            <span class="icon icon-sm icon-shifts-app ml-2"
                                [matTooltip]="'Shifts User (Last activity: ' + (dataItem.lastAppActivityDate | date : 'MM/dd/yyyy')+ ')'">
                            </span>
                        </ng-container>

                        <ng-container *ngSwitchCase="'invited'">
                            <mat-icon class="icon icon-sm ml-2"
                                [matTooltip]="'Invite Email (Sent on: ' + (dataItem.appInviteEmailSentDate | date : 'MM/dd/yyyy') + ')'">
                                email
                            </mat-icon>
                        </ng-container>
                    </ng-container>

                </ng-template>
            </portal-grid-column>

            <portal-grid-column
                title="Type"
                field="irpRecordTypeName"
                filterType="dropdown"
                [filterOptions]="resourceTypes$ | async"
                defaultText="All"
                [width]="80">
                <ng-template #cell let-dataItem="dataItem">
                    {{ dataItem.irpRecordTypeName }}
                </ng-template>
            </portal-grid-column>

            <portal-grid-column
                title="Availability"
                field="availabilityStatusId"
                filterType="dropdown"
                [filterOptions]="availabilityLookups$ | async"
                [sortable]="true"
                defaultText="All"
                width="150">
                <ng-template #cell let-dataItem="dataItem">
                    {{ dataItem.availabilityStatus }}
                </ng-template>
            </portal-grid-column>

            <portal-grid-column
                *hasSystemFields="['internalPool.jobTitle'];"
                title="Job Title"
                field="jobTitle"
                filterType="text"
                [sortable]="true">
                <ng-template #cell let-dataItem="dataItem">
                    {{ dataItem.jobTitle }}
                </ng-template>
            </portal-grid-column>

            <portal-grid-column
            *ifLdFeature="featureFlag.IrpWorkerPageChangeColumnOrder"
            title="Facilities"
            field="facilityId"
            [filterOptions]="facilityFilters$ | async"
            [sortable]="false">
            <ng-template #cell let-dataItem="dataItem">
                <span *ngIf="dataItem.facilities?.length == 1">{{ dataItem.facilities[0] }}</span>
                <span *ngIf="dataItem.facilities?.length > 1 && !selectedFacilityName"><a (click)="onFacilityClick(dataItem.name, dataItem.facilities)">{{ dataItem.facilities.length }} facilities</a></span>
                <span *ngIf="dataItem.facilities?.length > 1 && selectedFacilityName"><a (click)="onFacilityClick(dataItem.name, dataItem.facilities)">{{ selectedFacilityName }} + {{ dataItem.facilities.length - 1 }} more</a></span>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column
                *ifLdFeature="featureFlag.IrpWorkerPageChangeColumnOrder"
                title="Professions"
                field="professionId"
                filterType="combobox"
                [filterOptions]="professions$ | async"
                defaultText="All">
                <ng-template #cell let-dataItem="dataItem">
                    {{ dataItem.profession }}
                </ng-template>
            </portal-grid-column>

        <portal-grid-column *ngIf="!(removeGridFieldsForNyu$ | async)"
            title="Specialties"
            field="expertiseId"
            filterType="combobox"
            [sortable]="false"
            [width]="250"
            [filterOptions]="specialties$ | async"
            defaultText="All">
            <ng-template #cell let-dataItem="dataItem">
                <span *ngIf="dataItem.expertises?.length == 1">{{ dataItem.expertises[0] }}</span>
                <span *ngIf="dataItem.expertises?.length > 1 && !selectedSpecialtyName"><a (click)="onSpecialtyClick(dataItem.name, dataItem.expertises)">{{ dataItem.expertises.length }} specialties</a></span>
                <span *ngIf="dataItem.expertises?.length > 1 && selectedSpecialtyName"><a (click)="onSpecialtyClick(dataItem.name, dataItem.expertises)">{{ selectedSpecialtyName }} + {{ dataItem.expertises.length - 1 }} more</a></span>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column
            title="Status"
            hidden="true"
            field="status"
            filterType="dropdown"
            [filterOptions]="statuses$ | async"
            [sortable]="true"
            defaultText="All"
            width="150">
            <ng-template #cell let-dataItem="dataItem">
                {{ dataItem.status }}
            </ng-template>
        </portal-grid-column>

        <portal-grid-column
            title="Preferred Shifts"
            field="preferredShifts"
            [sortable]="true">
            <ng-template #cell let-dataItem="dataItem">
                <div *ngFor="let shift of dataItem.formattedShiftTimePreferences"
                    class="text-nowrap">
                    {{shift}}
                </div>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column *hasCustomSystemField="'PreferredLocation'; operation 'NOT'"
            title="Preferred Location"
            field="facilityId"
            filterType="dropdown"
            [defaultText]="'All units' | terminology"
            [filterOptions]="facilities$ | async"
            [sortable]="false">
            <ng-template #cell let-dataItem="dataItem">
                <span *ngIf="dataItem.preferredFacilities?.length == 1">{{ dataItem.preferredFacilities[0] }}</span>
                <span *ngIf="dataItem.preferredFacilities?.length > 1 && !selectedFacilityName"><a (click)="onFacilityClick(dataItem.name, dataItem.preferredFacilities)">{{ dataItem.preferredFacilities.length }} facilities</a></span>
                <span *ngIf="dataItem.preferredFacilities?.length > 1 && selectedFacilityName"><a (click)="onFacilityClick(dataItem.name, dataItem.preferredFacilities)">{{ selectedFacilityName }} + {{ dataItem.preferredFacilities.length - 1 }} more</a></span>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column
            *hasCustomSystemField="'PreferredLocation'"
            title="Preferred Location"
            field="customPreferredLocationIds"
            filterType="dropdown"
            defaultText="All"
            [filterOptions]="preferredLocationLookups$ | async"
            [sortable]="true">
            <ng-template #cell let-dataItem="dataItem" >

                <div *ngIf="customFieldsLookup$
                    | async
                    | customFieldFind: 'PreferredLocation'
                    | customFieldIncludes: dataItem.customFields as preferredLocations">
                    <span *ngIf="preferredLocations.length == 1">
                        {{ preferredLocations[0].name }}
                    </span>

                    <span *ngIf="preferredLocations.length > 1 && !selectedCustomPreferredLocation">
                        <a (click)="onPreferedLocationsClick(dataItem.name, preferredLocations)">
                            {{ preferredLocations.length }} preferred locations
                        </a>
                    </span>
                    <span *ngIf="preferredLocations.length > 1 && selectedCustomPreferredLocation">
                        <a (click)="onPreferedLocationsClick(dataItem.name, preferredLocations)">
                            {{ selectedCustomPreferredLocation }} + {{ preferredLocations.length - 1 }} more
                        </a>
                    </span>
                </div>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column
            *ifLdFeature="featureFlag.IrpWorkerPageChangeColumnOrder; enabled: false"
            title="Hourly Rate"
            field="hourlyRate"
            filterType="numeric"
            [sortable]="true">
            <ng-template #cell let-dataItem="dataItem">
                <span *ngIf="dataItem.hourlyJobPayRate">
                    {{ dataItem.hourlyJobPayRate | currency }}/hr
                </span>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column
            title="Languages"
            *ifLdFeature="featureFlag.IrpWorkerPageChangeColumnOrder; enabled: false"
            field="languageIds"
            filterType="dropdown"
            defaultText="All Languages"
            [filterOptions]="languages$ | async"
            [sortable]="true">
            <ng-template #cell let-dataItem="dataItem" class="text-nowrap">
                <div *ngFor="let language of dataItem.formattedLanguageSkills">
                    {{language}}
                </div>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column
            *hasCustomSystemField="'epicSkills'"
            title="Epic"
            field="epicSkillIds"
            filterType="dropdown"
            defaultText="All"
            [filterOptions]="epicSkillLookups$ | async"
            [sortable]="true">
            <ng-template #cell let-dataItem="dataItem">
                {{ dataItem.epicSkills }}
            </ng-template>
        </portal-grid-column>

        <ng-container>
            <ng-container *ngIf="canArchiveResource">
                <portal-grid-column
                    *ngIf="isArchiveFilter$ | async"
                    title="Actions">
                    <ng-template #cell let-dataItem="dataItem">
                        <button mat-button
                            class="link-button"
                            click-stop-propagation
                            color="accent"
                            (click)="onUnarchive(dataItem)">
                            Unarchive
                        </button>
                    </ng-template>
                </portal-grid-column>
            </ng-container>
        </ng-container>

        </portal-grid>
    </div>
</ayac-widget>
