import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { LoadingTypes } from 'src/app/shared/models';

export const selectFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectVendorProfileCertifications = createSelector(
    selectFeature,
    (state) => state.vendorProfileCertificationsState.certifications
);

export const selectVendorProfileCertificationsIsLoading = createSelector(
    selectFeature,
    (state) => state.vendorProfileCertificationsState.loading === LoadingTypes.LOADING
);

export const selectVendorProfileCertificationsLoadingSave = createSelector(
    selectFeature,
    (state) => state.vendorProfileCertificationsState.loadingSave
);

export const selectVendorProfileMetadata = createSelector(
    selectFeature,
    (state) => state.vendorProfileCertificationsState.metadata
);
