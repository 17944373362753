    <div class="ma-3" *ngIf="{ submitSave: submitSave$ | async } as submitSave">
        <form [formGroup]="form"
              (ngSubmit)="form.valid ? saveSubject$.next(true) : null">
            <mat-toolbar class="h-auto pa-0 mt-1 mb-2"
                         color="transparent">
                <button mat-flat-button
                        color="accent"
                        class="mb-2"
                        (click)="back($event)">
                    <mat-icon fontSet="fas"
                              fontIcon="fa-arrow-left"
                              class="mt-2"></mat-icon><span>Back</span>
                </button>
            </mat-toolbar>
            <hr>
            <ayac-portal-page-header data-qa-id="ac_admin_custom_field_details_header_container"
                                     title="Custom Field Details">
                <ng-template #pageHeaderToolbar>
                    <button mat-flat-button
                            color="accent"
                            type="submit"
                            data-qa-id="ac_admin_custom_field_details_save_button"
                            class="migrated-icon-button"
                            [disabled]="isSaving || form.invalid"
                            [style.margin-right]="'15px'">
                            <mat-icon class="fa fa-save"  [class.spinner]="isSaving"></mat-icon>
                            Save
                    </button>
                </ng-template>
            </ayac-portal-page-header>
            <mat-card>
                <mat-card-content>
                    <div class="form-section">
                        <div>
                            <h3>Field Configuration
                                <ng-container *ngIf="hasValues$ | async">&nbsp;<small><em>has module values*</em></small></ng-container>
                            </h3>
                            <formly-form [form]="form"
                                         [fields]="fields"
                                         [model]="model"
                                         [options]="options"></formly-form>
                        </div>

                        <div>
                            <h3>Model</h3>
                            <pre> {{ model | json }}</pre>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </form>
    </div>
