import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { HospitalAssociationVendorsGridItem } from 'src/app/facilities/models/hospital-association-vendors-grid-item.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { State } from 'src/app/shared/grid/models/state.model';

export const getInitialGridState = (): GridSearchQuery => ({
    filter: { logic: 'and', filters: [] },
    sort: [{ field: 'vendorName', dir: SortTypes.ASC }],
    skip: 0,
    take: 10
});

export const setQueryTake = (take: number): GridSearchQuery => ({
    filter: { logic: 'and', filters: [] },
    sort: [{ field: 'vendorName', dir: SortTypes.ASC }],
    skip: 0,
    take: take
});

export interface FacilityAssociationVendorsState {
    vendors: HospitalAssociationVendorsGridItem[];
    total: number;
    filtered: number;
    loadingState: LoadingTypes;
    gridSearchQuery: GridSearchQuery;
    states: State[];
    error: string;
}

export const getInitialFacilityAssociationVendorsState = (): FacilityAssociationVendorsState => ({
    vendors: [],
    total: 0,
    filtered: 0,
    loadingState: LoadingTypes.INIT,
    gridSearchQuery: getInitialGridState(),
    states: [],
    error: ''
});
