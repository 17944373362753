import { createAction, props } from '@ngrx/store';
import {
    FeatureSettingCategory,
    FeatureSettingItem
} from '../../../shared/feature-settings/models/feature-features.models';

export const loadFacilitySettings = createAction(
    '[Facility Details] Load Facility Settings',
    props<{ facilityId: number }>()
);

export const loadFacilitySettingsSuccess = createAction(
    '[Facility Details] Load Facility Settings Success',
    props<{ settings: FeatureSettingCategory[] }>()
);

export const loadFacilitySettingsFailed = createAction(
    '[Facility Details] Load Facility Settings Failed',
    props<{ error: any }>()
);

export const updateFacilitySettings = createAction(
    '[Facility Details] Update Facility Settings',
    props<{ facilityId: number; setting: FeatureSettingItem; reload?: boolean }>()
);

export const updateFacilitySettingsSuccess = createAction('[Facility Details] Update Facility Settings Success');

export const updateFacilitySettingsFailed = createAction(
    '[Facility Details] Update Facility Settings Failed',
    props<{ error: any }>()
);

export const clearFacilitySettings = createAction(
    '[Facility Details] Clear Facility Settings',
    props<{ error: any }>()
);
