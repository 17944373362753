import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Observable, of, Subscription, timer } from 'rxjs';
import { catchError, map, share, switchMap, takeWhile, tap } from 'rxjs/operators';

import { VERSION_POLL_INTERVAL } from 'src/app/config/settings';
import { ENV, Environment } from 'src/environments/environment.provider';

export interface AppVersion {
    major: number;
    minor: number;
    build: string | number;
    revision?: number;
}

/**
 * Transforms a string in the form of `#.#.#-####` to the AppVersion interface.
 *
 * Example: `parseAppVersion(1.2.3-4567)`
 *
 * output: `{major: 1, minor:2, build:3, revision:4567}`
 */
export function parseAppVersion(appVer: string): AppVersion {
    const v = appVer.split('.');
    const major = +v[0];
    const minor = +v[1];
    const buildRev = v[2].split('-');
    const revision = +buildRev[0];
    // handle default version number without revision `1.0.0`
    const build = buildRev.length > 1 ? +buildRev[1] : 0;

    return {
        major,
        minor,
        revision,
        build
    };
}

/**
 * Formats the appVersion parts to a typical version format `#.#.#-####`
 * @returns string
 */
export function formatAppVersion(appVersion: AppVersion): string {
    return `${appVersion.major}.${appVersion.minor}.${appVersion.revision}-${appVersion.build}`;
}

/**
 * Given the `environment` file has an `appVersion` property setup to extract the
 * `version` property of `package.json`, this will perform an http get every 5
 * minutes for the `app-version.json` currently on the server hosting the app
 * and compare to the version in the `package.json` file cached in the browser
 */
@Injectable({
    providedIn: 'root'
})
export class VersionCheckerService {
    newVersion = false;
    currentAppVersion: AppVersion = undefined;
    subscriptions: Subscription = Subscription.EMPTY;
    readonly timedVersionPoll = timer(this.versionPollInterval, this.versionPollInterval).pipe(
        switchMap(() => this.getAndNotifyOnNew()),
        // Prevent multiple timers making the same call
        share()
    );

    constructor(
        @Inject(ENV) private readonly env: Environment,
        @Inject(VERSION_POLL_INTERVAL) readonly versionPollInterval: number,
        private readonly http: HttpClient,
        private readonly ngZone: NgZone
    ) {
        this.currentAppVersion = this.env.appVersion;
    }

    public startPollingVersion() {
        this.timedVersionPoll.subscribe();
    }

    private getAndNotifyOnNew(): Observable<{ newVersion: boolean; appVer: AppVersion }> {
        return this.getAndCompareVersion().pipe(
            tap(({ appVer }) => {
                if (this.newVersion) {
                    this.showNewVersionNotice(appVer);
                }
            })
        );
    }

    private getAndCompareVersion(): Observable<{ newVersion: boolean; appVer: AppVersion }> {
        return of(this.currentAppVersion).pipe(
            takeWhile(() => !this.newVersion),
            switchMap((currentAppVersion) =>
                this.getAppVersion().pipe(catchError((_) => of(new HttpResponse({ body: currentAppVersion }))))
            ),
            map((response: HttpResponse<AppVersion>) => response.body),
            map((appVer: AppVersion) => {
                this.newVersion = JSON.stringify(this.currentAppVersion) !== JSON.stringify(appVer);
                return { newVersion: this.newVersion, appVer };
            })
        );
    }

    private showNewVersionNotice(appVer: AppVersion) {
        // eslint-disable-next-line no-console
        console.info(
            `current version: ${JSON.stringify(this.currentAppVersion)}, new version available: ${JSON.stringify(appVer)}`
        );
    }

    private getAppVersion(): Observable<HttpEvent<AppVersion>> {
        const options: any = {
            observe: 'response',
            responseType: 'json',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        };
        return this.ngZone.run(() => {
            return this.http.get<AppVersion>('/app-version.json', options);
        });
    }
}
