import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IDialogOptions } from 'src/app/shared/models/dialog.models';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import * as submittalsActions from 'src/app/submittals/store/submittals.actions';
import * as submittalsSelectors from 'src/app/submittals/store/selectors';
import {
    OfferStatus,
    SubmittalOfferDetails,
    SubmittalOfferNote,
    SubmittalOfferNoteAddRequest
} from 'src/app/shared/models/submittals';
import { AddOfferNoteDialogComponent } from 'src/app/submittals/components/submittal-details/add-offer-note-dialog/add-offer-note-dialog.component';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

const offerStatusesEligibleToAddComments = [
    OfferStatus.Pending,
    OfferStatus.ChangesRequested,
    OfferStatus.VendorReview,
    OfferStatus.RecruiterReview,
    OfferStatus.Accepted
];

@Component({
    selector: 'ayac-submittal-offer-notes',
    templateUrl: './submittal-offer-notes.component.html',
    styleUrls: ['./submittal-offer-notes.component.scss']
})
export class SubmittalOfferNotesComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() disableAddComment: boolean;
    @Input() facilityDirectApplyEnabled: boolean;
    offerNotes$: Observable<SubmittalOfferNote[]>;
    hasEligibleStatusToAddComments = false;
    offer$ = new BehaviorSubject<SubmittalOfferDetails>({ offerStatusId: 0 } as SubmittalOfferDetails);
    featureFlag = FeatureFlag;
    private _offer: SubmittalOfferDetails;

    constructor(
        private readonly _dialogService: DialogService,
        private readonly _store: Store,
        protected changeDetectorRef: ChangeDetectorRef,
        private readonly _featureManager: LDFeatureManager
    ) {
        super();
    }

    get canAddComment(): boolean {
        return !this.disableAddComment && this.hasEligibleStatusToAddComments;
    }

    get offer(): SubmittalOfferDetails {
        return this._offer;
    }

    @Input()
    set offer(value: SubmittalOfferDetails) {
        this._offer = value;
        this.offer$.next(value);
    }

    ngOnInit(): void {
        this.offerNotes$ = this._store.select(submittalsSelectors.selectSubmittalOfferNotes);
        this._store.dispatch(
            submittalsActions.loadOfferNotes({ clientSubmittalId: this.offer.submittalId, offerId: this.offer.offerId })
        );
        this.offer$.pipe(takeUntil(this.d$)).subscribe((offer) => {
            this.hasEligibleStatusToAddComments = offerStatusesEligibleToAddComments.includes(offer.offerStatusId);
        });
    }

    addOfferNote(): void {
        this._dialogService
            .openDialog(AddOfferNoteDialogComponent, {
                width: '500px',
                data: {
                    facilityDirectApplyEnabled: this.facilityDirectApplyEnabled
                }
            } as IDialogOptions<any>)
            .afterClosed()
            .subscribe((result: { note: string }) => {
                if (result) {
                    const request: SubmittalOfferNoteAddRequest = { ...result, offerId: this.offer.offerId };
                    this._store.dispatch(
                        submittalsActions.addOfferNote({ clientSubmittalId: this.offer.submittalId, request })
                    );
                }
            });
    }
}
