import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subscription, timer } from 'rxjs';
import { PowerBiSettings } from 'src/app/shared/models/powerbi/powerbi-settings.model';
import { Store } from '@ngrx/store';
import { filter, map, pairwise, takeUntil } from 'rxjs/operators';
import { Report, Page, IEmbedConfiguration, models, service as pbiService, factories } from 'powerbi-client';
import { IFilter, FilterType, IBasicFilter, IReportBookmark } from 'powerbi-models';
import * as selectors from 'src/app/powerbi/store/powerbi-report.selectors';
import * as actions from 'src/app/powerbi/store/powerbi-report.actions';
import { PowerBiAccessConfig } from 'src/app/shared/models/powerbi/powerbi-access-config.model';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { Location } from '@angular/common';
import { ToasterService } from 'src/app/core/services';
import { PowerBiExportRequest } from 'src/app/shared/models/powerbi/powerbi-export-request.model';
import { PowerBiExportStatus } from 'src/app/shared/models/powerbi/powerbi-export-status.model';
import { PowerBiExportState } from 'src/app/shared/models/powerbi/powerbi-export-state.enum';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { PowerbiExportModalComponent } from './powerbi-export-modal/powerbi-export-modal.component';
import { IDialogOptions } from 'src/app/shared/models/dialog.models';
import { PowerBiExportFormModel } from 'src/app/shared/models/powerbi/powerbi-export-form.model';
import { ListItem } from 'src/app/shared/models/list-item';
import { PowerBISecuritySettings } from 'src/app/shared/models/powerbi/powerbi-security-settings.model';
import { LDFeatureManager } from '../shared/feature-management/ld-feature-manager';
import { FeatureFlag } from '../shared/models/enums/feature-flag.enum';
import { ReportActionType } from '../reports/models/enums/report-actions-type.enum';
import { PowerBiReportActionConfirmationModalComponent } from './powerbi-action-confirm-modal/powerbi-action-confirm-modal.component';
import { PendoEventConnectReportPageChanged } from '../core/models/pendo/pendo-events';
import * as coreActions from 'src/app/core/state/actions';
import { ReportListItem } from '../reports/models';
import { ReportsRepositoryService } from '../reports/services/reports-repository.service';
@Component({
    selector: 'ayac-powerbi-report',
    templateUrl: './powerbi-report.component.html',
    styleUrls: ['./powerbi-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PowerBiReportComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild('pbi_container', { static: true }) pbiContainerElement: ElementRef;

    reportId: number;
    reportPage: string = '';
    reportPageCode: string = '';
    canEdit: boolean;
    pbSettings: PowerBiSettings = {} as PowerBiSettings;
    isDefaultsLoading: boolean;

    isLoading$: Observable<boolean>;
    isSettingsSaving$: Observable<boolean>;
    canSaveFilters$: Observable<boolean>;
    canExport$: Observable<boolean>;
    accessConfig$: Observable<PowerBiAccessConfig>;
    accessConfigError$: Observable<string>;
    settings$: Observable<PowerBiSettings>;
    reportExportStatus$: Observable<PowerBiExportStatus>;
    reportExportPercentComplete$: Observable<number>;
    reportExportIsRunning$: Observable<boolean>;
    reportExportIsLoading$: Observable<boolean>;
    showReportExportProgress$: Observable<boolean>;
    exportButtonTooltip$: Observable<string>;
    reportExportStatusPollingTimer$: Observable<number>;

    supportedExportFileFormats: ListItem[] = [];

    reportRefreshTimer$: Observable<number>;
    reportBookmark: IReportBookmark;
    reportRefreshTimerSubscription: Subscription;
    connectNewReportDesign = false;
    featureFlags = FeatureFlag;
    reports: ReportListItem[] = [];
    reportName = '';

    private report: Report;
    private powerBiCoreService: pbiService.Service;

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly location: Location,
        private readonly identityService: IdentityService,
        private readonly toasterService: ToasterService,
        private readonly dialogService: DialogService,
        private readonly reportsRepositoryService: ReportsRepositoryService,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
        this.powerBiCoreService = new pbiService.Service(
            factories.hpmFactory,
            factories.wpmpFactory,
            factories.routerFactory
        );
    }

    ngOnInit(): void {
        this.reportsRepositoryService.getReportList().subscribe((reports) => {
            this.reports = reports;
            if (this.reportId) {
                this.setReportName(this.reports.find((i) => i.reportId === this.reportId)?.reportName);
            }
        });

        this.setReportId(+this.route.snapshot.paramMap.get('code'));
        this.isLoading$ = this.store.select(selectors.selectIsLoading);
        this.isSettingsSaving$ = this.store.select(selectors.selectIsSettingsSaving);
        this.accessConfig$ = this.store.select(selectors.selectAccessConfig);
        this.settings$ = this.store.select(selectors.selectSettings);

        this.canExport$ = this.accessConfig$.pipe(
            filter((config) => config !== null),
            map((config) => config.canExport)
        );
        this.exportButtonTooltip$ = this.canExport$.pipe(
            map((val) => (!val ? 'Export is not available for this report' : ''))
        );

        this.accessConfig$
            .pipe(
                filter((config) => config !== null),
                takeUntil(this.d$)
            )
            .subscribe((config) => {
                this.supportedExportFileFormats = config.supportedExportFileFormats;
                if (config.accessTokenExpiration) {
                    const expiresOn = new Date(config.accessTokenExpiration);
                    expiresOn.setMinutes(expiresOn.getMinutes() - 5);

                    if (this.reportRefreshTimerSubscription) {
                        this.reportRefreshTimerSubscription.unsubscribe();
                    }

                    this.reportRefreshTimer$ = timer(expiresOn);
                    this.reportRefreshTimerSubscription = this.reportRefreshTimer$
                        .pipe(takeUntil(this.d$))
                        .subscribe(async (t) => {
                            if (this.report) {
                                if (this.connectNewReportDesign) {
                                    this.powerBiCoreService?.reset(this.pbiContainerElement.nativeElement);
                                } else {
                                    this.reportBookmark = await this.report.bookmarksManager.capture();
                                }
                                this.store.dispatch(
                                    actions.loadPowerBiReportAccessConfig({ id: this.reportId, isRefresh: true })
                                );
                            }
                        });
                }
            });

        this.accessConfig$
            .pipe(
                filter((config) => config !== null),
                map((config) => this.getEmbedConfig(config)),
                takeUntil(this.d$)
            )
            .subscribe((config) => {
                if (this.connectNewReportDesign) {
                    this.report = this.powerBiCoreService.embed(
                        this.pbiContainerElement.nativeElement,
                        config
                    ) as Report;
                } else {
                    if (this.report) {
                        this.report.config = config;
                        this.report.reload();
                    } else {
                        this.report = this.powerBiCoreService.embed(
                            this.pbiContainerElement.nativeElement,
                            config
                        ) as Report;
                    }
                }
                this.onEmbedded();
            });
        this.accessConfigError$ = this.accessConfig$.pipe(
            filter((config) => config !== null),
            map((config) => config.error)
        );

        this.canSaveFilters$ = this.accessConfig$.pipe(map((config) => config !== null && config.canSaveFilters));

        this.store
            .select(selectors.selectIsSettingsSavedSuccessfully)
            .pipe(
                takeUntil(this.d$),
                filter((isSaved) => isSaved)
            )
            .subscribe(() => this.loadSettings());

        combineLatest([this.settings$, this.store.select(selectors.selectIsSettingsLoaded)])
            .pipe(
                takeUntil(this.d$),
                filter(([settings, isLoaded]) => isLoaded),
                map(([settings, isLoaded]) => settings)
            )
            .subscribe((settings) => {
                this.isDefaultsLoading = false;
                this.applySettings(settings);
            });

        this.reportExportIsLoading$ = this.store.select(selectors.selectPowerBiReportExportIsLoading);
        this.reportExportStatus$ = this.store.select(selectors.selectPowerBiReportExportStatus);
        this.reportExportIsRunning$ = this.reportExportStatus$.pipe(map((status) => this.getIsExportRunning(status)));
        this.reportExportPercentComplete$ = this.reportExportStatus$.pipe(
            filter((status) => status !== null),
            map((status) => status.percentComplete)
        );
        this.reportExportStatusPollingTimer$ = timer(1000, 5000);

        this.showReportExportProgress$ = combineLatest([this.reportExportIsLoading$, this.reportExportIsRunning$]).pipe(
            map(([isLoading, isRunning]) => isLoading || isRunning)
        );

        this.reportExportStatus$
            .pipe(
                takeUntil(this.d$),
                filter((status) => status !== null)
            )
            .subscribe((status) => {
                switch (status.exportStatus) {
                    case PowerBiExportState.Succeeded:
                        this.store.dispatch(
                            actions.downloadPowerBiReportFile({ id: this.reportId, exportId: status.exportId })
                        );
                        break;
                    case PowerBiExportState.Failed:
                        this.toasterService.fail('Report export failed!');
                        break;
                    default:
                        break;
                }
            });

        combineLatest([
            this.reportExportStatusPollingTimer$,
            this.reportExportIsLoading$,
            this.reportExportIsRunning$,
            this.reportExportStatus$
        ])
            .pipe(takeUntil(this.d$))
            .pipe(
                pairwise(),
                filter(([[prevTimer], [curTimer, curIsLoading]]) => prevTimer !== curTimer && !curIsLoading)
            )
            .subscribe(([[, , ,], [, , curIsRunning, curStatus]]) => {
                if (curIsRunning && curStatus && curStatus.exportId && this.getIsExportRunning(curStatus)) {
                    this.getExportToFileStatus(curStatus.exportId);
                }
            });

        if (this.reportId > 0) {
            this.store.dispatch(actions.loadPowerBiReportAccessConfig({ id: this.reportId }));
        }

        combineLatest([this.ldFeatureManager.isEnabled(this.featureFlags.ConnectReportingRedesign), this.route.url])
            .pipe(takeUntil(this.d$))
            .subscribe(([flag, [url]]) => {
                this.connectNewReportDesign = flag;
                const isShowingClientReports = this.doShowClientReports();

                if (flag && isShowingClientReports && !url?.path?.includes('detail')) {
                    this.router.navigate(['/client', 'reports', 'powerbi', 'detail', this.reportId]);
                }
            });

        this.route.params.pipe(takeUntil(this.d$)).subscribe(() => {
            if (this.connectNewReportDesign) {
                this.onReportTabChanged();
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.store.dispatch(actions.clearPowerBiReport());
    }

    getIsExportRunning(status: PowerBiExportStatus): boolean {
        return (
            !!status &&
            !!status.exportStatus &&
            [PowerBiExportState.Running, PowerBiExportState.NotStarted].some((x) => x === status.exportStatus)
        );
    }

    save() {
        return this.grabSettings(this.report, this.pbSettings).then(
            () =>
                this.store.dispatch(
                    actions.savePowerBiReportSettings({
                        id: this.reportId,
                        page: this.reportPage,
                        settings: this.pbSettings
                    })
                ),
            () => {
                this.toasterService.fail('Failed to save settings!');
            }
        );
    }

    setDefaultSettings() {
        this.isDefaultsLoading = true;
        this.report.reload();
        this.store.dispatch(actions.clearPowerBiReportSettings({ id: this.reportId }));
    }

    grabSettings(report: Report, pbSettings: PowerBiSettings): Promise<any[]> {
        if (report) {
            pbSettings.slicerStates = [];
            const promiceSlicer = report.getPages().then((pages) => {
                const pagePromises = [];
                //Apply only for active page
                pages
                    .filter((page) => page.isActive)
                    .forEach((page) => {
                        const pagePromise = page.getVisuals().then((visuals) => {
                            const visualPromises = [];
                            visuals
                                .filter((visual) => visual.type === 'slicer')
                                .forEach((visual) => {
                                    const visualPromise = visual.getSlicerState().then((state) => {
                                        pbSettings.slicerStates.push(state);
                                    });
                                    visualPromises.push(visualPromise);
                                });
                            return Promise.all(visualPromises);
                        });
                        pagePromises.push(pagePromise);
                    });
                return Promise.all(pagePromises);
            });
            return promiceSlicer;
        }

        return Promise.resolve([]);
    }

    applySettings(settings: PowerBiSettings): Promise<any[]> {
        if (settings !== null) {
            this.pbSettings = settings;
        } else {
            this.pbSettings = { slicerStates: [] } as PowerBiSettings;
        }

        if (this.pbSettings.slicerStates?.length) {
            return this.report.getPages().then((pages) => {
                const pagePromises = [];
                //Apply only for active page
                pages
                    .filter((page) => page.isActive)
                    .forEach((page) => {
                        const pagePromise = page.getVisuals().then((visuals) => {
                            const visualPromises = [];
                            visuals
                                .filter((visual) => visual.type === 'slicer')
                                .forEach((visual) => {
                                    // @ts-ignore
                                    const visualPromise = visual.getSlicerState().then((state) => {
                                        const slicerStates = this.pbSettings.slicerStates;
                                        const slicer = slicerStates.filter(
                                            (st) =>
                                                this.getSlicerStateShortName(st) === this.getSlicerStateShortName(state)
                                        )[0];
                                        if (slicer) {
                                            return visual.setSlicerState(slicer) as any;
                                        }

                                        return Promise.resolve();
                                    });
                                    visualPromises.push(visualPromise);
                                });
                            return Promise.all(visualPromises);
                        });
                        pagePromises.push(pagePromise);
                    });
                return Promise.all(pagePromises);
                // eslint-disable-next-line @typescript-eslint/indent
            });
        }

        return Promise.resolve([]);
    }

    getSlicerStateShortName(slicerState) {
        return slicerState.targets.map((cv) => `${cv.column}/${cv.table}`)[0];
    }

    onEmbedded() {
        if (this.report) {
            this.report.off('loaded');
            this.report.off('pageChanged');
            this.subscribeReportLoaded();
            this.subscribeReportPageChanged();
            this.logEvents();
        }
    }

    subscribeReportLoaded() {
        this.report.on('loaded', (event) => {
            this.displayOnConsole('loaded', event);

            if (this.isDefaultsLoading) {
                this.report.setPage(this.reportPageCode);
            } else {
                this.report.getPages().then((pages) => {
                    const page = pages.find((page) => page.isActive);
                    this.reportPage = page ? page.displayName : '';
                    this.reportPageCode = page ? page.name : '';
                    if (this.reportPage && this.reportPage.length) {
                        this.loadSettings();
                    }
                });
            }

            this.restoreReportBookmark();
        });
    }

    subscribeReportPageChanged() {
        this.report.on<{ newPage: Page }>('pageChanged', (event) => {
            if (this.isDefaultsLoading) {
                return;
            }
            this.reportPage = event.detail.newPage.displayName;
            this.reportPageCode = event.detail.newPage.name;
            if (this.reportPage && this.reportPage.length) {
                this.loadSettings();
            }

            const page = event.detail.newPage.displayName;
            const evt = new PendoEventConnectReportPageChanged({});
            evt.name = `CC-${this.reportName}-${page}`.replace(/ /g, '');

            this.store.dispatch(coreActions.trackPendo({ event: evt }));
        });
    }

    getReportsUrl() {
        if (this.doShowClientReports()) {
            return '/client/reports';
        } else if (this.identityService.isAdminLogin()) {
            return '/admin/reports';
        } else if (this.identityService.isVendorLogin()) {
            return '/vendor/reports';
        }
        return null;
    }

    doShowClientReports(): boolean {
        return this.identityService.isClientLogin() || this.identityService.isSystemLogin();
    }

    goBackToReportPage() {
        const reportsUrl = this.getReportsUrl();
        if (reportsUrl) {
            this.router.navigateByUrl(reportsUrl);
        } else {
            this.location.back();
        }
    }

    async exportToFile(reportName: string = null) {
        const exportModel = (await this.dialogService
            .openDialog(PowerbiExportModalComponent, {
                width: this.connectNewReportDesign ? '500px' : '400px',
                data: { supportedExportFormats: this.supportedExportFileFormats, reportName }
            } as IDialogOptions<any>)
            .asAwaitable()) as PowerBiExportFormModel;

        if (exportModel) {
            const requestModel = {
                fileFormat: exportModel.fileFormatId,
                includeHiddenPages: !exportModel.currentPageOnly
            } as PowerBiExportRequest;

            const capturedBookmark = await this.report.bookmarksManager.capture();
            if (capturedBookmark) {
                requestModel.bookmark = {
                    name: !exportModel.exportWithCurrentValues ? capturedBookmark.name : null,
                    state: exportModel.exportWithCurrentValues ? capturedBookmark.state : null
                };
            }

            // Retrieve the active page.
            const pages = await this.report.getPages();
            const activePage = pages.filter((page) => {
                return page.isActive;
            })[0];
            requestModel.activePage = activePage.name;

            this.store.dispatch(actions.exportPowerBiReport({ id: this.reportId, requestModel }));
        }
    }

    getExportToFileStatus(exportId: string) {
        this.store.dispatch(actions.getPowerBiReportExportStatus({ id: this.reportId, exportId }));
    }

    setReportName(reportName: string) {
        this.reportName = reportName;
    }

    onReportTabChanged() {
        const changedReportId = +this.route.snapshot.paramMap.get('code');

        if (this.reportId !== changedReportId) {
            this.setReportId(changedReportId);
            if (this.reportId > 0) {
                this.powerBiCoreService?.reset(this.pbiContainerElement.nativeElement);
                this.store.dispatch(actions.loadPowerBiReportAccessConfig({ id: this.reportId }));
            }
        }
    }

    confirmActionsDialog(actionType: ReportActionType, reportName: string) {
        this.dialogService
            .openDialog(PowerBiReportActionConfirmationModalComponent, {
                width: '500px',
                data: {
                    actionType,
                    reportName
                }
            })
            .afterClosed()
            .subscribe((applyAction) => {
                if (applyAction) {
                    switch (actionType) {
                        case ReportActionType.SaveSettings:
                            this.save();
                            break;
                        case ReportActionType.RestoreSettings:
                            this.setDefaultSettings();
                            break;
                    }
                }
            });
    }

    setReportId(reportId: number) {
        this.reportId = reportId;

        if (this.reports.length) {
            this.setReportName(this.reports.find((i) => i.reportId === this.reportId)?.reportName);
        }
    }

    private getEmbedConfig(accessSettings: PowerBiAccessConfig): IEmbedConfiguration {
        const authFilters = this.getAuthFilters(accessSettings);
        // Igor T. For some reason IEmbedConfiguration.datasetId property does not work
        // Implemented according to MSDN: https://docs.microsoft.com/en-us/power-bi/developer/embedded/embed-dynamic-binding
        const datasetBinding =
            accessSettings.dynamicDatasetId && accessSettings.dynamicDatasetId.length
                ? { datasetId: accessSettings.dynamicDatasetId }
                : null;

        const config = {
            type: 'report',
            id: accessSettings.reportId,
            embedUrl: accessSettings.embedUrl,
            accessToken: accessSettings.accessToken,
            tokenType: models.TokenType.Embed,
            permissions: models.Permissions.Read,
            settings: {
                filterPaneEnabled: accessSettings.showFilterPane,
                navContentPaneEnabled: accessSettings.showNavContentPane
            },
            // ---- Filtering data according to user permissions ---- //
            filters: authFilters,
            // ---- Adjustment required for dynamic binding ---- //
            datasetBinding: datasetBinding
        };

        return config;
    }

    private getAuthFilters(accessSettings: PowerBiAccessConfig): IFilter[] {
        let filters = [];
        if (accessSettings.security) {
            const userFilters = this.getUserFilters(accessSettings.security);
            const systemFilters = this.getSystemFilters(accessSettings.security);
            const facilityFilters = this.getFacilityFilters(accessSettings.security);
            const unitFilters = this.getUnitFilters(accessSettings.security);
            const vendorFilters = this.getVendorFilters(accessSettings.security);

            filters = filters
                .concat(userFilters)
                .concat(systemFilters)
                .concat(facilityFilters)
                .concat(unitFilters)
                .concat(vendorFilters);
        }

        return filters;
    }

    private getUserFilters(security: PowerBISecuritySettings): IBasicFilter[] {
        if (security.userFilters?.length) {
            const coreUserId = this.identityService.userIdentity.coreUserId;
            const filters = security.userFilters.map((filter) =>
                this.createBasicInFilter(filter.table, filter.column, [coreUserId])
            );
            return filters;
        }

        return [];
    }

    private getSystemFilters(security: PowerBISecuritySettings): IBasicFilter[] {
        if (security.systems?.length && security.systemFilters?.length) {
            const filters = security.systemFilters.map((filter) =>
                this.createBasicInFilter(filter.table, filter.column, security.systems)
            );
            return filters;
        }

        return [];
    }

    private getFacilityFilters(security: PowerBISecuritySettings): IBasicFilter[] {
        if (security.facilities?.length && security.facilityFilters?.length) {
            const filters = security.facilityFilters.map((filter) =>
                this.createBasicInFilter(filter.table, filter.column, security.facilities)
            );
            return filters;
        }

        return [];
    }

    private getUnitFilters(security: PowerBISecuritySettings): IBasicFilter[] {
        if ((security.units?.length || security.facilities?.length) && security.unitFilters?.length) {
            const unitIds = Array.from(security.units);
            if (security.facilities?.length) {
                // We need to show records that do not have unit specified if user has access to the facility
                unitIds.push(null);
            }
            const filters = security.unitFilters.map((filter) =>
                this.createBasicInFilter(filter.table, filter.column, unitIds)
            );
            return filters;
        }

        return [];
    }

    private getVendorFilters(security: PowerBISecuritySettings): IBasicFilter[] {
        if (security.vendors?.length && security.vendorFilters?.length) {
            const filters = security.vendorFilters.map((filter) =>
                this.createBasicInFilter(filter.table, filter.column, security.vendors)
            );
            return filters;
        }

        return [];
    }

    private loadSettings() {
        this.store.dispatch(actions.loadPowerBiReportSettings({ id: this.reportId, page: this.reportPage }));
    }

    private async restoreReportBookmark() {
        if (this.reportBookmark) {
            if (this.reportBookmark.state) {
                await this.report.bookmarksManager.applyState(this.reportBookmark.state);
            } else if (this.reportBookmark.name) {
                await this.report.bookmarksManager.apply(this.reportBookmark.name);
            }

            this.reportBookmark = null;
        }
    }

    private createBasicInFilter(targetTable: string, targetColumn: string, values: any[]) {
        return {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
                table: targetTable,
                column: targetColumn
            },
            operator: 'In',
            values: values,
            filterType: FilterType.Basic,
            displaySettings: {
                isHiddenInViewMode: true
            }
        } as IBasicFilter;
    }

    private logEvents() {
        const evts = [
            'saved',
            'rendered',
            'saveAsTriggered',
            'error',
            'dataSelected',
            'buttonClicked',
            'info',
            'filtersApplied',
            'pageChanged',
            'commandTriggered',
            'swipeStart',
            'swipeEnd',
            'bookmarkApplied',
            'dataHyperlinkClicked',
            'visualRendered',
            'visualClicked',
            'selectionChanged',
            'renderingStarted',
            'blur'
        ];

        for (const e of evts) {
            this.report.on(e, (event) => {
                this.displayOnConsole(e, event);
            });
        }
    }

    private displayOnConsole(name: string, event: CustomEvent<unknown>) {
        console.log(`event:${name}`, event);
    }
}
