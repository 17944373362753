import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function numberWithSpacesValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const valid =
        /^\d/.test(control.value) &&
        /\d$/.test(control.value) &&
        !/\s{2,}/.test(control.value) &&
        !/[^\d\s]/.test(control.value);

    return valid ? null : { number: { value: control.value } };
}

export function minValueValidator(minValueSelector: () => number): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
        const minValue = minValueSelector();

        if (typeof minValue !== typeof control.value || typeof control.value !== 'number') {
            return { invalidType: { minType: typeof minValue, valueType: typeof control.value } };
        }

        if (control.value < minValue) {
            return { min: { value: control.value } };
        }

        return null;
    };
}

export function maxValueValidator(maxValueSelector: () => number): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
        const maxValue = maxValueSelector();

        if (typeof maxValue !== typeof control.value || typeof control.value !== 'number') {
            return { invalidType: { maxType: typeof maxValue, valueType: typeof control.value } };
        }

        if (control.value > maxValue) {
            return { max: { value: control.value } };
        }

        return null;
    };
}
