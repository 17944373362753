<loading-indicator *ngIf="(isLoading$ | async) || (isSaving$ | async)"></loading-indicator>

<div class="internalpool-container main-internalpool">
        <ayac-widget [sticky]="true">
        <div widget-breadcrumb>
            <ayac-breadcrumb>
                <a routerLink="/client/internalpool">All Internal Pool</a>
                <span>&nbsp;>&nbsp;</span>
                <span>Internal Pool Details</span>
            </ayac-breadcrumb>
        </div>
        <div widget-header>
            <div class="spacer"></div>
            <ayac-portal-page-header
            [title]="title$ | async" [isOneRow]="true">
            <ng-template #pageHeaderTitle let-title="title">
                <span class="mr-3">{{title}}</span>
                <span class="badge" *ngIf="isArchived$ | async">Archived</span>
                <span class="badge accent-background"
                        *ngIf="resourceType$ | async as resouceType">
                        {{resouceType}}
                    </span>
            </ng-template>
            <ng-template #pageHeaderToolbar>
                <ng-container *ngIf="isEdit then editModeToolbar; else viewModeToolbar"></ng-container>
            </ng-template>

            <ng-template #viewModeToolbar>
                <ng-container *ngIf="!(isArchived$ | async); else viewModeArchivedToolbar">
                    <ng-container>
                        <ayac-archive-worker-button
                            *ngIf="canArchiveResource$ | async"
                            (click)="onArchive()"
                            [hasFullSystemAccess] ="(hasFullSystemAccess$ | async)">
                        </ayac-archive-worker-button>
                    </ng-container>
                    <button mat-flat-button color="accent"
                            *ngIf="canEditResource"
                            [disabled]="!(canEditResource$ | async)"
                            data-qa-id="internalpool-details-edit-profile-btn"
                            (click)="onEdit()"
                            [matTooltipDisabled]="canEditResource$ | async"
                            matTooltip="View only due to worker not associated with a facility you manage."
                            matTooltipPosition="below"
                            matTooltipClass="tooltip-shrunken">
                        Edit Profile
                    </button>
                </ng-container>
            </ng-template>

            <ng-template #editModeToolbar>
                <button mat-stroked-button color="accent" (click)='onCancel()'>
                    Cancel
                </button>
                <button mat-flat-button color="accent" (click)='onSave()'
                    [disabled]="(isSaving$ | async) || forms?.isInvalid || forms?.isPending || !canEditResource">
                    Save Profile
                </button>
            </ng-template>

            <ng-template #viewModeArchivedToolbar>
                <ng-container>
                    <ng-container *ngIf="(canArchiveResource$ | async) && (isArchived$ | async)"  >
                        <button mat-flat-button
                            color="accent"
                            (click)="onUnarchive()">
                            Unarchive Worker
                        </button>
                    </ng-container>
                </ng-container>
            </ng-template>

            </ayac-portal-page-header>
        </div>
        <div>
            <mat-tab-group
                [selectedIndex]="selectedTabIndex"
                (selectedIndexChange)="updateTabRoute($event)">
                <mat-tab label="Profile">
                    <div fxLayout="row" fxLayoutGap="24px" class="my-3 mx-3">
                        <div fxFlex="50" fxLayot="row" fxLayoutGap="16px">
                            <ayac-personal-details-section
                                [edit]="isEdit">
                            </ayac-personal-details-section>

                            <ayac-notes-section
                                [edit]="isEdit">
                            </ayac-notes-section>

                            <ayac-shifts-app-section
                                [edit]="isEdit"
                                [showButtonInvitation] = "canEditResource"
                                (resendEmailEvent)="resendEmailInvitation()">
                            </ayac-shifts-app-section>

                        </div>
                        <div fxFlex="50" fxLayot="row" fxLayoutGap="16px">
                            <ayac-professional-details-section
                                [edit]="isEdit">
                            </ayac-professional-details-section>

                            <ayac-documents-section
                                [edit]="isEdit">
                            </ayac-documents-section>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Assignments">
                    <div fxLayout="column" fxLayoutGap="24px" class="my-3 mx-3">
                        <ayac-assignments-section></ayac-assignments-section>

                        <ayac-assignments-previous-section></ayac-assignments-previous-section>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </ayac-widget>
</div>
