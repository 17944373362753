import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { travelersStoreKey } from './travelers.state';
import { travelersEffects } from './effects';
import { travelersReducer } from './reducers';

@NgModule({
    imports: [StoreModule.forFeature(travelersStoreKey, travelersReducer), EffectsModule.forFeature(travelersEffects)]
})
export class TravelersStoreModule {}
