<div class="share-form-container" data-qa-id="shareFormContainer">
  <div class="modal-header" data-qa-id="modalHeader">
    <h2 class="share-form-title" data-qa-id="shareFormTitle">{{ expiredLink || oldLink ? 'Link Expired' : 'Share Client Link' }}
    </h2>
    <button mat-icon-button class="close-button" (click)="close()" data-qa-id="closeButton">&times;</button>
  </div>
  <form [formGroup]="sendLinkForm" data-qa-id="sendLinkForm">
    <div class="modal-body" *ngIf="!expiredLink && !oldLink" data-qa-id="modalBody">
      <div class="frame" data-qa-id="frame">
        <div class="warning-banner" data-qa-id="warningBanner">
          <div class="content" data-qa-id="content">
            <span class="material-icons-outlined" data-qa-id="warningIcon">warning_amber</span>
            <div class="text" data-qa-id="warningText"> Only share link with specified client email.</div>
          </div>
        </div>
        <div class="input-block" data-qa-id="inputBlock">
          <mat-form-field appearance="outline" class="input-field" [class.input-readonly]="readOnly">
            <input matInput type="email" formControlName="email" placeholder="Client Email Address" required
              data-qa-id="clientEmailAddress" />
            <mat-error *ngIf="sendLinkForm.get('email').hasError('required')" data-qa-id="emailRequiredError">
              Client Email Address is required.
            </mat-error>
            <mat-error *ngIf="sendLinkForm.get('email').hasError('email')" data-qa-id="emailInvalidError">
              Enter a valid email address.
            </mat-error>
            <mat-error *ngIf="sendLinkForm.get('email').hasError('employee')" data-qa-id="expiredEmployeeEmailAyaEmployeeError">
                Aya employees must access through the Facility profile
            </mat-error>
            <div class="expiry-text" *ngIf="!expiredLink && !oldLink"
              data-qa-id="expirationDateText">
              Link expires in 14 days on {{ expirationDate }}
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="modal-body" *ngIf="expiredLink && !oldLink" data-qa-id="expiredModalBody">
      <div class="frame" data-qa-id="expiredFrame">
        <div class="warning-banner-expired" data-qa-id="warningBannerExpired">
            <div class="content" data-qa-id="content">
                <span class="material-icons-outlined" data-qa-id="warningIcon">info</span>
                <div class="text" data-qa-id="warningText">Link has expired. Send a new link to original recipient.</div>
            </div>
        </div>
        <div class="new-text" data-qa-id="newText">
            Original recipient will send you the new link when they approve your request.
        </div>
        <div class="input-block" data-qa-id="expiredInputBlock">
          <mat-form-field appearance="outline" class="input-field" [class.input-readonly]="readOnly">
            <input matInput type="email" formControlName="email" placeholder="Client Email Address" required
              data-qa-id="expiredClientEmailAddress" />
            <mat-error *ngIf="sendLinkForm.get('email').hasError('required')" data-qa-id="expiredEmailRequiredError">
              Email is required.
            </mat-error>
            <mat-error *ngIf="sendLinkForm.get('email').hasError('email')" data-qa-id="expiredEmailInvalidError">
              Enter a valid email address.
            </mat-error>
            <mat-error *ngIf="sendLinkForm.get('email').hasError('employee')" data-qa-id="expiredEmployeeEmailAyaEmployeeError">
                Aya employees must access through the Facility profile
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="modal-body" *ngIf="!expiredLink && oldLink" data-qa-id="expiredModalBody">
      <div class="frame" data-qa-id="expiredFrame">
        <div class="warning-banner-expired" data-qa-id="warningBannerOld">
            <div class="content" data-qa-id="content">
                <span class="material-icons-outlined" data-qa-id="warningIcon">info</span>
                <div class="text" data-qa-id="warningText">Link has expired. Request a new link from a member of your account team.</div>
            </div>
        </div>
        <div class="input-block" data-qa-id="expiredInputBlock">
          <mat-form-field appearance="outline" class="input-field" [class.input-readonly]="readOnly">
            <input matInput type="email" formControlName="email" placeholder="Client's Email Address" required
              data-qa-id="expiredClientEmailAddressOldLink" />
            <mat-error *ngIf="sendLinkForm.get('email').hasError('required')" data-qa-id="expiredEmailRequiredErrorOldLink">
              Email Address is required.
            </mat-error>
            <mat-error *ngIf="sendLinkForm.get('email').hasError('email')" data-qa-id="expiredEmailInvalidErrorOldLink">
              Please Enter a valid email address.
            </mat-error>
            <mat-error *ngIf="sendLinkForm.get('email').hasError('employee')" data-qa-id="expiredEmployeeEmailAyaEmployeeError">
                Aya employees must access through the Facility profile
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="button-container" *ngIf="!expiredLink && !oldLink" data-qa-id="buttonContainer">
      <button class="email-button" (click)="sendEmail()" [disabled]="!sendLinkForm.valid || !linkGenerated"
        data-qa-id="sendEmailButton">Email</button>
      <button class="mat-stroked-button" (click)="copyLink()" [disabled]="!sendLinkForm.valid || !linkGenerated"
        data-qa-id="copyLinkButton">Copy link</button>
    </div>
    <div class="button-container" *ngIf="expiredLink || oldLink" data-qa-id="expiredButtonContainer">
      <button class="email-button" (click)="requestNewLink()" [disabled]="!sendLinkForm.valid"
        data-qa-id="requestNewLinkButton">{{expiredLink ? 'Request' : 'Request New Link'}}</button>
    </div>
  </form>
</div>
