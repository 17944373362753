<dialog-layout [title]="data?.title">
    <ng-container mat-dialog-content *ngIf="data?.isHtml; then htmlContent; else textContent">
    </ng-container>
    
    <ng-template #htmlContent>
        <div [innerHtml]="data?.text">o</div>
    </ng-template>

    <ng-template #textContent>
        <div>
            {{data?.text}}
        </div>
    </ng-template>

    <div mat-dialog-actions>
        <button mat-flat-button [color]="confirmButtonColor" (click)="close()">Close</button>
    </div>
</dialog-layout>
