import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as internalPoolSelectors from '../store/selectors';
import * as internalPoolActions from '../store/actions';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'ayac-assignments-section',
    templateUrl: './assignments-section.component.html',
    styleUrls: ['./assignments-section.component.scss']
})
export class AssignmentsSectionComponent implements OnInit {
    data$: Observable<GridDataResult>;
    query$: Observable<GridSearchQuery>;
    loading$: Observable<boolean>;
    isNyu$: Observable<boolean>;

    constructor(
        private readonly store: Store<{}>,
        private readonly route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.data$ = this.store.select(internalPoolSelectors.selectCurrentAssignmentsGridData);
        this.query$ = this.store.select(internalPoolSelectors.selectCurrentAssignmentsQuery);
        this.loading$ = this.store.select(internalPoolSelectors.selectCurrentAssignmentsLoading);
        this.isNyu$ = this.store.select(internalPoolSelectors.selectIsNyuSystem);
    }

    onDataStateChange(query: GridStateChangeEvent) {
        this.store.dispatch(internalPoolActions.setCurrentAssignmentsQuery({ query }));
        this.store.dispatch(internalPoolActions.loadCurrentResourceAssignments({ id: this.resourceId }));
    }

    get resourceId() {
        return Number(this.route.snapshot.paramMap.get('id'));
    }
}
