import { Action, createReducer, on } from '@ngrx/store';
import {
    loadPendingJobs,
    loadPendingJobsSuccess,
    loadPendingJobsFail,
    loadPendingTimecards,
    loadPendingTimecardsSuccess,
    loadPendingTimecardsFail
} from '../actions';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';

export interface PendingTimecardsState {
    totalCount: number;
    agingCount: number;
    agingDays: number;
    loading: LoadingTypes;
    error: string;
}

export const initialPendingTimecardsState: PendingTimecardsState = {
    totalCount: 0,
    agingCount: 0,
    agingDays: 7,
    loading: LoadingTypes.INIT,
    error: ''
};

export function pendingTimecardsReducer(state: PendingTimecardsState, action: Action) {
    return createReducer(
        initialPendingTimecardsState,
        on(loadPendingTimecards, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(loadPendingTimecardsSuccess, (state, action) => ({
            ...state,
            totalCount: action.totalCount,
            agingCount: action.agingCount,
            loading: LoadingTypes.LOADED
        })),

        on(loadPendingTimecardsFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: PendingTimecardsState | undefined, action: Action) {
    return pendingTimecardsReducer(state, action);
}
