<div>
    <label class="k-form-field">
      <span>
        {{ to.label }}
        <ng-container *ngIf="field.props.required">
          <span class="k-required">*</span>
        </ng-container>
        <formly-help-text [helpText]="props.helpText"></formly-help-text>        
      </span>
      <span id="dropdown">
        <kendo-dropdownlist 
          class="formly-kendo-dropdown" 
          [formControl]="formControl" 
          [data]="to.options"
          valueField="value"
          textField="label">
        </kendo-dropdownlist>
        <validation-message
          class="validation-message"
          [label]="to.label" 
          [control]="formControl" 
          [useConstantCustomMessage]="false">
        </validation-message>
      </span>
    </label>
</div>  
