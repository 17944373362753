import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as internalPoolSelectors from '../store/selectors';
import {FeatureFlag} from "../../shared/models/enums/feature-flag.enum";
import {LDFeatureManager} from "../../shared/feature-management/ld-feature-manager";

@Component({
    selector: 'ayac-archive-worker-button',
    templateUrl: './archive-worker-button.component.html',
    styleUrls: ['./archive-worker-button.component.scss']
})
export class ArchiveWorkerButtonComponent implements OnInit {
    hasAssignment$: Observable<boolean>;
    disabledReason$: Observable<string>;
    assignmentsLoading$: Observable<boolean>;
    canArchive$: Observable<boolean>;
    disabledText =
        'This clinician is assigned to shift(s). Please cancel/reassign shift(s) before you Archive the worker';
    loadingText = 'Assignments loading';
    limitedSystemPermissionText = 'Cannot archive due to limited System permissions';
    private limitToFullSystemUserFlag$: Observable<boolean>;

    @Input('hasFullSystemAccess') hasFullSystemAccess = true;
    @Output('click') click = new EventEmitter();

    featureFlag= FeatureFlag;

    constructor(
        private readonly store: Store,
        private readonly ldFeatureManager: LDFeatureManager
    ) { }

    ngOnInit(): void {
        this.hasAssignment$ = this.store.select(internalPoolSelectors.selectHasCurrentAssignment);
        this.assignmentsLoading$ = this.store.select(internalPoolSelectors.selectCurrentAssignmentsLoading);

        this.limitToFullSystemUserFlag$ = this.ldFeatureManager.isEnabled(FeatureFlag.LimitArchiveWorkerToFullSystemUser);
        this.disabledReason$ = combineLatest([
            this.hasAssignment$,
            this.assignmentsLoading$,
            this.limitToFullSystemUserFlag$
        ]).pipe(
            map(([hasAssignment, loading, ffEnabled]) => {
                if (loading) {
                    return this.loadingText;
                }
                if (hasAssignment) {
                    return this.disabledText;
                }
                if(ffEnabled && !this.hasFullSystemAccess){
                    return this.limitedSystemPermissionText;
                }
                return null;
            })
        );

        this.canArchive$ = combineLatest([
            this.hasAssignment$,
            this.assignmentsLoading$,
            this.limitToFullSystemUserFlag$
        ]).pipe(
            map(([hasAssignment, loading, limitToFullSystemUserFlag]) => !loading && !hasAssignment && (!limitToFullSystemUserFlag || this.hasFullSystemAccess)) //Add observable to the combine
        )
    }

    onClick() {
        this.click.emit();
    }
}
