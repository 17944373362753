import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileAttachment, FileAttachmentStyle } from '../../models/attachment';
import { FileAttachmentsPreviewComponent } from '../file-attachments-preview/file-attachments-preview.component';

@Component({
    selector: 'ayac-file-attachments-list',
    templateUrl: './file-attachments-list.component.html',
    styleUrls: ['./file-attachments-list.component.scss']
})
export class FileAttachmentsListComponent {
    constructor() {}
    @ViewChild(FileAttachmentsPreviewComponent, { static: true })
    public previewComponent: FileAttachmentsPreviewComponent;
    fileAttachmentStyle = FileAttachmentStyle;
    @Output() preview = new EventEmitter<FileAttachment>();
    @Input() attachments: FileAttachment[];
    @Input('style') listStyle: FileAttachmentStyle = FileAttachmentStyle.TitleAndList;
    @Input() editMode: boolean;
    @Input() hiddenTitle: boolean;

    attachmentToPreview(file: FileAttachment): void {
        this.preview.emit(file);
    }
}
