<mat-card>
    <mat-card-header>
        <mat-card-title>Personal Details</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="content"
        *ngIf="editMode; then formTemplate; else viewTemplate">
    </mat-card-content>


    <ng-template #viewTemplate>
        <ng-container *ngIf="resource$ | async as resource">
        <div class="k-form-inline">
            <div class="k-form-field k-form-field-static">
                <span><strong>First Name:</strong></span>
                <div>{{ resource.firstName }}</div>
            </div>
            <div class="k-form-field k-form-field-static">
                <span><strong>Middle Name:</strong></span>
                <div>{{ resource.middleName }}</div>
            </div>
            <div class="k-form-field k-form-field-static">
                <span><strong>Last Name:</strong></span>
                <div>{{ resource.lastName }}</div>
            </div>
            <div class="k-form-field k-form-field-static"
                *hasSystemFields="['internalPool.employeeId'];">
                <span><strong>Employee ID:</strong></span>
                <div>{{ resource.employeeId }}</div>
            </div>
            <div class="k-form-field k-form-field-static"
                *hasSystemFields="['internalPool.kerberosId'];">
                <span><strong>Kerberos ID:</strong></span>
                <div>{{ resource.kerberosId }}</div>
            </div>
            <div class="k-form-field k-form-field-static"
                *hasSystemFields="['internalPool.ssn'];">
                <span><strong>SSN:</strong></span>
                <div>{{ resource.ssn }}</div>
            </div>
            <div class="k-form-field k-form-field-static"
                *hasSystemFields="['internalPool.dob'];">
                <span><strong>DOB:</strong></span>
                <div>{{ resource.dob }}</div>
            </div>
            <div class="k-form-field k-form-field-static">
                <span><strong>Original Hire Date:</strong></span>
                <div>{{ resource.hireDate | date : 'MM/dd/yyyy'}}</div>
            </div>
            <div class="k-form-field k-form-field-static">
                <span><strong>Transfer Date:</strong></span>
                <div>{{ resource.transferDate | date : 'MM/dd/yyyy'}}</div>
            </div>
            <div class="k-form-field k-form-field-static">
                <span><strong>Phone:</strong></span>
                <div>{{ resource.phone }}</div>
            </div>
            <div class="k-form-field k-form-field-static">
                <span><strong>Email:</strong></span>
                <div>
                    <a [attr.href]="'mailto:' + resource.email">{{ resource.email }}</a>
                </div>
            </div>
            <div class="k-form-field k-form-field-static">
                <span><strong>Related Persons:</strong></span>

                <div>
                    <p *ngFor="let person of resource.relatedPersons">
                        {{person.name}}
                        <br>
                        {{person.title}} | {{person.department}}
                    </p>
                </div>
            </div>
        </div>
        </ng-container>
    </ng-template>

    <ng-template #formTemplate>
        <ng-container *ngIf="updateForm$ | async"></ng-container>

        <form [formGroup]="form" class="k-form-inline">
            <div class="k-form-field">
                <label>
                    First Name
                    <span class="k-required">*</span>
                </label>
                <input kendoTextBox formControlName="firstName" />
                <validation-message
                    label="First Name"
                    [control]="form.get('firstName')">
                </validation-message>
            </div>

            <div class="k-form-field">
                <label>
                    Middle Name
                </label>
                <input kendoTextBox formControlName="middleName" />
                <validation-message
                    label="Middle Name"
                    [control]="form.get('middleName')">
                </validation-message>
            </div>

            <div class="k-form-field">
                <label>
                    Last Name
                    <span class="k-required">*</span>
                </label>
                <input kendoTextBox formControlName="lastName" />
                <validation-message
                    label="Last Name"
                    [control]="form.get('lastName')">
                </validation-message>
            </div>

            <div class="k-form-field" *hasSystemFields="['internalPool.employeeId'];">
                <label>
                    Employee ID
                    <span class="k-required" *ngIf="!(removeNyuRequiredFields$ | async)">*</span>
                </label>
                <input kendoTextBox formControlName="employeeId" class="form-field-control form-control-long" disabled/>
                <validation-message label="Employee ID" [control]="form.get('employeeId')">
                </validation-message>
            </div>

            <div class="k-form-field" *hasSystemFields="['internalPool.kerberosId'];">
                <label>
                    Kerberos ID
                    <span class="k-required" *ngIf="!(removeNyuRequiredFields$ | async)">*</span>
                </label>
                <input kendoTextBox formControlName="kerberosId" class="form-field-control form-control-long" />
                <validation-message label="Kerberos ID" [control]="form.get('kerberosId')">
                </validation-message>
            </div>

            <div class="k-form-field"
                *hasSystemFields="['internalPool.ssn'];">
                <label>
                    SSN
                </label>
                <kendo-maskedtextbox type="number" formControlName="ssn" [mask]="ssnMask"
                    class="form-field-control form-control-long">
                </kendo-maskedtextbox>
                <validation-message label="SSN" [control]="form.get('ssn')">
                </validation-message>
            </div>

            <div class="k-form-field"
                *hasSystemFields="['internalPool.dob'];">
                <label>
                    DOB
                    <span class="k-required">*</span>
                </label>
                <input
                    formControlName="dob"
                    [matDatepicker]="dobDatepicker"
                    [min]="minDob"
                    class="form-field-control form-control-long k-textbox"
                    />
                <mat-datepicker-toggle [for]="dobDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #dobDatepicker color="accent"></mat-datepicker>
                <validation-message label="Date" [control]="form.get('dob')">
                </validation-message>
            </div>

            <div class="k-form-field">
                <label>
                    Original Hire Date
                </label>
                <kendo-datepicker formControlName="hireDate"></kendo-datepicker>
            </div>

            <div class="k-form-field">
                <label>
                    Transfer Date
                </label>
                <kendo-datepicker formControlName="transferDate"></kendo-datepicker>
            </div>


            <div class="k-form-field">
                <label>
                    Phone
                    <span class="k-required" *ngIf="isRequired('phone')">*</span>
                </label>

                <kendo-maskedtextbox formControlName="phone" [mask]="phoneMask">
                </kendo-maskedtextbox>
                <validation-message label="Phone" [control]="form.get('phone')">
                </validation-message>
            </div>

            <div class="k-form-field">
                <label>
                    Email
                    <span class="k-required">*</span>
                </label>

                <input kendoTextBox formControlName="email" class="form-field-control form-control-long" />
                <validation-message label="Email" [control]="form.get('email')">
                </validation-message>
            </div>


            <div>
                <label class="k-form-field">
                    Related Persons
                </label>
                <div class="ml-4">
                    <div formArrayName="relatedPersons"
                        *ngFor="let item of relatedPersons.controls; let i = index;"
                        fxLayout="row" fxLayoutAlign="space-between center"
                        [class.mt-4]="i > 0">
                        <div [formGroupName]="i" fxFlex>
                            <div class="k-form-field">
                                <label>
                                    Person {{i + 1}} Name
                                </label>
                                <input kendoTextBox formControlName="name" />
                            </div>
                            <div fxLayout="row" fxLayoutGap="16px">
                                <div class="k-form-field mb-0" fxFlex="50">
                                    <label>
                                        Relationship
                                    </label>
                                    <input kendoTextBox formControlName="title" />
                                </div>
                                <div class="k-form-field mb-0" fxFlex="50">
                                    <label>
                                        Dept.
                                    </label>
                                    <input kendoTextBox formControlName="department" />
                                </div>
                            </div>
                        </div>

                        <button mat-icon-button
                            (click)="onRemoveRelatedPerson(i)">
                            <mat-icon>delete</mat-icon>
                        </button>

                    </div>

                    <button class="mt-4" mat-button color="accent" (click)="onAddRelatedPerson()">
                        <mat-icon>add</mat-icon>
                        Add Related Person
                    </button>
                </div>
            </div>
        </form>
    </ng-template>
</mat-card>
