import { createFeatureSelector } from '@ngrx/store';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { AppState } from 'src/app/store/state/App.state';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

export const adminFeatureKey = 'admin';

export const getSelectorValue = async <TResult>(observable: Observable<TResult>): Promise<TResult> =>
    observable.pipe(first()).toPromise();

export const selectFeature = createFeatureSelector<AdminState>(adminFeatureKey);
