<ng-container *ngIf="adminJobLookups$ | async as jobLookups">
    <div class="top-toolbar">
        <span>
            <strong>Release Job Positions for</strong>
        </span>

        <div>
            <mat-form-field class="admin-jobs-select" appearance="outline" floatLabel="never">
                <mat-select #facilityGroupSelect (selectionChange)="facilityChanged($event)">
                    <mat-option>
                        Select a Facility...
                    </mat-option>
                    <mat-option *ngFor="let group of jobLookups.facilityGroups | sortBy: ['name']" [value]="group.id">
                        {{ group.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button data-qa-id="AdminJobsGridReleaseAllPositionsButton" mat-flat-button (click)="releaseJobPositions()" [class.warning]="!facilityGroupSelect?.value" [attr.title]="!facilityGroupSelect?.value ? 'Select a facility to enable feature' : ''" [matBadge]="numberOfGridSelectedRows"
                [disabled]="!facilityGroupSelect?.value || !jobLookups.canRelease || numberOfGridSelectedRows === 0 || !canSelectedRowsAllRelease" color="accent">
                Release All Positions
            </button>
        </div>

        <div>
            <button mat-flat-button color="accent" (click)="refresh()" data-qa-id="adminJobsRefreshButton">
                <mat-icon>refresh</mat-icon>
                Refresh
            </button>

            <button mat-flat-button color="accent" [matMenuTriggerFor]="exportMenu" data-qa-id="adminJobsExportButton">
                <mat-icon>arrow_downward</mat-icon>
                Export
            </button>

            <mat-menu #exportMenu="matMenu">
                <button mat-menu-item (click)="export()">Export All</button>
                <button mat-menu-item class="admin-jobs-item" (click)="exportSelected()"
                    *ngIf="numberOfGridSelectedRows > 0" [matBadge]="numberOfGridSelectedRows" matBadgeSize="small"
                    matBadgePosition="above after">Export
                    Selected</button>
            </mat-menu>
        </div>
    </div>

    <portal-grid #adminJobsGrid class="portal-grid-scroll-auto highlighted-portal-grid" data-title="Jobs"
        [selectable]="true" [selectableMode]="'multiple'" [loading]="isLoading$ | async"
        [data]="{ data: adminJobs$ | async, total: adminJobsTotal$ | async }" [query]="adminJobsQuery$ | async"
        (dataStateChange)="onDataStateChange($event)">

        <ng-template #header let-title="title" let-total="total">
            <div class="title">
                {{ title }} (Total Jobs: {{ total }} | Open Positions: {{ adminOpenJobs$ | async }} | Total Positions:
                {{ adminAllJobs$ | async }})
            </div>
        </ng-template>

        <portal-grid-column field="releaseStatusId" [filterOptions]="releaseFilterOptions" filterType="dropdown"
            textField="text" defaultText="-" [hidden]="!jobLookups.canRelease" width="55">
            <ng-template #columnHeaderTemplate>
                <i class="fa fa-lock" style="color:blue" aria-hidden="true"></i>
            </ng-template>

            <ng-template #cell let-dataItem="dataItem">
                <i title="{{dataItem.releaseStatusName}} [{{ (dataItem | releaseProgress:jobLookups?.facilityGroups).percentage }}%]"
                    class="fa text-{{ (dataItem | releaseProgress:jobLookups?.facilityGroups).class}}"
                    [class.fa-lock]="dataItem.releaseStatusId === 1"
                    [class.fa-unlock]="dataItem.releaseStatusId === 3"
                    aria-hidden="true"></i>
            </ng-template>

            <ng-template #dropdownListTemplate let-dataItem="dataItem">
                <i [title]="dataItem.text" class="fa {{ dataItem.image }}"
                    [ngClass]="{'lock': dataItem.id === 1, 'unlock': dataItem.id === 3}" aria-hidden="true">&nbsp;{{
                    dataItem.text }}</i>
            </ng-template>

            <ng-template #dropdownListValueTemplate let-dataItem="dataItem">
                <i [title]="dataItem.text" class="fa {{ dataItem.image }}"
                    [ngClass]="{'lock': dataItem.id === 1, 'unlock': dataItem.id === 3}" aria-hidden="true"></i>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column title="Job ID" field="id" filterType="identifier">
            <ng-template #cell let-dataItem="dataItem">
                <a [routerLink]="['/admin/job/', dataItem.id]">{{ dataItem.id }}</a>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column title=" " field="hideFromVendors" [sortable]="false" [filterable]="false">
            <ng-template #cell let-dataItem="dataItem">
                <i *ngIf="dataItem.hideFromVendors" class="fa fa-eye-slash" aria-hidden="true"></i>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column title=" " field="isAyaPriority" [sortable]="false" [filterable]="false">
            <ng-template #cell let-dataItem="dataItem">
                <i *ngIf="dataItem.isAyaPriority" class="fa fa-arrow-up" aria-hidden="true"></i>
            </ng-template>
        </portal-grid-column>

        <portal-grid-column title="Facility" field="facility" filterType="text">
        </portal-grid-column>

        <portal-grid-column title="City" field="city" filterType="text">
        </portal-grid-column>

        <portal-grid-column title="State" field="state" filterType="text">
        </portal-grid-column>

        <portal-grid-column title="Profession" field="profession" filterType="text">
        </portal-grid-column>

        <portal-grid-column title="Job Type" field="jobType" filterType="text">
        </portal-grid-column>

        <portal-grid-column title="Employment" field="employment" filterType="text">
            <ng-template #cell let-dataItem="dataItem">
                {{ dataItem.employment === 'TravelOrContract' ? dataItem.employment.replace('Or', '/') :
                dataItem.employment }}
            </ng-template>
        </portal-grid-column>

        <portal-grid-column title="Specialty" field="specialty" filterType="text">
        </portal-grid-column>

        <portal-grid-column title="Contract Group" field="contractGroup" filterType="text">
        </portal-grid-column>

        <portal-grid-column title="Shift" field="shiftName" sortField="shiftDuration" [filterable]="false">
            <ng-template #cell let-dataItem="dataItem">
                {{ dataItem.shiftName }}
            </ng-template>
        </portal-grid-column>

        <portal-grid-column title="Open Positions" field="positions" [filterable]="false" [sortable]="false">
        </portal-grid-column>

        <portal-grid-column title="Vendor Rate" field="vendorRate" [filterable]="false">
            <ng-template #cell let-dataItem="dataItem">
                {{ dataItem.vendorRate | currency }}{{payFormat(dataItem?.payPeriodTypeId)}}
            </ng-template>
        </portal-grid-column>

        <portal-grid-column title="Start Date" field="startDate" filterType="date" width="100" [style]="{'width': '15%'}"
        [headerStyle]="{'width': '15%'}">
            <ng-template #cell let-dataItem="dataItem">
                {{ dataItem.startDate | date:'MM/dd/YYYY'}}
            </ng-template>
        </portal-grid-column>

        <portal-grid-column title="Approved Date" field="approvalDate" [filterable]="false">
            <ng-template #cell let-dataItem="dataItem">
                {{ dataItem.approvalDate | date:'MM/dd/YYYY'}}
            </ng-template>
        </portal-grid-column>

        <portal-grid-column title="VMS" field="vms" filterType="text">
        </portal-grid-column>
    </portal-grid>
</ng-container>
