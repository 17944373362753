import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { travelersStoreKey, TravelersState } from './travelers.state';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { hasFileName, fileIsMissing } from 'src/app/core/utils';
import { MspFieldsVisibility } from 'src/app/travelers/travelers.models';
import { StaffingAdvisoryRestrictionLevel } from '../models/staffing-advisory-restriction-level.enum';
import { ClientTravelerStatus } from 'src/app/travelers/models/enums/client-traveler-status.enum';
import { ComplianceManagementTypeEnum } from '../models/enums/compliance-management-type.enum';

export const getTravelerFeatureState = createFeatureSelector<TravelersState>(travelersStoreKey);

export const enum LoadingState {
    INIT = 'INIT',
    LOADING = 'LOADING',
    LOADED = 'LOADED'
}

export const getError = createSelector(getTravelerFeatureState, (state) => state.list.error);

export const selectLookups = createSelector(getTravelerFeatureState, (state: TravelersState) => state.list.lookups);

export const selectTravelers = createSelector(getTravelerFeatureState, (state: TravelersState) => state.list.travelers);

export const selectGridDataQuery = createSelector(getTravelerFeatureState, (state: TravelersState) => {
    if (state.list.travelersQuery.filter) {
        state.list.travelersQuery.filter.filters = state.list.travelersQuery.filter.filters.map((f) => {
            if (f['field'] === 'searchFacilityIds') {
                return { ...f, field: 'facilityId' };
            } else if (f['field'] === 'searchExpertiseIds') {
                return { ...f, field: 'expertiseName' };
            } else if (f['field'] === 'searchProfessionIds') {
                return { ...f, field: 'professionName' };
            } else {
                return f;
            }
        });
    }

    return state.list.travelersQuery;
});

export const selectIsTravelersGridLoading = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) =>
        state.list.travelersLoadStatus === LoadingState.LOADING ||
        state.list.travelersLookupLoadStatus === LoadingState.LOADING
);

export const selectTraveler = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.traveler
);

export const selectIsClientManagedTravelerByDocumentDetails = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.documentDetails?.travelerDetails?.hasComplianceRulesConfigured
);

export const selectTravelerHireDate = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.traveler.sclHireDate
);

export const selectSupervisors = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.sclSupervisors
);

export const selectDepartment = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.sclDepartment
);

export const selectDepartments = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.sclDepartments
);

export const selectHasMultipleDepartments = createSelector(selectDepartments, (departments) => departments.length > 1);

export const selectCanSave = createSelector(getTravelerFeatureState, (state: TravelersState) => state.details.canSave);

export const selectPayrollLocationLookups = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.payrollLocationLookups
);

export const selectBuildingLookups = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.buildingLookups
);

export const selectFloorLookups = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.floorLookups
);

export const selectDepartmentLookups = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.departmentLookups
);

export const selectBusinessUnitLookups = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.businessUnitLookups
);

export const selectTravelerIsLoading = createSelector(getTravelerFeatureState, (state: TravelersState) =>
    [state.details.detailsLoading, state.details.jobsLoading, state.details.lookupsLoading].some(
        (loading) => loading === LoadingTypes.LOADING
    )
);

export const selectTravelerJobsAreLoading = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.jobsLoading === LoadingTypes.LOADING
);

export const selectTravelerJobs = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.details.travelerJobs
);

export const selectTravelerJobsView = createSelector(getTravelerFeatureState, (state: TravelersState) => ({
    data: state.details.travelerJobs,
    total: state.details.totalTravelerJobs
}));

export const selectTravelerJobsViewFiltered = createSelector(getTravelerFeatureState, (state: TravelersState) => ({
    data: state.details.filteredTravelerJobs,
    total: state.details.totalTravelerJobs
}));

export const selectTravelerDetails = createSelector(getTravelerFeatureState, (state) => state.details);

export const selectMspFieldsVisibility = createSelector(
    selectTravelerDetails,
    (state): MspFieldsVisibility => ({
        showBjcFields: state.showBjcFields,
        showPrismaFields: state.showPrismaFields
    })
);

export const selectFacilityFilterLookups = createSelector(getTravelerFeatureState, (state: TravelersState) => {
    const facilities =
        state.list.lookups === null
            ? []
            : state.list.lookups.facilities.map((f) => ({
                  id: f.id,
                  name: f.name
              }));
    facilities.sort((a, b) => (a.name > b.name ? 1 : -1));
    return facilities;
});

export const selectExpertiseFilterLookups = createSelector(getTravelerFeatureState, (state: TravelersState) => {
    const expertises = state.list.lookups === null ? [] : state.list.lookups.expertises;
    expertises.sort((a, b) => (a.name > b.name ? 1 : -1));
    return expertises;
});

export const selectProfessionFilterLookups = createSelector(getTravelerFeatureState, (state: TravelersState) => {
    const professions = state.list.lookups === null ? [] : state.list.lookups.professions;
    professions.sort((a, b) => (a.name > b.name ? 1 : -1));
    return professions;
});

export const selectTravelerName = createSelector(getTravelerFeatureState, (state: TravelersState) =>
    state.details.traveler != null ? state.details.traveler.travelerName : ''
);

export const selectStatusFilterLookups = createSelector(getTravelerFeatureState, (state: TravelersState) => {
    return state.list.lookups === null ? [] : state.list.lookups.statuses;
});

export const selectContractTypesFilerLookups = createSelector(getTravelerFeatureState, (state: TravelersState) => {
    return state.list.lookups === null ? [] : state.list.lookups.contractTypes;
});

export const selectUnitFilterLookups = createSelector(getTravelerFeatureState, (state: TravelersState) => {
    const units = state.list.lookups === null ? [] : state.list.lookups.units;
    units.sort((a, b) => (a.name > b.name ? 1 : -1));
    return units;
});

export const selectEvaluationStatusFilterLookups = createSelector(getTravelerFeatureState, (state: TravelersState) => {
    const evaluationStatuses = state.list.lookups === null ? [] : state.list.lookups.evaluationStatuses;
    return evaluationStatuses;
});

export const selectSystemFilterLookups = createSelector(getTravelerFeatureState, (state: TravelersState) => {
    return state.list.lookups === null ? [] : state.list.lookups.systems;
});

export const selectFilteredDepartment = pipe(
    select(selectDepartment),
    filter((department) => department != null)
);

export const selectFilteredTravelerJobs = pipe(
    select(selectTravelerJobs),
    filter((jobs) => jobs != null)
);

// Documents
export const selectTravelerDocuments = createSelector(
    getTravelerFeatureState,
    (state) => state.documents.travelerDocuments
);

export const selectFilteredTravelerDocuments = createSelector(
    getTravelerFeatureState,
    (state) => state.documents.filteredTravelerDocuments
);

export const selectTotalTravelerDocuments = createSelector(
    getTravelerFeatureState,
    (state) => state.documents.totalTravelerDocuments
);

export const selectFilteredDocuments = pipe(
    select(selectTravelerDocuments),
    filter((documents) => documents != null)
);

export const selectDocumentsIsLoading = createSelector(
    getTravelerFeatureState,
    (state) => state.documents.loading === LoadingTypes.LOADING
);

export const selectDocumentsViewFiltered = createSelector(
    selectFilteredTravelerDocuments,
    selectTotalTravelerDocuments,
    (filteredTravelerDocuments, totalTravelerDocuments) => ({
        data: filteredTravelerDocuments,
        total: totalTravelerDocuments
    })
);

export const selectTravelerHasDocuments = createSelector(
    getTravelerFeatureState,
    (state) => state.documents.travelerDocuments.length > 0
);

export const selectTravelerHasSelectableDocuments = createSelector(
    getTravelerFeatureState,
    (state) => state.documents.travelerDocuments.filter(hasFileName).length > 0
);

export const selectAnyDocumentsSelected = createSelector(
    getTravelerFeatureState,
    (state) => state.documents.selectedTravelerDocumentIds.length > 0
);

export const selectMissingDocuments = createSelector(
    getTravelerFeatureState,
    (state) => state.documents.travelerDocuments.filter(fileIsMissing).length
);

export const selectTotalDocuments = createSelector(
    getTravelerFeatureState,
    (state) => state.documents.travelerDocuments.length
);

export const selectMissingDocumentsPercent = createSelector(
    selectTotalDocuments,
    selectMissingDocuments,
    (total: number, missing: number) => (total > 0 ? Math.round((missing * 100) / total) : 0)
);

export const selectGridState = createSelector(getTravelerFeatureState, (state) => state.documents.gridState);

// Document Details
export const selectTravelerDocumentDetails = createSelector(
    getTravelerFeatureState,
    (state) => state.documentDetails.travelerDetails
);

export const selectCanRevertClearedStatus = createSelector(
    getTravelerFeatureState,
    (state) => state.documentDetails.travelerDetails?.clientTravelerStatusId === ClientTravelerStatus.PreStartCleared
);

export const selectCanSetClearedStatus = createSelector(
    getTravelerFeatureState,
    (state) =>
        state.documentDetails.travelerDetails?.clientTravelerStatusId === ClientTravelerStatus.PreStartNotCleared ||
        state.documentDetails.travelerDetails?.status === 'Pre-Start (Not Cleared)'
);

export const selectDocumentMetadata = createSelector(
    getTravelerFeatureState,
    (state) => state.documentDetails.documentMetadata
);

export const selectIsDocumentDetailsLoading = createSelector(
    getTravelerFeatureState,
    (state) => state.documentDetails.loading === LoadingTypes.LOADING
);

export const selectCurrentContractId = createSelector(
    getTravelerFeatureState,
    (state) => state.documentDetails.contractId
);

export const selectCurrentDocumentId = createSelector(
    getTravelerFeatureState,
    (state) => state.documentDetails.documentId
);

// Work Order Confirmation
export const selectWorkOrderConfirmationPDF = createSelector(
    getTravelerFeatureState,
    (state) => state.workOrderConfirmation.wocImage
);

// Extension
export const selectIsExtensionRequested = createSelector(
    getTravelerFeatureState,
    (state) => state.details.traveler.isExtensionRequested
);

export const selectIsExtensionEligible = createSelector(
    getTravelerFeatureState,
    (state) => state.details.traveler.isExtensionEligible
);

export const selectExtensionLogo = createSelector(getTravelerFeatureState, (state) => state.extensionLogo.logo);

export const selectExtensionDetails = createSelector(
    getTravelerFeatureState,
    (state) => state.extensionDetails.extension
);

export const selectExtensionDetailsIsLoading = createSelector(getTravelerFeatureState, (state) =>
    [state.extensionDetails.loading, state.extensionPostpone.loading].some((x) => x === LoadingTypes.LOADING)
);

export const selectExtensionCommandIsSuccess = createSelector(
    getTravelerFeatureState,
    (state) => state.extensionPostpone.loading === LoadingTypes.LOADED
);

export const selectExtensionPostponeResult = createSelector(
    getTravelerFeatureState,
    (state) => state.extensionPostpone.postponeResult
);

export const selectExtensionJobId = createSelector(getTravelerFeatureState, (state) => state.createExtension.jobId);

export const selectClientSubmittalId = createSelector(
    getTravelerFeatureState,
    (state) => state.createExtension.clientSubmittalId
);

// Staffing Advisory
export const selectStaffingAdvisory = createSelector(
    getTravelerFeatureState,
    (state) => state.staffingAdvisory.staffingAdvisories
);

export const selectStaffingAdvisoryIsSaved = createSelector(
    getTravelerFeatureState,
    (state) => state.staffingAdvisoryEdit.loading === LoadingTypes.LOADED
);

export const selectTravelerStaffingAdvisoryIsLoading = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) =>
        [
            state.details.detailsLoading,
            state.list.travelersLookupLoadStatus,
            state.staffingAdvisory.loading,
            state.staffingAdvisoryEdit.loading,
            state.staffingAdvisoryAttachments.loading,
            state.staffingAdvisoryLookups.loading,
            state.staffingAdvisoryDelete.loading
        ].some((x) => x === LoadingTypes.LOADING)
);

export const selectStaffingAdvisoryIsAttachmentPreviewLoading = createSelector(
    getTravelerFeatureState,
    (state) => state.staffingAdvisoryAttachmentsPreview.loading === LoadingTypes.LOADING
);

export const selectTravelerStaffingAdvisoryLookups = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => {
        const allowedRestrictionLevels = [
            StaffingAdvisoryRestrictionLevel.System,
            StaffingAdvisoryRestrictionLevel.Facility,
            StaffingAdvisoryRestrictionLevel.Unit
        ];

        return {
            categories: state.staffingAdvisoryLookups.categories,
            reasons: state.staffingAdvisoryLookups.reasons,
            restrictionLevels: state.staffingAdvisoryLookups.restrictionLevels.filter((val, ind, arr) =>
                allowedRestrictionLevels.some((level) => val.id === level)
            )
        };
    }
);

/* Staffing Advisory Attachments */
export const selectSaveStaffingAdvisoryAttachment = createSelector(
    getTravelerFeatureState,
    (state) => state.staffingAdvisoryAttachments.fileValidationResults
);

export const selectStaffingAdvisoryAttachments = createSelector(getTravelerFeatureState, (state) =>
    state.staffingAdvisoryAttachments.attachments.filter((a) => !a.isDeleted)
);

export const selectAttachmentPreview = createSelector(getTravelerFeatureState, (state) => {
    return {
        blob: state.staffingAdvisoryAttachmentsPreview.staffingAdvisoryPreviewAttachments,
        totalPages: state.staffingAdvisoryAttachmentsPreview.staffingAdvisoryPreviewTotalPage,
        fileName: state.staffingAdvisoryAttachmentsPreview.staffingAdvisoryPreviewFileName
    };
});

export const selectCurrentAttachment = createSelector(
    getTravelerFeatureState,
    (state) => state.staffingAdvisoryAttachmentsPreview.currentFile
);

/* Staffing Advisory Security Lookup */
export const selectStaffingAdvisorySystemLookup = createSelector(
    getTravelerFeatureState,
    (state) => state.staffingAdvisorySecurityLookups.system
);

export const selectStaffingAdvisorySystemsLookup = createSelector(
    getTravelerFeatureState,
    (state) => state.staffingAdvisorySecurityLookups.systems
);

export const selectStaffingAdvisoryFacilityLookup = createSelector(
    getTravelerFeatureState,
    (state) => state.staffingAdvisorySecurityLookups.facilities
);

export const selectStaffingAdvisoryUnitLookup = createSelector(
    getTravelerFeatureState,
    (state) => state.staffingAdvisorySecurityLookups.units
);

/* Create Extension */
export const selectTravelerCreateExtensionIsLoading = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.createExtension.loading === LoadingTypes.LOADING
);

/* Import Travelers custom fields */
export const selectImportCustomFieldsFeature = createSelector(
    getTravelerFeatureState,
    (state) => state.importCustomFields
);

export const selectImportCustomFieldsErrorMessages = createSelector(
    selectImportCustomFieldsFeature,
    (state) => state.errors
);

export const selectImportCustomFieldsStatus = createSelector(
    selectImportCustomFieldsFeature,
    (state) => state.importing
);

/* VMS document history */
export const selectDocumentHistory = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.documentHistory.travelerDocumentHistory
);

export const selectDocumentHistoryLoading = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.documentHistory.loading === LoadingTypes.LOADING
);

export const selectContractId = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.documentDetails.contractId
);

/* Extension Offer */
export const selectExtensionOfferFacilitySettingsIsLoading = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.extensionOffer.settingsLoading === LoadingTypes.LOADING
);

export const selectExtensionOfferFacilitySettings = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.extensionOffer.settings
);

export const selectCandidateDeclinedDocumentStatus = createSelector(
    getTravelerFeatureState,
    (state: TravelersState) => state.documents.clientDocumentStatus
);
