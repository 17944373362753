<loading-indicator *ngIf="isLoading"></loading-indicator>
<ayac-contact-toolbar [hasChanges]="hasChanges"
                      [disableSaveAndCancel]="!hasChanges || !isFormValid"
                      [qaId]="'vendor_candidates_detail'"
                      [disableCompleteSubmittal]="hasChanges || !isFormValid"
                      (backClicked)="back()"
                      (saveClicked)="save()"
                      (completeSubmittalClicked)="completeSubmittal()"
                      [showCancelButton]="false"
                      [showCompleteSubmittal]="isNewSubmittal">
</ayac-contact-toolbar>

<div *ngIf="isNpiNameClearedInvalid"
     class="banner-message banner-message--hot"
     data-qa-id="candidate-not-named-cleared-banner">
    <div class="message"> Candidate is not name cleared. </div>
</div>

<div *ngIf="isNewSubmittal$ | async">
    <p class="submittal-warning"><strong>&nbsp;Click the 'Complete Submittal' button to complete your
            submittal.</strong></p>
</div>

<div class="panel panel-warning">
    <div class="panel-heading contact-header">
        Candidate:
        {{candidate?.firstName}}
        {{candidate?.middleName}}
        {{candidate?.lastName}}
    </div>
</div>
<div class="section-container">
    <mat-tab-group class="migrated-tab-styles"
                   animationDuration="0ms"
                   [disableRipple]="true">

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>notifications</mat-icon>
                Profile
            </ng-template>
            <ayac-profile-tab *ngIf="!isNewCandidateLoading"
                [vendorId]="vendorId"
                [candidateId]="candidateId"
                [jobId]="jobId"
                [saveCalled]="saveCalled"
                (onIsSavingChanged)="onIsSavingChanged($event)"
                (onIsFormValidChanged)="onProfileIsFormValidChanged($event)"
                (valueChanged)="onChanges($event)"
                (onSaveSuccess)="onSaveSuccess($event)"
                (onSaveError)="onSaveError()"
                (onLoadCandidateSuccess)="onCandidateLoaded($event)"
                (showNpiNotClearedBanner)="onProfileIsNpiClearedChanged($event)"></ayac-profile-tab>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>file_copy</mat-icon>
                Files
            </ng-template>
            <ayac-files-tab *ngIf="!isNewCandidateLoading" [candidateId]="candidateId"></ayac-files-tab>
        </mat-tab>

        <mat-tab *ngIf="contractRequirements$ | async as contracts">
            <ng-template mat-tab-label>
                <mat-icon>check_box</mat-icon>
                Contract Requirements
            </ng-template>
            <ayac-contract-requirements-tab *ngIf="!isNewCandidateLoading"
                                            [credentialingSpecialist]="contracts.credentialingSpecialist"
                                            [credentialingSpecialistEmail]="contracts.credentialingSpecialistEmail"
                                            [contractRequirements]="contracts.contractRequirements"
                                            [candidateId]="candidateId"
                                            [candidateName]="candidate?.firstName + ' ' + candidate?.lastName"
                                            (uploadDocuments)="uploadDocuments($event)"></ayac-contract-requirements-tab>
        </mat-tab>

        <mat-tab *ngIf="(hasContractAtPerDiemHospital$ | async) === true && candidate && !isNewCandidateLoading && (hasAccessShiftsApp$ | async) === true">
            <ng-template mat-tab-label>
                <mat-icon>tune</mat-icon>
                Shifts
            </ng-template>
            <ayac-shifts-tab [candidateId]="candidateId"
                                [oldUserId]="candidate.oldUserId"
                                (viewCurrentShiftRequested)="onViewShift($event)"></ayac-shifts-tab>
        </mat-tab>
    </mat-tab-group>
</div>
