import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../services/dashboard.service';
import { loadPendingWocSuccess, loadPendingWocFail, loadPendingWoc } from '../actions/woc.actions';
import { withLatestFrom, catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getFilter } from '../selectors/filter.selectors';

@Injectable()
export class WocEffect {
    constructor(
        private actions$: Actions,
        private store$: Store<{}>,
        private service: DashboardService
    ) {}

    getPendingWoc$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPendingWoc),
            withLatestFrom(this.store$.select(getFilter)),
            switchMap(([action, query]) => {
                const filter = {
                    lineOfBusinessId: query.lineOfBusiness.id > 0 ? query.lineOfBusiness.id : null,
                    facilityProfileGroupId: query.facility.profileGroupId > 0 ? query.facility.profileGroupId : null,
                    unitId: query.unit.id > 0 ? query.unit.id : null
                };

                return this.service.getWoc(filter).pipe(
                    map((response) =>
                        loadPendingWocSuccess({
                            pendingWocCount: response
                        })
                    ),
                    catchError((error) => of(loadPendingWocFail({ error })))
                );
            })
        )
    );
}
