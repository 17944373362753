import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as internalPoolActions from 'src/app/internal-pool/store/actions';
import { ResourceDocument } from '../../models';
import { FileAttachment } from 'src/app/shared/models/attachment';

export interface ResourceDocumentsState {
    documents: ResourceDocument[];
    loading: LoadingTypes;
    preview: Blob;
    previewTotal: number;
    loadingPreview: LoadingTypes;
    currentFile: FileAttachment;
}

export const initialDocumentsState: ResourceDocumentsState = {
    documents: [],
    loading: LoadingTypes.INIT,
    preview: null,
    previewTotal: 0,
    loadingPreview: LoadingTypes.INIT,
    currentFile: null
};

export function resourceDocumentsReducer(state: ResourceDocumentsState, action: Action) {
    return createReducer(
        initialDocumentsState,
        on(internalPoolActions.loadResourceDocuments, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(internalPoolActions.loadResourceDocumentsSuccess, (state, action) => ({
            ...state,
            documents: action.documents,
            loading: LoadingTypes.LOADED
        })),

        on(internalPoolActions.loadResourceDocumentsFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL
        })),

        on(internalPoolActions.loadPreviewResourceDocuments, (state, action) => ({
            ...state,
            currentFile: action.file,
            loadingPreview: LoadingTypes.LOADING
        })),
        on(internalPoolActions.loadPreviewResourceDocumentsSuccess, (state, action) => ({
            ...state,
            preview: action.documentPreview,
            loadingPreview: LoadingTypes.LOADED,
            loading: LoadingTypes.LOADED
        })),

        on(internalPoolActions.loadPreviewResourceDocumentsFail, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.FAIL
        })),

        on(internalPoolActions.loadPreviewResourcePageCount, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.LOADING
        })),

        on(internalPoolActions.loadPreviewResourcePageCountSuccess, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.LOADED,
            previewTotal: action.total
        })),

        on(internalPoolActions.loadPreviewResourcePageCountFail, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.FAIL
        })),

        on(internalPoolActions.saveResourceDocument, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.LOADING
        })),

        on(internalPoolActions.saveResourceDocumentFail, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.LOADED
        })),

        on(internalPoolActions.saveResourceDocumentSuccess, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.FAIL
        })),

        on(internalPoolActions.clearResourceDocuments, (state, action) => ({
            ...initialDocumentsState
        })),

        on(internalPoolActions.clearResourceDocumentPreview, (state, action) => ({
            ...state,
            preview: initialDocumentsState.preview,
            previewTotal: initialDocumentsState.previewTotal
        }))
    )(state, action);
}

export function reducer(state: ResourceDocumentsState | undefined, action: Action) {
    return resourceDocumentsReducer(state, action);
}
