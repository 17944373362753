import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/powerbi/store/powerbi-report.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { PowerBiAccessConfig } from 'src/app/shared/models/powerbi/powerbi-access-config.model';

export interface PowerBiReportAccessConfigState {
    loading: LoadingTypes;
    config: PowerBiAccessConfig;
}

export const initialPowerBiReportAccessConfigState: PowerBiReportAccessConfigState = {
    loading: LoadingTypes.INIT,
    config: null
};

export function powerBiReportAccessConfigReducer(state: PowerBiReportAccessConfigState, action: Action) {
    return createReducer(
        initialPowerBiReportAccessConfigState,

        on(actions.loadPowerBiReportAccessConfig, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(actions.loadPowerBiReportAccessConfigSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,
            config: action.config
        })),

        on(actions.loadPowerBiReportAccessConfigFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            config: null
        })),

        on(actions.clearPowerBiReport, (state, action) => ({
            ...initialPowerBiReportAccessConfigState
        }))
    )(state, action);
}
