import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { PermJobGridActions } from '../actions';
import { PermanentJobService } from 'src/app/permanent-jobs/services/perm-jobs.service';
import { selectPermJobsQuery } from '../selectors/perm-jobs.selectors';
import { PermJobsGridResponse } from 'src/app/permanent-jobs/models/perm-jobs-grid.response';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { ToasterService } from 'src/app/core/services';
import { concatLatestFrom } from '@ngrx/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class PermJobsGridEffect extends BaseEffect {
    setQueryAndGetPermJobs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PermJobGridActions.setQueryAndGetPermJobs),
            concatLatestFrom(() => [this.store.select(selectPermJobsQuery)]),
            switchMap(([, query]) => {
                return this.permJobService.getFilteredPermJobs(query).pipe(
                    map((result: PermJobsGridResponse) => {
                        return PermJobGridActions.setQueryAndGetPermJobsSuccess({
                            permJobs: result.data,
                            permJobsTotal: result.total
                        });
                    }),
                    catchError((error: unknown) => {
                        this.handleError(error as HttpErrorResponse);
                        return of(PermJobGridActions.setQueryAndGetPermJobsFail());
                    })
                );
            })
        );
    });

    exportPermJobs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PermJobGridActions.exportPermJobs),
            concatLatestFrom(() => this.store.select(selectPermJobsQuery)),
            switchMap(([, query]) => {
                return this.permJobService.exportFilteredPermJobs(query).pipe(
                    map((response) => {
                        this.toasterService.success('Permanent Jobs are exported successfully.');
                        this.saveFile(response);
                        return PermJobGridActions.exportPermJobsSuccess();
                    }),
                    catchError((error: unknown) => {
                        this.handleError(error as HttpErrorResponse);
                        return of(PermJobGridActions.exportPermJobsFailure());
                    })
                );
            })
        );
    });

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly permJobService: PermanentJobService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
