<loading-indicator *ngIf="isLoading$ | async"></loading-indicator>
<portal-grid class="portal-grid-scroll-auto highlighted-portal-grid" data-title="Upcoming Shifts" [data]="shiftsResult$ | async" [query]="shiftsQuery"
(dataStateChange)="onDataStateChange($event)" (rowClicked)="onRowClicked($event)" [filterable]="false" [rowClass]="gridRowClass" [showPageSizes]="false" >

    <div>
        <ng-template #prefixContainer>
            <form [formGroup]="filterForm" class="filter-form">
                <div class="filter-form-section">
                    <div class="filter-form-control">
                        <mat-label>Min Rate</mat-label>
                        <mat-form-field appearance="outline" >
                            <mat-select formControlName="minRate" data-qa-id="min-rate">
                                <ngx-mat-select-search placeholderLabel="" noEntriesFoundLabel="" formControlName="minRateFilter"></ngx-mat-select-search>
                                <mat-option
                                            *ngFor="let rate of filteredMinRate$ | async"
                                            [value]="rate.id">
                                    {{ rate.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="filter-date-range">
                        <div class="filter-form-control">
                            <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="filterDateFromPicker" formControlName="dateFrom" data-qa-id="filter-date-from" />
                                <mat-datepicker-toggle matSuffix [for]="filterDateFromPicker"></mat-datepicker-toggle>
                                <mat-datepicker #filterDateFromPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div>to</div>
                        <div class="filter-form-control">
                            <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="filterDateToPicker" formControlName="dateTo" data-qa-id="filter-date-to" />
                                <mat-datepicker-toggle matSuffix [for]="filterDateToPicker"></mat-datepicker-toggle>
                                <mat-datepicker #filterDateToPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="filter-form-location-section">
                    <div class="separation-label"><mat-label>Location</mat-label></div>
                    <div>
                        <mat-radio-group formControlName="locationFilterType">
                            <mat-radio-button [value]="1" [checked]="locationFilterTypeControl.value === 1"><mat-label class="radio-label">Facility</mat-label></mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="flex-maximum filter-form-control">
                        <mat-form-field appearance="outline" >
                            <mat-select placeholder="Select facility..." formControlName="facility" data-qa-id="facility-filter">
                                <ngx-mat-select-search placeholderLabel="" noEntriesFoundLabel="" formControlName="facilityFilter"></ngx-mat-select-search>
                                <mat-option
                                            *ngFor="let facility of filteredFacilities$ | async"
                                            [value]="facility.id">
                                    {{ facility.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="separation-label">
                        OR
                    </div>

                    <div>
                        <mat-radio-group formControlName="locationFilterType">
                            <mat-radio-button [value]="2" [name]="'locationFilterType'" [checked]="locationFilterTypeControl.value === 2"><mat-label class="radio-label">City</mat-label></mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="flex-maximum filter-form-control">
                        <mat-form-field appearance="outline" >
                            <input matInput type="text" formControlName="city" data-qa-id="city-filter">
                        </mat-form-field>
                    </div>
                    <div class="filter-form-control flex-maximum">
                        <mat-label>State</mat-label>
                        <mat-form-field appearance="outline" >
                            <mat-select placeholder="Select state..." formControlName="state" data-qa-id="state-filter">
                                <ngx-mat-select-search placeholderLabel="" noEntriesFoundLabel="" formControlName="stateFilter"></ngx-mat-select-search>
                                <mat-option
                                            *ngFor="let state of filteredStates$ | async"
                                            [value]="state.code">
                                    {{ state.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex-minimum action-button-container">
                        <button mat-flat-button color="accent" class="migrated-btn-info" data-qa-id="ac_vendor_view_shifts_button" (click)="refreshShifts()">
                            View Shifts
                        </button>
                    </div>
                </div>
            </form>
        </ng-template>
    </div>

    <ng-template #header let-title="title" let-total="total">
        <div class="title">
            Open Qualified Shifts
        </div>
    </ng-template>

    <ng-template #toolbar>
        <button mat-flat-button color="accent" class="migrated-btn-info" data-qa-id="ac_vendor_view_open_shift_details_button" [disabled]="!selectedShift" (click)="viewShiftRequested.emit(selectedShift)">
            View Shift Details
        </button>
        <button mat-flat-button color="accent" class="migrated-btn-info" data-qa-id="ac_vendor_book_candidate_button" [disabled]="!selectedShift || isBookingCandidateInProgress" (click)="bookCandidate()">
            Book Candidate
        </button>
    </ng-template>

    <portal-grid-column
        title=""
        field="isSelected"
        [sortable]="false"
        [style]="{'width': '20px'}"
        [headerStyle]="{'width': '20px'}">
        <ng-template #cell let-dataItem="dataItem">
            <mat-checkbox [checked]="dataItem.id === selectedShift?.id"></mat-checkbox>
        </ng-template>
    </portal-grid-column>

    <portal-grid-column title="Date" field="shiftDate" >
        <ng-template #cell let-dataItem="dataItem">
            <mat-label>{{dataItem.shiftDate | date : 'MM/dd/yyyy'}}</mat-label>
        </ng-template>
    </portal-grid-column>

    <portal-grid-column title="Time" field="endTime">
        <ng-template #cell let-dataItem="dataItem">
            <mat-label>{{dataItem.startTime | date : 'HH:mm'}} - {{dataItem.endTime | date : 'HH:mm'}}</mat-label>
        </ng-template>
    </portal-grid-column>

    <portal-grid-column title="Facility" field="facilityName">
    </portal-grid-column>

    <portal-grid-column title="City" field="facilityCity">
    </portal-grid-column>

    <portal-grid-column title="ST" field="facilityState">
    </portal-grid-column>

    <portal-grid-column title="Specialty" field="expertiseName">
    </portal-grid-column>

    <portal-grid-column title="Profession" field="professionName">
    </portal-grid-column>

    <portal-grid-column title="Rate" field="billRateCalculated">
        <ng-template #cell let-dataItem="dataItem">
            <mat-label>{{dataItem.billRateCalculated | currency :'USD':'symbol':'1.2-2'}}</mat-label>
        </ng-template>
    </portal-grid-column>

    <portal-grid-column title="Status" field="statusName">
    </portal-grid-column>

    <portal-grid-column title="Candidate Name" field="candidateName">
        <ng-template #cell let-dataItem="dataItem">
            <mat-label>{{(!dataItem.candidateName || (dataItem.statusName === 'Closed' && (dataItem.statusId === 6 || dataItem.statusId === 14))) ? '' : dataItem.candidateName}}</mat-label>
        </ng-template>
    </portal-grid-column>
</portal-grid>
