import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as TravelersListActions from '../actions/travelers-list.actions';
import * as fromTraveler from '../../state';
import { TravelersService } from '../../services/travelers.service';
import { ToasterService } from 'src/app/core/services';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { SortTypes } from 'src/app/shared/models';
import { downloadBlob } from 'src/app/core/utils';
import * as dayjs from 'dayjs';
import { GridSearchQuery } from 'src/app/shared/grid/models';

@Injectable()
export class TravelersListEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly travelersService$: TravelersService,
        private readonly toaster: ToasterService,
        private readonly store: Store<{}>
    ) {}

    loadTravelersLookup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelersListActions.loadTravelersLookup),
            switchMap((action) =>
                this.travelersService$.getLookups().pipe(
                    map((lookups) => TravelersListActions.travelersLookupLoaded({ lookups: lookups })),
                    catchError((error) => of(TravelersListActions.travelersLookupLoaded(null)))
                )
            )
        )
    );

    loadTravelers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelersListActions.loadTravelers),
            withLatestFrom(this.store.pipe(select(fromTraveler.selectGridDataQuery))),
            switchMap(([action, query]) => {
                const queryResult = this.queryAndSort(query, 'clinicianSearch');

                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };

                const matchArgs = flattenFilter(query.filter);
                return this.travelersService$.getTravelers(pagination, queryResult.sortArgs, matchArgs).pipe(
                    map((res) => TravelersListActions.travelersLoaded({ travelers: res })),
                    catchError((error) => of(TravelersListActions.travelersLoadError({ error: error })))
                );
            })
        )
    );

    export$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelersListActions.exportTravelers),
            withLatestFrom(this.store.pipe(select(fromTraveler.selectGridDataQuery))),
            switchMap(([action, query]) => {
                const queryResult = this.queryAndSort(query, 'travelerName');

                const matchArgs = flattenFilter(query.filter);
                const filename = `Travelers_${dayjs().format('MMDDYYYY')}`;

                return this.travelersService$.export(queryResult.sortArgs, matchArgs).pipe(
                    map((res) =>
                        TravelersListActions.travelersExported({
                            blob: res,
                            filename: `${filename}.xlsx`
                        })
                    ),
                    catchError((error) => of(TravelersListActions.travelersExportError({ error })))
                );
            })
        )
    );

    downloadTravelers$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelersListActions.travelersExported),
                tap((action) => downloadBlob(action.blob, action.filename))
            ),
        { dispatch: false }
    );

    exportTravelersFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelersListActions.travelersExportError),
                tap((action) => this.toaster.fail('Failed to export'))
            ),
        { dispatch: false }
    );

    queryAndSort(
        query: GridSearchQuery,
        fieldName: string
    ): {
        sortArgs: {
            sortField: string;
            sortType: SortTypes;
        };
    } {
        if (query.filter) {
            let newQuery = query.filter.filters.map((f) => {
                if (f['field'] === 'facilityId') {
                    return { ...f, field: 'searchFacilityIds' };
                }
                if (f['field'] === 'expertiseName') {
                    return { ...f, field: 'searchExpertiseIds' };
                }
                if (f['field'] === 'professionName') {
                    return { ...f, field: 'searchProfessionIds' };
                } else {
                    return f;
                }
            });
            query.filter.filters = newQuery;
        }

        const sortCondition =
            query.sort && query.sort.length
                ? query.sort.map((q) => {
                      if (q.field === fieldName) {
                          return { ...q, field: 'firstname' };
                      } else if (q.field === 'unitSearch') {
                          return { ...q, field: 'unit' };
                      } else if (q.field === 'facilityName') {
                          return { ...q, field: 'facility' };
                      } else if (q.field === 'expertiseName') {
                          return { ...q, field: 'expertise' };
                      } else {
                          return q;
                      }
                  })
                : query.sort;

        const sortArgs = {
            sortField: sortCondition && sortCondition[0].field,
            sortType: sortCondition && (sortCondition[0].dir as SortTypes)
        };

        return {
            sortArgs
        };
    }
}
