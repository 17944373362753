import * as subittalsVendorReducer from './submittals-vendor.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { SubmittalsVendorState } from './submittals-vendor.reducers';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { VendorSubmittalDetails } from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';
import { VendorSubmittalStage } from 'src/app/shared/components/submittal-details/enums/vendor-submittal-stage.enum';
import { VendorSubmittalStatuses } from 'src/app/shared/components/submittal-details/enums/vendor-submittal-statuses.enum';

export const selectFeature = createFeatureSelector<subittalsVendorReducer.SubmittalsVendorState>(
    subittalsVendorReducer.subittalsVendorFeatureKey
);

export const selectVendorSubmittals = createSelector(selectFeature, (state: SubmittalsVendorState) => state.submittals);

export const selectIsLoading = createSelector(
    selectFeature,
    (state: SubmittalsVendorState) => state.loading === LoadingTypes.LOADING
);

export const selectVendorSubmittalsSearchQuery = createSelector(
    selectFeature,
    (state: SubmittalsVendorState) => state.searchQuery
);

export const selectVendorSubmittalsContacts = createSelector(
    selectFeature,
    (state: SubmittalsVendorState) => state.agents
);

export const selectVendorSubmittalsDetails = createSelector(
    selectFeature,
    (state: SubmittalsVendorState) => state.details
);

export const selectUseAyaAccountManagement = createSelector(
    selectVendorSubmittalsDetails,
    (details: VendorSubmittalDetails) => details && details.useAyaAccountManagement
);

export const selectVendorSubmittalsStages = createSelector(
    selectFeature,
    (state: SubmittalsVendorState) => state.stages
);

export const selectVendorSubmittalsStageReasons = createSelector(
    selectFeature,
    (state: SubmittalsVendorState) => state.stageReasons
);

export const selectVendorSubmittalsProfessionsSpecialties = createSelector(
    selectFeature,
    (state: SubmittalsVendorState) => state.professionSpecialties
);

export const selectVendorJobDetails = createSelector(selectFeature, (state: SubmittalsVendorState) => state.vendorJob);

export const selectCandidateInfo = createSelector(selectFeature, (state: SubmittalsVendorState) => state.candidateInfo);

export const selectIsPageLoading = createSelector(selectFeature, (state: SubmittalsVendorState) => state.loading);

export const selectHasChanges = createSelector(selectFeature, (state: SubmittalsVendorState) => state.hasChanges);

export const selectShowCancelSubmittalButton = createSelector(
    selectVendorSubmittalsDetails,
    (details: VendorSubmittalDetails) =>
        details &&
        (details.submittalStatusId === VendorSubmittalStatuses.CandidateSubmitted ||
            details.submittalStatusId === VendorSubmittalStatuses.PendingInformation ||
            details.submittalStatusId === VendorSubmittalStatuses.Queued ||
            details.submittalStatusId === VendorSubmittalStatuses.SentToClinical ||
            details.submittalStatusId === VendorSubmittalStatuses.SubmittedToClient ||
            details.submittalStatusId === VendorSubmittalStatuses.UnderReviewByHiringManager ||
            details.submittalStatusId === VendorSubmittalStatuses.AdditionalReviewNeeded ||
            details.submittalStatusId === VendorSubmittalStatuses.CandidateWithdrawingFromSubmission ||
            details.submittalStatusId === VendorSubmittalStatuses.Interview) &&
        !details.isRemovedFromLiveList
);

export const selectIsSavingDetails = createSelector(
    selectFeature,
    (state: SubmittalsVendorState) => state.loadingSave === LoadingTypes.LOADING
);

export const selectCreatedSubmittalId = createSelector(
    selectFeature,
    (state: SubmittalsVendorState) => state.createdSubmittalId
);

export const getSelectorValue = async <TResult>(observable: Observable<TResult>): Promise<TResult> =>
    observable.pipe(first()).toPromise();

//LOOKUPS//
export const selectSubmittalVendorLookups = createSelector(selectFeature, (state) => state.lookups);

export const selectSubmittalVendorLookupsStatuses = createSelector(
    selectSubmittalVendorLookups,
    (state) => state.statuses
);

export const selectSubmittalVendorLookupsSubStatuses = createSelector(
    selectSubmittalVendorLookups,
    (state) => state.subStatuses
);

export const selectSubmittalVendorLookupsSubStatusesCount = createSelector(
    selectSubmittalVendorLookups,
    (state) => state.subStatuses?.length
);

export const selectIsSubmittalDataLoading = createSelector(selectFeature, (state: SubmittalsVendorState) =>
    state.loadingVendorJob === LoadingTypes.LOADED && state.loadingCandidateInfo === LoadingTypes.LOADED ? false : true
);

export const selectSubmittalVendorActivities = createSelector(selectFeature, (state) => state.activities);

export const selectSubmittalVendorActivitiesSortBy = createSelector(selectFeature, (state) => state.sortActivitiesBy);

export const selectCommunicationEnabled = createSelector(selectFeature, (state) => state.communicationEnabled);
