import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import {
    loadEditJobsLinesOfBusiness,
    loadEditJobsLinesOfBusinessSucesss,
    loadEditJobsLinesOfBusinessFail
} from 'src/app/lookups/state/actions';

export interface EditJobsLinesOfBusinessState {
    linesOfBusiness: Array<ListItem>;
    loading: LoadingTypes;
    error: unknown;
}

export const initialEditJobsLinesOfBusinessState: EditJobsLinesOfBusinessState = {
    linesOfBusiness: [],
    loading: LoadingTypes.INIT,
    error: null
};

export function editJobsLinesOfBusinessReducer(state: EditJobsLinesOfBusinessState, action: Action) {
    return createReducer(
        initialEditJobsLinesOfBusinessState,
        on(loadEditJobsLinesOfBusiness, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadEditJobsLinesOfBusinessSucesss, (state, action) => ({
            ...state,
            linesOfBusiness: action.linesOfBusiness,
            loading: LoadingTypes.LOADED
        })),
        on(loadEditJobsLinesOfBusinessFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: EditJobsLinesOfBusinessState | undefined, action: Action) {
    return editJobsLinesOfBusinessReducer(state, action);
}
