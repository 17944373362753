<mat-toolbar class="portal-page-header" [ngClass]="classNames">
    <mat-toolbar-row class="portal-page-title primary-color" *ngIf="title">
        <span class="mat-headline-1 mb-0" fxLayout="row" fxLayoutAlign="start center">
            <ng-container
                *ngIf="pageHeaderTitle; else defaultTitle"
                [ngTemplateOutlet]="pageHeaderTitle"
                [ngTemplateOutletContext]="{
                    title: title
                }">
            </ng-container>
            <ng-template #defaultTitle>{{title}}</ng-template>
        </span>
        <span>&nbsp;</span>
        <span class="portal-page-title-description mat-subtitle-1 mb-0 pl-2">{{subtitle}}</span>
        <span *ngIf="badge" class="portal-page-badge mat-subtitle-1 mb-0 pl-2" [ngStyle]="{'background-color':badge.backgroundColor}">
                {{badge.displayText}}
        </span>
        <span *ngIf="link" class="portal-page-link">
            <a routerLink="{{link.url}}">{{link.label}}</a>
        </span>
        <ng-container
            *ngIf="pageHeaderSuffix"
            [ngTemplateOutlet]="pageHeaderSuffix">
        </ng-container>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="pageHeaderBanner" class="portal-page-header-banner">
        <ng-container
            [ngTemplateOutlet]="pageHeaderBanner">
        </ng-container>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="pageHeaderToolbar" class="portal-page-header-toolbar ayac-button">
        <ng-container
            [ngTemplateOutlet]="pageHeaderToolbar">
        </ng-container>
    </mat-toolbar-row>
</mat-toolbar>
