import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../services/dashboard.service';
import { withLatestFrom, catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getFilter } from '../selectors/filter.selectors';
import { loadWorkersSuccess, loadWorkersFail, loadTravelers } from '../actions';
import { selectStartingWithinDays, selectEndingWithinDays } from '../selectors/workers.selectors';

@Injectable()
export class WorkersEffect {
    constructor(
        private actions$: Actions,
        private store$: Store<{}>,
        private service: DashboardService
    ) {}

    getWorkers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTravelers),
            withLatestFrom(
                this.store$.select(getFilter),
                this.store$.select(selectStartingWithinDays),
                this.store$.select(selectEndingWithinDays)
            ),
            switchMap(([action, query, startingWithinDays, endingWithinDays]) => {
                const filter = {
                    lineOfBusinessId: query.lineOfBusiness.id > 0 ? query.lineOfBusiness.id : null,
                    facilityId: query.facility.id > 0 ? query.facility.id : null,
                    unitId: query.unit.id > 0 ? query.unit.id : null,
                    startingWithinDays: startingWithinDays,
                    endingWithinDays: endingWithinDays
                };

                return this.service.getWorkers(filter).pipe(
                    map((response) =>
                        loadWorkersSuccess({
                            workers: response
                        })
                    ),
                    catchError((error) => of(loadWorkersFail({ error })))
                );
            })
        )
    );
}
