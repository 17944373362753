<div
    class="menu-item"
    [class.menu-item--module-active]="isActive"
    [class.menu-item--expanded]="isExpanded"
    [class.menu-item--hovered]="showHoverMenu"
    (mouseenter)="onMouseOverModule(true)"
    (mouseleave)="onMouseOverModule(false)"
    (click)="handleModuleClick()"
    terminology>

    <div class="menu-item__content"
        [class.menu-item__content--module-active]="isActive">
        <a *ngIf="linkData?.subItems?.length === 0 && isExpanded"
            class="menu-item__link" (click)="onItemClick2(linkData)"></a>
        <a *ngIf="linkData?.subItems?.length === 0 && !isExpanded"
            class="menu-item__link"  (click)="onItemClick2(linkData)"
            [matTooltip]="linkData?.title" matTooltipPosition="right"></a>

        <ng-container [ngSwitch]="linkData?.icon | iconType">
            <div *ngSwitchCase="'mat'" class="menu-item__icon menu-icon-mat">
                <mat-icon>{{ linkData?.icon }}</mat-icon>
            </div>
            <div *ngSwitchCase="'fas'" class="menu-item__icon">
                <mat-icon fontSet="fas" [fontIcon]="linkData?.icon"></mat-icon>
            </div>
            <div *ngSwitchCase="'svg'" class="menu-item__icon menu-icon-mat">
                <mat-icon [svgIcon]="linkData?.icon"></mat-icon>
            </div>
        </ng-container>

        <span class="menu-item__text">{{ linkData?.title }}</span>
    </div>

    <div
        class="menu-popup"
        [class.menu-popup--above]="popupPosition === 'above'"
        [class.help]="linkData?.title === 'Help'"
        [class.help-expanded]="linkData?.title === 'Help' && isExpanded"
        *ngIf="showHoverMenu && linkData?.subItems?.length > 0"
        (mouseenter)="keepActive(true)"
        (mouseleave)="keepActive(false)">

        <div class="menu-popup__panel">
            <h3 class="menu-popup__heading" [attr.data-title]="linkData?.title">{{ linkData?.title }}</h3>
            <mat-nav-list>
                <a mat-list-item *ngFor="let subItem of linkData?.subItems"
                    class="menu-popup__item"
                    [routerLink]="subItem.path"
                    routerLinkActive="menu-item--item-active"
                    [routerLinkActiveOptions]="linkOptions"
                    [queryParams]="subItem.queryParams"
                    (click)="onItemClick(subItem)">

                    <ng-container [ngSwitch]="subItem?.icon | iconType">
                        <mat-icon *ngSwitchCase="'mat'"
                            matListIcon
                            class="menu-popup__item__icon menu-icon-mat">
                            {{ subItem?.icon }}
                        </mat-icon>
                        <mat-icon *ngSwitchCase="'fas'"
                            matListIcon
                            class="menu-popup__item__icon"
                            fontSet="fas"
                            [fontIcon]="subItem?.icon">
                        </mat-icon>
                        <mat-icon *ngSwitchCase="'svg'"
                            matListIcon
                            class="menu-popup__item__icon menu-icon-mat-svg"
                            [svgIcon]="subItem?.icon">
                        </mat-icon>
                    </ng-container>

                    <span class="menu-popup__item__label">{{ subItem.title }}</span>
                    <span class="menu-popup__item__badge">{{ subItem.bageText }}</span>
                </a>
            </mat-nav-list>

            <ng-content></ng-content>

        </div>
    </div>

</div>


