<dialog-layout data-qa-id="ac_cpd_dialog_container"[title]="data?.title" [isLoading]="loading$ | async">
    <div mat-dialog-content>
        
        <form class="k-form-inline" [formGroup]="form">
            <label class="k-form-field">
                <span>Current Password</span>
                <input class="k-textbox inputMaxWidth" 
                    placeholder="Enter current password"
                    type="password"
                    data-qa-id="ac_cpd_current_password_input"
                    formControlName="currentPassword" />                    
                <validation-message
                    label="Password"
                    [control]="form.get('currentPassword')">
                </validation-message>
            </label>
            <label class="k-form-field">
                <span>New Password</span>
                <input class="k-textbox inputMaxWidth"
                    placeholder="Enter new password"
                    type="password"
                    data-qa-id="ac_cpd_new_password_input"
                    formControlName="newPassword" />                    
                <validation-message
                    label="Password"
                    [control]="form.get('newPassword')">
                </validation-message>
            </label>
            <label class="k-form-field">
                <span>Confirm New Password</span>
                <input class="k-textbox inputMaxWidth"
                    placeholder="Enter new password again"
                    type="password"
                    data-qa-id="ac_cpd_confirm_password_input"
                    formControlName="confirmPassword" />                    
                <validation-message
                    label="Password"
                    [control]="form.get('confirmPassword')">
                </validation-message>
            </label>
        </form>

    </div>
    <div mat-dialog-actions>
        <button 
            mat-flat-button
            data-qa-id="ac_cpd_submit_button"
            color="accent"
            (click)="submit()">
            Submit
        </button>
        <button
            mat-stroked-button
            data-qa-id="ac_cpd_reject_button"
            (click)="close()">
            Cancel
        </button>
    </div>
</dialog-layout>
