import { HttpParams } from '@angular/common/http';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';

export function interpolateSearch(search: GridSearchQuery): HttpParams {
    let query: { [key: string]: string } = {};

    if (Array.isArray(search.sort)) {
        if (search.sort[0]) {
            const sortField = search.sort[0].field;
            query['sortField'] = String(sortField);

            const sortType = search.sort[0].dir;
            query['sortType'] = String(sortType);
        }
    }

    if (search.take) {
        query['pageSize'] = String(search.take);
    }

    if (search.skip) {
        query['skip'] = String(search.skip);
    }

    if (search.filter) {
        query = {
            ...query,
            ...flattenFilter(search.filter)
        };
    }

    return new HttpParams({ fromObject: query });
}
