<ayac-widget>
  <div class="application-document-viewer-container">
      <div widget-breadcrumb>
          <ayac-breadcrumb
              [items]="[
                      {label: 'Application Document'}
                  ]">
          </ayac-breadcrumb>
      </div>
      <div class="application-document-viewer-document" *ngIf="documentUrl$ | async as blobUrl">
          <ayac-documents-viewer [url]="blobUrl"></ayac-documents-viewer>
      </div>
  </div>
</ayac-widget>