/**angular */
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**local */
import { CertificationLevel } from 'src/app/admin/vendor-details/certifications/models/certification-level.model';
import { Certification } from 'src/app/admin/vendor-details/certifications/models/certification.model';
import { VendorCertificationBase } from 'src/app/admin/vendor-details/certifications/models/vendor-certification-base.model';
import { EthnicityType } from 'src/app/admin/vendor-details/certifications/models/ethnicity-type.model';

@Component({
    selector: 'ayac-vendor-profile-certifications-group',
    templateUrl: './vendor-profile-certifications-group.component.html',
    styleUrls: ['./vendor-profile-certifications-group.component.scss']
})
export class VendorProfileCertificationsGroupComponent {
    @Input() vendorId: number;
    @Input() certification: Certification = null;
    @Input() vendorCertifications: VendorCertificationBase[] = [];
    @Input() locationLevels: CertificationLevel[];
    @Input() ethnicityTypes: EthnicityType[];
    @Input() isDiversity: boolean = false;

    @Output() vendorCertificationsChange = new EventEmitter<any>();
    @Output() updateVendorCertification = new EventEmitter<any>();
    @Output() downloadFile = new EventEmitter<number>();

    toggle() {
        if (!this.vendorCertifications) {
            this.vendorCertifications = [];
        }

        if (this.vendorCertifications?.length === 0) {
            this.vendorCertifications.push({
                id: 0,
                certificationId: this.certification.id,
                expiration: null,
                level: null,
                location: null,
                organization: null,
                files: [],
                uploadFiles: [],
                ethnicity: null
            });
        } else {
            this.vendorCertifications = [];
        }

        this.emitUpdateVendorCertifications();
    }

    emitUpdateVendorCertifications() {
        this.vendorCertificationsChange.emit(this.vendorCertifications);
        this.updateVendorCertification.emit();
    }

    addCertification() {
        this.vendorCertifications.push({
            id: 0,
            certificationId: this.certification.id,
            expiration: null,
            level: null,
            location: null,
            organization: null,
            files: [],
            uploadFiles: [],
            ethnicity: null
        });

        this.emitUpdateVendorCertifications();
    }

    removeCertificate(certification) {
        if (!certification.id || confirm('Delete information. Continue?')) {
            this.vendorCertifications = this.vendorCertifications.filter((x) => x !== certification);
            this.emitUpdateVendorCertifications();
        }
    }

    get isChecked() {
        return this.vendorCertifications?.length > 0;
    }
}
