import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Editor, Validators } from 'ngx-editor';
import { ToasterService } from 'src/app/core/services';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { SubmittalRejectDialogData } from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';

@Component({
    selector: 'reject-offer-dialog-dialog',
    templateUrl: './reject-offer-dialog.component.html',
    styleUrls: ['./reject-offer-dialog.component.scss']
})
export class RejectOfferDialogComponent extends ConfirmationDialogComponent implements OnInit {
    form: UntypedFormGroup;
    maxCharacterLength = 2000;
    maxRichTextCharacterLength = 3800;
    editor!: Editor;
    commentsPlaceholder = 'Leave cancellation comments...';

    constructor(
        public readonly dialogRef: MatDialogRef<RejectOfferDialogComponent>,
        private readonly formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) readonly data: SubmittalRejectDialogData,
        public toasterService: ToasterService
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            reason: null,
            note: ''
        });
        if (this.data.communicationEnabled) {
            this.editor = new Editor();
            this.form.get('note').setValidators([Validators.maxLength(this.maxCharacterLength)]);
            this.form.get('note').updateValueAndValidity();
        }
    }

    close(isConfirmed: boolean = false): void {
        const formData = isConfirmed ? this.form.value : null;
        const data = {
            isConfirmed,
            formData
        };

        if (
            isConfirmed &&
            this.data.communicationEnabled &&
            data.formData.note.length > this.maxRichTextCharacterLength
        ) {
            this.toasterService.fail(
                'Note exceeds maximum character limit. Please reduce note length and/or note formatting.'
            );
        } else {
            this.dialogRef.close(data);
        }
    }
}
