import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as coreActions from '../actions';
import { ClientSharedData } from 'src/app/shared/models/client-shared-data.model';

export interface ClientSharedDataState {
    data: ClientSharedData;
    loading: LoadingTypes;
}

export const initialClientSharedDataState: ClientSharedDataState = {
    data: null,
    loading: LoadingTypes.INIT
};

export function clientSharedDataReducer(state: ClientSharedDataState, action: Action) {
    return createReducer(
        initialClientSharedDataState,
        on(coreActions.loadClientSharedData, (state) => ({
            ...state,
            loading: !state.data ? LoadingTypes.LOADING : LoadingTypes.LOADED
        })),

        on(coreActions.loadClientSharedDataSuccess, (state, action) => ({
            ...state,
            data: action.data,
            loading: LoadingTypes.LOADED
        })),

        on(coreActions.loadClientSharedDataFail, (state) => ({
            ...state,
            loading: LoadingTypes.FAIL
        })),
        on(coreActions.clearClientSharedData, (state) => ({
            ...initialClientSharedDataState
        }))
    )(state, action);
}

export function reducer(state: ClientSharedDataState | undefined, action: Action) {
    return clientSharedDataReducer(state, action);
}
