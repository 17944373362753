import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubmittalActivity } from 'src/app/submittals/models';
import { SubmittalNoteType } from 'src/app/submittals/models/submittal-note-type.enum';
import { SortTypes } from 'src/app/shared/models';
import { MatSelectChange } from '@angular/material/select';
import { DateHelper } from 'src/app/shared/utilities';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

@Component({
    selector: 'aya-submittal-activity-and-comments-section',
    templateUrl: 'activity-and-comments-section.component.html',
    styleUrls: ['activity-and-comments-section.component.scss']
})
export class ActivityAndCommentsSectionComponent implements OnInit, OnChanges {
    @Output() changeSortOrder = new EventEmitter<SortTypes>();
    @Output() replyToComment = new EventEmitter<SubmittalActivity>();
    @Output() addComment = new EventEmitter();

    @Input() source: 'Client' | 'Vendor' = 'Client';
    @Input() activities!: SubmittalActivity[];
    @Input() showChangeSortOrder = true;
    @Input() showReplyButton = true;
    @Input() isPendingOverdue = false;
    @Input() isAyaAccountManagement = false;

    noteType = SubmittalNoteType;
    localTimeZoneName = '';
    sortForm = new FormGroup({
        sortOrder: new FormControl('desc', Validators.required)
    });

    selectedSortOrder = SortTypes.DESC;
    allowAddComment = false;

    readonly featureFlag = FeatureFlag;

    constructor() {}

    ngOnInit(): void {
        this.localTimeZoneName = DateHelper.getLocalTimezoneName();
    }

    replyTo(activity: SubmittalActivity): void {
        this.replyToComment.emit(activity);
    }

    updateSortOrder(event: MatSelectChange): void {
        this.selectedSortOrder = event.value as SortTypes;
        this.changeSortOrder.emit(this.selectedSortOrder);
    }

    scrollToNote(noteId: number): void {
        const noteElement = document.querySelector(`[data-note-submittalNoteId="${noteId}"]`);
        if (!noteElement) {
            return;
        }

        noteElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        noteElement.classList.add('highlight-flash');

        setTimeout(() => {
            noteElement.classList.remove('highlight-flash');
        }, 2000);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activities && !changes.activities.previousValue?.length && this.source === 'Client') {
            this.changeSortOrder.emit(this.selectedSortOrder);
        }
    }

    addNewComment() {
        this.addComment.emit();
    }
}
