import { ClientSharedData } from 'src/app/shared/models/client-shared-data.model';
import { createAction, props } from '@ngrx/store';

export const loadClientSharedData = createAction('[CORE] Load Client Shared Data');
export const loadClientSharedDataSuccess = createAction(
    '[CORE] Load Client Shared Data Succeed',
    props<{ data: ClientSharedData }>()
);
export const loadClientSharedDataFail = createAction('[CORE] Load Client Shared Data Failed', props<{ error: any }>());
export const clearClientSharedData = createAction('[CORE] Clear Client Shared Data');
