import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SendLinkModalData } from 'src/app/clinical/models/send-link-modal-data';
import { ClinicalService } from 'src/app/clinical/services/clinical.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { emailValidator, notEmployeeEmailValidator } from 'src/app/shared/utilities';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'app-clinical-unit-description-send-link-modal',
    templateUrl: './send-link-modal.component.html',
    styleUrls: ['./send-link-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SendLinkModalComponent implements OnInit {
    sendLinkForm: UntypedFormGroup;
    linkGenerated = false;
    tokenSaved = false;
    generatedLink = '';
    expiredLink = false;
    expirationDate: string;
    oldLink = false;
    token = '';

    constructor(
        private readonly dialogRef: MatDialogRef<SendLinkModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SendLinkModalData,
        private readonly fb: UntypedFormBuilder,
        private readonly _clinicalService: ClinicalService,
        protected readonly toasterService: ToasterService
    ) {}

    ngOnInit(): void {
        this.sendLinkForm = this.fb.group({
            email: ['', [Validators.required, emailValidator, notEmployeeEmailValidator]]
        });

        if (this.data.expiredLink !== undefined) {
            this.expiredLink = this.data.expiredLink;
        }
        if (this.data.oldLink !== undefined) {
            this.oldLink = this.data.oldLink;
        }
        this.sendLinkForm.get('email').valueChanges.subscribe((value) => {
            if (this.sendLinkForm.valid) {
                this.generateLink();
            } else {
                this.linkGenerated = false;
                this.generatedLink = '';
            }
        });
        const expiration = new Date();
        expiration.setDate(expiration.getDate() + 14);
        this.expirationDate = formatDate(expiration, 'MMMM d, y', 'en-US');
    }

    generateLink(): void {
        if (this.sendLinkForm.invalid) {
            this.linkGenerated = false;
            this.generatedLink = '';
            return;
        }
        try {
            const { id } = this.data;
            this.token = this.expiredLink ? this.data?.token : uuid();
            const hostName = `${window.location.protocol}//${window.location.host}/#/`;
            this.generatedLink = `${hostName}units?formId=${id}&contact=1&token=${this.token}`;
            this.linkGenerated = true;
            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 14);
            this.expirationDate = formatDate(expiration, 'MMMM d, y', 'en-US');
        } catch (error) {
            this.toasterService.fail('Failed to generate link.');
        }
    }

    sendEmail(): void {
        if (this.sendLinkForm.invalid || !this.linkGenerated) {
            return;
        }
        if (!this.tokenSaved) {
            this._clinicalService
                .saveUDOnlyLinkSecurityToken({
                    link: this.generatedLink,
                    formId: this.data.id,
                    externalEmail: this.sendLinkForm.get('email').value,
                    token: this.token
                })
                .subscribe({
                    next: () => {
                        this.tokenSaved = true;
                        this.closeForm();
                    },
                    error: (error) => this.toasterService.fail('Failed to generate a new token:', error)
                });
        } else {
            this.closeForm();
        }
    }

    closeForm() {
        this.dialogRef.close({
            email: this.sendLinkForm.get('email').value,
            link: this.generatedLink,
            expirationDate: formatDate(new Date(this.expirationDate), 'MM/dd/YYYY', 'en-US')
        });
    }

    copyLink(): void {
        if (!this.tokenSaved) {
            this._clinicalService
                .saveUDOnlyLinkSecurityToken({
                    link: this.generatedLink,
                    formId: this.data.id,
                    externalEmail: this.sendLinkForm.get('email').value,
                    token: this.token
                })
                .subscribe({
                    next: () => {
                        this.tokenSaved = true;
                        this.copy();
                    },
                    error: (error) => this.toasterService.fail('Failed to generate a new token:', error)
                });
        } else {
            this.copy();
        }
    }

    copy() {
        navigator.clipboard
            .writeText(this.generatedLink)
            .then(() => {
                this.toasterService.success('Link copied.');
            })
            .catch(() => {
                this.toasterService.fail('Failed to copy link.');
            });
    }

    requestNewLink(): void {
        if (this.sendLinkForm.invalid) {
            return;
        }

        const formData = {
            email: this.sendLinkForm.get('email').value,
            link: this.generatedLink,
            expirationDate: this.expirationDate,
            oldLink: this.oldLink
        };
        this.dialogRef.close(formData);
    }

    close(): void {
        this.dialogRef.close();
    }
}
