import { createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { selectFeature } from '../submittals.selectors';

export const selectSubmittalJobDetailsObject = createSelector(selectFeature, (state) => state.job);

export const selectSubmittalJobDetails = createSelector(selectSubmittalJobDetailsObject, (state) => state.job);

export const selectJobDetailsError = createSelector(selectSubmittalJobDetailsObject, (details) => details.error);

export const selectJobDetailsLoading = createSelector(
    selectSubmittalJobDetailsObject,
    (details) => details.loading === LoadingTypes.LOADING
);

export const selectIsJobDetailsLoadFailed = createSelector(
    selectSubmittalJobDetailsObject,
    (details) => details.loading === LoadingTypes.LOADED && !details.job
);
