import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { FileAttachment } from 'src/app/shared/models/attachment';
import {
    clearStaffingAdvisory,
    clearStaffingAdvisoryAttachments,
    loadStaffingAdvisoryAttachmentPreview,
    loadStaffingAdvisoryAttachmentPreviewFail,
    loadStaffingAdvisoryAttachmentPreviewSuccess,
    loadStaffingAdvisoryPreviewPageCountSuccess,
    clearStaffingAdvisoryAttachmentsPreview
} from 'src/app/travelers/state/actions/staffing-advisory.actions';

export interface StaffingAdvisoryAttachmentsPreviewState {
    loading: LoadingTypes;
    staffingAdvisoryPreviewAttachments: Blob;
    staffingAdvisoryPreviewTotalPage: number;
    staffingAdvisoryPreviewFileName: string;
    currentFile: FileAttachment;
}

const initialState: StaffingAdvisoryAttachmentsPreviewState = {
    loading: LoadingTypes.INIT,
    staffingAdvisoryPreviewAttachments: null,
    staffingAdvisoryPreviewTotalPage: null,
    staffingAdvisoryPreviewFileName: null,
    currentFile: null
};

export function staffingAdvisoryAttachmentsPreviewReducer(
    state: StaffingAdvisoryAttachmentsPreviewState,
    action: Action
) {
    return createReducer(
        initialState,
        on(
            clearStaffingAdvisory,
            clearStaffingAdvisoryAttachments,
            clearStaffingAdvisoryAttachmentsPreview,
            (state) => ({ ...initialState })
        ),

        on(loadStaffingAdvisoryAttachmentPreview, (state, action) => {
            return {
                ...state,
                loading: LoadingTypes.LOADING,
                staffingAdvisoryPreviewFileName: action.file.fileName,
                currentFile: action.file
            };
        }),
        on(loadStaffingAdvisoryAttachmentPreviewSuccess, (state, action) => {
            const file = action.file;
            return {
                ...state,
                loading: LoadingTypes.LOADED,
                staffingAdvisoryPreviewAttachments: file
            };
        }),
        on(loadStaffingAdvisoryAttachmentPreviewFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL
        })),

        on(loadStaffingAdvisoryPreviewPageCountSuccess, (state, action) => ({
            ...state,
            staffingAdvisoryPreviewTotalPage: action.total
        }))
    )(state, action);
}

export function reducer(state: StaffingAdvisoryAttachmentsPreviewState | undefined, action: Action) {
    return staffingAdvisoryAttachmentsPreviewReducer(state, action);
}
