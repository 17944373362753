import { createAction, props } from '@ngrx/store';
import { ShiftTemplateDetails, ShiftTemplateEdit } from '../../models';

export const loadShiftTemplateDetails = createAction(
    '[SHIFTS] Load Shift Template Details',
    props<{ templateId: number }>()
);

export const loadShiftTemplateDetailsFail = createAction(
    '[SHIFTS] Load Shift Template Details Fail',
    props<{ error: any }>()
);

export const loadShiftTemplateDetailsSuccess = createAction(
    '[SHIFTS] Load Shift Template Details Success',
    props<{ template: ShiftTemplateDetails }>()
);

export const saveShiftTemplateDetails = createAction(
    '[SHIFTS] Save Shift Template Details',
    props<{ template: ShiftTemplateEdit }>()
);

export const saveShiftTemplateDetailsFail = createAction(
    '[SHIFTS] Save Shift Template Details Fail',
    props<{ error: any }>()
);

export const saveShiftTemplateDetailsSuccess = createAction('[SHIFTS] Save Shift Template Details Success');

export const deleteShiftTemplate = createAction('[SHIFTS] Delete Shift Template', props<{ templateId: number }>());

export const deleteShiftTemplateFail = createAction('[SHIFTS] Delete Shift Template Fail', props<{ error: any }>());

export const deleteShiftTemplateSuccess = createAction('[SHIFTS] Delete Shift Template Success');

export const clearShiftTemplateDetails = createAction('[SHIFTS] Clear Shift Template Details');
