import { createAction, props } from '@ngrx/store';
import { Job } from 'src/app/jobs/models';

export enum JobsActionEnum {
    LoadJobDetails = '[SUBMITTALS] Load Job Details',
    LoadJobDetailsSuccess = '[SUBMITTALS] Load Job Details success',
    LoadJobDetailsFail = '[SUBMITTALS] Load Job Details fail',
    ClearJobDetails = '[SUBMITTALS] Clear Job Details'
}

export const loadJobDetails = createAction(JobsActionEnum.LoadJobDetails, props<{ jobId: number }>());

export const loadJobDetailsSuccess = createAction(JobsActionEnum.LoadJobDetailsSuccess, props<{ job: Job }>());

export const loadJobDetailsFail = createAction(JobsActionEnum.LoadJobDetailsFail, props<{ error: any }>());

export const clearJobDetails = createAction(JobsActionEnum.ClearJobDetails);
