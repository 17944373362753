<mat-calendar
    class="calendar-container"
    [dateClass]="dateClass"
    [dateFilter]="dateFilter"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [headerComponent]="multiHeader"
    (selectedChange)="onSelect($event)">
</mat-calendar>

<div class="calendar-actions" >
    <button
        class="mr-2" 
        mat-stroked-button
        color="accent"
        (click)="onSave()">
        Confirm
        <ng-container *ngIf="showConfirmationCount && selected?.length">
            ({{selected.length}})
        </ng-container>
    </button>    
    <button
        mat-stroked-button
        color="basic"
        (click)="onCancel()">
        Cancel
    </button>
</div>