import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { PendingChangesComponent } from 'src/app/shared/guards/pending-changes.guard';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { CandidateProfileTabDetails } from '../../vendor-candidate.model';
import { VendorCandidateService } from '../../vendor-candidate.service';

@Component({
    selector: 'ayac-vendor-candidate-details',
    templateUrl: './vendor-candidate-details.component.html',
    styleUrls: ['./vendor-candidate-details.component.scss']
})
export class VendorCandidateDetailsComponent extends UnsubscribeOnDestroy implements OnInit, PendingChangesComponent {
    saveCalled = new EventEmitter();

    isLoading: boolean = true;
    hasChanges = false;
    isFormValid = true;
    isSaving: boolean;
    vendorId: number = 0;
    candidateId: number = -1;
    candidateProfileTabDetails: CandidateProfileTabDetails;
    isProfileV1: boolean = false;
    disableV1Profile: boolean = false;
    isNewCandidateLoading: boolean = false;

    constructor(
        readonly router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _dialog: DialogService,
        private readonly _vendorCandidateService: VendorCandidateService
    ) {
        super();
    }

    ngOnInit(): void {
        this._route.params
            .pipe(
                map((params: Params) => params.id),
                takeUntil(this.d$)
            )
            .subscribe((candidateId: string) => {
                if (candidateId === 'new') {
                    this.isLoading = false;
                    this.isNewCandidateLoading = false;
                } else {
                    this.candidateId = Number(candidateId);
                    this.isNewCandidateLoading = false;
                }
            });
    }

    back(): void {
        this.router.navigateByUrl('/admin/vendorCandidates');
    }

    save(): void {
        this.isSaving = true;
        this.saveCalled.emit();
    }

    onIsSavingChanged(isSaving: boolean): void {
        this.isLoading = isSaving;
    }

    onProfileIsFormValidChanged(isValid: boolean): void {
        this.isFormValid = isValid;
    }

    onProfileFormValueChanged(candidateProfileTabDetails: CandidateProfileTabDetails): void {
        this.candidateProfileTabDetails = candidateProfileTabDetails;
        this.vendorId = candidateProfileTabDetails.candidate.vendorId;
        this.hasChanges = true;
    }

    onSaveSuccess(event: { candidateId: number; isNew: boolean }): void {
        if (event.isNew) {
            this.isNewCandidateLoading = true;
        }

        if (
            this.isProfileV1 &&
            (this.candidateProfileTabDetails.candidate.oldUserId === undefined ||
                this.candidateProfileTabDetails.candidate.oldUserId === null)
        ) {
            this.createProfileV1(event.isNew, event.candidateId);
        } else {
            this.openSavedSuccessfullyDialog(event.isNew, event.candidateId);
        }
    }

    onSaveError(event): void {
        this._dialog.openMigratedSnackBarError(`Error occurred trying to process your request.`);

        this.isSaving = false;
        this.isLoading = false;
    }

    onCandidateLoaded(candidateProfileTabDetails: CandidateProfileTabDetails): void {
        this.candidateProfileTabDetails = candidateProfileTabDetails;
        this.isProfileV1 = candidateProfileTabDetails.candidate.oldUserId > 0;
        this.disableV1Profile = candidateProfileTabDetails.candidate.oldUserId > 0;
        this.vendorId = candidateProfileTabDetails.candidate.vendorId;
        this.isLoading = false;
    }

    createNovaProfileClicked(isProfileV1: boolean): void {
        this.hasChanges = true;
        this.isProfileV1 = isProfileV1;
    }

    canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
        return this.hasChanges && !this.isSaving ? this.openChangedNotSavedDialog() : true;
    }

    private createProfileV1(isNew: boolean, candidateId: number): void {
        this._vendorCandidateService
            .addCandidateV1Profile(candidateId)
            .pipe(takeUntil(this.d$))
            .subscribe({
                next: () => {
                    this.disableV1Profile = true;
                    this.openSavedSuccessfullyDialog(isNew, candidateId);
                },
                error: () => {
                    console.error('Error occurred saving V1 Profile.');
                    this.openSavedSuccessfullyDialog(isNew, candidateId);
                }
            });
    }

    private openChangedNotSavedDialog(): Promise<boolean> {
        return this._dialog.openConfirmationDialog({
            data: {
                title: 'Vendor Candidate Details',
                text: 'You have unsaved changes! Do you want to leave?'
            }
        });
    }

    private openSavedSuccessfullyDialog(isNew: boolean, candidateId: number): void {
        this.hasChanges = false;
        this.isFormValid = false;
        this._dialog.openMigratedSnackBarSuccess(`${isNew ? 'Created' : 'Saved'} Successfully!`);
        if (isNew) {
            this.router.navigateByUrl(`/admin/vendorCandidate/${candidateId}`);
        }
    }
}
