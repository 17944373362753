<ng-container *ngIf="qualifications$ | async as qualifications">
    <mat-card class="license-and-cert-card" data-qa-id="ac_client_smd_details_license_and_certifications"
        *ngIf="qualifications.licenses?.length > 0 || qualifications.certifications?.length > 0 || qualifications.boardCertsifications?.length > 0">
        <mat-card-title class="title"><span>Certifications & Licenses</span></mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <ng-container *ngIf="qualifications.boardCertifications?.length > 0">
                <h3 class="sub-title">Board Certifications</h3>
                <table mat-table [dataSource]="qualifications.boardCertifications" class="license-and-cert-table"
                    aria-label="List of candidate's Board Certifications" data-qa-id="ac_client_smd_details_board_certifications_table">
                    <ng-container matColumnDef="expertiseName">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Name </th>
                        <td mat-cell *matCellDef="let boardCerts"> {{boardCerts.expertiseName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="boardStatusDescription">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Board Status </th>
                        <td mat-cell *matCellDef="let boardCerts"> {{boardCerts.boardStatusDescription}} </td>
                    </ng-container>

                    <ng-container matColumnDef="expirationDate">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Expiration Date </th>
                        <td mat-cell *matCellDef="let boardCerts"> {{boardCerts.expirationDate | date: 'MM/dd/yyyy'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="boardCertsDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: boardCertsDisplayedColumns;"></tr>
                </table>
            </ng-container>

            <ng-container *ngIf="qualifications.crnaBoardCertification">
                <h3 class="sub-title">Board Certification</h3>
                <table mat-table [dataSource]="[{name: 'NBCRNA', expirationDate: qualifications.crnaBoardCertification.expirationDate }]"
                    class="license-and-cert-table" aria-label="NBCRNA Board Certification" data-qa-id="ac_client_smd_details_nbcrna_board_certification_table">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Name </th>
                        <td mat-cell *matCellDef="let crnaCert"> {{crnaCert.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="expirationDate">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Expiration Date </th>
                        <td mat-cell *matCellDef="let crnaCert"> {{crnaCert.expirationDate | date: 'MM/dd/yyyy'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="crnaCertDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: crnaCertDisplayedColumns;"></tr>
                </table>
            </ng-container>

            <ng-container *ngIf="qualifications.certifications?.length > 0">
                <h3 class="cert-sub-title">Certifications</h3>
                <table mat-table [dataSource]="qualifications.certifications" class="license-and-cert-table"
                    aria-label="List of candidate's certifications"
                    data-qa-id="ac_client_smd_details_certification_table">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Name </th>
                        <td mat-cell *matCellDef="let certification"> {{certification.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="expirationDate">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Expiration Date </th>
                        <td mat-cell *matCellDef="let certification"> {{certification.expirationDate | date:
                            'MM/dd/yyyy'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="certificationDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: certificationDisplayedColumns;"></tr>
                </table>
            </ng-container>

            <ng-container *ngIf="qualifications.imlcLetterIssuingStateName">
                <h3 class="cert-sub-title">IMLC Letter</h3>
                <table mat-table [dataSource]="[{ name: qualifications.imlcLetterIssuingStateName }]" class="license-and-cert-table"
                    aria-label="IMLC Letter" data-qa-id="ac_client_smd_details_imlc_letter">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef scope="row"> State </th>
                        <td mat-cell *matCellDef="let state"> {{ state.name }} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="imlcLetterDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: imlcLetterDisplayedColumns;"></tr>
                </table>
            </ng-container>

            <ng-container *ngIf="qualifications.licenses?.length > 0">
                <h3 class="sub-title">State Licenses</h3>
                <table mat-table [dataSource]="qualifications.licenses" class="license-and-cert-table"
                    aria-label="List of candidate's licenses" data-qa-id="ac_client_smd_details_license_table">
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef scope="row"> State </th>
                        <td mat-cell *matCellDef="let license"> {{license.stateName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Number </th>
                        <td mat-cell *matCellDef="let license"> {{license.licenseNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="issuanceDate">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Issuance Date </th>
                        <td mat-cell *matCellDef="let license"> {{license.issuanceDate | date: 'MM/dd/yyyy'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="expirationDate">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Expiration Date </th>
                        <td mat-cell *matCellDef="let license"> {{license.expirationDate | date: 'MM/dd/yyyy'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="isCompact">
                        <th mat-header-cell *matHeaderCellDef scope="row"></th>
                        <td mat-cell *matCellDef="let license">
                            <mat-chip-list *ngIf="license.isCompact" aria-label="isCompact" class="qualif__exp-panel__chips">
                                <mat-chip class="compact-label mat-chip-no-interactivity" data-qa-id="ac_client_smd_details_license_table_compact_chip" disableRipple>Compact</mat-chip>
                            </mat-chip-list>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="licenseDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: licenseDisplayedColumns;"></tr>
                </table>
            </ng-container>

            <ng-container *ngIf="qualifications.deaLicenses?.length > 0">
                <h3 class="cert-sub-title">DEA Licenses</h3>
                <table mat-table [dataSource]="qualifications.deaLicenses" class="license-and-cert-table"
                    aria-label="List of candidate's DEA Licenses"
                    data-qa-id="ac_client_smd_details_deaLicenses_table">
                    <ng-container matColumnDef="stateName">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Name </th>
                        <td mat-cell *matCellDef="let license"> {{license.stateName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="expirationDate">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Expiration Date </th>
                        <td mat-cell *matCellDef="let license"> {{license.expirationDate | date:
                            'MM/dd/yyyy'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="deaLicensesDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: deaLicensesDisplayedColumns;"></tr>
                </table>
            </ng-container>

            <ng-container *ngIf="qualifications.csrCdsLicenses?.length > 0">
                <h3 class="cert-sub-title csr-title">CSR Licenses</h3>
                <mat-icon
                    matTooltip="Also known as ‘Controlled Dangerous Substance License
                    (CDS)' in certain states, or ‘Furnishing License’ in CA for
                    Nurse Practitioners"
                    class="mx-2 csr-info-tooltip">
                    info
                </mat-icon>
                <table mat-table [dataSource]="qualifications.csrCdsLicenses" class="license-and-cert-table"
                    aria-label="List of candidate's CSR Licenses"
                    data-qa-id="ac_client_smd_details_csrLicenses_table">
                    <ng-container matColumnDef="stateName">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Name </th>
                        <td mat-cell *matCellDef="let license"> {{license.stateName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="expirationDate">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Expiration Date </th>
                        <td mat-cell *matCellDef="let license"> {{license.expirationDate | date:
                            'MM/dd/yyyy'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="csrCdsLicensesDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: csrCdsLicensesDisplayedColumns;"></tr>
                </table>
            </ng-container>
        </mat-card-content>
    </mat-card>
</ng-container>
