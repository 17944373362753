import { createAction, props } from '@ngrx/store';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ShiftSubmittalCandidate } from 'src/app/shifts/models';
import { InternalPoolResouceAvailability } from '../../../shared/models/shifts';
import { ResourceList } from '../../../shared/models/internal-pool/resource-list.model';
import { ShiftSubmittalCandidatesGridQuery } from '../reducers/shift-submittal-candidates.reducer';

export const loadShiftSubmittalCandidates = createAction(
    '[SHIFTS] Load Shift Submittal Candidates',
    props<{
        silent?: boolean;
        systemId: number;
        internalPoolResourcesAvailability: InternalPoolResouceAvailability;
    }>()
);

export const loadShiftSubmittalCandidatesSuccess = createAction(
    '[SHIFTS] Load Shift Submittals Candidates Success',
    props<{ candidates: ResourceList[]; total: number }>()
);

export const loadShiftSubmittalCandidatesFail = createAction(
    '[SHIFTS] Load Shift Submittals Candidates Fail',
    props<{ error: any }>()
);

export const setShiftSubmittalCandidatesQuery = createAction(
    '[SHIFTS] Set Shift Submittals Candidates Query',
    props<{ query: Partial<ShiftSubmittalCandidatesGridQuery> }>()
);

export const clearShiftSubmittalCandidatesQuery = createAction('[SHIFTS] Clear Shift Submittals Candidates Query');

export const showSubmitShiftCandidateModal = createAction('[SHIFTS] Show Submit Shift Candidates Modal');

export const submitShiftCandidate = createAction(
    '[SHIFTS] Submit Shift Candidates',
    props<{ shiftId: number | string; seriesId: string; candidates: ShiftSubmittalCandidate[] }>()
);

export const submitShiftCandidateSuccess = createAction('[SHIFTS] Submit Shift Candidates Success');

export const submitShiftCandidateFail = createAction('[SHIFTS] Submit Shift Candidates Fail', props<{ error: any }>());

export const setShiftCandidatesQuery = createAction(
    '[SHIFTS] Set Shift Candidates Query',
    props<{ query: Partial<GridSearchQuery> }>()
);

export const clearShiftSubmittalCandidates = createAction('[SHIFTS] Clear Shift Submittal Candidates');
