<section class="help-main">
    <section class="matter">
        <div class="container-fluid">
            <div class="row">
                <div class="widget worange">
                    <h1 class="help-title">
                        <em class="glyphicon glyphicon-question-sign"></em>&nbsp;{{titleMain}}
                    </h1>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="widget worange">
                    <div class="widget-header">{{title}}</div>
                    <div class="widget-content">
                        <div class="row sub-title"
                             *ngIf="identity === 'vendor'">
                            <div>Partner Network Resources</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6"
                                 *ngFor="let item of documents">
                                <div class="flex-wrap">
                                    <div *ngFor="let documentItem of item.data">
                                        <ng-container *ngIf="!documentItem.hidden">
                                            <a target="_blank"
                                               href="{{documentItem.link}}"
                                               rel="noopener noreferrer"
                                               *ngIf="!documentItem.blob"
                                               class="external-link">
                                                <span class="material-icons-outlined external-icon">{{
                                                    documentItem.matIcon }}</span>{{documentItem.title}}
                                            </a>
                                            <a href=""
                                               (click)="download(documentItem.link, $event)"
                                               *ngIf="documentItem.blob">
                                                <img src="/content/images/pdf-gray-icon.png"
                                                     height="30px"
                                                     class="m-10"
                                                     alt="{{documentItem.title}}" />{{documentItem.title}}
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row sub-title"
                             *ngIf="identity === 'vendor'">
                            <div>Videos</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6"
                                 *ngFor="let item of videos">
                                <div class="flex-wrap">
                                    <div *ngFor="let documentItem of item.data">
                                        <ng-container *ngIf="!documentItem.hidden">
                                            <a target="_blank"
                                               href="{{documentItem.link}}"
                                               rel="noopener noreferrer"
                                               *ngIf="!documentItem.blob"
                                               class="external-link">
                                                <span class="material-icons-outlined external-icon">{{
                                                    documentItem.matIcon }}</span>{{documentItem.title}}
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="contacts.length">
                            <div class="row sub-title">
                                <div>Contact Information</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6"
                                     *ngFor="let item of contacts">
                                    <div class="flex-wrap">
                                        <div *ngFor="let documentItem of item.data">
                                            <div class="ml-2">{{documentItem.description}}</div>
                                            <a target="_blank"
                                               href="mailto:{{documentItem.email}}"
                                               rel="noopener noreferrer"
                                               class="external-link">
                                                <span
                                                      class="material-icons-outlined external-icon">email</span>{{documentItem.email}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row"
                                 *ngIf="identity === 'admin' || identity === 'vendor'">
                                <div class="col-sm-6">
                                    <div class="ml-2">For technical support, log in credentials and to deactivate new
                                        users, please contact:</div>
                                    <a target="_blank"
                                       href="mailto:PartnerConnectSupport@ayahealthcare.com"
                                       class="external-link">
                                        <span class="material-icons-outlined external-icon">email</span>
                                        PartnerConnectSupport&#64;ayahealthcare.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
