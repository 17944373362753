import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { freeAccessItems } from 'src/app/shared/models';
import { IdentityService } from 'src/app/shared/services/identity.service';

@Injectable({
    providedIn: 'root'
})
export class LDFeatureFlagGuard {
    constructor(
        private readonly _identityService: IdentityService,
        private readonly _router: Router,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const isSignedIn = this._identityService.isSignedIn();
        const parentUrl = route.parent.url.toString();

        if (!isSignedIn && !(freeAccessItems.includes(parentUrl) || freeAccessItems.includes(route.url.join('/')))) {
            return of(false);
        }

        return this._ldFeatureManager.isEnabled(route.data.featureFlag).pipe(
            map((isEnabled) => {
                if (isEnabled) {
                    return true;
                } else {
                    let url = route.data.url;

                    if (route.params) {
                        // Replace url parameter placeholder (ex. ':id') with real value
                        for (const [key, value] of Object.entries(route.params)) {
                            // Deal with optional parameters from AngularJS
                            if (url.includes(`:${key}?`)) {
                                url = url.replace(`:${key}?`, value);
                            } else {
                                url = url.replace(`:${key}`, value);
                            }
                        }
                    }

                    if (route.queryParams) {
                        const params = new URLSearchParams(route.queryParams);
                        url = `${url}?${params.toString()}`;
                    }

                    this._router.navigateByUrl(url);

                    return false;
                }
            })
        );
    }
}
