import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { systemActions } from 'src/app/systems/store';

import { DialogService } from 'src/app/shared/services/dialog.service';
import { AdminSystemTearingService } from 'src/app/systems/services/admin-system-tearing.service';
import { IConfirmationReasonDialogOptions } from 'src/app/shared/models/dialog.models';
import { ConfirmationReasonDialogComponent } from 'src/app/shared/components/dialog/confirmation-reason-dialog/confirmation-reason-dialog.component';
import { ApplicationFeature } from 'src/app/shared/models';

@Injectable()
export class SystemSettingsTearingEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _adminSystemTearingService: AdminSystemTearingService,
        private readonly _dialogService: DialogService
    ) {}

    shiftTearingDisabled$ = createEffect(() =>
        this._actions$.pipe(
            ofType(systemActions.confirmUpdateSystemSetting),
            filter(
                (action) =>
                    action.current.featureId === ApplicationFeature.Allow_Shifts_Tiering &&
                    action.current.isEnabled === false
            ),
            map((action) => systemActions.showConfirmationDisableTearing({ systemId: action.systemId }))
        )
    );

    confirmationSucceed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(systemActions.saveShiftsTearingDisableReasonSuccess),
            map((action) => systemActions.applyUpdateSystemSetting())
        )
    );

    confirmationFailed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                systemActions.loadShiftsTearingDisableCountFailed,
                systemActions.saveShiftsTearingDisableReasonFail,
                systemActions.cancelConfirmationDisableTearing
            ),
            map((action) => systemActions.revertUpdateSystemSetting())
        )
    );

    showConfirmationDisableTearing$ = createEffect(() =>
        this._actions$.pipe(
            ofType(systemActions.showConfirmationDisableTearing),
            switchMap((action) => {
                return this._adminSystemTearingService.getDisableCount(action.systemId).pipe(
                    switchMap((count) =>
                        this.showDisabledTearingConfirmation(count).pipe(
                            map((reason) => {
                                if (reason) {
                                    return systemActions.saveShiftsTearingDisableReason({
                                        systemId: action.systemId,
                                        reason: reason.reason
                                    });
                                }

                                return systemActions.cancelConfirmationDisableTearing();
                            })
                        )
                    ),
                    catchError((err) => of(systemActions.loadShiftsTearingDisableCountFailed({ error: err.error })))
                );
            })
        )
    );

    saveShiftsTearingDisableReason$ = createEffect(() =>
        this._actions$.pipe(
            ofType(systemActions.saveShiftsTearingDisableReason),
            switchMap((action) => {
                return this._adminSystemTearingService.addReason(action.systemId, action.reason).pipe(
                    map(() => systemActions.saveShiftsTearingDisableReasonSuccess()),
                    catchError((err) => of(systemActions.saveShiftsTearingDisableReasonFail({ error: err.error })))
                );
            })
        )
    );

    private showDisabledTearingConfirmation(count: number): Observable<{ reason: string } | null> {
        const options: IConfirmationReasonDialogOptions = {
            title: 'Remove Shift Tiering',
            text:
                `<p class="mb-3"><strong>By clicking ‘Confirm’, <b>${count} shifts</b> ` +
                `will immediately lose their tiering setup from this system.</strong></p>` +
                `<p class="mb-3">When re enabling this feature, the default release settings will not be applied to any existing shifts. ` +
                `Each shift will need to be configured based on release preferences.</p>`,
            isHtml: true,
            confirmButtonText: 'Confirm',
            isInputRequired: true,
            inputLabel: 'Reason',
            inputPlaceholder: 'Please provide an explanation for turning this feature off',
            inputMaxLength: 100
        };
        const dialogRef = this._dialogService.openDialog(ConfirmationReasonDialogComponent, {
            width: '560px',
            data: options
        });

        return dialogRef.afterClosed();
    }
}
