<ng-container *ngIf="(qualifications$ | async)?.emrSystems as emrSystems">
    <mat-card class="emr-card" data-qa-id="ac_client_smd_details_emr" *ngIf="emrSystems.length > 0">
        <mat-card-title class="title"><span>EMR Systems</span></mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <div class="emr-content" data-qa-id="ac_client_smd_details_emr_list">
                <div class="field-title">Has used</div>
                <div class="field-value" data-qa-id="ac_client_smd_emr_list_items">
                    {{emrSystems | stringList}}
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>
