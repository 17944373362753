<loading-indicator *ngIf="isLoading$ | async"></loading-indicator>
<div class="board pre2019">
    <ng-container *ngIf="details$ | async as details">
        <div class="pre-header">
            <div class="pre-header__left">
                <button mat-flat-button color="accent" class="migrated-btn-info" [disabled]="isLoading$ | async" [routerLink]="['/admin', 'submittals']">
                    <mat-icon class="fa-sharp fa-regular fa-hand-point-left"></mat-icon><span>Back</span>
                </button>
                <button mat-flat-button color="accent" class="migrated-btn-info" [disabled]="(hasChanges$ | async) === false" [routerLink]="['/admin', 'submittals']">
                    <mat-icon class="reflect-icon">refresh</mat-icon><span>Cancel</span>
                </button>
                <button mat-flat-button color="accent" class="migrated-btn-info" [disabled]="(canSave$ | async) === false" (click)="save()">
                    <mat-icon>save</mat-icon><span>Save</span>
                </button>
                <span *ngIf="hasChanges$ | async"
                      class="dissolve-animation">
                    <em class="fa fa-asterisk fa-asterisk-large"></em>
                </span>
            </div>
        </div>

        <div class="header title">
            <div>
                Submittal: {{ details?.candidateFullName }} - {{ details?.expertiseName }} &#64; {{ details?.jobId > 0 ? details?.facilityName : details?.offer.facilityName }}
            </div>
        </div>

        <div class="content">
            <ayac-shared-submittal-details
                [submittalDetails]="details$ | async"
                [candidateInfo]="candidateInfo$ | async"
                [hasOfferDetailsPerms]="hasOfferDetailsPerms"
                [noteLabel]="noteLabel"
                [isAdmin]="true"
                [submittalStages$]="submittalStages$"
                (saveSubmittal)="updateSubmittal($event)"
                (hasNewChanges)="incomingChanges($event)"
                (updateStage)="updateStage($event)"
                (goViewJobDetails)="goToJobDetails($event)"
                (goViewCandidateDetails)="goToCandidateDetails($event)"
                (goViewVendorDetails)="goToVendorDetails($event)">
            </ayac-shared-submittal-details>
        </div>
    </ng-container>
</div>


