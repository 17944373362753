import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Inject, Injectable } from '@angular/core';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToasterService } from 'src/app/core/services';
import { TravelersService } from 'src/app/travelers/services/travelers.service';
import * as TravelerDocumentsHistoryActions from 'src/app/travelers/state/actions/travelers-documents-history.action';
import * as fromTravelers from 'src/app/travelers/state/index';
import { Store } from '@ngrx/store';

@Injectable()
export class TravelersDocumentsHistoryEffect {
    loadTravelerDocumentsHistory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TravelerDocumentsHistoryActions.loadTravelerDocumentsHistory),
            concatLatestFrom(() => this.store.select(fromTravelers.selectContractId)),
            switchMap(([action, contractId]) =>
                this.travelersService$.getTravelerDocumentsHistory(contractId, action.requirementId).pipe(
                    map((travelerDocumentsHistory) => {
                        return TravelerDocumentsHistoryActions.loadTravelerDocumentsHistorySuccess({
                            docHistory: travelerDocumentsHistory
                        });
                    }),
                    catchError((error: any) =>
                        of(TravelerDocumentsHistoryActions.loadTravelerDetailsForDocumentsHistoryFail({ error: error }))
                    )
                )
            )
        );
    });

    loadTravelerDocumentsHistoryFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(TravelerDocumentsHistoryActions.loadTravelerDetailsForDocumentsHistoryFail),
                tap((action) => {
                    this.toaster.fail('A problem occurred while trying to load the document history.');
                })
            );
        },
        { dispatch: false }
    );

    openDocument$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(TravelerDocumentsHistoryActions.openDocument),
                concatLatestFrom(() => this.store.select(fromTravelers.selectContractId)),
                tap(([action, contractId]) => {
                    this.window.open(`/#/client/workers/${contractId}/docs/${action.candidateDocumentId}`, '_blank');
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly travelersService$: TravelersService,
        private readonly toaster: ToasterService,
        private readonly store: Store,
        @Inject('Window') private readonly window: Window
    ) {}
}
