import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    TravelerProfile,
    TravelerJob,
    SclDepartment,
    SclSupervisor,
    PayrollLocationModel,
    BuildingModel,
    FloorModel,
    DepartmentModel,
    BusinessUnitModel
} from 'src/app/travelers/travelers.models';
import {
    clearTravelerJobs,
    loadTraveler,
    loadTravelerJobs,
    travelerLoadSuccess,
    travelerLoadError,
    travelerJobsLoadSuccess,
    travelerJobFilterRequest,
    travelerJobFilterSuccess,
    travelerJobsLoadError,
    sclDepartmentsLoadSuccess,
    sclSupervisorsLoadSuccess,
    loadSclDepartments,
    loadSclSupervisors,
    clearTravelerDetails,
    saveTravelerProfile,
    travelerProfileSaveSuccess,
    travelerProfileSaveError,
    saveTravelerProfileNyu,
    travelerProfileSaveNyuSuccess,
    travelerProfileSaveNyuError,
    travelerDetailsUpdated,
    sclDepartmentUpdated,
    updateMspFieldsVisibility,
    loadPayrollLocationLookups,
    payrollLocationLookupsLoadSuccess,
    payrollLocationLookupsLoadError,
    loadBuildingLookups,
    buildingLookupsLoadSuccess,
    buildingLookupsLoadError,
    loadFloorLookups,
    floorLookupsLoadSuccess,
    floorLookupsLoadError,
    loadDepartmentLookups,
    departmentLookupsLoadSuccess,
    departmentLookupsLoadError,
    loadBusinessUnitLookups,
    businessUnitLookupsLoadSuccess,
    businessUnitLookupsLoadError
} from '../actions/travelers-details.actions';

export interface TravelersDetailsState {
    traveler: TravelerProfile;
    sclDepartment: SclDepartment;
    sclDepartments: SclDepartment[];
    sclSupervisors: SclSupervisor[];
    detailsLoading: LoadingTypes;
    error: string;
    canSave: boolean;

    travelerJobs: TravelerJob[];
    filteredTravelerJobs: TravelerJob[];
    showPrismaFields: boolean;
    showBjcFields: boolean;
    totalTravelerJobs: number;
    jobsLoading: LoadingTypes;

    lookupsLoading: LoadingTypes;
    buildingLookups: BuildingModel[];
    floorLookups: FloorModel[];
    departmentLookups: DepartmentModel[];
    payrollLocationLookups: PayrollLocationModel[];
    businessUnitLookups: BusinessUnitModel[];
}

const initialJobsState = {
    travelerJobs: [],
    filteredTravelerJobs: [],
    showPrismaFields: false,
    showBjcFields: false,
    totalTravelerJobs: 0,
    jobsLoading: LoadingTypes.INIT
};

export const initialState: TravelersDetailsState = {
    traveler: null,
    travelerJobs: [],
    filteredTravelerJobs: [],
    showPrismaFields: false,
    showBjcFields: false,
    totalTravelerJobs: 0,
    sclDepartment: null,
    sclDepartments: [],
    sclSupervisors: [],
    detailsLoading: LoadingTypes.INIT,
    lookupsLoading: LoadingTypes.INIT,
    jobsLoading: LoadingTypes.INIT,
    error: '',
    canSave: false,
    buildingLookups: [],
    floorLookups: [],
    departmentLookups: [],
    payrollLocationLookups: [],
    businessUnitLookups: []
};

const reducer = createReducer(
    initialState,
    on(loadTraveler, (state) => ({
        ...state,
        detailsLoading: LoadingTypes.LOADING
    })),
    on(loadTravelerJobs, (state) => ({
        ...state,
        jobsLoading: LoadingTypes.LOADING
    })),
    on(saveTravelerProfile, (state) => ({
        ...state,
        detailsLoading: LoadingTypes.LOADING
    })),
    on(travelerLoadSuccess, (state, { traveler }) => ({
        ...state,
        traveler: traveler,
        detailsLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(travelerLoadError, (state, { error }) => ({
        ...state,
        traveler: null,
        detailsLoading: LoadingTypes.FAIL,
        error: error
    })),
    on(saveTravelerProfileNyu, (state) => ({
        ...state,
        detailsLoading: LoadingTypes.LOADING
    })),
    on(travelerProfileSaveNyuSuccess, (state) => ({
        ...state,
        detailsLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(travelerProfileSaveNyuError, (state, { error }) => ({
        ...state,
        detailsLoading: LoadingTypes.FAIL,
        error: error
    })),
    on(travelerJobsLoadSuccess, (state, { jobs }) => ({
        ...state,
        travelerJobs: jobs,
        filteredTravelerJobs: jobs,
        totalTravelerJobs: jobs.length,
        jobsLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(updateMspFieldsVisibility, (state, action) => ({
        ...state,
        showPrismaFields: action.showPrismaFields,
        showBjcFields: action.showBjcFields
    })),
    on(travelerJobsLoadError, (state, { error }) => ({
        ...state,
        travelerJobs: [],
        jobsLoading: LoadingTypes.FAIL,
        error: error
    })),
    on(travelerJobFilterRequest, (state) => ({
        ...state,
        jobsLoading: LoadingTypes.LOADING
    })),
    on(travelerJobFilterSuccess, (state, { filteredJobs }) => ({
        ...state,
        filteredTravelerJobs: filteredJobs.data,
        totalTravelerJobs: filteredJobs.total,
        jobsLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(loadSclDepartments, (state) => ({
        ...state,
        detailsLoading: LoadingTypes.LOADING
    })),
    on(loadSclSupervisors, (state) => ({
        ...state,
        detailsLoading: LoadingTypes.LOADING
    })),
    on(sclDepartmentsLoadSuccess, (state, { departments: departments, travelerDepartment: travelerDepartment }) => ({
        ...state,
        sclDepartment: travelerDepartment,
        sclDepartments: departments,
        detailsLoading: LoadingTypes.LOADED
    })),
    on(sclSupervisorsLoadSuccess, (state, { supervisors }) => ({
        ...state,
        sclSupervisors: supervisors,
        detailsLoading: LoadingTypes.LOADED
    })),
    on(sclDepartmentUpdated, (state, { department }) => ({
        ...state,
        sclDepartment: department
    })),
    on(travelerProfileSaveSuccess, (state) => ({
        ...state,
        detailsLoading: LoadingTypes.LOADED,
        canSave: false
    })),
    on(travelerProfileSaveError, (state) => ({
        ...state,
        detailsLoading: LoadingTypes.FAIL
    })),
    on(clearTravelerDetails, (state) => ({
        ...state,
        ...initialState
    })),
    on(clearTravelerJobs, (state) => ({
        ...state,
        ...initialJobsState
    })),
    on(travelerDetailsUpdated, (state) => ({
        ...state,
        canSave: true
    })),
    on(travelerLoadSuccess, (state, { traveler }) => ({
        ...state,
        travelerName: traveler.travelerName
    })),
    on(loadBuildingLookups, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.LOADING
    })),
    on(buildingLookupsLoadSuccess, (state, { buildingLookups }) => ({
        ...state,
        buildingLookups: buildingLookups,
        lookupsLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(buildingLookupsLoadError, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.FAIL
    })),
    on(loadFloorLookups, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.LOADING
    })),
    on(floorLookupsLoadSuccess, (state, { floorLookups }) => ({
        ...state,
        floorLookups: floorLookups,
        lookupsLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(floorLookupsLoadError, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.FAIL
    })),
    on(loadDepartmentLookups, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.LOADING
    })),
    on(departmentLookupsLoadSuccess, (state, { departmentLookups }) => ({
        ...state,
        departmentLookups: departmentLookups,
        lookupsLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(departmentLookupsLoadError, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.FAIL
    })),
    on(loadPayrollLocationLookups, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.LOADING
    })),
    on(payrollLocationLookupsLoadSuccess, (state, { payrollLocationLookups }) => ({
        ...state,
        payrollLocationLookups: payrollLocationLookups,
        lookupsLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(payrollLocationLookupsLoadError, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.FAIL
    })),
    on(loadBusinessUnitLookups, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.LOADING
    })),
    on(businessUnitLookupsLoadSuccess, (state, { businessUnitLookups }) => ({
        ...state,
        businessUnitLookups: businessUnitLookups,
        lookupsLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(businessUnitLookupsLoadError, (state) => ({
        ...state,
        lookupsLoading: LoadingTypes.FAIL
    }))
);

export function travelersDetailsReducer(state: TravelersDetailsState | undefined, action: Action) {
    return reducer(state, action);
}
