<table mat-table
[dataSource]="dataSource"
class="document-upload-info-table"
aria-describedby="attachmentDataSourceMat">

<ng-container matColumnDef="name">
    <th mat-header-cell
        *matHeaderCellDef
        class="file-upload-label"> Name </th>
    <td mat-cell
        *matCellDef="let attachment">
        {{ attachment.name }}
    </td>
</ng-container>

<ng-container matColumnDef="lastModifiedDate">
    <th mat-header-cell
        *matHeaderCellDef
        class="file-upload-label">Uploaded Date</th>
    <td mat-cell
        *matCellDef="let attachment"> {{ attachment.uploadedDate |
        fixTimezoneOffset | date:
        'MM/dd/yyyy - h:mm a' }}</td>
</ng-container>

<ng-container matColumnDef="uploadedBy">
    <th mat-header-cell
        *matHeaderCellDef
        class="file-upload-label">Added By</th>
    <td mat-cell
        *matCellDef="let attachment">{{ attachment.uploadedBy }}</td>
</ng-container>

<ng-container matColumnDef="actions">
    <th mat-header-cell
        *matHeaderCellDef></th>
    <td mat-cell
        *matCellDef="let attachment">
        <ng-container *ngIf="!isEdit">
            <button mat-icon-button
                    color="warn"
                    (click)="deleteAttachmentClick()"
                    [attr.data-qa-id]="'vendorCandidateDocumentDeleteBtn'">
                <mat-icon>delete</mat-icon>
            </button>
        </ng-container>
    </td>
</ng-container>

<tr mat-header-row
    *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row
    *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<ng-container *ngIf="!attachmentDataHasValue">
    <div class="no-data-container">
        No file added
    </div>
</ng-container>
