<div>
    <ng-container
        *ngFor="let backgroundHistoryForm of formArray.controls; let i = index"
    >
        <div
            class="candidate-profile-control-container background-history-form-row"
            [formGroup]="backgroundHistoryForm"
        >
            <ayac-form-control-wrapper
                class="question-control"
                [controlLabel]="backgroundHistoryForm.get('text')?.value"
                labelPosition="top"
            >
                <mat-button-toggle-group
                    color="accent"
                    [attr.data-qa-id]="'Question' + (i + 1)"
                    formControlName="answer"
                    (change)="backgroundHistoryForm.get('details').setValue('')"
                    [required]="!isAdminForm"
                    class="migrated-btn-toggle-group"
                >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                </mat-button-toggle-group>

                <ayac-form-control-wrapper
                    [isCentered]="false"
                    class="details-control"
                    *ngIf="backgroundHistoryForm.get('answer')?.value === 'Yes'"
                >
                    <mat-form-field appearance="outline">
                        <textarea
                            matInput
                            placeholder="Details"
                            formControlName="details"
                            [attr.data-qa-id]="'Details' + (i + 1)"
                            rows="10"
                            cols="50"
                            maxlength="500"
                        ></textarea>
                    </mat-form-field>
                </ayac-form-control-wrapper>
            </ayac-form-control-wrapper>
        </div>
    </ng-container>
</div>
