import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { TravelerExtensionModel } from '../../extension/traveler-extension.model';
import * as ExtensionActions from '../actions/travelers-extension.actions';

export interface ExtensionDetailsState {
    extension: TravelerExtensionModel;
    error: string;
    loading: LoadingTypes;
}

const initialState: ExtensionDetailsState = {
    extension: null,
    error: '',
    loading: LoadingTypes.INIT
};

export function extensionDetailsReducer(state: ExtensionDetailsState, action: Action) {
    return createReducer(
        initialState,
        on(ExtensionActions.loadExtensionDetails, ExtensionActions.completeExtensionRequest, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(ExtensionActions.loadExtensionDetailsSuccess, (state, action) => ({
            ...state,
            extension: action.extension,
            loading: LoadingTypes.LOADED
        })),

        on(
            ExtensionActions.loadExtensionDetailsFail,
            ExtensionActions.completeExtensionRequestFail,
            (state, action) => ({
                ...state,
                error: action.error,
                loading: LoadingTypes.FAIL
            }
        ))
    )(state, action);
}

export function reducer(state: ExtensionDetailsState | undefined, action: Action) {
    return extensionDetailsReducer(state, action);
}
