import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Inject, Injectable } from '@angular/core';
import { switchMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToasterService } from 'src/app/core/services';
import { TravelersService } from 'src/app/travelers/services/travelers.service';
import * as TravelerDocumentsActions from 'src/app/travelers/state/actions/travelers-documents.actions';
import { downloadBlob } from 'src/app/core/utils';
import { Store } from '@ngrx/store';
import { selectGridState, selectTraveler } from '..';

@Injectable()
export class TravelersDocumentsEffect {
    constructor(
        @Inject('Window') private readonly window: Window,
        private readonly actions$: Actions,
        private readonly travelersService$: TravelersService,
        private readonly toaster: ToasterService,
        private readonly store$: Store<{}>
    ) {}

    loadTravelerDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDocumentsActions.loadTravelerDocuments),
            switchMap((action) =>
                this.travelersService$.getTravelerDocuments(action.contractId).pipe(
                    map((documents) => {
                        return TravelerDocumentsActions.documentsLoadSuccess({ documents: documents });
                    }),
                    catchError((error) => of(TravelerDocumentsActions.documentsLoadError({ error: error })))
                )
            )
        )
    );

    downloadSelectedTravelerDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDocumentsActions.loadSelectedTravelerDocumentsRequest),
            switchMap((action) =>
                this.travelersService$.downloadTravelerDocuments(action.contractId, action.documentIds).pipe(
                    map((response) => {
                        return TravelerDocumentsActions.loadDocumentSuccess({
                            blob: response,
                            filename: `ContractFiles-${action.contractId}.pdf`
                        });
                    }),
                    catchError((error) => of(TravelerDocumentsActions.loadDocumentFail({ error: error })))
                )
            )
        )
    );

    downloadAllTravelerDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDocumentsActions.loadAllTravelerDocumentsRequest),
            switchMap((action) =>
                this.travelersService$.downloadAllTravelerDocuments(action.contractId).pipe(
                    map((response) => {
                        return TravelerDocumentsActions.loadDocumentSuccess({
                            blob: response,
                            filename: `ContractFiles-${action.contractId}.pdf`
                        });
                    }),
                    catchError((error) => of(TravelerDocumentsActions.loadDocumentFail({ error: error })))
                )
            )
        )
    );

    filterDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDocumentsActions.documentsFilterRequest),
            withLatestFrom(this.store$.select(selectGridState)),
            switchMap(([action, state]) => {
                return this.travelersService$.filterTravelerDocuments(state, action.documents).pipe(
                    map((result) => {
                        return TravelerDocumentsActions.documentsFilterSuccess({ filteredDocuments: result });
                    }),
                    catchError((error) => of(TravelerDocumentsActions.documentsFilterFail({ error: error })))
                );
            })
        )
    );

    filterDocumentsByTitleOrCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDocumentsActions.documentsFilterRequestByTitleOrCategory),
            switchMap((action) => {
                return this.travelersService$.filterTravelerDocuments(action.state, action.documents).pipe(
                    map((result) => {
                        return TravelerDocumentsActions.documentsFilterSuccess({ filteredDocuments: result });
                    }),
                    catchError((error) => of(TravelerDocumentsActions.documentsFilterFail({ error: error })))
                );
            })
        )
    );

    openDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDocumentsActions.openDocument),
            switchMap((action) =>
                of(
                    TravelerDocumentsActions.openDocumentInViewer({
                        contractId: action.contractId,
                        documentId: action.documentId
                    })
                )
            )
        )
    );

    openDocumentInViewer$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDocumentsActions.openDocumentInViewer),
                tap((action) => {
                    this.window.open(`/#/client/workers/${action.contractId}/docs/${action.documentId}`, '_blank');
                })
            ),
        { dispatch: false }
    );

    downloadDocument$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDocumentsActions.loadDocumentSuccess),
                tap((action) => {
                    downloadBlob(action.blob, action.filename);
                })
            ),
        { dispatch: false }
    );

    loadDocumentsFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDocumentsActions.loadDocumentFail),
                tap((action) => this.toaster.fail('Failed to download document'))
            ),
        { dispatch: false }
    );

    exportGrid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDocumentsActions.exportGrid),
            withLatestFrom(this.store$.select(selectTraveler)),
            switchMap(([action, traveler]) =>
                this.travelersService$.exportGrid(action.contractId).pipe(
                    map((blob) =>
                        TravelerDocumentsActions.exportGridSuccess({
                            blob,
                            filename: `${traveler.travelerFirstName} ${traveler.travelerLastName}-Documents Summary.xlsx`
                        })
                    ),
                    catchError((error) => of(TravelerDocumentsActions.exportGridFail({ error })))
                )
            )
        )
    );

    downloadGridExcel$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDocumentsActions.exportGridSuccess),
                tap((action) => {
                    downloadBlob(action.blob, action.filename);
                })
            ),
        { dispatch: false }
    );

    updateClearedStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDocumentsActions.updateClearedStatus),
            switchMap((action) =>
                this.travelersService$.updateClearedStatus(action.contractId, action.clearedToStart).pipe(
                    map((_) => TravelerDocumentsActions.updateClearedStatusSuccess({ contractId: action.contractId })),
                    catchError((error) => of(TravelerDocumentsActions.updateClearedStatusFail({ error })))
                )
            )
        )
    );

    updateClearedStatusSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TravelerDocumentsActions.updateClearedStatusSuccess),
            map((action) => {
                this.toaster.success(`Worker Status successfully updated.`);
                return TravelerDocumentsActions.loadTravelerDetailsForDocuments({ contractId: action.contractId });
            })
        )
    );

    updateClearedStatusFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TravelerDocumentsActions.updateClearedStatusFail),
                map((_) => this.toaster.fail('Unable to update Worker Status. Please try again.'))
            ),
        { dispatch: false }
    );
}
