<ayac-file-attachments-grid
[id]="vendorId"
[grid]="gridData$ | async"
[searchQuery]="searchQuery$ | async"
[deletingFileIds]="deletingFileIds$ | async"
[fileTypes]="fileTypes$ | async"
[loading]="loading$ | async"
[isDeleting]="isDeleting$ | async"
[isUploading]="isUploading"
[message]="'vendor'"
(addFiles)="onFileSelected($event)"
(downloadFile)="onDownloadFile($event)"
(deleteFile)="onDeleteFile($event)"
(refreshGrid)="refreshGrid()"
(dataStateChange)="onDataStateChange($event)"
>

</ayac-file-attachments-grid>
