<div class="banner pa-3" [ngClass]="className" *ngIf="!isHidden"
    data-qa-id="banner">
    <div class="d-flex align-items-center">
        <ng-container *ngIf="icon">
            <mat-icon data-qa-id="banner-icon" class="mr-2 flex-no-shrink"
            [ngClass]="iconClassName">{{icon}}</mat-icon>
        </ng-container>
        <div class="flex-fill banner-content">
            <ng-content></ng-content>
        </div>
        <button mat-icon-button
            *ngIf="!closeHidden"
            (click)="onClose()"
            class="mat-icon-button-small ml-2 flex-no-shrink"
            data-qa-id="banner-close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>