import { BaseResponse } from '../../shared/models/base-response';
import { JobsFilteredResponse } from 'src/app/jobs/models/responses/jobs-filtered-response';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings, APP_CONFIG } from 'src/app/config/settings';
import { Observable } from 'rxjs';
import { ApproveJobResponse } from '../models/responses/approve-job-response';
import { RejectJobResponse } from '../models/responses/reject-job-response';
import { JobsUrl } from '../models/enums/jobs-url.enum';
import { JobsLookupsResponse } from '../models/responses/jobs-lookups-response';
import { JobPayrollLocationLookupsResponse } from '../models/responses/job-payroll-location-lookups-response';
import { JobDetailsResponse } from '../models/responses/job-details-response';
import { map } from 'rxjs/operators';
import { Submittal } from '../models/submittal.model';
import { FilledPosition } from '../models/filled-position';
import { interpolateParams, interpolateSearch, interpolateUrl } from 'src/app/core/utils';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { GroupMetadataResponse } from '../models/responses/group-metadata.response';
import { JobTemplateResponse } from '../models/responses/job-template.response';
import { JobSaveModel } from '../models/job-save.model';
import { FacilityMetadataResponse } from '../models/responses/facility-metadata.response';
import { Job, JobSaveResponse, ProfessionLookup } from '../models';
import { WorkLocation } from '../models/work-location.model';
import { JobBusinessUnitLookupsResponse } from 'src/app/jobs/models/responses/job-business-unit-lookups-response';
import { JobBuildingLookupsResponse } from 'src/app/jobs/models/responses/job-building-lookups-response';
import { JobDepartmentLookupsResponse } from 'src/app/jobs/models/responses/job-department-lookups-response';
import { JobFloorLookupsResponse } from 'src/app/jobs/models/responses/job-floor-lookups-response';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ResourceList } from 'src/app/shared/models/internal-pool/resource-list.model';
import { SpecialtyLookup } from 'src/app/shared/models/lookups/specialty-lookup.model';
import { ListItem } from 'src/app/shared/models/list-item';
import { LineOfBusinessEnum } from 'src/app/shared/models/facilities/enums/line-of-business.enum';
import { SortTypes } from 'src/app/shared/models';
import { JobBillingRateResponse } from '../models/responses/job-billingrate-response';
import { GetCoverageTypesResponse } from '../models/responses/get-coverage-types-response';
import * as dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class JobsRepositoryService {
    interpolateSearchFunc = interpolateSearch;
    getJobSaveModel(job: Job): JobSaveModel {
        const startDate = job.startDate ? dayjs(job.startDate).format('YYYY-MM-DD') + 'T00:00:00Z' : null;
        return {
            id: job.id || 0,
            employmentType: job.employmentType,
            hospitalId: job.facilityId,
            expertiseCode: job.specialtyId,
            professionCode: job.professionId,
            billingRate: job.billRate,
            jobTitle: job.title,
            prismaRequisitionId: job.prismaRequisitionId,
            numPositions: job.positions,
            startDate,
            description: job.description,

            altShift: job.isAlternating,
            hours: job.hours,
            shifts: job.shifts,
            shiftsAlt: job.shiftsAlt,
            hoursAlt: Number(job.hoursAlt),
            shiftM: job.shiftM,
            shiftH: job.shiftH,
            shiftToM: job.shiftToM,
            shiftToH: job.shiftToH,
            shiftAltM: job.shiftAltM,
            shiftAltH: job.shiftAltH,
            shiftAltToM: job.shiftAltToM,
            shiftAltToH: job.shiftAltToH,

            isStandardShiftStartHoursFlexible: job.isStandardShiftStartHoursFlexible,
            isAlternatingShiftStartHoursFlexible: job.isAlternatingShiftStartHoursFlexible,
            isStandardShiftEndHoursFlexible: job.isStandardShiftEndHoursFlexible,
            isAlternatingShiftEndHoursFlexible: job.isAlternatingShiftEndHoursFlexible,

            unitId: job.unitId,
            templateId: job.templateId,
            jobEnteredFrom: 7, // Magic number
            releaseSchedulingEnabled: job.releaseSchedulingEnabled,
            isTriggerScheduledReleases: job.isTriggerScheduledReleases,
            durationWeeks: job.durationWeeks,

            bjcPositionRequestId: job.bjcPositionRequestId,
            bjcPositionId: job.bjcPositionId,
            payrollLocationId: job.payrollLocationId,
            isUnionJob: job.isUnionJob,
            openReason: job.jobOpenReasonId,
            facilityAddress: job.facilityAddress,
            facilityCity: job.facilityCity,
            facilityZip: job.facilityZip,
            facilityState: job.facilityState,
            workLocationId: job.workLocationId,
            businessUnitId: job.businessUnitId,
            departmentId: job.departmentId,
            buildingId: job.buildingId,
            floorId: job.floorId,
            kerberosId: job.kerberosId,
            chartString: job.chartString,
            rateHigh: job.rateHigh,
            rateLow: job.rateLow,
            clientJobId: job.clientJobId,
            unionCodeId: job.unionCodeId,
            isOnHold: job.isOnHold,

            customFields: job.customFields,
            coverageTypeId: job.coverageTypeId,
            isShiftTypeDay: job.isShiftTypeDay,
            isShiftTypeSwing: job.isShiftTypeSwing,
            isShiftTypeNight: job.isShiftTypeNight,
            isShiftTypeWeekend: job.isShiftTypeWeekend,
            isBlockSchedule: job.isBlockSchedule
        };
    }

    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings
    ) {}

    getJobs(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        const params = interpolateParams({
            ...pagination,
            ...sortArgs,
            ...matchArgs
        });
        return this.http.get<JobsFilteredResponse>(`${this.settings.CORE}${JobsUrl.JOBS}`, { params });
    }

    getJobById(jobId: number): Observable<JobDetailsResponse> {
        return this.http.get<JobDetailsResponse>(
            `${this.settings.CORE}${JobsUrl.JOBS_DETAILS}?ViewTypeId=3&JobId=` + jobId
        );
    }

    deleteJob(jobId: number): Observable<BaseResponse> {
        return this.http.post<BaseResponse>(`${this.settings.CORE}${JobsUrl.JOBS_DELETE}`, { id: jobId }).pipe(
            map((response) => {
                if (response.responseStatus === 0) {
                    throw '';
                } else {
                    return response;
                }
            })
        );
    }

    getJobsLookups(systemId?: number): Observable<JobsLookupsResponse> {
        if (systemId !== null) {
            return this.http.get<JobsLookupsResponse>(
                `${this.settings.CORE}${JobsUrl.JOBS_LOOKUPS}?SystemId=${systemId}&ViewTypeId=3&`
            );
        } else {
            return this.http.get<JobsLookupsResponse>(`${this.settings.CORE}${JobsUrl.JOBS_LOOKUPS}?ViewTypeId=3&`);
        }
    }

    getPayrollLocationLookups(businessUnitId: number, filter: string): Observable<JobPayrollLocationLookupsResponse> {
        return this.http.get<JobPayrollLocationLookupsResponse>(
            `${this.settings.CORE}${JobsUrl.JOBS_PAYROLL_LOCATION_LOOKUPS}?businessUnitId=${businessUnitId}&filter=${filter}`
        );
    }

    getBusinessUnitLookups(systemId: number): Observable<JobBusinessUnitLookupsResponse> {
        return this.http.get<JobBusinessUnitLookupsResponse>(
            `${this.settings.CORE}${JobsUrl.JOBS_BUSINESS_UNIT_LOOKUPS}/${systemId}`
        );
    }

    getBuildingLookups(filter: string): Observable<JobBuildingLookupsResponse> {
        return this.http.get<JobBuildingLookupsResponse>(
            `${this.settings.CORE}${JobsUrl.JOBS_BUILDING_LOOKUPS}?filter=${filter}`
        );
    }

    getDepartmentLookups(businessUnitId: number, filter: string): Observable<JobDepartmentLookupsResponse> {
        return this.http.get<JobDepartmentLookupsResponse>(
            `${this.settings.CORE}${JobsUrl.JOBS_DEPARTMENT_LOOKUPS}?businessUnitId=${businessUnitId}&filter=${filter}`
        );
    }

    getFloorLookups(buildingId: number, filter: string): Observable<JobFloorLookupsResponse> {
        return this.http.get<JobFloorLookupsResponse>(
            `${this.settings.CORE}${JobsUrl.JOBS_BUILDING_LOOKUPS}/${buildingId}/floors?filter=${filter}`
        );
    }

    approveJobs(ids: number[]): Observable<ApproveJobResponse> {
        return this.http.post<ApproveJobResponse>(`${this.settings.CORE}${JobsUrl.JOBS_APPROVE}`, {
            ViewTypeId: 3,
            ids
        });
    }

    rejectJobs(comment: string, ids: number[]): Observable<ApproveJobResponse> {
        return this.http.post<RejectJobResponse>(`${this.settings.CORE}${JobsUrl.JOBS_REJECT}`, {
            ViewTypeId: 3,
            comment,
            ids
        });
    }

    export(filteredArgs: GridSearchQuery) {
        const params = this.interpolateSearchFunc(filteredArgs);
        const url = JobsUrl.EXPORT;
        return this.http.get(`${this.settings.CORE}${url}`, { params, responseType: 'blob' });
    }

    setJobPositions(jobId: number, positionsCount: number): Observable<BaseResponse> {
        return this.http
            .post<BaseResponse>(
                `${this.settings.CORE}${JobsUrl.JOBS_CONTROLLER}/${jobId}/positions/open/${positionsCount}`,
                {}
            )
            .pipe(
                map((response) => {
                    if (response.responseStatus === 0) {
                        throw '';
                    } else {
                        return response;
                    }
                })
            );
    }

    getJobSubmittals(jobId: number): Observable<Submittal[]> {
        return this.http.get<Submittal[]>(`${this.settings.CORE}${JobsUrl.JOBS_CONTROLLER}/${jobId}/submittals`);
    }

    getJobInternalSubmittals(jobId: number): Observable<Submittal[]> {
        const url = interpolateUrl(JobsUrl.JOB_INTERNAL_POOL_SUBMITALS, { jobId });
        return this.http.get<Submittal[]>(`${this.settings.CORE}${url}`);
    }

    getJobFilledPositions(jobId: number): Observable<FilledPosition[]> {
        return this.http.get<FilledPosition[]>(
            `${this.settings.CORE}${JobsUrl.JOBS_CONTROLLER}/${jobId}/positions?status=1`
        );
    }

    getGroupMetadata(facilityGroupId: number) {
        const url = JobsUrl.GROUP_METADATA;
        const params = interpolateParams({
            facilityGroup: facilityGroupId
        });

        return this.http.get<GroupMetadataResponse>(`${this.settings.CORE}${url}`, { params });
    }

    getFacilityMetadata(facilityGroupId: number, professionId: number, expertiseId: number) {
        const url = JobsUrl.FACILITY_METADATA;
        const params = interpolateParams({
            facilityGroup: facilityGroupId,
            profession: professionId,
            expertise: expertiseId
        });

        return this.http.get<FacilityMetadataResponse>(`${this.settings.CORE}${url}`, { params });
    }

    saveJob(job: JobSaveModel) {
        const url = JobsUrl.SAVE_JOB;
        const body = {
            viewTypeId: 3,
            job
        };

        return this.http.post<JobSaveResponse>(`${this.settings.CORE}${url}`, body);
    }

    getJobTemplate(jobTemplateId) {
        const url = interpolateUrl(JobsUrl.JOB_TEMPLATE, { jobTemplateId });
        const params = interpolateParams({
            id: jobTemplateId
        });

        return this.http.get<JobTemplateResponse>(`${this.settings.CORE}${url}`, { params });
    }

    getHospitalId(facilityGroupId: number, professionId: number) {
        const url = JobsUrl.HOSPITAL_PROFILE;
        const params = interpolateParams({
            facilityProfileGroupId: facilityGroupId,
            professionId
        });

        return this.http.get<number>(`${this.settings.CORE}${url}`, { params });
    }

    savePrismaJob(job: JobSaveModel) {
        const url = JobsUrl.SAVE_PRISMA_JOB;
        const body = {
            jobId: job.id,
            prismaRequisitionId: job.prismaRequisitionId
        };

        return this.http.post<number>(`${this.settings.CORE}${url}/${job.id}`, body);
    }

    saveBjcJob(job: JobSaveModel) {
        const url = JobsUrl.SAVE_BJC_JOB;
        const body = {
            jobId: job.id,
            bjcPositionRequestId: job.bjcPositionRequestId,
            bjcPositionId: job.bjcPositionId
        };

        return this.http.post<number>(`${this.settings.CORE}${url}/${job.id}`, body);
    }

    saveNyuJob(job: JobSaveModel) {
        const url = JobsUrl.SAVE_NYU_JOB;
        const body = {
            jobId: job.id,
            payrollLocationId: job.payrollLocationId,
            isUnionJob: job.isUnionJob,
            businessUnitId: job.businessUnitId,
            departmentId: job.departmentId,
            buildingId: job.buildingId,
            floorId: job.floorId,
            kerberosId: job.kerberosId,
            chartString: job.chartString,
            unionCodeId: job.unionCodeId
        };

        return this.http.post<number>(`${this.settings.CORE}${url}/${job.id}`, body);
    }

    getWorkLocations(facilityProfileGroupId: number) {
        const url = JobsUrl.WORK_LOCATIONS;

        return this.http.get<WorkLocation[]>(`${this.settings.CORE}${url}/facilities/${facilityProfileGroupId}`);
    }

    getJobCandidates(jobId: number, query: GridSearchQuery) {
        const url = interpolateUrl(JobsUrl.JOB_CANDIDATES, { jobId });
        const params = interpolateSearch(query);

        return this.http.get<PagingToken<ResourceList[]>>(`${this.settings.CORE}${url}`, { params });
    }

    getCandidateLookups() {
        const url = JobsUrl.JOB_CANDIDATE_LOOKUPS;
        type CandidateLookupsResponse = {
            specialties: SpecialtyLookup[];
            shiftSubmittalStatuses: ListItem[];
            availabilityStatuses: ListItem[];
            epicSkills: ListItem[];
            languageSkillLevels: ListItem[];
            languages: ListItem[];
        };

        return this.http.get<CandidateLookupsResponse>(`${this.settings.CORE}${url}`);
    }

    submitCandidates(jobId: number, candidateIds: number[]) {
        const url = interpolateUrl(JobsUrl.JOB_SUBMIT_CANDIDATES, { jobId });
        const body = {
            jobId,
            candidateIds
        };

        return this.http.post<unknown>(`${this.settings.CORE}${url}`, body);
    }

    getPrecalculatedBillingRate(
        facilityId: number,
        professionId: number,
        expertiseId: number,
        shiftH?: number,
        hours?: number
    ) {
        const url = JobsUrl.JOB_BILLINGRATE;
        const params = interpolateParams({
            facilityId,
            professionId,
            expertiseId,
            shiftHours: shiftH,
            hours
        });

        return this.http.get<JobBillingRateResponse>(`${this.settings.CORE}${url}`, { params });
    }

    getLineOfBusinessProfessions(lineOfBusiness: LineOfBusinessEnum) {
        return this.http.get<ProfessionLookup[]>(
            `${this.settings.CORE}/ayaconnect/jobclient/line-of-business/${lineOfBusiness}/professions`
        );
    }

    getCoverageTypes(): Observable<GetCoverageTypesResponse> {
        return this.http.get<GetCoverageTypesResponse>(`${this.settings.CORE}/ayaconnect/jobclient/coverage-types`);
    }

    // releases all positions under multiple jobs
    releaseAllJobsPositions(jobIds: number[], vendorIds: number[]): Observable<unknown> {
        const params = {
            entityIds: jobIds,
            vendorIds
        };
        const url = `${this.settings.CORE}/AyaConnect/JobClient/manual-release-positions`;
        return this.http.put(url, params);
    }

    // releases all positions under one jobs
    releaseAllJobPositions(jobId: number, vendorIds: number[]): Observable<unknown> {
        const url = `${this.settings.CORE}/AyaConnect/JobClient/${jobId}/manual-release-positions`;
        return this.http.put(url, vendorIds);
    }

    // releases one positions under one jobs
    releaseJobPosition(jobId: number, jobPositionId: number, vendorIds: number[]): Observable<unknown> {
        const url = `${this.settings.CORE}/AyaConnect/JobClient/${jobId}/positions/${jobPositionId}/manual-release-position`;
        return this.http.put(url, vendorIds);
    }
}
