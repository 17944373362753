<kendo-dropdownlist 
    [defaultItem]="defaultItem"
    [data]="listItems"
    [value]="comparisonFilter.type"
    [valuePrimitive]="true"
    textField="text"
    valueField="value"
    [style.width.px]="120"
    (selectionChange)="selectionChange($event)"
    [ngClass]="uniqueFilterClass + '--dropdown'"
    #dropDownInput>
</kendo-dropdownlist>

<kendo-numerictextbox
    class="submittals-count-textbox"
    [spinners]="false"
    [style.width]="'46px'"
    [value]="comparisonFilter.value"
    [min]="0"
    valueChangeDelay="500"
    format="#"
    (afterValueChanged)="onAfterValueChange($event)"
    [ngClass]="uniqueFilterClass + '--numTextBox'"
    #textBoxInput>
</kendo-numerictextbox>
