import { createAction, props } from '@ngrx/store';
import { DocumentHistoryDto } from '../../traveler-details/traveler-documents-history-modal/document-history-dto';

export const loadTravelerDocumentsHistory = createAction(
    '[Travelers Document Grid] Load Documents History',
    props<{ requirementId: number }>()
);

export const loadTravelerDocumentsHistorySuccess = createAction(
    '[Travelers Document History Modal] Load Traveler Document History Success',
    props<{ docHistory: DocumentHistoryDto[] }>()
);
export const loadTravelerDetailsForDocumentsHistoryFail = createAction(
    '[Travelers Document History Modal] Load Traveler Document History Fail',
    props<{ error: string }>()
);

export const clearTravelerDocumentHistoryDetailsOnModalClose = createAction(
    '[Travelers Document History Modal] Clear Traveler Document History Details On Modal Close'
);

export const openDocument = createAction(
    '[Travelers Document History Modal] Open Document',
    props<{ candidateDocumentId: number }>()
);
