import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    exportInvoices,
    exportInvoicesFail,
    exportInvoicesSuccess,
    exportInvoiceLineDisputes,
    exportInvoiceLineDisputesFail,
    exportInvoiceLineDisputesSuccess,
    exportInvoiceLineDisputesHistory,
    exportInvoiceLineDisputesHistoryFail,
    exportInvoiceLineDisputesHistorySuccess
} from '../invoices.actions';

export interface InvoicesExportState {
    loading: LoadingTypes;
}

const initialState: InvoicesExportState = {
    loading: LoadingTypes.INIT
};

export function invoicesExportReducer(state: InvoicesExportState, action: Action) {
    return createReducer(
        initialState,
        on(exportInvoices, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(exportInvoiceLineDisputes, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(exportInvoiceLineDisputesHistory, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(exportInvoicesSuccess, (state) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(exportInvoiceLineDisputesSuccess, (state) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(exportInvoiceLineDisputesHistorySuccess, (state) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(exportInvoicesFail, (state) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(exportInvoiceLineDisputesFail, (state) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(exportInvoiceLineDisputesHistoryFail, (state) => ({
            ...state,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: InvoicesExportState | undefined, action: Action) {
    return invoicesExportReducer(state, action);
}
