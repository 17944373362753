import { createAction, props } from '@ngrx/store';
import { FileAttachment, FileValidationResult } from 'src/app/shared/models/attachment';
import { ListItem } from 'src/app/shared/models/list-item';
import { StaffingAdvisoryEditModel } from 'src/app/shared/models/travelers/staffing-advisory-edit.model';
import { StaffingAdvisory } from 'src/app/travelers/models/staffing-advisory.model';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { UnitLookup } from 'src/app/shared/models/lookups/unit-lookup.model';
import { SystemLookup } from 'src/app/shared/models/lookups/system-lookup.model';

export const loadStaffingAdvisories = createAction(
    '[Travelers Detail Component] Load Staffing Advisories',
    props<{ systemId: number; facilityId: number; unitId: number; travelerId: number }>()
);
export const loadStaffingAdvisoriesSuccess = createAction(
    '[Travelers Detail Service] Load Staffing Advisories Success',
    props<{ staffingAdvisories: Array<StaffingAdvisory> }>()
);
export const loadStaffingAdvisoriesFail = createAction(
    '[Travelers Detail Service] Load Staffing Advisories Fail',
    props<{ error: any }>()
);

export const saveStaffingAdvisory = createAction(
    '[Traveler Staffing Advisory] Add Staffing Advisory',
    props<{ staffingAdvisory: StaffingAdvisoryEditModel; files: any }>()
);
export const saveStaffingAdvisorySuccess = createAction(
    '[Traveler Staffing Advisory] Add Staffing Advisory Success',
    props<{ staffingAdvisoryId: number; files: any }>()
);
export const saveStaffingAdvisoryFail = createAction(
    '[Traveler Staffing Advisory] Add Staffing Advisory Fail',
    props<{ error: any }>()
);

export const loadStaffingAdvisoryLookups = createAction('[Traveler Staffing Advisory] Load Staffing Advisory Lookups');
export const loadStaffingAdvisoryLookupsSuccess = createAction(
    '[Traveler Staffing Advisory] Load Staffing Advisory Lookups Success',
    props<{
        restrictionLevels: ListItem[];
        categories: ListItem[];
        reasons: ListItem[];
    }>()
);
export const loadStaffingAdvisoryLookupsFail = createAction(
    '[Traveler Staffing Advisory] Load Staffing Advisory Fail',
    props<{ error: any }>()
);

export const deleteStaffingAdvisory = createAction(
    '[Traveler Staffing Advisory] Delete Staffing Advisory',
    props<{ staffingAdvisoryId: number }>()
);
export const deleteStaffingAdvisorySuccess = createAction(
    '[Traveler Staffing Advisory] Delete Staffing Advisory Success',
    props<{ staffingAdvisoryId: number }>()
);
export const deleteStaffingAdvisoryFail = createAction(
    '[Traveler Staffing Advisory] Delete Staffing Advisory Fail',
    props<{ error: any }>()
);

export const clearStaffingAdvisory = createAction('[Traveler Staffing Advisory] Clear Staffing Advisory');

/* attachments */
export const deleteStaffingAdvisoryAttachment = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Delete Job Attachments',
    props<{ staffingAdvisoryId: number; id: number }>()
);
export const deleteStaffingAdvisoryAttachmentSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Delete Staffing Advisory Attachments success'
);
export const deleteStaffingAdvisoryAttachmentFail = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Delete Staffing Advisory Attachments fail',
    props<{ error: any }>()
);

export const saveStaffingAdvisoryAttachment = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Save Staffing Advisory Single Attachment',
    props<{ staffingAdvisoryId: number; fileToUpload: any }>()
);
export const saveStaffingAdvisoryAttachmentSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Save Staffing Advisory Single Attachment success',
    props<{ fileValidationResult: FileValidationResult[]; staffingAdvisoryId: number }>()
);
export const saveStaffingAdvisoryAttachmentFail = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Save Staffing Advisory Single Attachment fail',
    props<{ fileValidationResult: FileValidationResult; error: any }>()
);

export const saveStaffingAdvisoryAttachments = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Save Staffing Advisory Multiple Attachments',
    props<{ staffingAdvisoryId: number; filesToUpload: any[] }>()
);
export const saveStaffingAdvisoryAttachmentsSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Save Staffing Advisory Multiple Attachments success',
    props<{ fileValidationResult: FileValidationResult[]; staffingAdvisoryId: number }>()
);
export const saveStaffingAdvisoryAttachmentsFail = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Save Staffing Advisory Multiple Attachments fail',
    props<{ error: any }>()
);

export const loadStaffingAdvisoryAttachments = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Staffing Advisory Attachments',
    props<{ staffingAdvisoryIds: number[] }>()
);
export const loadStaffingAdvisoryAttachmentsSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Staffing Advisory Attachments success',
    props<{ staffingAdvisoryAttachments: FileAttachment[] }>()
);
export const loadStaffingAdvisoryAttachmentsFail = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Staffing Advisory Attachments fail',
    props<{ error: any }>()
);

export const loadStaffingAdvisoryAttachment = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Staffing Advisory Attachment',
    props<{ staffingAdvisoryId: number; id: number; file: any }>()
);
export const loadStaffingAdvisoryAttachmentSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Staffing Advisory Attachment success'
);
export const loadStaffingAdvisoryAttachmentFail = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Staffing Advisory Attachment fail',
    props<{ error: any }>()
);

export const updateStaffingAdvisoryAttachment = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Update Staffing Advisory Attachments',
    props<{ staffingAdvisoryId: number; fileToUpdate: any }>()
);
export const updateStaffingAdvisoryAttachmentSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Update Staffing Advisory Attachments success',
    props<{ staffingAdvisoryId: number }>()
);
export const updateStaffingAdvisoryAttachmentFail = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Update Staffing Advisory Attachments fail',
    props<{ error: any }>()
);

export const clearStaffingAdvisoryAttachments = createAction(
    '[STAFFING ADVISORY ATTACHMENT] Clear Staffing Advisory Attachments'
);

export const loadStaffingAdvisoriesSecurityLookups = createAction(
    '[TRAVELER STAFFING ADVISORY EDIT COMPONENT] Load Staffing Advisory Security Lookups'
);
export const loadStaffingAdvisoriesSecurityLookupsSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY SERVICE] Load Staffing Advisory Security Lookups Success',
    props<{ system: SystemLookup; systems: SystemLookup[]; facilities: FacilityLookup[]; units: UnitLookup[] }>()
);
export const loadStaffingAdvisoriesSecurityLookupsSuccessV2 = createAction(
    '[TRAVELER STAFFING ADVISORY SERVICE] Load Staffing Advisory Security Lookups Success v2',
    props<{ systems: SystemLookup[]; facilities: FacilityLookup[]; units: UnitLookup[] }>()
);
export const loadStaffingAdvisoriesSecurityLookupsFail = createAction(
    '[TRAVELER STAFFING ADVISORY SERVICE] Load Staffing Advisory Security Lookups Fail',
    props<{ error: any }>()
);

export const loadStaffingAdvisoryAttachmentPreview = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Staffing Advisory Attachment Preview',
    props<{ staffingAdvisoryId: number; pageNum: number; file: FileAttachment }>()
);
export const loadStaffingAdvisoryAttachmentPreviewSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Staffing Advisory Attachment Preview Success',
    props<{ file: Blob }>()
);
export const loadStaffingAdvisoryAttachmentPreviewFail = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Staffing Advisory Attachment Preview Fail',
    props<{ error: any }>()
);

export const clearStaffingAdvisoryAttachmentsPreview = createAction(
    '[STAFFING ADVISORY ATTACHMENT] Clear Staffing Advisory Attachments Preview'
);

export const loadStaffingAdvisoryPreviewNextPage = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Preview Next Page',
    props<{ pageNum: number }>()
);
export const loadStaffingAdvisoryPreviewPageCount = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Preview Page Count Attachments',
    props<{ staffingAdvisoryId: number; fileId: number }>()
);
export const loadStaffingAdvisoryPreviewPageCountSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Preview Page Count Attachments Success',
    props<{ total: number }>()
);
export const loadStaffingAdvisoryPreviewPageCountFail = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Load Preview Page Count Attachments fail',
    props<{ error: any }>()
);

export const downloadStaffingAdvisoryAttachment = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Download Staffing Advisory Attachment',
    props<{ staffingAdvisoryId: number; fileId: number; fileName: string }>()
);
export const downloadStaffingAdvisoryAttachmentSuccess = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Download Staffing Advisory Attachment success'
);
export const downloadStaffingAdvisoryAttachmentFail = createAction(
    '[TRAVELER STAFFING ADVISORY ATTACHMENT] Download Staffing Advisory Attachment fail',
    props<{ error: any }>()
);
