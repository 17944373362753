import { LoadingTypes } from 'src/app/shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import { PermJob } from 'src/app/permanent-jobs/models/perm-job.model';
import { PermJobGridActions } from '../actions';

export interface PermJobsGridState {
    permJobs: PermJob[];
    permJobsTotal: number;
    loading: LoadingTypes;
}

export const initialState: PermJobsGridState = {
    permJobs: [],
    permJobsTotal: 0,
    loading: LoadingTypes.INIT
};

type SetQueryAndGetPermJobsSuccessActionType = ReturnType<typeof PermJobGridActions.setQueryAndGetPermJobsSuccess>;

export const permJobsGridReducer = createReducer(
    initialState,
    on(
        PermJobGridActions.setQueryAndGetPermJobs,
        (state: PermJobsGridState): PermJobsGridState => ({
            ...state,
            loading: LoadingTypes.LOADING
        })
    ),
    on(
        PermJobGridActions.setQueryAndGetPermJobsSuccess,
        (state: PermJobsGridState, action: SetQueryAndGetPermJobsSuccessActionType): PermJobsGridState => ({
            ...state,
            permJobs: action.permJobs,
            permJobsTotal: action.permJobsTotal,
            loading: LoadingTypes.LOADED
        })
    ),
    on(
        PermJobGridActions.setQueryAndGetPermJobsFail,
        (state: PermJobsGridState): PermJobsGridState => ({
            ...state,
            permJobs: [],
            permJobsTotal: 0,
            loading: LoadingTypes.FAIL
        })
    ),
    on(
        PermJobGridActions.exportPermJobs,
        (state: PermJobsGridState): PermJobsGridState => ({
            ...state,
            loading: LoadingTypes.LOADING
        })
    ),
    on(
        PermJobGridActions.exportPermJobsSuccess,
        (state: PermJobsGridState): PermJobsGridState => ({
            ...state,
            loading: LoadingTypes.LOADED
        })
    ),
    on(
        PermJobGridActions.exportPermJobsFailure,
        (state: PermJobsGridState): PermJobsGridState => ({
            ...state,
            loading: LoadingTypes.FAIL
        })
    )
);

export const reducer = (state: PermJobsGridState | undefined, action: Action) => {
    return permJobsGridReducer(state, action);
};
