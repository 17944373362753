<ng-container *ngIf="activities && activities.length > 0" terminology>
    <mat-card class="activity-card" data-qa-id="ac_client_smd_details_activities">
        <mat-card-title class="title">
            <div class="activity-title-info">
                <span>Activity & Comments</span>
                <mat-icon id="pending-overdue" data-qa-id="ac_client_smd_details_activities_pending_overdue"
                    *ngIf="isPendingOverdue"
                    matTooltip="{{'Submittal has been pending review for over 2 days' | terminology}}" color="error"
                    class="mx-2">
                    error
                </mat-icon>
            </div>

            <ng-container *ifLdFeature="featureFlag.VendorClientCommunicationForNonVMSVendorSubmittals;">
                <button *ngIf="isAyaAccountManagement && source === 'Vendor'" mat-flat-button color="accent" class="add-comment-btn" (click)="addNewComment()" data-qa-id="ac_add_comment_btn">
                    <mat-icon>add</mat-icon>
                    Add Comment
                </button>
            </ng-container>
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <ng-container *ngIf="showChangeSortOrder">
                <form id="sort-form" class="order-selection" [formGroup]="sortForm">
                    <mat-form-field appearance="outline" class="remove-mat-field-bottom-padding">
                        <mat-select formControlName="sortOrder" (selectionChange)="updateSortOrder($event)"
                            data-qa-id="ac_client_smd_details_sort_select">
                            <mat-option value="asc">Oldest to Newest</mat-option>
                            <mat-option value="desc">Newest to Oldest</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
                <mat-divider></mat-divider>
            </ng-container>
            <div class="activity" *ngFor="let activity of activities; let i = index" [attr.data-note-submittalNoteId]="activity.submittalNoteId">
                <div class="activity-row">
                    <span class="date" data-qa-id="ac_client_smd_details_activity_date">
                        {{ activity.activityDate | fixTimezoneOffset | date : 'MMMM d, yyyy' }}
                        {{ activity.activityDate + 'Z' | date : 'h:mm a' }} {{localTimeZoneName}}
                    </span>
                    <div>
                        <button [attr.id]="'reply-btn' + i" class="reply-btn" (click)="replyTo(activity)"
                            *ngIf="showReplyButton" [attr.data-qa-id]="'ac_client_smd_details_activity_reply_btn_' + i">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_3858_11223)">
                                    <path
                                        d="M12.6667 12.3333V9.66667C12.6667 9.11111 12.4722 8.63889 12.0833 8.25C11.6944 7.86111 11.2222 7.66667 10.6667 7.66667H4.55L6.95 10.0667L6 11L2 7L6 3L6.95 3.93333L4.55 6.33333H10.6667C11.5889 6.33333 12.375 6.65833 13.025 7.30833C13.675 7.95833 14 8.74444 14 9.66667V12.3333H12.6667Z"
                                        fill="#2A7393" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_3858_11223">
                                        <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </div>

                <!-- Submittal created -->
                <ng-container *ngIf="activity.noteType === noteType.Created">
                    <div class="activity-row">
                        <span class="activity-header"
                            [attr.data-qa-id]="'ac_client_smd_details_activity_header_' + i">Candidate Submitted
                            -
                            {{activity.status}}
                        </span>
                    </div>
                    <div class="activity-row comment-wrapper" *ngIf="activity.notes">
                        <div class="comment-header">
                            Comment
                        </div>
                        <div class="comment" [attr.data-qa-id]="'ac_client_smd_details_activity_comment_' + i"
                            [innerHtml]="activity.notes"></div>
                    </div>
                </ng-container>

                <!-- Submittal status changed -->
                <ng-container
                    *ngIf="activity.noteType === noteType.StatusChange || activity.noteType === noteType.MigratedNote">
                    <div class="activity-row">
                        <span class="activity-header"
                            [attr.data-qa-id]="'ac_client_smd_details_activity_header_' + i">Status Change
                            {{ activity.status ? ' - ' + activity.status : '' }}
                            <span *ngIf="activity.subStatus" class="activity-header-sub-status">
                                ({{ activity.subStatus ?  activity.subStatus : '' }})
                            </span>
                        </span>
                    </div>
                    <div class="activity-row comment-wrapper" *ngIf="activity.notes">
                        <div class="comment-header"
                            [attr.data-qa-id]="'ac_client_smd_details_activity_comment_header_' + i">Comment
                        </div>
                        <div class="comment" [attr.data-qa-id]="'ac_client_smd_details_activity_comment_' + i"
                            [innerHtml]="activity.notes"></div>
                    </div>
                </ng-container>

                <!-- Comment reply -->
                <ng-container *ngIf="activity.noteType === noteType.ReplyNote">
                    <div class="activity-row">
                        <span class="activity-header" *ngIf="activity.userType !== source"
                            [attr.data-qa-id]="'ac_client_smd_details_activity_comment_from_' + i">Comment from
                            {{activity.userType}}</span>
                        <span class="activity-header" *ngIf="activity.userType === source"
                            [attr.data-qa-id]="'ac_client_smd_details_activity_comment_to_' + i">Comment to
                            {{activity.replyNote?.userType}}</span>
                    </div>
                    <div class="activity-row comment-wrapper" *ngIf="activity.replyNote">
                        <div class="comment-header reply" *ngIf="activity.replyNote.noteType === noteType.StatusChange || activity.replyNote.noteType === noteType.MigratedNote; else replyNote">
                            Status Change {{ activity.replyNote.status ? ' - ' + activity.replyNote.status : '' }}
                            <span *ngIf="activity.replyNote.subStatus" class="activity-header-sub-status">
                                    ({{ activity.replyNote.subStatus ?  activity.replyNote.subStatus : '' }})
                            </span>
                            - {{activity.replyNote.activityDate | fixTimezoneOffset | date : 'MMMM d, yyyy' }} {{
                                activity.replyNote.activityDate + 'Z' | date : 'h:mm a' }} {{localTimeZoneName}}
                        </div>

                        <ng-template #replyNote>
                            <div class="comment-header reply"
                                [attr.data-qa-id]="'ac_client_smd_details_activity_comment_header_' + i">Comment
                                from
                                {{activity.replyNote.userType}} - {{
                                activity.replyNote.activityDate | fixTimezoneOffset | date : 'MMMM d, yyyy' }} {{
                                activity.replyNote.activityDate + 'Z' | date : 'h:mm a' }} {{localTimeZoneName}}
                            </div>

                        </ng-template>

                        <div class="comment reply"
                             [attr.data-qa-id]="'ac_client_smd_details_activity_comment_reply_' + i"
                             *ngIf="activity.replyNote.notes"
                             [innerHTML]="activity.replyNote.notes">
                        </div>

                    </div>
                    <div class="activity-row comment-wrapper" [attr.data-qa-id]="'ac_client_smd_details_activity_comment_' + i"
                        [innerHtml]="activity.notes"></div>

                </ng-container>

                <!-- Note Added -->
                <ng-container *ngIf="activity.noteType === noteType.AddNote">
                    <div class="activity-row">
                        <span class="activity-header"
                            [attr.data-qa-id]="'ac_client_smd_details_activity_header_' + i">{{ activity.userType }} left a comment
                        </span>
                    </div>
                    <div class="activity-row comment-wrapper" *ngIf="activity.notes">
                        <div class="comment" [attr.data-qa-id]="'ac_client_smd_details_activity_comment_' + i"
                            [innerHtml]="activity.notes"></div>
                    </div>
                </ng-container>

            </div>
        </mat-card-content>
    </mat-card>
</ng-container>
