<mat-card>
    <mat-card-header>
        <mat-card-title>Notes</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="content"
        *ngIf="editMode; then formTemplate; else viewTemplate">
    </mat-card-content>

    <ng-template #viewTemplate>
        <ng-container *ngIf="resource$ | async as resource">
            <div class="div k-form-inline">
                <p>
                    {{ resource.notes }}
                </p>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #formTemplate>
        <ng-container *ngIf="updateForm$ | async"></ng-container>
        <form [formGroup]="form" class="k-form">
            
            <div class="k-form-field">
                <textarea kendoTextArea formControlName="notes" class="form-field-control form-control-long" rows="7"></textarea>
                <validation-message label="Email" [control]="form.get('notes')">
                </validation-message>
            </div>
        </form>
    </ng-template>
</mat-card>