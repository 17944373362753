import { createAction, props } from '@ngrx/store';
import {
    TravelerProfile,
    TravelerJobGetDto,
    TravelerJob,
    TravelerProfileUpdate,
    SclSupervisor,
    SclDepartment,
    TravelerJobAddDto,
    TravelerPrismaPositionDto,
    TravelerProfileNyuUpdate,
    PayrollLocationModel,
    BuildingModel,
    FloorModel,
    DepartmentModel,
    BusinessUnitModel
} from 'src/app/travelers/travelers.models';
import { GridStateChangeEvent } from 'src/app/shared/grid/models';
import { GridDataResult } from '@progress/kendo-angular-grid';

export const loadTraveler = createAction('[Travelers] Load Traveler Profile', props<{ id: number }>());
export const travelerLoadSuccess = createAction(
    '[Travelers] Traveler Profile Load success',
    props<{ traveler: TravelerProfile }>()
);
export const travelerLoadError = createAction('[Travelers] Traveler Profile Load Error', props<{ error: any }>());
export const loadTravelerJobs = createAction('[Travelers] Load Traveler Jobs', props<{ request: TravelerJobGetDto }>());
export const travelerJobsLoadSuccess = createAction(
    '[Travelers] Traveler Jobs Load success',
    props<{ jobs: TravelerJob[] }>()
);
export const updateMspFieldsVisibility = createAction(
    '[Travelers] Update Msp Fields Visibility',
    props<{ showPrismaFields: boolean; showBjcFields: boolean }>()
);
export const travelerJobsLoadError = createAction('[Travelers] Traveler Jobs Load Error', props<{ error: any }>());
export const saveTravelerProfile = createAction(
    '[Travelers] Save Traveler Profile',
    props<{ traveler: TravelerProfileUpdate }>()
);
export const travelerProfileSaveSuccess = createAction('[Travelers] Traveler Profile Saved');
export const travelerProfileSaveError = createAction('[Travelers] Traveler Profile Save Error');
export const saveTravelerProfileNyu = createAction(
    '[Travelers] Save Traveler Profile Nyu',
    props<{ traveler: TravelerProfileNyuUpdate }>()
);
export const travelerProfileSaveNyuSuccess = createAction('[Travelers] Traveler Profile Nyu Saved');
export const travelerProfileSaveNyuError = createAction(
    '[Travelers] Traveler Profile Nyu Save Error',
    props<{ error: any }>()
);
export const loadSclSupervisors = createAction(
    '[Travelers] Load Scl Supervisors',
    props<{ sclSupervisorId: number }>()
);
export const sclSupervisorsLoadSuccess = createAction(
    '[Travelers API] Scl Supervisors Loaded Success',
    props<{ supervisors: SclSupervisor[] }>()
);
export const sclSupervisorsLoadError = createAction(
    '[Travelers API] Scl Supervisors Loaded Error',
    props<{ error: any }>()
);
export const loadSclDepartments = createAction('[Travelers] Load Scl Departments', props<{ employeeId: number }>());
export const sclDepartmentsLoadSuccess = createAction(
    '[Travelers API] Scl Departments Loaded Success',
    props<{ departments: SclDepartment[]; travelerDepartment: SclDepartment }>()
);
export const updateSclDepartment = createAction(
    '[Travelers] Update Scl Department',
    props<{ departmentCode: number }>()
);
export const sclDepartmentUpdated = createAction(
    '[Travelers] Scl Department Updated',
    props<{ department: SclDepartment }>()
);
export const sclDepartmentsLoadError = createAction(
    '[Travelers API] Scl Departments Loaded Fail',
    props<{ error: any }>()
);
export const saveTravelerJob = createAction(
    '[Travelers] Save Traveler Job',
    props<{ job: TravelerJobAddDto; id: number }>()
);
export const travelerJobSaved = createAction('[Travelers] Traveler Job Saved', props<{ id: number }>());
export const travelerJobSaveError = createAction('[Travelers] Traveler Job Save Error', props<{ error: any }>());
export const removeTravelerJob = createAction('[Travelers] Remove Traveler Job', props<{ id: number }>());
export const travelerJobRemoved = createAction('[Travelers] Traveler Job Removed', props<{ id: number }>());
export const travelerJobRemoveError = createAction('[Travelers] Traveler Job Remove Error', props<{ error: any }>());
export const saveTravelerPrismaPosition = createAction(
    '[Travelers] Save Traveler Prisma Position ID',
    props<{ prismaPosition: TravelerPrismaPositionDto; id: number }>()
);
export const travelerPrismaPositionSaved = createAction(
    '[Travelers] Traveler Prisma Position ID Saved',
    props<{ id: number }>()
);
export const travelerPrismaPositionSaveError = createAction(
    '[Travelers] Traveler Prisma Position ID Save Error',
    props<{ error: any }>()
);
export const removeTravelerPrismaPosition = createAction(
    '[Travelers] Remove Traveler Prisma Position ID',
    props<{ travelerId: number; id: number }>()
);
export const travelerPrismaPositionRemoved = createAction(
    '[Travelers] Traveler Prisma Position ID Removed',
    props<{ id: number }>()
);
export const travelerPrismaPositionRemoveError = createAction(
    '[Travelers] Traveler Prisma Position ID Remove Error',
    props<{ error: any }>()
);
export const saveTravelerBjcWorkerId = createAction(
    '[Travelers] Save Traveler BJC Worked ID',
    props<{ bjcWorkerId: string; userId: number; contractId: number }>()
);
export const travelerBjcWorkerIdSaved = createAction(
    '[Travelers] Traveler BJC Worked ID Saved',
    props<{ id: number }>()
);
export const travelerBjcWorkerIdSaveError = createAction(
    '[Travelers] Traveler BJC Worked ID Save Error',
    props<{ error: any }>()
);
export const removeTravelerBjcWorkerId = createAction(
    '[Travelers] Remove Traveler BJC Worked ID',
    props<{ userId: number; contractId: number }>()
);
export const travelerBjcWorkerIdRemoved = createAction(
    '[Travelers] Traveler BJC Worked ID Removed',
    props<{ id: number }>()
);
export const travelerBjcWorkerIdRemoveError = createAction(
    '[Travelers] Traveler BJC Worked ID Remove Error',
    props<{ error: any }>()
);

export const clearTravelerDetails = createAction('[Travelers] Clear Traveler Details');
export const clearTravelerJobs = createAction('[Travelers] Clear Traveler Jobs');
export const travelerDetailsUpdated = createAction('[Travelers] Traveler Details Updated');
export const travelerJobFilterRequest = createAction(
    '[Travelers] Job Filter request',
    props<{ state: GridStateChangeEvent; jobs: TravelerJob[] }>()
);
export const travelerJobFilterSuccess = createAction(
    '[Travelers] Job Filter request success',
    props<{ filteredJobs: GridDataResult }>()
);
export const loadBuildingLookups = createAction('[Travelers] Load Building Lookups');
export const buildingLookupsLoadSuccess = createAction(
    '[Travelers] Building Lookups Loaded',
    props<{ buildingLookups: BuildingModel[] }>()
);
export const buildingLookupsLoadError = createAction(
    '[Travelers] Building Lookups Load Error',
    props<{ error: any }>()
);
export const loadFloorLookups = createAction('[Travelers] Load Floor Lookups', props<{ buildingId: number }>());
export const floorLookupsLoadSuccess = createAction(
    '[Travelers] Floor Lookups Loaded',
    props<{ floorLookups: FloorModel[] }>()
);
export const floorLookupsLoadError = createAction('[Travelers] Floor Lookups Load Error', props<{ error: any }>());
export const loadDepartmentLookups = createAction(
    '[Travelers] Load Department Lookups',
    props<{ businessUnitId: number }>()
);
export const departmentLookupsLoadSuccess = createAction(
    '[Travelers] Department Lookups Loaded',
    props<{ departmentLookups: DepartmentModel[] }>()
);
export const departmentLookupsLoadError = createAction(
    '[Travelers] Department Lookups Load Error',
    props<{ error: any }>()
);
export const loadPayrollLocationLookups = createAction(
    '[Travelers] Load Payroll Location Lookups',
    props<{ businessUnitId: number }>()
);
export const payrollLocationLookupsLoadSuccess = createAction(
    '[Travelers] Payroll Location Lookups Loaded',
    props<{ payrollLocationLookups: PayrollLocationModel[] }>()
);
export const payrollLocationLookupsLoadError = createAction(
    '[Travelers] Payroll Location Lookups Load Error',
    props<{ error: any }>()
);
export const loadBusinessUnitLookups = createAction(
    '[Travelers] Load Business Unit Lookups',
    props<{ systemId: number }>()
);
export const businessUnitLookupsLoadSuccess = createAction(
    '[Travelers] Business Unit Lookups Loaded',
    props<{ businessUnitLookups: BusinessUnitModel[] }>()
);
export const businessUnitLookupsLoadError = createAction(
    '[Travelers] Business Unit Lookups Load Error',
    props<{ error: any }>()
);
