<div class="kendo-grid-lookalike-multiselect">
    <mat-form-field appearance="outline" [style.width]="(width ?? 300) + 'px'" class="filter-dropdown-padding">
        <mat-select [required]="required"
                    [placeholder]="defaultText"
                    [disableRipple]="true"
                    [disableOptionCentering]="true"
                    panelClass="kendo-grid-lookalike-multiselect-panel select-container multifield-multiselect-panel"
                    panelWidth="auto"
                    [formControl]="groupControl"
                    (openedChange)="panelStateChange($event)"
                    (selectionChange)="selectionChange($event)"
                    [value]="selectedValues"
                    #select
                    [compareWith]="compareGroupItem"
                    multiple>

            <mat-select-trigger>
                    <span *ngIf="groupControl.value && groupControl.value.length === dataItems.length">
                            {{selectAll}}
                    </span>
                     <span *ngIf="groupControl.value && groupControl.value.length < dataItems.length ">
                            {{firstItem?.length > charLimit ? (firstItem | slice:0:charLimit) + '...' : firstItem}}
                    </span>
                    <span *ngIf="groupControl.value && (groupControl.value.length || 0) > 1 && groupControl.value.length !== dataItems.length">
                            (+{{(groupControl.value.length || 0) - 1}} more)
                   </span>
            </mat-select-trigger>
            <ng-container *ngIf="textFilter">
                <div class="text-filter-wrapper">
                    <input matInput #searchInput [formControl]="filterCtrl" (keydown)="$event.stopPropagation()" placeholder="Type to filter...">
                    <mat-divider></mat-divider>
                </div>
            </ng-container>

            <div *ngIf="selectAll" class="checkbox-wrapper">
                <mat-checkbox
                        #selectAllCheckBox
                        class="selectalloption"
                        data-qa-id="select-all-groups"
                        id="select-all-groups"
                        name="select-all-groups"
                        [checked]="dataItems?.length === selectedValues?.length"
                        [indeterminate]="selectedValues?.length > 0 && dataItems?.length !== selectedValues?.length"
                        (change)="selectAllGroups($event)">Select All</mat-checkbox>
                <mat-divider></mat-divider>
            </div>
            <div #groupDivs *ngFor="let group of data" class="show flex-display-row group">
                <div class="checkbox-wrapper flex-display">
                    <mat-icon [attr.data-qa-id]="'group-collapse-' + group.name"
                            class="cursor-hand"
                            (click)="collapseGroup(group.name)"
                            [id]="'group-collapse-' + group.name">
                            {{ this.collapsedState.get(group.name) === false ? 'arrow_right' : 'arrow_drop_down' }}
                    </mat-icon>
                    <mat-checkbox
                            class="group-selector"
                            [disableRipple]="true"
                            #groupCheckbox
                            [attr.data-qa-id]="'group-selector-' + group.name"
                            [name]="'group-selector-' + group.name"
                            [id]="'group-selector-' + group.name"
                            [value]="group.name"
                            (change)="selectGroup($event)">
                            {{group.displayName}}
                    </mat-checkbox>
                </div>
                <div class="flex-display-option">
                    <mat-option #groupOption *ngFor="let subGroup of group.items"
                                    class="show group-option"
                                    [value]="{ group: group.name, id: subGroup.id , name : subGroup.name }"
                                    [attr.data-qa-id]="'group-' + group.name + '-option-' + subGroup.id"
                                    [id]="'group-' + group.name + '-option-' + subGroup.id">
                        <div class="flex-display-option-content">
                            <mat-checkbox   [disableRipple]="true"
                                            (change)="matOptionClick($event, groupOption)"
                                            (click)="$event.stopPropagation()"
                                            [name]="'group-' + group.name + '-checkbox-' + subGroup.id"
                                            [id]="'group-' + group.name + '-checkbox-' + subGroup.id"
                                            [checked]="groupOption.selected"></mat-checkbox>
                            <div class="group-option-text"> {{subGroup.name}}</div>
                        </div>
                    </mat-option>
                </div>
            </div>
        </mat-select>
        
        <kendo-svg-icon class="k-button-icon" matSuffix [icon]="caretDown"></kendo-svg-icon>

    </mat-form-field>
</div>
