import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, mergeMap, tap, filter, switchMap, map } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { FileValidationResult } from 'src/app/shared/models/attachment';
import { StaffingAdvisoryService } from 'src/app/travelers/services/staffing-advisory.service';
import * as StaffingAdvisoryActions from 'src/app/travelers/state/actions/staffing-advisory.actions';
import { downloadBlob } from 'src/app/core/utils';

@Injectable()
export class StaffingAdvisoryAttachmentsEffect extends BaseEffect {
    saveStaffingAdvisoryAttachments$ = createEffect(() =>
        this.actions.pipe(
            ofType(StaffingAdvisoryActions.saveStaffingAdvisoryAttachments),
            mergeMap((action) => {
                const attachmentActions: Array<Observable<FileValidationResult>> = [];
                action.filesToUpload.forEach((fileToUpload) => {
                    attachmentActions.push(this.service.postAttachment(action.staffingAdvisoryId, fileToUpload));
                });

                return forkJoin(attachmentActions).pipe(
                    filter((x) => x !== null),
                    mergeMap((x) =>
                        of(
                            StaffingAdvisoryActions.saveStaffingAdvisoryAttachmentsSuccess({
                                staffingAdvisoryId: action.staffingAdvisoryId,
                                fileValidationResult: x
                            }),
                            StaffingAdvisoryActions.saveStaffingAdvisorySuccess({
                                staffingAdvisoryId: action.staffingAdvisoryId,
                                files: x
                            })
                        )
                    ),
                    catchError((error) =>
                        of(
                            StaffingAdvisoryActions.saveStaffingAdvisoryAttachmentsFail({
                                error: `Error uploading file: ${error}`
                            }),
                            StaffingAdvisoryActions.saveStaffingAdvisoryFail({ error })
                        )
                    )
                );
            })
        )
    );

    saveStaffingAdvisoryAttachmentFail$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(
                    StaffingAdvisoryActions.saveStaffingAdvisoryAttachmentFail,
                    StaffingAdvisoryActions.loadStaffingAdvisoryAttachmentsFail,
                    StaffingAdvisoryActions.loadStaffingAdvisoryAttachmentPreviewFail,
                    StaffingAdvisoryActions.loadStaffingAdvisoryPreviewPageCountFail
                ),
                tap((resource) => this.toasterService.fail(`${resource.error}.`))
            ),
        { dispatch: false }
    );

    loadStaffingAdvisoryAttachments = createEffect(() =>
        this.actions.pipe(
            ofType(StaffingAdvisoryActions.loadStaffingAdvisoryAttachments),
            switchMap((action) =>
                this.service.getAttachments(action.staffingAdvisoryIds).pipe(
                    map((response) =>
                        StaffingAdvisoryActions.loadStaffingAdvisoryAttachmentsSuccess({
                            staffingAdvisoryAttachments: response
                        })
                    ),
                    catchError((error) => of(StaffingAdvisoryActions.loadStaffingAdvisoryAttachmentsFail({ error })))
                )
            )
        )
    );

    loadStaffingAdvisoryAttachmentPreview$ = createEffect(() =>
        this.actions.pipe(
            ofType(StaffingAdvisoryActions.loadStaffingAdvisoryAttachmentPreview),
            switchMap((action) =>
                this.service.getPreviewAttachmentBlob(action.staffingAdvisoryId, action.file.id, action.pageNum).pipe(
                    map((response) =>
                        StaffingAdvisoryActions.loadStaffingAdvisoryAttachmentPreviewSuccess({ file: response })
                    ),
                    catchError((error) =>
                        of(StaffingAdvisoryActions.loadStaffingAdvisoryAttachmentPreviewFail({ error }))
                    )
                )
            )
        )
    );

    loadingStaffingAdvisoryPreviewPageCount = createEffect(() =>
        this.actions.pipe(
            ofType(StaffingAdvisoryActions.loadStaffingAdvisoryPreviewPageCount),
            switchMap((action) =>
                this.service.getTotalNumOfDoc(action.staffingAdvisoryId, action.fileId).pipe(
                    map((response) =>
                        StaffingAdvisoryActions.loadStaffingAdvisoryPreviewPageCountSuccess({ total: response })
                    ),
                    catchError((error) =>
                        of(StaffingAdvisoryActions.loadStaffingAdvisoryPreviewPageCountFail({ error }))
                    )
                )
            )
        )
    );

    getPreviewNextPage$ = createEffect(() =>
        this.actions.pipe(
            ofType(StaffingAdvisoryActions.loadStaffingAdvisoryAttachmentPreview),
            switchMap((action) =>
                this.actions.pipe(
                    ofType(StaffingAdvisoryActions.loadStaffingAdvisoryPreviewNextPage),
                    map(({ pageNum }) =>
                        StaffingAdvisoryActions.loadStaffingAdvisoryAttachmentPreview({
                            ...action,
                            pageNum
                        })
                    )
                )
            )
        )
    );

    downloadAttachment$ = createEffect(() =>
        this.actions.pipe(
            ofType(StaffingAdvisoryActions.downloadStaffingAdvisoryAttachment),
            switchMap((action) =>
                this.service.getAttachment(action.staffingAdvisoryId, action.fileId).pipe(
                    map((response) => {
                        downloadBlob(response, action.fileName);
                        return StaffingAdvisoryActions.downloadStaffingAdvisoryAttachmentSuccess();
                    }),
                    catchError((error) => of(StaffingAdvisoryActions.downloadStaffingAdvisoryAttachmentFail({ error })))
                )
            )
        )
    );

    constructor(
        private readonly actions: Actions,
        private readonly service: StaffingAdvisoryService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
