
<div
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{dates: value}"
    [ngClass]="{'mat-icon-override': useMaterialIcon, 'disabled': isDisabled}">

    <input kendoTextBox
        class="form-field-control k-textbox"
        [value]="viewValue"
        [disabled]="isDisabled"
        [formControl]="dateControl"
        readonly/>


    <ng-container *ngIf="useMaterialIcon; else matIconOverride">
        <span class="icon-container">
            <mat-icon class="dates-icon">today</mat-icon>
        </span>
    </ng-container>
    <ng-template #matIconOverride>
        <kendo-svg-icon [icon]="calendarIcon"
            class="dates-icon">
        </kendo-svg-icon>
    </ng-template>


    <mat-error *ngIf="validateField && dateControl.invalid && dateControl.touched">
        Please select at least one date.
    </mat-error>
</div>

<mat-menu #menu="matMenu" xPosition="before" [class]="panelClass">
    <ng-template matMenuContent let-dates="dates">
        <multi-select-calendar
            click-stop-propagation
            [dates]="dates"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [disabledDates]="disabledDates"
            [disabledDatesStyle]="disabledDatesStyle"
            [extraDateClass]="extraDateClass"
            [multi]="multi"
            [showConfirmationCount]="showConfirmationCount"
            (save)="onSave($event)"
            (cancel)="onCancel()">
        </multi-select-calendar>
    </ng-template>
</mat-menu>
