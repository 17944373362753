<form>
    <div class="doc-form-label"
         for="contract-input"
         data-qa-id="doc-att-form-contract-label">
        Contract
        <span class="doc-required-ast">&ast;</span>
    </div>
    <div class="doc-form-label-contract-info" data-qa-id="doc-att-form-contract-string">
        {{ contractInfoInputString }}
    </div>
</form>

<ng-container *ngIf="areMetadataFieldsLoaded$ | async">
    <form fxLayout="column"
          fxLayoutAlign="start stretch"
          [formGroup]="form">

        <div *ngFor="let field of documentAttributeFields$ | async; trackBy: trackFieldBy"
             #metadataFields>

            <ng-container *ngIf="hardwiredFields.includes(field.fieldName); else documentAttributeInputs">
                <input type="hidden"
                       [formControlName]="field.fieldName"
                       [attr.data-qa-id]="'metadata-' + field.fieldName">
            </ng-container>

            <ng-template #documentAttributeInputs>

                <div class="doc-form-label"
                     [attr.for]="'doc-upload-input-' + field.fieldName">
                    {{ field.displayText}}
                    <span *ngIf="field.isRequired"
                          class="doc-required-ast">&ast;</span>
                </div>

                <div [ngSwitch]="field.fieldType">

                    <ng-container *ngSwitchCase="documentAttributeFieldTypes.Text">

                        <ayac-form-control-wrapper [controlLabel]="field.displayText"
                                                   [showRequiredAsterisk]="false"
                                                   class="doc-upload-text-control"
                                                   [isCentered]="false">
                            <mat-form-field [floatLabel]="'auto'"
                                            class="full-width"
                                            appearance="outline">
                                <input matInput
                                       [formControlName]="field.fieldName"
                                       [attr.data-qa-id]="'metadata-' + field.fieldName"
                                       [id]="'doc-upload-input-' + field.fieldName"
                                       [placeholder]="'Contract'">
                            </mat-form-field>
                        </ayac-form-control-wrapper>
                    </ng-container>

                    <ng-container *ngSwitchCase="documentAttributeFieldTypes.Numeric">
                        <ayac-form-control-wrapper [controlLabel]="field.displayText"
                                                   [showRequiredAsterisk]="false"
                                                   class="doc-upload-numeric-control"
                                                   [isCentered]="false">
                            <mat-form-field [floatLabel]="'auto'"
                                            class="full-width"
                                            appearance="outline">
                                <input matInput
                                       [formControlName]="field.fieldName"
                                       type="number"
                                       [attr.data-qa-id]="'metadata-' + field.fieldName"
                                       [id]="'doc-upload-input-' + field.fieldName">
                            </mat-form-field>
                        </ayac-form-control-wrapper>
                    </ng-container>

                    <ng-container *ngSwitchCase="documentAttributeFieldTypes.Textarea">
                        <ayac-form-control-wrapper [controlLabel]="field.displayText"
                                                   [showRequiredAsterisk]="false"
                                                   class="doc-upload-text-area-control"
                                                   [isCentered]="false">
                            <mat-form-field [floatLabel]="'auto'"
                                            class="full-width"
                                            appearance="outline">
                                <textarea matInput
                                          [formControlName]="field.fieldName"
                                          [attr.data-qa-id]="'metadata-' + field.fieldName"
                                          [id]="'doc-upload-input-date-input-' + field.fieldName"></textarea>
                            </mat-form-field>
                        </ayac-form-control-wrapper>
                    </ng-container>

                    <ng-container *ngSwitchCase="documentAttributeFieldTypes.Date">
                        <ayac-form-control-wrapper [controlLabel]="field.displayText"
                                                   [showRequiredAsterisk]="false"
                                                   class="doc-upload-date-control"
                                                   [isCentered]="false">
                            <mat-form-field [floatLabel]="'auto'"
                                            class="full-width"
                                            appearance="outline">
                                <input matInput
                                       type="text"
                                       [matDatepicker]="pickerDate"
                                       [formControlName]="field.fieldName"
                                       [attr.placeholder]="field.fieldName === 'expirationDate' ? 'Select Expiration Date' : 'Choose a Date'"
                                       (keypress)="keyPressNumericWithSlash($event)"
                                       (paste)="onPaste($event)"
                                       [min]="getValidatorMinDateValue(field.validators)"
                                       [max]="getValidatorMaxDateValue(field.validators)"
                                       [attr.data-qa-id]="'metadata-' + field.fieldName"
                                       [id]="'doc-upload-input-' + field.fieldName">
                                <mat-datepicker-toggle matSuffix
                                                       [for]="pickerDate"></mat-datepicker-toggle>
                                <mat-datepicker #pickerDate></mat-datepicker>
                                <mat-error *ngIf="form.get(field.fieldName).hasError('matDatepickerMin')">
                                    Must be on or after {{
                                    friendlyFormatDate(form.get(field.fieldName).errors.matDatepickerMin.min)
                                    }}
                                </mat-error>
                                <mat-error *ngIf="form.get(field.fieldName).hasError('matDatepickerMax')">
                                    Must be on or before {{
                                    friendlyFormatDate(form.get(field.fieldName).errors.matDatepickerMax.max) }}
                                </mat-error>
                            </mat-form-field>
                        </ayac-form-control-wrapper>
                    </ng-container>


                    <mat-checkbox [(ngModel)]="isNotExpirationDateChecked"
                        [ngModelOptions]="{standalone: true}"
                        aria-label="Checkbox for does not expire"
                        (change)="onExpirationDateCheckedChange($event.checked)"
                        [attr.data-qa-id]="'doesNotExpireCheckbox'"
                        class="doc-checkbox-label">Does not expire
                    </mat-checkbox>

                    <ng-container *ngSwitchCase="documentAttributeFieldTypes.DateCompare">
                        <ayac-form-control-wrapper [controlLabel]="field.displayText"
                                                   [showRequiredAsterisk]="false"
                                                   class="doc-upload-date-compare-control"
                                                   [isCentered]="false"
                                                   [dataQaId]="'doc-att-form-exp-date-input'"
                                                   [hidden]="isNotExpirationDateChecked">
                            <mat-form-field [floatLabel]="'auto'"
                                            class="full-width"
                                            appearance="outline">
                                <input matInput
                                       type="text"
                                       [matDatepicker]="pickerDateCompare"
                                       [formControlName]="field.fieldName"
                                       [attr.placeholder]="field.fieldName === 'expirationDate' ? 'Select Expiration Date' : 'Choose a Date'"
                                       (keypress)="keyPressNumericWithSlash($event)"
                                       (paste)="onPaste($event)"
                                       [min]="getValidatorMinDateValue(field.validators)"
                                       [max]="getValidatorMaxDateValue(field.validators)"
                                       [attr.data-qa-id]="'metadata-' + field.fieldName"
                                       [id]="'doc-upload-input-date-compare-' + field.fieldName">
                                <mat-datepicker-toggle matSuffix
                                                       [for]="pickerDateCompare"></mat-datepicker-toggle>
                                <mat-datepicker #pickerDateCompare></mat-datepicker>
                                <mat-error *ngIf="form.get(field.fieldName).hasError('matDatepickerMin')">
                                    Must be on or after {{
                                    friendlyFormatDate(form.get(field.fieldName).errors.matDatepickerMin.min)
                                    }}
                                </mat-error>
                                <mat-error *ngIf="form.get(field.fieldName).hasError('matDatepickerMax')">
                                    Must be on or before {{
                                    friendlyFormatDate(form.get(field.fieldName).errors.matDatepickerMax.max) }}
                                </mat-error>
                            </mat-form-field>
                        </ayac-form-control-wrapper>
                    </ng-container>

                    <ng-container *ngSwitchCase="documentAttributeFieldTypes.Singleselect">
                        <ayac-form-control-wrapper [controlLabel]="field.displayText"
                                                   [showRequiredAsterisk]="false"
                                                   class="doc-upload-single-select-control"
                                                   [isCentered]="false">
                            <mat-form-field floatLabel="auto"
                                            class="full-width"
                                            appearance="outline">
                                <mat-select [formControlName]="field.fieldName"
                                            [compareWith]="optionComparison"
                                            [attr.data-qa-id]="'metadata-' + field.fieldName"
                                            [id]="'doc-upload-input-single-select-' + field.fieldName">
                                    <mat-option *ngIf="!field.isRequired"
                                                value=""></mat-option>
                                    <mat-option *ngFor="let fieldOption of field.fieldOptions"
                                                [value]="fieldOption"
                                                [disabled]="fieldOption.disabled">
                                        {{fieldOption.displayText}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ayac-form-control-wrapper>
                    </ng-container>

                    <!--
                  "multiselect" type is used to indicate that multiple values may be selected as acceptable options inside of a rule (aka description).
                  This does not support selecting multiple values when extracting data from the document.
                  If there is a case where this is needed down the road. A refactoring will need to occur.
              -->

                    <ng-container *ngSwitchCase="documentAttributeFieldTypes.Multiselect">
                        <ayac-form-control-wrapper [controlLabel]="field.displayText"
                                                   [showRequiredAsterisk]="false"
                                                   class="doc-upload-multi-select-control"
                                                   [isCentered]="false">
                            <mat-form-field floatLabel="auto"
                                            class="full-width"
                                            appearance="outline">
                                <mat-select [formControlName]="field.fieldName"
                                            [compareWith]="optionComparison"
                                            [attr.data-qa-id]="'metadata-' + field.fieldName"
                                            [id]="'doc-upload-input-multi-select-' + field.fieldName">
                                    <mat-option *ngIf="!field.isRequired"
                                                value=""></mat-option>
                                    <mat-option *ngFor="let fieldOption of field.fieldOptions"
                                                [value]="fieldOption"
                                                [disabled]="fieldOption.disabled">
                                        {{fieldOption.displayText}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ayac-form-control-wrapper>
                    </ng-container>

                    <ng-container *ngSwitchCase="documentAttributeFieldTypes.TrueFalse">
                        <ayac-form-control-wrapper [controlLabel]="field.displayText"
                                                   [showRequiredAsterisk]="false"
                                                   class="doc-upload-multi-select-control"
                                                   [isCentered]="false">
                            <mat-form-field floatLabel="auto"
                                            class="full-width"
                                            appearance="outline">
                                <mat-select [formControlName]="field.fieldName"
                                            [attr.data-qa-id]="'metadata-' + field.fieldName"
                                            [id]="'doc-upload-input-' + field.fieldName">
                                    <mat-option *ngIf="!field.isRequired"
                                                value=""></mat-option>
                                    <mat-option value="true">Yes</mat-option>
                                    <mat-option value="false">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ayac-form-control-wrapper>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <ayac-form-control-wrapper [controlLabel]="field.displayText"
                                                   [showRequiredAsterisk]="false"
                                                   class="doc-upload-multi-select-control"
                                                   [isCentered]="false">
                            <mat-form-field [floatLabel]="'auto'"
                                            class="full-width"
                                            appearance="outline">
                                <input matInput
                                       [formControlName]="field.fieldName"
                                       [attr.data-qa-id]="'metadata-' + field.fieldName"
                                       [id]="'doc-upload-input-' + field.fieldName">
                            </mat-form-field>
                        </ayac-form-control-wrapper>
                    </ng-container>

                </div>
            </ng-template>
        </div>
        <!-- REMOVE FOR NOW - DOCUMENT NOTE IS NOT NEEDED IN THE MVP FOR THIS FEATURE.
        WE MAY ADD THIS LATER. -->
        <!-- <div class="doc-form-label"
             for="doc-upload-input-note">
            Document Note
        </div> -->
        <!-- <ayac-form-control-wrapper [controlLabel]="'Document Note'"
                                   [showRequiredAsterisk]="false"
                                   class="doc-upload-text-area-control"
                                   [isCentered]="false">
            <mat-form-field [floatLabel]="'auto'"
                            class="full-width"
                            appearance="outline">
                <textarea matInput
                          formControlName="note"
                          [attr.data-qa-id]="'metadata-' + 'note'"
                          [id]="'doc-upload-input-' + 'note'"></textarea>
            </mat-form-field>
        </ayac-form-control-wrapper> -->
    </form>
</ng-container>
