<dialog-layout title="{{title}}">
    <div mat-dialog-content>
        <div class="form-group">
            <label>Date: </label>
            <span>{{ now | date: 'M/d/yyyy' }}</span>
        </div>
        <form class="form"
              [formGroup]='form'>
            <label>Note:</label>
            <br />
            <mat-form-field appearance="fill">
                <textarea matInput
                          rows="5"
                          maxlength="150"
                          formControlName="note"></textarea>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button
                color="accent"
                (click)="exclude()">
            Exclude
        </button>
        <button mat-stroked-button
                color="primary"
                [mat-dialog-close]="false">
            Cancel
        </button>
    </div>
</dialog-layout>
