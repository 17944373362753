<ayac-submittals-modal title="Update Status"
                       loading="false"
                       actionButtonName="Confirm"
                       actionButtonColor="accent"
                       (actionButtonClick)="updateStatus()"
                       [actionButtonDisabled]="!form.valid || noteItemIsNotValid"
                       *ifLdFeature="featureFlag.ConnectClientDisplayNewSubmittalStatus; enabled: true">
    <form [formGroup]="form">
        <div class="form-container">
            <div class="more-information-warning-container"
                *ngIf="data?.submittalStatusId === submittalStatuses.MoreInformationNeeded">
                <div class="align-center">
                    <mat-icon>warning</mat-icon>
                </div>
                <div>
                    This status will remove the submittal from view. This action cannot be undone. The submittal will become viewable again in Pending Review status when more details are added.
                </div>
            </div>
            <div class="grid">
                <div>
                    <strong>Status</strong>
                </div>
                <span data-qa-id="update-status-name">{{data?.submittalStatusName}} {{data?.submittalSubStatusName}}</span>

                <div>
                    <strong data-qa-id="status-update-note-text" id="status-update-note-text">{{notesText}}<span *ngIf="isMoreInformationNeededSelected"> *</span></strong>
                </div>
                <ayac-text-area-with-counter placeholder="{{notePlaceholder}}"
                                         formControlName="notes"
                                         [editor]="editor"
                                         [isVendorClientCommunicationEnabled]="isVendorClientCommunicationEnabled"
                                         [data-qa-id]="update-status-note"
                                         (formItemValidationChanged)="onNoteItemValidationChanged($event)">
                </ayac-text-area-with-counter>
            </div>
        </div>
    </form>
</ayac-submittals-modal>
<dialog-layout title="Update Status"  *ifLdFeature="featureFlag.ConnectClientDisplayNewSubmittalStatus; enabled: false">
    <div mat-dialog-content>
        <div class="k-form-inline" [formGroup]="form">
            <div class="status-row">
                <span class="title">Status</span>
                <span data-qa-id="update-status-name">{{data?.submittalStatusName}} {{data?.submittalSubStatusName}}</span>
            </div>

            <div class="notes-row">
                <span class="title">Notes</span>
                <mat-form-field appearance="outline" class="notes">
                    <textarea data-qa-id="update-status-note" matInput rows="3" type="text" formControlName="notes"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-flat-button class="status-button" data-qa-id="update-status-confirm-btn" color="accent" (click)="updateStatus()">
            Confirm
        </button>
        <button mat-stroked-button class="status-button" mat-dialog-close color="basic" data-qa-id="update-status-cancel-btn">
            Cancel
        </button>
    </div>
</dialog-layout>
