import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InternalPoolService } from '../../internal-pool.service';
import * as internalPoolActions from 'src/app/internal-pool/store/actions';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Router } from '@angular/router';

@Injectable()
export class ResourceArchiveEffect extends BaseEffect {
    constructor(
        private readonly actions: Actions,
        private readonly internalPoolService: InternalPoolService,
        private readonly dialogService: DialogService,
        private readonly router: Router,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    archiveWorkerConfirmation$ = createEffect(() =>
        this.actions.pipe(
            ofType(internalPoolActions.archiveWorkerConfirmation),
            switchMap((action) => {
                const dialog = this.dialogService.openConfirmationDialog({
                    data: {
                        title: 'Archive Worker',
                        text: 'Are you sure?'
                    }
                });
                return from(dialog).pipe(
                    filter((r) => r === true),
                    map((_) => internalPoolActions.archiveWorker({ id: action.id }))
                );
            })
        )
    );

    archiveWorker$ = createEffect(() =>
        this.actions.pipe(
            ofType(internalPoolActions.archiveWorker),
            switchMap((action) =>
                this.internalPoolService.archiveWorker(action.id).pipe(
                    map((response) => internalPoolActions.archiveWorkerSuccess()),
                    catchError((error) => of(internalPoolActions.archiveWorkerFail({ error })))
                )
            )
        )
    );

    archiveWorkerSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(internalPoolActions.archiveWorkerSuccess),
                tap(() => this.handleSuccess('Worker archived successfully.')),
                tap(() => {
                    this.router.navigate(['/client', 'internalpool']);
                })
            ),
        { dispatch: false }
    );

    archiveWorkerFailed$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(internalPoolActions.archiveWorkerFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );

    // Unarchive
    unarchiveWorkerConfirmation$ = createEffect(() =>
        this.actions.pipe(
            ofType(internalPoolActions.unarchiveWorkerConfirmation),
            switchMap((action) => {
                const dialog = this.dialogService.openConfirmationDialog({
                    data: {
                        title: 'Unarchive Worker',
                        text: 'Are you sure you want to unarchive this worker?'
                    }
                });
                return from(dialog).pipe(
                    filter((r) => r === true),
                    map((_) => internalPoolActions.unarchiveWorker({ id: action.id }))
                );
            })
        )
    );

    unarchiveWorker$ = createEffect(() =>
        this.actions.pipe(
            ofType(internalPoolActions.unarchiveWorker),
            switchMap((action) =>
                this.internalPoolService.unarchiveWorker(action.id).pipe(
                    map((response) => internalPoolActions.unarchiveWorkerSuccess()),
                    catchError((error) => of(internalPoolActions.unarchiveWorkerFail({ error })))
                )
            )
        )
    );

    unarchiveWorkerSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(internalPoolActions.unarchiveWorkerSuccess),
                tap(() => this.handleSuccess('Worker unarchived successfully.'))
            ),
        { dispatch: false }
    );

    unarchiveWorkerFailed$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(internalPoolActions.unarchiveWorkerFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );

    unarchiveWorkerNavigateBack$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(internalPoolActions.unarchiveWorkerConfirmation),
                filter((action) => action.navigateBack === true),
                switchMap(() => {
                    const success$ = this.actions.pipe(ofType(internalPoolActions.unarchiveWorkerSuccess));
                    const failed$ = this.actions.pipe(ofType(internalPoolActions.unarchiveWorkerFail));

                    return success$.pipe(takeUntil(failed$), take(1));
                }),
                tap(() => {
                    this.router.navigate(['/client', 'internalpool']);
                })
            ),
        { dispatch: false }
    );

    unarchiveWorkerReloadDetails$ = createEffect(() =>
        this.actions.pipe(
            ofType(internalPoolActions.unarchiveWorkerConfirmation),
            filter((action) => action.refreshDetails === true),
            switchMap((action) => {
                const success$ = this.actions.pipe(ofType(internalPoolActions.unarchiveWorkerSuccess));
                const failed$ = this.actions.pipe(ofType(internalPoolActions.unarchiveWorkerFail));

                return success$.pipe(takeUntil(failed$), take(1), mapTo(action));
            }),
            map((action) => internalPoolActions.loadResourceDetailsCurrentSystem({ id: action.id }))
        )
    );

    unarchiveWorkerReloadList$ = createEffect(() =>
        this.actions.pipe(
            ofType(internalPoolActions.unarchiveWorkerConfirmation),
            filter((action) => action.refreshList === true),
            switchMap(() => {
                const success$ = this.actions.pipe(ofType(internalPoolActions.unarchiveWorkerSuccess));
                const failed$ = this.actions.pipe(ofType(internalPoolActions.unarchiveWorkerFail));

                return success$.pipe(takeUntil(failed$), take(1));
            }),
            map(() => internalPoolActions.loadResourcesCurrentSystem())
        )
    );
}
