<loading-indicator *ngIf="isLoading$ | async"></loading-indicator>

<div class="pre-header submittal-status">
    <div class="pre-header__left">
        <span class="stage-label" *ngIf="(statusLookups$ | async)?.length > 0">
            Submittal Stage
        </span>
        <span class="substage-label" *ngIf="(subStatusLookups$ | async)?.length > 0">
            Sub-stages
        </span>
    </div>
    <div class="pre-header__right">
        <button mat-flat-button color="accent" class="migrated-btn-info" data-qa-id="ad_vendor_cand_refresh_button" (click)="refreshGrid()">
            <mat-icon>refresh</mat-icon>
            Refresh
        </button>
        <button mat-flat-button color="accent" class="migrated-btn-info" data-qa-id="ad_vendor_cand_export_button" (click)="exportExcel()">
            <mat-icon>arrow_downward</mat-icon>
            Export
        </button>
    </div>
</div>

<portal-grid
  class="portal-grid-scroll-auto portal-grid-toolbar-legacy"
  data-title="Submittals"
  [data]="submittals$ | async"
  [query]="submittalsQuery$ | async"
  (dataStateChange)="onDataStateChange($event)"
  (rowClicked)="onRowClicked($event)" >

    <ng-template #header let-title="title" >
        <div class="title">
            Submittals {{ (submittals$ | async).data.length }} / {{ (submittals$ | async).total }}
        </div>
    </ng-template>

    <portal-grid-quick-filter
        place="header"
        field="submittalStatusIds"
        filterType="multiselect"
        selectAll="All stages"
        defaultText="Select a stage"
        [defaultToAllSelected]="true"
        [width]="300"
        [filterOptions]="statusLookups$ | async">
    </portal-grid-quick-filter>
    <portal-grid-quick-filter
        place="header"
        field="submittalSubStatusIds"
        filterType="multiselect"
        selectAll="All Sub-stages"
        defaultText="Select a Sub-stage"
        [defaultToAllSelected]="true"
        [width]="300"
        [filterOptions]="subStatusLookups$ | async"
        *ngIf="(subStatusLookups$ | async)?.length > 0">
    </portal-grid-quick-filter>

    <portal-grid-column
        title="Candidate Name"
        field="candidateName"
        filterType="text"
        [style]="{'width': '15%'}"
        [headerStyle]="{'width': '15%'}">
    </portal-grid-column>

    <portal-grid-column
        title="Facility"
        filterType="text"
        field="facility">
    </portal-grid-column>

    <portal-grid-column
        title="Vendor"
        filterType="text"
        field="vendor">
    </portal-grid-column>

    <portal-grid-column
        title="Profession"
        filterType="text"
        field="profession">
    </portal-grid-column>

    <portal-grid-column
        title="Specialty"
        filterType="text"
        field="specialty">
    </portal-grid-column>

    <portal-grid-column
        title="Submitted"
        field="submitted"
        sortField="submitted"
        format="date">
    </portal-grid-column>

    <portal-grid-column
        title="Status"
        field="currentStatus"
        sortField="currentStatus">
    </portal-grid-column>

    <portal-grid-column
        title="VMS"
        filterType="text"
        field="vms">
    </portal-grid-column>
</portal-grid>
