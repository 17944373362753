export enum AyaWannaConnectFeatureFlags {
    ConnectClientDisplayNewSubmittalStatus = 'CONNECT_CLIENT_DISPLAY_NEW_SUBMITTAL_STATUS',
    ReplaceCurrentHelpSectionWithPendoResourceCenter = 'REPLACE_CURRENT_HELP_SECTION_WITH_PENDO_RESOURCE_CENTER',
    ClientEmailSubscriptions = 'CONNECT_CLIENT_EMAIL_SUBSCRIPTIONS',
    ConnectClientAdminSettingsEnabled = 'CONNECT_CLIENT_ADMIN_SETTINGS_ENABLED',
    ConnectInvoicingTemplateMethodCallPipeFix = 'CONNECT_INVOICING_TEMPLATE_METHOD_CALL_PIPE_FIX',
    BillableInvoiceAvailableCanSubscribe = 'BILLABLE_INVOICE_AVAILABLE_CAN_SUBSCRIBE',
    InvoiceDisputeOpenCanSubscribe = 'INVOICE_DISPUTE_OPEN_CAN_SUBSCRIPTION',
    InvoiceDisputeCommentAddedCanSubscribe = 'INVOICE_DISPUTE_COMMENT_ADDED_CAN_SUBSCRIPTION',
    InvoiceDisputeResolvedCanSubscribe = 'INVOICE_DISPUTE_RESOLVED_CAN_SUBSCRIPTION',
    BillableInvoiceApprovedCanSubscribe = 'BILLABLE_INVOICE_APPROVED_CAN_SUBSCRIPTION',
    JobsApprovedRejectedClosedCanSubscribe = 'JOBS_APPROVED_REJECTED_CLOSED_CAN_SUBSCRIBE',
    JobDetailsEditedCanSubscribe = 'JOB_DETAILS_EDITED_CAN_SUBSCRIBE',
    JobApprovalNeededCanSubscribe = 'JOB_APPROVAL_NEEDED_CAN_SUBSCRIBE',
    JobApprovalNeededReminder24HCanSubscribe = 'JOB_APPROVAL_NEEDED_REMINDER_24H_CAN_SUBSCRIBE',
    ClientConnectCompliance = 'CLIENT_CONNECT_COMPLIANCE',
    VendorConnectCompliance = 'VENDOR_CONNECT_COMPLIANCE',
    ConnectClientTravelersDocumentsDeclineApproveEnabled = 'CONNECT_CLIENT_TRAVELERS_DOCUMENTS_DECLINE_APPROVE_ENABLED',
    EnableConnectExpenses = 'ENABLE_CONNECT_EXPENSES',
    ConnectClientJobsGridSpecialtyFilter = 'connect-client-jobs-grid-specialty-filter',
    LotusOneClientTimecardsEmptyAllowApprovalShowBillingDetails = 'lotusone-client-timecards-empty-allow-approval-show-billing-details'
}
