import { createAction, props } from '@ngrx/store';
import { ListItem } from 'src/app/shared/models/list-item';

export const loadLinesOfBusiness = createAction('[Dashboard component] Load Lines Of Business Lookup');
export const loadLinesOfBusinessSuccess = createAction(
    '[Lookups API] Lines Of Business Lookup Load Succeed',
    props<{ linesOfBusiness: ListItem[] }>()
);
export const loadLinesOfBusinessFail = createAction(
    '[Lookups API] Lines Of Business Lookup Load Failed',
    props<{ error: any }>()
);

export const loadJobsLinesOfBusiness = createAction('[Jobs Component] Load Jobs Line of Business');
export const loadJobsLinesOfBusinessSucesss = createAction(
    '[Lookups API] Load Jobs Line of Business Success',
    props<{ linesOfBusiness: ListItem[] }>()
);
export const loadJobsLinesOfBusinessFail = createAction(
    '[Lookups API] Load Jobs Line of Business Fail',
    props<{ error: unknown }>()
);

export const loadWorkersLinesOfBusiness = createAction('[Workers Component] Load Workers Line of Business');
export const loadWorkersLinesOfBusinessSucesss = createAction(
    '[Lookups API] Load Workers Line of Business Success',
    props<{ linesOfBusiness: ListItem[] }>()
);
export const loadWorkersLinesOfBusinessFail = createAction(
    '[Lookups API] Load Workers Line of Business Fail',
    props<{ error: unknown }>()
);

export const loadTimecardsLinesOfBusiness = createAction('[Timecards Component] Load Timecards Line of Business');
export const loadTimecardsLinesOfBusinessSucesss = createAction(
    '[Lookups API] Load Timecards Line of Business Success',
    props<{ linesOfBusiness: ListItem[] }>()
);
export const loadTimecardsLinesOfBusinessFail = createAction(
    '[Lookups API] Load Timecards Line of Business Fail',
    props<{ error: unknown }>()
);

export const loadSubmittalsLinesOfBusiness = createAction('[Submittals Component] Load Submittals Line of Business');
export const loadSubmittalsLinesOfBusinessSucesss = createAction(
    '[Lookups API] Load Submittals Line of Business Success',
    props<{ linesOfBusiness: ListItem[] }>()
);
export const loadSubmittalsLinesOfBusinessFail = createAction(
    '[Lookups API] Load Submittals Line of Business Fail',
    props<{ error: unknown }>()
);

export const loadEditJobsLinesOfBusiness = createAction('[Edit Jobs Component] Load Edit Jobs Line of Business');
export const loadEditJobsLinesOfBusinessSucesss = createAction(
    '[Lookups API] Load Edit Jobs Line of Business Success',
    props<{ linesOfBusiness: ListItem[] }>()
);
export const loadEditJobsLinesOfBusinessFail = createAction(
    '[Lookups API] Load Edit Jobs Line of Business Fail',
    props<{ error: unknown }>()
);
