import { createAction, props } from '@ngrx/store';

export const loadPendingSubmittals = createAction('[Dashboard Component] Load Pending Submittals');
export const loadPendingSubmittalsSuccess = createAction(
    '[Dashboard Api] Load Pending Submittals Success',
    props<{ total: number; agingCount: number }>()
);
export const loadPendingSubmittalsFail = createAction(
    '[Dashboard Api] Load Pending Submittals Fail',
    props<{ error: any }>()
);
