import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities';
import { DocumentTravelerDetails } from '../models/document-traveler-details.model';
import { DocumentMetadata } from '../models/document-metadata.model';
import { DocumentDeclineReasonEnum } from '../models/enums/document-decline-reason.enum';
import { ClientRequirementDocumentStatus } from '../traveler-details/traveler-documents/grid/traveler-documents-grid.model';

@Injectable({
    providedIn: 'root'
})
export class TravelerDocumentService {
    private readonly coreApiPath: string;
    private readonly documentPath: string;

    constructor(
        private readonly _http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {
        this.coreApiPath = combine(_settings.CORE, 'AyaConnect');
        this.documentPath = combine(this.coreApiPath, '/Client/Traveler/Document');
    }

    getDocumentTravelerDetails(contractId: number): Observable<DocumentTravelerDetails> {
        const url = `${this.documentPath}/${contractId}/traveler-details`;
        return this._http.get<DocumentTravelerDetails>(url);
    }

    getDocumentMetadata(contractId: number, documentId: number): Observable<DocumentMetadata> {
        const url = `${this.documentPath}/${contractId}/metadata/${documentId}`;
        return this._http.get<DocumentMetadata>(url);
    }

    declineDocument(contractId: number, documentId: number, reason: DocumentDeclineReasonEnum, notes: string) {
        const data = {
            reason,
            notes
        };

        const url = `${this.documentPath}/${contractId}/decline/${documentId}`;
        return this._http.put(url, data);
    }

    approveDocument(contractId: number, documentId: number) {
        const url = `${this.documentPath}/${contractId}/approve/${documentId}`;
        return this._http.put(url, {});
    }

    getDocumentDeclinedApprovalStatus(
        contractId: number,
        candidateDocumentId: number
    ): Observable<ClientRequirementDocumentStatus> {
        const url = `${this.documentPath}/${contractId}/approval-status/${candidateDocumentId}`;
        return this._http.get<ClientRequirementDocumentStatus>(url);
    }
}
