import { Component, Input } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import * as moment from 'moment';

@Component({
    selector: 'grid-time-filter',
    templateUrl: './time-filter.component.html'
})
export class TimeFilterComponent extends BaseFilterCellComponent {
    @Input() public field: string;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() public value: Date;
    @Input() uniqueFilterClass: string;

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public onChange(value: Date): void {
        this.applyFilter(
            value === null
                ? this.removeFilter(this.field)
                : this.updateFilter({
                      field: this.field,
                      operator: 'eq',
                      value: moment(value).format('YYYY-MM-DDTHH:mm:ss')
                  })
        );
    }
}
