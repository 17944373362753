<kendo-combobox
    [data]="dataFiltered"
    [textField]="textField"
    [valueField]="valueField"
    [placeholder]="placeholder"
    [valuePrimitive]="true"
    [value]="selectedValue"
    [attr.disabled]="disabled ? '' : null"
    [popupSettings]="settings"
    [filterable]="true"
    [kendoDropDownFilter]="filterSettings"
    (valueChange)="onChange($event)"
    [style.width.px]="width"
    [ngClass]="uniqueFilterClass">
</kendo-combobox>
