<div fxLayout="rows" fxLayoutAlign="center center">
    <kendo-dropdownlist
        class="hide-arrow"
        [disabled]="disabled"
        [defaultItem]="defaultHour"
        [data]="hoursList"
        [value]="hours"
        (selectionChange)="onHoursChange($event)"
        textField="text"
        valueField="value"
        [valuePrimitive]="true"
        [popupSettings]="popup">
    </kendo-dropdownlist>

    <span>&nbsp;:&nbsp;</span>

    <kendo-dropdownlist
        class="hide-arrow"
        [disabled]="disabled"
        [defaultItem]="defaultMinute"
        [data]="minutesList"
        [value]="minutes"
        (selectionChange)="onMinutesChange($event)"
        textField="text"
        valueField="value"
        [valuePrimitive]="true"
        [popupSettings]="popup">
    </kendo-dropdownlist>
</div>
