import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VendorNoteType } from 'src/app/admin/vendor-details/models/vendor-note-type.model';
import { VendorNote } from 'src/app/admin/vendor-details/models/vendor-note.model';
import { APP_CONFIG, Settings } from 'src/app/config/settings';

@Injectable({
    providedIn: 'root'
})
export class VendorNoteService {
    constructor(
        private readonly _httpClient: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {}

    getVendorNoteTypes(): Observable<VendorNoteType[]> {
        return this._httpClient.get<VendorNoteType[]>(`${this._settings.CORE}/ayaconnect/admin/vendors/notes/types`);
    }

    getVendorNotesByVendorId(vendorId: number): Observable<VendorNote[]> {
        return this._httpClient.get<VendorNote[]>(
            `${this._settings.CORE}/ayaconnect/admin/vendors/${vendorId}/vendor-notes`
        );
    }

    addVendorNote(note: Partial<VendorNote>, vendorId: number): Observable<unknown> {
        return this._httpClient.post(`${this._settings.CORE}/ayaconnect/admin/vendors/${vendorId}/vendor-notes`, note);
    }
}
