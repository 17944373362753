import { Pipe, PipeTransform } from '@angular/core';
import { TypeListItem } from '../models/vendor.model';

@Pipe({
    name: 'vendorEmailAddress'
})
export class VendorEmailAddressPipe implements PipeTransform {
    constructor() {}

    transform(email: string, emailTypeId: number, emailTypes: TypeListItem[]): string {
        const type = emailTypes?.find((x) => x.id === emailTypeId);
        if (!email) {
            return `${type.type}: `;
        }

        return `${type?.type ?? ''}: ${email}`;
    }
}
