import { Action, createReducer, on } from '@ngrx/store';
import * as ExtensionActions from '../actions/travelers-extension.actions';

export interface ExtensionLogoState {
    logo: Blob;
    error: string;
}

const initialState: ExtensionLogoState = {
    logo: null,
    error: ''
};

export function extensionLogoReducer(state: ExtensionLogoState, action: Action) {
    return createReducer(
        initialState,
        on(ExtensionActions.loadExtensionLogo, (state, action) => ({
            ...state
        })),

        on(ExtensionActions.loadExtensionLogoSuccess, (state, action) => ({
            ...state,
            logo: action.logo
        })),

        on(ExtensionActions.loadExtensionLogoFail, (state, action) => ({
            ...state,
            error: action.error
        }))
    )(state, action);
}

export function reducer(state: ExtensionLogoState | undefined, action: Action) {
    return extensionLogoReducer(state, action);
}
