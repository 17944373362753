import { createAction, props } from '@ngrx/store';
import { ReleaseGroupEdit } from 'src/app/facilities/models/release-group-edit.model';
import { ReleaseGroup } from 'src/app/facilities/models/release-group.model';
import { AcceptedVendor } from 'src/app/facilities/models/accepted-vendor.model';
import { ReleasePermissions } from 'src/app/facilities/models/release-permissions.model';

export enum ShiftReleaseSettingsActionEnum {
    LoadShiftReleaseSettingsPermissions = '[Admin Facility Shift Release Settings Component] Shift Release Settings Permissions',
    LoadShiftReleaseSettingsPermissionsSuccess = '[Admin Facility Shift Release Settings API] Load Shift Release Settings Permissions Success',
    LoadShiftReleaseSettingsPermissionsFailure = '[Admin Facility Shift Release Settings API] Load Shift Release Settings Permissions Failure',

    LoadShiftReleaseGroups = '[Admin Facility Shift Release Settings Component] Load Shift Release Groups',
    LoadShiftReleaseGroupsSuccess = '[Admin Facility Shift Release Settings API] Load Shift Release Groups Success',
    LoadShiftReleaseGroupsFailure = '[Admin Facility Shift Release Settings API] Load Shift Release Groups Failure',

    SaveShiftReleaseGroup = '[Admin Facility Shift Release Settings API] Save Shift Release Group',
    SaveShiftReleaseGroupSuccess = '[Admin Facility Shift Release Settings API] Save Shift Release Group Success',
    SaveShiftReleaseGroupFailure = '[Admin Facility Shift Release Settings API] Save Shift Release Group Failure',

    DeleteShiftReleaseGroup = '[Admin Facility Shift Release Settings API]] Delete Shift Release Group',
    DeleteShiftReleaseGroupSuccess = '[Admin Facility Shift Release Settings API] Delete Shift Release Group Success',
    DeleteShiftReleaseGroupFailure = '[Admin Facility Shift Release Settings API] Delete Shift Release Group Failure',

    LoadShiftReleaseGroupVendors = '[Admin Facility Shift Release Settings API] Load Shift Release Group Vendors',
    LoadShiftReleaseGroupVendorsSuccess = '[Admin Facility Shift Release Settings API] Load Shift Release Group Vendors Success',
    LoadShiftReleaseGroupVendorsFailure = '[Admin Facility Shift Release Settings API] Load Shift Release Group Vendors Failure',

    CopyShiftReleaseSettingsToFacilities = '[Admin Facility Shift Release Settings Component] Copy Shift Release Setting To Facilities',
    CopyShiftReleaseSettingsToFacilitiesSuccess = '[Admin Facility Shift Release Settings API] Copy Shift Release Setting To Facilities Success',
    CopyShiftReleaseSettingsToFacilitiesFailure = '[Admin Facility Shift Release Settings API] Copy Shift Release Setting To Facilities Failure',

    CopyShiftReleaseSettingsFromFacility = '[Admin Facility Shift Release Settings Component] Copy Release Setting From Facility',
    CopyShiftReleaseSettingsFromFacilitySuccess = '[Admin Facility Shift Release Settings API] Copy Release Setting From Facility Success',
    CopyShiftReleaseSettingsFromFacilityFailure = '[Admin Facility Shift Release Settings API] Copy Release Setting From Facility Failure'
}

export const loadShiftReleaseSettingsPermissions = createAction(
    ShiftReleaseSettingsActionEnum.LoadShiftReleaseSettingsPermissions,
    props<{ facilityId: number }>()
);
export const loadShiftReleaseSettingsPermissionsSuccess = createAction(
    ShiftReleaseSettingsActionEnum.LoadShiftReleaseSettingsPermissionsSuccess,
    props<{ releaseGroupsPermissions: ReleasePermissions }>()
);
export const loadShiftReleaseSettingsPermissionsFailure = createAction(
    ShiftReleaseSettingsActionEnum.LoadShiftReleaseSettingsPermissionsFailure,
    props<{ error: any }>()
);

export const loadShiftReleaseGroups = createAction(
    ShiftReleaseSettingsActionEnum.LoadShiftReleaseGroups,
    props<{ facilityId: number }>()
);
export const loadShiftReleaseGroupsSuccess = createAction(
    ShiftReleaseSettingsActionEnum.LoadShiftReleaseGroupsSuccess,
    props<{ releaseGroups: ReleaseGroup[] }>()
);
export const loadShiftReleaseGroupsFailure = createAction(
    ShiftReleaseSettingsActionEnum.LoadShiftReleaseGroupsFailure,
    props<{ error: any }>()
);

export const deleteShiftReleaseGroup = createAction(
    ShiftReleaseSettingsActionEnum.DeleteShiftReleaseGroup,
    props<{ groupId: number; facilityId: number }>()
);
export const deleteShiftReleaseGroupSuccess = createAction(
    ShiftReleaseSettingsActionEnum.DeleteShiftReleaseGroupSuccess,
    props<{ facilityId: number }>()
);
export const deleteShiftReleaseGroupFailure = createAction(
    ShiftReleaseSettingsActionEnum.DeleteShiftReleaseGroupFailure,
    props<{ error: any }>()
);

export const saveShiftReleaseGroup = createAction(
    ShiftReleaseSettingsActionEnum.SaveShiftReleaseGroup,
    props<{ group: ReleaseGroupEdit }>()
);
export const saveShiftReleaseGroupSuccess = createAction(
    ShiftReleaseSettingsActionEnum.SaveShiftReleaseGroupSuccess,
    props<{ facilityId: number }>()
);
export const saveShiftReleaseGroupFailure = createAction(ShiftReleaseSettingsActionEnum.SaveShiftReleaseGroupFailure);

export const loadShiftReleaseGroupVendors = createAction(
    ShiftReleaseSettingsActionEnum.LoadShiftReleaseGroupVendors,
    props<{ facilityId: number }>()
);
export const loadShiftReleaseGroupVendorsSuccess = createAction(
    ShiftReleaseSettingsActionEnum.LoadShiftReleaseGroupVendorsSuccess,
    props<{ vendors: AcceptedVendor[] }>()
);
export const loadShiftReleaseGroupVendorsFailure = createAction(
    ShiftReleaseSettingsActionEnum.LoadShiftReleaseGroupVendorsFailure
);

export const copyShiftReleaseSettingsToFacilities = createAction(
    ShiftReleaseSettingsActionEnum.CopyShiftReleaseSettingsToFacilities,
    props<{ fromFacilityId: number; toFacilityIds: number[] }>()
);
export const copyShiftReleaseSettingsToFacilitiesSuccess = createAction(
    ShiftReleaseSettingsActionEnum.CopyShiftReleaseSettingsToFacilitiesSuccess
);
export const copyShiftReleaseSettingsToFacilitiesFailure = createAction(
    ShiftReleaseSettingsActionEnum.CopyShiftReleaseSettingsToFacilitiesFailure,
    props<{ error: any }>()
);

export const copyShiftReleaseSettingsFromFacility = createAction(
    ShiftReleaseSettingsActionEnum.CopyShiftReleaseSettingsFromFacility,
    props<{ fromFacilityId: number; toFacilityId: number }>()
);
export const copyShiftReleaseSettingsFromFacilitySuccess = createAction(
    ShiftReleaseSettingsActionEnum.CopyShiftReleaseSettingsFromFacilitySuccess,
    props<{ facilityId: number }>()
);
export const copyShiftReleaseSettingsFromFacilityFailure = createAction(
    ShiftReleaseSettingsActionEnum.CopyShiftReleaseSettingsFromFacilityFailure,
    props<{ error: any }>()
);
