<dialog-layout title="Book Internal Candidate">
    <div mat-dialog-content [formGroup]="form">
        <div class="k-form-inline">
            <div class="k-form-field k-form-field-static">
                <span><strong>Shifts:</strong></span>
                   {{profile?.shifts}}
            </div>

            <div class="k-form-field k-form-field-static">
                <span><strong>Hours:</strong></span>
                <div>
                    {{profile?.formattedShiftHours}}
                </div>
            </div>

            <div class="k-form-field">
                <span><strong>Start Date:</strong></span>
                <kendo-datepicker formControlName="startDate"></kendo-datepicker>
                <validation-message
                    [control]="form.get('startDate')"
                    label="Start Date">
                </validation-message>
            </div>

            <div class="k-form-field">
                <span><strong>Duration:</strong></span>
                <kendo-numerictextbox
                    formControlName="shiftDuration"
                    min="0"
                    max="999"
                    autoCorrect="true"
                    format="0"
                    decimals="0">
                </kendo-numerictextbox>
                <span>&nbsp;weeks</span>
                <validation-message
                    [control]="form.get('shiftDuration')"
                    label="Duration">
                </validation-message>
            </div>


            <div class="k-form-field k-form-field-static">
                <span><strong>Select Workdays:</strong></span>
                <ayac-workdays-select
                    [maxCount]="profile.shifts"
                    formControlName="workDays">
                </ayac-workdays-select>
            </div>
            <div class="k-form-field">
                <span><strong>End Date:</strong></span>
                <kendo-datepicker formControlName="endDate"></kendo-datepicker>
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <p>
            *Booking an internal candidate will push data to shifts calendar.
        </p>
        <span class="spacer"></span>
        <button
            mat-flat-button
            color="accent"
            (click)="onSave()"
            [disabled]="form.invalid"
            style="width:fit-content">
               Book
        </button>
        <button
            mat-stroked-button
            color="basic"
            (click)="onClose()"
            style="width:fit-content">
                Cancel
        </button>
    </div>
</dialog-layout>
