import { createReducer, Action, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { PowerBiSettings } from 'src/app/shared/models/powerbi/powerbi-settings.model';
import * as actions from 'src/app/powerbi/store/powerbi-report.actions';

export interface PowerBiReportSettingsState {
    loading: LoadingTypes;
    saving: LoadingTypes;
    settings: PowerBiSettings;
}

export const initialPowerBiReportSettingsState: PowerBiReportSettingsState = {
    loading: LoadingTypes.INIT,
    saving: LoadingTypes.INIT,
    settings: null
};

export function powerBiReportSettingsReducer(state: PowerBiReportSettingsState, action: Action) {
    return createReducer(
        initialPowerBiReportSettingsState,

        on(actions.loadPowerBiReportSettings, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(actions.loadPowerBiReportSettingsSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,
            settings: action.settings
        })),

        on(actions.loadPowerBiReportSettingsFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            settings: null
        })),

        on(actions.savePowerBiReportSettings, actions.clearPowerBiReportSettings, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADING
        })),

        on(actions.savePowerBiReportSettingsSuccess, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADED
        })),

        on(actions.clearPowerBiReportSettingsSuccess, (state, action) => ({
            ...state,
            saving: LoadingTypes.LOADED,
            settings: null
        })),

        on(actions.savePowerBiReportSettingsFail, actions.clearPowerBiReportSettingsFail, (state, action) => ({
            ...state,
            saving: LoadingTypes.FAIL
        })),

        on(actions.clearPowerBiReport, (state, action) => ({
            ...initialPowerBiReportSettingsState
        }))
    )(state, action);
}
