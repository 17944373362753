<div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
        <form [formGroup]="form">
            <ayac-form-control-wrapper [controlLabel]="vendorLabel" [showRequiredAsterisk]="true" class="contact-control" *ngIf="isAdminForm">
                <mat-form-field appearance="outline">
                    <mat-select formControlName="vendorId" data-qa-id="VendorId">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Select or search a vendor from the list…" noEntriesFoundLabel="" formControlName="vendorFilter"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                                        *ngFor="let vendor of filteredVendors | async"
                                        [value]="vendor.id">
                                {{ vendor.name }}
                            </mat-option>
                    </mat-select>
                    <mat-error *ngIf="vendorIdControl.hasError('required')">{{ vendorLabel }} is required.</mat-error>
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="firstNameLabel" [showRequiredAsterisk]="true" class="first-name-control">
                <mat-form-field appearance="outline">
                    <input matInput [placeholder]="firstNameLabel" formControlName="firstName" data-qa-id="FirstName" required />
                    <mat-error *ngIf="firstNameControl.hasError('required')">{{ firstNameLabel }} is required.</mat-error>
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="middleNameLabel" class="middle-name-control">
                <mat-form-field appearance="outline">
                    <input matInput [placeholder]="middleNameLabel" formControlName="middleName" data-qa-id="MiddleName" />
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="lastNameLabel" [showRequiredAsterisk]="true" class="last-name-control">
                <mat-form-field appearance="outline">
                    <input matInput [placeholder]="lastNameLabel" formControlName="lastName" data-qa-id="LastName" required />
                    <mat-error *ngIf="lastNameControl.hasError('required')">{{ lastNameLabel }} is required.</mat-error>
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="emailLabel" [showRequiredAsterisk]="true" class="email-control">
                <mat-form-field appearance="outline">
                    <input matInput [placeholder]="emailLabel" formControlName="email" data-qa-id="Email" type="email" required />
                    <mat-error *ngIf="emailControl.hasError('required')">{{ emailLabel }} is required.</mat-error>
                    <mat-error *ngIf="emailControl.hasError('email')">Invalid {{ emailLabel }}.</mat-error>
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="contactLabel" [showRequiredAsterisk]="true" class="contact-control">
                <mat-form-field appearance="outline">
                    <mat-select formControlName="contact" required data-qa-id="Contact">
                        <mat-option *ngFor="
                                let contact of vendorContacts
                                    | notEqualsFilter: ' ':'firstName'
                                    | notEqualsFilter: null:'firstName'
                            " [value]="contact.id">
                            {{ contact | vendorContactName }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="contactControl.hasError('required') && (contactControl.dirty || contactControl.touched)">{{ contactLabel }} is required.</mat-error>
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper class="contact-details-control" *ngIf="isAdminForm">
                <div class="contact-details-container">
                    <div class="contact-detail-phone">
                        Phone: {{selectedVendorContact | vendorContactPhone}}
                    </div>
                    <div class="contact-detail-email">
                        Email: <a href="mailto:{{selectedVendorContact | vendorContactEmail}}" target="_top" >{{selectedVendorContact | vendorContactEmail}}</a>
                    </div>
                </div>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="preferredShiftLabel" class="preferred-shift-control">
                <mat-form-field appearance="outline">
                    <mat-select formControlName="preferredShifts" data-qa-id="VendorCandidatesPreferredShifts" multiple>
                        <mat-option *ngFor="let shift of shiftTypes$ | async" [value]="shift.id">
                            {{ shift.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="ssnLabel" class="ssn-control">
                <ng-container *ngIf="candidate?.includeSSN ?? true; else secureSsn">
                    <mat-form-field appearance="outline">
                        <input matInput [placeholder]="ssnLabel" formControlName="ssn" data-qa-id="SSN" mask="000-00-0000" [type]="showSsn || isNew ? 'text' : 'password'" autocomplete="off webauthn"/>
                        <button mat-icon-button matSuffix (click)="toggleShowSsn()" *ngIf="!isNew">
                            <mat-icon>{{ showSsn ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                    </mat-form-field>
                </ng-container>
                <ng-template #secureSsn>
                    <div class="ssn-coverup">
                        <span>*********</span>
                        <mat-icon (click)="editSsn()" data-qa-id="ssn-edit-btn" class="edit-ssn">edit</mat-icon>
                    </div>
                </ng-template>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="dateOfBirthLabel" class="date-of-birth-control">
                <mat-form-field appearance="outline">
                    <input matInput [matDatepicker]="dateOfBirthPicker" formControlName="dateOfBirth" data-qa-id="DateOfBirth" />
                    <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfBirthPicker></mat-datepicker>
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="availableStartDateLabel" [showRequiredAsterisk]="isAdminForm" class="available-start-date-control">
                <mat-form-field appearance="outline">
                    <input matInput [matDatepicker]="startDatePicker" formControlName="availableStartDate" data-qa-id="AvailableStartDate" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                    <mat-error *ngIf="availableStartDateControl.hasError('required')">{{ availableStartDateLabel }} is required.</mat-error>
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ayac-form-control-wrapper [controlLabel]="rtoLabel" class="rto-control">
                <div class="rto-container">
                    <mat-radio-group class="rto-radio-group" formControlName="rto" [attr.data-qa-id]="'rto-radio-toggle'" (change)="rtoToggleChange($event)">
                        <mat-radio-button [value]="true">YES, RTO NEEDED</mat-radio-button>
                        <mat-radio-button [value]="false">NO, RTO NOT NEEDED</mat-radio-button>
                    </mat-radio-group>

                    <datepicker-multiple *ngIf="rtoToggle" formControlName="requestTimeOffDates"
                        [title]="'Select RTO dates'"
                        [format]="requestTimeOffDatesFormat"
                        [min]="minDate"
                        [validateField]="true"
                        [showRequiredAsterisk]="rtoToggle"
                        [useMaterialIcon]="true">
                    </datepicker-multiple>
                </div>
            </ayac-form-control-wrapper>

            <ng-container *ngIf="additionalValidation; else address1NoValidation">
                <ayac-form-control-wrapper [controlLabel]="address1Label" [showRequiredAsterisk]="additionalValidation" class="address1-control">
                    <mat-form-field appearance="outline">
                        <input matInput [placeholder]="address1Label" formControlName="address1" data-qa-id="Address1" />
                        <mat-error *ngIf="address1Control.hasError('required')">{{ address1Label }} is required.</mat-error>
                    </mat-form-field>
                </ayac-form-control-wrapper>
            </ng-container>

            <ng-template #address1NoValidation>
                <ayac-form-control-wrapper [controlLabel]="address1Label" class="address1-control">
                    <mat-form-field appearance="outline">
                        <input matInput [placeholder]="address1Label" formControlName="address1" data-qa-id="Address1" />
                    </mat-form-field>
                </ayac-form-control-wrapper>
            </ng-template>

            <ayac-form-control-wrapper [controlLabel]="address2Label" class="address2-control">
                <mat-form-field appearance="outline">
                    <input matInput [placeholder]="address2Label" formControlName="address2" data-qa-id="Address2" />
                    <mat-error *ngIf="address2Control.hasError('maxlength')">{{ address2Label }} cannot exceed 50 characters.</mat-error>
                </mat-form-field>
            </ayac-form-control-wrapper>

            <ng-container *ngIf="additionalValidation; else cityNoValidation">
                <ayac-form-control-wrapper [controlLabel]="cityLabel" [showRequiredAsterisk]="additionalValidation" class="city-control">
                    <mat-form-field appearance="outline">
                        <input matInput [placeholder]="cityLabel" formControlName="city" data-qa-id="City" />
                        <mat-error *ngIf="cityControl.hasError('required')">{{ cityLabel }} is required.</mat-error>
                    </mat-form-field>
                </ayac-form-control-wrapper>
            </ng-container>

            <ng-template #cityNoValidation>
                <ayac-form-control-wrapper [controlLabel]="cityLabel" class="city-control">
                    <mat-form-field appearance="outline">
                        <input matInput [placeholder]="cityLabel" formControlName="city" data-qa-id="City" />
                    </mat-form-field>
                </ayac-form-control-wrapper>
            </ng-template>

            <ayac-form-control-wrapper [controlLabel]="stateLabel" [showRequiredAsterisk]="additionalValidation" class="state-control">
                <mat-form-field appearance="outline">
                    <mat-select formControlName="state" data-qa-id="State">
                        <ng-container *ngIf="isAdminForm; else nonGroupedStates">
                            <mat-optgroup *ngFor="let countryStateGroup of countryStatesLookup$ | async" [label]="countryStateGroup.country.name">
                                <mat-option
                                            *ngFor="let state of countryStateGroup.states | notEqualsFilter: '':'code'"
                                            [value]="state.id">
                                    {{ state.name }}
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                        <ng-template #nonGroupedStates>
                            <ng-container *ngFor="let countryStateGroup of countryStatesLookup$ | async">
                                <mat-option
                                            *ngFor="let state of countryStateGroup.states | notEqualsFilter: '':'code'"
                                            [value]="state.id">
                                    {{ state.name }}
                                </mat-option>
                            </ng-container>
                        </ng-template>
                    </mat-select>
                    <mat-error *ngIf="additionalValidation && stateControl.hasError('required')">{{ stateLabel }} is required.</mat-error>
                </mat-form-field>
            </ayac-form-control-wrapper>


            <ng-container *ngIf="additionalValidation; else zipNoValidation">
                <ayac-form-control-wrapper [controlLabel]="zipCodeLabel" [showRequiredAsterisk]="additionalValidation" class="zip-code-control">
                    <mat-form-field appearance="outline">
                        <input matInput [placeholder]="zipCodeLabel" formControlName="zipCode" data-qa-id="ZipCode" mask="00000" />
                        <mat-error *ngIf="zipCodeControl.hasError('mask')">{{ zipCodeLabel }} has to be 5 characters long.</mat-error>
                        <mat-error *ngIf="zipCodeControl.hasError('required')">{{ zipCodeLabel }} is required.</mat-error>
                    </mat-form-field>
                </ayac-form-control-wrapper>
            </ng-container>

            <ng-template #zipNoValidation>
                <ayac-form-control-wrapper [controlLabel]="zipCodeLabel" class="zip-code-control">
                    <mat-form-field appearance="outline">
                        <input *ngIf="isAdminForm; else nonAdminZip" matInput [placeholder]="zipCodeLabel" formControlName="zipCode" data-qa-id="ZipCode" [maxlength]="50" />
                        <ng-template #nonAdminZip>
                            <input matInput [placeholder]="zipCodeLabel" formControlName="zipCode" data-qa-id="ZipCode" mask="00000" />
                        </ng-template>
                        <mat-error *ngIf="zipCodeControl.hasError('mask')">{{ zipCodeLabel }} has to be 5 characters
                            long.</mat-error>
                            <mat-error *ngIf="zipCodeControl.hasError('maxlength')">{{ zipCodeLabel }} cannot exceed 50 characters.</mat-error>
                    </mat-form-field>
                </ayac-form-control-wrapper>
            </ng-template>

            <ayac-form-control-wrapper [controlLabel]="sellingPointsLabel" class="selling-points-control" *ngIf="!isAdminForm">
                <mat-form-field appearance="outline">
                    <textarea matInput [placeholder]="sellingPointsLabel" formControlName="sellingPoints" data-qa-id="SellingPoints" rows="10" cols="50"></textarea>
                    <mat-error *ngIf="sellingPointsControl.hasError('maxlength')">{{ sellingPointsLabel }} cannot exceed 1500 characters.</mat-error>
                </mat-form-field>
            </ayac-form-control-wrapper>
        </form>
    </div>
</div>
