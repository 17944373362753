import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../services/dashboard.service';
import { withLatestFrom, catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getFilter } from '../selectors/filter.selectors';
import { loadPendingTimecardsSuccess, loadPendingTimecardsFail, loadPendingTimecards } from '../actions';
import { getPendingTimecardsAgingDays } from '../selectors';

@Injectable()
export class PendingTimecardsEffect {
    constructor(
        private actions$: Actions,
        private store$: Store<{}>,
        private service: DashboardService
    ) {}

    getPendingTimecards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPendingTimecards),
            withLatestFrom(this.store$.select(getFilter), this.store$.select(getPendingTimecardsAgingDays)),
            switchMap(([action, query, agingDays]) => {
                const filter = {
                    lineOfBusinessId: query.lineOfBusiness.id > 0 ? query.lineOfBusiness.id : null,
                    facilityId: query.facility.id > 0 ? query.facility.id : null,
                    unitId: query.unit.id > 0 ? query.unit.id : null,
                    agingDays: agingDays
                };

                return this.service.getPendingTimecards(filter).pipe(
                    map((response) =>
                        loadPendingTimecardsSuccess({
                            totalCount: response.totalCount,
                            agingCount: response.agingCount
                        })
                    ),
                    catchError((error) => of(loadPendingTimecardsFail({ error })))
                );
            })
        )
    );
}
