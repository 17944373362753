<div class="panel panel-default">
    <div class="panel-body">
        <form  [formGroup]="vendorProfileForm">
            <fieldset>
                <legend>Vendor Profile</legend>

                <div class="container contract-line" *ngFor="let line of (contractLines$ | async)?.lines" [formGroupName]="line.id">
                    <label class="control-label contract-line-status">
                        {{ line.name }} Status:
                    </label>

                    <div class="contract-line-container">
                        <div class="checkbox-container" *ngFor="let item of line.contractTypes">
                            <mat-checkbox [formControlName]="item.id">
                                {{item.name}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="container input-container-grid">
                    <label for="operName" class="control-label">
                            Legal Name:
                    </label>
                    <div>
                        <mat-form-field class="vendor-profile-field" appearance="outline">
                            <input id="legalName" placeholder="Legal Name" matInput type="text" formControlName="legalName">
                        </mat-form-field>
                        <mat-error *ngIf="vendorProfileForm.get('legalName').hasError('required')">Legal Name is required</mat-error>
                    </div>

                </div>

                <div class="container input-container-grid">
                    <label for="operName" class="control-label">
                        Oper. Name:
                    </label>

                    <mat-form-field class="vendor-profile-field" appearance="outline">
                        <input id="operName" placeholder="Operating Name" matInput type="text" formControlName="operationName">
                    </mat-form-field>
                </div>

                <div class="container input-container-grid">
                    <label for="type" class="control-label">
                        Type:
                    </label>

                    <mat-form-field floatLabel="never" class="vendor-profile-field" appearance="outline">
                        <mat-select placeholder="Select a type" formControlName="businessType">
                            <mat-option *ngFor="let type of businessTypes$ | async" [value]="type.id">{{ type.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="container input-container-grid">
                    <label for="fedTaxId" class="control-label">
                        Fed TaxID:
                    </label>

                    <mat-form-field class="vendor-profile-field" appearance="outline">
                        <input id="fedTaxId" placeholder="fedTaxId" matInput type="text" formControlName="fedTaxId">
                    </mat-form-field>
                </div>

                <div class="container input-container-grid">
                    <label for="stateIncor" class="control-label">
                        State Incorp.:
                    </label>

                    <mat-form-field floatLabel="never" class="vendor-profile-field" appearance="outline">
                        <mat-select placeholder="Select a state" id="stateIncor" formControlName="stateIncorporated">
                            <mat-option *ngFor="let state of states" [value]="state.id">
                                {{ state.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="container input-container-grid">
                    <label for="dateIncor" class="control-label">
                        Date Incorp.:
                    </label>

                    <mat-form-field class="vendor-profile-field" appearance="outline">
                        <input matInput placeholder="Day-Month-Year" [matDatepicker]="dateIncorporated" formControlName="dateIncorporated">
                        <mat-datepicker-toggle matSuffix [for]="dateIncorporated"></mat-datepicker-toggle>
                        <mat-datepicker #dateIncorporated>
                        </mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="container input-container-grid">
                    <label for="dateIncor" class="control-label">
                        Agreement Date:
                    </label>

                    <mat-form-field class="vendor-profile-field" appearance="outline">
                        <input matInput placeholder="Day-Month-Year" [matDatepicker]="agreementDate" formControlName="agreementDate">
                        <mat-datepicker-toggle matSuffix [for]="agreementDate"></mat-datepicker-toggle>
                        <mat-datepicker #agreementDate>
                        </mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="container input-container-grid">
                    <label for="dateIncor" class="control-label">
                        Agency Active Date:
                    </label>

                    <mat-form-field class="vendor-profile-field" appearance="outline">
                        <input matInput placeholder="Day-Month-Year" [matDatepicker]="agencyActiveDate" formControlName="agencyActiveDate">
                        <mat-datepicker-toggle matSuffix [for]="agencyActiveDate"></mat-datepicker-toggle>
                        <mat-datepicker #agencyActiveDate>
                        </mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="container input-container-grid">
                    <label for="" class="control-label">
                    </label>

                    <mat-checkbox formControlName="autoOfferNewFacility">
                        Auto-Offer New Facilities
                    </mat-checkbox>
                </div>

                <div class="container input-container-grid">
                    <label for="" class="control-label">
                        Tags:
                    </label>
                    <div>
                        <kendo-multiselect
                                           placeholder="Add a tag"
                                           [data]="(vendorTypes$ | async)?.types"
                                           [kendoDropDownFilter]="filterSettings"
                                           textField="type"
                                           valueField="vendorTypeId"
                                           formControlName="tags">
                        </kendo-multiselect>

                        <ayac-vendor-tags-validity
                                                   [control]="vendorProfileForm.get('tags')"
                                                   [tags]="(vendorTypes$ | async)?.types">
                        </ayac-vendor-tags-validity>
                    </div>

                </div>

                <div class="container input-container-grid">
                    <label for="accountId" class="control-label">
                        Accounting ID:
                    </label>

                    <mat-form-field class="vendor-profile-field" appearance="outline">
                        <input
                            id="accountId"
                            placeholder="Accounting ID"
                            matInput
                            class="accountId-input"
                            type="number"
                            formControlName="accountingId" maxlength="9">
                    </mat-form-field>
                </div>

            </fieldset>
        </form>
    </div>
</div>
