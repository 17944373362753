/**angular */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';

/**kendo */
import { GridDataResult } from '@progress/kendo-angular-grid';

/**shared */
import { FilterRequest } from 'src/app/shared/models/filtered-request';
import { combine } from 'src/app/shared/utilities';
import { interpolateParams } from 'src/app/core/utils';

/**rxjs */
import { Observable, of } from 'rxjs';

/**local */
import { SubmittalsExportAll } from 'src/app/admin/submittals/models/submittals-export-all.model';
import {
    SubmittalStageEntry,
    VendorSubmittalDetails
} from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';
import { ListItem } from 'src/app/shared/models/list-item';
import { SortTypes } from 'src/app/shared/models';
import { renameKeys } from 'src/app/core/utils/rename-keys';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { SubmittalVendorListItem } from 'src/app/shared/models/submittals-vendor/submittal-vendor-list-item.model';

@Injectable({
    providedIn: 'root'
})
export class SubmittalsService {
    private readonly apiPath: string;

    constructor(
        private readonly _httpClient: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {
        this.apiPath = combine(_settings.CORE, 'ayaconnect', 'submittalAdmin');
    }

    getSubmittalDetails(submittalId: number): Observable<VendorSubmittalDetails> {
        return this._httpClient.get<VendorSubmittalDetails>(
            `${this._settings.CORE}/AyaConnect/admin/vendors/submittals/${submittalId}`
        );
    }

    getSubmittalStages(): Observable<ListItem[]> {
        return this._httpClient.get<ListItem[]>(`${this._settings.CORE}/AyaConnect/admin/vendors/submittals/stages`);
    }

    getSubmittalStageReasons(): Observable<ListItem[]> {
        return this._httpClient.get<ListItem[]>(
            `${this._settings.CORE}/AyaConnect/admin/vendors/submittals/stage-reasons`
        );
    }

    updateSubmittalNote(submittalId: number, note: string): Observable<void> {
        return this._httpClient.put<void>(`${this._settings.CORE}/AyaConnect/admin/vendors/submittals`, {
            vendorSubmittalId: submittalId,
            note
        });
    }

    updateSubmittalStage(submittalStage: SubmittalStageEntry): Observable<SubmittalStageEntry> {
        return this._httpClient.put<SubmittalStageEntry>(
            `${this._settings.CORE}/AyaConnect/admin/vendors/submittals/stage`,
            {
                note: submittalStage.note,
                submittalId: submittalStage.submittalId,
                submittalStageId: submittalStage.currentStageId,
                submittalStageReasonId: submittalStage.submittalStageReasonId
            }
        );
    }

    getSubmittals_V2(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj.sortField;
        sortArgsObj['sort.type'] = sortArgsObj.sortType === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }
        const params = interpolateParams({
            ...pagination,
            ...sortArgs,
            ...matchArgsObj
        });
        const url = `${this._settings.CORE}/AyaConnect/admin/vendors/submittals/GetFilteredSubmittals`;
        return this._httpClient.get<PagingToken<SubmittalVendorListItem[]>>(url, { params });
    }

    getSubmittalStatuses(): Observable<ListItem[]> {
        return this._httpClient.get<ListItem[]>(`${this._settings.CORE}/AyaConnect/admin/vendors/submittals/statuses`);
    }

    getSubmittalSubStatuses(statusId: number): Observable<ListItem[]> {
        return this._httpClient.get<ListItem[]>(
            `${this._settings.CORE}/AyaConnect/admin/vendors/submittals/sub-statuses/${statusId}`
        );
    }

    exportAll(filter: SubmittalsExportAll): Observable<Blob> {
        const url = `${this._settings.CORE}/AyaConnect/admin/vendors/submittals/Export/Excel/`;
        const params = interpolateParams({ ...filter });
        return this._httpClient.get(url, { responseType: 'blob', params });
    }
}
