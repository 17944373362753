<portal-grid class="portal-grid-scroll-auto highlighted-portal-grid" data-title="Upcoming Shifts" [data]="currentShiftsResult" [query]="currentShiftsQuery$ | async"
(dataStateChange)="onDataStateChange($event)" (rowClicked)="onRowClicked($event)" filterable="false" [rowClass]="gridRowClass" [showPageSizes]="false" >

    <ng-template #header let-title="title" let-total="total">
        <div class="title">
            Upcoming Shifts
        </div>
    </ng-template>

    <ng-template #toolbar>
        <button mat-flat-button color="accent" data-qa-id="ac_vendor_view_shift_details_button" [disabled]="!selectedCurrentShift" (click)="viewCurrentShiftRequested.emit(selectedCurrentShift)">
            View Shift Details
        </button>
        <button mat-flat-button color="accent" data-qa-id="ac_vendor_shift_remove_candidate_button" [disabled]="!canRemoveCandidate" (click)="onDeleteShiftCandidate()">
            Remove Candidate
        </button>
    </ng-template>

    <portal-grid-column
        title=""
        field="isSelected"
        [sortable]="false"
        [style]="{'width': '20px'}"
        [headerStyle]="{'width': '20px'}">
        <ng-template #cell let-dataItem="dataItem">
            <mat-checkbox [checked]="dataItem.id === selectedCurrentShift?.id"></mat-checkbox>
        </ng-template>
    </portal-grid-column>

    <portal-grid-column title="Date" field="shiftDate" >
        <ng-template #cell let-dataItem="dataItem">
            <mat-label>{{dataItem.shiftDate | date : 'MM/dd/yyyy'}}</mat-label>
        </ng-template>
    </portal-grid-column>

    <portal-grid-column title="Time" field="endTime">
        <ng-template #cell let-dataItem="dataItem">
            <mat-label>{{dataItem.startTime | date : 'HH:mm'}} - {{dataItem.endTime | date : 'HH:mm'}}</mat-label>
        </ng-template>
    </portal-grid-column>

    <portal-grid-column title="Facility" field="facilityName">
    </portal-grid-column>

    <portal-grid-column title="City" field="facilityCity">
    </portal-grid-column>

    <portal-grid-column title="ST" field="facilityState">
    </portal-grid-column>

    <portal-grid-column title="Specialty" field="expertiseName">
    </portal-grid-column>

    <portal-grid-column title="Profession" field="professionName">
    </portal-grid-column>

    <portal-grid-column title="Rate" field="billRateCalculated">
        <ng-template #cell let-dataItem="dataItem">
            <mat-label>{{dataItem.billRateCalculated | currency :'USD':'symbol':'1.2-2'}}</mat-label>
        </ng-template>
    </portal-grid-column>

    <portal-grid-column title="Status" field="statusName">
    </portal-grid-column>
</portal-grid>
