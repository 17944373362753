import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { FilterBase } from './../base/filter-base';

@Component({
    selector: 'grid-checkbox-filter',
    templateUrl: './checkbox-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFilterComponent extends FilterBase implements OnInit {
    private selectedValues: any[];
    @Input() options: any;
    @Input() optionTemplate: TemplateRef<any>;
    @Input() filterStyle: any;
    @Input() uniqueFilterClass: string;
    optionTitles: string[];
    optionValues: any[];

    ngOnInit() {
        if (Array.isArray(this.options)) {
            this.optionTitles = this.optionValues = this.options;
        } else {
            this.optionTitles = Object.keys(this.options);
            this.optionValues = Object.values(this.options);
        }

        this.selectedValues = this.getLatestFilterValue() || [];
    }

    addOption(item: MatSelectionListChange) {
        const option = item.options[0];
        const ix = this.selectedValues.indexOf(option.value);
        if (ix >= 0) {
            this.selectedValues.splice(ix, 1);
        } else {
            this.selectedValues.push(option.value);
        }

        if (this.selectedValues.length === this.optionValues.length) {
            //clear filter if all options are checked
            this.filterService.filter({ filters: [], logic: 'and' });
        } else {
            this.filterService.filter({
                filters: [
                    {
                        field: this.field,
                        operator: 'eq',
                        value: this.selectedValues
                    }
                ],
                logic: 'and'
            });
        }
    }
}
