<mat-card>
    <mat-card-header>
        <mat-card-title>Shifts App</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="content">
        <div  class="k-form-inline">

            <ng-container [ngSwitch]="resourceStatus$ | async">
                 <div *ngSwitchCase="'application'" fxLayout="row" fxLayoutAlign="start center" class="mb-3">
                    <span class="icon icon-shifts-app mr-2"></span>
                    <span>Shifts User (Last Activity: {{ (resource$ | async)?.lastAppActivityDate | date : 'M/dd/yyyy' }})</span>
                </div>

                <div *ngSwitchCase="'invited'" fxLayout="row" fxLayoutAlign="start center" class="mb-3">
                    <mat-icon class="mr-2">email</mat-icon>
                    <span>
                        Invite Email (Sent on: {{ (resource$ | async)?.appInviteEmailSentDate | date : 'M/dd/yyyy' }})
                    </span>
                    <button mat-flat-button color="accent" class="mat-narrow ml-4" *ngIf="showButtonInvitation"
                        (click)="resendEmail()">
                        Resend Email
                    </button>
                </div>

                <ng-container *ngSwitchDefault>
                    <ng-container
                        *ngIf="editMode; then formTemplate; else viewTemplate">
                    </ng-container>
                </ng-container>
            </ng-container>

            <div class="k-form-field k-form-field-static"
                *ngIf="resourceUsername$ | async as resourceUsername">
                <span><strong>User ID Login:</strong></span>
                <div><strong>{{ resourceUsername }}</strong></div>
            </div>
        </div>

    </mat-card-content>

    <ng-template #formTemplate>
        <ng-container *ngIf="updateForm$ | async"></ng-container>
        <ng-container [formGroup]="form">
            <label class="k-form-field">
                <span style="width: 40%">
                    <strong>Send Invite Email</strong>
                    <br>
                    <small class="sub-label">(email will send after saving)</small>
                </span>
                <mat-radio-group formControlName="sendInvitationEmail" class="mt-2 form-field-control">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <br>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
            </label>
        </ng-container>
    </ng-template>

    <ng-template #viewTemplate>
        <ng-container *ngIf="resource$ | async as resource">
            <div class="k-form-field k-form-field-static">
                <span><strong>Send Invite Email:</strong></span>
                <div>{{ resource.sendInvitationEmail ? 'Yes' : 'No' }}</div>
            </div>
        </ng-container>
    </ng-template>

</mat-card>