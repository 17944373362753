import { Component, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, pairwise, takeUntil, tap } from 'rxjs/operators';
import { vendorProfileWebsiteActions } from 'src/app/admin/store/actions/vendor-profile-website.actions';
import {
    selectVendorWebsiteTypes,
    selectVisibleVendorWebsites
} from 'src/app/admin/store/selectors/vendor-profile.selectors';
import { VendorWebsiteType } from 'src/app/admin/vendor-details/models/vendor-website-type.model';
import { VendorWebsite } from 'src/app/admin/vendor-details/models/vendor-website.model';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-vendor-profile-websites',
    templateUrl: './vendor-profile-websites.component.html',
    styleUrls: ['./vendor-profile-websites.component.scss']
})
export class VendorProfileWebsitesComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    set vendorId(id: number) {
        if (id) {
            this._vendorId = id;

            if (!this._isSaving) {
                this.sitesFormArray.clear();
                this._store.dispatch(vendorProfileWebsiteActions.loadVendorProfileWebsites({ vendorId: id }));
            }
        } else {
            this._store.dispatch(vendorProfileWebsiteActions.resetWebsitesSection());
        }
    }

    @Input()
    set isSaving(saving: boolean) {
        this._isSaving = saving;

        if (saving) {
            this.expansionPanels.forEach((item) => {
                item.expanded = false;
            });

            this.sitesFormArray.clear();
        }
    }

    get vendorId(): number {
        return this._vendorId;
    }

    @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;

    websiteForm: UntypedFormGroup;

    websiteTypes$: Observable<VendorWebsiteType[]>;
    websites$: Observable<VendorWebsite[]>;

    private _vendorId = 0;
    private _isSaving = false;

    constructor(
        private readonly _builder: UntypedFormBuilder,
        private readonly _store: Store
    ) {
        super();
    }

    ngOnInit(): void {
        this.websiteForm = this._builder.group({
            sites: this._builder.array([])
        });

        this.websiteTypes$ = this._store.select(selectVendorWebsiteTypes);

        this.websites$ = this._store.select(selectVisibleVendorWebsites).pipe(
            tap((sites) => {
                if (this.sitesFormArray.length === 0) {
                    this.sitesFormArray.clear();
                    sites.forEach((site) => this.addSite(site));
                }
            })
        );

        this.websiteForm
            .get('sites')
            .valueChanges.pipe(
                pairwise(),
                map(([oldState, newState]) => {
                    let changes = {};
                    for (const key in newState) {
                        if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
                            changes[key] = newState[key];
                        }
                    }
                    return changes;
                }),
                filter((changes) => Object.keys(changes).length !== 0 && !this.websiteForm.invalid),
                takeUntil(this.d$)
            )
            .subscribe((changes) => {
                const sites: Partial<{ siteId: number; siteType: number; url: string }[]> = Object.values(changes);

                if (sites && sites.length > 0) {
                    sites.forEach((item) => {
                        this._store.dispatch(
                            vendorProfileWebsiteActions.updateWebsite({
                                siteId: item.siteId,
                                siteType: item.siteType,
                                siteUrl: item.url
                            })
                        );
                    });
                }
            });

        this._store.dispatch(vendorProfileWebsiteActions.loadVendorProfileWebsiteTypes());
    }

    get sitesFormArray(): UntypedFormArray {
        return (this.websiteForm?.get('sites') as UntypedFormArray) ?? this._builder.array([]);
    }

    addSite(site: VendorWebsite): void {
        const form = this._builder.group({
            siteId: this._builder.control(site.id),
            siteType: this._builder.control(site.vendorWebsiteTypeId),
            url: this._builder.control(site.url)
        });

        this.sitesFormArray.push(form);
    }

    removeSite(index: number): void {
        const siteId = this.sitesFormArray.at(index).get('siteId').value;
        this.sitesFormArray.clear();

        this._store.dispatch(vendorProfileWebsiteActions.deleteWebsite({ siteId }));
    }

    addNewSite(): void {
        const form = this._builder.group({
            siteId: this._builder.control(0),
            siteType: this._builder.control(1),
            url: this._builder.control('')
        });

        this.sitesFormArray.push(form);

        const site: VendorWebsite = {
            id: 0,
            vendorWebsiteTypeId: 1,
            url: '',
            isDeleted: false,
            note: undefined,
            vendorId: this.vendorId,
            vendorWebsiteType: {
                id: 1,
                type: 'Main'
            }
        };

        this._store.dispatch(vendorProfileWebsiteActions.addWebsite({ site }));
    }
}
