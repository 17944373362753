import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    FormGroup,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { loadSubmittalDeclineReasons } from 'src/app/submittals/store/submittals.actions';
import { Observable, takeUntil } from 'rxjs';
import { SubmittalDeclineReasons } from 'src/app/shared/models/submittals/lookups/submittal-decline-reasons.model';
import { selectSubmittalDeclineReasons } from 'src/app/submittals/store/submittals.selectors';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { SubmittalStatusLookup, SubmittalSubStatuses } from 'src/app/shared/models/submittals';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { Editor } from 'ngx-editor';

@Component({
    selector: 'ayac-decline-submittal-dialog',
    templateUrl: './decline-submittal-dialog.component.html',
    styleUrls: ['./decline-submittal-dialog.component.scss']
})
export class DeclineSubmittalDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    form: UntypedFormGroup;
    declineReasons$: Observable<SubmittalDeclineReasons[]>;
    noteLength: number;
    featureFlag = FeatureFlag;
    noteItemIsNotValid = false;
    editor!: Editor;
    isVendorClientCommunicationEnabled = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            declineReasonId?: number;
            notes: string;
            status?: SubmittalStatusLookup;
            facilityDirectApplyEnabled: boolean;
        },
        private readonly _dialogRef: MatDialogRef<DeclineSubmittalDialogComponent>,
        private readonly _store: Store,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _featureManager: LDFeatureManager
    ) {
        super();
    }

    get selectedReason(): SubmittalSubStatuses {
        return this.form?.get('reason')?.value;
    }

    get note(): string {
        return this.form?.get('note')?.value;
    }

    get isOtherSelected(): boolean {
        return this.selectedReason === SubmittalSubStatuses.Other;
    }

    ngOnInit() {
        if (!this.data.notes) this.data.notes = '';
        this.noteLength = this.data.notes ? this.data.notes.length : 0;

        this._featureManager
            .isEnabled(FeatureFlag.VendorClientCommunicationForApnDirectSubmittals)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.isVendorClientCommunicationEnabled = isEnabled && this.data.facilityDirectApplyEnabled;
                if (this.isVendorClientCommunicationEnabled) {
                    this.editor = new Editor();
                }
            });

        this._store.dispatch(loadSubmittalDeclineReasons());

        this.declineReasons$ = this._store.select(selectSubmittalDeclineReasons);

        this.form = this._formBuilder.group({
            reason: [this.data.declineReasonId, [Validators.required]],
            note: [this.data.notes]
        });

        this.form.addValidators(this.customNotesValidator());
    }

    customNotesValidator(): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            return this.isOtherSelected && !this.note ? { invalidNote: true } : null;
        };
    }

    declineCandidate() {
        const declineReasonId = this.form.get('reason').value;
        const notes = this.form.get('note').value;

        this._dialogRef.close({ declineReasonId, notes });
    }

    onNoteItemValidationChanged(isValid: boolean) {
        this.noteItemIsNotValid = !isValid;
    }
}
