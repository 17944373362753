<div class="offer-details-container">
    <mat-card data-qa-id="ac_client_smd_details_card" class="offer-details">
        <mat-card-title>Offer Details</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content class="offer-details-content">
            <div class="offer-details-sections flex-col gap-20">
                <div class="offer-details-section flex-col gap-10">
                    <div class="flex-row items-baseline">
                        <span>Job ID</span>
                        <span data-qa-id="ac_client_od_jobid_readonly">
                            {{offer.jobId}}
                        </span>
                    </div>
                    <div class="flex-row items-baseline">
                        <span>Offer Status</span>
                        <span data-qa-id="ac_client_od_offerstatus_readonly">
                            {{offer.offerStatus}}
                        </span>
                    </div>
                    <div class="flex-row items-baseline">
                        <span>Contract Type</span>
                        <span data-qa-id="ac_client_od_contracttype_readonly">
                            {{offer.contractType}}
                        </span>
                    </div>
                    <div class="flex-row items-baseline">
                        <span>Vendor</span>
                        <span data-qa-id="ac_client_od_vendor_readonly">
                            {{offer.agencyName}}
                        </span>
                    </div>
                    <div class="flex-row items-baseline">
                        <span>Vendor Contact</span>
                        <span data-qa-id="ac_client_od_vendorcontact_readonly">
                            {{offer.vendorContactFirstName}} {{offer.vendorContactLastName}}
                        </span>
                    </div>
                    <div class="flex-row items-baseline">
                        <span>Vendor Contact Email</span>
                        <span data-qa-id="ac_client_od_vendorcontactemail_readonly">
                            {{offer.vendorContactEmail}}
                        </span>
                    </div>
                </div>

                <div class="offer-details-section flex-col gap-10">
                    <div class="flex-row items-baseline">
                        <span>Facility</span>
                        <span data-qa-id="ac_client_od_facility_readonly">
                            {{offer.facility}}
                        </span>
                    </div>
                    <div class="flex-row items-baseline">
                        <span>Job Location</span>
                        <span data-qa-id="ac_client_od_joblocation_readonly">
                            {{offer.facilityAddress}}<br>
                            {{offer.facilityCity}}, {{offer.facilityState}} {{offer.facilityZipCode}}
                        </span>
                    </div>
                </div>

                <div class="offer-details-section flex-col gap-10">
                    <div class="flex-row items-baseline">
                        <span>Profession</span>
                        <span data-qa-id="ac_client_od_profession_readonly">
                            {{offer.profession}}
                        </span>
                    </div>
                    <div class="flex-row items-baseline">
                        <span>Specialty</span>
                        <span data-qa-id="ac_client_od_specialty_readonly">
                            {{offer.specialty}}
                        </span>
                    </div>
                </div>

                <div *ngIf="canViewBillRate && !inEditMode" class="offer-details-section flex-col gap-10">
                    <div class="flex-row items-baseline">
                        <span>Bill Rate</span>
                        <span data-qa-id="ac_client_od_billrate_readonly">
                            {{offer.facilityRate | currency}}
                        </span>
                    </div>
                </div>

                <div class="offer-details-section flex-col gap-10">
                    <ng-container *ngIf="(standaloneVmsEditOfferFlag$ | async) && inEditMode else offerInViewMode">
                        <form [formGroup]="offerForm" class="offer-details-form flex-col gap-10">
                            <div *ngIf="canEditBillRate" class="flex-row items-baseline">
                                <mat-label>Bill Rate</mat-label>
                                <div class="flex-col">
                                    <kendo-numerictextbox
                                        formControlName="billRate"
                                        [autoCorrect]="true"
                                        [min]="0"
                                        [max]="10000"
                                        format="n2"
                                        decimals="2"
                                        [spinners]="true"
                                        data-qa-id="ac_client_od_billrate">
                                    </kendo-numerictextbox>
                                    <validation-message
                                        [control]="offerForm.get('billRate')"
                                        data-qa-id="ac_client_od_billrate_error"
                                        label="Bill Rate"
                                        orientation="bottom"
                                        customMessage="Bill Rate must be between $0 and $10,000"
                                        [noHover]="true">
                                    </validation-message>
                                </div>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>Start Date</mat-label>
                                <datepicker-multiple formControlName="startDate" [multi]="false" [min]="minStartDate"
                                    [validateField]="true" data-qa-id="ac_client_od_startdate">
                                </datepicker-multiple>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>End Date</mat-label>
                                <datepicker-multiple formControlName="endDate" [multi]="false" [min]="minEndDate"
                                    [validateField]="true" data-qa-id="ac_client_od_enddate">
                                </datepicker-multiple>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>Weeks Length</mat-label>
                                <div class="flex-col">
                                    <kendo-numerictextbox
                                        formControlName="weeksLength"
                                        [autoCorrect]="true"
                                        [min]="0"
                                        format="n0"
                                        decimals="0"
                                        [spinners]="true"
                                        (valueChange)="calculateContractDates('weeksLengthChanged')"
                                        data-qa-id="ac_client_od_weekslength">
                                    </kendo-numerictextbox>
                                    <validation-message
                                        [control]="offerForm.get('weeksLength')"
                                        data-qa-id="ac_client_od_weekslength_error"
                                        label="Weeks Length"
                                        orientation="bottom"
                                        [noHover]="true">
                                    </validation-message>
                                </div>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>Days Length</mat-label>
                                <div class="flex-col">
                                    <kendo-numerictextbox
                                        formControlName="daysLength"
                                        [autoCorrect]="true"
                                        [min]="1"
                                        format="n0"
                                        decimals="0"
                                        [spinners]="true"
                                        (valueChange)="calculateContractDates('daysLengthChanged')"
                                        data-qa-id="ac_client_od_dayslength">
                                    </kendo-numerictextbox>
                                    <validation-message [control]="offerForm.get('daysLength')"
                                        data-qa-id="ac_client_od_dayslength_error"
                                        label="Days Length"
                                        orientation="bottom"
                                        [noHover]="true">
                                    </validation-message>
                                </div>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>Shift Type</mat-label>
                                <kendo-dropdownlist [data]="shiftTypeOptions" [valuePrimitive]="true"
                                    data-qa-id="ac_client_od_shiftType" valueField="id" textField="name"
                                    formControlName="shiftTypeId">
                                </kendo-dropdownlist>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>Number of Shifts</mat-label>
                                <div class="flex-row gap-10">
                                    <span [class.flex-100]="!showAlternatingFields" [class.flex-50]="showAlternatingFields">
                                        <kendo-dropdownlist class="self-start"
                                            data-qa-id="ac_client_od_shifts" [data]="hoursDropdownOptions"
                                            formControlName="shifts">
                                        </kendo-dropdownlist>
                                    </span>
                                    <div *ngIf="showAlternatingFields" class="flex-50">
                                        <div class="flex-col">
                                            <kendo-dropdownlist
                                                class="self-start"
                                                data-qa-id="ac_client_od_shiftsAlt"
                                                [data]="hoursDropdownOptions"
                                                formControlName="shiftsAlt">
                                            </kendo-dropdownlist>
                                            <validation-message [control]="offerForm.get('shiftsAlt')"
                                                data-qa-id="ac_client_od_shiftsAlt_error"
                                                label="Alt Shifts"
                                                orientation="bottom"
                                                [noHover]="true">
                                            </validation-message>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>Shift Length</mat-label>
                                <div class="flex-row gap-10">
                                    <span [class.flex-100]="!showAlternatingFields" [class.flex-50]="showAlternatingFields">
                                        <kendo-dropdownlist class="self-start"
                                            [data]="shiftLengthOptions" [valuePrimitive]="true"
                                            data-qa-id="ac_client_od_shiftLength" valueField="id" textField="name"
                                            formControlName="hours">
                                        </kendo-dropdownlist>
                                    </span>
                                    <div *ngIf="showAlternatingFields" class="flex-50">
                                        <div class="flex-col">
                                            <kendo-dropdownlist
                                                class="self-start"
                                                [data]="shiftLengthOptions"
                                                [valuePrimitive]="true"
                                                data-qa-id="ac_client_od_shiftAltLength"
                                                valueField="id"
                                                textField="name"
                                                formControlName="hoursAlt">
                                            </kendo-dropdownlist>
                                            <validation-message [control]="offerForm.get('hoursAlt')"
                                                data-qa-id="ac_client_od_shiftAltLength_error"
                                                label="Alt Shift Hours"
                                                orientation="bottom"
                                                [noHover]="true">
                                            </validation-message>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>Shift Time of Day</mat-label>
                                <div class="flex-row gap-10">
                                    <div [class.flex-50]="showAlternatingFields || showRotatingFields">
                                        {{ shiftTimeOfDayText }}
                                    </div>
                                    <div *ngIf="showAlternatingFields || showRotatingFields" class="flex-50">
                                        {{ shiftAltTimeOfDayText }}
                                    </div>
                                </div>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>Shift Time Start</mat-label>
                                <div class="flex-row gap-10">
                                    <span [class.flex-100]="!showAlternatingFields && !showRotatingFields"
                                        [class.flex-50]="showAlternatingFields || showRotatingFields">
                                        <div class="flex-col">
                                            <kendo-timepicker
                                                formControlName="shiftTimeStart"
                                                data-qa-id="ac_client_od_shiftTimeStart"
                                                [nowButton]="false"
                                                [steps]="timeSteps"
                                                format="HH:mm"
                                                formatPlaceholder="short">
                                            </kendo-timepicker>
                                            <validation-message
                                                [control]="offerForm.get('shiftTimeStart')"
                                                data-qa-id="ac_client_od_shiftTimeStart_error"
                                                label="Shift Time Start"
                                                orientation="bottom"
                                                [noHover]="true">
                                            </validation-message>
                                        </div>
                                    </span>
                                    <div *ngIf="showAlternatingFields || showRotatingFields" class="flex-50">
                                        <div class="flex-col">
                                            <kendo-timepicker
                                                formControlName="shiftAltTimeStart"
                                                data-qa-id="ac_client_od_shiftAltTimeStart"
                                                [nowButton]="false"
                                                [steps]="timeSteps"
                                                format="HH:mm"
                                                formatPlaceholder="short">
                                            </kendo-timepicker>
                                            <validation-message
                                                [control]="offerForm.get('shiftAltTimeStart')"
                                                data-qa-id="ac_client_od_shiftAltTimeStart_error"
                                                label="Alt Shift Time Start"
                                                orientation="bottom"
                                                [noHover]="true">
                                            </validation-message>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>Shift Time End</mat-label>
                                <div class="flex-row gap-10">
                                    <span [class.flex-100]="!showAlternatingFields && !showRotatingFields"
                                        [class.flex-50]="showAlternatingFields || showRotatingFields">
                                        <div class="flex-col">
                                            <kendo-timepicker
                                                formControlName="shiftTimeEnd"
                                                data-qa-id="ac_client_os_shiftTimeEnd"
                                                [nowButton]="false"
                                                [steps]="timeSteps"
                                                format="HH:mm"
                                                formatPlaceholder="short">
                                            </kendo-timepicker>
                                            <validation-message
                                                [control]="offerForm.get('shiftTimeEnd')"
                                                data-qa-id="ac_client_os_shiftTimeEnd_error"
                                                label="Shift Time End"
                                                orientation="bottom"
                                                [noHover]="true">
                                            </validation-message>
                                        </div>
                                    </span>
                                    <div *ngIf="showAlternatingFields || showRotatingFields" class="flex-50">
                                        <div class="flex-col">
                                            <kendo-timepicker
                                                formControlName="shiftAltTimeEnd"
                                                data-qa-id="ac_client_od_shiftAltTimeEnd"
                                                [nowButton]="false"
                                                [steps]="timeSteps"
                                                format="HH:mm"
                                                formatPlaceholder="short">
                                            </kendo-timepicker>
                                            <validation-message
                                                [control]="offerForm.get('shiftAltTimeEnd')"
                                                data-qa-id="ac_client_od_shiftAltTimeEnd_error"
                                                label="Alt Shift Time End"
                                                orientation="bottom"
                                                [noHover]="true">
                                            </validation-message>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>RTO</mat-label>
                                <mat-checkbox formControlName="isRtoApproved"
                                    data-qa-id="ac_client_od_rto_approval">
                                </mat-checkbox>
                            </div>

                            <div class="flex-row items-baseline">
                                <mat-label>RTO Dates</mat-label>
                                <div class="flex-col">
                                    <datepicker-multiple class="rto-dates" formControlName="rtoDates" [multi]="true"
                                        [format]="rtoDatesFormat" data-qa-id="ac_client_od_rtodates">
                                    </datepicker-multiple>
                                    <validation-message [control]="offerForm.get('isRtoApproved')"
                                        data-qa-id="ac_client_od_rto_dates_error"
                                        label="RTO Dates"
                                        orientation="bottom"
                                        [noHover]="true">
                                    </validation-message>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                    <ng-template #offerInViewMode>
                        <div class="flex-row items-baseline">
                            <span>Start Date</span>
                            <span data-qa-id="ac_client_od_startdate_readonly">
                                {{offer.startDate | date:'MM/dd/YYYY'}}
                            </span>
                        </div>
                        <div class="flex-row items-baseline">
                            <span>End Date</span>
                            <span data-qa-id="ac_client_od_enddate_readonly">
                                {{offer.endDate | date:'MM/dd/YYYY'}}
                            </span>
                        </div>
                        <div class="flex-row items-baseline">
                            <span>Weeks Length</span>
                            <span data-qa-id="ac_client_od_weekslength_readonly">
                                {{weeksLength}} weeks
                            </span>
                        </div>
                        <div class="flex-row items-baseline">
                            <span>Days Length</span>
                            <span data-qa-id="ac_client_od_dayslength_readonly">
                                {{daysLength}} days
                            </span>
                        </div>
                        <div class="flex-row items-baseline">
                            <span>Shifts</span>
                            <span data-qa-id="ac_client_od_shifts_readonly">
                                {{offer.shiftTextFormatted}}
                            </span>
                        </div>
                        <div class="flex-row items-baseline">
                            <span>RTO</span>
                            <span data-qa-id="ac_client_od_rto_readonly">
                                {{offer.requestedTimeOffIsApproved ? 'Approved': 'Not Approved'}}
                            </span>
                        </div>
                        <div class="flex-row items-baseline">
                            <span>RTO Dates</span>
                            <span data-qa-id="ac_client_od_rtodates_readonly">
                                {{offer.rtoDatesFormatted}}
                            </span>
                        </div>
                    </ng-template>
                </div>

                <div class="offer-details-section flex-col gap-10">
                    <div class="flex-row items-baseline">
                        <span>Other Details</span>
                        <span data-qa-id="ac_client_od_otherdetails_readonly">
                            {{offer.vendorNote}}
                        </span>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <ayac-submittal-offer-notes *ngIf="standaloneVmsOfferNotesFlag$ | async"
        [offer]="offer"
        [disableAddComment]="inEditMode"
        [facilityDirectApplyEnabled]="facilityDirectApplyEnabled">
    </ayac-submittal-offer-notes>
</div>
