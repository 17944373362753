import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { of, throttleTime } from 'rxjs';
import { ToasterService } from 'src/app/core/services';
import * as WocActions from '../actions/work-order-confirmation.actions';
import { TravelersService } from '../../services/travelers.service';
import { Store } from '@ngrx/store';
import * as TravelerDetailsActions from '../actions/travelers-details.actions';

@Injectable()
export class WorkOrderConfirmationEffect {
    constructor(
        private actions$: Actions,
        private travelersService$: TravelersService,
        private toaster: ToasterService,
        private store$: Store<{}>,
        private router: Router
    ) {}

    getWocDetails = createEffect(() =>
        this.actions$.pipe(
            ofType(WocActions.loadWocDetails),
            switchMap((action) =>
                of(
                    TravelerDetailsActions.loadTraveler({ id: action.id }),
                    WocActions.loadWorkOrderConfirmationPDF({ id: action.id })
                )
            )
        )
    );

    loadWocPDF$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WocActions.loadWorkOrderConfirmationPDF),
            switchMap((action) =>
                this.travelersService$.getWocPdf(action.id).pipe(
                    map((image) =>
                        WocActions.workOrderConfirmationPDFSuccess({
                            image: image
                        })
                    ),
                    catchError((error) => of(WocActions.workOrderConfirmationPDFError({ error: error })))
                )
            )
        )
    );

    loadWocPDFFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(WocActions.workOrderConfirmationPDFError),
                map((action) => action.error),
                tap(() => this.toaster.fail('Unable to load work order confirmation.'))
            );
        },
        { dispatch: false }
    );

    postWocAgreement = createEffect(() => this.actions$.pipe(
        ofType(WocActions.postAgreeWoc),
        throttleTime(1000),
        switchMap(action => this.travelersService$.putWocChoice(action.contractId)
        .pipe(
            map(WocActions.postAgreeWocSuccess),
            tap(() => this.router.navigate(['/client', 'workers', action.contractId ])),
            catchError(error => of(WocActions.postAgreeWocError({ error: error })))
        ))
    ));

}
