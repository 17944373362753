import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { BackgroundHistory } from 'src/app/shared/models/candidate';

@Component({
    selector: 'ayac-background-history-form',
    templateUrl: './background-history-form.component.html',
    styleUrls: ['./background-history-form.component.scss']
})
export class BackgroundHistoryFormComponent extends UnsubscribeOnDestroy implements OnInit {
    formArray: UntypedFormArray;
    @Input() formGroupName = 'backgroundHistory';
    @Input() candidateId?: number;
    @Input() isAdminForm = false;
    @Output() onChangeMade = new EventEmitter();
    dataPopulated = false;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly formGroupDirective: FormGroupDirective
    ) {
        super();
        this.formArray = this.formBuilder.array([]);
    }

    ngOnInit(): void {
        this.formGroupDirective.form.addControl(this.formGroupName, this.formArray);
        this.formArray.setParent(this.formGroupDirective.form);
        this.formArray.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => {
            if (!this.dataPopulated) {
                return;
            }

            this.onChangeMade.emit();
        });
    }

    addBackgroundHistory(backgroundHistory?: BackgroundHistory): void {
        const form = this.formBuilder.group({
            candidateId: this.formBuilder.control(backgroundHistory?.vendorCandidateId),
            questionId: this.formBuilder.control(backgroundHistory?.backgroundHistoryQuestionId),
            answer: this.formBuilder.control(backgroundHistory?.answer, this.isAdminForm ? [] : Validators.required),
            details: this.formBuilder.control(backgroundHistory?.details),
            type: this.formBuilder.control(backgroundHistory?.backgroundHistoryQuestion?.typeId),
            text: this.formBuilder.control(backgroundHistory?.backgroundHistoryQuestion?.text),
            active: this.formBuilder.control(backgroundHistory?.backgroundHistoryQuestion?.active)
        });

        this.formArray.push(form);
    }

    populate(backgroundHistoryList: Array<BackgroundHistory>): void {
        this.formArray.clear();

        backgroundHistoryList?.forEach((backgroundHistory) => {
            this.addBackgroundHistory(backgroundHistory);
        });

        this.dataPopulated = true;
    }

    getBackgroundHistory(): Array<BackgroundHistory> {
        const result = new Array<BackgroundHistory>();

        this.formArray.controls.forEach((form: UntypedFormGroup) => {
            const backgroundHistory: BackgroundHistory = {
                vendorCandidateId: form.get('candidateId').value,
                backgroundHistoryQuestionId: form.get('questionId').value,
                answer: form.get('answer').value,
                details: form.get('details').value,
                backgroundHistoryQuestion: {
                    id: form.get('questionId').value,
                    typeId: form.get('type').value,
                    text: form.get('text').value,
                    active: form.get('active').value
                }
            };

            result.push(backgroundHistory);
        });

        return result;
    }
}
