import { createAction, props } from '@ngrx/store';
import { ImportTravelerFileResult } from '../../models/import-traveler-file-result';

export enum ImportTravelersCustomFieldsEnum {
    Import = '[TRAVELERS IMPORT CUSTOM FIELDS] Importing Custom Fields',
    ImportSuccess = '[TRAVELERS IMPORT CUSTOM FIELDS] Import Custom Fields Success',
    ImportFail = '[TRAVELERS IMPORT CUSTOM FIELDS] Import Custom Fields Fail',
    ImportReset = '[TRAVELERS IMPORT CUSTOM FIELDS] Import Custom Fields Reset'
}

export const importTravelersCustomFields = createAction(
    ImportTravelersCustomFieldsEnum.Import,
    props<{ fileToUpload: any }>()
);

export const importTravelersCustomFieldsSuccess = createAction(ImportTravelersCustomFieldsEnum.ImportSuccess);

export const importTravelersCustomFieldsFail = createAction(
    ImportTravelersCustomFieldsEnum.ImportFail,
    props<{ fileName: string; lines: ImportTravelerFileResult[] }>()
);

export const importTravelersCustomFieldsReset = createAction(ImportTravelersCustomFieldsEnum.ImportReset);
