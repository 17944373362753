import { ConnexusFeatureFlags } from './connexus-feature-flags.enum';
import { StarBucksFeatureFlags } from './starbucks-feature-flags.enum';
import { AyaWannaConnectFeatureFlags } from './aya-wanna-connect-feature-flags.enum';
import { ALatteFunFeatureFlags } from './a-latte-fun-feature-flags';
import { AyaKiddingMeFeatureFlags } from './aya-kidding-me-feature-flags.enum';
import { AMIRightFeatureFlags } from './am-i-right-feature-flags';
import { ProgramManagementFeatureFlags } from './program-management-feature-flags';
import { SyncOrSwimFlags } from './sync-or-swim-flags.enum';
import { QuesoFormaggioFeatureFlags } from './queso-formaggio-feature-flags.enum';
import { XAlchemistsFeatureFlags } from 'src/app/shared/models/enums/x-alchemists-feature-flags';
import { ShiftsGettingRealFeatureFlags } from 'src/app/shared/models/enums/shifts-getting-real-feature-flags.enum';
import { AccountOperationsFeatureFlags } from './account-operations-feature-flags';
import { ItsOver9000FeatureFlags } from './its-over-9000-feature-flags';
import { HWFeatureFlags } from './hw-feature-flags.enum';
import { SuperSonicFeatureFlags } from './super-sonic-feature-flags';

export enum FeatureFlags {
    // Note: leave a trailing comma,
    // it will help avoid conflicts in merge
    FeatureA = 'FeatureA',
    FeatureB = 'FeatureB',
    AddIRPRecordType = 'ADD_IRP_RECORD_TYPE',
    UpdateSelectAllForGetShifts = '20220711_SHIFTS-1970_UPDATE_SELECTALL_GETSHIFTS',
    OrientationDetailsFix = '20220808_CNW-11933_ORIENTAION_DETAILS_FIX',
    DevOpsFlag = 'DEVOPS_FLAG',
    ShowIsOnHoldFieldSubmittalsGrid = 'SHOW_IS_ON_HOLD_FIELD_SUBMITTALS_GRID',
    UseCoreEndpointsForClinicalClientsGrid = 'USE_CORE_ENDPOINTS_FOR_CLINICAL_CLIENTS_GRID',
    EnableAccessToVendorToShiftsApp = 'ENABLE_ACCESS_TO_VENDOR_TO_SHIFTS_APP',
    RefreshCustomFieldsOnFacilityChange = 'REFRESH_CUSTOM_FIELDS_ON_FACILITY_CHANGE',
    EnableJobTemplateCustomFields = 'ENABLE_JOB_TEMPLATE_CUSTOM_FIELDS',
    EnableJobTemplateCustomFieldsSync = 'ENABLE_JOB_TEMPLATE_CUSTOM_FIELDS_SYNC',
    EnablePerDiemWorkerCustomFields = 'ENABLE_PER_DIEM_WORKER_CUSTOM_FIELDS',
    WfdHideUnitDescriptionRestrictedFields = 'WFD-2486_CONNECT_HIDE_UNIT_DESCRIPTION_RESTRICTED_FIELDS',
    AccessSecureUDLinks = 'ACCESS_SECURE_UD_LINKS',
    AccessSecureUdLinksByFacilities = 'access-secure-ud-links',
    MailtoIconHotfix = 'mailto-icon-hotfix',
    PreventSavingShiftSeriestInOverlapSituation = 'PREVENT_SAVING_SHIFT_SERIES_IN_OVERLAP_SITUATION',
    ShiftsScheduleCalendarGridLarger = 'connect-shifts-schedule-calendar-grid-larger',
    EnableDashboardTodoTileConnectExpenses = 'ENABLE-DASHBOARD-TODO-TILE-CONNECT-EXPENSES',
    ClientConnectVmsDocDueDate = 'client-connect-vms-doc-due-date',
    EnableExpenseFileAttachmentPreview = 'ENABLE_EXPENSE_FILE_ATTACHMENT_PREVIEW',
    AddingClientInternalIdFieldToLocalPoolPage = 'adding-client-internal-id-field-to-local-pool-page',
    VendorExpenseUseMiscellaneousDate = 'Vendor_Expense_Use_Miscellaneous_Date', // DOC-4237 Jira ticket to remove feature flag
    VendorSubmitAndAddAnotherExpense = 'vendor-submit-and-add-another-expense',
    VendorExpenseUseRentalCarPickupDate = 'Vendor_Expense_Use_RentalCar_Pickup_Date', //DOC-4238 feature flag removal ticket
    VmsEmitExpenseDateChanged = 'vms-emit-expense-date-changed',
    VmsRentalCarExpenseEditTotalDays = 'vms-rental-car-expense-edit-total-days',
    ValidateTimecardAttachmentUponUpload = 'validate-timecard-attachment-upon-upload',
    UseApproveSingleTimecardAPI = 'lotusone-approve-single-timecard',
    EnableCandidateRemovalBefore4HourWindow = 'enable-candidate-removal-before-4-hour-window'
}

export const FeatureFlag = {
    ...FeatureFlags,
    ...ConnexusFeatureFlags,
    ...StarBucksFeatureFlags,
    ...AyaWannaConnectFeatureFlags,
    ...ALatteFunFeatureFlags,
    ...AyaKiddingMeFeatureFlags,
    ...ItsOver9000FeatureFlags,
    ...AMIRightFeatureFlags,
    ...ProgramManagementFeatureFlags,
    ...SyncOrSwimFlags,
    //...SvtFeatureFlags,
    ...QuesoFormaggioFeatureFlags,
    ...ShiftsGettingRealFeatureFlags,
    ...XAlchemistsFeatureFlags,
    ...AccountOperationsFeatureFlags,
    ...HWFeatureFlags,
    ...SuperSonicFeatureFlags
};

export type FeatureFlag =
    | FeatureFlags
    | ConnexusFeatureFlags
    | StarBucksFeatureFlags
    | AyaWannaConnectFeatureFlags
    | ALatteFunFeatureFlags
    | AyaKiddingMeFeatureFlags
    | ItsOver9000FeatureFlags
    | AMIRightFeatureFlags
    | ProgramManagementFeatureFlags
    | SyncOrSwimFlags
    //| SvtFeatureFlags
    | QuesoFormaggioFeatureFlags
    | ShiftsGettingRealFeatureFlags
    | XAlchemistsFeatureFlags
    | AccountOperationsFeatureFlags
    | SuperSonicFeatureFlags
    | HWFeatureFlags;
