<mat-card>
    <mat-card-header>
        <mat-card-title>Documents</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="content">

        <ayac-file-attachments
            *ngIf="edit"
            class="attachments"
            [id]="resourceId"
            [attachmentConfig]="attachmentConfig"
            [loading]="loading$ | async"
            [attachments]="attachments$ | async"
            (attachmentUpload)="attachmentToUpload($event)"
            (attachmentDelete)="onDelete($event)"
            (getAttachmentFile)="onDownload($event)"
            (getAttachmentPreviewFile)="attachmentToPreview($event)"
            (updateAttachmentFile)="attachmentToUpdate($event)"
            (getAttachmentPreviewFile)="attachmentToPreview($event)">
        </ayac-file-attachments>

        <div *ngIf="!edit">
            <ayac-file-attachments-list
                [hiddenTitle]="true"
                [attachments]="attachments$ | async"
                (preview)="attachmentToPreview($event)">
            </ayac-file-attachments-list>
        </div>

        <ayac-file-attachments-preview
            [file]="currentFile$ | async"
            [preview]="preview$ | async"
            (pageChange)="onPageChange($event)"
            (download)="onDownload($event)"
            (close)="onClose()">
        </ayac-file-attachments-preview>

    </mat-card-content>
</mat-card>
