import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { getEmptyPagingToken, PagingToken } from 'src/app/shared/models/paging-token';
import { submittalsVendorActions as actions } from 'src/app/submittals-vendor/store/actions';
import { ListItem } from 'src/app/shared/models/list-item';
import {
    CandidateInfo,
    SubmittalProfessionSpecialties,
    VendorSubmittalDetails
} from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';
import { VendorJobDetails } from 'src/app/shared/models/jobs';
import { SubmittalVendorListItem } from 'src/app/shared/models/submittals-vendor/submittal-vendor-list-item.model';
import { SubmittalActivity } from 'src/app/submittals/models';

export const subittalsVendorFeatureKey = 'submittals-vendor';

export const initialGridState = (): GridSearchQuery => ({
    sort: [{ field: 'submitted', dir: SortTypes.DESC }],
    take: 10,
    skip: 0,
    filter: {
        logic: 'and',
        filters: [{ field: 'statusId', operator: 'eq', value: [] }]
    }
});

export interface SubmittalsVendorState {
    loading: LoadingTypes;
    error: any;
    searchQuery: GridSearchQuery;
    submittals: PagingToken<any[]>;
    agents: ListItem[];
    details: VendorSubmittalDetails;
    stages: ListItem[];
    stageReasons: ListItem[];
    professionSpecialties: SubmittalProfessionSpecialties[];
    vendorJob: VendorJobDetails;
    candidateInfo: CandidateInfo;
    hasChanges: boolean;
    loadingSave: LoadingTypes;
    createdSubmittalId: number | null;
    lookups: SubmittalVendorLookupsState;
    loadingVendorJob: LoadingTypes;
    loadingCandidateInfo: LoadingTypes;
    loadingActivities: LoadingTypes;
    activities: SubmittalActivity[];
    sortActivitiesBy: string;
    // This can be deleted when removing feature flags
    communicationEnabled: boolean;
}

export interface SubmittalVendorLookupsState {
    statuses: ListItem[];
    subStatuses: ListItem[];
}

export const initialState: SubmittalsVendorState = {
    loading: LoadingTypes.INIT,
    error: '',
    searchQuery: initialGridState(),
    submittals: getEmptyPagingToken<SubmittalVendorListItem[]>(),
    agents: [],
    details: null,
    stages: [],
    stageReasons: [],
    professionSpecialties: [],
    vendorJob: null,
    candidateInfo: null,
    hasChanges: false,
    loadingSave: LoadingTypes.INIT,
    createdSubmittalId: null,
    lookups: {
        statuses: [],
        subStatuses: []
    },
    loadingVendorJob: LoadingTypes.INIT,
    loadingCandidateInfo: LoadingTypes.INIT,
    loadingActivities: LoadingTypes.INIT,
    activities: [],
    sortActivitiesBy: 'desc',
    communicationEnabled: false
};

const submittalsVendorReducer = createReducer(
    initialState,
    on(actions.SubmittalsVendorActions.loadSubmittals, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING,
        submittals: getEmptyPagingToken()
    })),

    on(actions.SubmittalsVendorActions.loadSubmittalsSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        submittals: action.submittals
    })),

    on(actions.SubmittalsVendorActions.loadSubmittalsFail, (state, action) => ({
        ...state,
        loading: LoadingTypes.FAIL,
        submittals: getEmptyPagingToken(),
        error: action.error
    })),

    on(
        actions.SubmittalsVendorActions.loadVendorContacts,
        actions.SubmittalsVendorDetailsActions.loadSubmittalsDetails,
        actions.SubmittalsVendorDetailsActions.updateSubmittalNote,
        actions.SubmittalsVendorDetailsActions.updateStageHistoryEntry,
        actions.SubmittalsVendorDetailsActions.getCandidateProfessionSpecialties,
        actions.SubmittalsVendorLookupsActions.loadSubmittalStageReasons,
        actions.SubmittalsVendorLookupsActions.loadSubmittalStages,
        (state, _) => ({
            ...state,
            loading: LoadingTypes.LOADING,
            hasChanges: false,
            isSaving: false
        })
    ),

    on(actions.SubmittalsVendorDetailsActions.loadVendorJob, (state, _) => ({
        ...state,
        loadingVendorJob: LoadingTypes.LOADING,
        hasChanges: false,
        isSaving: false
    })),

    on(actions.SubmittalsVendorDetailsActions.loadCandidateInfo, (state, _) => ({
        ...state,
        loadingCandidateInfo: LoadingTypes.LOADING,
        hasChanges: false,
        isSaving: false
    })),

    on(actions.SubmittalsVendorDetailsActions.loadSubmittalsDetails, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),

    on(
        actions.SubmittalsVendorActions.loadVendorContactsFail,
        actions.SubmittalsVendorDetailsActions.loadSubmittalsDetailsFail,
        actions.SubmittalsVendorDetailsActions.updateSubmittalNoteFailure,
        actions.SubmittalsVendorDetailsActions.updateStageHistoryEntryFailure,
        actions.SubmittalsVendorDetailsActions.getCandidateProfessionSpecialtiesFail,
        actions.SubmittalsVendorLookupsActions.loadSubmittalStageReasonsFail,
        actions.SubmittalsVendorLookupsActions.loadSubmittalStagesFail,
        (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            error: action.error
        })
    ),

    on(actions.SubmittalsVendorDetailsActions.loadCandidateInfoFail, (state, action) => ({
        ...state,
        loadingCandidateInfo: LoadingTypes.FAIL,
        error: action.error
    })),

    on(actions.SubmittalsVendorDetailsActions.loadVendorJobFail, (state, action) => ({
        ...state,
        loadingVendorJob: LoadingTypes.FAIL,
        error: action.error
    })),

    on(actions.SubmittalsVendorActions.loadVendorContactsSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        agents: action.agents
    })),

    on(actions.SubmittalsVendorDetailsActions.loadSubmittalsDetailsSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        details: action.details,
        hasChanges: false
    })),

    on(actions.SubmittalsVendorDetailsActions.createNewSubmittal, (state, action) => ({
        ...state,
        loadingSave: LoadingTypes.LOADING
    })),

    on(actions.SubmittalsVendorDetailsActions.createNewSubmittalSuccess, (state, action) => ({
        ...state,
        loadingSave: LoadingTypes.LOADED,
        createdSubmittalId: action.submittalId
    })),

    on(actions.SubmittalsVendorDetailsActions.createNewSubmittalFailure, (state, action) => ({
        ...state,
        loadingSave: LoadingTypes.FAIL,
        createdSubmittalId: null
    })),

    on(actions.SubmittalsVendorActions.clearVendorSubmittalsFilters, (state) => ({
        ...state,
        searchQuery: initialGridState()
    })),

    on(actions.SubmittalsVendorLookupsActions.loadSubmittalStagesSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        stages: action.stages
    })),

    on(actions.SubmittalsVendorLookupsActions.loadSubmittalStageReasonsSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        stageReasons: action.stageReasons
    })),

    on(actions.SubmittalsVendorDetailsActions.getCandidateProfessionSpecialtiesSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        professionSpecialties: action.professionSpecialties
    })),

    on(actions.SubmittalsVendorDetailsActions.loadVendorJobSuccess, (state, action) => ({
        ...state,
        loadingVendorJob: LoadingTypes.LOADED,
        vendorJob: action.jobDetails
    })),

    on(actions.SubmittalsVendorDetailsActions.loadCandidateInfoSuccess, (state, action) => ({
        ...state,
        loadingCandidateInfo: LoadingTypes.LOADED,
        candidateInfo: action.candidateInfo
    })),

    on(actions.SubmittalsVendorDetailsActions.setChangeState, (state, action) => ({
        ...state,
        hasChanges: action.hasChanges
    })),

    on(actions.SubmittalsVendorActions.setSearchQuery, (state, action) => ({
        ...state,
        searchQuery: action.searchQuery,
        loading: LoadingTypes.LOADING
    })),

    on(actions.SubmittalsVendorDetailsActions.createNewSubmittalSuccess, (state, action) => ({
        ...state,
        hasChanges: false
    })),

    on(actions.SubmittalsVendorDetailsActions.clearSubmittalDetails, (state, action) => ({
        ...state,
        ...initialState
    })),

    on(
        actions.SubmittalsVendorActions.loadSubmittalsFail,
        actions.SubmittalsVendorActions.exportAllSubmittalsFail,
        (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            error: action.error
        })
    ),

    on(actions.SubmittalsVendorActions.exportAllSubmittalsSuccess, (state) => ({
        ...state,
        loading: LoadingTypes.LOADED
    })),

    on(actions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsStatuses, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),

    on(actions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsStatusesSuccess, (state, action) => ({
        ...state,
        lookups: {
            statuses: action.statuses,
            subStatuses: state.lookups.subStatuses
        },
        loading: LoadingTypes.LOADED
    })),

    on(actions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsStatusesFail, (state, action) => ({
        ...state,
        loading: LoadingTypes.FAIL,
        error: action.error
    })),

    on(actions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsSubStatuses, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),

    on(actions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsSubStatusesSuccess, (state, action) => ({
        ...state,
        lookups: {
            statuses: state.lookups.statuses,
            subStatuses: action.subStatuses
        },
        loading: LoadingTypes.LOADED
    })),

    on(actions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsSubStatusesFail, (state, action) => ({
        ...state,
        loading: LoadingTypes.FAIL,
        error: action.error
    })),
    on(actions.SubmittalsVendorDetailsActions.loadSubmittalActivities, (state) => ({
        ...state,
        loadingActivities: LoadingTypes.LOADING
    })),
    on(actions.SubmittalsVendorDetailsActions.loadSubmittalActivitiesSuccess, (state, action) => ({
        ...state,
        loadingActivities: LoadingTypes.LOADED,
        activities: action.activities
    })),
    on(actions.SubmittalsVendorDetailsActions.loadSubmittalActivitiesFail, (state, action) => ({
        ...state,
        loadingActivities: LoadingTypes.FAIL,
        error: action.error
    })),
    on(actions.SubmittalsVendorDetailsActions.setActivitiesSortBy, (state, action) => ({
        ...state,
        sortActivitiesBy: action.sortActivitiesBy
    })),
    on(actions.SubmittalsVendorDetailsActions.loadSubmittalCommunicationEnabledSuccess, (state, action) => ({
        ...state,
        communicationEnabled: action.communicationEnabled
    }))
);

export function reducer(state: SubmittalsVendorState | undefined, action: Action) {
    return submittalsVendorReducer(state, action);
}
