import { AbstractControl, ValidationErrors } from '@angular/forms';

export function nameValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }
    const namePattern = new RegExp("^[a-zA-Z]+(?:[ '-][a-zA-Z]+([.]{0,1})*)*$");
    const isValid = namePattern.test(control.value);

    return isValid ? null : { name: { value: control.value } };
}

export function nameValidatorAllowApostrophes(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }
    const namePattern = new RegExp("^[a-zA-Z]+(?:[ '-][a-zA-Z]+([.]{0,1})*)*'?$");
    const isValid = namePattern.test(control.value);

    return isValid ? null : { name: { value: control.value } };
}

export function titleValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }
    const titlePattern = /^[a-zA-Z\d\s-\'"\.+=_#\(\):\*\\|\/&!@]+$/g;
    const isValid = titlePattern.test(control.value);

    return isValid ? null : { title: { value: control.value } };
}
