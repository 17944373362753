import { Action, createReducer, on } from '@ngrx/store';
import {
    AdminVendorCandidatesListState,
    getInitialAdminVendorCandidatesListState
} from '../state/admin.vendor-candidates.state';
import * as actions from 'src/app/admin/store/actions/admin.vendor-candidates-list.actions';
import { LoadingTypes } from 'src/app/shared/models';

export function vendorCandidateListReducer(state: AdminVendorCandidatesListState, action: Action) {
    return createReducer(
        getInitialAdminVendorCandidatesListState(),
        on(actions.loadAdminVendorCandidatesExpertisesSuccess, (state, action) => ({
            ...state,
            expertises: action.expertises
        })),

        on(actions.loadAdminVendorCandidatesExpertiseFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error)
        })),

        on(actions.loadAdminVendorCandidateStatesSuccess, (state, action) => ({
            ...state,
            states: action.states
        })),

        on(actions.loadAdminVendorCandidateStatesFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error)
        })),

        on(actions.loadAdminVendorCandidatesVendorsSuccess, (state, action) => ({
            ...state,
            vendors: action.vendors
        })),

        on(actions.loadAdminVendorCandidatesVendorsFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error)
        })),

        on(actions.loadAdminVendorCandidatesProfessionsSuccess, (state, action) => ({
            ...state,
            professions: action.professions
        })),

        on(actions.loadAdminVendorCandidatesProfessionsFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error)
        })),

        on(actions.loadAdminVendorCandidatesList, (state) => ({
            ...state,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.loadAdminVendorCandidatesListSuccess, (state, action) => ({
            ...state,
            candidates: action.candidates,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.loadAdminVendorCandidatesListFailure, (state) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.setVendorCandidatesListSearchQuery, (state, action) => ({
            ...state,
            gridSearchQuery: action.searchQuery,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.exportAdminVendorCandidatesList, (state) => ({
            ...state,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.exportAdminVendorCandidatesListSuccess, (state) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.exportAdminVendorCandidatesListFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error),
            loadingState: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: AdminVendorCandidatesListState | undefined, action: Action) {
    return vendorCandidateListReducer(state, action);
}
