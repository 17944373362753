import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import {
    loadSecurityLookups,
    loadSecurityLookupsSuccess,
    loadSecurityLookupsFail
} from '../actions/security-lookups.actions';

@Injectable()
export class SecurityLookupsEffect {
    constructor(
        private actions$: Actions,
        private service: DashboardService
    ) {}
    getLookupsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSecurityLookups),
            switchMap((action) => {
                return this.service.getSecurityLookups().pipe(
                    map((response) =>
                        loadSecurityLookupsSuccess({
                            facilities: response.facilities.sort((a, b) =>
                                a.profileGroupName.localeCompare(b.profileGroupName)
                            ),
                            systems: response.systems,
                            units: response.units.sort((a, b) => a.name.localeCompare(b.name))
                        })
                    ),
                    catchError((error) => of(loadSecurityLookupsFail({ error })))
                );
            })
        )
    );
}
