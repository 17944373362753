import { createAction, props } from '@ngrx/store';
import { PendoEvent } from '../../models';

export const initPendo = createAction('[Core] Initialize Pendo');

export const initPendoSuccess = createAction('[Core] Initialize Pendo Success');

export const initPendoFail = createAction('[Core] Initialize Pendo Fail');

export const trackPendo = createAction(
    '[Core] Track Pendo Event',
    props<{
        event: PendoEvent;
    }>()
);
