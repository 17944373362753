<loading-indicator *ngIf="isLoading$ | async"></loading-indicator>

<ayac-widget>
    <div id="client-submittals-page" terminology>
        <portal-grid
            data-title="Submittals"
            [data]="submittals$ | async"
            [query]="submittalsQuery$ | async"
            (dataStateChange)="onDataStateChange($event)"
            (rowSelected)="onRowSelected($event)"
            [selectable]="true"
            class="submittalsGrid portal-grid-scroll-auto"
            selectableMode="multiple">

                <ng-template #toolbar>
                    <button mat-flat-button color="accent" data-qa-id="ac_client_sm_export_button" (click)="export()">
                        <mat-icon>get_app</mat-icon>
                        <span>Export</span>
                        <span *ngIf="selected.length > 0">&nbsp;({{ selected.length }})</span>
                    </button>
                </ng-template>

                <portal-grid-quick-filter
                    filterTooltip="Submitted in the past 72 hours"
                    data-qa-id="client-submittals-grid-new-submittals-filter"
                    field="isRecentlySubmitted"
                    label="New Submittals"
                    filterType="switch">
                </portal-grid-quick-filter>

                <portal-grid-quick-filter
                    filterTooltip="Submittals removed within the past two weeks"
                    data-qa-id="client-submittals-grid-removed-submittals-filter"
                    *ifLdFeature="featureFlags.ConnectClientSubmittalRemovedFromLiveList"
                    field="isRecentlyRemoved"
                    label="Removed Submittals"
                    filterType="switch">
                </portal-grid-quick-filter>

                <portal-grid-quick-filter
                    data-qa-id="client-submittals-grid-change-requested-filter"
                    *ifLdFeature="featureFlags.ConnectStandaloneVMSClientOfferFlow"
                    field="hasOfferWithChangeRequested"
                    label="Change Request"
                    filterType="switch">
                </portal-grid-quick-filter>

                <portal-grid-quick-filter
                    data-qa-id="client-submittals-grid-line-of-business-filter"
                    place="header"
                    field="lineOfBusinessId"
                    filterType="dropdown"
                    defaultToFirstItem="true"
                    [filterOptions]="linesOfBusiness$ | async">
                </portal-grid-quick-filter>

                <ng-container  *ngIf="!isConnectDetailIRPJobSubmittals">
                    <portal-grid-quick-filter
                            data-qa-id="client-submittals-grid-status-filter-non-detail-IRP"
                            place="header"
                            field="submittalStatusId"
                            filterType="multiselect"
                            selectAll="All Statuses"
                            defaultText="Select a status"
                            [defaultToAllSelected]="true"
                            [width]="270"
                            [filterOptions]="statusLookupsInPlacedState$ | async">
                    </portal-grid-quick-filter>

                </ng-container>


                <ng-container *ngIf="isConnectDetailIRPJobSubmittals">
                    <portal-grid-quick-filter
                        data-qa-id="client-submittals-grid-status-filter-detail-IRP"
                        place="header"
                        filterType="groupmultiselect"
                        selectAll="All Statuses"
                        defaultText="Select a status"
                        (filterChanging)="onStatusesChange($event)"
                        [defaultToAllSelected]="true"
                        [width]="375"
                        [filterOptions]="{data: (statusAndSubStatusLookup$ | async)  }">
                    </portal-grid-quick-filter>
                </ng-container>

                <portal-grid-quick-filter
                    data-qa-id="client-submittals-grid-sub-status-filter"
                    place="header"
                    field="submittalSubStatusId"
                    filterType="multiselect"
                    selectAll="All Sub-statuses"
                    defaultText="Select a sub-status"
                    [defaultToAllSelected]="true"
                    [filterOptions]="subStatuses"
                    *ngIf="subStatuses.length">
                </portal-grid-quick-filter>

                <portal-grid-column
                    title="Name"
                    field="travelerName"
                    filterType="text">
                    <ng-template #cell let-dataItem="dataItem">
                        <span class="extension">
                            <ng-container *ifLdFeature="featureFlags.ConnectStandaloneVMSClientOfferFlow; enabled: false">
                                <a *ngIf="!dataItem.isInternalPool"
                                    [routerLink]="['/client', 'submittals', dataItem.id]">
                                    {{dataItem.travelerName}}
                                </a>
                            </ng-container>
                            <ng-container *ifLdFeature="featureFlags.ConnectStandaloneVMSClientOfferFlow; enabled: true">
                                <ng-container *ngIf="!dataItem.isInternalPool">
                                    <a *ngIf="!dataItem.hasOffer"
                                        [routerLink]="['/client', 'submittals', dataItem.id]">
                                        {{dataItem.travelerName}}
                                    </a>
                                    <a *ngIf="dataItem.hasOffer"
                                        [routerLink]="['/client', 'submittals', dataItem.id, submittalRoutes.offerDetails]">
                                        {{dataItem.travelerName}}
                                    </a>
                                </ng-container>
                            </ng-container>
                            <a *ngIf="dataItem.isInternalPool"
                                [routerLink]="['/client', 'submittals', dataItem.id, 'internal']"
                                [queryParams]="{candidateId: dataItem.candidateId}">
                                {{dataItem.travelerName}}
                            </a>
                            <span class="grid-icons">
                                <span *ngIf="dataItem.showNpiWarning" class="mx-2 badge show-npi-warning-badge">NC</span>
                                <span *ngIf="dataItem.isExtensionsJob" class="extension-badge badge-inactive" matTooltip="Extended">E</span>
                                <span *ngIf="dataItem.isInternalPool" class="label-badge" matTooltip="Internal Pool Candidate">IRP</span>
                            </span>
                        </span>
                    </ng-template>
                    <ng-template #detailLink></ng-template>
                </portal-grid-column>

                <portal-grid-column
                    title="Job Title"
                    field="jobTitle"
                    filterType="text">
                </portal-grid-column>

                <portal-grid-column
                    title="Profession"
                    field="profession"
                    filterType="text">
                </portal-grid-column>

                <portal-grid-column
                    title="Specialty"
                    field="expertise"
                    filterType="text">
                </portal-grid-column>

                <portal-grid-column
                    title="Facility"
                    field="searchFacilityIds"
                    filterType="multiselect"
                    defaultText="All facilities"
                    [filterDisabled]="!(isFacilityFilterEnabled$ | async)"
                    [filterOptions]="facilityLookups$ | async"
                    [defaultText]="facilityFilterDefaultText$ | async">
                    <ng-template #cell let-dataItem="dataItem">
                        {{ dataItem.facility }}
                    </ng-template>
                </portal-grid-column>

                <portal-grid-column
                    title="Unit"
                    field="unitId"
                    filterType="combobox"
                    defaultText="All units"
                    [filterOptions]="unitLookupsFiltered$ | async"
                    [filterDisabled]="!(isFacilitySelected$ | async)"
                    [filterTooltip]="unitFilterTooltip$ | async">
                    <ng-template #cell let-dataItem="dataItem">
                        {{ dataItem.unit }}
                    </ng-template>
                </portal-grid-column>

                <portal-grid-column
                    title="Submittal Date"
                    field="submissionSentOn"
                    filterType="date"
                    [disabledKeyboard]="true">
                    <ng-template #cell let-dataItem="dataItem">
                        <span class="submittal-date">
                            <span>{{ dataItem.submissionSentOn | date : 'MM/dd/yyyy' }}</span>
                            <ng-container *ifLdFeature="featureFlags.ConnectStandaloneVMSClientOfferFlow; enabled: false">
                                <mat-icon
                                    *ngIf="dataItem.isPendingOver2Days"
                                    matTooltip="{{'Submittal has been pending review for over 2 days' | terminology}}"
                                    class="pending-submittal">
                                        error
                                </mat-icon>
                            </ng-container>
                        </span>
                    </ng-template>
                </portal-grid-column>

                <portal-grid-column
                    title="Available Date"
                    field="availableOn"
                    filterType="date"
                    [disabledKeyboard]="true">
                    <ng-template #cell let-dataItem="dataItem">
                        {{ dataItem.availableOn | date : 'MM/dd/yyyy' }}
                    </ng-template>
                </portal-grid-column>

                <portal-grid-column
                    title="Status"
                    field="submittalStatusId"
                    filterType="none">
                    <ng-template #cell let-dataItem="dataItem">
                        <ng-container *ifLdFeature="featureFlags.ConnectStandaloneVMSClientOfferFlow; enabled: false">
                            {{ dataItem.isInternalPool ? dataItem.status : dataItem.submittalStatusName }}
                            <span class="sub-status" *ngIf="dataItem.submittalSubStatusName">{{dataItem.submittalSubStatusName}}</span>
                        </ng-container>
                        <ng-container *ifLdFeature="featureFlags.ConnectStandaloneVMSClientOfferFlow; enabled: true">
                            <div class="status">
                                {{ dataItem.isInternalPool ? dataItem.status : dataItem.submittalStatusName }}
                                    <div class="sub-status" *ngIf="dataItem.submittalSubStatusName">{{dataItem.submittalSubStatusName}}</div>
                                    <mat-icon
                                        data-qa-id="client-submittals-grid-changes-requested-icon"
                                        *ngIf="hasOfferInChangeRequestStatus(dataItem)"
                                        matTooltip="Changes Requested">
                                            error
                                    </mat-icon>
                                    <mat-icon
                                        data-qa-id="client-submittals-grid-pending-two-days-icon"
                                        *ngIf="dataItem.isPendingOver2Days"
                                        [matTooltip]="'Submittal has been pending review for over 2 days' | terminology">
                                        error
                                </mat-icon>
                            </div>
                        </ng-container>
                    </ng-template>
                </portal-grid-column>

                <portal-grid-column
                    title="Agency"
                    field="agency"
                    width="150"
                    filterType="text">
                    <ng-template #cell let-dataItem="dataItem">
                        <div>{{ dataItem.isInternalPool
                            ? 'In House Temp'
                            : dataItem.agency }}
                        </div>
                        <div class="diversity-info" *ngIf="dataItem.certifications?.length > 0">
                            <ayac-rich-tooltip
                                [displayTemplate]="diversityInfoTemplate"
                                [data]="dataItem.certifications"
                                width="350">
                                <a class="diversity-tooltip" role="button"><mat-icon>group</mat-icon> Diversity Partner</a>
                            </ayac-rich-tooltip>
                        </div>
                    </ng-template>
                </portal-grid-column>

                <portal-grid-column
                    title="Application"
                    filterType="none"
                    [sortable]="false">
                    <ng-template #cell let-dataItem="dataItem">
                        <button *ngIf="!dataItem.isInternalPool && dataItem.hasAttachment"
                            mat-icon-button color="accent"
                            aria-label="Download application"
                            (click)="onViewApplication(dataItem);"
                            [disabled]="isSubmittalApplicationLoading(dataItem.id) | async">
                            <mat-icon>insert_drive_file</mat-icon>
                        </button>

                        <button *ngIf="dataItem.isInternalPool"
                            mat-icon-button
                            color="accent"
                            aria-label="Download application"
                            (click)="onViewApplication(dataItem);">
                            <mat-icon>insert_drive_file</mat-icon>
                        </button>
                    </ng-template>
                </portal-grid-column>
        </portal-grid>
    </div>

    <ng-template #diversityInfoTemplate let-data="data">
        <ayac-diversity-info-tooltip [certifications]="data">
        </ayac-diversity-info-tooltip>
    </ng-template>
</ayac-widget>
