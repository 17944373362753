export enum ConnexusFeatureFlags {
    RetainSubmittalIdUponSave = 'submittal-retain-id-upon-save',
    UseAyaConnectToCallUnitDescriptionApis = 'UseAyaConnectToCallUnitDescriptionApis',
    SetVendorCandidateNpiFromFormControl = 'set-vendor-candidate-npi-from-form-control',
    AlwaysUseFacilityOrientationHoursForVendorJobDetails = 'always-use-facility-orientation-hours-for-vendor-job-details',
    ClientSubmittalDetailsMoveActivitySection = 'client-submittal-details-move-activity-section',
    AddContractGroupToVendorsJobs = 'add-contract-group-to-vendors-jobs',
    LazyLoadVendorCandidateContractRequirements = 'lazy-load-vendor-contract-requirements',
    VendorClientCommunicationForApnDirectSubmittals = 'enable-vendor-client-communication-for-apn-direct-submittals',
    VendorClientCommunicationForNonVMSVendorSubmittals = 'enable-communication-for-non-vms-vendor-submittals',
    DisplayCAMinimumWage = 'display-ca-minimum-wage',

    // Start SergiiM
    VendorJobDetailsCaMinimumWageType = 'vendor-job-details-ca-minimum-wage-type',
    VendorSubmittalsDetailsMoveStatusToTopRight = 'vendor-submittals-details-move-status-to-top-right',
    UseLocalDatetimeForVendorCandidateDateOfBirth = 'use-local-datetime-for-vendor-candidate-date-of-birth',
    DisableUtcConversionForVendorFields = 'disable-utc-conversion-for-vendor-fields',
    DisableUtcConversionForAdminFields = 'disable-utc-conversion-for-admin-fields',
    // End SergiiM

    // Start Will
    SetSendToClientEmailBodyAsStatusChangeNoteFromLiveList = 'set-send-to-client-email-body-as-status-change-note-from-live-list',
    // End Will

    // Start Bruno
    FacilityAdminQueryImprovement = 'facility-admin-query-improvement'
    //End Bruno
}
