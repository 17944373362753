import { ReleaseGroupEdit } from 'src/app/facilities/models/release-group-edit.model';
import { ProfessionEdit } from 'src/app/facilities/models/profession-edit.model';
import { createAction, props } from '@ngrx/store';
import { ScheduledReleaseRule } from 'src/app/jobs/models/lookups/scheduled-release-rule.lookup';
import { ReleasePermissions } from 'src/app/facilities/models/release-permissions.model';
import { ReleaseGroup } from 'src/app/facilities/models/release-group.model';
import { AcceptedVendor } from 'src/app/facilities/models/accepted-vendor.model';
import { Expertise } from 'src/app/shared/models/shifts/expertise.model';

export enum JobReleaseSettingsActionEnum {
    LoadJobReleaseSettingsPermissions = '[Admin Facility Job Release Settings Component] Job Release Settings Permissions',
    LoadJobReleaseSettingsPermissionsSuccess = '[Admin Facility Job Release Settings API] Load Job Release Groups Permissions Success',
    LoadJobReleaseSettingsPermissionsFailure = '[Admin Facility Job Release Settings API] Load Job Release Groups Permissions Failure',

    LoadJobReleaseGroups = '[Admin Facility Job Release Settings Component] Load Job Release Groups',
    LoadJobReleaseGroupsSuccess = '[Admin Facility Job Release Settings API] Load Job Release Groups Success',
    LoadJobReleaseGroupsFailure = '[Admin Facility Job Release Settings API] Load Job Release Groups Failure',

    SaveJobReleaseGroup = '[Admin Facility Job Release Settings Component] Save Job Release Group',
    SaveJobReleaseGroupSuccess = '[Admin Facility Job Release Settings API] Save Job Release Group Success',
    SaveJobReleaseGroupFailure = '[Admin Facility Job Release Settings API] Save Job Release Group Failure',

    DeleteJobReleaseGroup = '[Admin Facility Job Release Settings Component]] Delete Job Release Group',
    DeleteJobReleaseGroupSuccess = '[Admin Facility Job Release Settings API] Delete Job Release Group Success',
    DeleteJobReleaseGroupFailure = '[Admin Facility Job Release Settings API] Delete Job Release Group Failure',

    LoadJobReleaseGroupVendors = '[Admin Facility Job Release Settings Component] Load Job Release Group Vendors',
    LoadJobReleaseGroupVendorsSuccess = '[Admin Facility Job Release Settings API] Load Job Release Group Vendors Success',
    LoadJobReleaseGroupVendorsFailure = '[Admin Facility Job Release Settings API] Load Job Release Group Vendors Failure',

    // Copy To
    CopyJobReleaseSettingsToFacilities = '[Admin Facility Job Release Settings Component] Copy Job Release Settins To Facilities',
    CopyJobReleaseSettingsToFacilitiesSuccess = '[Admin Facility Job Release Settings API] Copy Job Release Settings To Facilities Success',
    CopyJobReleaseSettingsToFacilitiesFailure = '[Admin Facility Job Release Settings API] Copy Job Release Settings To Facilities Failure',

    CopyScheduledJobReleaseRules = '[Admin Facility Job Release Settings Component] Copy Scheduled Job Release Rules To Facilities',
    CopyScheduledJobReleaseRulesSuccess = '[Admin Facility Job Release Settings API] Copy Scheduled Job Release Rules To Facilities Success',
    CopyScheduledJobReleaseRulesFailure = '[Admin Facility Job Release Settings API] Copy Scheduled Job Release Rules To Facilities Failure',

    CopyJobReleaseSettingsFromFacility = '[Admin Facility Job Release Settings Component] Copy Release Settings From Facility',
    CopyJobReleaseSettingsFromFacilitySuccess = '[Admin Facility Job Release Settings API] Copy Release Settings From Facility Success',
    CopyJobReleaseSettingsFromFacilityFailure = '[Admin Facility Job Release Settings API] Copy Release Settings From Facility Failure',

    LoadScheduledJobReleaseRulesPermissionsSuccess = '[Admin Facility Job Release Settings API] Load Scheduled Job Release Rules Permissions Success',
    LoadScheduledJobReleaseRulesPermissionsFailure = '[Admin Facility Job Release Settings API] Load Scheduled Job Release Rules Permissions Failure',

    LoadScheduledJobReleaseRules = '[Admin Facility Job Release Settings Component] Load Scheduled Job Release Rules',
    LoadScheduledJobReleaseRulesSuccess = '[Admin Facility Job Release Settings API] Load Scheduled Job Release Rules Success',
    LoadScheduledJobReleaseRulesFailure = '[Admin Facility Job Release Settings API] Load Scheduled Job Release Rules Failure',

    CleanScheduledJobReleaseRule = '[Admin Facility Job Release Settings Component] Clean Scheduled Job Release Rule',
    LoadScheduledJobReleaseRule = '[Admin Facility Job Release Settings Component] Load Scheduled Job Release Rule',
    LoadScheduledJobReleaseRuleSuccess = '[Admin Facility Job Release Settings API] Load Scheduled Job Release Rule Success',
    LoadScheduledJobReleaseRuleFailure = '[Admin Facility Job Release Settings API] Load Scheduled Job Release RuleFailure',

    DeleteScheduledJobReleaseRule = '[Admin Facility Job Release Settings Component] Delete Scheduled Job Release Rule',
    DeleteScheduledJobReleaseRuleSuccess = '[Admin Facility Job Release Settings API] Delete Scheduled Job Release Rule Success',
    DeleteScheduledJobReleaseRuleFailure = '[Admin Facility Job Release Settings API] Delete Scheduled Job Release Rule Failure',

    // Schedule Release Rules Edit
    LoadScheduledJobReleaseRuleProfessions = '[Admin Facility Job Release Settings Component] Load Scheduled Job Release Rule Professions',
    LoadScheduledJobReleaseRuleProfessionsSuccess = '[Admin Facility Job Release Settings API] Load Scheduled Job Release Rule Professions Success',
    LoadScheduledJobReleaseRuleProfessionsFailure = '[Admin Facility Job Release Settings API] Load Scheduled Job Release Rule Professions Failure',

    LoadScheduledJobReleaseRuleExpertises = '[Admin Facility Job Release Settings Component] Load Scheduled Release Rule Expertises',
    LoadScheduledJobReleaseRuleExpertisesSuccess = '[Admin Facility Job Release Settings API] Load Scheduled Release Rule Expertises Success',
    LoadScheduledJobReleaseRuleExpertisesFailure = '[Admin Facility Job Release Settings API] Load Scheduled Release Rule Expertises Failure',

    LoadScheduledJobReleaseRuleVendors = '[Admin Facility Job Release Settings Component] Load Scheduled Job Release Rule Vendors',
    LoadScheduledJobReleaseRuleVendorsSuccess = '[Admin Facility Job Release Settings API] Load Scheduled Job Release Rule Vendors Success',
    LoadScheduledJobReleaseRuleVendorsFailure = '[Admin Facility Job Release Settings API] Load Scheduled Job Release Rule Vendors Failure',

    SaveScheduledJobReleaseRule = '[Admin Facility Job Release Settings Component] Save Scheduled Job Release Rule',
    SaveScheduledJobReleaseRuleSuccess = '[Admin Facility Job Release Settings API] Save Scheduled Release Job Rule Success',
    SaveScheduledJobReleaseRuleFailure = '[Admin Facility Job Release Settings API] Save Scheduled Release Job Rule Failure'
}

export const loadJobReleaseSettingsPermissions = createAction(
    JobReleaseSettingsActionEnum.LoadJobReleaseSettingsPermissions,
    props<{ facilityId: number }>()
);
export const loadJobReleaseSettingsPermissionsSuccess = createAction(
    JobReleaseSettingsActionEnum.LoadJobReleaseSettingsPermissionsSuccess,
    props<{ releaseGroupsPermissions: ReleasePermissions }>()
);
export const loadJobReleaseSettingsPermissionsFailure = createAction(
    JobReleaseSettingsActionEnum.LoadJobReleaseSettingsPermissionsFailure,
    props<{ error: any }>()
);

export const loadJobReleaseGroups = createAction(
    JobReleaseSettingsActionEnum.LoadJobReleaseGroups,
    props<{ facilityId: number }>()
);
export const loadJobReleaseGroupsSuccess = createAction(
    JobReleaseSettingsActionEnum.LoadJobReleaseGroupsSuccess,
    props<{ releaseGroups: ReleaseGroup[]; facilityId: number }>()
);
export const loadJobReleaseGroupsFailure = createAction(
    JobReleaseSettingsActionEnum.LoadJobReleaseGroupsFailure,
    props<{ error: any }>()
);

export const deleteJobReleaseGroup = createAction(
    JobReleaseSettingsActionEnum.DeleteJobReleaseGroup,
    props<{ groupId: number; facilityId: number }>()
);
export const deleteJobReleaseGroupSuccess = createAction(
    JobReleaseSettingsActionEnum.DeleteJobReleaseGroupSuccess,
    props<{ facilityId: number }>()
);
export const deleteJobReleaseGroupFailure = createAction(
    JobReleaseSettingsActionEnum.DeleteJobReleaseGroupFailure,
    props<{ error: any }>()
);

export const saveJobReleaseGroup = createAction(
    JobReleaseSettingsActionEnum.SaveJobReleaseGroup,
    props<{ group: ReleaseGroupEdit }>()
);
export const saveJobReleaseGroupSuccess = createAction(
    JobReleaseSettingsActionEnum.SaveJobReleaseGroupSuccess,
    props<{ facilityId: number }>()
);
export const saveJobReleaseGroupFailure = createAction(JobReleaseSettingsActionEnum.SaveJobReleaseGroupFailure);

export const loadScheduledJobReleaseRulesPermissionsSuccess = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRulesPermissionsSuccess,
    props<{ scheduledReleaseRulePermissions: ReleasePermissions }>()
);
export const loadScheduledJobReleaseRulesPermissionsFailure = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRulesPermissionsFailure,
    props<{ error: any }>()
);

export const loadJobReleaseGroupVendors = createAction(
    JobReleaseSettingsActionEnum.LoadJobReleaseGroupVendors,
    props<{ facilityId: number }>()
);
export const loadJobReleaseGroupVendorsSuccess = createAction(
    JobReleaseSettingsActionEnum.LoadJobReleaseGroupVendorsSuccess,
    props<{ vendors: AcceptedVendor[] }>()
);
export const loadJobReleaseGroupVendorsFailure = createAction(
    JobReleaseSettingsActionEnum.LoadJobReleaseGroupVendorsFailure
);

export const loadScheduledJobReleaseRules = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRules,
    props<{ facilityId: number }>()
);
export const loadScheduledJobReleaseRulesSuccess = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRulesSuccess,
    props<{ scheduledReleaseRules: ScheduledReleaseRule[] }>()
);
export const loadScheduledJobReleaseRulesFailure = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRulesFailure,
    props<{ error: any }>()
);

export const cleanScheduledJobReleaseRule = createAction(JobReleaseSettingsActionEnum.CleanScheduledJobReleaseRule);
export const loadScheduledJobReleaseRule = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRule,
    props<{ ruleId: number }>()
);
export const loadScheduledJobReleaseRuleSuccess = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleSuccess,
    props<{ scheduledReleaseRule: ScheduledReleaseRule }>()
);
export const loadScheduledJobReleaseRuleFailure = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleFailure
);

export const deleteScheduledJobReleaseRule = createAction(
    JobReleaseSettingsActionEnum.DeleteScheduledJobReleaseRule,
    props<{ ruleId: number; facilityId: number }>()
);
export const deleteScheduledJobReleaseRuleSuccess = createAction(
    JobReleaseSettingsActionEnum.DeleteScheduledJobReleaseRuleSuccess,
    props<{ facilityId: number }>()
);
export const deleteScheduledJobReleaseRuleFailure = createAction(
    JobReleaseSettingsActionEnum.DeleteScheduledJobReleaseRuleFailure,
    props<{ error: any }>()
);

export const saveScheduledJobReleaseRule = createAction(
    JobReleaseSettingsActionEnum.SaveScheduledJobReleaseRule,
    props<{ rule: any }>()
);
export const saveScheduledJobReleaseRuleSuccess = createAction(
    JobReleaseSettingsActionEnum.SaveScheduledJobReleaseRuleSuccess,
    props<{ facilityId: number }>()
);
export const saveScheduledJobReleaseRuleFailure = createAction(
    JobReleaseSettingsActionEnum.SaveScheduledJobReleaseRuleFailure
);

export const copyJobReleaseSettingsToFacilities = createAction(
    JobReleaseSettingsActionEnum.CopyJobReleaseSettingsToFacilities,
    props<{ fromFacilityId: number; toFacilityIds: number[] }>()
);

export const copyJobReleaseSettingsToFacilitiesSuccess = createAction(
    JobReleaseSettingsActionEnum.CopyJobReleaseSettingsToFacilitiesSuccess,
    props<{ facilityId: number }>()
);
export const copyJobReleaseSettingsToFacilitiesFailure = createAction(
    JobReleaseSettingsActionEnum.CopyJobReleaseSettingsToFacilitiesFailure,
    props<{ error: any }>()
);

export const copyScheduledJobReleaseRulesToFacilities = createAction(
    JobReleaseSettingsActionEnum.CopyScheduledJobReleaseRules,
    props<{ fromFacilityId: number; toFacilityIds: number[] }>()
);
export const copyScheduledJobReleaseRulesToFacilitiesSuccess = createAction(
    JobReleaseSettingsActionEnum.CopyScheduledJobReleaseRulesSuccess
);
export const copyScheduledJobReleaseRulesToFacilitiesFailure = createAction(
    JobReleaseSettingsActionEnum.CopyScheduledJobReleaseRulesFailure,
    props<{ error: any }>()
);

export const copyJobReleaseSettingsFromFacility = createAction(
    JobReleaseSettingsActionEnum.CopyJobReleaseSettingsFromFacility,
    props<{ fromFacilityId: number; toFacilityId: number }>()
);
export const copyJobReleaseSettingsFromFacilitySuccess = createAction(
    JobReleaseSettingsActionEnum.CopyJobReleaseSettingsFromFacilitySuccess,
    props<{ facilityId: number }>()
);
export const copyJobReleaseSettingsFromFacilityFailure = createAction(
    JobReleaseSettingsActionEnum.CopyJobReleaseSettingsFromFacilityFailure,
    props<{ error: any }>()
);

export const loadScheduledJobReleaseRuleProfessions = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleProfessions,
    props<{ facilityId: number }>()
);
export const loadScheduledJobReleaseRuleProfessionsSuccess = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleProfessionsSuccess,
    props<{ professions: ProfessionEdit[] }>()
);
export const loadScheduledJobReleaseRuleProfessionsFailure = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleProfessionsFailure
);

export const loadScheduledJobReleaseRuleExpertises = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleExpertises,
    props<{ professionId: number }>()
);
export const loadScheduledJobReleaseRuleExpertisesSuccess = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleExpertisesSuccess,
    props<{ expertises: Expertise[] }>()
);
export const loadScheduledJobReleaseRuleExpertisesFailure = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleExpertisesFailure
);

export const loadScheduledJobReleaseRuleVendors = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleVendors,
    props<{ facilityId: number }>()
);
export const loadScheduledJobReleaseRuleVendorsSuccess = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleVendorsSuccess,
    props<{ vendors: AcceptedVendor[] }>()
);
export const loadScheduledJobReleaseRuleVendorsFailure = createAction(
    JobReleaseSettingsActionEnum.LoadScheduledJobReleaseRuleVendorsFailure
);
