import { Action, createReducer, on } from '@ngrx/store';
import * as TravelerDocumentHistoryActions from '../actions/travelers-documents-history.action';
import { LoadingTypes } from 'src/app/shared/models';
import { DocumentHistoryDto } from '../../traveler-details/traveler-documents-history-modal/document-history-dto';

export interface TravelersDocumentHistoryState {
    error: string;
    loading: LoadingTypes;
    travelerDocumentHistory: DocumentHistoryDto[];
    contractId: number;
    requirementId: number;
}

export const initialState: TravelersDocumentHistoryState = {
    error: null,
    loading: LoadingTypes.INIT,
    travelerDocumentHistory: [],
    contractId: null,
    requirementId: null
};

const reducer = createReducer(
    initialState,
    on(
        TravelerDocumentHistoryActions.loadTravelerDocumentsHistory,
        (state, action): TravelersDocumentHistoryState => ({
            ...state,
            loading: LoadingTypes.LOADING,
            requirementId: action.requirementId
        })
    ),
    on(
        TravelerDocumentHistoryActions.loadTravelerDocumentsHistorySuccess,
        (state, action): TravelersDocumentHistoryState => ({
            ...state,
            loading: LoadingTypes.LOADED,
            travelerDocumentHistory: action.docHistory
        })
    ),

    on(
        TravelerDocumentHistoryActions.loadTravelerDetailsForDocumentsHistoryFail,
        (state, action): TravelersDocumentHistoryState => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        })
    ),
    on(
        TravelerDocumentHistoryActions.clearTravelerDocumentHistoryDetailsOnModalClose,
        (state): TravelersDocumentHistoryState => ({
            ...state,
            ...initialState
        })
    )
);

export function travelersDocumentHistoryReducer(state: TravelersDocumentHistoryState | undefined, action: Action) {
    return reducer(state, action);
}
