import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { ListItem } from 'src/app/shared/models/list-item';
import { combine } from 'src/app/shared/utilities';
import {
    BackgroundHistory,
    Candidate,
    CandidateShiftsAppState,
    CandidateUpdate,
    Certification,
    Expertise,
    HospitalType,
    Phone,
    SpecialtyListItem,
    StateLicense,
    WorkShift
} from 'src/app/shared/models/candidate';
import { interpolateParams } from 'src/app/core/utils';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CandidateService {
    private readonly coreApiPath: string;
    private readonly vendorCandidatesPath: string;

    constructor(
        private readonly _http: HttpClient,
        @Inject(APP_CONFIG) _settings: Settings
    ) {
        this.coreApiPath = combine(_settings.CORE, 'ayaconnect');
        this.vendorCandidatesPath = combine(this.coreApiPath, 'vendor', 'candidates');
    }

    getVendorCandidate(candidateId: number): Observable<Candidate> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString());

        return this._http.get<Candidate>(url);
    }

    getVendorContacts(): Observable<ListItem[]> {
        const url = combine(this.coreApiPath, 'vendorcontacts');
        return this._http.get<ListItem[]>(url);
    }

    getShiftTypes(): Observable<WorkShift[]> {
        const url = combine(this.coreApiPath, 'candidates', 'shift-types');
        return this._http.get<WorkShift[]>(url);
    }

    getPhones(candidateId: number): Observable<Phone[]> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'phones');
        return this._http.get<Phone[]>(url);
    }

    getPhoneTypes(): Observable<ListItem[]> {
        const url = combine(this.coreApiPath, 'candidates', 'phone-types');
        return this._http.get<ListItem[]>(url);
    }

    getSpecialties(professionId: number): Observable<SpecialtyListItem[]> {
        const url = combine(this.coreApiPath, 'candidates', 'professions', professionId.toString(), 'specialties');
        return this._http.get<SpecialtyListItem[]>(url);
    }

    getProfessions(): Observable<ListItem[]> {
        const url = combine(this.coreApiPath, 'candidates', 'professions');
        return this._http.get<ListItem[]>(url);
    }

    getCertifications(): Observable<ListItem[]> {
        const url = combine(this.coreApiPath, 'candidates', 'certifications');
        return this._http.get<ListItem[]>(url);
    }

    getCandidateCertifications(candidateId: number): Observable<Certification[]> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'certifications');
        return this._http.get<Certification[]>(url);
    }

    getCandidateExpertiseList(candidateId: number): Observable<Expertise[]> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'profession-specialties');
        return this._http.get<Expertise[]>(url);
    }

    getBackgroundHistory(candidateId: number): Observable<BackgroundHistory[]> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'background');
        return this._http.get<BackgroundHistory[]>(url);
    }

    getStateLicenses(candidateId: number): Observable<StateLicense[]> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'state-licenses');
        return this._http.get<StateLicense[]>(url);
    }

    getHasContractAtPerDiemHospital(candidateId: number): Observable<boolean> {
        const url = combine(this.coreApiPath, 'PerDiemScheduler', 'HasContractAtPerDiemHospital');
        const params = interpolateParams({
            userId: candidateId
        });

        return this._http.get<boolean>(url, { params });
    }

    updateCandidate(candidateId: number, candidate: CandidateUpdate): Observable<Candidate> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString());
        return this._http.put<CandidateUpdate>(url, candidate);
    }

    createCandidate(candidate: CandidateUpdate): Observable<Candidate> {
        const url = combine(this.vendorCandidatesPath);
        return this._http.post<CandidateUpdate>(url, candidate);
    }

    saveExpertise(candidateId: number, expertiseList: Expertise[]): Observable<void> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'profession-specialties');
        return this._http.post<void>(url, expertiseList);
    }

    savePhones(candidateId: number, phoneList: Phone[]): Observable<Phone[]> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'phones');
        return this._http.post<Phone[]>(url, phoneList);
    }

    saveCertifications(candidateId: number, certificationList: Certification[]): Observable<Certification[]> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'certifications');
        return this._http.post<Certification[]>(url, certificationList);
    }

    saveBackgroundHistory(
        candidateId: number,
        backgroundHistoryList: BackgroundHistory[]
    ): Observable<BackgroundHistory[]> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'background');
        return this._http.put<BackgroundHistory[]>(url, backgroundHistoryList);
    }

    saveLicenses(candidateId: number, licenseList: StateLicense[]): Observable<StateLicense[]> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'state-licenses');
        return this._http.post<StateLicense[]>(url, licenseList);
    }

    getShiftAppState(candidateId: number): Observable<CandidateShiftsAppState> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'shiftsapp');

        return this._http.get<CandidateShiftsAppState>(url);
    }

    sendShiftsAppInvitation(candidateId: number): Observable<void> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'shiftsapp-invite');
        return this._http.post<void>(url, {});
    }

    checkCandidateFiles(candidateId: number): Observable<boolean> {
        const url = combine(this.vendorCandidatesPath, candidateId.toString(), 'files');

        return this._http.get<boolean>(url, { observe: 'response' }).pipe(map((result) => result.status === 200));
    }

    getSubmittalJobDetails(jobId: number) {
        const url = combine(this.coreApiPath, `Vendors/submittals/job/${jobId}`);
        return this._http.get<HospitalType>(url);
    }

    getOtherInfo(candidateId: string): Observable<void> {
        const url = combine(this.vendorCandidatesPath, candidateId, 'other-info');
        return this._http.get<void>(url);
    }
}
