<dialog-layout [title]="title">
    <div mat-dialog-content>
        <div class="export-dialog-content">
            <mat-form-field appearance="outline" class="export-modal-item">
                <mat-label>Export File Format</mat-label>
                <mat-select [(value)]="exportModel.fileFormatId">
                    <mat-option *ngFor="let exportFileFormat of exportFileFormats" [value]="exportFileFormat.id">
                        {{exportFileFormat.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="export-modal-item">
                <mat-label>Export with</mat-label>
                <mat-select [(value)]="exportModel.exportWithCurrentValues">
                    <mat-option *ngFor="let exportWithOption of exportWithCurrentValuesOptions" [value]="exportWithOption.value">
                        {{exportWithOption.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-checkbox class="export-modal-item" [(ngModel)]="exportModel.currentPageOnly">
                Only export current page
            </mat-checkbox>
        </div>
    </div>

    <mat-divider class="my-1"></mat-divider>

    <div mat-dialog-actions>
        <ng-container *ifLdFeature="featureFlags.ConnectReportingRedesign; enabled: true">
            <button data-qa-id="report-export-action" id="report-export-action" (click)="export()" color="accent" mat-flat-button>Export</button>
            <button data-qa-id="report-cancel-export-action" id="report-cancel-export-action"mat-stroked-button (click)="cancel()" cdkFocusInitial>Cancel</button>
        </ng-container>
        <ng-container *ifLdFeature="featureFlags.ConnectReportingRedesign; enabled: false">
            <button mat-stroked-button (click)="cancel()" cdkFocusInitial>Cancel</button>
            <button mat-raised-button (click)="export()" color="accent">Export</button>
        </ng-container>
    </div>
</dialog-layout>
