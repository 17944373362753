import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FileService } from '../../services/file.service';
import { Candidate, ContractRequirementDocument, ContractRequirements } from 'src/app/shared/models/candidate';
import { Observable, map, takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { Store } from '@ngrx/store';
import { VendorRequirementDocumentUploadInfo } from '../../store/models/vendor-requirement-document-upload-info';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { CandidateService } from 'src/app/shared/services/candidate.service';
import { DocumentAttributesFormComponent } from '../document-attributes-form/document-attributes-form.component';
import { DocumentTypeConfiguration } from 'src/app/shared/models/requirements/document-type-configuration.model';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

@Component({
    selector: 'ayac-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentUploadComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild(DocumentAttributesFormComponent, { static: true }) formComponent: DocumentAttributesFormComponent;
    candidate: Candidate;
    validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.tiff', '.pdf', '.doc', '.docx', '.xls', '.xlsx'];
    validExtensionsString = this.validExtensions.join(', ');
    validFileSize = 20;
    isLoading = true;
    isDocumentPreviewUploadLoading$: Observable<boolean>;
    docTypeId = 0;
    displayedColumns: string[] = ['name', 'lastModifiedDate', 'uploadedBy', 'actions'];
    dataSource = new MatTableDataSource<VendorRequirementDocumentUploadInfo>();
    hasChanges = false;
    isFormValid = true;
    contractRequirements$: Observable<ContractRequirements>;
    contractRequirementDocuments: ContractRequirementDocument[];
    selectedDocument: ContractRequirementDocument;
    contractId = 0;
    contractInfoInputString = '';
    areMetadataFieldsLoaded$: Observable<boolean>;
    isMetadataFormValid$: Observable<boolean>;
    candidateId = 0;
    contractRequirementLineItemsForModal: DocumentTypeConfiguration[];
    isEdit = false;
    candidateDocumentId = 0;
    isEditObservable$: Observable<boolean>;
    hasChanges$: Observable<boolean>;
    readonly featureFlag = FeatureFlag;

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _fileService: FileService,
        private readonly store: Store,
        private readonly _cd: ChangeDetectorRef,
        private readonly _identityService: IdentityService,
        private readonly _dialog: DialogService,
        private readonly _candidateService: CandidateService,
        private readonly _datePipe: DatePipe
    ) {
        super();
    }

    get isAttachmentDataEmpty() {
        return this.dataSource ? this.dataSource.data.length === 0 : null;
    }

    get userLoggedInName() {
        return this._identityService.userFullName;
    }

    // Called on browser page refresh
    @HostListener('window:beforeunload', ['$event']) onRefresh(event: Event) {
        // Only delete the document if one has been uploaded and we are not editing.
        if (this.hasChanges && !this.isEdit) {
            // Delete the document, the user clicked refresh or back in the browser. Navigate away for now until we handle this better to avoid errors and worse UI/UX experience.
            this.store.dispatch(actions.deleteDocumentCalled({ deleteDocumentCalledWithNavigation: true }));

            // Recommended
            event.preventDefault();

            // Included for legacy support, e.g. Chrome/Edge < 119
            event.returnValue = true;
        }
    }

    ngOnInit(): void {
        this.isDocumentPreviewUploadLoading$ = this.store.select(selectors.selectDocumentUploadPreviewLoading);
        this.areMetadataFieldsLoaded$ = this.store.select(selectors.selectAreMetadataFieldsLoaded);
        this.isMetadataFormValid$ = this.store.select(selectors.selectIsMetadataFormValid);
        this.isEditObservable$ = this.store.select(selectors.selectIsEdit);
        this.hasChanges$ = this.store.select(selectors.selectDocumentUploadFormHasChanges);

        this._route.params
            .pipe(
                map((params: Params) => params.id),
                takeUntil(this.d$)
            )
            .subscribe((candidateId: string) => {
                this.candidateId = +candidateId;
                this._cd.detectChanges();
            });

        this._route.queryParams.pipe(takeUntil(this.d$)).subscribe((params: Params) => {
            this.docTypeId = +params.docType;
            this.store.dispatch(actions.setDocumentTypeId({ docTypeId: this.docTypeId }));

            if (params.candidateDocumentId) {
                this.candidateDocumentId = +params.candidateDocumentId;
                this.store.dispatch(actions.setCandidateDocumentId({ candidateDocumentId: this.candidateDocumentId }));
            }

            if (params.edit) {
                this.isEdit = true;
                this.store.dispatch(
                    actions.getDocumentUploaded({
                        isEdit: this.isEdit
                    })
                );
            }

            this._cd.detectChanges();
        });

        this.store
            .select(selectors.selectDocumentUploadInfo)
            .pipe(takeUntil(this.d$))
            .subscribe((data) => {
                if (data.fileName && data.uploadedByUser && data.uploadedDate) {
                    const info: VendorRequirementDocumentUploadInfo[] = [
                        {
                            uploadedBy: data.uploadedByUser,
                            uploadedDate: new Date(data.uploadedDate),
                            name: data.fileName
                        }
                    ];

                    this.dataSource.data = info;
                    this.hasChanges = true;

                    this._cd.detectChanges();
                } else {
                    this.dataSource = new MatTableDataSource<VendorRequirementDocumentUploadInfo>();
                }
            });

        this.store
            .select(selectors.selectContractRequirements)
            .pipe(takeUntil(this.d$))
            .subscribe((contractRequirements) => {
                if (
                    contractRequirements?.contractRequirements &&
                    contractRequirements?.contractRequirements.length > 0
                ) {
                    this.selectedDocument = contractRequirements.contractRequirements
                        .flatMap((cr) => cr.documents)
                        .find((doc) => doc.documentTypeId === this.docTypeId);

                    this.contractInfoInputString =
                        contractRequirements.contractRequirements[0].contractStage +
                        ' ' +
                        contractRequirements.contractRequirements[0].facility +
                        ' ' +
                        this._datePipe.transform(contractRequirements.contractRequirements[0].from, 'MM/dd/yyyy - ') +
                        this._datePipe.transform(contractRequirements.contractRequirements[0].to, 'MM/dd/yyyy');

                    this.contractId = this.selectedDocument.contractId;
                    this._cd.detectChanges();
                } else {
                    this.store.dispatch(actions.getContractRequirements({ candidateId: this.candidateId }));
                }
            });

        this.store
            .select(selectors.selectSaveDocumentMetadataLoadedSuccess)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                if (result) {
                    this.hasChanges = false;
                    this._cd.detectChanges();
                    this.resetStateAndNavigate();
                }
            });

        this.store
            .select(selectors.selectDocumentDeletedSuccess)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                if (result) {
                    this.hasChanges = false;
                    this.dataSource = new MatTableDataSource<VendorRequirementDocumentUploadInfo>();
                    this.store.dispatch(actions.resetDocumentUploadStateAfterSaveOrDelete());
                    this._cd.detectChanges();
                }
            });

        this.store
            .select(selectors.selectDeleteDocumentCalledWithNavigationSuccess)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                if (result) {
                    this.hasChanges = false;
                    this._cd.detectChanges();
                    this.resetStateAndNavigate();
                }
            });

        this.handleCandidateDataFromFileService();
    }

    handleCandidateDataFromFileService() {
        this._fileService.candidate$.pipe(takeUntil(this.d$)).subscribe((candidate) => {
            if (candidate) {
                this.candidate = candidate;
                this.isLoading = false;
                this.store.dispatch(actions.setCandidateForDocumentUpload({ candidate }));
                this._cd.detectChanges();
            } else {
                this.getCandidatePageRefresh();
            }
        });
    }

    getCandidatePageRefresh() {
        this._candidateService
            .getVendorCandidate(this.candidateId)
            .pipe(takeUntil(this.d$))
            .subscribe((candidate) => {
                this.candidate = candidate;
                this.isLoading = false;
                this.store.dispatch(actions.setCandidateForDocumentUpload({ candidate }));
                this._cd.detectChanges();
            });
    }

    attachmentUpload(file) {
        if (file) {
            this.store.dispatch(
                actions.uploadRequirementFilePreview({
                    docTypeId: this.docTypeId,
                    fileToUpload: file,
                    contractId: this.contractId,
                    candidateId: this.candidate.id,
                    candidateOldUserId: this.candidate.oldUserId
                })
            );

            this.hasChanges = true;
            this._cd.detectChanges();
        }
    }

    deleteAttachment() {
        this._dialog
            .openConfirmationDialog({
                data: {
                    title: 'Delete Attachment',
                    text: 'This attachment will be deleted and the action cannot be undone.',
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel'
                }
            })
            .then((result) => {
                if (result) {
                    this.store.dispatch(actions.deleteDocumentCalled({ deleteDocumentCalledWithNavigation: false }));
                    this._cd.detectChanges();
                } else {
                    this.store.dispatch(actions.deleteDocumentCancelled());
                    this._cd.detectChanges();
                }
            });
    }

    back(): void {
        if (this.hasChanges && !this.isEdit) {
            this._dialog
                .openConfirmationDialog({
                    data: {
                        title: 'Unsaved Changes Will Be Lost',
                        text: `You have unsaved changes on this page. If you leave before saving, your changes will be lost.`
                    }
                })
                .then((result) => {
                    if (result) {
                        this.store.dispatch(actions.deleteDocumentCalled({ deleteDocumentCalledWithNavigation: true }));
                    } else {
                        this.store.dispatch(actions.deleteDocumentCancelled());
                    }
                    return result;
                });
        } else {
            this.resetStateAndNavigate();
        }
    }

    save(): void {
        const dialogData = this.isEdit
            ? {
                  title: 'Overwrite Existing Data',
                  text: 'This document was previously submitted. Are you sure you want to overwrite the existing data?',
                  confirmButtonText: 'Overwrite',
                  cancelButtonText: 'Cancel'
              }
            : {
                  title: 'Confirm Submission',
                  text: 'A document cannot be re-submitted while it is pending approval. Please confirm that all details are correct.',
                  confirmButtonText: 'Confirm',
                  cancelButtonText: 'Cancel'
              };

        this._dialog.openConfirmationDialog({ data: dialogData }).then((result) => {
            if (result) {
                this.store.dispatch(
                    actions.saveDocumentMetadata({
                        documentMetadataInputForSave: this.formComponent.form?.value
                    })
                );
            }
        });
    }

    resetStateAndNavigate() {
        this.store.dispatch(actions.resetDocumentUploadStateAfterSaveOrDelete());
        this._router.navigateByUrl(`/vendor/candidate/${this.candidate.id}`);
    }

    viewDocumentDetails(document: ContractRequirementDocument): void {
        this.store.dispatch(
            actions.loadViewRequirementDetails({
                docTypeId: document.documentTypeId,
                contractId: document.contractId,
                title: document.title
            })
        );
    }

    private _openChangedNotSavedDialog(): boolean | Promise<boolean> | Observable<boolean> {
        return this._dialog
            .openConfirmationDialog({
                data: {
                    title: 'Unsaved Changes Will Be Lost',
                    text: `You have unsaved changes on this page. If you leave before saving, your changes will be lost.`
                }
            })
            .then((result) => {
                return result;
            });
    }
}
