import { createAction, props } from '@ngrx/store';
import { ShiftTemplate } from '../../models';
import { ShiftTemplatesQuery } from '../reducers/shift-templates.reducers';

export const loadShiftTemplates = createAction('[SHIFTS] Load Shift Templates');

export const loadShiftTemplatesFail = createAction('[SHIFTS] Load Shift Templates Fail', props<{ error: any }>());

export const loadShiftTemplatesSuccess = createAction(
    '[SHIFTS] Load Shift Templates Success',
    props<{ templates: ShiftTemplate[]; total: number }>()
);

export const setShiftTemplatesQuery = createAction(
    '[SHIFTS] Set Shift Templates Query',
    props<{ query: Partial<ShiftTemplatesQuery> }>()
);

export const clearShiftTemplates = createAction('[SHIFTS] Clear Shift Templates');
