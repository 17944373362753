<div class="modal-wrapper" [spinner]="isLoading">
    <div class="modal-header">
        <button mat-icon-button mat-dialog-close aria-label="Close" *ngIf="backArrow">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <div>
            <h3 class="modal-title">
                {{title}}
            </h3>
            <h5 *ngIf="subTitle" class="modal-sub-title">
                {{subTitle}}
            </h5>
        </div>
        <div class="top-button">
            <button *ngIf="upperButtonTitle" (click)="upperButtonClick()" mat-flat-button color="accent" class="mr-2">
                <span class="fas fa-download"></span>
                {{upperButtonTitle}}
            </button>

            <button type="button" class="close">
                <span (click)="close()">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <ng-content select="[mat-dialog-content]"></ng-content>
    </div>
    <div class="modal-footer">
        <ng-content select="[mat-dialog-actions]"></ng-content>
    </div>
</div>
