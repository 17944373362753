import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import {
    loadLinesOfBusiness,
    loadLinesOfBusinessSuccess,
    loadLinesOfBusinessFail
} from 'src/app/lookups/state/actions';

export interface LinesOfBusinessState {
    linesOfBusiness: Array<ListItem>;
    loading: LoadingTypes;
    error: string;
}

export const initialLinesOfBusinessState: LinesOfBusinessState = {
    linesOfBusiness: [],
    loading: LoadingTypes.INIT,
    error: ''
};

export function lineOfBusinessReducer(state: LinesOfBusinessState, action: Action) {
    return createReducer(
        initialLinesOfBusinessState,
        on(loadLinesOfBusiness, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadLinesOfBusinessSuccess, (state, action) => ({
            ...state,
            linesOfBusiness: action.linesOfBusiness,
            loading: LoadingTypes.LOADED
        })),

        on(loadLinesOfBusinessFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: LinesOfBusinessState | undefined, action: Action) {
    return lineOfBusinessReducer(state, action);
}
