import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings, APP_CONFIG } from 'src/app/config/settings';
import { interpolateUrl } from 'src/app/core/utils';
import { CoreUrls, MenuResponse, PreferencesResponse } from '../models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoreRepositoryService {
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings
    ) {}

    getMenu(applicationId: number) {
        const url = interpolateUrl(CoreUrls.MENU, { applicationId });
        return this.http.get<MenuResponse>(`${this.settings.CORE}${url}`);
    }

    getClientFeaturedLogo(systemId: number): Observable<Blob> {
        return this.http.get(`${this.settings.CORE}${CoreUrls.CLIENT_FEATURED_LOGO}${systemId}`, {
            responseType: 'blob'
        });
    }

    getPreferences(id: number) {
        const url = `${CoreUrls.CLIENT_PREFERENCES}/${id}`;
        return this.http.get<PreferencesResponse>(`${this.settings.CORE}${url}`);
    }

    updatePreference(id: number, preference: { [key: string]: any }) {
        const url = `${CoreUrls.CLIENT_PREFERENCES}`;
        const body = {
            userPreferenceTypeId: id,
            preference: JSON.stringify(preference)
        };
        return this.http.put<PreferencesResponse>(`${this.settings.CORE}${url}`, body);
    }

    createPreference(id: number, preference: { [key: string]: any }) {
        const url = `${CoreUrls.CLIENT_PREFERENCES}`;
        const body = {
            userPreferenceTypeId: id,
            preference: JSON.stringify(preference)
        };
        return this.http.post<PreferencesResponse>(`${this.settings.CORE}${url}`, body);
    }
}
