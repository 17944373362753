export enum XAlchemistsFeatureFlags {
    ConnectStandaloneVMSClientOfferFlow = 'CONNECT_STANDALONE_VMS_CLIENT_OFFER_FLOW',
    ConnectVMSClientEditOffer = 'connect-vms-client-edit-offer',
    ConnectVMSClientRescindOffer = 'connect-vms-client-rescind-offer',
    ConnectVMSClientCreateOffer = 'connect-vms-client-create-offer',
    ConnectVMSOfferChip = 'connect-vms-offer-chip',
    ConnectMultipleOffersDialogRescindList = 'connect-multiple-offers-dialog-rescind-list',
    ConnectVMSClientOfferNotes = 'connect-vms-client-offer-notes',
    ConnectVMSDeclineChangesOffer = 'connect-vms-client-decline-changes',
    ConnectVMSPreventMoreOffersThanPositions = 'connect-vms-client-prevent-more-offers-than-positions',
    ConnectVMSExtensionOffer = 'connect-vms-extension-offer',
    ConnectVmsOfferBillRateUsePermissions = 'connect-vms-offer-bill-rate-use-permissions'
}
