<ayac-submittals-modal title="Comment"
                       loading="false"
                       actionButtonName="Comment"
                       actionButtonColor="accent"
                       (actionButtonClick)="addOfferNote()"
                       [actionButtonDisabled]="!form.valid"
                       [displayRedActionButton]="false">
    <form [formGroup]="form">
        <ayac-text-area-with-counter formControlName="note"
                                     [editor]="editor"
                                     [isVendorClientCommunicationEnabled]="isVendorClientCommunicationEnabled"
                                     data-qa-id="add-offer-note-input">
        </ayac-text-area-with-counter>
    </form>
</ayac-submittals-modal>
