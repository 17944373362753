<dialog-layout [title]="title" terminology>
    <div mat-dialog-content  style="max-width: 800px">

        <mat-card class="ma-2">
            <mat-card-content>
                <div fxLayout="row">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon>assignment</mat-icon>
                    </div>
                    <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center">

                        <div class="mx-4">
                            <h3 class="mat-headline-6 mb-0">
                                Your To-Do's
                            </h3>
                            <p>
                                View all of your action items in one place. You'll see an "!" icon on items that you may have forgotten about. Click on any of the to-do tiles to view details.
                            </p>
                        </div>
                    </div>
                    <div fxFlex="40" fxLayout="row" fxLayoutAlign="center center" class="welcome-image">
                        <img src="/content/images/tutorial/your-todos.png" alt="Your To-Do's" />
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="ma-2">
            <mat-card-content>
                <div fxLayout="row">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon fontSet="fas" fontIcon="fa-users"></mat-icon>
                    </div>
                    <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center">
                        <div class="mx-4">
                            <h3 class="mat-headline-6 mb-0">
                                Workers
                            </h3>
                            <p>
                                See a summarized view of your workforce. Click on the blue text to view details.
                            </p>
                        </div>
                    </div>
                    <div fxFlex="40" fxLayout="row" fxLayoutAlign="center center" class="welcome-image">
                        <img src="/content/images/tutorial/workers.png" alt="Workers" />
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="ma-2">
            <mat-card-content>
                <div fxLayout="row">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon fontSet="fas" fontIcon="fa-briefcase"></mat-icon>
                    </div>
                    <div fxFlex="60" fxLayout="row" fxLayoutAlign="center center">
                        <div class="mx-4">
                            <h3 class="mat-headline-6 mb-0">
                                Jobs
                            </h3>
                            <p>
                                Get an overview of positions that still need to be filled and submittals to those positions. Click on the blue text to view details.
                            </p>
                        </div>
                    </div>
                    <div fxFlex="40" fxLayout="row" fxLayoutAlign="center center" class="welcome-image">
                        <img *ngIf="!hasTerminologyPermission; else terminologyImage" src="/content/images/tutorial/jobs.png" alt="Jobs" />
                        <ng-template #terminologyImage>
                            <img src="/content/images/tutorial/jobs-termkeys.png" alt="Jobs" />
                        </ng-template>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>


    </div>

    <div mat-dialog-actions *ngIf="email$ | async as email">
        <p>
            Got questions? Reach out to the team at
            <a [attr.href]="'mailto:' + email">{{email}}</a>
            or contact your account manager.
        </p>
    </div>
</dialog-layout>
