import { createAction, props } from '@ngrx/store';
import { GridStateChangeEvent } from 'src/app/shared/grid/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import {
    ClientRequirementDocumentStatus,
    TravelerDocumentGridItem
} from '../../traveler-details/traveler-documents/grid/traveler-documents-grid.model';
import { DocumentTravelerDetails } from '../../models/document-traveler-details.model';
import { DocumentMetadata } from '../../models/document-metadata.model';
import { DocumentDeclineReasonEnum } from '../../models/enums/document-decline-reason.enum';

//Traveler Documents
export const loadTravelerDocuments = createAction('[Travelers] Load Documents', props<{ contractId: number }>());
export const loadSelectedTravelerDocumentsRequest = createAction(
    '[Travelers] Download Selected Documents',
    props<{ contractId: number; documentIds: number[] }>()
);
export const loadAllTravelerDocumentsRequest = createAction(
    '[Travelers] Download All Documents',
    props<{ contractId: number }>()
);
export const documentsLoadSuccess = createAction(
    '[Travelers] Load Documents Success',
    props<{ documents: TravelerDocumentGridItem[] }>()
);
export const documentsLoadError = createAction('[Travelers] Load Documents Error', props<{ error: any }>());
export const openDocument = createAction(
    '[Travelers] Open Document',
    props<{ contractId: number; documentId: number }>()
);
export const openDocumentInViewer = createAction(
    '[Travelers] Open Document In Viewer',
    props<{ contractId: number; documentId: number }>()
);
export const loadDocumentSuccess = createAction(
    '[Travelers] Download Document Success',
    props<{ blob: Blob; filename: string }>()
);
export const loadDocumentFail = createAction('[Travelers] Download Document Failure', props<{ error: any }>());
export const documentsSelected = createAction(
    '[Travelers] Documents selected',
    props<{ selectedDocuments: number[] }>()
);
export const clearDocumentsGrid = createAction('[Travelers] Clear Documents Grid');
export const documentsFilterRequest = createAction(
    '[LOCAL POOL DOCUMENTS COMPONENT] Document Filter request',
    props<{ documents: TravelerDocumentGridItem[] }>()
);
export const documentsFilterRequestByTitleOrCategory = createAction(
    '[TRAVELERS DOCUMENTS COMPONENT] Document Filter Title/Category request',
    props<{ state: GridStateChangeEvent; documents: TravelerDocumentGridItem[] }>()
);
export const documentsFilterSuccess = createAction(
    '[Travelers] Document Filter request success',
    props<{ filteredDocuments: GridDataResult }>()
);
export const documentsFilterFail = createAction('[Travelers] Document Filter request failure', props<{ error: any }>());
export const setGridState = createAction(
    '[Travelers Component] Documents Set Grid State',
    props<{ newState: GridStateChangeEvent }>()
);
export const setGridFilters = createAction(
    '[Travelers Component] Documents Set Grid Filters ',
    props<{ filters: FilterDescriptor[] }>()
);

export const loadTravelerDetailsForDocuments = createAction(
    '[Traveler Document Details] Load Traveler Details for Document',
    props<{ contractId: number }>()
);
export const loadTravelerDetailsForDocumentsSuccess = createAction(
    '[Traveler Service] Load Traveler Details for Document Success',
    props<{ details: DocumentTravelerDetails }>()
);
export const loadTravelerDetailsForDocumentsFail = createAction(
    '[Traveler Service] Load Traveler Details for Document Fail',
    props<{ error: unknown }>()
);

export const loadDocumentMetadata = createAction(
    '[Traveler Document Details] Load Document Metadata',
    props<{ contractId: number; documentId: number }>()
);
export const loadDocumentMetadataSuccess = createAction(
    '[Traveler Service] Load Document Metadata Success',
    props<{ metadata: DocumentMetadata }>()
);
export const loadDocumentMetadataFail = createAction(
    '[Traveler Service] Load Document Metadata Fail',
    props<{ error: unknown }>()
);

export const declineDocument = createAction(
    '[Traveler Document Details] Decline Document',
    props<{ contractId: number; documentId: number; reason: DocumentDeclineReasonEnum; notes: string }>()
);
export const declineDocumentSuccess = createAction('[Traveler Service] Decline Document Success');
export const declineDocumentFail = createAction(
    '[Traveler Service] Decline Document Fail',
    props<{ error: unknown }>()
);

export const approveDocument = createAction(
    '[Traveler Document Details] Approve Document',
    props<{ contractId: number; documentId: number }>()
);
export const approveDocumentSuccess = createAction('[Traveler Service] Approve Document Success');
export const approveDocumentFail = createAction(
    '[Traveler Service] Approve Document Fail',
    props<{ error: unknown }>()
);

export const exportGrid = createAction(
    '[Travelers Document Grid] Export Documents Grid',
    props<{ contractId: number }>()
);
export const exportGridSuccess = createAction(
    '[Travelers Document Grid] Export Documents Grid Success',
    props<{ blob: Blob; filename: string }>()
);
export const exportGridFail = createAction(
    '[Travelers Document Grid] Export Documents Grid Fail',
    props<{ error: unknown }>()
);

export const updateClearedStatus = createAction(
    '[Traveler Document Grid] Update Cleared Status',
    props<{ contractId: number; clearedToStart: boolean }>()
);
export const updateClearedStatusSuccess = createAction(
    '[Traveler Service] Update Cleared Status Success',
    props<{ contractId: number }>()
);
export const updateClearedStatusFail = createAction(
    '[Traveler Service] Revert Update Status Fail',
    props<{ error: unknown }>()
);

export const loadCandidateDeclinedDocumentStatus = createAction(
    '[Travelers Document Details] Load Candidate Requirements Document Status',
    props<{ contractId: number; candidateDocumentId: number }>()
);

export const loadCandidateDeclinedDocumentStatusSuccess = createAction(
    '[Travelers Document Details] Load Candidate Requirements Document Status Success',
    props<{ documentStatus: ClientRequirementDocumentStatus }>()
);

export const loadCandidateDeclinedDocumentStatusFailure = createAction(
    '[Travelers Document Details] Load Candidate Requirements Document Status Failed',
    props<{ error: unknown }>()
);
