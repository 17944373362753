import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { LoadingTypes } from 'src/app/shared/models';

export const selectVendorCandidateFileFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectVendorCandidateFiles = createSelector(
    selectVendorCandidateFileFeature,
    (state) => state.vendorCandidateFileState.candidateFiles
);

export const selectVendorCandidateFilesLoading = createSelector(
    selectVendorCandidateFileFeature,
    (state) => state.vendorCandidateFileState.loading === LoadingTypes.LOADING
);

export const selectVendorCandidateFilesSearchQuery = createSelector(
    selectVendorCandidateFileFeature,
    (state) => state.vendorCandidateFileState.gridSearchQuery
);

export const selectVendorCandidateFileIsDeleting = createSelector(
    selectVendorCandidateFileFeature,
    (state): boolean => state.vendorCandidateFileState.isDeleting
);

export const selectVendorCandidateFilesDeletingFileIds = createSelector(
    selectVendorCandidateFileFeature,
    (state): number[] => state.vendorCandidateFileState.deletingFileIds
);

export const selectVendorCandidateFilesDeleteSuccess = createSelector(
    selectVendorCandidateFileFeature,
    (state): boolean => state.vendorCandidateFileState.isDeleteSuccess
);

export const selectFileTypes = createSelector(
    selectVendorCandidateFileFeature,
    (state) => state.vendorCandidateFileState.fileTypes
);
