<!-- Header section for attachments -->
<!-- attachmentData is the data that passed from the server VS document.files is the data that stored in browser. -->
<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="label-text">Attachments<span *ngIf="attachmentConfig.attachmentRequired == true" class="k-required"> *</span></div>    
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div><span class="desc">{{fileExtensions}} &nbsp;&nbsp;</span></div>
    </div>
</div>
<mat-progress-bar
    *ngIf="loading"
    mode="indeterminate"
    color="accent">
</mat-progress-bar>
<!-- editMode will prevent the user from adding files and it won't let the user to modify any exisiting documents -->
<div
    *ngIf="!editMode && !attachmentConfig.hiddenNotes && !attachmentConfig.hiddenPermissionNote"
    class="label-text-editing">
    You don't have enough permissions to add an attachment.
</div>
<div>
    <div *ngIf="editMode">       
        <form method="POST" enctype="multipart/form-data" #fileForm
            (dragover)="updateDragState($event)" (dragleave)="updateDragState($event)" (drop)="getDroppedFiles($event)">
            <input 
                #fileInput 
                class="uploadInputType" 
                type="file" 
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,message/rfc822,application/vnd.ms-outlook,text/plain,image/jpeg,image/jpg,image/tiff,image/gif,image/bmp,image/png,image/bpg,image/svg" 
                id="file-input" 
                (change)="addSelectedFiles()" 
                multiple>
            <div [ngClass]="{ 'drop-target': true, 'drag-over': attachmentConfig.isDragging, 'empty': !document.files.length }">
                <!-- using the id to distinguish entities UX team wants the user to have the same experinence when the user add files to exisitng entity vs new entity -->
                <ng-container *ngIf="!document.files.length || id === 0">
                    <div class="drag-drop-display">
                        <div><mat-icon class="material-icons" color="accent">cloud_upload</mat-icon></div>
                        <div><label for="file-input" color="accent">Drag &amp; Drop</label></div>
                        <div><p>OR</p></div>
                        <div><button type="button" mat-stroked-button class="mat-raised-button mat-primary" color="accent" data-qa-id="ac_client_af_browse_button" (click)="openExplorer()">Browse</button></div>                                               
                    </div>
                </ng-container>             
            </div>
        </form>
    </div>
    <!-- show messages for validation -->
    <div *ngFor="let result of validationResults; trackBy: trackItem" [ngClass]="{ 'upload-error': !result.isValid, 'upload-success': result.isValid }">        
        <div class="error-uploading-mya">
            <div class="file-upload__message">{{result.message}}</div>
        </div>
    </div>
    <!-- 'Save to Job' need to be replaced by an input variable passed from the parent component -->
    
    <div *ngIf="!attachmentConfig.hiddenNotes">
        <div *ngIf="attachmentConfig.attachmentRequired == false && 
                    document.files &&                                      
                    document.files.length === 0">Note: No attachment is required to 'Save a {{attachmentConfig.attachmentTitle}}'</div>
        <div *ngIf="document.files &&
                    document.files.length === 0 &&
                    attachmentData?.length === 0">
                 <div [innerHtml]="attachmentConfig.attachmentNotes"></div> 
        </div>    
        <div *ngIf="attachmentConfig.hasTemplate &&
                    document.files && document.files.length === 0 && 
                    attachmentConfig.attachmentNotes.length === 0 &&
                    attachmentData?.length === 0">Note: No attachment is required to 'Save as Template'</div>
    </div>
    <!-- this is a place holder if the entity is not exists -->
    <div>
        <ng-container *ngIf="id === 0 && document.files.length">
            <ng-container *ngIf="isDuplicate">
                <div>
                    <h3>New Attachments</h3>
                </div>
            </ng-container>            
            <table class="attachments-table table table-bordered" aria-describedby="AttachmentTableForAddingNewContent">
                <thead class="thead-light">
                    <tr>
                        <th id="attachmentTitleHeader">Document Name</th>       
                        <th id="attachmentViewableRadioHeader" *ngIf="attachmentConfig.viewableByRadioButton && editMode">Viewable by</th>           
                        <th id="attachmentEditActionHeader" *ngIf="editMode">Actions</th>
                    </tr>
                </thead>
                <tbody>                    
                    <tr *ngFor="let file of document.files">
                        <td *ngIf="editMode || (attachmentConfig.viewableByRadioButton && !file.internalOnly)">
                            <span class="aya__link" 
                            matTooltip="Please click save to be able to view the file.">{{file.name}}</span>     
                        </td>
                        <td *ngIf="attachmentConfig.viewableByRadioButton && editMode">
                            Viewable by:
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="file.internalOnly" (change)="onRadioSelected()">
                                <mat-radio-button [value]="true">Internal Only &nbsp;</mat-radio-button>
                                <mat-radio-button [value]="false">Everyone</mat-radio-button>
                            </mat-radio-group>
                        </td>
                        <td *ngIf="editMode">
                            <mat-icon (click)="removeFile(file)" class="material-icons aya__cursor" color="accent">delete</mat-icon>                    
                        </td>
                    </tr>
                </tbody>
            </table>    
        </ng-container>
    </div>
     <!-- this is for the attachment for existing entities -->
     <ng-container *ngIf="isDuplicate">
        <div>
            <h3>Attachments to copy</h3>
        </div>
    </ng-container>
    <table class="attachments-table table table-bordered">
        <caption></caption>
        <thead class="thead-light">
            <tr>
                <th id="attachmentTitleExistingHeader">Document Name</th>       
                <th id="attachmentViewableRadioExistingHeader" *ngIf="attachmentConfig.viewableByRadioButton && editMode">Viewable by</th>   
                <th id="attachmentEditActionExistingHeader" *ngIf="editMode">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="id > 0 && !attachmentData?.length">
                <td colspan="4" class="no-attachment-msg">No attachments have been uploaded.</td>
            </tr>
            <tr *ngFor="let file of attachmentData">
                <td *ngIf="editMode || (attachmentConfig.viewableByRadioButton && !file.internalOnly)">
                    <span class="aya__link" (click)="getAttachmentPreview(file)"
                    matTooltip="Uploaded by: {{file.userFullName}} &#013;&#010; {{(file.uploadDate | fixTimezoneOffset | date: 'MM/dd/yyyy - h:mm a')}}"
                    >{{ file.fileName }}</span>     
                </td>
                <td *ngIf="attachmentConfig.viewableByRadioButton && editMode">
                    Viewable by:
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="file.internalOnly" (change)="onRadioSelected($event)">
                        <mat-radio-button [value]="true"  (click)="updateAttachment(file, true)">Internal Only &nbsp;</mat-radio-button>
                        <mat-radio-button [value]="false" (click)="updateAttachment(file, false)">Everyone</mat-radio-button>
                    </mat-radio-group>
                </td>
                <td *ngIf="editMode && id > 0">
                    <mat-icon (click)="deleteAttachment(file)" class="material-icons aya__cursor" color="accent">delete</mat-icon>                    
                </td>
                <td *ngIf="editMode && isDuplicate">
                    <mat-icon (click)="removeFileForDuplicateWorkflow(file)" class="material-icons aya__cursor" color="accent">delete</mat-icon>                                        
                </td>
            </tr>
        </tbody>
    </table>
</div>
