<div class="file-list" *ngIf="listStyle == fileAttachmentStyle.TitleAndList">
    <mat-list data-qa-id="ac_client_fal_attachments_list" *ngFor="let fileItem of attachments; let first = first">
        <mat-list-item>
            <ng-container *ngIf="!hiddenTitle">
                <div *ngIf="first" class="field-title">Attachments:</div>
                <div *ngIf="!first" class="field-title"></div>
            </ng-container>
            <ng-content *ngTemplateOutlet="attachmentList; context : {rowData : fileItem}"></ng-content>
        </mat-list-item>
    </mat-list>
</div>

<div class="file-list" *ngIf="listStyle == fileAttachmentStyle.ListOnly">
    <div *ngFor="let fileItem of attachments">
        <ng-content *ngTemplateOutlet="attachmentList; context : {rowData : fileItem}"></ng-content>
    </div>
</div>

<ng-template let-rowData="rowData" #attachmentList>
    <div *ngIf="!rowData.internalOnly" class="field-value">
        <a data-qa-id="ac_client_fal_file_name_link" matTooltip="Uploaded by: {{rowData.userFullName}} &#013;&#010; {{(rowData.uploadDate | fixTimezoneOffset | date: 'MM/dd/yyyy - h:mm a')}}"
            (click)="attachmentToPreview(rowData)">{{rowData?.fileName}}
        </a>
    </div>
    <div *ngIf="rowData.internalOnly && editMode" class="field-value">
        <a data-qa-id="ac_client_fal_file_name_link" matTooltip="Uploaded by: {{rowData.userFullName}} &#013;&#010; {{(rowData.uploadDate | fixTimezoneOffset | date: 'MM/dd/yyyy - h:mm a')}}"
            (click)="attachmentToPreview(rowData)" pill-style="Internal Only">{{rowData?.fileName}}
        </a>
    </div>
    <div *ngIf="rowData.internalOnly && !editMode" class="field-value">
        <a data-qa-id="ac_client_fal_file_name_link" matTooltip="Uploaded by: {{rowData.userFullName}} &#013;&#010; {{(rowData.uploadDate | fixTimezoneOffset |date: 'MM/dd/yyyy - h:mm a')}}"
            pill-style="Internal Only">{{rowData?.fileName}}
        </a>
    </div>
</ng-template>
