import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    Validators
} from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Observable } from 'rxjs';
import { first, map, startWith, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ListItem } from 'src/app/shared/models/list-item';
import { Expertise, SpecialtyListItem } from 'src/app/shared/models/candidate';
import { CandidateService } from 'src/app/shared/services/candidate.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
    selector: 'ayac-expertise-form',
    templateUrl: './expertise-form.component.html',
    styleUrls: ['./expertise-form.component.scss']
})
export class ExpertiseFormComponent extends UnsubscribeOnDestroy implements OnInit {
    specialtyAutoCompleteControl = new UntypedFormControl();
    form: UntypedFormGroup;
    @Input() formGroupName = 'expertise';
    @Input() professionLabel = 'Profession';
    @Input() specialtiesLabel = 'Specialties';
    @Input() candidateId?: number;
    @Output() onChangeMade = new EventEmitter();
    dataPopulated = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    professions$: Observable<ListItem[]>;
    specialties: SpecialtyListItem[] = [];
    filteredSpecialties$: Observable<SpecialtyListItem[]>;
    @ViewChild('specialtyInput') specialtyInput: ElementRef<HTMLInputElement>;

    public get professionControl(): AbstractControl | undefined {
        return this.form?.get('profession');
    }

    public get specialtyIdsControl(): AbstractControl | undefined {
        return this.form?.get('specialtyIds');
    }

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly formGroupDirective: FormGroupDirective,
        private readonly candidateService: CandidateService
    ) {
        super();
        this.form = this.formBuilder.group({
            profession: this.formBuilder.control(null),
            specialtyIds: this.formBuilder.control([], Validators.required),
            specialties: this.formBuilder.array([])
        });
    }

    ngOnInit(): void {
        this.professions$ = this.candidateService.getProfessions();
        this.filteredSpecialties$ = this.specialtyAutoCompleteControl.valueChanges.pipe(
            startWith(null),
            map((filterSpecialtyText: string | null) => {
                const specialties = this.specialties.filter(
                    (x) => this.specialtyIdsControl.value.indexOf(x.specialtyId) < 0
                );
                return filterSpecialtyText
                    ? specialties.filter((x) =>
                          x.name.toLowerCase().includes(filterSpecialtyText.toString().toLowerCase())
                      )
                    : specialties.slice();
            })
        );

        this.formGroupDirective.form.addControl(this.formGroupName, this.form);
        this.form.setParent(this.formGroupDirective.form);
        this.professionControl.valueChanges.pipe(takeUntil(this.d$)).subscribe((profession) => {
            this.specialties = [];
            (this.form.get('specialties') as UntypedFormArray).clear();
            this.specialtyIdsControl.setValue([]);

            if (!this.professionControl?.value) {
                return;
            }

            this.candidateService
                .getSpecialties(profession)
                ?.pipe(first())
                .subscribe((specialties) => {
                    this.specialties = specialties;
                    this.specialtyAutoCompleteControl.setValue('');
                });
        });

        this.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((valueChange) => {
            if (!this.dataPopulated) {
                return;
            }

            this.onChangeMade.emit();
        });
    }

    populate(professionId: number, expertiseList: Expertise[]): void {
        this.professionControl.setValue(professionId);

        expertiseList?.forEach((expertise) => {
            this.addExpertise(expertise);
        });

        this.dataPopulated = true;
    }

    addExpertise(expertise: Expertise): void {
        const formArray = this.form.get('specialties') as UntypedFormArray;
        const form = this.formBuilder.group({
            id: this.formBuilder.control(expertise.specialtyId),
            professionId: this.formBuilder.control(expertise.professionId),
            yearsExperience: this.formBuilder.control(expertise.yearsExperience),
            candidateId: this.formBuilder.control(this.candidateId),
            name: this.formBuilder.control(expertise.specialtyDto?.name)
        });
        const sortedFormArray = formArray.controls.sort((controlA, controlB) => {
            const expertiseA = controlA.get('name').value;
            const expertiseB = controlB.get('name').value;
            return expertiseA < expertiseB ? -1 : 1;
        });

        const higherIndex = sortedFormArray.findIndex((x) => x.get('name').value > expertise.specialtyDto?.name);

        if (higherIndex === -1) {
            formArray.push(form);
        } else {
            formArray.insert(higherIndex, form);
        }

        const specialtyIds: number[] = this.specialtyIdsControl.value;
        if (higherIndex === -1) {
            specialtyIds.push(expertise.specialtyId);
        } else {
            specialtyIds.splice(higherIndex, 0, expertise.specialtyId);
        }

        this.specialtyIdsControl.setValue(specialtyIds);
    }

    getSpecialtyNameById(specialtyId: number): string {
        return this.specialties?.find((x) => x.specialtyId === specialtyId)?.name ?? '';
    }

    removeSpecialty(specialtyId: number): void {
        const specialtyIds: number[] = this.specialtyIdsControl.value;
        this.specialtyIdsControl.setValue(specialtyIds.filter((x) => x !== specialtyId));

        const formArray = this.form.get('specialties') as UntypedFormArray;
        const controlToRemove = formArray.controls.find(
            (control: UntypedFormGroup) => control.get('id').value === specialtyId
        );
        formArray.removeAt(formArray.controls.indexOf(controlToRemove));
        this.specialtyIdsControl.markAsTouched();

        this.specialtyAutoCompleteControl.setValue('');
        this.specialtyAutoCompleteControl.markAsTouched();
    }

    specialtySelected(event: MatAutocompleteSelectedEvent): void {
        const specialty = this.specialties.find((x) => x.specialtyId === +event.option.value);
        this.addExpertise({
            specialtyId: specialty.specialtyId,
            professionId: specialty.professionId,
            yearsExperience: 0,
            vendorCandidateId: this.candidateId,
            specialtyDto: {
                id: specialty.specialtyId,
                name: specialty.name,
                professionExpertiseCode: null
            }
        });

        if (this.specialtyInput?.nativeElement) {
            this.specialtyInput.nativeElement.value = '';
        }
        this.specialtyAutoCompleteControl.setValue(null);
        this.specialtyIdsControl.markAsTouched();
    }

    getExpertiseList(): Expertise[] {
        const result: Expertise[] = [];
        const specialties = this.form.get('specialties') as UntypedFormArray;
        if (!specialties || specialties.length === 0) {
            return result;
        }

        specialties.controls.forEach((specialtyFormGroup: UntypedFormGroup) => {
            result.push({
                yearsExperience: specialtyFormGroup.get('yearsExperience').value,
                professionId: specialtyFormGroup.get('professionId').value,
                vendorCandidateId: this.candidateId,
                specialtyId: specialtyFormGroup.get('id').value,
                specialtyDto: {
                    id: specialtyFormGroup.get('id').value,
                    name: specialtyFormGroup.get('name').value,
                    professionExpertiseCode: undefined
                }
            });
        });

        return result;
    }

    getSelectedProfession(): number {
        return this.professionControl.value;
    }

    addFilteredSpecialty(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        const specialty = this.specialties.find((x) => x.name.toLowerCase() === value?.toLowerCase());

        if (specialty) {
            this.addExpertise({
                specialtyId: specialty.specialtyId,
                professionId: specialty.professionId,
                yearsExperience: 0,
                vendorCandidateId: this.candidateId,
                specialtyDto: {
                    id: specialty.specialtyId,
                    name: specialty.name,
                    professionExpertiseCode: null
                }
            });
        }

        if (this.specialtyInput?.nativeElement) {
            this.specialtyInput.nativeElement.value = '';
        }
        this.specialtyAutoCompleteControl.setValue(null);
        this.specialtyIdsControl.markAsTouched();
    }
}
