import { FacilityProfile } from 'src/app/facilities/models/facility-profile.model';
import { createAction, props } from '@ngrx/store';

const enum FacilityProfileActionEnum {
    LoadFacilityProfile = '[Facilities Component] Load Facility Profile',
    LoadFacilityProfileSuccess = '[Facilities API] Load Facility Profile Success',
    LoadFacilityProfileFailure = '[Facilities API] Load Facility Profile Failure',

    UpdateTermSheetRequired = '[Facilities Component] Update Term Sheet Required',
    UpdateTermSheetRequiredSuccess = '[Facilities API] Update Term Sheet Required Success',
    UpdateTermSheetRequiredFailure = '[Facilities API] Update Term Sheet Required Failure'
}

export const loadFacilityProfile = createAction(FacilityProfileActionEnum.LoadFacilityProfile, props<{ id: number }>());
export const loadFacilityProfileSuccess = createAction(
    FacilityProfileActionEnum.LoadFacilityProfileSuccess,
    props<{ profile: FacilityProfile }>()
);
export const loadFacilityProfileFailure = createAction(FacilityProfileActionEnum.LoadFacilityProfileFailure);

export const updateTermSheetRequired = createAction(
    FacilityProfileActionEnum.UpdateTermSheetRequired,
    props<{ isRequired: boolean }>()
);
export const updateTermSheetRequiredSuccess = createAction(FacilityProfileActionEnum.UpdateTermSheetRequiredSuccess);
export const updateTermSheetRequiredFailure = createAction(
    FacilityProfileActionEnum.UpdateTermSheetRequiredFailure,
    props<{ isRequired: boolean }>()
);
