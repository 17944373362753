import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { FeatureSettingHintPipe } from 'src/app/shared/feature-settings/pipes/feature-setting-hint.pipe';
import { FeatureSettingsPresentationComponent } from 'src/app/shared/feature-settings/components/feature-settings-presentation/feature-settings-presentation.component';

@NgModule({
    declarations: [FeatureSettingsPresentationComponent, FeatureSettingHintPipe],
    imports: [CommonModule, SharedModule, ReactiveFormsModule],
    exports: [FeatureSettingsPresentationComponent]
})
export class FeatureSettingsModule {}
