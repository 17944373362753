<ng-container *ngIf="workHistory$ | async as workHistory">
    <mat-card class="work-history-card" data-qa-id="ac_client_smd_details_work-history" *ngIf="workHistory.length > 0">
        <mat-card-title class="title"><span>Work History</span></mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <table mat-table [dataSource]="workHistory" class="work-history-table"
                aria-label="List of candidate's work history">
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef scope="row"> Start Date </th>
                    <td mat-cell *matCellDef="let workHistory"> {{workHistory.employmentDateStart}} </td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef scope="row"> End Date </th>
                    <td mat-cell *matCellDef="let workHistory"> {{workHistory.employmentDateEnd}} </td>
                </ng-container>

                <ng-container matColumnDef="facility">
                    <th mat-header-cell *matHeaderCellDef scope="row" class="large-cell"> Facility Name </th>
                    <td mat-cell *matCellDef="let workHistory"> {{workHistory.facilityName}} </td>
                </ng-container>

                <ng-container matColumnDef="profession">
                    <th mat-header-cell *matHeaderCellDef scope="row"> Profession </th>
                    <td mat-cell *matCellDef="let workHistory"> {{workHistory.profession}} </td>
                </ng-container>

                <ng-container matColumnDef="specialty">
                    <th mat-header-cell *matHeaderCellDef scope="row"> Specialty </th>
                    <td mat-cell *matCellDef="let workHistory"> {{workHistory.specialty}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="workHistoryDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: workHistoryDisplayedColumns;"></tr>
            </table>
        </mat-card-content>
    </mat-card>
</ng-container>
