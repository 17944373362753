<dialog-layout data-qa-id="ac_client_smdc_dialog_container" title="Decline Candidate" class="submittal-decline">
    <div mat-dialog-content>
        <form [formGroup]="form">
            <div class="decline-grid">
                <div class="decline-form-label">
                    <strong>Reason *</strong>
                </div>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="reason">
                        <mat-option *ngFor="let declineReason of declineReasons$ | async" [value]="declineReason.id">
                            {{declineReason.reason}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="decline-form-label">
                    <strong>Notes *</strong>
                </div>
                <mat-form-field appearance="outline">
                    <textarea matInput maxlength="620" rows="4" formControlName="note">
                    </textarea>
                    <span class="text-length">{{noteLength}}/620</span>
                </mat-form-field>
            </div>
        </form>
    </div>
    <hr>
    <div mat-dialog-actions>
        <div>
            <button mat-flat-button data-qa-id="ac_client_smdc_decline_button" class="submittal-decline-button" (click)="declineCandidate()"
                [disabled]="!form.valid">Decline</button>
            <button mat-stroked-button data-qa-id="ac_client_smdc_canel_button" mat-dialog-close
                cdkFocusInitial>Cancel</button>
        </div>
    </div>
</dialog-layout>
