/**angular */
import { Injectable } from '@angular/core';

/**ngrx and rxjs */
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

/**local */
import * as actions from '../actions/system-contacts-profile.actions';
import * as selectors from '../selectors/system-contacts-profile.selectors';

import { SystemContactsService } from 'src/app/admin/system-contacts/services/system-contacts.service';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { SystemContact } from 'src/app/admin/system-contacts/models/system-contact.model';
import { SystemContactSave } from 'src/app/admin/system-contacts/models/system-contact-save.model';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Injectable()
export class SystemContactsProfileEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store,
        private readonly _service: SystemContactsService,
        private readonly _lookupsService: LookupsService,
        private readonly _router: Router,
        private readonly _dialogService: DialogService
    ) {}

    defaultContact: SystemContact = {
        id: null,
        systemId: 0,
        firstName: '',
        lastName: '',
        userId: null,
        emailAddress: '',
        showEmailExternally: false,
        phone: '',
        phoneCountryCode: '',
        phoneAreaCode: '',
        phoneExchange: '',
        phoneStation: '',
        phoneExtension: '',
        note: null,
        systemContactTypeTags: []
    };

    createNewSystemContactAndLoadRelatedData$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.createNewSystemContactAndLoadRelatedData),
            exhaustMap(() => {
                return forkJoin({
                    systemContact: of(this.defaultContact),
                    hospitalSystems: this._lookupsService.getHospitalSystems(),
                    systemContactTypes: this._service.getSystemContactTypes(),
                    identityProviders: this._service.getIdentityProviders()
                }).pipe(
                    map((response) => actions.loadSystemContactAndRelatedDataSuccess(response)),
                    catchError((error: unknown) => of(actions.loadSystemContactAndRelatedDataFailure({ error })))
                );
            })
        );
    });

    getSystemContractsProfileAndRelatedData$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadSystemContactAndRelatedData),
            exhaustMap((action) => {
                return forkJoin({
                    systemContact: this._service.getSystemContact(action.systemContactId),
                    hospitalSystems: this._lookupsService.getHospitalSystems(),
                    systemContactTypes: this._service.getSystemContactTypes(),
                    identityProviders: this._service.getIdentityProviders()
                }).pipe(
                    map((response) => actions.loadSystemContactAndRelatedDataSuccess(response)),
                    catchError((error: unknown) => of(actions.loadSystemContactAndRelatedDataFailure({ error })))
                );
            })
        );
    });

    saveSystemContactsProfile$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.saveSystemContactsProfile),
            concatLatestFrom(() => this._store$.select(selectors.selectSystemContactsProfile)),
            exhaustMap(([action, contact]) => {
                return this._service.saveSystemContact(this.convertSystemContactToRequestModel(contact)).pipe(
                    map((response: number | null) => {
                        return actions.saveSystemContactsProfileSuccess({
                            systemContactId: response,
                            contact
                        });
                    }),
                    catchError((error: unknown) => {
                        return of(actions.saveSystemContactsProfileFailure({ error, contact }));
                    })
                );
            })
        );
    });

    updateSystemContactsProfile$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.updateSystemContactsProfile),
            switchMap((action: { systemContact: SystemContact }) => {
                return of(actions.updateSystemContactsProfileSuccess({ systemContact: action.systemContact })).pipe(
                    catchError((error: unknown) => of(actions.updateSystemContactsProfileFailure({ error })))
                );
            })
        );
    });

    showSuccessMessage$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveSystemContactsProfileSuccess),
                map((action) => {
                    const actionType = action.contact?.id ? 'updated' : 'added';
                    this._dialogService.openMigratedSnackBarSuccess(
                        `${action.contact.firstName} ${action.contact.lastName} was ${actionType} successfully!`
                    );
                })
            );
        },
        { dispatch: false }
    );

    showErrorMessage$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveSystemContactsProfileFailure),
                map((action) => {
                    this._dialogService.openMigratedSnackBarErrorFromErrorResponse(
                        action.error,
                        `Error occurred trying to update ${action.contact.firstName} ${action.contact.lastName}.`
                    );
                    return action;
                })
            );
        },
        { dispatch: false }
    );

    routeToContacts$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveSystemContactsProfileSuccess),
                map(() => this._router.navigateByUrl('/admin/systemContacts'))
            );
        },
        { dispatch: false }
    );

    private convertSystemContactToRequestModel(systemContact: SystemContact): SystemContactSave {
        const result = {
            id: systemContact.id,
            firstName: systemContact.firstName,
            lastName: systemContact.lastName,
            userId: systemContact.userId,
            emailAddress: systemContact.emailAddress,
            showEmailExternally: systemContact.showEmailExternally,
            phoneCountryCode: systemContact.phoneCountryCode,
            phoneAreaCode: systemContact.phoneAreaCode,
            phoneExchange: systemContact.phoneExchange,
            phoneStation: systemContact.phoneStation,
            phoneExtension: systemContact.phoneExtension,
            note: systemContact.note,
            systemContactTypeTags: systemContact.systemContactTypeTags,
            user: systemContact.user,
            title: 'System Contact',
            systemId: systemContact.systemId
        } as SystemContactSave;
        return result;
    }
}
