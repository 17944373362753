import { Injectable } from '@angular/core';
import { MenuItemDirective } from 'src/app/core/app-side-nav/menu-item.directive';

@Injectable()
export class MenuService {
    private readonly loggedInUser: string = null;
    private readonly modules: MenuItemDirective[] = [];
    static publicRoutes = ['/', '/signin', '/signin/forgot-password', '/signin/reset-password'];

    private forEachSubItem(item: MenuItemDirective, callback: (subItem: MenuItemDirective) => void) {
        if (item.subItems.length) {
            for (const subItem of item.subItems) {
                callback(subItem);
            }
        }
    }

    register(menuItem: MenuItemDirective) {
        this.modules.push(menuItem);
    }

    getVisibleItems(): MenuItemDirective[] {
        return this.modules;
    }

    urlToModuleItem(url: string): { moduleName: string; itemName: string } {
        if (MenuService.publicRoutes.includes(url)) {
            return { moduleName: 'signin', itemName: null };
        }

        let result = { moduleName: null, itemName: null };

        if (url && url.length) {
            for (const menu of this.modules) {
                if (compareUrls(url, menu.url, menu.queryParams)) {
                    result = { moduleName: menu.name, itemName: null };
                }

                this.forEachSubItem(menu, (menuItem) => {
                    if (compareUrls(url, menuItem.url, menuItem.queryParams)) {
                        result = { moduleName: menu.name, itemName: menuItem.name };
                    }

                    this.forEachSubItem(menuItem, (menuSubItem) => {
                        if (compareUrls(url, menuSubItem.url, menuSubItem.queryParams)) {
                            result = { moduleName: menu.name, itemName: menuSubItem.name };
                        }
                    });
                });
            }
        }

        return result;
    }
}

function compareUrls(url1: string, url2: string, queryParams: any): boolean {
    const queryString = queryParams
        ? Object.entries(queryParams)
              .map((kvp) => `${kvp[0]}=${kvp[1]}`)
              .join('&')
        : null;
    return (
        url1 &&
        url2 &&
        url1.toLowerCase().startsWith(url2.toLowerCase()) &&
        (!queryString || url1.includes(queryString))
    );
}
