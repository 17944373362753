import { TravelersDetailsState } from './reducers/travelers-details.reducer';
import { TravelersListState } from './reducers/travelers-list.reducers';
import { TravelersDocumentsState } from './reducers/travelers-documents.reducers';
import { WorkOrderConfirmationState } from './reducers/work-order-confirmation.reducers';
import { ExtensionLogoState } from './reducers/travelers-extension-logo.reducer';
import { ExtensionDetailsState } from './reducers/travelers-extension-details.reducer';
import { ExtensionPostponeState } from './reducers/travelers-extension-postpone.reducer';
import { StaffingAdvisoryState } from './reducers/staffing-advisory.reducer';
import { StaffingAdvisoryAttachmentsState } from './reducers/staffing-advisory-attachments.reducer';
import { StaffingAdvisoryLookupsState } from './reducers/staffing-advisory-lookups.reducer';
import { StaffingAdvisoryEditState } from './reducers/staffing-advisory-edit.reducer';
import { StaffingAdvisorySecurityLookupsState } from './reducers/staffing-advisory-security-lookups.reducer';
import { StaffingAdvisoryDeleteState } from './reducers/staffing-advisory-delete.reducer';
import { StaffingAdvisoryAttachmentsPreviewState } from './reducers/staffing-advisory-attachments-preview.reducer';
import { CreateExtensionsState } from './reducers/create-extension.reducers';
import { TravelersImportCustomFieldsState } from './reducers/travelers-import-custom-fields.reducer';
import { TravelersDocumentDetailsState } from './reducers/travelers-document-details.reducer';
import { TravelersExtensionOfferState } from './reducers/travelers-extension-offer.reducer';
import { TravelersDocumentHistoryState } from './reducers/traveler-documents-history.reducer';

export const travelersStoreKey = 'client/travelers';

export interface TravelersState {
    list: TravelersListState;
    details: TravelersDetailsState;
    documents: TravelersDocumentsState;
    documentDetails: TravelersDocumentDetailsState;
    documentHistory: TravelersDocumentHistoryState;
    workOrderConfirmation: WorkOrderConfirmationState;
    extensionLogo: ExtensionLogoState;
    extensionDetails: ExtensionDetailsState;
    extensionPostpone: ExtensionPostponeState;
    staffingAdvisory: StaffingAdvisoryState;
    staffingAdvisoryEdit: StaffingAdvisoryEditState;
    staffingAdvisoryDelete: StaffingAdvisoryDeleteState;
    staffingAdvisoryLookups: StaffingAdvisoryLookupsState;
    staffingAdvisoryAttachments: StaffingAdvisoryAttachmentsState;
    staffingAdvisoryAttachmentsPreview: StaffingAdvisoryAttachmentsPreviewState;
    staffingAdvisorySecurityLookups: StaffingAdvisorySecurityLookupsState;
    createExtension: CreateExtensionsState;
    importCustomFields: TravelersImportCustomFieldsState;
    extensionOffer: TravelersExtensionOfferState;
}
