import { createAction, props } from '@ngrx/store';
import { CandidateEducation, CandidateQualifications, CandidateWorkHistory, SubmittalActivity } from '../../models';
import { NewSubmittalNote } from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';

export enum LeadsActionEnum {
    LoadCandidateEducation = '[SUBMITTAL EDUCATION COMPONENT] Load Candidate Education',
    LoadCandidateEducationSuccess = '[SUBMITTAL EDUCATION COMPONENT] Load Candidate Education Success',
    LoadCandidateEducationFail = '[SUBMITTAL EDUCATION COMPONENT] Load Candidate Education Failure',
    LoadCandidateWorkHistory = '[SUBMITTAL WORK HISTORY COMPONENT] Load Candidate Work History',
    LoadCandidateWorkHistorySuccess = '[SUBMITTAL WORK HISTORY COMPONENT] Load Candidate Work History Success',
    LoadCandidateWorkHistoryFail = '[SUBMITTAL WORK HISTORY COMPONENT] Load Candidate Work History Failure',
    LoadCandidateQualifications = '[SUBMITTAL EMR COMPONENT] Load Candidate Qualifications',
    LoadCandidateQualificationsSuccess = '[SUBMITTAL EMR COMPONENT] Load Candidate Qualifications Success',
    LoadCandidateQualificationsFail = '[SUBMITTAL EMR COMPONENT] Load Candidate Qualifications Failure',
    LoadSubmittalActivities = '[SUBMITTAL ACTIVITY COMPONENT] Load Submittal Activities',
    LoadSubmittalActivitiesSuccess = '[SUBMITTAL ACTIVITY COMPONENT] Load Candidate Submittal Activities Success',
    LoadSubmittalActivitiesFail = '[SUBMITTAL ACTIVITY COMPONENT] Load Candidate Submittal Activities Failure',
    AddSubmittalNote = '[SUBMITTAL ACTIVITY COMPONENT] Add Submittal Note',
    AddSubmittalNoteSuccess = '[SUBMITTAL ACTIVITY COMPONENT] Add Submittal Note Success',
    AddSubmittalNoteFail = '[SUBMITTAL ACTIVITY COMPONENT] Add Submittal Note Fail',
    LoadSubmittalCommunicationEnabled = '[SUBMITTAL ACTIVITY COMPONENT] Load Submittal Communication Enabled',
    LoadSubmittalCommunicationEnabledSuccess = '[SUBMITTAL ACTIVITY COMPONENT] Load Submittal Communication Enabled Success',
    LoadSubmittalCommunicationEnabledFail = '[SUBMITTAL ACTIVITY COMPONENT] Load Submittal Communication Enabled Failure'
}

export const loadCandidateEducation = createAction(
    LeadsActionEnum.LoadCandidateEducation,
    props<{ candidateId: number }>()
);

export const loadCandidateEducationSuccess = createAction(
    LeadsActionEnum.LoadCandidateEducationSuccess,
    props<{ education: CandidateEducation[] }>()
);

export const loadCandidateEducationFail = createAction(
    LeadsActionEnum.LoadCandidateEducationFail,
    props<{ error: unknown }>()
);

export const loadCandidateWorkHistory = createAction(
    LeadsActionEnum.LoadCandidateWorkHistory,
    props<{ candidateId: number }>()
);

export const loadCandidateWorkHistorySuccess = createAction(
    LeadsActionEnum.LoadCandidateWorkHistorySuccess,
    props<{ workHistory: CandidateWorkHistory[] }>()
);

export const loadCandidateWorkHistoryFail = createAction(
    LeadsActionEnum.LoadCandidateWorkHistoryFail,
    props<{ error: unknown }>()
);

export const loadCandidateQualifications = createAction(
    LeadsActionEnum.LoadCandidateQualifications,
    props<{ candidateId: number; isSubContractor: boolean }>()
);

export const loadCandidateQualificationsSuccess = createAction(
    LeadsActionEnum.LoadCandidateQualificationsSuccess,
    props<{ qualifications: CandidateQualifications }>()
);

export const loadCandidateQualificationsFail = createAction(
    LeadsActionEnum.LoadCandidateQualificationsFail,
    props<{ error: unknown }>()
);

export const loadSubmittalActivities = createAction(
    LeadsActionEnum.LoadSubmittalActivities,
    props<{ submittalId: number }>()
);

export const loadSubmittalActivitiesSuccess = createAction(
    LeadsActionEnum.LoadSubmittalActivitiesSuccess,
    props<{ activities: SubmittalActivity[] }>()
);

export const loadSubmittalActivitiesFail = createAction(
    LeadsActionEnum.LoadSubmittalActivitiesFail,
    props<{ error: unknown }>()
);

export const addSubmittalNote = createAction(
    LeadsActionEnum.AddSubmittalNote,
    props<{ submittalId: number; newSubmittalNote: NewSubmittalNote }>()
);

export const addSubmittalNoteSuccess = createAction(
    LeadsActionEnum.AddSubmittalNoteSuccess,
    props<{ submittalId: number }>()
);

export const addSubmittalNoteFail = createAction(LeadsActionEnum.AddSubmittalNoteFail, props<{ error: any }>());

export const loadSubmittalCommunicationEnabled = createAction(
    LeadsActionEnum.LoadSubmittalCommunicationEnabled,
    props<{ useAyaAccountManagement: boolean }>()
);

export const loadSubmittalCommunicationEnabledSuccess = createAction(
    LeadsActionEnum.LoadSubmittalCommunicationEnabledSuccess,
    props<{ communicationEnabled: boolean }>()
);

export const loadSubmittalCommunicationEnabledFail = createAction(
    LeadsActionEnum.LoadSubmittalCommunicationEnabledFail,
    props<{ error: any }>()
);
