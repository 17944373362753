<form method="POST"
    enctype="multipart/form-data"
    #fileForm
    (dragover)="updateDragState($event, true)"
    (dragleave)="updateDragState($event, false)"
    (drop)="getDroppedFiles($event)"
>
    <div class="drop-area" [ngClass]="{'drop-area-hover': isDragging}">
        <input type="file" id="file-input" #fileInput (change)="addSelectedFiles()" accept="acceptableFileTypes" [multiple]="isMultiple">
        <div class="drop-area-actions">
            <div class="drop-area-actions">
                <div class="drag-and-drop">
                    <div>
                        <mat-icon class="drag-and-drop-icon">backup</mat-icon>
                    </div>
                    <div class="drag-and-drop-text">
                        Drag & Drop
                    </div>
                </div>
                <ng-container *ngIf="showBrowseButton">
                    <div class="dnd-or">
                        OR
                    </div>
                    <div class="dnd-browse">
                        <button mat-raised-button (click)="openExplorer()" class="browse-button" data-qa-id="file-att-form-browse-btn">
                            Browse
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</form>
