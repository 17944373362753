import { Directive, Input, HostListener, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[afterValueChanged]'
})
export class AfterValueChangedDirective implements OnInit, OnDestroy {
    _valueChangeDelay = 300;

    @Output()
    public afterValueChanged: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    set valueChangeDelay(val) {
        this._valueChangeDelay = Number(val);
    }

    private stream: Subject<any> = new Subject<any>();
    private subscription: Subscription;

    constructor() {}

    ngOnInit() {
        this.subscription = this.stream
            .pipe(debounceTime(this._valueChangeDelay))
            .subscribe((value: any) => this.afterValueChanged.next(value));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    @HostListener('valueChange', ['$event'])
    public onValueChange(value: any): void {
        this.stream.next(value);
    }
}
