/**angular */
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

/**local */
import { CertificationLevel } from 'src/app/admin/vendor-details/certifications/models/certification-level.model';
import { Certification } from 'src/app/admin/vendor-details/certifications/models/certification.model';
import { VendorCertificationBase } from 'src/app/admin/vendor-details/certifications/models/vendor-certification-base.model';
import { EthnicityType } from '../../models/ethnicity-type.model';
import { expirationDateValidator } from 'src/app/shared/utilities';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-vendor-profile-certifications-detail',
    templateUrl: './vendor-profile-certifications-detail.component.html',
    styleUrls: ['./vendor-profile-certifications-detail.component.scss']
})
export class VendorProfileCertificationsDetailComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @Input() vendorId: number;
    @Input() certification: Certification = null;
    @Input() vendorCertification: VendorCertificationBase = {};
    @Input() locationLevels: CertificationLevel[];
    @Input() ethnicityTypes: EthnicityType[];
    @Input() isDiversity = false;

    @Output() vendorCertificationChange = new EventEmitter<any>();
    @Output() updateVendorCertification = new EventEmitter<any>();
    @Output() downloadFile = new EventEmitter<number>();

    certForm: UntypedFormGroup;
    @ViewChild('fileUpload') fileUpload: ElementRef;

    get dpkExpiration(): AbstractControl {
        return this.certForm.get('dpkExpiration');
    }

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.setCertForm();
    }

    get isCertificationTruthy() {
        return !!this.vendorCertification;
    }

    getSelectedLevel() {
        return this.locationLevels?.find((level) => {
            return level.id === this.vendorCertification?.level;
        });
    }

    getSelectedEthnicityType() {
        return this.ethnicityTypes?.find((ethnicity) => {
            return ethnicity.certificationEthnicityTypeId === this.vendorCertification?.ethnicity;
        });
    }

    certificateUpdated() {
        this.vendorCertification = this.UpdatedVendorCertificationObject;
        this.emitVendorCertification();
    }

    onFilesSelected(event) {
        if (event.target.files && event.target.files.length > 0) {
            let filesAdded = false;

            if (!this.vendorCertification.uploadFiles) {
                this.vendorCertification.uploadFiles = [];
            }

            [...event.target.files].forEach((f) => {
                if (f.size / 1024 / 1024 > 20) {
                    alert(`File '${f.name}' exceeds the maximum file size (20 MB).`);
                    return;
                }

                if (this.vendorCertification.uploadFiles.some((uf) => uf.name === f.name)) {
                    return;
                }

                this.vendorCertification.uploadFiles.push(f);
                filesAdded = true;
            });

            if (filesAdded) {
                this.emitVendorCertification();
            }
        }
    }

    get UpdatedVendorCertificationObject() {
        return {
            id: this.vendorCertification.id,
            certificationId: this.vendorCertification.certificationId,
            expiration: this.certForm?.get('dpkExpiration')?.value,
            level: this.certForm?.get('ddlLevel')?.value,
            location: this.certForm?.get('txtLocation')?.value,
            organization: this.certForm?.get('txtOrganization')?.value,
            ethnicity: this.certForm?.get('ddlEthnicity')?.value,
            files: this.vendorCertification.files,
            uploadFiles: this.vendorCertification.uploadFiles
        };
    }

    emitVendorCertification() {
        this.vendorCertificationChange.emit(this.vendorCertification);
        this.updateVendorCertification.emit(this.certForm);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.vendorCertification) {
            this.setCertForm();
        }
    }

    setCertForm() {
        this.certForm = this.fb.group({
            certificationId: this.fb.control(this.certification.id),
            dpkExpiration: this.fb.control(this.vendorCertification?.expiration, [Validators.required]),
            ddlLevel: this.fb.control(this.vendorCertification?.level),
            txtLocation: this.fb.control(this.vendorCertification?.location),
            txtOrganization: this.fb.control(this.vendorCertification?.organization),
            ddlEthnicity: this.fb.control(this.vendorCertification?.ethnicity)
        });

        const dpkExpirationControl = this.certForm.get('dpkExpiration');
        dpkExpirationControl.setValidators([Validators.required, expirationDateValidator]);
        dpkExpirationControl.updateValueAndValidity();
        this.toggleValidationForExpiration();
    }

    toggleValidationForExpiration() {
        this.certForm.controls.dpkExpiration.setValidators(
            this.certification.requiresExpiration
                ? [Validators.required, expirationDateValidator]
                : [expirationDateValidator]
        );

        this.certForm.controls.dpkExpiration.updateValueAndValidity();
    }

    removeFile(fileId: number) {
        if (confirm('This will permanently delete the file. Continue?')) {
            this.vendorCertification.files = this.vendorCertification.files.filter((x) => x.id !== fileId);
            this.emitVendorCertification();
        }
    }

    removeUploadFile(file: File) {
        this.vendorCertification.uploadFiles = this.vendorCertification.uploadFiles.filter((x) => x !== file);
        this.fileUpload.nativeElement.value = null;
        this.emitVendorCertification();
    }
}
