<grid-filter-popup
	[isFilterActive]="isFilterActive"
	[disableClear]="disableClear"
	[ngClass]="uniqueFilterClass"
	(onResetClick)="onResetClick()"
	(onFilterClick)="onFilterClick()"
	(onClose)="onPopupClose()">

	<grid-date-range-filter 
		[field]="field"
		[(filter)]="filter"
		[filterService]="filterService"
		(filterValueChanged)="filterValueChanged()"
		[submitOnChange]="false"
		[labelBefore]="labelBefore"
		[labelAfter]="labelAfter"
		[ngClass]="uniqueFilterClass + '--range'">
	</grid-date-range-filter>
	
</grid-filter-popup>
	