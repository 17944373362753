import { createAction, props } from '@ngrx/store';
import { FileAttachment, FileValidationResult } from 'src/app/shared/models/attachment';
import { ResourceDocument } from '../../models';

export const loadResourceDocuments = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Documents',
    props<{ resourceId: number }>()
);
export const loadResourceDocumentsSuccess = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Documents Success',
    props<{ documents: ResourceDocument[] }>()
);

export const loadResourceDocumentsFail = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Documents Failed',
    props<{ error: any }>()
);

export const deleteResourceDocument = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Delete Resource Documents',
    props<{ resourceId: number; id: number }>()
);
export const deleteResourceDocumentsuccess = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Delete Resource Documents Success',
    props<{ resourceId: number; id: number }>()
);
export const deleteResourceDocumentFail = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Delete Resource Documents Fail',
    props<{ error: any }>()
);

export const saveResourceDocument = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Save Resource Document',
    props<{ resourceId: number; fileToUpload: File; internalOnly?: boolean }>()
);
export const saveResourceDocumentSuccess = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Save Resource Document Success',
    props<{ fileValidationResult: FileValidationResult[]; resourceId: number }>()
);
export const saveResourceDocumentFail = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Save Resource Document Fail',
    props<{ fileValidationResult: FileValidationResult; error: any }>()
);

export const loadResourceDocument = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Resource Document',
    props<{ resourceId: number; id: number; fileName?: string }>()
);
export const loadResourceDocumentsuccess = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Resource Document Success'
);
export const loadResourceDocumentFail = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Resource Document Fail',
    props<{ error: any }>()
);

export const updateResourceDocument = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Update Resource Documents',
    props<{ resourceId: number; fileToUpdate: FileAttachment; internalOnly: boolean }>()
);
export const updateResourceDocumentsuccess = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Update Resource Documents Success',
    props<{ resourceId: number }>()
);
export const updateResourceDocumentFail = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Update Resource Documents Fail',
    props<{ error: any }>()
);

export const loadPreviewResourceDocuments = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Preview',
    props<{ resourceId: number; id: number; pageNum: number; file: FileAttachment }>()
);
export const loadPreviewResourceDocumentsSuccess = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Preview Success',
    props<{ documentPreview: Blob }>()
);
export const loadPreviewResourceDocumentsFail = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Preview Fail',
    props<{ error: any }>()
);
export const loadPreviewNextPage = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Preview Next Page',
    props<{ pageNum: number }>()
);

export const loadPreviewResourcePageCount = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Preview Page Count Resource Documents',
    props<{ resourceId: number; id: number }>()
);
export const loadPreviewResourcePageCountSuccess = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Preview Page Count Resource Documents Success',
    props<{ total: number }>()
);
export const loadPreviewResourcePageCountFail = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Load Preview Page Count Resource Documents Fail',
    props<{ error: any }>()
);

export const clearResourceDocuments = createAction('[INTERNAL POOL RESOURCE DOCUMENTS] Clear Resource Documents');
export const clearResourceDocumentPreview = createAction(
    '[INTERNAL POOL RESOURCE DOCUMENTS] Clear Resource Document Preview'
);
