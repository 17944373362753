import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';
import * as StaffingAdvisoryActions from 'src/app/travelers/state/actions/staffing-advisory.actions';
import * as StaffingAdvisorySelectors from 'src/app/travelers/state/index';
import { ToasterService } from 'src/app/core/services';
import { TravelersService } from 'src/app/travelers/services/travelers.service';
import { StaffingAdvisoryService } from 'src/app/travelers/services/staffing-advisory.service';
import { ListItem } from 'src/app/shared/models/list-item';
import { Store } from '@ngrx/store';
import { TravelersState } from 'src/app/travelers/state/travelers.state';

@Injectable()
export class StaffingAdvisoryEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<TravelersState>,
        private readonly travelersService: TravelersService,
        private readonly staffingAdvisoryService: StaffingAdvisoryService,
        private readonly toaster: ToasterService
    ) {}

    getStaffingAdvisory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StaffingAdvisoryActions.loadStaffingAdvisories),
            switchMap((action) => {
                return this.travelersService
                    .getStaffingAdvisories(action.systemId, action.facilityId, action.unitId, action.travelerId)
                    .pipe(
                        map((response) =>
                            StaffingAdvisoryActions.loadStaffingAdvisoriesSuccess({ staffingAdvisories: response })
                        ),
                        catchError((error) => of(StaffingAdvisoryActions.loadStaffingAdvisoriesFail({ error })))
                    );
            })
        )
    );

    staffingAdvisoryError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    StaffingAdvisoryActions.loadStaffingAdvisoriesFail,
                    StaffingAdvisoryActions.loadStaffingAdvisoriesSecurityLookupsFail
                ),
                switchMap((action) => {
                    const error = action.error;
                    return this.toaster.fail('ERROR:', error.status + ' ' + error.error);
                })
            ),
        { dispatch: false }
    );

    saveStaffingAdvisory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StaffingAdvisoryActions.saveStaffingAdvisory),
            switchMap((action) => {
                return this.staffingAdvisoryService.addStaffingAdvisory(action.staffingAdvisory).pipe(
                    map((response) =>
                        action.files && action.files.length
                            ? StaffingAdvisoryActions.saveStaffingAdvisoryAttachments({
                                  staffingAdvisoryId: response,
                                  filesToUpload: action.files
                              })
                            : StaffingAdvisoryActions.saveStaffingAdvisorySuccess({
                                  staffingAdvisoryId: response,
                                  files: action.files
                              })
                    ),
                    catchError((error) => of(StaffingAdvisoryActions.saveStaffingAdvisoryFail({ error })))
                );
            })
        )
    );

    saveStaffingAdvisorySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(StaffingAdvisoryActions.saveStaffingAdvisorySuccess),
                tap((action) => {
                    this.toaster.success(`Staffing Advisory ${action.staffingAdvisoryId} Saved`);
                })
            ),
        { dispatch: false }
    );

    saveStaffingAdvisoryFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(StaffingAdvisoryActions.saveStaffingAdvisoryFail),
                tap((action) => {
                    console.error(action.error);
                    this.toaster.fail(
                        'A problem occurred while persisting staffing advisory. Please contact support for further assistance.'
                    );
                })
            ),
        { dispatch: false }
    );

    loadStaffingAdvisoryLookups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StaffingAdvisoryActions.loadStaffingAdvisoryLookups),
            switchMap((action) => {
                const lookupActions: Array<Observable<ListItem[]>> = [
                    this.staffingAdvisoryService.getCategories(),
                    this.staffingAdvisoryService.getReasons(),
                    this.staffingAdvisoryService.getRestrictionLevels()
                ];
                return forkJoin(lookupActions);
            }),
            switchMap((lookups) =>
                of(
                    StaffingAdvisoryActions.loadStaffingAdvisoryLookupsSuccess({
                        categories: lookups[0],
                        reasons: lookups[1],
                        restrictionLevels: lookups[2]
                    })
                )
            ),
            catchError((error) => of(StaffingAdvisoryActions.loadStaffingAdvisoryLookupsFail({ error })))
        )
    );

    loadStaffingAdvisoryLookupsFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(StaffingAdvisoryActions.loadStaffingAdvisoryLookupsFail),
                tap((action) => {
                    console.error(action.error);
                })
            ),
        { dispatch: false }
    );

    loadStaffingAdvisorySecurityLookups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StaffingAdvisoryActions.loadStaffingAdvisoriesSecurityLookups),
            switchMap((action) => {
                return this.staffingAdvisoryService.getSecurityLookups().pipe(
                    map((response) =>
                        StaffingAdvisoryActions.loadStaffingAdvisoriesSecurityLookupsSuccess({
                            system: response.systems[0],
                            systems: response.systems,
                            facilities: response.facilities.sort((a, b) =>
                                a.profileGroupName.localeCompare(b.profileGroupName)
                            ),
                            units: response.units.sort((a, b) => a.name.localeCompare(b.name))
                        })
                    ),
                    catchError((error) => of(StaffingAdvisoryActions.loadStaffingAdvisoriesSecurityLookupsFail(error)))
                );
            })
        )
    );

    deleteStaffingAdvisory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StaffingAdvisoryActions.deleteStaffingAdvisory),
            withLatestFrom(this.store$.select(StaffingAdvisorySelectors.selectTravelerDetails)),
            switchMap(([action, travelerDetails]) => {
                return this.staffingAdvisoryService.deleteStaffingAdvisory(action.staffingAdvisoryId).pipe(
                    switchMap((response) => [
                        StaffingAdvisoryActions.deleteStaffingAdvisorySuccess({
                            staffingAdvisoryId: action.staffingAdvisoryId
                        }),
                        StaffingAdvisoryActions.loadStaffingAdvisories({
                            travelerId: travelerDetails.traveler.travelerId,
                            systemId: travelerDetails.traveler.hospSystemId,
                            facilityId: travelerDetails.traveler.facilityId,
                            unitId: travelerDetails.traveler.unitId
                        })
                    ]),
                    catchError((error) => of(StaffingAdvisoryActions.deleteStaffingAdvisoryFail({ error })))
                );
            })
        )
    );

    deleteStaffingAdvisorySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(StaffingAdvisoryActions.deleteStaffingAdvisorySuccess),
                tap((action) => {
                    this.toaster.success(`Staffing Advisory ${action.staffingAdvisoryId} deleted`);
                })
            ),
        { dispatch: false }
    );

    deleteStaffingAdvisoryFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(StaffingAdvisoryActions.deleteStaffingAdvisoryFail),
                tap((action) => {
                    console.error(action.error);
                    this.toaster.fail(
                        'A problem occurred while removing staffing advisory. Please contact support for further assistance.'
                    );
                })
            ),
        { dispatch: false }
    );
}
