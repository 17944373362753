import { createAction, props } from '@ngrx/store';

export const loadWocDetails = createAction('[Travelers] Load WOC Details', props<{ id: number }>());
export const loadWorkOrderConfirmationPDF = createAction(
    '[Travelers] Load Work Order Confirmation',
    props<{ id: number }>()
);
export const workOrderConfirmationPDFSuccess = createAction(
    '[Travelers] Work Order Confirmation PDF Success',
    props<{ image: Blob }>()
);
export const workOrderConfirmationPDFError = createAction(
    '[Travelers] Work Order Confirmation PDF Error',
    props<{ error: any }>()
);

export const postAgreeWoc = createAction('[Travelers] Post Woc Agreement', props<{ contractId: number }>());
export const postAgreeWocSuccess = createAction('[Travelers] Post Woc Agreement Successful');
export const postAgreeWocError = createAction('[Travelers] Post Woc Agreement Error', props<{ error: any }>());
