<!-- Deprecated, can be removed once the feature flag with key REPLACE_CURRENT_HELP_SECTION_WITH_PENDO_RESOURCE_CENTER is removed -->
<ng-container *ifLdFeature="featureFlag.ReplaceCurrentHelpSectionWithPendoResourceCenter; enabled: true">
    <ayac-nav-menu-link [isExpanded]="isExpanded" [linkData]="linkData" popupPosition="above">

        <div class="pa-2" [attr.data-qa-id]="'PreviousHelpSection'">
            <mat-divider class="my-2"></mat-divider>

            <p class="my-2">
                <a href="{{privacyUrl}}" target="_blank" class="link">
                    Privacy Policy
                </a>
            </p>
            <p class="my-2">
                <a href="{{termsOfServiceUrl}}" target="_blank" class="link">
                    Terms of Use
                </a>
            </p>
            <p class="my-2">
                <a href="{{electronicConsentUrl}}" target="_blank" class="link">
                    Electronic Consent
                </a>
            </p>
            <p class="my-2" class="copyright">
                &copy; {{ copyright }}
            </p>
            <p class="my-2">
                <app-version-number></app-version-number>
            </p>
        </div>
    </ayac-nav-menu-link>
</ng-container>

<ng-container *ifLdFeature="featureFlag.ReplaceCurrentHelpSectionWithPendoResourceCenter; enabled: false">
    <div class="menu-item">
        <div class="menu-item__content">
            <div class="menu-item__icon menu-icon-mat" [attr.data-qa-id]="'ConnectResourceCenterIcon'">
                <mat-icon [attr.data-qa-id]="'connect-resource-center'">{{ linkData?.icon }}</mat-icon>
            </div>
        </div>
    </div>
</ng-container>
