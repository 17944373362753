import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import {
    loadSubmittalsLinesOfBusiness,
    loadSubmittalsLinesOfBusinessSucesss,
    loadSubmittalsLinesOfBusinessFail
} from 'src/app/lookups/state/actions';

export interface SubmittalsLinesOfBusinessState {
    linesOfBusiness: Array<ListItem>;
    loading: LoadingTypes;
    error: unknown;
}

export const initialSubmittalsLinesOfBusinessState: SubmittalsLinesOfBusinessState = {
    linesOfBusiness: [],
    loading: LoadingTypes.INIT,
    error: null
};

export function submittalsLinesOfBusinessReducer(state: SubmittalsLinesOfBusinessState, action: Action) {
    return createReducer(
        initialSubmittalsLinesOfBusinessState,
        on(loadSubmittalsLinesOfBusiness, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadSubmittalsLinesOfBusinessSucesss, (state, action) => ({
            ...state,
            linesOfBusiness: action.linesOfBusiness,
            loading: LoadingTypes.LOADED
        })),
        on(loadSubmittalsLinesOfBusinessFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: SubmittalsLinesOfBusinessState | undefined, action: Action) {
    return submittalsLinesOfBusinessReducer(state, action);
}
