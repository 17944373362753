import { Pipe, PipeTransform } from '@angular/core';
import { JobFacilityGroup } from 'src/app/admin/jobs/models/job-facility-group.model';
import { JobGridItem } from 'src/app/admin/jobs/models/job-grid-item.model';

@Pipe({
    name: 'releaseProgress'
})
export class ReleaseProgressPipe implements PipeTransform {
    transform(dataItem: JobGridItem, facilityGroups: JobFacilityGroup[]): { class: string; percentage: number } {
        const progress = this._getReleaseProgress(dataItem, facilityGroups);
        const textClass = this._getReleaseProgressClass(progress);

        return {
            class: textClass,
            percentage: progress
        };
    }

    private _getReleaseProgress(dataItem: JobGridItem, facilityGroups: JobFacilityGroup[]): number {
        if (!facilityGroups) {
            return 0;
        }

        for (const group of facilityGroups) {
            if (!group.facilities) {
                continue;
            }

            for (const facility of group.facilities) {
                if (facility.id === dataItem.facilityId) {
                    return facility.acceptedVendorCount > 0 &&
                        facility.acceptedVendorCount >= dataItem.acceptedReleaseVendors
                        ? Math.round((dataItem.acceptedReleaseVendors / facility.acceptedVendorCount) * 100)
                        : 100;
                }
            }
        }

        return null;
    }

    private _getReleaseProgressClass(progress: number): string {
        if (!progress) {
            return 'muted';
        }

        if (progress > 0 && progress <= 50) {
            return 'danger';
        }

        if (progress > 50 && progress < 100) {
            return 'warning';
        }

        return 'success';
    }
}
