import { Component, Input, ViewChild } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { ComparisonFilter } from '../../models/comparison-filter.model';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'greater-than-less-than-equals-cell',
    templateUrl: './greater-than-less-than-equals.component.html',
    styleUrls: ['./greater-than-less-than-equals.component.scss']
})
export class GreaterThanLessThanEqualsComponent extends BaseFilterCellComponent {
    @Input() public field: string;
    @Input() public options: any;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() uniqueFilterClass: string;

    @ViewChild('dropDownInput', { static: true }) DropDownInput: DropDownListComponent;
    @ViewChild('textBoxInput', { static: true }) TextBoxInput: NumericTextBoxComponent;

    defaultComparisonFilter: ComparisonFilter = {
        type: null,
        value: null
    };

    public defaultItem: { text: string; value: string | null } = {
        text: 'Select...',
        value: null
    };

    public listItems: Array<{ text: string; value: string | null }> = [
        { text: 'Equals (=)', value: 'equals' },
        { text: 'Less (<)', value: 'lessThan' },
        { text: 'Greater (>)', value: 'greaterThan' }
    ];

    public get comparisonFilter(): ComparisonFilter {
        if (!this.filterFields) {
            const filter = this.defaultComparisonFilter;
            Object.keys(filter).forEach((key) => {
                const filterField = this.filterByField(`${this.field}.${key}`);
                filter[key] = filterField ? filterField.value : null;
            });

            this.filterFields = filter;
        }

        return this.filterFields;
    }

    private filterFields: ComparisonFilter;

    public get selectedValue(): any {
        const filter = this.filterByField(this.field);
        return filter ? filter.value : null;
    }

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public selectionChange(option: any): void {
        this.filterFields.type = option.value;
        if (this.filterFields.type && this.filterFields.value === null) {
            this.TextBoxInput.focus();
        } else if (this.filterFields.type && this.filterFields.value !== null) {
            this.applyChangedFilter();
        } else {
            this.filterFields.value = null;
            this.TextBoxInput.value = null;
            this.TextBoxInput['elementValue'] = '';
            this.applyChangedFilter();
        }
    }

    public onAfterValueChange(value: number): void {
        this.filterFields.value = value;
        if (this.filterFields.type != null) {
            if (this.filterFields.value === null) {
                this.filterFields.type = null;
                this.DropDownInput.writeValue(this.defaultItem.value);
            }
            this.applyChangedFilter();
        }
    }

    applyChangedFilter() {
        Object.keys(this.filterFields).forEach((key) => {
            this.applyFilter(
                this.filterFields[key] === null
                    ? this.removeFilter(`${this.field}.${key}`)
                    : this.updateFilter({
                          field: `${this.field}.${key}`,
                          operator: 'eq',
                          value: this.filterFields[key]
                      })
            );
        });
    }
}
