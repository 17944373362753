<dialog-layout title="Release History">
    <div mat-dialog-content>
        <table mat-table matSort multiTemplateDataRows [dataSource]="releaseHistory" class="mat-elevation-z0" aria-label="A list of job release history.">

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                <td mat-cell *matCellDef="let item">
                    {{ item?.isReleased ? 'Released' : 'Revoked' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="method">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Method</th>
                <td mat-cell *matCellDef="let item">
                    <span [title]="item?.scheduledReleaseRule ? item?.scheduledReleaseRule.name : ''">
                        {{ item?.isManual ? 'Manual' : 'Scheduled' }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="agency">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>
                        <span>Agency</span>
                        <button mat-icon-button [matMenuTriggerFor]="searchMenu" (click)="$event.stopPropagation();">
                            <mat-icon>filter_alt</mat-icon>
                        </button>

                        <mat-menu class="job-release-search-menu" #searchMenu="matMenu" yPosition="above">
                            <div (click)="$event.stopPropagation();">
                                <div class="search-header">
                                    <span>Search</span>
                                </div>
                                <input class="search-input" type="text" placeholder="Enter text to search" (keyup)="filterAgency($event)">
                            </div>
                        </mat-menu>
                    </div>
                </th>
                <td mat-cell *matCellDef="let item">
                    <span [title]="item?.vendor.legalName">
                        {{ item?.vendor.operatingName }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="when">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>When</th>
                <td mat-cell *matCellDef="let item">
                    {{ item?.updatedDate | date:'MM/dd/YYYY hh:mm a'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="who">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Who</th>
                <td mat-cell *matCellDef="let item">
                    {{ item?.updatedBy }}
                </td>
            </ng-container>

            <tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="5">No data found.</td>
            </tr>

        </table>
    </div>
</dialog-layout>
