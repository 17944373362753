import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { emailValidator } from 'src/app/shared/utilities';
import { PhoneType } from 'src/app/shared/models/candidate';
import { VendorEmail } from '../models/vendor.model';
import { VendorService } from '../services/vendor.service';
import { VendorProfileService } from '../vendor-profile.service';

@Component({
    selector: 'ayac-vendor-emails',
    templateUrl: './vendor-emails.component.html',
    styleUrls: ['./vendor-emails.component.scss']
})
export class VendorEmailsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    get emailsFormArray(): UntypedFormArray {
        return this.form.get('emails') as UntypedFormArray;
    }
    form: UntypedFormGroup;
    emailTypes: PhoneType[] = [];
    @Input() vendorId?: number;
    @Input() saveCalled?: Subject<void>;
    @Input() isSaving: boolean = false;
    @Output() onSaveSuccess = new EventEmitter();
    @Output() onSaveError = new EventEmitter<string>();
    @Output() onChangesMade = new EventEmitter();
    emailIdsToDelete: number[] = [];
    isPopulated = false;
    private getEmailsSubscription?: Subscription;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly vendorService: VendorService,
        private readonly vendorProfileService: VendorProfileService
    ) {
        super();
        this.form = this.formBuilder.group({
            emails: this.formBuilder.array([])
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.vendorId) {
            this.reloadEmails();
        }
    }

    ngOnInit(): void {
        this.vendorProfileService
            .getEmailTypes()
            .pipe(takeUntil(this.d$))
            .subscribe((emailTypes) => (this.emailTypes = emailTypes));

        this.saveCalled?.pipe(takeUntil(this.d$))?.subscribe((_) => {
            this.save();
        });
    }

    addEmail(email?: VendorEmail): void {
        const form = this.formBuilder.group({
            id: this.formBuilder.control(email?.id),
            emailAddress: this.formBuilder.control(email?.emailAddress, emailValidator),
            vendorEmailTypeId: this.formBuilder.control(
                email?.vendorEmailTypeId ?? this.emailTypes?.find((x) => x)?.id
            ),
            note: this.formBuilder.control(email?.note)
        });

        this.emailsFormArray.push(form);
    }

    getEmails(): VendorEmail[] {
        const result: VendorEmail[] = [];
        this.emailsFormArray.controls.forEach((form: UntypedFormGroup) => {
            if (!form.get('emailAddress').value) {
                return;
            }

            result.push({
                id: form.get('id').value,
                vendorId: this.vendorId,
                emailAddress: form.get('emailAddress').value,
                vendorEmailTypeId: form.get('vendorEmailTypeId').value,
                note: form.get('note').value,
                vendorEmailType: this.emailTypes.find((x) => x.id === form.get('vendorEmailTypeId').value)
            });
        });

        return result;
    }

    removeEmail(index: number): void {
        const emailId = this.emailsFormArray.at(index).get('id').value;
        if (emailId) {
            this.emailIdsToDelete.push(emailId);
        }

        this.emailsFormArray.removeAt(index);
    }

    save(): void {
        const emails = this.getEmails();
        const saveCalls: Observable<VendorEmail>[] = [];
        this.emailIdsToDelete.forEach((x) => saveCalls.push(this.vendorService.deleteEmail(x)));
        emails.forEach((email) => {
            if (email.id) {
                saveCalls.push(this.vendorService.updateEmail(email));
            } else {
                saveCalls.push(this.vendorService.addEmail(email));
            }
        });

        forkJoin(saveCalls)
            .pipe(first())
            .subscribe(
                (saveResults) => {
                    this.onSaveSuccess.emit();
                    this.reloadEmails();
                },
                (error) => {
                    this.onSaveError.emit('An error occured while saving vendor emails. Please try again later.');
                }
            );
    }

    reloadEmails(): void {
        if (this.isSaving) {
            return;
        }

        this.getEmailsSubscription?.unsubscribe();

        if (!this.vendorId) {
            this.getEmailsSubscription = this.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((valueChange) => {
                this.onChangesMade.emit();
            });
            return;
        }

        this.isPopulated = false;
        this.emailsFormArray.clear();

        this.vendorProfileService
            .getEmails(this.vendorId)
            .pipe(first())
            .subscribe((emails) => {
                emails.forEach((email) => this.addEmail(email));
                this.isPopulated = true;

                setTimeout(() => {
                    this.getEmailsSubscription = this.form.valueChanges
                        .pipe(takeUntil(this.d$))
                        .subscribe((valueChange) => {
                            this.onChangesMade.emit();
                        });
                });
            });
    }
}
