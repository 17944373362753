import { Pipe, PipeTransform } from '@angular/core';
import { CustomSystemField, CustomSystemFieldLookup } from 'src/app/core/models/system-custom-field.model';
import { ListItem } from 'src/app/shared/models/list-item';

@Pipe({
    name: 'customFieldIncludes'
})
export class CustomFieldIncludesPipe implements PipeTransform {
    transform(customFieldLookup?: CustomSystemFieldLookup, customFields?: CustomSystemField[]): ListItem[] {
        if (!customFieldLookup || !customFields) {
            return [];
        }
        const customFieldId = customFieldLookup.id;
        const itemCustomField = customFields.find((item) => item.customFieldId === customFieldId);
        if (!itemCustomField) {
            return [];
        }

        const result = itemCustomField.value.map((id) => customFieldLookup.fieldValues.find((fv) => fv.id === id));

        return result;
    }
}
