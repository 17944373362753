
<div>
    <mat-error *ngIf="displayWarning$ | async">
        Candidate is not credentialed. Once credentialed, the Send button is enabled
    </mat-error>
</div>

<ayac-form-control-wrapper
    controlLabel="Email Set Up Instructions">
    <div class="send-button-container">

        <button mat-flat-button
            [disabled]="disableSendButton$ | async"
            color="accent"
            (click)="onSend()">
            <mat-icon>send</mat-icon>
            Send
        </button>

        <mat-spinner
            *ngIf="sendEmailLoading$ | async"
            color="accent"
            diameter="20">
        </mat-spinner>
    </div>
</ayac-form-control-wrapper>

<ayac-form-control-wrapper
    *ngIf="inviteSent$ | async as inviteSent"
    controlLabel="Date/Time Sent">

    <div>
        {{ inviteSent | date : "MM/dd/yy hh:mm a"}}
    </div>
</ayac-form-control-wrapper>
