<!-- jobAttachmentsViewer  fileAttachmentsViewer -->
<loading-indicator [bgOverlay]="false" *ngIf="isLoading || isRendering || (isPreviewLoading$ | async)"></loading-indicator>
<dialog-layout [title]="title" data-qa-id="ac_client_fav_dialog_container" [upperButtonTitle]="upperButtonTitle" (upperButtonClicked)="onDownloadAttachment()">

    <div *ngIf="attachmentPreview" class="view-style-attachment" mat-dialog-content>
        <img class="preview-img" [src]='attachmentPreview' alt="Avatar">
    </div>
    <div *ngIf="!attachmentPreview && !isLoading" class="view-style-attachment no-viewer"
        mat-dialog-content>
        <div class="drag-drop-display" *ngIf="!(isPreviewLoading$ | async)">
            <h3>No preview available.</h3>
            <button mat-flat-button color="accent" data-qa-id="ac_client_fav_download_button" (click)="onDownloadAttachment()" class="mr-2">
                <span class="fas fa-download"></span>
                Download
            </button>
        </div>
    </div>
    <div *ngIf="!attachmentPreview && isLoading" class="place-holder" mat-dialog-content>
    </div>
    <div mat-dialog-actions class="modal-footer-right">
        <div id="pager" class="pager-attachment" *ngIf="totalPages?.length > 1">
            <ul id="pagination" class="pagination-sm pagination">
                <li class="page-item first">
                    <a data-qa-id="ac_client_fav_first_page_link" (click)="onPageClicked(1)" class="page-link">First</a></li>
                <li class="page-item prev">
                    <a data-qa-id="ac_client_fav_prev_page_link" (click)="onPrev(selectedPage)" class="page-link">Previous</a></li>
                <li *ngFor="let i of totalPages" [ngClass]="(selectedPage == i) ?'page-item active':'page-item'">
                    <a data-qa-id="ac_client_fav_page_link" (click)="onPageClicked(i)" class="page-link">{{i}}</a>
                </li>
                <li class="page-item next"><a data-qa-id="ac_client_fav_next_page_link" (click)="onNext(selectedPage)" class="page-link">Next</a></li>
                <li class="page-item last"><a data-qa-id="ac_client_fav_last_page_link" (click)="onPageClicked(totalPages.length)" class="page-link">Last</a></li>
            </ul>
        </div>
        <div>
            <button mat-stroked-button color="accent" data-qa-id="ac_client_fav_cancel_button" (click)="onCancel()" mat-dialog-close>
                Cancel
            </button>
        </div>
    </div>
</dialog-layout>
