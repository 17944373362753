import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { SubmittalActivity } from 'src/app/submittals/models';
import { loadSubmittalActivities } from 'src/app/submittals/store/actions/submittals-leads.actions';
import { selectSubmittalActivities, getSubmittalLookupsStatuses } from 'src/app/submittals/store/submittals.selectors';
import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';
import { SubmittalStatusLookup } from '../../../../shared/models/submittals';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import * as submittalsSelectors from 'src/app/submittals/store/submittals.selectors';

@Component({
    selector: 'ayac-submittal-activity-section',
    templateUrl: './submittal-activity-section.component.html',
    styleUrls: ['./submittal-activity-section.component.scss']
})
export class SubmittalActivitySectionComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() submittalId: number;
    activitiesDisplayedColumns = ['activityDate', 'activityOwnerFullName', 'statusId', 'notes'];
    activities$: Observable<SubmittalActivity[]>;
    statuses: SubmittalStatusLookup[] = [];
    featureFlag = FeatureFlag;
    isPendingOverdue$: Observable<boolean>;
    localTimeZoneName: string = '';

    constructor(
        private readonly store: Store,
        private readonly _featureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit() {
        this.activitiesDisplayedColumns = ['activityDate', 'activityOwnerFullName', 'statusId', 'notes'];

        this.store.dispatch(loadSubmittalActivities({ submittalId: this.submittalId }));
        this.activities$ = this.store.select(selectSubmittalActivities);

        this.store
            .select(getSubmittalLookupsStatuses)
            .pipe(takeUntil(this.d$))
            .subscribe((statuses) => {
                this.statuses = statuses;
            });

        this.isPendingOverdue$ = this.store.select(submittalsSelectors.selectIsPendingOverdue);
        this.localTimeZoneName = this.getLocalTimezoneName();
    }

    getStatusName(statusId: number): string {
        return this.statuses.find((x) => x.id === statusId)?.name ?? '';
    }

    getLocalTimezoneName() {
        const localDate = new Date();
        const shortLocalDate = localDate.toLocaleDateString(undefined);
        const fullLocalDate = localDate.toLocaleDateString(undefined, { timeZoneName: 'long' });

        let timeZoneAcronym = fullLocalDate;

        const shortIndex = fullLocalDate.indexOf(shortLocalDate);
        if (shortIndex >= 0) {
            const longTimeZoneName =
                fullLocalDate.substring(0, shortIndex) + fullLocalDate.substring(shortIndex + shortLocalDate.length);

            timeZoneAcronym = longTimeZoneName.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
            const upperCaseLettersMatches = timeZoneAcronym.match(/\b(\w)/g);
            timeZoneAcronym = upperCaseLettersMatches.join('');
        }

        return timeZoneAcronym;
    }
}
