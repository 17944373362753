<div class="top-toolbar">
    <div class="btn-group">

        <ng-container *ngIf="showBackButtonIcon">
            <button mat-flat-button color="accent" class="migrated-icon-button" [attr.data-qa-id]="qaId + '_back_button'"
            (click)="back()">
                <mat-icon class="fa-sharp fa-regular fa-hand-point-left"></mat-icon>
                Back
            </button>
        </ng-container>

        <ng-container *ngIf="showOutlineBackButtonNoIcon">
            <button mat-stroked-button color="accent" class="back-btn-outline-no-icon" [attr.data-qa-id]="qaId + '_back_button_outline_no_icon'"
            (click)="back()">
                Back
            </button>
        </ng-container>

        <button *ngIf="showCancelButton" mat-flat-button color="accent" class="migrated-icon-button"
            [attr.data-qa-id]="qaId + '_cancel_button'" (click)="cancel()" [disabled]="disableSaveAndCancel">
            <mat-icon class="fa fa-undo"></mat-icon>
            Cancel
        </button>

        <ng-container *ngIf="showSaveButtonIcon">
            <button mat-flat-button color="accent" class="migrated-icon-button" [attr.data-qa-id]="qaId + '_save_button'"
            (click)="save()" [disabled]="disableSaveAndCancel">
                <mat-icon class="fa fa-save"></mat-icon>
                Save
            </button>
        </ng-container>

        <ng-container *ngIf="showSubmitButtonNoIcon">
            <button mat-flat-button color="accent" class="migrated-button" [attr.data-qa-id]="qaId + '_save_button'"
            (click)="save()" [disabled]="disableSaveAndCancel">
                Submit
            </button>
        </ng-container>

        <span *ngIf="hasChanges">
            <mat-icon class="fa fa-asterisk fa-asterisk-large"></mat-icon>
        </span>

    </div>
    <div class="btn-group">
        <button mat-flat-button *ngIf="showCompleteSubmittal" color="accent" class="migrated-icon-button" [attr.data-qa-id]="qaId + '_complete_submittal_button'"
            (click)="completeSubmittal()" [disabled]="disableCompleteSubmittal">
            <mat-icon class="fa fa-upload"></mat-icon>
            Complete Submittal
        </button>
        <button *ngIf="showCreateNovaButton" mat-flat-button color="accent" class="migrated-icon-button nova-btn"
            [attr.data-qa-id]="qaId + '_create_nova_profile_button'" (click)="createNovaProfile()" [disabled]="disableV1Profile">
            Create Nova Profile
            <mat-icon *ngIf="isProfileV1">check</mat-icon>
        </button>
    </div>

</div>
