import { createAction, props } from '@ngrx/store';

export const exportVendorInsurance = createAction(
    '[VENDOR INSURANCE COMPONENT] Export Vendor Insurance',
    props<{ vendorId: number }>()
);
export const exportVendorInsuranceSuccess = createAction(
    '[VENDOR INSURANCE API] Export Vendor Insurance Success',
    props<{ response: Blob }>()
);
export const exportVendorInsuranceFailure = createAction(
    '[VENDOR INSURANCE API] Export Vendor Insurance Failure',
    props<{ error: unknown }>()
);
