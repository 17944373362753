import { Injectable, Inject } from '@angular/core';
import { ENV, Environment } from 'src/environments/environment.provider';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { datadogLogs as datadog } from '@datadog/browser-logs';
import { formatAppVersion } from './version-checker.service';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    initialized = false;
    constructor(
        @Inject(ENV) private readonly env: Environment,
        @Inject(APP_CONFIG) readonly settings: Settings
    ) {
        if (!this.settings?.SERVER_NAME) {
            return;
        }
        try {
            datadog.init({
                clientToken: this.settings.DATADOG_CLIENT_TOKEN,
                site: 'us3.datadoghq.com',
                service: this.settings.DATADOG_SERVICE,
                forwardErrorsToLogs: true,
                sessionSampleRate: 100,
                env: this.settings.ENV,
                version: formatAppVersion(env.appVersion).replace(/-\d*$/, '')
            });

            datadog.setGlobalContextProperty('application', 'Connect');
            if (env.production) {
                datadog.logger.setHandler(['http', 'console']);
            } else {
                datadog.logger.setHandler(['console']);
            }

            this.initialized = true;
        } catch (err) {
            console.log(err.message);
        }
    }

    public debug(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            datadog.logger.debug(message, context);
        }
    }

    public info(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            datadog.logger.info(message, context);
        }
    }

    public warn(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            datadog.logger.warn(message, context);
        }
    }

    public error(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            datadog.logger.error(message, context);
        }
    }
}
