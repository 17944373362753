import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as StaffingAdvisoryActions from 'src/app/travelers/state/actions/staffing-advisory.actions';

export interface StaffingAdvisoryDeleteState {
    error: string;
    loading: LoadingTypes;
}

export const initialState: StaffingAdvisoryDeleteState = {
    error: '',
    loading: LoadingTypes.INIT
};

export function staffingAdvisoryDeleteReducer(state: StaffingAdvisoryDeleteState, action: Action) {
    return createReducer(
        initialState,
        on(StaffingAdvisoryActions.deleteStaffingAdvisory, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(StaffingAdvisoryActions.deleteStaffingAdvisorySuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(StaffingAdvisoryActions.deleteStaffingAdvisoryFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        })),

        on(StaffingAdvisoryActions.clearStaffingAdvisory, (state, action) => ({
            ...initialState
        }))
    )(state, action);
}

export function reducer(state: StaffingAdvisoryDeleteState | undefined, action: Action) {
    return staffingAdvisoryDeleteReducer(state, action);
}
