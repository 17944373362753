import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AdminSystemsService } from 'src/app/systems/services';
import { systemActions } from 'src/app/systems/store';

import { DialogService } from 'src/app/shared/services/dialog.service';
import { ApplicationFeature } from 'src/app/shared/models';
import { FeatureSettingItem } from 'src/app/shared/feature-settings';

@Injectable()
export class SystemSettingsEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _systemsService: AdminSystemsService,
        private readonly _dialogService: DialogService
    ) {}

    loadSystemSettings$ = createEffect(() =>
        this._actions$.pipe(
            ofType(systemActions.loadSystemSettings),
            switchMap((action) => {
                return this._systemsService.getSystemSettings(action.systemId).pipe(
                    map((settings) => {
                        settings.slice().sort((a, b) => a.sortOrder - b.sortOrder);
                        return systemActions.loadSystemSettingsSuccess({ settings });
                    }),
                    catchError((err) => {
                        this._dialogService.openSnackBarError(err.message);
                        return of(systemActions.loadSystemSettingsFailed({ error: err.error }));
                    })
                );
            })
        )
    );

    updateSystemSettings$ = createEffect(() =>
        this._actions$.pipe(
            ofType(systemActions.updateSystemSettings),
            switchMap((action) =>
                this._systemsService.updateSystemSettings(action.systemId, action.setting).pipe(
                    map(() => systemActions.updateSystemSettingsSuccess()),
                    tap(() => {
                        this._dialogService.openSnackBarSuccess(`Systems settings has been successfully updated!`);
                    }),
                    catchError((err) => {
                        this._dialogService.openSnackBarError(err.message);
                        return of(systemActions.updateSystemSettingsFailed({ error: err.error }));
                    })
                )
            )
        )
    );

    reloadSystemSettings$ = createEffect(() =>
        this._actions$.pipe(
            ofType(systemActions.updateSystemSettings),
            filter((action) => action.reload === true),
            switchMap((action) => {
                const success$ = this._actions$.pipe(ofType(systemActions.updateSystemSettingsSuccess));
                const failed$ = this._actions$.pipe(ofType(systemActions.updateSystemSettingsFailed));

                return success$.pipe(
                    takeUntil(failed$),
                    take(1),
                    map(() => systemActions.loadSystemSettings({ systemId: action.systemId }))
                );
            })
        )
    );

    confirmSystemSetting$ = createEffect(() =>
        this._actions$.pipe(
            ofType(systemActions.confirmUpdateSystemSetting),
            switchMap((action) => {
                // This hook needed to await for confirmation
                // and do not update setting immediately.
                // for other changes we can just dispatch update action
                const isConfirmationNeeded = this.isApplicationFeatureConfirmationNeeded(action.current);

                if (!isConfirmationNeeded) {
                    return of(
                        systemActions.updateSystemSettings({
                            setting: action.current,
                            systemId: action.systemId,
                            reload: action.reload
                        })
                    );
                }

                // Targeting effect should catch confirmation action
                // then dispatch action to apply/revert setting
                const success$ = this._actions$.pipe(
                    ofType(systemActions.applyUpdateSystemSetting),
                    map(() => {
                        return systemActions.updateSystemSettings({
                            setting: action.current,
                            systemId: action.systemId,
                            reload: action.reload
                        });
                    })
                );
                const revert$ = this._actions$.pipe(
                    ofType(systemActions.revertUpdateSystemSetting),
                    map(() => {
                        return systemActions.setSystemSetting({
                            setting: action.previous
                        });
                    })
                );

                return merge(success$, revert$).pipe(first());
            })
        )
    );

    private isApplicationFeatureConfirmationNeeded(setting: FeatureSettingItem) {
        switch (setting.featureId) {
            // when shifts tiering is disabled
            // confirmation is needed
            case ApplicationFeature.Allow_Shifts_Tiering: {
                if (setting.isEnabled === false) {
                    return true;
                }
            }
        }

        return false;
    }
}
