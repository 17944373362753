import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PDFModule } from '@progress/kendo-angular-grid';
import { TimeFilterComponent } from 'src/app/shared/grid/filters/time-filter/time-filter.component';
import { KendoModule } from 'src/app/shared/kendo/kendo.module';
import { AfterValueChangedDirective } from '../directives/after-value-changed.directive';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { GridColumnDirective } from './components/grid-column.directive';
import { GridQuickFilterComponent } from './components/grid-quick-filter.component';
import { GridComponent } from './components/grid.component';
import { CheckboxFilterComponent } from './filters/checkbox-filter/checkbox-filter.component';
import { ComboBoxFilterComponent } from './filters/combobox-filter/combobox-filter.component';
import { DateFilterComponent } from './filters/date-filter/date-filter.component';
import { DateRangeFilterPopupComponent } from './filters/date-range-filter/date-range-filter-popup.component';
import { DateRangeFilterComponent } from './filters/date-range-filter/date-range-filter.component';
import { DropDownFilterRowComponent } from './filters/dropdown-filter/dropdown-filter-row.component';
import { DropDownFilterComponent } from './filters/dropdown-filter/dropdown-filter.component';
import { DropDownMultiSelectFilterComponent } from './filters/dropdown-multiselect-filter/dropdown-multiselect-filter.component';
import { FilterPopupComponent } from './filters/filter-popup/filter-popup.component';
import { GreaterThanLessThanEqualsComponent } from './filters/greater-than-less-than-equals/greater-than-less-than-equals.component';
import { MultiFieldSelectFilterComponent } from './filters/multifield-select-filter/multifield-select-filter.component';
import { MultiSelectFilterRowComponent } from './filters/multiselect-filter/multiselect-filter-row.component';
import { MultiSelectFilterComponent } from './filters/multiselect-filter/multiselect-filter.component';
import { SwitchFilterComponent } from './filters/switch-filter/switch-filter.component';
import { TextFilterComponent } from './filters/text-filter/text-filter.component';
import { IconsModule } from '@progress/kendo-angular-icons';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        KendoModule,
        PDFModule,
        PipesModule,
        ReactiveFormsModule,
        IconsModule
    ],
    declarations: [
        GridComponent,
        GridColumnDirective,
        GridQuickFilterComponent,
        TextFilterComponent,
        CheckboxFilterComponent,
        DropDownFilterRowComponent,
        DropDownFilterComponent,
        MultiSelectFilterRowComponent,
        MultiSelectFilterComponent,
        FilterPopupComponent,
        DateRangeFilterComponent,
        DateRangeFilterPopupComponent,
        DateFilterComponent,
        TimeFilterComponent,
        ComboBoxFilterComponent,
        SwitchFilterComponent,
        GreaterThanLessThanEqualsComponent,
        AfterValueChangedDirective,
        DropDownMultiSelectFilterComponent,
        MultiFieldSelectFilterComponent
    ],
    exports: [
        GridComponent,
        GridColumnDirective,
        GridQuickFilterComponent,
        GreaterThanLessThanEqualsComponent,
        AfterValueChangedDirective
    ],
    providers: []
})
export class AppGridModule {}
