<form>
    <div>
        <ayac-form-control-wrapper
            [controlLabel]="stateLabel"
            [showRequiredAsterisk]="true"
            class="license-state-control"
        >
            <mat-form-field appearance="outline">
                <mat-chip-list #chipList aria-label="State">
                    <mat-chip
                        *ngFor="let stateId of selectedStateIds"
                        (removed)="deleteLicense(stateId)"
                    >
                        {{ getStateName(stateId) }}

                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                        autocomplete="off"
                        placeholder="Select state.."
                        #stateInput
                        [formControl]="stateAutocompleteFormControl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addFilteredState($event)"
                        #trigger="matAutocompleteTrigger"
                        (click)="trigger.openPanel()"
                    />
                </mat-chip-list>
                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="stateSelected($event)"
                >
                    <mat-option
                        *ngFor="let state of filteredStates$ | async"
                        [value]="state.id"
                    >
                        {{ state.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ayac-form-control-wrapper>

        <ayac-form-control-wrapper
            *ngIf="selectedStateIds.length > 0"
            class="license-container"
        >
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Licenses</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <ng-container
                        *ngFor="
                            let licenseForm of formArray.controls;
                            let i = index
                        "
                    >
                        <div
                            class="candidate-profile-control-container license-form-row"
                            [formGroup]="licenseForm"
                        >
                            <div
                                fxLayout="row"
                                fxLayout.lt-md="column"
                                fxLayoutAlign="space-around stretch"
                                fxLayoutAlign.lt-md="space-around stretch"
                                fxLayoutGap.gt-sm="15px"
                                class="license-control"
                            >
                                <div fxFlex.gt-sm="15" class="label-container">
                                    <span>
                                        {{
                                            getStateName(
                                                licenseForm.get("stateId").value
                                            )
                                        }}
                                    </span>
                                </div>
                                <div fxFlex.gt-sm="35">
                                    <mat-form-field appearance="outline">
                                        <input
                                            matInput
                                            placeholder="License Number"
                                            formControlName="licenseNumber"
                                            [attr.data-qa-id]="
                                                'LicenseNumber' + (i + 1)
                                            "
                                        />
                                    </mat-form-field>
                                </div>
                                <div fxFlex.gt-sm="35">
                                    <mat-form-field appearance="outline">
                                        <input
                                            matInput
                                            placeholder="Expiration Date"
                                            [matDatepicker]="expDatePicker"
                                            formControlName="expDate"
                                            [attr.data-qa-id]="
                                                'ExpDate' + (i + 1)
                                            "
                                            data-qa-id="ExpDate"
                                        />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="expDatePicker"
                                        ></mat-datepicker-toggle>
                                        <mat-datepicker
                                            #expDatePicker
                                        ></mat-datepicker>
                                        <mat-error *ngIf="licenseForm.get('expDate').hasError('InvalidExpDate')">
                                            Expiration date needs to be between 1/1/1753 and 12/31/9999.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxFlex.gt-sm="15">
                                    <mat-checkbox
                                        formControlName="isCompact"
                                        [attr.data-qa-id]="
                                            'IsCompact' + (i + 1)
                                        "
                                        >Compact</mat-checkbox
                                    >
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </ayac-form-control-wrapper>
        <ayac-form-control-wrapper [controlLabel]="npiLabel" class="license-state-control" [showRequiredAsterisk]="enableNpiRequiredValidation">
            <mat-form-field appearance="outline">
                <input #npiInput matInput placeholder="National Provider Identifier" [formControl]="npiFormControl" data-qa-id="npi_input" (input)="onNpiInputChange($event)" />
                <mat-error *ngIf="npiFormControl.hasError('required') && npiFormControl.touched">{{ npiLabel }} is required for submittal.</mat-error>
                <mat-error *ngIf="npiFormControl.hasError('pattern') && npiFormControl.touched">Please enter a valid 10-digit number.</mat-error>
            </mat-form-field>
        </ayac-form-control-wrapper>
    </div>
</form>
