import { Action, createReducer, on } from '@ngrx/store';
import { getEmptyFacilitiesLookups } from 'src/app/facilities/models/facility-administration-lookups.model';
import * as actions from 'src/app/facilities/store/actions/facilities-list-lookups.actions';
import {
    FacilitiesListLookupsState,
    getInitialFacilitiesListLookupsState
} from 'src/app/facilities/store/state/facilities-list-lookups.state';
import { LoadingTypes } from 'src/app/shared/models';

export function facilitiesListLookupsReducer(state: FacilitiesListLookupsState | undefined, action: Action) {
    return createReducer(
        getInitialFacilitiesListLookupsState(),

        on(
            actions.loadFacilitiesListLookups,
            (state, action): FacilitiesListLookupsState => ({
                ...state,
                ...getEmptyFacilitiesLookups(),
                loadingState: LoadingTypes.LOADING
            })
        ),

        on(
            actions.loadFacilitiesListLookupsSuccess,
            (state, action): FacilitiesListLookupsState => ({
                ...state,
                ...action.lookups,
                loadingState: LoadingTypes.LOADED,
                error: ''
            })
        ),

        on(
            actions.loadFacilitiesListLookupsFailure,
            (state, action): FacilitiesListLookupsState => ({
                ...state,
                loadingState: LoadingTypes.FAIL,
                error: action.error
            })
        )
    )(state, action);
}
