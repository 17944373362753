import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, dashboardStoreKey } from 'src/app/dashboard-new/store/dashboard.state';

const selectFeature = createFeatureSelector<DashboardState>(dashboardStoreKey);

export const getSecurityFacilities = createSelector(selectFeature, (state) => state.securityLookups.facilities);

export const getSecuritySystems = createSelector(selectFeature, (state) => state.securityLookups.systems);

export const getSecurityUnits = createSelector(selectFeature, (state) => state.securityLookups.units);

export const getSecurityError = createSelector(selectFeature, (state) => state.securityLookups.error);

export const getShiftRanges = createSelector(selectFeature, (state) => state.securityLookups.ranges);
