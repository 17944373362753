import { AcceptedVendor } from 'src/app/facilities/models/accepted-vendor.model';
import { LoadingTypes } from 'src/app/shared/models';
import { ReleasePermissions } from 'src/app/facilities/models/release-permissions.model';
import { ReleaseGroup } from 'src/app/facilities/models/release-group.model';

export interface ReleaseGroupsState {
    releaseGroupsPermissions: ReleasePermissions;
    releaseGroupsPermissionsLoadingState: LoadingTypes;
    releaseGroupsLoadingState: LoadingTypes;
    releaseGroups: ReleaseGroup[];
    releaseGroupVendors: AcceptedVendor[];
    releaseGroupVendorsLoadingState: LoadingTypes;
    saveReleaseGroupLoadingState: LoadingTypes;
    copySettingsLoadingState: LoadingTypes;
}

export const getInitialReleaseGroupsState = (): ReleaseGroupsState => ({
    releaseGroupsPermissions: { canAdd: false, canDelete: false, canEdit: false, canView: false, facilityId: 0 },
    releaseGroupsPermissionsLoadingState: LoadingTypes.INIT,
    releaseGroupsLoadingState: LoadingTypes.INIT,
    releaseGroups: [],
    releaseGroupVendors: [],
    releaseGroupVendorsLoadingState: LoadingTypes.INIT,
    saveReleaseGroupLoadingState: LoadingTypes.INIT,
    copySettingsLoadingState: LoadingTypes.INIT
});
