import { ToasterService } from 'src/app/core/services/toaster.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { saveAs } from 'js/lib/file-saver/FileSaver';
import { isDevMode } from '@angular/core';

export class BaseEffect {
    private readonly isDevEnvironment: boolean;

    constructor(protected toasterService: ToasterService) {
        this.isDevEnvironment = isDevMode();
    }

    protected handleSuccess(success: any) {
        return this.toasterService.success(success);
    }

    protected handleStringError(error: string) {
        return this.toasterService.fail(error);
    }

    protected handleError(error: HttpErrorResponse) {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred.
            errorMessage = `An error occurred: ${error.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            errorMessage = `A backend error occurred: ${error.status}\nMessage: ${error.message}`;
        }

        this.logError(error);
        this.toasterService.fail(errorMessage);
    }

    protected logData(obj: any) {
        // It’s better to disable debugging messaging output so that it can only be used locally
        if (this.isDevEnvironment) {
            console.log(obj);
        }
    }

    protected logError(obj: any) {
        console.error(obj);
    }

    protected saveFile(response: HttpResponse<Blob>) {
        const isFileSaverSupported = typeof saveAs === 'function';
        const fileName = this.getFileNameFromHttpResponse(response);

        if (isFileSaverSupported && fileName) {
            saveAs(response.body, fileName);
        } else {
            const fileURL = URL.createObjectURL(response.body);
            window.open(fileURL, '_blank');
        }
    }

    protected getFileNameFromHttpResponse(httpResponse: HttpResponse<Blob>) {
        const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
        if (contentDispositionHeader && contentDispositionHeader.length) {
            const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
            return result.replace(/"/g, '');
        }

        return null;
    }
}
