import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'multipleDatesFormat'
})
export class MultipleDatesFormatPipe implements PipeTransform {
    private readonly dateFormat = 'MM/DD/YYYY';

    transform(dates: Date | Date[], format = '%min - %max (%count selected)', rangeSize = 5): any {
        if (!dates) {
            return dates;
        }

        dates = Array.isArray(dates) ? dates : [dates];

        if (rangeSize && dates.length >= rangeSize) {
            const max = Math.max.apply(
                null,
                dates.map((d) => new Date(d).getTime())
            );
            const min = Math.min.apply(
                null,
                dates.map((d) => new Date(d).getTime())
            );

            return format
                .replace('%min', moment(min).format(this.dateFormat))
                .replace('%max', moment(max).format(this.dateFormat))
                .replace('%count', String(dates.length));
        }

        return dates.map((date) => moment(date).format(this.dateFormat)).join(', ');
    }
}
