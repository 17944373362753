<div class="textarea-wrapper">
    <div *ngIf="!editor else richTextEditor">
        <textarea [rows]="rowsCount"
                class="k-textarea"
                [ngClass]="{'textarea-wrapper-value-is-not-valid': !isValid, 'hide-border': displayBorderBottomOnly}"
                [placeholder]="placeholder"
                [(ngModel)]="model"
                (ngModelChange)="updateModel($event)"
                data-qa-id="dataQaId"
                [readOnly]="readonly">
        </textarea>
    </div>
     <ng-template #richTextEditor>
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
        </ngx-editor-menu>
        <div class="editor-container">
            <ngx-editor
                [editor]="editor"
                [placeholder]="placeholder"
                [ngClass]="{'textarea-wrapper-value-is-not-valid': !isValid, 'hide-border': displayBorderBottomOnly}"
                [ngModel]="model"
                (ngModelChange)="updateModel($event)"
            >
            </ngx-editor>
        </div>
     </ng-template>
</div>
<div>
    <span class="text-length" [ngClass]="{'text-length-value-is-not-valid': !isValid}">
        {{ model ? noteLength : 0}}/{{maxLength}} {{isVendorClientCommunicationEnabled ? 'characters' : ''}}
    </span>
</div>
