import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { InternalPoolService } from '../../internal-pool.service';
import * as internalPoolActions from 'src/app/internal-pool/store/actions';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';

@Injectable()
export class ResourceInviteEffect extends BaseEffect {
    constructor(
        private readonly actions: Actions,
        private readonly internalPoolService: InternalPoolService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    sendInvitationEmail$ = createEffect(() =>
        this.actions.pipe(
            ofType(internalPoolActions.resendInvitationEmail),
            switchMap((action) =>
                this.internalPoolService.resendInvitationEmail(action.id).pipe(
                    switchMap((response) => [internalPoolActions.resendInvitationEmailSuccess()]),
                    catchError((error) => of(internalPoolActions.resendInvitationEmailFail({ error })))
                )
            )
        )
    );

    sendInvitationEmailSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(internalPoolActions.resendInvitationEmailSuccess),
                tap(() => this.handleSuccess('Invite email sent'))
            ),
        { dispatch: false }
    );

    sendInvitationEmailFailed$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(internalPoolActions.resendInvitationEmailFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );
}
