<dialog-layout [title]="title">
    <div id="dialogContent" mat-dialog-content *ngIf="releaseGroups$ | async as releaseGroups">

        <div *ngIf="areReleaseGroupsEmpty$ | async" class="alert alert-warning" role="alert">
            No release groups found for the selected facility. Please contact support for assistance.
        </div>

        <div class="tier-container" *ngFor="let item of releaseGroups | keyvalue; let i = index;">
            <h4 class="tier-name">{{ item.key }}</h4>

            <div>
                <mat-accordion *ngFor="let group of item.value; let idx = index;">
                    <mat-expansion-panel [expanded]="trackingStatus.isItemOpen[group.id]" (opened)="accordionOpened(idx)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-checkbox class="group-checkbox" [checked]="trackingStatus.isChecked[group.id]" (click)="$event.stopPropagation()" (change)="toggleGroup(group, $event)">
                                    {{ group?.name }}
                                </mat-checkbox>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <table mat-table [dataSource]="group.vendors" aria-label="vendors table">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox [checked]="trackingStatus.isChecked[group.id]" (click)="$event.stopPropagation()" (change)="toggleGroup(group, $event)">
                                        <strong>Select All</strong>
                                    </mat-checkbox>
                                </th>

                                <td mat-cell *matCellDef="let row; let i = index;">
                                    <mat-checkbox  [checked]="trackingStatus.vendors[row.id]" (click)="$event.stopPropagation()" [attr.data-qa-id]="row?.legalName + 'Checkbox'"
                                        (change)="toggleVendor(row.id, $event)">
                                        {{ row?.operatingName }} <span *ngIf="row?.operatingName !== row?.legalName">({{ row?.legalName }})</span>
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: ['select'];"></tr>
                        </table>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-flat-button color="accent" (click)="releaseVendors()" [disabled]="selectedCount === 0" [matBadge]="selectedCount">Release Selected</button>
        <button mat-flat-button color="accent" (click)="releaseAllVendors()">Release All Vendors</button>
        <button mat-stroked-button color="primary" [mat-dialog-close]="false">Cancel</button>
    </div>
</dialog-layout>
