import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmittalExpanded } from 'src/app/shared/models/submittals';

export type CreateOfferStepType = 'selectOption' | 'rescindOffer' | 'createOffer';

@Component({
    selector: 'ayac-create-offer-wizard-dialog',
    templateUrl: './create-offer-wizard-dialog.component.html'
})
export class CreateOfferWizardDialogComponent {
    submittal: SubmittalExpanded;
    currentStep: CreateOfferStepType = 'selectOption';

    constructor(@Inject(MAT_DIALOG_DATA) private readonly data: { submittal: SubmittalExpanded }) {
        this.submittal = data.submittal;
    }

    setCurrentStep(step: CreateOfferStepType): void {
        this.currentStep = step;
    }
}
