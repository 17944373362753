import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions, concatLatestFrom } from '@ngrx/effects';
import { map, catchError, switchMap, tap, withLatestFrom, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { SubmittalsVendorService } from 'src/app/submittals-vendor/submittals-vendor.service';
import { submittalsVendorActions } from 'src/app/submittals-vendor/store/actions/';
import { ToasterService } from 'src/app/core/services';
import { VendorSubmittalStage } from 'src/app/shared/components/submittal-details/enums/vendor-submittal-stage.enum';
import { SubmittalActivity } from 'src/app/submittals/models';
import {
    selectSubmittalVendorActivitiesSortBy,
    selectCommunicationEnabled,
    selectCreatedSubmittalId,
    selectVendorSubmittalsDetails
} from '../submittals-vendor.selectors';
import { SubmittalCommunicationService } from 'src/app/shared/services/submittal-communcation.service';

@Injectable()
export class SubmittalsVendorDetailsEffects {
    loadSubmittalDetails$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(
                submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalsDetails,
                submittalsVendorActions.SubmittalsVendorDetailsActions.updateSubmittalNoteSuccess,
                submittalsVendorActions.SubmittalsVendorDetailsActions.updateStageHistoryEntrySuccess
            ),
            switchMap((actions) =>
                this.submittalsVendorService.getVendorSubmittalDetails(actions.submittalId).pipe(
                    map((details) =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalsDetailsSuccess({ details })
                    ),
                    catchError((error: unknown) =>
                        of(submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalsDetailsFail({ error }))
                    )
                )
            )
        );
    });

    //These effects can be removed when removing feature flags and
    // loadSubmittalActivities can be dispatched from the submittal-vendor-details component for an existing submittal
    loadSubmittalsDetailsSuccess$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalsDetailsSuccess),
            map((action) =>
                submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalCommunicationEnabled({
                    useAyaAccountManagement: action.details.useAyaAccountManagement
                })
            )
        );
    });

    loadJobDetailsSuccess$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.loadVendorJobSuccess),
            map((action) =>
                submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalCommunicationEnabled({
                    useAyaAccountManagement: action.jobDetails.useAyaAccountManagement
                })
            )
        );
    });

    loadSubmittalCommunicationEnabled$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalCommunicationEnabled),
            switchMap((action) =>
                this._submittalCommunicationService.isCommunicationEnabled(action.useAyaAccountManagement).pipe(
                    map((communicationEnabled) =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalCommunicationEnabledSuccess(
                            { communicationEnabled }
                        )
                    ),
                    catchError((error: unknown) =>
                        of(
                            submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalCommunicationEnabledFail(
                                { error }
                            )
                        )
                    )
                )
            )
        );
    });

    loadSubmittalCommunicationEnabledSuccess$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalCommunicationEnabledSuccess),
            concatLatestFrom(() => [
                this.store$.select(selectVendorSubmittalsDetails),
                this.store$.select(selectCreatedSubmittalId)
            ]),
            filter(
                ([action, submittalDetails, createdSubmittalId]) =>
                    action.communicationEnabled && (submittalDetails !== null || createdSubmittalId > 0)
            ),
            map(([, submittalDetails, createdSubmittalId]) =>
                submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalActivities({
                    submittalId: submittalDetails?.id ?? createdSubmittalId
                })
            )
        );
    });
    //end removal

    updateSubmittalNote$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.updateSubmittalNote),
            switchMap((actions) =>
                this.submittalsVendorService.updateSubmittalNote(actions.submittalId, actions.note).pipe(
                    map(() =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.updateSubmittalNoteSuccess({
                            submittalId: actions.submittalId
                        })
                    ),
                    catchError((error: unknown) =>
                        of(submittalsVendorActions.SubmittalsVendorDetailsActions.updateSubmittalNoteFailure({ error }))
                    )
                )
            )
        );
    });

    updateSubmittalPreferences$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.updateSubmittalPreferences),
            switchMap((actions) =>
                this.submittalsVendorService.updateSubmittalPreferences(actions.submittalId, actions.preferences).pipe(
                    map(() =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.updateSubmittalPreferencesSuccess()
                    ),
                    catchError((error: unknown) =>
                        of(
                            submittalsVendorActions.SubmittalsVendorDetailsActions.updateSubmittalPreferencesFailure({
                                error
                            })
                        )
                    )
                )
            )
        );
    });

    updateSubmittalStageHistoryEntry$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.updateStageHistoryEntry),
            switchMap((actions) => {
                return this.submittalsVendorService.updateSubmittalStageHistoryEntry(actions.entry).pipe(
                    map(() => {
                        let message = '';
                        switch (actions.entry.currentStageId) {
                            case VendorSubmittalStage.Cancelled:
                                message = 'Submittal Cancelled!';
                                break;
                            case VendorSubmittalStage.OfferAccepted:
                                message = 'Offer Accepted!';
                                break;
                            case VendorSubmittalStage.OfferDeclined:
                                message = 'Offer Declined!';
                                break;
                        }
                        return submittalsVendorActions.SubmittalsVendorDetailsActions.updateStageHistoryEntrySuccess({
                            submittalId: actions.entry.submittalId,
                            successToasterMessage: message
                        });
                    }),
                    catchError((error: unknown) => {
                        const message = 'Error updating submittal';
                        return of(
                            submittalsVendorActions.SubmittalsVendorDetailsActions.updateStageHistoryEntryFailure({
                                error: message
                            })
                        );
                    })
                );
            })
        );
    });

    loadVendorProfessionSpecialties$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.getCandidateProfessionSpecialties),
            switchMap((actions) =>
                this.submittalsVendorService.getCandidateProfessionSpecialties(actions.candidateId).pipe(
                    map((professionSpecialties) =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.getCandidateProfessionSpecialtiesSuccess(
                            { professionSpecialties }
                        )
                    ),
                    catchError((error: unknown) =>
                        of(
                            submittalsVendorActions.SubmittalsVendorDetailsActions.getCandidateProfessionSpecialtiesFail(
                                { error }
                            )
                        )
                    )
                )
            )
        );
    });

    loadVendorJobDetails$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.loadVendorJob),
            switchMap((actions) =>
                this.submittalsVendorService.getSubmittalVendorJob(actions.jobId).pipe(
                    map((job) =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.loadVendorJobSuccess({ jobDetails: job })
                    ),
                    catchError((error: unknown) =>
                        of(submittalsVendorActions.SubmittalsVendorDetailsActions.loadVendorJobFail({ error }))
                    )
                )
            )
        );
    });

    loadCandidateInfo$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.loadCandidateInfo),
            switchMap((actions) =>
                this.submittalsVendorService.getCandidateInfo(actions.candidateId).pipe(
                    map((candidateInfo) =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.loadCandidateInfoSuccess({
                            candidateInfo
                        })
                    ),
                    catchError((error: unknown) =>
                        of(submittalsVendorActions.SubmittalsVendorDetailsActions.loadCandidateInfoFail({ error }))
                    )
                )
            )
        );
    });

    createSubmittal$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.createNewSubmittal),
            switchMap((actions) =>
                this.submittalsVendorService.createSubmittal(actions.submittalParams).pipe(
                    map((submittalId) =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.createNewSubmittalSuccess({
                            submittalId
                        })
                    ),
                    catchError((error: unknown) =>
                        of(submittalsVendorActions.SubmittalsVendorDetailsActions.createNewSubmittalFailure({ error }))
                    )
                )
            )
        );
    });

    createNewSubmittalFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.createNewSubmittalFailure),
                switchMap((error) =>
                    this.toaster.fail(error?.error?.error ?? 'Error updating submittal, please try again.')
                )
            );
        },
        { dispatch: false }
    );

    updateSubmittalNoteSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.updateSubmittalNoteSuccess),
                switchMap(() => this.toaster.success('Submittal note updated'))
            );
        },
        { dispatch: false }
    );

    updateSubmittalNoteFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.updateSubmittalNoteFailure),
                switchMap(() =>
                    this.toaster.success(
                        'Error updating submittal. There was a server error updating the submittal note, please try again'
                    )
                )
            );
        },
        { dispatch: false }
    );

    updateStageHistoryEntrySuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.updateStageHistoryEntrySuccess),
                switchMap((actions) => this.toaster.success(actions.successToasterMessage))
            );
        },
        { dispatch: false }
    );

    updateStageHistoryEntryFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.updateStageHistoryEntryFailure),
                switchMap((actions) => this.toaster.success(`${actions.error}, please try again`))
            );
        },
        { dispatch: false }
    );

    getSubmittalActivities$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalActivities,
                submittalsVendorActions.SubmittalsVendorDetailsActions.addSubmittalNoteSuccess,
                submittalsVendorActions.SubmittalsVendorDetailsActions.updateStageHistoryEntrySuccess,
                submittalsVendorActions.SubmittalsVendorDetailsActions.setActivitiesSortBy
            ),
            concatLatestFrom(() => [
                this.store$.select(selectSubmittalVendorActivitiesSortBy),
                this.store$.select(selectCommunicationEnabled)
            ]),
            filter(([, , communicationEnabled]) => communicationEnabled),
            switchMap(([action, sortBy]) =>
                this.submittalsVendorService.getSubmittalActivities(action.submittalId, sortBy).pipe(
                    map((activities: SubmittalActivity[]) =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalActivitiesSuccess({
                            activities
                        })
                    ),
                    catchError((error: unknown) =>
                        of(
                            submittalsVendorActions.SubmittalsVendorDetailsActions.loadSubmittalActivitiesFail({
                                error
                            })
                        )
                    )
                )
            )
        );
    });

    addSubmittalNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.addSubmittalNote),
            switchMap((action) =>
                this.submittalsVendorService.addSubmittalNote(action.submittalId, action.newSubmittalNote).pipe(
                    map(() =>
                        submittalsVendorActions.SubmittalsVendorDetailsActions.addSubmittalNoteSuccess({
                            submittalId: action.submittalId
                        })
                    ),
                    catchError((error: unknown) =>
                        of(submittalsVendorActions.SubmittalsVendorDetailsActions.addSubmittalNoteFail({ error }))
                    )
                )
            )
        );
    });

    addSubmittalNoteSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.addSubmittalNoteSuccess),
                tap(() => {
                    this.toaster.success('Comment was successfully added.');
                })
            );
        },
        { dispatch: false }
    );

    addSubmittalNoteFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(submittalsVendorActions.SubmittalsVendorDetailsActions.addSubmittalNoteFail),
                tap((error) => {
                    this.toaster.fail(`There is an error while adding a comment: ${error?.error?.error}`);
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly submittalsVendorService: SubmittalsVendorService,
        private readonly store$: Store<{}>,
        private toaster: ToasterService,
        private readonly _submittalCommunicationService: SubmittalCommunicationService
    ) {}
}
