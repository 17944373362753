import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';

@Component({
    selector: 'grid-date-filter',
    templateUrl: './date-filter.component.html'
})
export class DateFilterComponent extends BaseFilterCellComponent implements AfterViewInit, OnDestroy {
    @Input() public field: string;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() public logic;
    @Input() public disabledKeyboard: boolean;
    @Input() uniqueFilterClass: string;
    @ViewChild(DatePickerComponent, { static: true }) private readonly datePicker: DatePickerComponent;

    selectedValue: Date | null;

    // Cannot extend our UnsubscribeOnDestroy because we are already extending another component
    private readonly unsubscribe$ = new Subject<void>();

    constructor(
        filterService: FilterService,
        private readonly cdRef: ChangeDetectorRef
    ) {
        super(filterService);
    }

    @Input() public set options(options) {
        if (options && options.min) {
            this.datePicker.min = options.min instanceof Date ? options.min : new Date(options.min);
        }
        if (options && options.max) {
            this.datePicker.max = options.max instanceof Date ? options.max : new Date(options.max);
        }
    }

    ngAfterViewInit() {
        const filter = this.filterByField(this.field);
        this.selectedValue = filter ? new Date(filter.value) : null;
        this.cdRef.detectChanges();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onChange(value: Date): void {
        this.applyFilter(
            value === null
                ? this.removeFilter(this.field)
                : this.updateFilter({
                      field: this.field,
                      operator: this.logic,
                      // Use the built in Angular date formatter instead of moment to reduce import/bundling costs and size
                      // Dev/Unit tested and verified to format the exact same way as previous moment implementation
                      value: formatDate(value, 'yyyy-MM-ddTHH:mm:ss', 'en-us')
                  })
        );
    }

    disableKeyboardInput(event: KeyboardEvent): void {
        const key = event.key;
        if (key === 'Backspace' || key === 'Delete') {
            return;
        } // allow backspace and delete

        if (this.disabledKeyboard) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
