export enum TimecardsUrl {
    TIMECARDS = '/AyaConnect/Client/Timecards',
    TIMECARD_DETAIL = '/AyaConnect/Client/Timecards/{timecardId}',
    TIMECARD_UNITS = '/AyaConnect/Client/Timecards/{timecardId}/Units',

    TIMECARD_HISTORY = '/AyaConnect/Client/Timecards/{timecardId}/History',
    TIMECARD_APPROVE = '/AyaConnect/Client/Timecards/Approve',
    TIMECARD_APPROVE_SINGLE = '/AyaConnect/Client/Timecards/{timecardId}/approve',
    TIMECARD_REJECT = '/AyaConnect/Client/Timecards/Reject',
    TIMECARD_STATUS_TOTALS = '/AyaConnect/Client/Timecards/StatusTotals',
    TIMECARD_SAVE = '/AyaConnect/Client/Timecards/{timecardId}/update-and-approve',
    TIMECARD_SAVE_WITHOUT_APPROVAL = '/AyaConnect/Client/Timecards/{timecardId}/update-without-approval',
    TIMECARDS_EXPORT = '/AyaConnect/Client/Timecards/Export',

    TIMECARDS_SECURITY_LIST = '/AyaConnect/Client/Timecards/security-lists',
    TIMECARDS_STATUSES = '/AyaConnect/Client/Timecards/statuses',
    TIMECARDS_PAYTYPES = '/AyaConnect/Client/Timecards/paytypes',

    PAYROLLDETAIL_SYNC = '/AyaConnect/Client/Payroll-Details/Sync'
}
