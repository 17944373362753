<mat-selection-list
    (selectionChange)="addOption($event)"
    [ngStyle]="filterStyle"
    [ngClass]="uniqueFilterClass">
    <mat-list-option *ngFor="let value of optionValues; index as i" [value]="value" [selected]="selectedValues.includes(value)">
        <ng-container *ngIf="optionTemplate">
            <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ value: value, title: optionTitles[i] }">
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!optionTemplate">
            <span>{{optionTitles[i]}}</span>
        </ng-container>
    </mat-list-option>
</mat-selection-list>