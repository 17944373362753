<section class="migrated-section-container">
    <fieldset>
        <legend>
            Banking
        </legend>
    
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header class="banks-header">
                    <mat-panel-title>
                        ACH Payment
                    </mat-panel-title>
                </mat-expansion-panel-header>
    
                <div [formGroup]="bankingForm" class="form" fxLayout="column" fxLayoutAlign="space-around stretch">
                    <div>
                        <label class="control-label" fxFlex="14">
                            Bank Name:
                        </label>
    
                        <mat-form-field floatLabel="never" appearance="outline">
                            <input data-qa-id="bankSelectionInput" matInput type="text" placeholder="Select or search a bank from the list..." (keydown)="addCustomBank($event)" formControlName="bank" [matAutocomplete]="auto">
                            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>

                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayBank" [autoActiveFirstOption]="true" (optionSelected)="bankSelected($event)" (optionActivated)="optionActivated($event)">
                                <mat-option *ngFor="let bank of (filteredBanks$ | async)" [value]="bank" [attr.data-qa-id]="bank.id">
                                    {{ bank.name }}
                                </mat-option>
                            </mat-autocomplete>
    
                            <mat-hint>Use TAB to add bank name to list.</mat-hint>
                        </mat-form-field>
                    </div>
    
                    <div>
                        <label class="control-label" fxFlex="14">
                            Routing No.:
                        </label>
    
                        <mat-form-field floatLabel="never" appearance="outline">
                            <input data-qa-id="bankRoutingNumInput" matInput formControlName="routingNum" placeholder="Routing Number">
                        </mat-form-field>
                    </div>
    
                    <div>
                        <label class="control-label" fxFlex="14">
                            Account No.:
                        </label>
    
                        <mat-form-field floatLabel="never" appearance="outline">
                            <input data-qa-id="bankAccountNumInput" matInput formControlName="accountNum" placeholder="Account Number">
                        </mat-form-field>
                    </div>
    
                    <div>
                        <label class="control-label" fxFlex="14">
                            Wire Routing No.:
                        </label>
    
                        <mat-form-field floatLabel="never" appearance="outline">
                            <input data-qa-id="bankWireRoutingNumInput" matInput formControlName="wireRoutingNum" placeholder="Wire Routing Number">
                        </mat-form-field>
                    </div>
    
                    <div>
                        <label class="control-label" fxFlex="14">
                            Beneficiary Name:
                        </label>
    
                        <mat-form-field floatLabel="never" appearance="outline">
                            <input data-qa-id="bankBeneficiaryInput" matInput formControlName="beneficiary" placeholder="Beneficiary Name">
                        </mat-form-field>
                    </div>
    
                    <div>
                        <label class="control-label" fxFlex="14">
                            Account Type:
                        </label>
    
                        <mat-button-toggle-group data-qa-id="bankAccountTypeButtonGroup" formControlName="accountType">
                            <mat-button-toggle data-qa-id="1" [value]="1">Checking</mat-button-toggle>
                            <mat-button-toggle data-qa-id="2" [value]="2">Savings</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
    
            </mat-expansion-panel>
        </mat-accordion>
    </fieldset>
</section>