import { Action, createReducer, on } from '@ngrx/store';
import {
    getInitialVendorProfileCertificationsState,
    VendorProfileCertificationsState
} from 'src/app/admin/store/state/vendor-profile-certifications.state';
import { LoadingTypes } from 'src/app/shared/models';
import * as actions from '../actions/vendor-profile-certifications.actions';

export function adminVendorProfileCertificationsReducer(state: VendorProfileCertificationsState, action: Action) {
    return createReducer(
        getInitialVendorProfileCertificationsState(),

        on(actions.loadVendorProfileCertifications, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING,
            vendorId: action.vendorId
        })),

        on(actions.loadVendorProfileCertificationsSuccess, (state, action) => ({
            ...state,
            certifications: action.response,
            loading: LoadingTypes.LOADED,
            vendorId: action.vendorId
        })),

        on(actions.loadVendorProfileCertificationsFailure, (state, action) => ({
            ...state,
            certifications: null,
            loading: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })),

        on(actions.loadVendorProfileMetadata, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(actions.loadVendorProfileMetadataSuccess, (state, action) => ({
            ...state,
            metadata: action.response,
            loading: LoadingTypes.LOADED
        })),

        on(actions.exportVendorCertifications, (state) => ({
            ...state,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.exportVendorCertificationsSuccess, (state) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.exportVendorCertificationsFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })),

        on(actions.updateVendorProfileCertifications, (state, action) => ({
            ...state,
            loadingSave: LoadingTypes.LOADING
        })),

        on(actions.updateVendorProfileCertificationsSuccess, (state, action) => ({
            ...state,
            loadingSave: LoadingTypes.LOADED
        })),

        on(actions.updateVendorProfileCertificationsFailure, (state, action) => ({
            ...state,
            loadingSave: LoadingTypes.FAIL,
            error: action.error
        }))
    )(state, action);
}

export function reducer(state: VendorProfileCertificationsState | undefined, action: Action) {
    return adminVendorProfileCertificationsReducer(state, action);
}
