<mat-card>
    <mat-card-header>
        <mat-card-title>Professional Details</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="content"
        *ngIf="editMode; then formTemplate; else viewTemplate">
    </mat-card-content>

    <ng-template #viewTemplate>
        <ng-container *ngIf="resource$ | async as resource">
            <div class="k-form-inline">
                <div class="k-form-field k-form-field-static">
                    <span><strong>Type:</strong></span>
                    <div>{{ resource.irpRecordTypeName }}</div>
                </div>
                <div class="k-form-field k-form-field-static">
                    <span><strong>Availability:</strong></span>
                    <div>{{ resource.availabilityStatus }}</div>
                </div>
                <div class="k-form-field k-form-field-static"
                    *hasSystemFields="['internalPool.jobTitle'];">
                    <span><strong>Job Title:</strong></span>
                    <div>{{ resource.jobTitle }}</div>
                </div>
                <div class="k-form-field k-form-field-static" *ngIf="!(removeNyuRequiredFields$ | async)">
                    <span><strong>System:</strong></span>
                    <div>{{ resource.systemName || resource.systemId }}</div>
                </div>                
                <ng-container *ifLdFeature="featureFlag.IRPCustomizedProfAndSpec">
                    <div class="k-form-field k-form-field-static" *ngIf="!(removeNyuRequiredFields$ | async)">
                        <span><strong>Profession:</strong></span>
                        <div>{{ resource.professionName }}</div>
                    </div>
                    <div class="k-form-field k-form-field-static" *ngIf="!(removeNyuRequiredFields$ | async)">
                        <span><strong>Specialties:</strong></span>
                        <div>
                            <div *ngFor="let expertise of resource.expertises">
                                {{ expertise.expertiseName }}
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="k-form-field k-form-field-static">
                    <span><strong>Facilities:</strong></span>
                    <div>
                        <div *ngFor="let facility of isFacilitiesExpanded
                        ? resource.facilities
                        : (resource.facilities | slice: 0 : 10)">
                            {{facility.facilityName}}
                        </div>
                        <app-expand-button
                            *ngIf="resource.facilities.length > 10"
                            [(expanded)]="isFacilitiesExpanded"
                            [showIcon]="false"
                            [lessText]="'Collapse facilities'"
                            [moreText]="'Show all ' + resource.facilities.length + ' facilities'"
                        ></app-expand-button>
                    </div>
                </div>
                <ng-container *ifLdFeature="featureFlag.IRPCustomizedProfAndSpec; enabled: false">
                    <div class="k-form-field k-form-field-static" *ngIf="!(removeNyuRequiredFields$ | async)">
                        <span><strong>Profession:</strong></span>
                        <div>{{ resource.professionName }}</div>
                    </div>
                    <div class="k-form-field k-form-field-static" *ngIf="!(removeNyuRequiredFields$ | async)">
                        <span><strong>Specialties:</strong></span>
                        <div>
                            <div *ngFor="let expertise of resource.expertises">
                                {{ expertise.expertiseName }}
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="k-form-field k-form-field-static">
                    <span><strong>Hourly Rate:</strong></span>
                    <div>{{ resource.hourlyRate }}</div>
                </div>
                <div class="k-form-field k-form-field-static">
                    <span><strong>Preferred Shifts:</strong></span>
                    <div>
                        <table class="preferred-shift-table" aria-label="Preferred Shifts">
                            <tr class="hidden">
                                <th colspan="3" scope="colgroup"></th>
                            </tr>
                            <tr *ngFor="let ps of resource.preferredShifts">
                                <td>{{PreferredShiftDays[ps.day]}}:</td>
                                <td>{{ps.shiftHours}}hr</td>
                                <td>
                                    {{ps.startTime | date: 'hh:mm a'}}
                                    -
                                    {{
                                        ps.startTime
                                        | addDuration : ps.shiftHours
                                        | date: 'hh:mm a'
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="k-form-field k-form-field-static" *hasCustomSystemField="'PreferredLocation'">
                    <span><strong>Preferred Location:</strong></span>
                    <div>
                        <div *ngFor="let item of customFieldsLookup$
                            | async
                            | customFieldFind: 'PreferredLocation'
                            | customFieldIncludes: resource.customFields">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class="k-form-field k-form-field-static" *hasCustomSystemField="'PreferredLocation'; operation 'NOT'">
                    <span><strong>Preferred Location:</strong></span>
                    <div>
                        <div *ngFor="let preferredFacility of resource.preferredFacilities">
                            {{ preferredFacility.facilityName }}
                        </div>
                    </div>
                </div>
                <div class="k-form-field k-form-field-static">
                    <span><strong>Language Skills:</strong></span>
                    <div>
                        <div *ngFor="let lang of resource.languageSkills">
                            {{ lang.language }} ({{ lang.skillLevel }})
                        </div>
                    </div>
                </div>
                <div class="k-form-field k-form-field-static" *hasCustomSystemField="'epicSkills'">
                    <span><strong>Epic Skills:</strong></span>
                    <div>
                        <div *ngFor="let epicSkill of resource.epicSkills">
                            {{ epicSkill }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #formTemplate>
        <ng-container *ngIf="updateForm$ | async"></ng-container>

        <form [formGroup]="form" class="k-form-inline">
            <div class="k-form-field">
                <label>
                    Type
                </label>
                <mat-radio-group
                    formControlName="irpRecordType">
                    <div *ngFor="let item of resourceTypeLookups$ | async">
                        <mat-radio-button
                            [value]="item.id">
                            {{item.name}}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>

            <div class="k-form-field">
                <label>
                    Availability
                </label>
                <kendo-combobox
                    formControlName="availabilityId"
                    textField="name"
                    valueField="id"
                    [valuePrimitive]="true"
                    [data]="availabilityLookups$ | async">
                </kendo-combobox>
                <validation-message
                    label="Preferred Shift"
                    [control]="form.get('availabilityId')">
                </validation-message>
            </div>

            <div class="k-form-field"
                *hasSystemFields="['internalPool.jobTitle'];">
                <label>
                    Job Title
                    <span class="k-required" *ngIf="!(removeNyuRequiredFields$ | async)">*</span>
                </label>

                <input kendoTextBox formControlName="jobTitle" />
                <validation-message label="Job Title" [control]="form.get('jobTitle')">
                </validation-message>
            </div>

            <label class="k-form-field" *ngIf="(systemLookups$ | async)?.length > 1 && !(removeNyuRequiredFields$ | async)">
                <span>
                    System
                    <span class="k-required">*</span>
                </span>
                <kendo-combobox
                    formControlName="systemId"
                    [data]="systemLookups$ | async"
                    [valuePrimitive]="true"
                    textField="name"
                    valueField="id"
                    [kendoDropDownFilter]="filterSettings">
                </kendo-combobox>
                <validation-message
                    label="System"
                    [control]="form.get('systemId')">
                </validation-message>
            </label>
            
            <ng-container *ifLdFeature="featureFlag.IRPCustomizedProfAndSpec">
                <label class="k-form-field" *ngIf="!(removeNyuRequiredFields$ | async)">
                    <span>
                        Profession
                        <span class="k-required">*</span>
                    </span>
                    <kendo-combobox
                        formControlName="professionId"
                        [data]="(professionLookups$ | async)"
                        [valuePrimitive]="true"
                        textField="name"
                        valueField="id"
                        [kendoDropDownFilter]="filterSettings">
                    </kendo-combobox>
                    <validation-message
                        label="Profession"
                        [control]="form.get('professionId')">
                    </validation-message>
                </label>

                <label class="k-form-field" *ngIf="!(removeNyuRequiredFields$ | async)">
                    <span>
                        Specialties
                        <span class="k-required">*</span>
                    </span>
                    <kendo-multiselect
                        formControlName="expertiseIds"
                        [data]="filteredSpecialtyLookups$ | async"
                        [valuePrimitive]="true"
                        textField="name"
                        valueField="id"
                        [kendoDropDownFilter]="filterSettings">
                    </kendo-multiselect>
                    <validation-message
                        label="Specialties"
                        [control]="form.get('expertiseIds')">
                    </validation-message>
                </label>
            </ng-container>

            <div class="k-form-field"
                [class.has-static-text]="hasReadonlyFacilities$ | async"
                [class.k-form-field-static]="hasReadonlyFacilities$ | async">
                <span>
                    Facilities
                    <span class="k-required" *ngIf="!(removeNyuRequiredFields$ | async)">*</span>
                </span>

                <div>
                    <ng-container *ngIf="readonlyFacilities$ | async as readonlyFacilities">
                        <div *ngFor="let facility of isFacilitiesExpanded
                            ? readonlyFacilities
                            : (readonlyFacilities | slice: 0 : 10)">
                            {{facility.name}}
                        </div>

                        <app-expand-button
                            *ngIf="readonlyFacilities.length > 10"
                            [(expanded)]="isFacilitiesExpanded"
                            [showIcon]="false"
                            [lessText]="'Collapse facilities'"
                            [moreText]="'Show all ' + readonlyFacilities.length + ' facilities'"
                        ></app-expand-button>
                        <div class="mb-2"></div>
                    </ng-container>
                </div>

                <kendo-multiselect
                    class="limited-height"
                    [itemDisabled]="facilityDisabledFn"
                    [data]="filteredFacilityLookups$ | async"
                    [valuePrimitive]="true"
                    textField="name"
                    valueField="id"
                    [kendoDropDownFilter]="filterSettings"
                    formControlName="facilityIds" #facilityMultiSelect>
                    <ng-template kendoMultiSelectHeaderTemplate let-dataItem>
                        <div class="k-list">
                            <button mat-button
                                (click)="selectAll()"
                                class="k-item dropdown-header-btn">
                                Select All
                            </button>
                        </div>
                    </ng-template>
                </kendo-multiselect>
                <validation-message
                    label="Facility"
                    [control]="form.get('facilityIds')">
                </validation-message>
            </div>

            <ng-container *ifLdFeature="featureFlag.IRPCustomizedProfAndSpec">
                <ayac-banner
                    *ngIf="showFacilityBanner$ | async"
                    class="mb-4 mt-3"
                    color="grey"
                    align="left"
                    icon="info"
                    iconSize="extra-small"
                    iconAlignment="top"
                    [closeHidden]="true">
                    Facilities are matched based on selected profession and specialties.
                </ayac-banner>
            </ng-container>
            
            <ng-container *ifLdFeature="featureFlag.IRPCustomizedProfAndSpec; enabled: false">

                <label class="k-form-field" *ngIf="!(removeNyuRequiredFields$ | async)">
                    <span>
                        Profession
                        <span class="k-required">*</span>
                    </span>
                    <kendo-combobox
                        formControlName="professionId"
                        [data]="(professionLookups$ | async)"
                        [valuePrimitive]="true"
                        textField="name"
                        valueField="id"
                        [kendoDropDownFilter]="filterSettings">
                    </kendo-combobox>
                    <validation-message
                        label="Profession"
                        [control]="form.get('professionId')">
                    </validation-message>
                </label>

                <label class="k-form-field" *ngIf="!(removeNyuRequiredFields$ | async)">
                    <span>
                        Specialties
                        <span class="k-required">*</span>
                    </span>
                    <kendo-multiselect
                        formControlName="expertiseIds"
                        [data]="filteredSpecialtyLookups$ | async"
                        [valuePrimitive]="true"
                        textField="name"
                        valueField="id"
                        [kendoDropDownFilter]="filterSettings">
                    </kendo-multiselect>
                    <validation-message
                        label="Specialties"
                        [control]="form.get('expertiseIds')">
                    </validation-message>
                </label>
            </ng-container>

            <div class="k-form-field">
                <label>
                    Hourly Rate
                </label>
                <input kendoTextBox formControlName="hourlyRate" matInputCommified maxlength="7"  />
            </div>

            <div class="k-form-field">
                <label>
                    Preferred Shifts
                </label>
                <div>
                    <table class="preferred-shift-table" aria-label="Preferred Shifts">
                        <tr class="hidden">
                            <th colspan="3" scope="colgroup"></th>
                        </tr>
                        <tr *ngFor="let ps of form.get('preferredShifts').value">
                            <td>{{PreferredShiftDays[ps.day]}}:</td>
                            <td>{{ps.shiftHours}}hr</td>
                            <td>
                                {{ps.startTime | date: 'hh:mm a'}}
                                -
                                {{
                                    ps.startTime
                                    | addDuration : ps.shiftHours
                                    | date: 'hh:mm a'
                                }}
                            </td>
                        </tr>
                    </table>
                    <button
                        mat-button
                        color="accent"
                        (click)="onEditPreferredShift()">
                        Edit Preferred Shift
                    </button>

                </div>
            </div>

            <div class="k-form-field" *hasCustomSystemField="'PreferredLocation'; operation 'NOT'">
                <label>
                    Preferred Location
                </label>
                <kendo-multiselect
                    [data]="preferredFacilityLookups$ | async"
                    [valuePrimitive]="true"
                    textField="name"
                    valueField="id"
                    [kendoDropDownFilter]="filterSettings"
                    formControlName="preferredLocationIds">
                </kendo-multiselect>
            </div>

            <div class="k-form-field" *hasCustomSystemField="'PreferredLocation'">
                <label>
                    Preferred Location
                </label>
                <kendo-multiselect
                    [data]="getCustomFieldValueList('PreferredLocation') | async"
                    textField="name"
                    valueField="id"
                    formControlName="customPreferredLocationIds"
                    [valuePrimitive]="true"
                    [kendoDropDownFilter]="filterSettings"
                    (valueChange)="customFieldValueChanged('PreferredLocation', $event)">
                </kendo-multiselect>
            </div>

            <div class="k-form-field">
                <label>
                    Language Skills
                </label>
                <div fxFlex>

                    <div *ngFor="let language of languageSkills.controls; let i = index;"
                        formArrayName="languageSkills"
                        fxLayout="row"
                        fxLayoutGap="16px">

                        <ng-container [formGroupName]="i">
                            <div class="k-form-field" fxFlex="40%">
                                <kendo-combobox
                                    formControlName="languageId"
                                    textField="name"
                                    valueField="id"
                                    [valuePrimitive]="true"
                                    [data]="languageLookups$ | async">
                                </kendo-combobox>
                            </div>
                            <div class="k-form-field" fxFlex="60%">
                                <label>
                                    Skill Level
                                </label>
                                <kendo-combobox
                                    formControlName="skillLevelId"
                                    textField="name"
                                    valueField="id"
                                    [valuePrimitive]="true"
                                    [data]="languageSkillLookups$ | async">
                                </kendo-combobox>
                            </div>

                        </ng-container>

                    </div>


                    <button mat-button color="accent" (click)="onAddLanguageSkill()">
                        <mat-icon>add</mat-icon>
                        Add Language
                    </button>
                </div>
            </div>

            <div class="k-form-field" *hasCustomSystemField="'EpicSkills'">
                <label>
                    Epic Skills
                </label>
                <kendo-multiselect
                    textField="name"
                    valueField="id"
                    formControlName="epicSkills"
                    [valuePrimitive]="true"
                    [data]="getCustomFieldValueList('EpicSkills') | async"
                    (valueChange)="customFieldValueChanged('EpicSkills', $event)">
                </kendo-multiselect>
            </div>
        </form>
    </ng-template>
</mat-card>
