<div class="container-fluid">

    <ayac-contact-toolbar [hasChanges]="hasChanges"
    [disableSaveAndCancel]="!canSave()"
    [qaId]="'ad_vendor_detail'" (backClicked)="goBack()" (saveClicked)="save()" (cancelClicked)="goBack()">
    </ayac-contact-toolbar>

    <div *ngIf="isLoading || isVendorContactsLoading || !vendor" class="page-splash dissolve-animation">
        <mat-spinner style="left: 50%; top: 50%;"></mat-spinner>
    </div>

    <div>
        <div class="vendor-name">
            <span class="text" *ngIf="vendor">Vendor: {{vendor.legalName || 'New Vendor'}}</span>
        </div>
        <div class="widget">
            <div class="flex-row widget-content">
                <div class="col-md-9 flex-75">
                    <mat-tab-group color="accent" animationDuration="0ms" [disableRipple]="true">
                        <mat-tab label="Profile">
                            <ng-template mat-tab-label class="nav-label">
                                <mat-icon>notifications</mat-icon>
                                Profile
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="tab-content" *ngIf="vendor">
                                    <!-- Modern angular component only shown if feature flag is true -->
                                    <ayac-vendor-profile [vendorDetails]="vendor" [states]="states"
                                        (formChanged)="formChanged($event)"></ayac-vendor-profile>
                                    <!-- End of modern component -->

                                    <ayac-vendor-job-rules-panel
                                        [isLoading]="isVendorRulesLoading$ | async"
                                        [jobRules]="jobRules$ | async"
                                        [jobRuleDetails]="jobRuleDetails$ | async"
                                        [isNew]="isNew"
                                        [professionTypeTree]="professionTypeTree$ | async"
                                        [professionTypeValues]="professionTypeValues$ | async"
                                        (jobRuleDetailEmit)="getJobRuleDetails($event)"></ayac-vendor-job-rules-panel>

                                    <div class="panel panel-default" *ngIf="certifications">
                                        <div class="panel-body">
                                            <form name="certificationForm" class="form-horizontal" role="form">
                                                <ayac-vendor-profile-certifications
                                                    (saveResult)="saveResultFromAngular($event)"
                                                    (isLoading)="setLoading($event)" [vendorId]='vendor.id'
                                                    [vendorName]='vendor.operatingName ? vendor.operatingName : vendor.legalName'>
                                                </ayac-vendor-profile-certifications>
                                            </form>
                                        </div>
                                    </div>

                                    <ayac-vendor-banking [banks]="banks" [vendorDetails]="vendor"
                                        (customBankAdded)="addNewBankFromAngular($event)"
                                        (bankInfoChanged)="bankInfoChangedFromAngular($event)"
                                        (bankNameChanged)="bankNameChangedFromAngular($event)">
                                    </ayac-vendor-banking>

                                    <ayac-vendor-phones [isSaving]="isSaving" [vendorId]="vendor.id"
                                        [saveCalled]="saveVendorPhonesCalled$" (onChangesMade)="hasChanges = true">
                                    </ayac-vendor-phones>

                                    <ayac-vendor-emails [isSaving]="isSaving" [vendorId]="vendor.id"
                                        [saveCalled]="saveVendorEmailsCalled$" (onChangesMade)="hasChanges = true">
                                    </ayac-vendor-emails>

                                    <ayac-vendor-profile-websites [vendorId]="vendor.id" [isSaving]="isSaving">
                                    </ayac-vendor-profile-websites>

                                    <ayac-vendor-locations [isSaving]="isSaving" [vendorId]="vendor.id">
                                    </ayac-vendor-locations>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Contacts">
                            <ng-template mat-tab-label class="nav-label">
                                <mat-icon>notifications</mat-icon>
                                Contacts
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="tab-content">
                                    <aya-vendor-contacts [vendorContacts]="vendorContacts"
                                        [vendorContactsTotal]="vendorContactsTotal" [states]="states"
                                        [contactTags]="contactTags" [phoneTypes]="contactPhoneTypes"
                                        [emailTypes]="contactEmailTypes" [vendorId]="vendor.id" [isSaving]="isSaving"
                                        [contactsPaginationArgs]="contactsPagination"
                                        (vendorContactsChanged)="vendorContactsChanged($event)"
                                        (vendorContactsPaginationChange)="vendorContactsPaginationChange($event)">
                                    </aya-vendor-contacts>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Facilities">
                            <ng-template mat-tab-label class="nav-label">
                                <mat-icon>notifications</mat-icon>
                                Facilities
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="tab-content" *ngIf="vendor">
                                    <ayac-vendor-facilities [vendorId]="vendor.id"
                                        [vendorNotes]="vendor.includeExcludeNotes"
                                        (notesChange)="setNoteChange($event)">
                                    </ayac-vendor-facilities>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Files">
                            <ng-template mat-tab-label class="nav-label">
                                <mat-icon>notifications</mat-icon>
                                Files
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="tab-content" *ngIf="vendor">
                                    <ayac-vendor-details-files [vendorId]="vendor.id"></ayac-vendor-details-files>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Insurance">
                            <ng-template mat-tab-label class="nav-label">
                                <mat-icon>notifications</mat-icon>
                                Insurance
                            </ng-template>
                            <ng-template matTabContent>
                                <div class="tab-content" *ngIf="vendor">
                                    <ayac-vendor-insurance [vendorInsurance]="vendor"
                                        (exportInsurance)="exportInsurance($event)"
                                        (formChanged)="insuranceFormChanged($event)"></ayac-vendor-insurance>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div class="col-md-3 flex-display flex-25 no-padding visible-lg visible-md migrated-notes-bar"
                    [ngClass]="{ 'notes-top': notesToTop === true }" *ngIf="!isNew && vendor">
                    <ayac-vendor-notes [vendorId]="vendor.id"></ayac-vendor-notes>
                </div>
            </div>
        </div>
    </div>
</div>
