import { createAction, props } from '@ngrx/store';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { FullEmailVm } from 'src/app/shared/models/full-email-vm.model';
import { ShiftSubmittalList } from 'src/app/shifts-submittals/models';
import { BookedCandidates } from '../../models';

export const loadShiftSubmittals = createAction('[SHIFTS API] Load Shift Submittals', props<{ shiftId: number }>());

export const loadShiftSubmittalsSuccess = createAction(
    '[SHIFTS] Load Shift Submittals Success',
    props<{ submittals: ShiftSubmittalList[] }>()
);

export const loadShiftSubmittalsFail = createAction('[SHIFTS] Load Shift Submittals Fail', props<{ error: any }>());

export const loadShiftBookedCandidates = createAction(
    '[SHIFTS API] Load Shift Candidates Booked',
    props<{ shiftId: number }>()
);

export const loadShiftBookedCandidatesSuccess = createAction(
    '[SHIFTS BOOKED CANDIDATES API] Load Shift Candidates Success',
    props<{ bookedCandidates: BookedCandidates[] }>()
);

export const loadShiftBookedCandidatesFail = createAction(
    '[SHIFTS BOOKED CANDIDATES API] Load Shift Candidates Fail',
    props<{ error: any }>()
);

export const loadSubmittalEmailOffer = createAction(
    '[SHIFTS SUBMITTALS API] Load Shift Submittal Email Offer',
    props<{ submittalId: number }>()
);

export const loadSubmittalEmailOfferSuccess = createAction(
    '[SHIFTS SUBMITTALS] Load Shift Submittall Email Offer Success',
    props<{ fullEmailOffer: FullEmailVm }>()
);

export const loadSubmittalEmailOfferFail = createAction(
    '[SHIFTS SUBMITTALS] Load Shift Submittal Email Offer Fail',
    props<{ error: any }>()
);

export const setShiftSubmittalsQuery = createAction(
    '[SHIFTS] Set Shift Submittals Query',
    props<{ query: Partial<GridSearchQuery> }>()
);

export const updateShiftSubmittalsStatus = createAction(
    '[SHIFTS] Update Shift Submittals Status',
    props<{ submittalId: number; status: number; note: string; declineReasonId?: number; shiftId?: number }>()
);

export const updateShiftSubmittalsStatusSuccess = createAction('[SHIFTS] Update Shift Submittals Status Success');

export const updateShiftSubmittalsStatusFail = createAction(
    '[SHIFTS] Update Shift Submittals Status Fail',
    props<{ error: any }>()
);

export const clearShiftSubmittals = createAction('[SHIFTS] Clear Shift Submittals');
