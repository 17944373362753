<dialog-layout data-qa-id="ac_fpd_dialog_container"
               [title]="data?.title"
               [isLoading]="loading$ | async">
    <div mat-dialog-content>
        <p>If you have forgotten your password you can reset it here.</p>
        <p>Please, enter your Username</p>

        <form class="k-form-inline"
              [formGroup]="form">

            <label class="k-form-field fp-input">
                <span class="input-group-addon"><em class="glyphicon glyphicon-user"></em></span>
                <input class="k-textbox"
                       placeholder="Your Username"
                       data-qa-id="ac_fpd_username_input"
                       formControlName="username" />
                <validation-message label="Username"
                                    [control]="form.get('username')">
                </validation-message>
            </label>
        </form>

    </div>
    <div mat-dialog-actions>
        <button mat-flat-button
                data-qa-id="ac_fpd_submit_button"
                color="accent"
                [disabled]="form.invalid"
                (click)="form.valid ? submit() : null">
            Reset My Password
        </button>
        <button mat-stroked-button
                data-qa-id="ac_fpd_reject_button"
                mat-dialog-close>
            Cancel
        </button>
    </div>
</dialog-layout>
