<div class="custom-widget">   
    <div class="ayac-widget-breadcrumb">
        <span *ngIf="collapsible" (click)="onHeaderClicked($event)">
            <em *ngIf="collapsed" class="fa fa-plus-square"></em>
            <em *ngIf="!collapsed" class="fa fa-minus-square"></em>
        </span>
        <ng-content select="[widget-breadcrumb]"></ng-content>
    </div>
    <div class="ayac-widget-header" [ngClass]="headerClassName">
        <ng-content select="[widget-header]"></ng-content>
    </div>
    <div [hidden]="!!collapsible && !!collapsed" class="ayac-widget-content">
        <ng-content></ng-content>
    </div>
</div>