<div class="calendar">
    <div class="calendar-navigation">
        <span>{{date | date: 'MMMM yyyy'}}</span>
        <button mat-icon-button (click)="prevMonth()">
            <mat-icon>arrow_left</mat-icon>
        </button>
        <button mat-icon-button (click)="nextMonth()">
            <mat-icon>arrow_right</mat-icon>
        </button>
    </div>

    <div class="calendar-header">
        <span>Su</span>
        <span>Mo</span>
        <span>Tu</span>
        <span>We</span>
        <span>Th</span>
        <span>Fr</span>
        <span>Sa</span>
    </div>
    <div class="calendar-month">
        <div class="calendar-day"
            [class.other-month]="!day.isCurrentMonth"
            [class.disabled]="day.isDisabled"
            [class.today]="day.isToday"
            *ngFor="let day of days; trackBy: trackByFn">
            
            <div
                class="calendar-day-cell"
                [ngClass]="day.className">
            </div>

            <span class="calendar-day-number">
                {{ day.dayNumber }}
            </span>

            <div *ngFor="let line of day.text"
                class="calendar-day-text">
                {{line}}
            </div>

            <div *ngFor="let event of eventsForDay(day); trackBy: trackEventFn"
                class="calendar-event"
                [ngClass]="{
                    'calendar-event-start': event.isStart,
                    'calendar-event-end': event.isEnd,
                    'calendar-event-range': event.isRange,
                    'calendar-event-holder': event.isHolder
                }"
                [ngStyle]="{
                    'backgroundColor': event.event?.color
                }">
                {{event.event?.title}}
            </div>
        </div>
    </div>
</div>