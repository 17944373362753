<form class="contact-form" [formGroup]="contactPhoneForm">
    <ng-container formArrayName="phones">
        <div class="phone-input-wrapper" *ngFor="let phone of phones.controls; let i = index;">
            <ng-container [formGroupName]="i">
                <mat-form-field class="left-field" appearance="outline">
                    <mat-select formControlName="phoneTypeId">
                        <mat-option *ngFor="let phone of phoneTypes" [value]="phone.id">
                            {{phone.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="right-field" appearance="outline">
                    <input matInput class="form-input" type="tel" formControlName="phoneNumber" [specialCharacters]="['(',')',' ','-','e','x','t','.']"
                        mask="(000) 000-0000||(000) 000-0000 ext. 9999">
                    <mat-error *ngIf="phone.get('phoneNumber').hasError('required')">
                        Please enter a phone number.
                    </mat-error>
                    <mat-error *ngIf="phone.get('phoneNumber').hasError('mask')">
                        Not a valid phone number.
                    </mat-error>
                </mat-form-field>

                <button class="delete-button" mat-icon-button matTooltip="Remove phone" (click)="removePhone(i)">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-container>
        </div>
    </ng-container>

    <div class="actions">
        <button mat-flat-button type="button" color="accent" class="migrated-icon-button" matTooltip="Add new phone" (click)="addPhone()">
            <mat-icon>add</mat-icon>
            Add Phone
        </button>
    </div>
</form>