import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities/route';
import {
    TravelersLookup,
    Travelers,
    TravelerProfile,
    TravelerProfileUpdate,
    TravelerProfileNyuUpdate,
    SclSupervisor,
    SclDepartment,
    TravelerJobAddDto,
    TravelerJobGetDto,
    TravelerJob,
    TravelerJobList,
    PayrollLocationModel,
    BuildingModel,
    DepartmentModel,
    FloorModel,
    BusinessUnitModel
} from '../travelers.models';
import { GridStateChangeEvent } from 'src/app/shared/grid/models';
import { FilterDescriptor, process } from '@progress/kendo-data-query';
import { interpolateParams } from 'src/app/core/utils/interpolate-params';
import { SortTypes } from 'src/app/shared/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TravelerPrismaPositionDto } from 'src/app/travelers/travelers.models';
import { StaffingAdvisory } from 'src/app/travelers/models';
import { TravelerDocumentGridItem } from '../traveler-details/traveler-documents/grid/traveler-documents-grid.model';
import { DocumentHistoryDto } from '../traveler-details/traveler-documents-history-modal/document-history-dto';

@Injectable({
    providedIn: 'root'
})
export class TravelersService {
    private coreApiPath: string;
    private travelerPath: string;
    private travelerJobPath: string;
    private travelerPrismaPositionPath: string;
    private travelerDocumentPath: string;
    private readonly integrationPath: string;
    private readonly travelerBjcWorkerPath: string;

    constructor(private readonly _http: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {
        this.coreApiPath = combine(_settings.CORE, 'ayaconnect');
        this.travelerPath = combine(this.coreApiPath, '/Client/Traveler');
        this.travelerJobPath = combine(this.travelerPath, '/job');
        this.travelerPrismaPositionPath = combine(this.travelerPath, '/PrismaJob');
        this.travelerDocumentPath = combine(this.travelerPath, '/document');
        this.travelerBjcWorkerPath = combine(this.coreApiPath, '/Client/MspTraveler');
        this.integrationPath = combine(this.coreApiPath, '/Client/Integration');
    }

    getLookups(): Observable<TravelersLookup> {
        const url = `${this.travelerPath}/Lookup`;
        return this._http.get<TravelersLookup>(url);
    }

    getTravelersListContainsVMSFacility(): Observable<boolean> {
        const url = `${this.travelerPath}/contains-vms-facility`;
        return this._http.get<boolean>(url);
    }

    getTravelers(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        const params = interpolateParams({
            ...pagination,
            ...sortArgs,
            ...matchArgs
        });

        const url = `${this.travelerPath}`;
        return this._http.get<Travelers[]>(url, { params });
    }

    getTraveler(id: number): Observable<TravelerProfile> {
        const url = `${this.travelerPath}/${id}`;
        return this._http.get<TravelerProfile>(url);
    }

    getSupervisors(): Observable<SclSupervisor[]> {
        const url = `${this.travelerPath}/scl-supervisors`;
        return this._http.get<SclSupervisor[]>(url);
    }

    getDepartments(employeeId: number): Observable<SclDepartment[]> {
        const url = `${this.travelerPath}/scl-departments/${employeeId}`;
        return this._http.get<SclDepartment[]>(url);
    }

    saveTraveler(traveler: TravelerProfileUpdate): Observable<any> {
        const url = `${this.travelerPath}`;
        return this._http.put(url, traveler);
    }

    saveTravelerNyu(traveler: TravelerProfileNyuUpdate): Observable<unknown> {
        const url = `${this.travelerPath}/nyu`;
        return this._http.put(url, traveler);
    }

    getTravelerJobs(job: TravelerJobGetDto): Observable<any> {
        const params = interpolateParams({
            facilityId: job.facilityId,
            professionId: job.professionId,
            monthsBack: job.monthsBack
        });

        const url = `${this.travelerJobPath}`;
        return this._http.get<TravelerJobList>(url, { params });
    }

    saveTravelerJob(job: TravelerJobAddDto): Observable<any> {
        return this._http.post(`${this.travelerJobPath}?travelerId=${job.travelerId}&jobId=${job.jobId}`, {});
    }

    removeTravelerJob(travelerId: number): Observable<any> {
        return this._http.delete(`${this.travelerJobPath}?travelerId=${travelerId}`);
    }

    saveTravelerPrismaPositionId(prismaPosition: TravelerPrismaPositionDto): Observable<any> {
        return this._http.post(`${this.travelerPrismaPositionPath}`, {
            travelerId: prismaPosition.travelerId,
            prismaPositionId: prismaPosition.prismaPositionId
        });
    }

    removeTravelerPrismaPositionId(travelerId: number): Observable<any> {
        return this._http.delete(`${this.travelerPrismaPositionPath}?travelerId=${travelerId}`);
    }

    saveTravelerBjcWorkerId(bjcWorkerId: string, userId: number): Observable<any> {
        return this._http.post(`${this.travelerBjcWorkerPath}/${userId}`, {
            bjcWorkerId: bjcWorkerId
        });
    }

    removeTravelerBjcWorkerId(userId: number): Observable<any> {
        return this._http.delete(`${this.travelerBjcWorkerPath}/${userId}`);
    }

    filterTravelerJobs(state: GridStateChangeEvent, jobs: TravelerJob[]): Observable<GridDataResult> {
        var gridData = process(jobs, state);
        return of(gridData);
    }

    export(
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        const params = interpolateParams({
            ...matchArgs,
            ...sortArgs
        });
        const url = `${this.travelerPath}/export`;
        return this._http.get(url, { params, responseType: 'blob' });
    }

    updateClearedStatus(contractId: number, clearedToStart: boolean): Observable<any> {
        const url = `${this.travelerPath}/${contractId}/upsert-cleared-status?clearedToStart=${clearedToStart}`;

        return this._http.put(url, {});
    }

    getTravelerDocuments(contractId: number): Observable<TravelerDocumentGridItem[]> {
        const url = `${this.travelerDocumentPath}/${contractId}`;

        return this._http.get<TravelerDocumentGridItem[]>(`${url}`);
    }

    getTravelerDocumentsHistory(contractId: number, requirementId: number): Observable<DocumentHistoryDto[]> {
        const url = `${this.travelerDocumentPath}/${contractId}/document-history/${requirementId}`;
        return this._http.get<DocumentHistoryDto[]>(`${url}`);
    }

    downloadTravelerDocuments(contractId: number, documentIds: number[]): Observable<Blob> {
        const url = `${this.travelerDocumentPath}/${contractId}/stream`;

        return this._http.post(url, documentIds, { responseType: 'blob' });
    }

    downloadAllTravelerDocuments(contractId: number): Observable<Blob> {
        const url = `${this.travelerDocumentPath}/${contractId}/stream`;

        return this._http.get(url, { responseType: 'blob' });
    }

    exportGrid(contractId: number): Observable<Blob> {
        const url = `${this.travelerDocumentPath}/${contractId}/excel`;

        return this._http.get(url, { responseType: 'blob' });
    }

    filterTravelerDocuments(
        state: GridStateChangeEvent,
        documents: TravelerDocumentGridItem[]
    ): Observable<GridDataResult> {
        let deletedExpDates: FilterDescriptor;
        let expirationDateFiltersIndex;

        // Expiration Date filter need to be preserved
        if (state.filter && state.filter.filters.length > 0) {
            expirationDateFiltersIndex = state.filter.filters.findIndex(
                (a: FilterDescriptor) => a.field === 'expirationDate'
            );
            if (expirationDateFiltersIndex >= 0) {
                deletedExpDates = state.filter.filters.splice(expirationDateFiltersIndex, 1)[0] as FilterDescriptor;
            }
        }

        var gridData = process(documents, state);

        // Apply expiration filter
        if (deletedExpDates && deletedExpDates.value.length > 0) {
            let startDate: Date, endDate: Date, startDate2: Date, endDate2: Date;
            ({ startDate, endDate, startDate2, endDate2 } = this.getExpirationDateRanges(
                deletedExpDates,
                startDate,
                endDate,
                startDate2,
                endDate2
            ));

            // Filter gridData
            let expirationDateRange: boolean;
            const docs: TravelerDocumentGridItem[] = gridData.data;
            const filteredDocs = docs.filter((d) => {
                const expdate = new Date(d.expirationDate);
                expirationDateRange = expdate >= startDate && expdate <= endDate;
                if (startDate2 && endDate2) {
                    expirationDateRange = expirationDateRange || (expdate >= startDate2 && expdate <= endDate2);
                }

                return expirationDateRange;
            });

            // re-add filter to state
            state.filter.filters.splice(expirationDateFiltersIndex, 0, deletedExpDates);

            return of({ data: filteredDocs, total: filteredDocs.length });
        }

        return of(gridData);
    }

    getExpirationDateRanges(
        deletedExpDates: FilterDescriptor,
        startDate: Date,
        endDate: Date,
        startDate2: Date,
        endDate2: Date
    ) {
        const daysExpired = 0;
        const days306090 = [30, 60, 90];
        const days3060 = [30, 60];
        const days6090 = [60, 90];
        const days3090 = [30, 90];

        const includeExpired = deletedExpDates.value.includes(daysExpired);
        const allExist = days306090.every((value) => {
            return deletedExpDates.value.includes(value);
        });
        const days3060Exist = days3060.every((value) => {
            return deletedExpDates.value.includes(value);
        });
        const days6090Exist = days6090.every((value) => {
            return deletedExpDates.value.includes(value);
        });
        const days3090Exist = days3090.every((value) => {
            return deletedExpDates.value.includes(value);
        });

        if (allExist) {
            startDate = this.addDays(0, new Date());
            endDate = this.addDays(90, new Date());
        } else if (days3060Exist) {
            startDate = this.addDays(0, new Date());
            endDate = this.addDays(60, new Date());
        } else if (days6090Exist) {
            startDate = this.addDays(31, new Date());
            endDate = this.addDays(90, new Date());
        } else if (days3090Exist) {
            startDate = this.addDays(0, new Date());
            endDate = this.addDays(30, new Date());
            startDate2 = this.addDays(61, new Date());
            endDate2 = this.addDays(90, new Date());
        } else {
            if (deletedExpDates.value.includes(30)) {
                startDate = this.addDays(0, new Date());
                endDate = this.addDays(30, new Date());
            } else if (deletedExpDates.value.includes(60)) {
                startDate = this.addDays(31, new Date());
                endDate = this.addDays(60, new Date());
            } else if (deletedExpDates.value.includes(90)) {
                startDate = this.addDays(61, new Date());
                endDate = this.addDays(90, new Date());
            }
        }

        if (includeExpired) {
            if (!startDate) {
                startDate = new Date(2001, 0, 1);
                endDate = this.addDays(-1, new Date());
            } else {
                startDate2 = new Date(2001, 0, 1);
                endDate2 = this.addDays(-1, new Date());
            }
        }

        return { startDate, endDate, startDate2, endDate2 };
    }

    addDays(numOfDays: number, date = new Date()) {
        date.setDate(date.getDate() + numOfDays);
        date.setHours(0, 0, 0, 0);

        return date;
    }

    putWocChoice(contractId: number): Observable<any> {
        const url = `${this.coreApiPath}/client/traveler/woc/${contractId}`;
        return this._http.put(url, {});
    }

    getWocPdf(contractId: number): Observable<Blob> {
        const url = `${this.coreApiPath}/client/traveler/woc/${contractId}`;
        return this._http.get(url, { responseType: 'blob' });
    }

    getStaffingAdvisories(
        systemId: number,
        facilityId: number,
        unitId: number,
        travelerId: number
    ): Observable<Array<StaffingAdvisory>> {
        const request = {
            systemId: systemId,
            facilityId: facilityId,
            unitId: unitId,
            travelerId: travelerId
        };

        const params = interpolateParams({
            ...request
        });

        const url = `${this.travelerPath}/staffing-advisory`;

        return this._http.get<Array<StaffingAdvisory>>(url, { params });
    }

    getBuildingLookups() {
        const url = `${this.integrationPath}/Nyu/buildings`;
        return this._http.get<BuildingModel[]>(`${url}`);
    }

    getFloorLookups(buildingId: number = 0) {
        const url = `${this.integrationPath}/Nyu/buildings/${buildingId}/floors`;
        return this._http.get<FloorModel[]>(`${url}`);
    }

    getDepartmentLookups(businessUnitId: number) {
        let url = `${this.integrationPath}/Nyu/departments`;
        if (businessUnitId) {
            url = `${url}?businessUnitId=${businessUnitId}`;
        }
        return this._http.get<DepartmentModel[]>(`${url}`);
    }

    getPayrollLocationLookups(businessUnitId: number) {
        let url = `${this.integrationPath}/Nyu/payroll-locations`;
        if (businessUnitId) {
            url = `${url}?businessUnitId=${businessUnitId}`;
        }
        return this._http.get<PayrollLocationModel[]>(`${url}`);
    }

    getBusinessUnitLookups(systemId: number) {
        const url = `${this._settings.CORE}/BusinessUnits/${systemId}`;
        return this._http.get<BusinessUnitModel[]>(`${url}`);
    }
}
