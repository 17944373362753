import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities/route';
import { HttpClient } from '@angular/common/http';
import { interpolateParams } from 'src/app/core/utils';
import { Observable } from 'rxjs';
import { Workers } from '../models/workers.model';
import { SecurityLookup } from 'src/app/shared/models/lookups/security-lookup.model';
import { AyaLeadsCount, JobsCount, ShiftsCount } from 'src/app/dashboard-new/models';
import { DashboardDraftInvoices } from 'src/app/shared/models/dashboard-new/dashboard-draft-invoices.model';
import { PendingExpense } from 'src/app/shared/models/expenses/pending-expense.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private readonly coreApiPath: string;

    constructor(
        @Inject(APP_CONFIG) private readonly settings: Settings,
        private readonly http: HttpClient
    ) {
        this.coreApiPath = combine(settings.CORE, 'ayaconnect', 'dashboard');
    }

    getWoc(filter: { facilityProfileGroupId: number; unitId: number }): Observable<number> {
        const params = interpolateParams({
            ...filter
        });

        const url = `${this.coreApiPath}/pending-woc`;
        return this.http.get<number>(`${url}`, { params });
    }

    getAwaitingClearance(filter: { facilityId: number; unitId: number }): Observable<number> {
        const params = interpolateParams({
            ...filter
        });

        const url = `${this.coreApiPath}/awaiting-clearance`;
        return this.http.get<number>(`${url}`, { params });
    }

    getWorkers(filter: {
        facilityId: number;
        unitId: number;
        startingWithinDays: number;
        endingWithinDays: number;
    }): Observable<Workers> {
        const params = interpolateParams({
            ...filter
        });

        const url = `${this.coreApiPath}/workers`;
        return this.http.get<Workers>(`${url}`, { params });
    }

    getPendingJobs(filter: { facilityId: number; unitId: number }): Observable<any> {
        const params = interpolateParams({
            ...filter
        });

        const url = `${this.coreApiPath}/pending-jobs`;
        return this.http.get<any>(`${url}`, { params });
    }

    getPendingTimecards(filter: { facilityId: number; unitId: number }): Observable<any> {
        const params = interpolateParams({
            ...filter
        });

        const url = `${this.coreApiPath}/pending-timecards`;
        return this.http.get<any>(`${url}`, { params });
    }

    getPendingExpenses(filter: { facilityId: number; unitId: number }): Observable<PendingExpense> {
        const params = interpolateParams({
            ...filter
        });

        const url = `${this.coreApiPath}/pending-expenses`;
        return this.http.get<PendingExpense>(`${url}`, { params });
    }

    getSecurityLookups(): Observable<SecurityLookup> {
        const url = `${this.coreApiPath}/security-lists`;

        return this.http.get<SecurityLookup>(url);
    }

    getJobsCount(filter: { facilityId: number; facilityProfileGroupId: number; unitId: number }) {
        const params = interpolateParams(filter);

        const url = `${this.coreApiPath}/jobs`;
        return this.http.get<JobsCount>(url, { params });
    }

    getShiftsCount(filter: {
        facilityProfileGroupId: number;
        unitId: number;
        shiftRange: number;
        facilityId: number;
    }): Observable<ShiftsCount> {
        const params = interpolateParams(filter);
        const url = `${this.coreApiPath}/shifts`;

        return this.http.get<ShiftsCount>(url, { params });
    }

    getPendingSubmittals(filter: { facilityId: number; unitId: number }): Observable<any> {
        const params = interpolateParams({
            ...filter
        });

        const url = `${this.coreApiPath}/pending-submittals`;
        return this.http.get<any>(`${url}`, { params });
    }

    getDraftInvoicesCount(filter: { facilityId: number }) {
        const params = interpolateParams(filter);

        const url = `${this.coreApiPath}/draft-invoices`;
        return this.http.get<DashboardDraftInvoices>(url, { params });
    }

    getAyaLeadsCount(filter: {
        facilityProfileGroupId: number;
        unitId: number;
        facilityId: number;
        applicationStatusIds: number[];
    }): Observable<AyaLeadsCount> {
        const params = interpolateParams(filter);
        const url = `${this.coreApiPath}/aya-leads-count`;

        return this.http.get<AyaLeadsCount>(url, { params });
    }
}
