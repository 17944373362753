import { SubmittalExpanded } from 'src/app/shared/models/submittals/submittal-expanded.model';
import { SubmittalJob } from 'src/app/shared/models/submittals/submittal-job.model';
import { SubmittalEmailData } from 'src/app/shared/models/submittals/submittal-email-data.model';
import { LoadingTypes } from 'src/app/shared/models';
import {
    loadSubmittalDetails,
    loadSubmittalDetailsSuccess,
    loadSubmittalDetailsFail,
    loadSubmittalJobs,
    loadSubmittalJobsSuccess,
    loadSubmittalJobsFail,
    loadSubmittalEmailData as loadSubmittalEmailData,
    loadSubmittalEmailDataSuccess as loadSubmittalEmailDataSuccess,
    loadSubmittalEmailDataFail as loadSubmittalEmailDataFail,
    updateSubmittalStatus,
    updateSubmittalStatusSuccess,
    updateSubmittalStatusFail,
    clearSubmittalDetails,
    clearSubmittalActivities,
    loadSubmittalMspCustomFieldsSuccess,
    loadSubmittalAttachmentsMetaData,
    loadSubmittalAttachmentsMetaDataSuccess,
    loadSubmittalAttachmentsMetaDataFailure,
    loadSubmittalAttachmentBlob,
    loadSubmittalAttachmentBlobSuccess,
    loadSubmittalAttachmentBlobFailure
} from 'src/app/submittals/store/submittals.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { MspCustomFieldsList } from 'src/app/shared/models/submittals/submittal-msp-fields.model';
import {
    SubmittalStatusType,
    SubmittalStatuses
} from 'src/app/shared/models/submittals/enums/submittal-status-type.enum';
import { SubmittalAttachmentMetaData } from 'src/app/shared/models/submittals/responses/submittal-attachment-meta-data.model';
import { CandidateEducation, CandidateQualifications, CandidateWorkHistory, SubmittalActivity } from '../../models';
import {
    loadCandidateEducationSuccess,
    loadCandidateQualificationsSuccess,
    loadCandidateWorkHistorySuccess,
    loadSubmittalActivitiesSuccess,
    loadSubmittalCommunicationEnabledSuccess
} from '../actions/submittals-leads.actions';
import { IsPendingReviewOverDue, OverDueBy } from 'src/app/shared/utilities/is-pending-review-over-due';

export interface SubmittalDetailsState {
    submittalDetails: SubmittalExpanded;
    submittalJobs: SubmittalJob[];
    emailData: SubmittalEmailData;
    detailsLoading: LoadingTypes;
    jobsLoading: LoadingTypes;
    emailDataLoading: LoadingTypes;
    statusLoading: LoadingTypes;
    mspCustomFields: MspCustomFieldsList;
    pendingOverdueDays: number;
    isPendingOverdue: boolean;
    canUpdateStatus: boolean;
    attachmentsMetaData: SubmittalAttachmentMetaData[];
    attachmentsMetaDataLoading: LoadingTypes;
    submittalAttachmentBlob: Blob;
    submittalAttachmentBlobLoading: LoadingTypes;
    candidateEducation: CandidateEducation[];
    candidateWorkHistory: CandidateWorkHistory[];
    candidateQualifications: CandidateQualifications;
    clientSubmittalId: number;
    attachmentId: number;
    submittalId: number;
    error: unknown;
    activities: SubmittalActivity[];
    communicationEnabled: boolean;
}

const initialState: SubmittalDetailsState = {
    submittalDetails: null,
    submittalJobs: [],
    emailData: null,
    detailsLoading: LoadingTypes.INIT,
    jobsLoading: LoadingTypes.INIT,
    emailDataLoading: LoadingTypes.INIT,
    statusLoading: LoadingTypes.INIT,
    mspCustomFields: null,
    pendingOverdueDays: OverDueBy,
    isPendingOverdue: false,
    canUpdateStatus: false,
    attachmentsMetaData: [],
    submittalAttachmentBlob: null,
    attachmentsMetaDataLoading: LoadingTypes.INIT,
    submittalAttachmentBlobLoading: LoadingTypes.INIT,
    candidateEducation: [],
    candidateWorkHistory: [],
    candidateQualifications: null,
    clientSubmittalId: -1,
    attachmentId: -1,
    submittalId: -1,
    error: null,
    activities: [],
    communicationEnabled: false
};

export const submittalDetailsReducer = (reducerState: SubmittalDetailsState, reducerAction: Action) => {
    return createReducer(
        initialState,
        on(
            loadSubmittalDetails,
            (state): SubmittalDetailsState => ({
                ...state,
                detailsLoading: LoadingTypes.LOADING
            })
        ),
        on(loadSubmittalDetailsSuccess, (state, action): SubmittalDetailsState => {
            const pendingOverdue = getIsPendingOver2Days(action.details);

            return {
                ...state,
                submittalDetails: action.details,
                isPendingOverdue: pendingOverdue,
                canUpdateStatus: action.canUpdateStatus,
                detailsLoading: LoadingTypes.LOADED
            };
        }),
        on(
            loadSubmittalDetailsFail,
            (state): SubmittalDetailsState => ({
                ...state,
                submittalDetails: null,
                isPendingOverdue: false,
                canUpdateStatus: false,
                detailsLoading: LoadingTypes.FAIL
            })
        ),
        on(
            loadSubmittalJobs,
            (state): SubmittalDetailsState => ({
                ...state,
                jobsLoading: LoadingTypes.LOADING
            })
        ),
        on(
            loadSubmittalJobsSuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                submittalJobs: action.jobs,
                jobsLoading: LoadingTypes.LOADED
            })
        ),
        on(
            loadSubmittalJobsFail,
            (state): SubmittalDetailsState => ({
                ...state,
                submittalJobs: [],
                jobsLoading: LoadingTypes.FAIL
            })
        ),
        on(
            loadSubmittalEmailData,
            (state): SubmittalDetailsState => ({
                ...state,
                emailDataLoading: LoadingTypes.LOADING
            })
        ),
        on(
            loadSubmittalEmailDataSuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                emailData: action.data,
                emailDataLoading: LoadingTypes.LOADED
            })
        ),
        on(
            loadSubmittalEmailDataFail,
            (state): SubmittalDetailsState => ({
                ...state,
                emailData: null,
                emailDataLoading: LoadingTypes.FAIL
            })
        ),
        on(
            updateSubmittalStatus,
            (state): SubmittalDetailsState => ({
                ...state,
                statusLoading: LoadingTypes.LOADING
            })
        ),
        on(updateSubmittalStatusSuccess, (state, action): SubmittalDetailsState => {
            const pendingOverdue = getIsPendingOver2Days(action.details);

            return {
                ...state,
                submittalDetails: action.details,
                isPendingOverdue: pendingOverdue,
                statusLoading: LoadingTypes.LOADED
            };
        }),
        on(
            updateSubmittalStatusFail,
            (state): SubmittalDetailsState => ({
                ...state,
                submittalDetails: state.submittalDetails ? { ...state.submittalDetails } : null,
                isPendingOverdue: false,
                statusLoading: LoadingTypes.FAIL
            })
        ),
        on(
            loadSubmittalMspCustomFieldsSuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                mspCustomFields: action.mspCustomFields
            })
        ),
        on(
            clearSubmittalDetails,
            clearSubmittalActivities,
            (): SubmittalDetailsState => ({
                ...initialState
            })
        ),
        on(
            loadSubmittalAttachmentsMetaData,
            (state, action): SubmittalDetailsState => ({
                ...state,
                attachmentsMetaDataLoading: LoadingTypes.LOADING,
                clientSubmittalId: action.clientSubmittalId
            })
        ),
        on(
            loadSubmittalAttachmentsMetaDataSuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                attachmentsMetaDataLoading: LoadingTypes.LOADED,
                attachmentsMetaData: action.attachmentsMetaData
            })
        ),
        on(
            loadSubmittalAttachmentsMetaDataFailure,
            (state): SubmittalDetailsState => ({
                ...state,
                attachmentsMetaData: [],
                attachmentsMetaDataLoading: LoadingTypes.FAIL
            })
        ),
        on(
            loadSubmittalAttachmentBlob,
            (state, action): SubmittalDetailsState => ({
                ...state,
                submittalAttachmentBlobLoading: LoadingTypes.LOADING,
                attachmentId: action.attachmentId,
                submittalId: action.submittalId
            })
        ),
        on(
            loadSubmittalAttachmentBlobSuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                submittalAttachmentBlobLoading: LoadingTypes.LOADED,
                submittalAttachmentBlob: action.submittalAttachmentBlob
            })
        ),
        on(
            loadSubmittalAttachmentBlobFailure,
            (state, action): SubmittalDetailsState => ({
                ...state,
                submittalAttachmentBlob: null,
                submittalAttachmentBlobLoading: LoadingTypes.FAIL,
                error: action.error
            })
        ),
        on(
            loadCandidateEducationSuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                candidateEducation: action.education
            })
        ),
        on(
            loadCandidateWorkHistorySuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                candidateWorkHistory: action.workHistory
            })
        ),
        on(
            loadCandidateQualificationsSuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                candidateQualifications: action.qualifications
            })
        ),
        on(
            loadSubmittalActivitiesSuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                activities: action.activities
            })
        ),
        on(
            loadSubmittalCommunicationEnabledSuccess,
            (state, action): SubmittalDetailsState => ({
                ...state,
                communicationEnabled: action.communicationEnabled
            })
        )
    )(reducerState, reducerAction);
};

export const reducer = (state: SubmittalDetailsState | undefined, action: Action) => {
    return submittalDetailsReducer(state, action);
};

export const getIsPendingOver2Days = (submittal: SubmittalExpanded): boolean => {
    const checkPendingReview =
        submittal.statusId === SubmittalStatusType.PENDING_REVIEW ||
        submittal.submittalStatusId === SubmittalStatuses.PendingReview;
    return IsPendingReviewOverDue(checkPendingReview, submittal.submissionSentOn, initialState.pendingOverdueDays);
};
