import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { DashboardState, dashboardStoreKey } from 'src/app/dashboard-new/store/dashboard.state';

const selectFeature = createFeatureSelector<DashboardState>(dashboardStoreKey);

export const selectJobs = createSelector(selectFeature, (state) => state.jobs);

export const selectJobsCount = createSelector(selectJobs, (state) => state.count);

export const loadingJobsCount = createSelector(selectJobs, (state) => state.loading === LoadingTypes.LOADING);

export const getSubmittalsAgingHours = createSelector(selectFeature, (state) => state.jobs.submittalsAgingHours);

export const getFacilityHasJobApproval = createSelector(
    selectFeature,
    (state) => state.jobs.count.facilityHasJobApproval
);
