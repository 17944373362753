<loading-indicator *ngIf="isLoading$ | async"></loading-indicator>

<ayac-contact-toolbar [hasChanges]="hasChanges" [disableSaveAndCancel]="!hasChanges || invalidChange"
    [qaId]="'ad_system_contact_detail'" (backClicked)="back()" (saveClicked)="save()" (cancelClicked)="cancel()">
</ayac-contact-toolbar>

<ng-container *ngIf="contact$ | async as contact">
    <div class="panel panel-warning">
        <div class="panel-heading contact-header">
            System Contact: {{contact.id ? contact.firstName + ' ' + contact.lastName : 'New System Contact'}}
        </div>
    </div>
    <div class="section-container">
        <mat-card class="mat-elevation-z0">
            <h2 class="migration-section-header">Contact Profile</h2>
            <ayac-contact-profile [contact]="convertSystemContactToCommonContact(contact)"
                [contactTypeTags]="contactTypeTags$ | async" [systemsOrClients]="systems$ | async"
                [identityProviders]="identityProviders$ | async" [isSystemContact]="true"
                [roles]="roles$ | async"
                (contactChanged)="contactInfoChanged($event)">
            </ayac-contact-profile>
        </mat-card>
    </div>
</ng-container>
