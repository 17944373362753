import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import {
    MatLegacySnackBarModule as MatSnackBarModule,
    MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS
} from '@angular/material/legacy-snack-bar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import {
    MatLegacyProgressSpinnerModule as MatProgressSpinnerModule,
    MAT_LEGACY_PROGRESS_SPINNER_DEFAULT_OPTIONS as MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS
} from '@angular/material/legacy-progress-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';

const MODULES = [
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatBadgeModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatCardModule,
    MatSidenavModule,
    MatProgressBarModule,
    LayoutModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatTableModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatTableModule,
    MatExpansionModule,
    MatSortModule,
    MatRadioModule,
    MatTableModule,
    NgxMatSelectSearchModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatPaginatorModule
];

const PROVIDERS = [
    {
        provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
        useValue: {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
        }
    },
    {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {}
    },
    { provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: { diameter: 60, strokeWidth: 3 } }
];

@NgModule({
    declarations: [],
    providers: [...PROVIDERS],
    imports: [...MODULES],
    exports: [...MODULES]
})
export class MaterialModule {}
