import { AcceptedVendor } from 'src/app/facilities/models/accepted-vendor.model';
import { ProfessionEdit } from 'src/app/facilities/models/profession-edit.model';
import { ScheduledReleaseRule } from 'src/app/jobs/models/lookups/scheduled-release-rule.lookup';
import { LoadingTypes } from 'src/app/shared/models';
import { ReleasePermissions } from 'src/app/facilities/models/release-permissions.model';
import { Expertise } from 'src/app/shared/models/shifts/expertise.model';

export interface ScheduledReleaseRulesState {
    scheduledReleaseRules: ScheduledReleaseRule[];
    scheduledReleaseRule: ScheduledReleaseRule;
    scheduledReleaseRulesPermissions: ReleasePermissions;
    scheduledReleaseRulesPermissionsLoadingState: LoadingTypes;
    scheduledReleaseRulesLoadingState: LoadingTypes;
    scheduledReleaseRuleLoadingState: LoadingTypes;
    scheduledReleaseRuleProfessions: ProfessionEdit[];
    scheduledReleaseRuleProfessionsLoadingState: LoadingTypes;
    scheduledReleaseRuleExpertises: Expertise[];
    scheduledReleaseRuleExpertisesLoadingState: LoadingTypes;
    scheduledReleaseRuleVendors: AcceptedVendor[];
    scheduledReleaseRuleVendorsLoadingState: LoadingTypes;
    saveScheduledReleaseRuleLoadingState: LoadingTypes;
}

export const getInitialScheduledReleaseRulesState = (): ScheduledReleaseRulesState => ({
    scheduledReleaseRulesPermissions: {
        canAdd: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        facilityId: 0
    },
    scheduledReleaseRulesPermissionsLoadingState: LoadingTypes.INIT,
    scheduledReleaseRulesLoadingState: LoadingTypes.INIT,
    scheduledReleaseRules: [],
    scheduledReleaseRule: {} as ScheduledReleaseRule,
    scheduledReleaseRuleLoadingState: LoadingTypes.INIT,
    scheduledReleaseRuleProfessions: [],
    scheduledReleaseRuleProfessionsLoadingState: LoadingTypes.INIT,
    scheduledReleaseRuleExpertises: [],
    scheduledReleaseRuleExpertisesLoadingState: LoadingTypes.INIT,
    scheduledReleaseRuleVendors: [],
    scheduledReleaseRuleVendorsLoadingState: LoadingTypes.INIT,
    saveScheduledReleaseRuleLoadingState: LoadingTypes.INIT
});
