import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaginationRequest } from 'src/app/manage-permissions/models';
import { LoadingTypes } from 'src/app/shared/models';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ShiftListTableItem } from 'src/app/vendor-perdiemscheduler/models/shift-list-table-item.model';
import { PerdiemSchedulerState, perdiemSchedulerStoreKey } from './perdiemscheduler.state';

export const selectFeature = createFeatureSelector<PerdiemSchedulerState>(perdiemSchedulerStoreKey);

export const selectShifts = createSelector(selectFeature, (state) => state.list);

export const selectAttachmentsFeature = createSelector(selectFeature, (state) => state.attachments);

export const selectShiftsView = createSelector(selectShifts, (state) => ({
    data: state.list,
    total: state.total
}));

export const selectShiftsIsLoading = createSelector(selectFeature, (state) =>
    [
        state.list.loading,
        state.professions.loading,
        state.specialties.loading,
        state.facilities.loading,
        state.states.loading,
        state.attachments.loading,
        state.detailShifts.loading
    ].some((x) => x === LoadingTypes.LOADING)
);

export const selectShiftsQuery = createSelector(selectFeature, (state) => state.query);

export const selectProfessions = createSelector(selectFeature, (state) => state.professions.list);

export const selectSpecialties = createSelector(selectFeature, (state) => state.specialties.list);

export const selectFacilities = createSelector(selectFeature, (state) => state.facilities.list);

export const selectStates = createSelector(selectFeature, (state) => state.states.list);

export const selectSelectedShifts = createSelector(selectFeature, (state) => state.selectedShifts.shifts);

export const selectCandidates = createSelector(selectFeature, (state) => ({
    data: state.candidates.list,
    total: state.candidates.total
}));

export const selectFilteredCandidates = createSelector(selectFeature, (state) => ({
    data: state.candidates.filteredList,
    total: state.candidates.total
}));

export const selectCandidatesIsLoading = createSelector(
    selectFeature,
    (state) => state.candidates.loading === LoadingTypes.LOADING
);

export const selectCancelReasons = createSelector(selectFeature, (state) => state.cancelReasons.list);

export const selectShiftsDetailIsLoading = createSelector(
    selectFeature,
    (state) => state.detailShifts.loading === LoadingTypes.LOADING
);
export const selectShiftsDetail = createSelector(selectFeature, (state) => state.detailShifts.shifts);
export const selectShiftsDetailAndSearchQuery = createSelector(
    selectFeature,
    (state): [PagingToken<ShiftListTableItem[]>, PaginationRequest] => {
        return [state.detailShifts.shifts, state.detailShifts.searchQuery];
    }
);

export const selectAttachments = createSelector(selectFeature, (state) => state.attachments.attachments);

export const selectAttachmentPreview = createSelector(selectAttachmentsFeature, (state) => {
    return {
        blob: state.preview,
        totalPages: state.previewTotal
    };
});

export const selectAttachmentsLoading = createSelector(
    selectAttachmentsFeature,
    (state) => state.loading === LoadingTypes.LOADING
);

export const selectAttachmentCurrent = createSelector(selectAttachmentsFeature, (state) => state.currentFile);

export const selectShiftAttachmentsFeature = createSelector(
    selectFeature,
    (state) => state.attachments.attachmentFeatures
);

export const selectShiftSeries = createSelector(selectFeature, (state) => {
    return state.detailShifts.shiftSeries;
});

export const selectGetDefaultDateFrom = createSelector(selectFeature, (state) =>
    state ? state.minDate.minDate : null
);
