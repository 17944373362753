<div *ngIf="documentUploadError" class="error-box">
  <mat-icon class="icon error-icon">info</mat-icon>
  <span><strong>Upload Error:</strong> {{documentUploadError}}</span>
</div>

<div class="pre-header">
    <div class="pre-header__left">
        <div>
            <p class="note"><strong>Please note, it may take up to 48 hours for updates to be reflected here.</strong></p>
        </div>
    </div>
    <div class="pre-header__right">
        <button mat-flat-button data-qa-id="contract_requirements_export_button" (click)="exportContractRequirements()" class="export-button" color="primary">
            <mat-icon>file_download</mat-icon><span>Export</span>
        </button>
    </div>
</div>

<div class="warning-box" role="alert" *ngIf="!contractRequirements?.length else showContractRequirements">
    No contracts found.
</div>

<ng-template #showContractRequirements>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let requirement of contractRequirements" class="document-panel mat-elevation-z0" [expanded]="true" (opened)="panelIsOpen = true" (closed)="panelIsOpen = false">
        <mat-expansion-panel-header [class.opened]="panelIsOpen">
            <mat-panel-title>
            {{getStatusName(requirement) | uppercase}}
            {{requirement.facility}}
            {{requirement.from | date: "MM/dd/yyyy"}} <ng-container *ngIf="requirement.to"> - {{requirement.to | date: "MM/dd/yyyy"}}</ng-container>
            </mat-panel-title>
            <mat-panel-title class="document-due-date-title">
                Document Due Date: {{requirement.documentDueDate | date: "MM/dd/yyyy"}}
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="!requirement.documents?.length" class="warning-box empty-documents" role="alert">
            No documents found.
        </div>

        <div class="info-message">
            <p *ngIf="requirement.usersAllowedToApproveRequirements">For questions, please
                <span *ngIf="!requirement.usersAllowedToApproveRequirements.length">contact the facility’s compliance team</span>
                <a *ngIf="requirement.usersAllowedToApproveRequirements.length" href="mailto:{{requirement.usersAllowedToApproveRequirements | stringList}}">contact the facility’s compliance team</a>.
            </p>
            <p *ngIf="!requirement.usersAllowedToApproveRequirements && credentialingSpecialistEmail">For questions, please contact credentialing specialist
                <a href="mailto:{{credentialingSpecialistEmail}}">{{credentialingSpecialist}}</a>.
            </p>
        </div>

        <table mat-table *ngIf="requirement.documents?.length > 0" [dataSource]="requirement.documents" aria-label="Table list of required documents">
            <ng-container matColumnDef="status">
            <th class="row-header" mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let document">
                {{document.statusId | displayEnumStringValue:status}}
            </td>
            </ng-container>

            <ng-container matColumnDef="upload-error">
            <th class="row-header" mat-header-cell *matHeaderCellDef> </th>
            <td class="right-padding" mat-cell *matCellDef="let document">
                <mat-icon class="icon warning-icon" *ngIf="document.statusId === status.Missing || document.statusId === status.Expiring || document.statusId === status.Expired">
                warning</mat-icon>
                <mat-icon class="icon pending-icon" *ngIf="document.statusId === status.Pending || document.statusId === status.PendingApproval">schedule</mat-icon>
                <mat-icon class="icon pending-icon" *ngIf="document.statusId === status.ForReview">info</mat-icon>
                <mat-icon class="icon approved-icon" *ngIf="document.statusId === status.Approved || document.statusId === status.Received">check_circle</mat-icon>
                <ng-container *ngIf="document.isUploadError">
                <mat-icon class="icon error-icon">info</mat-icon>
                <span class="error-text">Upload Error</span>
                </ng-container>
            </td>
            </ng-container>

            <ng-container matColumnDef="title">
            <th class="row-header" mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let document"> {{document.title}} </td>
            </ng-container>

            <ng-container matColumnDef="expiration">
            <th class="row-header" mat-header-cell *matHeaderCellDef> Expiration </th>
            <td mat-cell *matCellDef="let document">{{ document.expiration === defaultNeverExpireDocumentDate ? 'Does not expire' : (document.expiration | date : 'MM/dd/yyyy') }}</td>
            </ng-container>

            <ng-container matColumnDef="view-details">
            <th class="row-header" mat-header-cell *matHeaderCellDef> </th>
            <td class="left-align right-padding" mat-cell *matCellDef="let document">
                <a (click)="viewDocumentDetails(document)">View Requirement Details</a>
            </td>
            </ng-container>

            <ng-container matColumnDef="upload-document"
                *ifLdFeature="featureFlag.VendorConnectCompliance; enabled: false">
                <th class="row-header" mat-header-cell *matHeaderCellDef> </th>
                <td class="right-align" mat-cell *matCellDef="let document">
                    <input type="file" class="file-input" #fileUpload multiple
                        (change)="onFileChange($event, document)" />
                    <button mat-flat-button color="primary" *ngIf="document.canUploadDocument"
                        (click)="fileUpload.click()" [disabled]="document.isUploadInProgress">Upload
                        Document</button>
                    <span class="uploaded" *ngIf="!document.canUploadDocument">Document uploaded</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="upload-document"
                *ifLdFeature="featureFlag.VendorConnectCompliance; enabled: true">
                <th class="row-header" mat-header-cell *matHeaderCellDef> </th>
                <ng-container *ngIf="requirement.isVMSContract">
                    <td mat-cell *matCellDef="let document"
                            [ngClass]="{'upload-doc-text-align': (!document.canUploadDocument && document.statusId !== status.PendingApproval) || document.statusId === status.PendingApproval}">
                        <button mat-flat-button color="primary" *ngIf="document.canUploadDocument"
                            (click)="docUploadOrEditClick($event, document, false)"
                            [disabled]="document.isUploadInProgress">Upload Document</button>
                        <span class="uploaded" *ngIf="!document.canUploadDocument && document.statusId !== status.PendingApproval">Document
                            uploaded</span>
                        <a [attr.data-qa-id]="'vendor-compliance-edit-document-details-link_'+ document.id"
                            *ngIf="document.statusId === status.PendingApproval"
                            (click)="docUploadOrEditClick($event, document, true)">Edit Details</a>
                    </td>
                </ng-container>
                <ng-container *ngIf="!requirement.isVMSContract">
                    <td class="right-align" mat-cell *matCellDef="let document">
                        <input type="file" class="file-input" #fileUpload multiple
                            (change)="onFileChange($event, document)" />
                        <button mat-flat-button color="primary" *ngIf="document.canUploadDocument"
                            (click)="fileUpload.click()" [disabled]="document.isUploadInProgress">Upload
                            Document</button>
                        <span class="uploaded" *ngIf="!document.canUploadDocument">Document uploaded</span>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row [class.upload-error]="document.isUploadError" *matRowDef="let document; columns: displayedColumns;"></tr>
        </table>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

