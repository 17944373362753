import { LoadingTypes } from 'src/app/shared/models';
import { FeatureSettingCategory } from 'src/app/shared/feature-settings/models/feature-features.models';

export interface FacilitySettingsState {
    facilitySettings: FeatureSettingCategory[];
    loadingState: LoadingTypes;
}

export const getInitialFacilitySettingsState = (): FacilitySettingsState => ({
    facilitySettings: [],
    loadingState: LoadingTypes.INIT
});
