import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VendorBank } from 'src/app/admin/vendor-details/models/vendor-bank.model';
import { APP_CONFIG, Settings } from 'src/app/config/settings';

@Injectable({
    providedIn: 'root'
})
export class VendorBankingService {
    constructor(
        private readonly _httpClient: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {}

    getBanks(): Observable<VendorBank[]> {
        return this._httpClient.get<VendorBank[]>(`${this._settings.CORE}/ayaconnect/banks`);
    }

    addCustomBank(bankName: string): Observable<VendorBank> {
        return this._httpClient.post<VendorBank>(
            `${this._settings.CORE}/ayaconnect/banks/bank?bankName=${bankName}`,
            null
        );
    }
}
