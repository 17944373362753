import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as TravelerDocumentsActions from 'src/app/travelers/state/actions/travelers-documents.actions';
import { TravelerDocumentService } from '../../services/traveler-document.service';
import { ToasterService } from 'src/app/core/services';
import { Store } from '@ngrx/store';
import { selectCurrentContractId, selectDocumentMetadata } from '..';
import { Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class TravelersDocumentDetailsEffect {
    constructor(
        private readonly _actions$: Actions,
        private readonly _documentService$: TravelerDocumentService,
        private readonly _store: Store<{}>,
        private readonly _toaster: ToasterService,
        private readonly _router: Router
    ) {}

    loadTravelerDetailsForDocuments$ = createEffect(() =>
        this._actions$.pipe(
            ofType(TravelerDocumentsActions.loadTravelerDetailsForDocuments),
            switchMap((action) =>
                this._documentService$.getDocumentTravelerDetails(action.contractId).pipe(
                    map((details) => {
                        return TravelerDocumentsActions.loadTravelerDetailsForDocumentsSuccess({ details });
                    }),
                    catchError((error) =>
                        of(TravelerDocumentsActions.loadTravelerDetailsForDocumentsFail({ error: error }))
                    )
                )
            )
        )
    );

    loadDocumentMetadata$ = createEffect(() =>
        this._actions$.pipe(
            ofType(TravelerDocumentsActions.loadDocumentMetadata),
            switchMap((action) =>
                this._documentService$.getDocumentMetadata(action.contractId, action.documentId).pipe(
                    map((metadata) => {
                        return TravelerDocumentsActions.loadDocumentMetadataSuccess({ metadata });
                    }),
                    catchError((error) => of(TravelerDocumentsActions.loadDocumentMetadataFail({ error: error })))
                )
            )
        )
    );

    declineDocument$ = createEffect(() =>
        this._actions$.pipe(
            ofType(TravelerDocumentsActions.declineDocument),
            switchMap((action) =>
                this._documentService$
                    .declineDocument(action.contractId, action.documentId, action.reason, action.notes)
                    .pipe(
                        map((_) => TravelerDocumentsActions.declineDocumentSuccess()),
                        catchError((error) => of(TravelerDocumentsActions.declineDocumentFail({ error })))
                    )
            )
        )
    );

    declineDocumentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(TravelerDocumentsActions.declineDocumentSuccess),
                withLatestFrom(this._store.select(selectDocumentMetadata), this._store.select(selectCurrentContractId)),
                map(([_, metadata, contractId]) => {
                    this._toaster.info(`Agency was notified to resubmit ${metadata.name}.`);
                    this._router.navigate(['/', 'client', 'workers', 'docs', contractId]);
                })
            ),
        { dispatch: false }
    );

    declineDocumentFail$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(TravelerDocumentsActions.declineDocumentFail),
                map((_) => this._toaster.fail('Unable to complete action. Please try again.'))
            ),
        { dispatch: false }
    );

    approveDocument$ = createEffect(() =>
        this._actions$.pipe(
            ofType(TravelerDocumentsActions.approveDocument),
            switchMap((action) =>
                this._documentService$.approveDocument(action.contractId, action.documentId).pipe(
                    map((_) => TravelerDocumentsActions.approveDocumentSuccess()),
                    catchError((error) => of(TravelerDocumentsActions.approveDocumentFail({ error })))
                )
            )
        )
    );

    approveDocumentSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(TravelerDocumentsActions.approveDocumentSuccess),
                withLatestFrom(this._store.select(selectDocumentMetadata), this._store.select(selectCurrentContractId)),
                map(([_, metadata, contractId]) => {
                    this._toaster.success(`${metadata.name} was successfully validated.`);
                    this._router.navigate(['/', 'client', 'workers', 'docs', contractId]);
                })
            ),
        { dispatch: false }
    );

    approveDocumentFail$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(TravelerDocumentsActions.approveDocumentFail),
                map((_) => this._toaster.fail('Unable to complete action. Please try again.'))
            ),
        { dispatch: false }
    );

    loadCandidateDeclinedDocumentStatus$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(TravelerDocumentsActions.loadCandidateDeclinedDocumentStatus),
            switchMap((action) =>
                this._documentService$
                    .getDocumentDeclinedApprovalStatus(action.contractId, action.candidateDocumentId)
                    .pipe(
                        map((status) => {
                            return TravelerDocumentsActions.loadCandidateDeclinedDocumentStatusSuccess({
                                documentStatus: status
                            });
                        }),
                        catchError((error) =>
                            of(TravelerDocumentsActions.loadCandidateDeclinedDocumentStatusFailure({ error: error }))
                        )
                    )
            )
        );
    });

    loadCandidateDeclinedDocumentStatusSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(TravelerDocumentsActions.loadCandidateDeclinedDocumentStatusSuccess),
                concatLatestFrom(() => [this._store.select(selectCurrentContractId)]),
                map((val) => {
                    void this._router.navigate(['/', 'client', 'workers', 'docs', val[1]]);
                })
            );
        },
        { dispatch: false }
    );
}
