import { createAction, props } from '@ngrx/store';
import { FacilityAdministrationLookups } from 'src/app/facilities/models/facility-administration-lookups.model';

export enum FacilitiesListLookupsActionEnum {
    LoadFacilitiesListLookups = '[FacilitiesListLookups] Load Facilities Lookups',
    LoadFacilitiesListLookupsSuccess = '[FacilitiesListLookups] Load Facilities Lookups Success',
    LoadFacilitiesListLookupsFailure = '[FacilitiesListLookups] Load Facilities Lookups Failure'
}

export const loadFacilitiesListLookups = createAction(FacilitiesListLookupsActionEnum.LoadFacilitiesListLookups);
export const loadFacilitiesListLookupsSuccess = createAction(
    FacilitiesListLookupsActionEnum.LoadFacilitiesListLookupsSuccess,
    props<{ lookups: FacilityAdministrationLookups }>()
);
export const loadFacilitiesListLookupsFailure = createAction(
    FacilitiesListLookupsActionEnum.LoadFacilitiesListLookupsFailure,
    props<{ error: any }>()
);
