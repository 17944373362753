<section class="migrated-section-container">
    <fieldset>

        <legend>
            Web Sites
        </legend>

        <form [formGroup]="websiteForm">
            <mat-accordion *ngIf="websites$ | async" formArrayName="sites">
                <ng-container *ngFor="let siteControls of sitesFormArray.controls; let i = index;" [formGroupName]="i">
                    <mat-expansion-panel [formGroup]="siteControls" [attr.data-qa-id]="'websiteExpansionPanel' + (i + 1)" class="mat-elevation-z0">
                        <mat-expansion-panel-header class="websites-header">
                            <mat-panel-title>
                                {{ sitesFormArray.at(i).get('siteType').value | websiteTypeLabel }}: {{ sitesFormArray.at(i).get('url').value }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
        
                        <div class="website-container">
                            <mat-form-field fxFlex="15" floatLabel="never" appearance="outline">
                                <mat-select data-qa-id="siteTypeSelect" formControlName="siteType">
                                    <mat-option *ngFor="let type of websiteTypes$ | async" [value]="type.id" [attr.data-qa-id]="type.id">
                                        {{ type.type }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field fxFlex="65" floatLabel="never" appearance="outline">
                                <input data-qa-id="siteUrlInput" matInput type="text" placeholder="URL" formControlName="url">
                            </mat-form-field>
        
                            <button mat-icon-button data-qa-id="removeSiteButton" matTooltip="Remove WebSite" class="delete-button" (click)="removeSite(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
        
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </form>

        <button color="accent" class="vendor-add-button" matTooltip="Add New Website" data-qa-id="addSiteButton" mat-flat-button (click)="addNewSite()">
            <mat-icon>add</mat-icon>
            Add Website
        </button>
    
    </fieldset>
</section>