import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { downloadBlob, interpolateParams } from 'src/app/core/utils';
import { FacilityVendorTSUpdate } from 'src/app/facilities/models';
import { SortTypes } from 'src/app/shared/models';
import { VendorFacilityStatus } from 'src/app/shared/models/enums/vendor-facility-status.enum';
import { VendorFacility, VendorFacilityLookups } from 'src/app/shared/models/facilities';
import { FacilitiesAdminUrls } from 'src/app/shared/models/facilities/enums/facilities-admin-urls.enum';
import { PagingToken } from 'src/app/shared/models/paging-token';

@Injectable({
    providedIn: 'root'
})
export class VendorFacilitiesService {
    constructor(
        private readonly _http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {}

    getVendorFacilities(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<PagingToken<VendorFacility[]>> {
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgs
        });
        const url = `${this._settings.CORE}/ayaconnect/admin/vendors/view-facilities`;

        return this._http.get<PagingToken<VendorFacility[]>>(`${url}`, {
            params
        });
    }

    getVendorFacilitiesLookups() {
        return this._http.get<VendorFacilityLookups>(`${this._settings.CORE}${FacilitiesAdminUrls.VENDOR_LOOKUPS}`);
    }

    updateCtsSigned(params: { vendorId: number; ids: number[]; termSheetSigned: boolean }) {
        return this._http.put<FacilityVendorTSUpdate>(
            `${this._settings.CORE}/ayaconnect/admin/vendors/term-sheet-signed`,
            params
        );
    }

    updateAllCtsSigned(
        params: { vendorId: number; ids: number[]; termSheetSigned: boolean },
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';

        const _params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgs
        });

        const url = `${this._settings.CORE}/ayaconnect/admin/vendors/term-sheet-signed/all?${_params}`;

        return this._http.put<FacilityVendorTSUpdate>(url, params);
    }

    getAllVendorFacilityIds(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<number[]> {
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgs
        });

        return this._http.get<number[]>(`${this._settings.CORE}/ayaconnect/admin/vendors/view-facilities/ids`, {
            params
        });
    }

    updateFacilityStatus(
        vendorId: number,
        params: {
            status: VendorFacilityStatus;
            facilityIds: number[];
            note: string;
        }
    ) {
        const url = `${this._settings.CORE}/ayaconnect/admin/vendors/${vendorId}/status`;
        return this._http.put(url, params);
    }

    export(facilityIds: number[], vendorId: number) {
        return this._http
            .post(`${this._settings.CORE}/AyaConnect/Vendors/Facility/export-selected/${vendorId}`, facilityIds, {
                responseType: 'blob'
            })
            .pipe(
                tap((resultBlob: Blob) => {
                    downloadBlob(resultBlob, 'Vendors.xlsx');
                })
            );
    }
}
