<mat-card data-qa-id="UsernameToEmailWarning" class="toaster-fail" *ngIf="userNameToEmailWarning">
    <mat-card-content>Username '{{originalUsername}}' will be changed to email '{{currentEmail}}' on saving</mat-card-content>
</mat-card>
<form class="k-form-inline contact-form" [formGroup]="contactProfileForm">

    <div class="form-row" *ngIf="vendors">
        <span class="form-label" aria-label="Vendor">Vendor: <span class="k-required">*</span></span>
        <aya-input-dropdown controlName="vendor" placeholder="Select or search a vendor from the list…" requiredMessage="Vendor is required." [options]="vendors">
        </aya-input-dropdown>
    </div>

    <div class="form-row">
        <span class="form-label" aria-label="Contact first name">First Name: <span class="k-required">*</span></span>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="firstName" placeholder="First name" />
            <mat-error *ngIf="contactProfileForm.get('firstName').hasError('required')">
                Please enter a first name.
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-row">
        <span class="form-label">
            Last Name: <span class="k-required">*</span>
        </span>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="lastName" placeholder="Last name" />
            <mat-error *ngIf="contactProfileForm.get('lastName').hasError('required')">
                Please enter a last name.
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-row">
        <span class="form-label">Portal Sign-In: </span>
        <mat-button-toggle-group name="portalSignIn" aria-label="Add or remove user portal sign in" formControlName="portalSignIn">
            <mat-button-toggle [value]="true" (click)="addUser()">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false" (click)="removeUser()">No</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div class="form-column" *ngIf="contactProfileForm.get('user') && contactProfileForm.get('portalSignIn')?.value" formGroupName="user">
        <div class="form-row">
            <span class="indent form-label">Username<span *ngIf="!hasParentProfile"> (from email)</span>: <span class="k-required">*</span></span>
            <mat-form-field appearance="outline"
                [class.multiple-errors]="contactProfileForm.get('user')?.get('username').hasError('usernameUnavailable') && contactProfileForm.get('user')?.get('username').hasError('businessEmailRequired')">
                <input data-qa-id="VendorContactUserName" matInput id="username-input" type="text" formControlName="username" placeholder="{{!hasParentProfile ? 'Will be sourced from user\'s business Email' : 'Username'}}" [readonly]="!hasParentProfile" />
                <mat-error *ngIf="contactProfileForm.get('user').get('username').hasError('required')">
                    User name cannot be empty.
                </mat-error>
                <mat-error *ngIf="contactProfileForm.get('user').get('username').hasError('usernameUnavailable')">
                    This user name is not available.
                </mat-error>
                <mat-error
                    *ngIf="!contactProfileForm.get('user').get('username').hasError('required') && contactProfileForm.get('user').get('username').hasError('businessEmailRequired')">
                    At least one business email is required.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row" *ngIf="showPasswordField">
            <span class="indent form-label">Password: <span class="k-required">*</span></span>
            <mat-form-field appearance="outline">
                <input data-qa-id="VendorContactPassword" matInput type="text" formControlName="password" placeholder="Password" />
                <mat-error *ngIf="contactProfileForm.get('user')?.get('password')?.hasError('required')">
                    Please enter a password.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row" *ngIf="canManageLinkedProfiles">
            <span class="indent form-label">Link to parent profile:</span>
            <button data-qa-id="LinkedToParentCloseButton" *ngIf="hasParentProfile"
                mat-icon-button color="accent" (click)="parentProfileChanged()" 
                name="linkedToParentButton" aria-label="Link user to parent profile">
                {{linkedToParentProfile?.name}} <mat-icon [inline]="true">close</mat-icon>
            </button>
            <mat-form-field *ngIf="!hasParentProfile" floatLabel="never" appearance="outline">
                <input data-qa-id="LinkedToParentSelectEdit" 
                        (input)="searchProfilesText$.next($event.target.value)" matInput type="text"
                        placeholder="Select or search the parent profile from the list..." 
                        [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true" (optionSelected)="parentProfileChanged($event.option)">
                    <mat-option *ngFor="let profile of (filteredProfiles$ | async)" [value]="profile.name" [id]="profile.id" [attr.data-qa-id]="'LinkedToParentSelectOption-' + profile.id">
                        {{ profile.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="form-row" *ngIf="!canManageLinkedProfiles && hasParentProfile">
            <span class="indent form-label">Link to parent profile:</span>
            <span data-qa-id="LinkedToParentSelectView" class="parent-linked-profile-label">{{linkedToParentProfile?.name}}</span>
        </div>
        <div class="form-row" *ngIf="contact.user.coreUserId">
            <span class="indent form-label"></span>
            <button data-qa-id="VendorContactImpersonate" *ngIf="canImpersonate" (click)="impersonate()" class="impersonate-button" mat-raised-button color="accent">
                <span class="fa fa-user"></span>&nbsp;
                Impersonate
            </button>
        </div>
    </div>

    <div class="form-row">
        <span class="form-label">Tags:</span>
        <mat-form-field appearance="outline">
            <mat-chip-list #tagChipList aria-label="Tag selection" formControlName="tags">
                <mat-chip *ngFor="let tag of contactProfileForm.get('tags').value" (removed)="removeTag(tag)">
                    {{tag.name}}
                    <button matChipRemove>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-chip>

                <input #tagsInput matInput id="tag-input" placeholder="Select tag..." formControlName="tagInput" [matAutocomplete]="tagAuto" [matChipInputFor]="tagChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTag($event)" #tagTrigger="matAutocompleteTrigger"
                    (click)="tagTrigger.openPanel()">
            </mat-chip-list>
            <mat-autocomplete #tagAuto="matAutocomplete" (optionSelected)="addTag($event)">
                <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag">
                    {{tag.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div class="form-row">
        <span class="form-label">States:</span>
        <mat-form-field appearance="outline">
            <mat-chip-list #stateChipList aria-label="State selection" formControlName="states">
                <mat-chip *ngFor="let state of contactProfileForm.get('states').value" (removed)="removeState(state)">
                    {{state.name}}
                    <button matChipRemove>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-chip>
                <input #statesInput matInput id="state-input" placeholder="Select state..." formControlName="stateInput" [matAutocomplete]="stateAuto"
                    [matChipInputFor]="stateChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addState($event)"
                    #stateTrigger="matAutocompleteTrigger" (click)="stateTrigger.openPanel()">
            </mat-chip-list>
            <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="addState($event)">
                <mat-option *ngFor="let state of filteredStates$ | async" [value]="state">
                    {{state.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</form>
