<mat-card class="files-tab-card">
    <mat-card-content>
        <div *ngIf="isNew">
            <div class="alert alert-warning text-center my-2" role="alert">
                Please save the {{message}} before uploading a file.
            </div>
        </div>
        <div
            class="header-buttons"
        >
            <div>
                <button
                    mat-flat-button
                    color="accent"
                    data-qa-id="ac_vendor_candidate_refresh_button"
                    (click)="refresh()"
                    [disabled]="loading"
                    [class.spinner]="loading"
                    class="migrated-btn-info-brdr"
                >
                    <mat-icon>refresh</mat-icon> Refresh
                </button>

                <button
                    mat-flat-button
                    color="accent"
                    data-qa-id="ac_vendor_candidate_add_files_button"
                    class="migrated-btn-info-brdr"
                    [disabled]="isUploading"
                    *ngIf="!isNew"
                    [class.spinner]="isUploading"
                    [matMenuTriggerFor]="menu"
                >
                    <mat-icon>add</mat-icon> Add Files
                </button>
                <mat-menu #menu="matMenu">
                    <div *ngFor="let fileType of fileTypes">
                        <button mat-menu-item (click)="uploadFile(fileType)">
                        {{fileType?.name}}
                        </button>
                    </div>
                </mat-menu>
                <input
                    type="file"
                    class="file-input"
                    #fileUpload
                    multiple
                    (change)="onFileSelected($event)" (click)="$event.target.value=null"
                />
            </div>
        </div>

        <div class="grid-container">
            <portal-grid
                        class="portal-grid-scroll-auto highlighted-portal-grid"
                        [data]="gridData"
                        [query]="searchQuery"
                        [selectable]="false"
                        (dataStateChange)="onDataStateChange($event)">

                    <portal-grid-column field="id"
                                        title="id"
                                        [sortable]="true" [hidden]="true">
                        <ng-template #cell
                                    let-dataItem="dataItem"
                                    data-qa-id="fileId"
                                    >
                            {{ dataItem.file.id }}
                        </ng-template>
                    </portal-grid-column>

                    <portal-grid-column field="name"
                                        title="Name"
                                        [sortable]="true"
                                        [style]="{ width: '40%' }"
                                        filterType="text"
                                        [headerStyle]="{ width: '40%' }">
                        <ng-template #cell let-dataItem="dataItem">
                            <span
                                (click)="download(dataItem)"
                                class="file-name"
                                >{{ dataItem.file.name }}</span
                            >
                        </ng-template>
                    </portal-grid-column>

                    <portal-grid-column field="typename"
                                        title="Type"
                                        [sortable]="true"
                                        [style]="{ width: '30%' }"
                                        [headerStyle]="{ width: '30%' }">
                        <ng-template #cell
                                    let-dataItem="dataItem">
                            {{ dataItem.fileType?.type }}
                        </ng-template>
                    </portal-grid-column>

                    <portal-grid-column field="size"
                                        title="Size"
                                        [style]="{ width: '30%' }"
                                        [headerStyle]="{ width: '30%' }"
                                        [sortable]="true">
                                        <ng-template #cell let-dataItem="dataItem">
                                            {{ dataItem.file.size| byteFormatter }}
                                        </ng-template>
                    </portal-grid-column>

                    <portal-grid-column title="">
                        <ng-template #cell let-dataItem="dataItem">
                            <button
                                (click)="delete(dataItem)"
                                mat-mini-fab
                                color="warn"
                                [disabled]="isDeleting$ | async"
                                aria-label="Delete file"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-template>
                    </portal-grid-column>
                </portal-grid>
        </div>
    </mat-card-content>
</mat-card>
