import { Action, createReducer, on } from '@ngrx/store';
import { AyaLeadsJobDetailsActions } from '../actions';
import { LoadingTypes } from 'src/app/shared/models';
import { AyaLeadsJobDetails } from 'src/app/aya-leads/models/aya-leads-job-details';
import { AyaLeadsJobApplicants } from 'src/app/aya-leads/models/aya-leads-job-applicants';

export interface AyaLeadsJobDetailsState {
    jobDetails: AyaLeadsJobDetails;
    jobDetailsLoading: LoadingTypes;
    jobApplicants: AyaLeadsJobApplicants;
    jobApplicantsLoading: LoadingTypes;
    removeJobLoading: LoadingTypes;
}

export const ayaLeadsJobDetailsInitialState: AyaLeadsJobDetailsState = {
    jobDetails: null,
    jobDetailsLoading: LoadingTypes.INIT,
    jobApplicants: null,
    jobApplicantsLoading: LoadingTypes.INIT,
    removeJobLoading: LoadingTypes.INIT
};

export const ayaLeadsJobDetailsReducer = (reducerState: AyaLeadsJobDetailsState, reducerAction: Action) => {
    return createReducer(
        ayaLeadsJobDetailsInitialState,
        on(
            AyaLeadsJobDetailsActions.loadAyaLeadsJobDetails,
            (state): AyaLeadsJobDetailsState => ({
                ...state,
                jobDetailsLoading: LoadingTypes.LOADING
            })
        ),
        on(
            AyaLeadsJobDetailsActions.loadAyaLeadsJobDetailsSuccess,
            (state, action): AyaLeadsJobDetailsState => ({
                ...state,
                jobDetails: action.jobDetails,
                jobDetailsLoading: LoadingTypes.LOADED
            })
        ),
        on(
            AyaLeadsJobDetailsActions.loadAyaLeadsJobDetailsFail,
            (state): AyaLeadsJobDetailsState => ({
                ...state,
                jobDetails: null,
                jobDetailsLoading: LoadingTypes.FAIL
            })
        ),
        on(
            AyaLeadsJobDetailsActions.loadAyaLeadsJobApplicants,
            (state): AyaLeadsJobDetailsState => ({
                ...state,
                jobApplicantsLoading: LoadingTypes.LOADING
            })
        ),
        on(
            AyaLeadsJobDetailsActions.loadAyaLeadsJobApplicantsSuccess,
            (state, action): AyaLeadsJobDetailsState => ({
                ...state,
                jobApplicants: action.jobApplicants,
                jobApplicantsLoading: LoadingTypes.LOADED
            })
        ),
        on(
            AyaLeadsJobDetailsActions.loadAyaLeadsJobApplicantsFail,
            (state): AyaLeadsJobDetailsState => ({
                ...state,
                jobApplicants: null,
                jobApplicantsLoading: LoadingTypes.FAIL
            })
        ),
        on(
            AyaLeadsJobDetailsActions.removeAyaLeadsJob,
            (state): AyaLeadsJobDetailsState => ({
                ...state,
                removeJobLoading: LoadingTypes.LOADING
            })
        ),
        on(
            AyaLeadsJobDetailsActions.removeAyaLeadsJobSuccess,
            (state): AyaLeadsJobDetailsState => ({
                ...state,
                removeJobLoading: LoadingTypes.LOADED
            })
        ),
        on(
            AyaLeadsJobDetailsActions.removeAyaLeadsJobFail,
            (state): AyaLeadsJobDetailsState => ({
                ...state,
                removeJobLoading: LoadingTypes.FAIL
            })
        )
    )(reducerState, reducerAction);
};
