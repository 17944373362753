<mat-card>
    <mat-card-header>
        <mat-card-title>Previous Assignments</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="content">
        <portal-grid #grid
            [title]=""
            [data]="data$ | async"
            [query]="query$ | async"
            (dataStateChange)="onDataStateChange($event)"
            [selectable]="false"
            [pageable]="true"
            class="portal-grid-flat">

            <portal-grid-column
                title="Facility"
                field="facilityName"
                [sortable]="false">
            </portal-grid-column>

            <portal-grid-column
               *hasSystemFields="['internalPool.jobTitle']"
                title="Job Title"
                field="jobTitle"
                [sortable]="false">
            </portal-grid-column>

        <ng-container>
                <portal-grid-column title="Job ID" field="jobId" [sortable]="false" *ngIf="isNyu$ | async">
                    <ng-template #cell let-dataItem="dataItem">
                        <a [routerLink]="['/client', 'jobs', dataItem.jobId]" href="#" >{{ dataItem.jobId }}</a>
                    </ng-template>
                </portal-grid-column>
        </ng-container>

            <portal-grid-column
                title="Specialty"
                field="specialty"
                [sortable]="false">
            </portal-grid-column>

            <portal-grid-column
                title="Dates"
                field="startdate"
                [sortable]="false">
                <ng-template #cell let-dataItem="dataItem">
                    {{dataItem.startDateStrings}}
                </ng-template>
            </portal-grid-column>

            <portal-grid-column
                title="Start/End Times"
                field="scheduleTimeStrings"
                [sortable]="false">
                <ng-template #cell let-dataItem="dataItem">
                    {{dataItem.scheduleTimeStrings}} {{dataItem.shiftCount}}
                </ng-template>
            </portal-grid-column>

            <portal-grid-column
                title="Status"
                field="statusName"
                [sortable]="false">
            </portal-grid-column>
        </portal-grid>
    </mat-card-content>
</mat-card>
