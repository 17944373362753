<dialog-layout title="Status History: {{ vendorData.vendorName }}">
    <div mat-dialog-content class="row">

        <table mat-table [dataSource]="history$ | async" class="" *ngIf="(history$ | async).length" class="history-table" aria-describedby="History Status">
            <ng-container matColumnDef="status">
                <th mat-header-cell scope="col" *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element?.status}} </td>
            </ng-container>

            <ng-container matColumnDef="statusDate">
                <th mat-header-cell scope="col" *matHeaderCellDef> Date Changed </th>
                <td mat-cell *matCellDef="let element"> {{element?.statusDate | date : 'MM/dd/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="updatedBy">
                <th mat-header-cell scope="col" *matHeaderCellDef> User </th>
                <td mat-cell *matCellDef="let element"> {{element?.updatedBy}} </td>
            </ng-container>

            <ng-container matColumnDef="note">
                <th mat-header-cell scope="col" *matHeaderCellDef > Note </th>
                <td mat-cell *matCellDef="let element">
                    <div class="note-container">
                        <span *ngIf="!element.isEdit">{{element?.note}} </span>
                        <input matInput type="text" [value]="element.note" name="note" #note id="note" [(ngModel)]="element.note" *ngIf="element.isEdit">

                        <div class="note-controls">
                            <button mat-icon-button *ngIf="element.isEdit" class="edit-controls" (click)="element.isEdit = !element.isEdit; noteUpdate(true, element.id, element.note)">
                                <mat-icon>done</mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="element.isEdit" class="edit-controls" (click)="element.isEdit = !element.isEdit; element.note = originalNote; noteUpdate(false, element.id, element.note)">
                                <mat-icon>close</mat-icon>
                            </button>

                            <button mat-icon-button [matMenuTriggerFor]="appMenu" [disabled]="element.isEdit">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #appMenu="matMenu">
                                <ng-template matMenuContent let-name="name">
                                <button mat-menu-item (click)="element.isEdit = !element.isEdit; editNote(element.note)">Edit Note</button>
                                </ng-template>
                            </mat-menu>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="statusDisplayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: statusDisplayColumns;"></tr>
        </table>
    </div>

    <div mat-dialog-actions class="modal-footer-right">
        <button mat-flat-button color="accent" type="button" (click)="dialogRef.close()">Close</button>
    </div>

</dialog-layout>
