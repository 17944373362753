import { createAction, props } from '@ngrx/store';

export const showConfirmationDisableTearing = createAction(
    '[Systems Tearing] Show Confirmation Disable Tearing',
    props<{ systemId: number }>()
);
export const cancelConfirmationDisableTearing = createAction('[Systems Tearing] Cancel Confirmation Disable Tearing');

export const loadShiftsTearingDisableCountFailed = createAction(
    '[Systems Tearing] Load Shifts Tearing Disable Count Fail',
    props<{ error: any }>()
);

export const saveShiftsTearingDisableReason = createAction(
    '[Systems Tearing] Save Shifts Tearing Disable Reason',
    props<{ systemId: number; reason: string }>()
);

export const saveShiftsTearingDisableReasonSuccess = createAction(
    '[Systems Tearing] Save Shifts Tearing Disable Reason Success'
);

export const saveShiftsTearingDisableReasonFail = createAction(
    '[Systems Tearing]  Save Shifts Tearing Disable Reason Fail',
    props<{ error: any }>()
);
