import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as timezonePlugin from 'dayjs/plugin/timezone';
import { ConnectDocumentUploadFormValidators } from './document-upload-form.validators';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions';
import { Observable, takeUntil, withLatestFrom } from 'rxjs';
import { DocumentAttributeField } from '../../store/models/document-attribute-field';
import { DocumentAttributeFieldTypes } from '../../store/enums/document-attribute-field-types';
import { DocumentAttributeValidatorTypes } from '../../store/enums/document-attribute-validator-types';
import { DocumentAttributeFieldOption } from '../../store/models/document-attribute-field-option';
import { DocumentAttributeValidator } from '../../store/models/document-attribute-validator';
import { DefaultNeverExpireDocumentDate } from 'src/app/shared/constants/constants';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezonePlugin);

@Component({
    selector: 'ayac-document-attributes-form',
    templateUrl: 'document-attributes-form.component.html',
    styleUrls: ['document-attributes-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentAttributesFormComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() contractInfoInputString = '';
    @Input() areMetadataFieldsLoaded$: Observable<boolean>;

    form: UntypedFormGroup;
    standardInputHeight = 47;
    documentAttributeFieldTypes = DocumentAttributeFieldTypes;
    documentAttributeValidatorTypes = DocumentAttributeValidatorTypes;
    hardwiredFields: string[] = [];
    candidateDocumentId: number;
    docTypeId: number;
    isNotExpirationDateChecked = false;

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly store: Store,
        private readonly _cd: ChangeDetectorRef
    ) {
        super();
    }

    get documentAttributeFields$(): Observable<DocumentAttributeField[]> {
        return this.store.select(selectors.selectDocumentUploadMetadataFields);
    }

    get formIsValid(): boolean {
        return this.form.valid;
    }

    ngOnInit(): void {
        // Cannot combine these because we want them to fire individually and respond individually
        this.store
            .select(selectors.selectSaveDocumentMetadataCalled)
            .pipe(takeUntil(this.d$))
            .subscribe((saveCalled: boolean) => {
                if (saveCalled) {
                    this.store.dispatch(
                        actions.saveDocumentMetadata({ documentMetadataInputForSave: this.form.value })
                    );
                }
            });

        this.store
            .select(selectors.selectCandidateDocumentId)
            .pipe(takeUntil(this.d$))
            .subscribe((candidateDocumentId: number) => {
                this.candidateDocumentId = candidateDocumentId;
            });

        this.store
            .select(selectors.selectDocumentUploadDocTypeId)
            .pipe(takeUntil(this.d$))
            .subscribe((docTypeId: number) => {
                this.docTypeId = docTypeId;
            });

        this.store
            .select(selectors.selectDocumentUploadMetadataFields)
            .pipe(takeUntil(this.d$))
            .subscribe((fields: DocumentAttributeField[]) => {
                const controls = fields.reduce((controlsConfig, field) => {
                    const validators = this.getValidators(field);

                    const value = field.selectedOption?.fieldOptionId
                        ? field.fieldOptions.find((x) => x.fieldOptionId === field.selectedOption?.fieldOptionId)
                        : this.convertFieldStringValue(field.fieldType, field.fieldValue);

                    controlsConfig[field.fieldName] = this._fb.control(value, {
                        validators,
                        updateOn:
                            field.fieldType === DocumentAttributeFieldTypes.Date ||
                            field.fieldType === DocumentAttributeFieldTypes.DateCompare ||
                            field.fieldType === DocumentAttributeFieldTypes.Text
                                ? 'blur'
                                : 'change'
                    });
                    // REMOVE THIS FOR NOW - DOCUMENT NOTE IS NOT REQUIRED FOR THE MVP OF THIS NEW FEATURE.
                    // WE MAY ADD THIS BACK IN LATER.
                    // Add note form control if other valid controls are present.
                    // if (Object.keys(controlsConfig).length > 0) {
                    //     // eslint-disable-next-line @typescript-eslint/dot-notation
                    //     controlsConfig['note'] = this._fb.control('', {
                    //         updateOn: 'blur'
                    //     });
                    // }
                    return controlsConfig;
                }, {});

                this.form = this._fb.group(controls);

                // If there are no controls (occurs after a delete of a document, set the form to invalid to correctly disable the save button after a delete)
                if (Object.keys(this.form.controls).length > 0) {
                    this.store.dispatch(actions.setIsFormValid({ isFormValid: this.form.valid }));
                } else {
                    this.store.dispatch(actions.setIsFormValid({ isFormValid: false }));
                }

                Object.keys(this.form.controls)
                    .filter((c) => !this.hardwiredFields.includes(c))
                    .forEach((c) => {
                        // eslint-disable-next-line rxjs/no-nested-subscribe
                        this.form.controls[c].valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
                            this.store.dispatch(actions.setDocumentAttributeValue({ fieldName: c, fieldValue: value }));
                        });
                    });

                // eslint-disable-next-line rxjs/no-nested-subscribe
                this.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((result) => {
                    this.store.dispatch(actions.setIsFormValid({ isFormValid: this.form.valid }));
                });
            });

        this.store
            .select(selectors.selectDocumentUploadMetadataAttributes)
            .pipe(withLatestFrom(this.store.select(selectors.selectDocumentUploadMetadataFields)), takeUntil(this.d$))
            .subscribe(([documentAttributes, documentAttributeFields]) => {
                documentAttributes
                    .filter((da) => this.hardwiredFields.includes(da.fieldName))
                    .forEach((da) => {
                        const field = documentAttributeFields.find((f) => f.fieldId === da.fieldId);
                        const value = da.selectedOption?.fieldOptionId
                            ? field.fieldOptions.find((x) => x.fieldOptionId === da.selectedOption.fieldOptionId)
                            : this.convertFieldStringValue(da.fieldType, da.value);
                        this.form.get(da.fieldName)?.setValue(value);
                    });
            });

        this.documentAttributeFields$
            .pipe(takeUntil(this.d$))
            .subscribe((documentAttributeFields: DocumentAttributeField[]) => {
                const expirationField = documentAttributeFields.find(
                    (field) => field.fieldName === 'expirationDate' && field.fieldValue
                );
                if (expirationField) {
                    const [year, month, day] = expirationField.fieldValue.split('-').map(Number);
                    const fieldValueAsDate = new Date(year, month - 1, day);
                    fieldValueAsDate.setHours(0, 0, 0, 0);
                    const defaultDate = new Date(DefaultNeverExpireDocumentDate);
                    if (fieldValueAsDate.getTime() === defaultDate.getTime()) {
                        this.isNotExpirationDateChecked = true;
                        this.onExpirationDateCheckedChange(true);
                    }
                }
            });
    }

    getValidators(field: DocumentAttributeField): ValidatorFn[] {
        const validators: ValidatorFn[] = [];
        if (field.isRequired) {
            validators.push(Validators.required);
        }

        if (field.fieldName === 'expirationDate') {
            validators.push(Validators.required);
        }

        if (field.fieldType === DocumentAttributeFieldTypes.Numeric) {
            validators.push(Validators.min(-2147483648));
            validators.push(Validators.max(2147483647));
        }

        if (
            field.fieldType === DocumentAttributeFieldTypes.Text ||
            field.fieldType === DocumentAttributeFieldTypes.Textarea
        ) {
            validators.push(Validators.maxLength(200));
        }

        if (
            field.fieldType === DocumentAttributeFieldTypes.Date ||
            field.fieldType === DocumentAttributeFieldTypes.DateCompare
        ) {
            for (const validator of field.validators) {
                if (validator.name === DocumentAttributeValidatorTypes.Max) {
                    validators.push(
                        ConnectDocumentUploadFormValidators.maxDate(dayjs.utc(validator.value).local().toDate())
                    );
                } else if (validator.name === DocumentAttributeValidatorTypes.Min) {
                    validators.push(
                        ConnectDocumentUploadFormValidators.minDate(dayjs.utc(validator.value).local().toDate())
                    );
                }
            }
        }
        return validators;
    }

    keyPressNumericWithSlash(event: any): void {
        const input = String.fromCharCode(event.keyCode);

        if (!/[0-9\/]/.test(input)) {
            event.preventDefault();
        }
    }

    onPaste(event: ClipboardEvent) {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text');
        if (!/^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]([1-9][0-9])\d\d$/.test(pastedText)) {
            event.preventDefault();
        }
    }

    optionComparison(option: DocumentAttributeFieldOption, value: DocumentAttributeFieldOption): boolean {
        return option.fieldOptionId === value?.fieldOptionId;
    }

    getValidatorMaxDateValue(validators: DocumentAttributeValidator[]): Date {
        const value = validators.find((v) => v.name === DocumentAttributeValidatorTypes.Max)?.value;
        return value ? dayjs.utc(value).local().toDate() : new Date(9999, 0, 0);
    }

    getValidatorMinDateValue(validators: DocumentAttributeValidator[]): Date {
        const value = validators.find((v) => v.name === DocumentAttributeValidatorTypes.Min)?.value;
        return value ? dayjs.utc(value).local().toDate() : new Date(0, 0, 0);
    }

    friendlyFormatDate(date: Date) {
        return dayjs(date).format('M/D/YYYY');
    }

    trackFieldBy(index, item: DocumentAttributeField) {
        return item.fieldId;
    }

    hasLongLabel(el: any): boolean {
        const elHeight = el.offsetHeight;
        return elHeight > this.standardInputHeight;
    }

    onExpirationDateCheckedChange(checked: boolean) {
        if (checked) {
            this.form.controls['expirationDate'].setValue(new Date(DefaultNeverExpireDocumentDate));
            this.form.controls['expirationDate'].setErrors(null);
            this.form.controls['expirationDate'].updateValueAndValidity();
        } else {
            this.form.controls['expirationDate'].setValue(null);
            this.form.controls['expirationDate'].addValidators(Validators.required);
            this.form.controls['expirationDate'].updateValueAndValidity();
        }
    }

    private isEmpty = (value: unknown): boolean => {
        if (value === null || value === undefined) {
            return true;
        }

        if (typeof value === 'string') {
            return !value.length;
        }

        if (value instanceof Date || value instanceof File || typeof value === 'number' || typeof value === 'boolean') {
            return false;
        }

        if (Array.isArray(value)) {
            return !value.length || value.every((v) => this.isEmpty(v));
        }

        if (Object.values(value).every((v) => this.isEmpty(v))) {
            return true;
        }

        // Default to not empty when anything else
        return false;
    };

    private convertFieldStringValue(fieldType: string, fieldValue: string) {
        if (this.isEmpty(fieldValue)) {
            return fieldValue;
        }

        switch (fieldType) {
            case DocumentAttributeFieldTypes.Date:
            case DocumentAttributeFieldTypes.DateCompare:
                return dayjs(fieldValue).toDate();
            case DocumentAttributeFieldTypes.Numeric:
                return +fieldValue;
            case DocumentAttributeFieldTypes.TrueFalse:
                return fieldValue.toLowerCase();
            default:
                return fieldValue;
        }
    }
}
