<side-nav-menu [isExpanded]="isExpanded">

    <ayac-menu-item
        *ngFor="let group of menuItems$ | async"
        defaultItem="dashboard"
        [title]="group.name"
        [icon]="group.icon"
        [path]="group.path">

        <ayac-menu-item
            *ngFor="let item of group.children"
            [title]="item.name"
            [icon]="item.icon"
            [path]="item.path"
            [queryParams]="item.queryParams">
        </ayac-menu-item>

    </ayac-menu-item>

</side-nav-menu>
