import { ReleaseGroupType } from 'src/app/facilities/models/enums/release-group-type.enum';
import { CopyFromFacility } from 'src/app/facilities/models/copy-from-facility.lookup';
import { createAction, props } from '@ngrx/store';

export enum CopyReleaseSettingsFromModalActionEnum {
    LoadCopyFromFacilities = '[Copy From Facilities Component] Load Facilities List',
    LoadCopyFromFacilitiesSuccess = '[Copy From Facilities Api] Load Facilities List Success',
    LoadCopyFromFacilitiesFailure = '[Copy From Facilities Api] Load Facilities List Failure'
}

export const loadCopyFromFacilities = createAction(
    CopyReleaseSettingsFromModalActionEnum.LoadCopyFromFacilities,
    props<{ facilityId: number; releaseType: ReleaseGroupType }>()
);
export const loadCopyFromFacilitiesSuccess = createAction(
    CopyReleaseSettingsFromModalActionEnum.LoadCopyFromFacilitiesSuccess,
    props<{ facilities: CopyFromFacility[] }>()
);
export const loadCopyFromFacilitiesFailure = createAction(
    CopyReleaseSettingsFromModalActionEnum.LoadCopyFromFacilitiesFailure,
    props<{ error: any }>()
);
