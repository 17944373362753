import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { loadJobDetails, loadJobDetailsFail, loadJobDetailsSuccess } from '../actions';

import { ToasterService } from 'src/app/core/services';
import { JobsRepositoryService } from 'src/app/jobs/services/jobs-repository.service';

/**
 * Class handles the loading of the job details for one particulat Submittal.
 * Partially duplicates the effects from @see Connect\src\app\jobs\store\effects\jobs-details.effect.ts
 */
@Injectable()
export class SubmittalsJobsDetailsEffect {
    loadJobDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadJobDetails),
            switchMap((action) => {
                if (!action.jobId) {
                    return of(loadJobDetailsSuccess({ job: null }));
                }
                return this.jobsRepository.getJobById(action.jobId).pipe(
                    mergeMap((response) => [loadJobDetailsSuccess({ job: response.job })]),
                    catchError((error) => of(loadJobDetailsFail({ error: error })))
                );
            })
        )
    );

    loadJobDetailsFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadJobDetailsFail),
                tap((action) => {
                    console.log('Job Details Loading Error: ' + action.error);
                    this.toaster.fail(
                        typeof action.error === 'string' ? action.error : 'This job is no longer available.'
                    );
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly jobsRepository: JobsRepositoryService,
        private readonly toaster: ToasterService
    ) {}
}
