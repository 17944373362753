import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { GridStateChangeEvent } from 'src/app/shared/grid/models';
import { GridDataResult } from 'src/app/shared/grid/models/grid-data-result.model';
import { GridSearchQuery } from 'src/app/shared/grid/models/grid-search-query.model';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { camelize } from 'src/app/shared/utilities';
import { DateHelper } from 'src/app/shared/utilities/date-helper';
import { ShiftListItem } from 'src/app/shifts/models/shift-list-item.model';
import { RemoveCandidateDialogComponent } from 'src/app/vendor-perdiemscheduler/remove-candidate-dialog/remove-candidate-dialog.component';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions/vendor-candidate-details.actions';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors/vendor-candidate-details.selectors';

@UntilDestroy()
@Component({
    selector: 'ayac-upcoming-shifts-list',
    templateUrl: './upcoming-shifts-list.component.html',
    styleUrls: ['./upcoming-shifts-list.component.scss']
})
export class UpcomingShiftsListComponent implements OnInit {
    @Output() viewCurrentShiftRequested = new EventEmitter<ShiftListItem>();
    currentShiftsResult!: GridDataResult;
    isLoading$: Observable<boolean>;
    currentShiftsQuery$: Observable<GridSearchQuery>;
    selectedCurrentShift?: ShiftListItem;
    canRemoveCandidate = false;
    enableCandidateRemovalBefore4HourWindow = false;

    constructor(
        private readonly _store: Store,
        private readonly _dialog: DialogService,
        private readonly _featureManager: LDFeatureManager
    ) {}

    @Input() set candidateId(candidateId: number) {
        if (!candidateId) {
            return;
        }

        this._store.dispatch(
            actions.setCurrentShiftListSearchQuery({
                searchQuery: {
                    skip: 0,
                    take: 10,
                    filter: {
                        filters: [
                            { field: 'oldUserId', operator: 'eq', value: candidateId },
                            { field: 'dateFrom', operator: 'eq', value: new Date().toDateString() }
                        ],
                        logic: 'and'
                    },
                    sort: [{ field: 'shiftDate, startTime', dir: 'asc' }]
                }
            })
        );
    }

    ngOnInit(): void {
        this._store
            .select(selectors.selectCurrentShiftListView)
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.currentShiftsResult = data;
            });
        this.isLoading$ = this._store.select(selectors.selectCurrentShiftListIsLoading);
        this.currentShiftsQuery$ = this._store.select(selectors.selectCurrentShiftListQuery);

        this._featureManager
            .isEnabled(FeatureFlag.EnableCandidateRemovalBefore4HourWindow)
            .pipe(untilDestroyed(this))
            .subscribe((flagIsEnabled) => (this.enableCandidateRemovalBefore4HourWindow = flagIsEnabled));
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this._store.dispatch(
            actions.setCurrentShiftListSearchQuery({
                searchQuery: state
            })
        );
    }

    onRowClicked(shift: ShiftListItem): void {
        if (this.selectedCurrentShift) {
            this.selectedCurrentShift.isSelected = false;
        }

        this.selectedCurrentShift = this.selectedCurrentShift?.id === shift.id ? undefined : shift;

        if (this.selectedCurrentShift) {
            this.selectedCurrentShift.isSelected = true;
        }

        if (this.enableCandidateRemovalBefore4HourWindow) {
            this.canRemoveCandidate = this.selectedCurrentShift?.canRemoveCandidate ?? false;
        } else {
            const isShiftAwayEnoughHours = this.selectedCurrentShift
                ? DateHelper.isOverHoursAwayFromDate(new Date(this.selectedCurrentShift.shiftDate), 12)
                : false;
            this.canRemoveCandidate =
                this.selectedCurrentShift &&
                this.selectedCurrentShift.statusName === 'Filled' &&
                isShiftAwayEnoughHours;
        }
    }

    gridRowClass(context: RowClassArgs): any {
        if (!context.dataItem) {
            return {};
        }

        const statusClassName = `status-${camelize(context.dataItem.statusName)}`;

        return {
            'is-selected': context.dataItem.isSelected,
            [statusClassName]: true
        };
    }

    onDeleteShiftCandidate(): void {
        const dialogRef = this._dialog.openDialog(RemoveCandidateDialogComponent, {
            width: '500px',
            data: {
                shiftId: this.selectedCurrentShift.id,
                seriesId: this.selectedCurrentShift.seriesId
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.selectedCurrentShift.isSelected = false;
                this._store.dispatch(actions.loadCurrentShiftList());
            }
        });
    }
}
