import {
    Input,
    TemplateRef,
    ContentChild,
    Component,
    OnInit,
    HostBinding,
    ChangeDetectionStrategy
} from '@angular/core';
import { FilterService } from '@progress/kendo-angular-grid';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GridColumnDirective } from './grid-column.directive';

@Component({
    selector: 'portal-grid-quick-filter',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridQuickFilterComponent extends GridColumnDirective implements OnInit {
    @Input() field: string;
    @Input() label: string;
    @Input() filterLogic: string = 'eq';
    @Input() toggleValue: any;
    @Input() defaultToFirstItem: boolean;
    @Input() defaultToAllSelected?: boolean;
    @Input() selectAll?: string;
    @Input() reverseAllAndNoneSelectedOnChange: boolean;
    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;

    @Input() filterService: FilterService;
    filterValue$: Observable<any>;

    @HostBinding('style.order') get order() {
        return this.position === 'right' ? 1 : 0;
    }

    @Input() position: string;
    @Input() place: 'toolbar' | 'header' = 'toolbar';
    @Input() options: { [key: string]: any };
    @Input() filterDebounceTime: number = 500;
    @Input() search: boolean = false;

    constructor() {
        super();
        this.filterChange = this.filterChange.bind(this);
    }

    ngOnInit() {
        if (this.filterService) {
            this.filterValue$ = this.filterService.changes.pipe(
                filter((f) => Array.isArray(f.filters)),
                map((f: any) => f.filters.find((a) => a.field === this.field)),
                filter((f) => f !== undefined),
                map((f) => f.value)
            );
        }
    }

    public filterChange(value) {
        this.filterService.filter({
            filters: [
                {
                    field: this.field,
                    operator: 'eq',
                    value: value
                }
            ],
            logic: 'and'
        });
    }
}
