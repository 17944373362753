<ng-container [formGroup]="form">
    <div class="workdays-container">
        <label>
            <span>S</span>
            <mat-checkbox [formControlName]="Workdays.SUNDAY"></mat-checkbox>
        </label>
        <label>
            <span>M</span>
            <mat-checkbox [formControlName]="Workdays.MONDAY"></mat-checkbox>
        </label>
        <label>
            <span>T</span>
            <mat-checkbox [formControlName]="Workdays.TUESDAY"></mat-checkbox>
        </label>
        <label>
            <span>W</span>
            <mat-checkbox [formControlName]="Workdays.WEDNESDAY"></mat-checkbox>
        </label>
        <label>
            <span>T</span>
            <mat-checkbox [formControlName]="Workdays.THURSDAY"></mat-checkbox>
        </label>
        <label>
            <span>F</span>
            <mat-checkbox [formControlName]="Workdays.FRIDAY"></mat-checkbox>
        </label>
        <label>
            <span>S</span>
            <mat-checkbox [formControlName]="Workdays.SATURDAY"></mat-checkbox>
        </label>
    </div>
</ng-container>