import { FacilityProfile } from 'src/app/facilities/models/facility-profile.model';
import { FacilityFeature } from 'src/app/facilities/models/facility-feature.model';
import { LoadingTypes } from 'src/app/shared/models';

export interface FacilityProfileState {
    facilityProfile: FacilityProfile;
    loadingState: LoadingTypes;
}

export const getInitialFacilityProfileState = (): FacilityProfileState => ({
    facilityProfile: null,
    loadingState: LoadingTypes.INIT
});
