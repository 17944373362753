<kendo-multiselect
    [data]="data"
    [textField]="textField"
    [valueField]="valueField"
    [value]="selectedValue"
    [placeholder]="defaultText"
    [valuePrimitive]="true"
    [kendoDropDownFilter]="filterSettings"
    [attr.disabled]="disabled ? '' : null"
    (valueChange)="onChange($event)"
    [popupSettings]="popupSettings"
    [ngClass]="uniqueFilterClass"
    [tagMapper]="tagMapper"
    [class.single-line-chips]="singleLine"
    [class.has-hidden-label]="tagsHiddenCount > 0">
    
    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
        {{ labelText(dataItems) }}
    </ng-template>
      
</kendo-multiselect>

<span class="input-placeholder-сheck"></span>