<div class="form-control-container" [attr.data-qa-id]="dataQaId">
    <div *ngIf="isCentered; else notCenteredLayout" class="form-row-flex">
        <div class="label-container row-flex-gt-sm-15 ">
            <span *ngIf="labelPosition === 'left'">
                {{ controlLabel }}
            </span>
        </div>
        <div class="row-flex-gt-sm-70">
            <div *ngIf="labelPosition === 'top'">
                {{ controlLabel }}
            </div>
            <div class="form-row-flex-15">
                <div class="form-flex">
                    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
                </div>
                <div class="row-flex-14">
                    <span *ngIf="showRequiredAsterisk" class="required-label"></span>
                </div>
            </div>
        </div>
        <div class="label-container row-flex-gt-sm-15 "></div>
    </div>

    <ng-template #notCenteredLayout>
        <div class="w-100" [ngClass]="{'item-flex' : showRequiredAsterisk}">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
            <span *ngIf="showRequiredAsterisk" class="required-label"></span>
        </div>
    </ng-template>
</div>

<ng-template #contentTpl><ng-content></ng-content></ng-template>
