import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import {
    ContractRequirements,
    ContractRequirementUpload,
    ContractRequirementUploadResponse
} from 'src/app/shared/models/candidate';
import { DocumentTypeConfiguration } from 'src/app/shared/models/requirements/document-type-configuration.model';
import { combine } from 'src/app/shared/utilities';

@Injectable({
    providedIn: 'root'
})
export class VendorCandidateContractService {
    constructor(
        private readonly _http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {}

    getContractRequirementsForExport(candidateId: number): Observable<Blob> {
        const url = `${this._settings.CORE}/AyaConnect/Vendor/Candidates/${candidateId}/contract-requirements-export`;
        return this._http.get(url, { responseType: 'blob' });
    }

    getContractRequirements(candidateId: number): Observable<ContractRequirements> {
        const url = `${this._settings.CORE}/AyaConnect/Vendor/Candidates/${candidateId}/contract-requirements`;
        return this._http.get<ContractRequirements>(url);
    }

    getDocumentRequirementsByContractId(
        documentTypeId: number,
        contractId: number,
        includeInternal: boolean,
        includeExternal: boolean
    ) {
        const url = `${this._settings.CORE}/AyaConnect/Vendor/Candidates/DocumentType/${documentTypeId}/Contract/${contractId}/requirements?includeInternal=${includeInternal}&includeExternal=${includeExternal}`;
        return this._http.get<DocumentTypeConfiguration[]>(url).pipe(
            tap((requirements) => {
                requirements.sort((a, b) => {
                    return a.documentTypeDescriptionTypeDisplayOrder - b.documentTypeDescriptionTypeDisplayOrder;
                });
            })
        );
    }

    documentUpload(uploadData: ContractRequirementUpload): Observable<ContractRequirementUploadResponse[]> {
        const uploadRequest = new FormData();
        uploadRequest.append('documentTypeId', uploadData.documentTypeId.toString());
        uploadRequest.append('candidateId', uploadData.candidateId.toString());
        uploadRequest.append('originalFileName', uploadData.originalFileName);
        uploadRequest.append('contractId', uploadData.contractId.toString());
        uploadData.files.forEach((file) => uploadRequest.append('files', file));

        const url = combine(
            `${this._settings.CORE}/AyaConnect/Vendor/Candidates/`,
            uploadData.candidateId.toString(),
            'document-upload'
        );
        return this._http.post<ContractRequirementUploadResponse[]>(url, uploadRequest);
    }
}
