import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/facility-settings.actions';
import {
    FacilitySettingsState,
    getInitialFacilitySettingsState
} from 'src/app/facilities/store/state/facility-settings.state';
import { LoadingTypes } from 'src/app/shared/models';

export function facilitySettingsReducer(state: FacilitySettingsState | undefined, action: Action) {
    return createReducer(
        getInitialFacilitySettingsState(),

        on(
            actions.loadFacilitySettings,
            (): FacilitySettingsState => ({
                ...state,
                loadingState: LoadingTypes.LOADING
            })
        ),

        on(
            actions.loadFacilitySettingsSuccess,
            (state, action): FacilitySettingsState => ({
                ...state,
                facilitySettings: action.settings,
                loadingState: LoadingTypes.LOADED
            })
        ),

        on(
            actions.loadFacilitySettingsFailed,
            (state): FacilitySettingsState => ({
                ...state,
                loadingState: LoadingTypes.FAIL
            })
        ),

        on(
            actions.updateFacilitySettings,
            (state): FacilitySettingsState => ({
                ...state,
                loadingState: LoadingTypes.LOADING
            })
        ),

        on(
            actions.updateFacilitySettingsSuccess,
            (state): FacilitySettingsState => ({
                ...state,
                loadingState: LoadingTypes.LOADED
            })
        ),

        on(
            actions.updateFacilitySettingsFailed,
            (state): FacilitySettingsState => ({
                ...state,
                loadingState: LoadingTypes.FAIL
            })
        ),

        on(
            actions.clearFacilitySettings,
            (state): FacilitySettingsState => ({
                ...getInitialFacilitySettingsState()
            })
        )
    )(state, action);
}
