import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { CustomFieldsImportResult } from '../models/custom-fields-import-result';
import { TravelersUrl } from '../models/enums/travelers.enums';

@Injectable({
    providedIn: 'root'
})
export class TravelersImportCustomFieldsService {
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings
    ) {}

    importCustomFields(fileToImport: any): Observable<CustomFieldsImportResult> {
        const input = new FormData();
        input.append('file', fileToImport);
        return this.http.post<CustomFieldsImportResult>(
            `${this.settings.CORE}${TravelersUrl.ImportCustomFields}`,
            input
        );
    }
}
