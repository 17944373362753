<form>
    <div>
        <ayac-form-control-wrapper
            [controlLabel]="certificationsLabel"
            class="certifications-control"
        >
            <mat-form-field
                appearance="outline"
            >
                <mat-chip-list #chipList aria-label="Certification">
                    <mat-chip
                        *ngFor="let certificationId of selectedCertificationIds"
                        (removed)="deleteCertification(certificationId)"
                    >
                        {{ getCertificationName(certificationId) }}

                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                        autocomplete="off"
                        [placeholder]="certificationsLabel"
                        #certificationInput
                        [formControl]="certificationsAutocompleteFormControl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="
                            addFilteredCertification($event)
                        "
                        #trigger="matAutocompleteTrigger"
                        (click)="trigger.openPanel()"
                    />
                </mat-chip-list>
                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="certificationSelected($event)"
                >
                    <mat-option
                        *ngFor="
                            let certification of filteredCertifications$ | async
                        "
                        [value]="certification.id"
                    >
                        {{ certification.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ayac-form-control-wrapper>

        <ayac-form-control-wrapper
            *ngIf="selectedCertificationIds.length > 0"
            class="expiration-dates-container"
        >
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Expiration Dates</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <ng-container
                        *ngFor="
                            let certificationForm of formArray.controls;
                            let i = index
                        "
                    >
                        <div
                            class="candidate-profile-control-container certification-form-row"
                            [formGroup]="certificationForm"
                        >
                            <ayac-form-control-wrapper
                                [controlLabel]="
                                    certificationForm.get('name')?.value
                                "
                                [showRequiredAsterisk]="true"
                                class="expidation-date-control"
                            >
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        [matDatepicker]="expDatePicker"
                                        formControlName="expDate"
                                        [attr.data-qa-id]="'ExpDate' + (i + 1)"
                                        required
                                        data-qa-id="ExpDate"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="expDatePicker"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker
                                        #expDatePicker
                                    ></mat-datepicker>

                                    <mat-error
                                        *ngIf="
                                            certificationForm
                                                .get('expDate')
                                                .hasError('required')
                                        "
                                        >Expiration date is required.</mat-error
                                    >
                                    <mat-error
                                    *ngIf="
                                        certificationForm
                                            .get('expDate')
                                            .hasError('InvalidExpDate')
                                    "
                                    >Expiration date needs to be between 1/1/1753 and 12/31/9999.</mat-error
                                >
                                </mat-form-field>
                            </ayac-form-control-wrapper>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </ayac-form-control-wrapper>
    </div>
</form>
