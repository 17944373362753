import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import {
    loadWorkersLinesOfBusiness,
    loadWorkersLinesOfBusinessSucesss,
    loadWorkersLinesOfBusinessFail
} from 'src/app/lookups/state/actions';

export interface WorkersLinesOfBusinessState {
    linesOfBusiness: Array<ListItem>;
    loading: LoadingTypes;
    error: unknown;
}

export const initialWorkersLinesOfBusinessState: WorkersLinesOfBusinessState = {
    linesOfBusiness: [],
    loading: LoadingTypes.INIT,
    error: null
};

export function workersLinesOfBusinessReducer(state: WorkersLinesOfBusinessState, action: Action) {
    return createReducer(
        initialWorkersLinesOfBusinessState,
        on(loadWorkersLinesOfBusiness, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadWorkersLinesOfBusinessSucesss, (state, action) => ({
            ...state,
            linesOfBusiness: action.linesOfBusiness,
            loading: LoadingTypes.LOADED
        })),
        on(loadWorkersLinesOfBusinessFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: WorkersLinesOfBusinessState | undefined, action: Action) {
    return workersLinesOfBusinessReducer(state, action);
}
