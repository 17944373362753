<dialog-layout [title]="data?.title">
    <div mat-dialog-content class="form-group">
        {{data?.text}}

        <form [formGroup]="form" class="reject-form">
            <h3 *ngIf="data.communicationEnabled" class="description">Reason</h3>
            <mat-form-field [ngClass]="{ 'reason-dropdown': data.communicationEnabled === true }" appearance="outline">
                <mat-select formControlName="reason" placeholder="Select a reason">
                    <mat-option *ngFor="let r of data.reasons" [value]="r.id">
                        {{ r.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="!data.communicationEnabled else richTextEditor">
                <mat-label>Additional Notes:</mat-label>
                <mat-form-field appearance="outline">
                    <textarea matInput rows="10" cols="50" formControlName="note"></textarea>
                </mat-form-field>
            </ng-container>
            <ng-template #richTextEditor>
                <h3 class="description">Comments</h3> 
                <ayac-text-area-with-counter placeholder="Enter comment"
                            formControlName="note"
                            [maxLength]="maxCharacterLength"
                            [placeholder]="commentsPlaceholder"
                            [editor]="editor">
                </ayac-text-area-with-counter>                   
            </ng-template>
        </form>
    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button color="primary" (click)="close()">{{cancelButtonText}}</button>
        <button mat-flat-button [color]="confirmButtonColor" [disabled]="!form.valid" type="submit" (click)="close(true)">{{confirmButtonText}}</button>
    </div>
</dialog-layout>
