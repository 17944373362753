import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as WocActions from '../actions/work-order-confirmation.actions';
import { saveJobTemplate } from 'src/app/jobs/store/jobs.actions';
import { state } from '@angular/animations';

export interface WorkOrderConfirmationState {
    wocImage?: Blob;
    wocLoading: LoadingTypes;
    error: string;
}

const initialState: WorkOrderConfirmationState = {
    wocLoading: LoadingTypes.INIT,
    error: ''
};

const reducer = createReducer(
    initialState,
    on(WocActions.loadWorkOrderConfirmationPDF, (state) => ({
        ...state,
        wocLoading: LoadingTypes.LOADING,
        wocImage: null
    })),
    on(WocActions.workOrderConfirmationPDFSuccess, (state, { image }) => ({
        ...state,
        wocLoading: LoadingTypes.LOADED,
        wocImage: image,
        error: ''
    })),
    on(WocActions.workOrderConfirmationPDFError, (state, { error }) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        wocImage: null,
        error: error
    })),
    on(WocActions.postAgreeWoc, (state) => ({
        ...state,
        wocLoading: LoadingTypes.LOADING,
        error: ''
    })),
    on(WocActions.postAgreeWocSuccess, (state) => ({
        ...state,
        wocLoading: LoadingTypes.LOADED,
        error: ''
    })),
    on(WocActions.postAgreeWocError, (state, { error }) => ({
        ...state,
        error: error
    }))
);

export function workOrderConfirmationReducer(state: WorkOrderConfirmationState | undefined, action: Action) {
    return reducer(state, action);
}
