import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { Editor } from 'ngx-editor';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-add-offer-note-dialog',
    templateUrl: './add-offer-note-dialog.component.html',
    styleUrls: ['./add-offer-note-dialog.component.scss']
})
export class AddOfferNoteDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    form: UntypedFormGroup;
    editor!: Editor;
    isVendorClientCommunicationEnabled = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            facilityDirectApplyEnabled: boolean;
        },
        private readonly _dialogRef: MatDialogRef<AddOfferNoteDialogComponent>,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.ldFeatureManager
            .isEnabled(FeatureFlag.VendorClientCommunicationForApnDirectSubmittals)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.isVendorClientCommunicationEnabled = isEnabled && this.data.facilityDirectApplyEnabled;
                if (this.isVendorClientCommunicationEnabled) {
                    this.editor = new Editor();
                }
            });

        this.form = this._formBuilder.group({
            note: ['', [Validators.required]]
        });
    }

    addOfferNote(): void {
        const note = this.form?.get('note')?.value;
        this._dialogRef.close({ note });
    }
}
