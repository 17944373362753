<div class="panel panel-default">
    <div class="panel-body">
        <form class="" [formGroup]="vendorInsuranceForm">
            <fieldset>

                <legend>Vendor Insurance</legend>
                <div class="layout" fxLayout="column" fxFlex="80%">

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <div fxLayout="column" fxFlex="40%"><label for="generalLiabilityExp" class="control-label">
                                    GL Exp. Date:
                                </label>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="60%">
                            <mat-form-field appearance="outline" class="input-field">
                                <input placeholder="Day-Month-Year" matInput [matDatepicker]="generalLiabilityExp"
                                    formControlName="generalLiabilityExp">
                                <mat-datepicker-toggle matSuffix [for]="generalLiabilityExp">
                                </mat-datepicker-toggle>
                                <mat-datepicker #generalLiabilityExp>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="generalLiabilityPerOccurrence" class="control-label">
                                GL Per Occurrence:
                            </label>
                        </div>

                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="generalLiabilityPerOccurrence" id="generalLiabilityPerOccurrence"
                                class="input-field form-control" placeholder="General Liability Per Occurrence Value"
                                type="number" formControlName="generalLiabilityPerOccurrence">
                        </div>
                    </div>


                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="generalLiabilityAggregrate"
                                class="control-label">
                                GL Aggregrate:
                            </label>
                        </div>

                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="generalLiabilityAggregrate" id="generalLiabilityAggregrate"
                                class="vendor-insurance-field" placeholder="General Liability Aggregrate Value"
                                type="number" class="form-control input-field"
                                formControlName="generalLiabilityAggregrate">
                        </div>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="generalLiabilityWaiverOfSubrogation" class="control-label">
                                GL Waiver of Subrogation:

                            </label>

                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="generalLiabilityWaiverOfSubrogation"
                                id="generalLiabilityWaiverOfSubrogation" type="checkbox" class="checkbox"
                                formControlName="generalLiabilityWaiverOfSubrogation" />
                        </div>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="generalLiabilityUmbrellaCoverageRequired" class="control-label">
                                GL Umbrella Coverage Required:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="generalLiabilityUmbrellaCoverageRequired"
                                id="generalLiabilityUmbrellaCoverageRequired" type="checkbox" class="checkbox"
                                formControlName="generalLiabilityUmbrellaCoverageRequired" />
                        </div>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="profecionalLiabilityExp"
                                class="control-label">PL Exp. Date:</label></div>
                        <div fxLayout="column" fxFlex="60%">

                            <mat-form-field appearance="outline" class="input-field">
                                <input placeholder="Day-Month-Year" matInput [matDatepicker]="profecionalLiabilityExp"
                                    formControlName="profecionalLiabilityExp">
                                <mat-datepicker-toggle matSuffix [for]="profecionalLiabilityExp">
                                </mat-datepicker-toggle>
                                <mat-datepicker #profecionalLiabilityExp>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="profecionalLiabilityPerOccurrence"
                                class="control-label">PL Per Occurrence:</label></div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="profecionalLiabilityPerOccurrence" id="profecionalLiabilityPerOccurrence"
                                type="number" formControlName="profecionalLiabilityPerOccurrence"
                                placeholder="Professional Liability Per Occurrence Value"
                                class="form-control input-field" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="profecionalLiabilityAggregrate"
                                class="control-label">PL Aggregrate:</label></div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="profecionalLiabilityAggregrate" id="profecionalLiabilityAggregrate"
                                class="form-control input-field" formControlName="profecionalLiabilityAggregrate"
                                placeholder="Professional Liability Aggregrate Value" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="profecionalLiabilityWaiverOfSubrogation"
                                class="control-label">PL Waiver of Subrogation:</label></div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="profecionalLiabilityWaiverOfSubrogation"
                                id="profecionalLiabilityWaiverOfSubrogation" type="checkbox" class="checkbox"
                                formControlName="profecionalLiabilityWaiverOfSubrogation" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="profecionalLiabilityUmbrellaCoverageRequired"
                                class="control-label">PL Umbrella Coverage Required:</label></div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="profecionalLiabilityUmbrellaCoverageRequired"
                                id="profecionalLiabilityUmbrellaCoverageRequired" type="checkbox" class="checkbox"
                                formControlName="profecionalLiabilityUmbrellaCoverageRequired" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="workersCompExp" class="control-label">
                                WC Exp. Date:</label></div>
                        <div fxLayout="column" fxFlex="60%">

                            <mat-form-field appearance="outline" class="input-field">
                                <input placeholder="Day-Month-Year" matInput [matDatepicker]="workersCompExp"
                                    formControlName="workersCompExp">
                                <mat-datepicker-toggle matSuffix [for]="workersCompExp">
                                </mat-datepicker-toggle>
                                <mat-datepicker #workersCompExp>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="workersCompWcaccident" class="control-label">
                                WC Each Accident:</label></div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="workersCompWcaccident" id="workersCompWcaccident"
                                formControlName="workersCompWcaccident" placeholder="WC Each Accident Value"
                                class="form-control input-field" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="workersCompDisease" class="control-label">
                                WC Disease - each empl:</label></div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="workersCompDisease" id="workersCompDisease"
                                formControlName="workersCompDisease" placeholder="Disease - each empl Value"
                                class="form-control input-field" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="workersCompLimit" class="control-label">
                                WC Limit:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="workersCompLimit" id="workersCompLimit"
                                formControlName="workersCompLimit" placeholder="Worker’s Compensation Limit Value"
                                class="form-control input-field" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="workersCompWaiverOfSubrogation" class="control-label">
                                WC Waiver of Subrogation:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="workersCompWaiverOfSubrogation" id="workersCompWaiverOfSubrogation"
                                type="checkbox" class="checkbox" formControlName="workersCompWaiverOfSubrogation"
                                name="workersCompWaiverOfSubrogation" />

                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="workersCompUmbrellaCoverageRequired"
                                class="control-label">
                                WC Umbrella Coverage Required:</label></div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="workersCompUmbrellaCoverageRequired"
                                id="workersCompUmbrellaCoverageRequired" type="checkbox" class="checkbox"
                                formControlName="workersCompUmbrellaCoverageRequired"
                                name="workersCompUmbrellaCoverageRequired" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="umbrellaExp" class="control-label">
                                Umbrella Exp. Date:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">
                            <mat-form-field appearance="outline" class="input-field">
                                <input placeholder="Day-Month-Year" matInput [matDatepicker]="umbrellaExp"
                                    formControlName="umbrellaExp">
                                <mat-datepicker-toggle matSuffix [for]="umbrellaExp">
                                </mat-datepicker-toggle>
                                <mat-datepicker #umbrellaExp>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%"><label for="umbrellaPerOccurrence" class="control-label">
                                Umbrella Per Occurrence:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="umbrellaNotes" id="umbrellaPerOccurrence"
                                formControlName="umbrellaPerOccurrence" placeholder="Umbrella Per Occurrence"
                                class="form-control input-field" />
                        </div>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaAggregateLimit" class="control-label">
                                Umbrella Aggregate Limit:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="umbrellaAggregateLimit" id="umbrellaAggregateLimit"
                                formControlName="umbrellaAggregateLimit" placeholder="Umbrella Aggregate Limit"
                                class="form-control input-field" />
                        </div>
                    </div>


                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="autoExp" class="control-label">
                                Auto Exp. Date:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">


                            <mat-form-field appearance="outline" class="input-field">
                                <input placeholder="Day-Month-Year" matInput [matDatepicker]="autoExp"
                                    formControlName="autoExp">
                                <mat-datepicker-toggle matSuffix [for]="autoExp">
                                </mat-datepicker-toggle>
                                <mat-datepicker #autoExp>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaAuto" class="control-label">Auto:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="umbrellaAuto" id="umbrellaAuto" formControlName="umbrellaAuto"
                                placeholder="Umbrella Auto Value" class="form-control input-field" />
                        </div>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaCoverageRequired" class="control-label">Auto Umbrella Coverage Required:
                            </label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaCoverageRequired" id="umbrellaCoverageRequired" type="checkbox"
                                class="checkbox" formControlName="umbrellaCoverageRequired"
                                name="umbrellaCoverageRequired" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaWaiverSubrogation" class="control-label">Auto Waiver of
                                Subrogation:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaWaiverSubrogation" id="umbrellaWaiverSubrogation" type="checkbox"
                                class="checkbox" formControlName="umbrellaWaiverSubrogation"
                                name="umbrellaWaiverSubrogation" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="cyberExp" class="control-label">Cyber Exp. Date:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <mat-form-field appearance="outline" class="input-field">
                                <input placeholder="Day-Month-Year" matInput [matDatepicker]="cyberExp"
                                    formControlName="cyberExp">
                                <mat-datepicker-toggle matSuffix [for]="cyberExp">
                                </mat-datepicker-toggle>
                                <mat-datepicker #cyberExp>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaCyber" class="control-label">Cyber:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="umbrellaCyber" id="umbrellaCyber" formControlName="umbrellaCyber"
                                placeholder="Umbrella Cyber Value" class="form-control input-field" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaCyberCoverage" class="control-label">Cyber Umbrella Coverage
                                Required:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaCyberCoverage" id="umbrellaCyberCoverage" type="checkbox"
                                class="checkbox" formControlName="umbrellaCyberCoverage" name="umbrellaCyberCoverage" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaCyberWaiverSubrogation" class="control-label">Cyber Waiver of
                                Subrogation:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaCyberWaiverSubrogation" id="umbrellaCyberWaiverSubrogation"
                                type="checkbox" class="checkbox" formControlName="umbrellaCyberWaiverSubrogation"
                                name="umbrellaCyberWaiverSubrogation" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="employeeCrimeExp" class="control-label">Employee Crime Exp. Date:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <mat-form-field appearance="outline" class="input-field">
                                <input placeholder="Day-Month-Year" matInput [matDatepicker]="employeeCrimeExp"
                                    formControlName="employeeCrimeExp">
                                <mat-datepicker-toggle matSuffix [for]="employeeCrimeExp">
                                </mat-datepicker-toggle>
                                <mat-datepicker #employeeCrimeExp>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaEmployeeCrimeTheftDishonesty" class="control-label">Employee
                                Crime/Theft/Dishonesty:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="umbrellaEmployeeCrimeTheftDishonesty"
                                id="umbrellaEmployeeCrimeTheftDishonesty"
                                formControlName="umbrellaEmployeeCrimeTheftDishonesty"
                                placeholder="Umbrella Employee Crime/Theft/Dishonesty"
                                class="form-control input-field" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaCrimeWaiverSubrogation" class="control-label">Crime Waiver of
                                Subrogation:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaCrimeWaiverSubrogation" id="umbrellaCrimeWaiverSubrogation"
                                type="checkbox" class="checkbox" formControlName="umbrellaCrimeWaiverSubrogation"
                                name="umbrellaCrimeWaiverSubrogation" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="sexualAssaultExp" class="control-label">Sexual Assault Exp. Date:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <mat-form-field appearance="outline" class="input-field">
                                <input placeholder="Day-Month-Year" matInput [matDatepicker]="sexualAssaultExp"
                                    formControlName="sexualAssaultExp">
                                <mat-datepicker-toggle matSuffix [for]="sexualAssaultExp">
                                </mat-datepicker-toggle>
                                <mat-datepicker #sexualAssaultExp>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaSexualAssaultCoverage" class="control-label">Sexual Assault
                                Coverage:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="umbrellaSexualAssaultCoverage" id="umbrellaSexualAssaultCoverage"
                                formControlName="umbrellaSexualAssaultCoverage"
                                placeholder="Umbrella Sexual Assault Coverage" class="form-control input-field" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaSexualAssaultCoverageRequired" class="control-label">Sexual Assault
                                Umbrella Coverage Required:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaSexualAssaultCoverageRequired"
                                id="umbrellaSexualAssaultCoverageRequired" type="checkbox" class="checkbox"
                                formControlName="umbrellaSexualAssaultCoverageRequired"
                                name="umbrellaSexualAssaultCoverageRequired" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaSexualAssaultWaiverSubrogation" class="control-label">Sexual Assault
                                Waiver of Subrogation:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaSexualAssaultWaiverSubrogation"
                                id="umbrellaSexualAssaultWaiverSubrogation" type="checkbox" class="checkbox"
                                formControlName="umbrellaSexualAssaultWaiverSubrogation"
                                name="umbrellaSexualAssaultWaiverSubrogation" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="doExp" class="control-label">D&O Exp. Date:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">
                            <mat-form-field appearance="outline" class="input-field">
                                <input placeholder="Day-Month-Year" matInput [matDatepicker]="doExp"
                                    formControlName="doExp">
                                <mat-datepicker-toggle matSuffix [for]="doExp">
                                </mat-datepicker-toggle>
                                <mat-datepicker #doExp>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaDocoverage" class="control-label">Directors & Officers Coverage:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <input data-qa-id="umbrellaDocoverage" id="umbrellaDocoverage"
                                formControlName="umbrellaDocoverage" type="number"
                                placeholder="Umbrella Directors & Officers Coverage" class="form-control input-field" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaDocoverageRequired" class="control-label">
                                D&O Umbrella Coverage Required:
                            </label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaDocoverageRequired" id="umbrellaDocoverageRequired"
                                type="checkbox" class="checkbox" formControlName="umbrellaDocoverageRequired" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaDowaiverSubrogation" class="control-label">D&O Waiver of
                                Subrogation:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaDowaiverSubrogation" id="umbrellaDowaiverSubrogation"
                                type="checkbox" class="checkbox" formControlName="umbrellaDowaiverSubrogation" />
                        </div>
                    </div>

                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaRequiredLanguage" class="control-label">Required Language:</label>
                        </div>
                        <div fxLayout="column" fxFlex="10%" class="checkbox-container">

                            <input data-qa-id="umbrellaRequiredLanguage" id="umbrellaRequiredLanguage" type="checkbox"
                                class="checkbox" formControlName="umbrellaRequiredLanguage"
                                name="umbrellaRequiredLanguage" />
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%">
                        <div fxLayout="column" fxFlex="40%">
                            <label for="umbrellaNotes" class="control-label">Notes:</label>
                        </div>
                        <div fxLayout="column" fxFlex="60%">

                            <textarea id="umbrellaNotes" rows="6" class="form-control input-field"
                                formControlName="umbrellaNotes" placeholder="Umbrella Notes" data-qa-id="umbrellaNotes">
                            </textarea>
                        </div>
                    </div>
                </div>
                 <div fxLayout="column" fxFlex="15%" class="pull-right">
                    <button mat-flat-button color="accent" class="migrated-btn-info" (click)="export()" data-qa-id="exportBtn">
                        <span><i class="fa fa-file-excel" aria-hidden="true"></i>&nbsp;&nbsp;Export</span>
                    </button>
                </div>
            </fieldset>
        </form>
    </div>
</div>
