<div class="list-container" [ngClass]="uniqueClass">
    <div class="list" *ngFor="let professionType of professionTypes; trackBy: trackByProfessionType">
        <div class="row-header">
            <div class="icon-container">
                <mat-icon class="expand-collapse-icon" (click)="toggleExpand(professionType)">{{ professionType.expanded ? 'remove' : 'add' }}</mat-icon>
            </div>
            <mat-checkbox
                [value]="professionType.id"
                [checked]="professionType.isChecked"
                [indeterminate]="professionType.isIndeterminate"
                (change)="onProfessionTypeChange($event.checked, professionType)"
                [disabled]="isReadOnly">
                {{professionType.name}}
            </mat-checkbox>
        </div>
        <ng-container>
            <div *ngIf="professionType.expanded">
                <div class="list profession-list" *ngFor="let profession of professionType.professions; let professionIndex = index">
                    <div class="row-header">
                        <div class="icon-container">
                            <mat-icon *ngIf="profession.specialties?.length > 0" class="expand-collapse-icon" (click)="toggleExpand(profession)">{{ profession.expanded ? 'remove' : 'add' }}</mat-icon>
                        </div>
                        <mat-checkbox
                            [value]="profession.id"
                            [checked]="profession.isChecked"
                            [indeterminate]="profession.isIndeterminate"
                            (change)="onProfessionChange($event.checked, profession)"
                            [disabled]="isReadOnly">
                            {{profession.name}}
                        </mat-checkbox>
                    </div>
                    <ng-container>
                        <div *ngIf="profession.expanded">
                            <div class="list specialty-list" *ngFor="let specialty of profession.specialties; let specialtiesIndex = index">
                                <mat-checkbox
                                    [value]="specialty.id"
                                    [checked]="specialty.isChecked"
                                    (change)="onSpecialtyChange($event.checked, specialty)"
                                    [disabled]="isReadOnly">
                                    {{specialty.name}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
  </div>
