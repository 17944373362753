import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/facility-association.actions';
import {
    FacilityAssociationState,
    getInitialFacilityAssociationState
} from 'src/app/facilities/store/state/facility-association.state';
import { LoadingTypes } from 'src/app/shared/models';

export function facilityAssociationReducer(state: FacilityAssociationState | undefined, action: Action) {
    return createReducer(
        getInitialFacilityAssociationState(),

        on(
            actions.loadFacilityAssociation,
            (): FacilityAssociationState => ({
                ...getInitialFacilityAssociationState(),
                loadingState: LoadingTypes.LOADING
            })
        ),

        on(
            actions.loadFacilityAssociationSuccess,
            (state, action): FacilityAssociationState => ({
                ...state,
                facilityAssociation: action.association,
                loadingState: LoadingTypes.LOADED
            })
        ),

        on(
            actions.loadFacilityAssociationFailure,
            (state): FacilityAssociationState => ({ ...state, loadingState: LoadingTypes.FAIL })
        )
    )(state, action);
}
