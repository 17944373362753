<loading-indicator *ngIf="(isLoading) || (isDocumentPreviewUploadLoading$ | async) === true"></loading-indicator>
<ng-container *ngIf="isEdit">
    <ayac-banner [color]="'warn-orange-darker'" [closeHidden]="true" class="mb-4 mt-3">
        <span data-qa-id="vendor-compliance-document-upload-edit-document-banner-text"  class="banner-doc-pending-approval-text">
            A file for this document was submitted and is pending approval.
        </span>
    </ayac-banner>
</ng-container>
<div class="extra-btm-padding">
    <ayac-contact-toolbar
        [hasChanges]="hasChanges"
        [disableSaveAndCancel]="(isMetadataFormValid$ | async) === false || (hasChanges$ | async) === false"
        [qaId]="'vendor_candidates_detail_doc_upload'"
        [disableCompleteSubmittal]="true"
        (backClicked)="back()"
        (saveClicked)="save()"
        [showCancelButton]="false"
        [showCompleteSubmittal]="false"
        [showBackButtonIcon]="false"
        [showOutlineBackButtonNoIcon]="true"
        [showSaveButtonIcon]="false"
        [showSubmitButtonNoIcon]="true">
    </ayac-contact-toolbar>
</div>

<div class="panel panel-warning">
    <div class="panel-heading contact-header"
         [ngClass]="{'header-panel-loading' : isLoading}">

        <ng-container *ngIf="candidate">
            Document:&nbsp;{{ candidate?.firstName
            }}&nbsp;{{ candidate?.middleName ? candidate.middleName + '&nbsp;' : ''
            }}{{ candidate?.lastName }}&nbsp;&#8210;&nbsp;{{
            selectedDocument?.title }}
        </ng-container>
    </div>
</div>
<div class="doc-upload-container">
    <div class="file-attachment-form">
        <div class="file-input-header">All fields with&nbsp;<span class="required-asterisk">&ast;</span>&nbsp;must
            be
            completed.</div>

            <div class="view-requirement-details">
                <a (click)="viewDocumentDetails(selectedDocument)" class="view-requirement-details-link" data-qa-id="doc-upload-view-req-details-link">View Requirement Details</a>
            </div>

        <ng-container *ngIf="isAttachmentDataEmpty && !isEdit">
            <ayac-file-attachments-form #uploadFormComponent
                                        [isMultiple]="false"
                                        [acceptableFileTypes]="validExtensionsString"
                                        [maxFileSizeMb]="validFileSize"
                                        [showBrowseButton]="true"
                                        (attachmentUpload)="attachmentUpload($event)"></ayac-file-attachments-form>
            <div class="note-container">
                <span class="file-upload-details">&ast;Accepted file types: {{ validExtensionsString }}</span>
                <span class="file-upload-details">&ast;Maximum file size: {{ validFileSize }} MB.</span>
            </div>
        </ng-container>

        <ayac-document-upload-info [isEdit]="isEdit"
                                   [displayedColumns]="displayedColumns"
                                   [dataSource]="dataSource"
                                   (deleteAttachment)="deleteAttachment()"></ayac-document-upload-info>

        <div class="container-content">
            <div fxLayout="column"
                 fxLayoutAlign="start stretch">
                <ayac-document-attributes-form [contractInfoInputString]="contractInfoInputString"
                                               [areMetadataFieldsLoaded$]="areMetadataFieldsLoaded$"></ayac-document-attributes-form>
            </div>
        </div>
    </div>
    <div class="file-preview">
        <ayac-vendor-compliance-document-preview [showToolbar]="false"
                               [sidebarVisible]="false"></ayac-vendor-compliance-document-preview>
    </div>
</div>
