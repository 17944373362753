import { CoreEffects } from './core.effects';
import { CoreWelcomeEffects } from './core-welcome.effects';
import { CorePreferenceEffects } from './core-preferences.effects';
import { CoreBrandingEffects } from './core-branding.effect';
import { CorePendoEffects } from './core-pendo.effect';
import { ClientSharedDataEffect } from 'src/app/core/state/effects/client-shared-data.effect';
import { CoreMenuEffects } from 'src/app/core/state/effects/core-menu.effects';

export const coreEffects = [
    CoreEffects,
    CoreWelcomeEffects,
    CorePreferenceEffects,
    CoreBrandingEffects,
    CorePendoEffects,
    ClientSharedDataEffect,
    CoreMenuEffects
];
