import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { JobsReleaseDialogComponent } from 'src/app/admin/jobs/jobs-release-dialog.component';
import { JobGridItem } from 'src/app/admin/jobs/models/job-grid-item.model';
import { JobLookup } from 'src/app/admin/jobs/models/job-lookup.model';
import { adminJobsActions } from 'src/app/admin/store/actions/admin-jobs.actions';
import {
    selectAdminJobLookups,
    selectAdminJobs,
    selectAdminJobsFilteredAllCount,
    selectAdminJobsFilteredOpenCount,
    selectAdminJobsGridSearchQuery,
    selectAdminJobsTotal,
    selectIsLoading
} from 'src/app/admin/store/selectors/admin-jobs.selectors';
import { GridComponent } from 'src/app/shared/grid/components/grid.component';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { billRateFormat } from 'src/app/shared/utilities/bill-rate-format';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-admin-jobs',
    templateUrl: './admin-jobs.component.html',
    styleUrls: ['./admin-jobs.component.scss']
})
export class AdminJobsComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChild('adminJobsGrid', { static: false }) adminJobsGrid: GridComponent;

    adminJobs$: Observable<JobGridItem[]>;
    adminJobsQuery$: Observable<GridSearchQuery>;
    adminOpenJobs$: Observable<number>;
    adminAllJobs$: Observable<number>;
    adminJobsTotal$: Observable<number>;
    adminJobLookups$: Observable<JobLookup>;
    isLoading$: Observable<boolean>;

    facilityReleaseJobsControl = new UntypedFormControl('');

    releaseFilterOptions = [
        {
            text: 'Not Released',
            name: '',
            image: 'fa-lock',
            color: 'black',
            id: 1
        },
        {
            text: 'Released',
            name: '',
            image: 'fa-unlock',
            color: 'green',
            id: 3
        }
    ];

    featureFlag = FeatureFlag;
    payFormat = billRateFormat;

    constructor(
        private readonly _store: Store,
        private readonly _dialogService: DialogService,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    get numberOfGridSelectedRows(): number {
        return this.adminJobsGrid?.selectedRows?.length ?? 0;
    }

    get gridSelectedRows() {
        return this.adminJobsGrid?.selectedRows ?? [];
    }

    get canSelectedRowsAllRelease() {
        return this.adminJobsGrid?.selectedRows.every((item: JobGridItem) => item.canRelease) ?? false;
    }

    ngOnInit(): void {
        this.adminJobs$ = this._store.select(selectAdminJobs);
        this.adminJobsQuery$ = this._store.select(selectAdminJobsGridSearchQuery);
        this.adminOpenJobs$ = this._store.select(selectAdminJobsFilteredOpenCount);
        this.adminAllJobs$ = this._store.select(selectAdminJobsFilteredAllCount);
        this.adminJobsTotal$ = this._store.select(selectAdminJobsTotal);
        this.adminJobLookups$ = this._store.select(selectAdminJobLookups);
        this.isLoading$ = this._store.select(selectIsLoading);

        this._store.dispatch(adminJobsActions.loadAdminJobs());
        this._store.dispatch(adminJobsActions.loadAdminJobLookups());
    }

    ngOnDestroy(): void {
        this._store.dispatch(adminJobsActions.resetGridSearchQuery());
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this._store.dispatch(adminJobsActions.setVendorCandidatesListSearchQuery({ searchQuery: state }));
    }

    refresh(): void {
        this._store.dispatch(adminJobsActions.loadAdminJobs());
    }

    export(): void {
        this._store.dispatch(adminJobsActions.exportAdminJobs());
    }

    exportSelected(): void {
        const ids = this.adminJobsGrid.selectedRows.map((item) => item.id);
        this._store.dispatch(adminJobsActions.exportSelectedAdminJobs({ selectedJobs: ids }));
    }

    facilityChanged(event: MatSelectChange): void {
        this.adminJobsGrid.clearSelection();
        this._store.dispatch(adminJobsActions.setFacilityGroupIdFilter({ facilityGroupId: event.value }));
    }

    releaseJobs(): void {
        const jobIds = this.gridSelectedRows.map((item) => item.id);

        this._dialogService
            .openDialog(JobsReleaseDialogComponent, {
                data: {
                    facilityIds: this.gridSelectedRows
                        .map((item) => item.facilityId)
                        .filter((item, idx, self) => self.indexOf(item) === idx),
                    jobIds
                },
                width: 'auto'
            })
            .afterClosed()
            .pipe(filter(Boolean))
            .subscribe((value: { vendorIds: number[] }) => {
                this.adminJobsGrid.clearSelection();
                this._store.dispatch(adminJobsActions.releaseJobs({ entityIds: jobIds, vendorIds: value.vendorIds }));
            });
    }

    releaseJobPositions(): void {
        const jobIds = this.gridSelectedRows.map((item) => item.id);
        this._dialogService
            .openDialog(JobsReleaseDialogComponent, {
                data: {
                    facilityIds: this.gridSelectedRows
                        .map((item) => item.facilityId)
                        .filter((item, idx, self) => self.indexOf(item) === idx),
                    jobIds: jobIds,
                    isReleaseAllPositions: true
                },
                width: 'auto'
            })
            .afterClosed()
            .pipe(filter(Boolean), first())
            .subscribe((value: { vendorIds: number[] }) => {
                this.openConfirmationDialog(jobIds, value?.vendorIds);
            });
    }

    async openConfirmationDialog(jobIds: number[], vendorIds: number[]) {
        const selectedVendorCount = vendorIds?.length;
        let jobSuffix = '';
        let vendorSuffix = '';
        if (selectedVendorCount && selectedVendorCount > 0) {
            if (jobIds?.length > 1) {
                jobSuffix = 's';
            }
            if (vendorIds?.length > 1) {
                vendorSuffix = 's';
            }
            const options: IConfirmationDialogOptions = {
                data: {
                    title: 'Release All Positions',
                    text: `All positions in the selected job${jobSuffix} will be released to ${selectedVendorCount} vendor${vendorSuffix} immediately and can not be undone. Are you sure you would like to proceed?`,
                    confirmButtonText: 'confirm',
                    cancelButtonText: 'cancel'
                }
            };

            const isRelease = await this._dialogService.openConfirmationDialog(options);
            if (isRelease) {
                this.releaseAllPositions(jobIds, vendorIds);
            }
        }
    }

    private releaseAllPositions(jobIds: number[], vendorIds: number[]) {
        if (jobIds?.length > 0 && vendorIds?.length > 0) {
            this._store.dispatch(adminJobsActions.releaseAllJobsPositions({ jobIds, vendorIds: vendorIds }));
        }
    }
}
