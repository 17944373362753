import { createReducer, on, Action } from '@ngrx/store';
import * as extensionActions from 'src/app/travelers/state/actions/travelers-extension.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { ExtensionOfferFacilitySettings } from 'src/app/travelers/models';

export interface TravelersExtensionOfferState {
    settings: ExtensionOfferFacilitySettings;
    settingsLoading: LoadingTypes;
    settingsLoadingError: string | null;
}

export const initialState: TravelersExtensionOfferState = {
    settings: null,
    settingsLoading: LoadingTypes.INIT,
    settingsLoadingError: null
};

export const travelersExtensionOfferReducer = createReducer(
    initialState,
    on(
        extensionActions.getExtensionOfferFacilitySettings,
        (state): TravelersExtensionOfferState => ({
            ...state,
            settings: null,
            settingsLoading: LoadingTypes.LOADING,
            settingsLoadingError: null
        })
    ),
    on(
        extensionActions.getExtensionOfferFacilitySettingsSuccess,
        (state, action): TravelersExtensionOfferState => ({
            ...state,
            settings: action.settings,
            settingsLoading: LoadingTypes.LOADED,
            settingsLoadingError: null
        })
    ),
    on(
        extensionActions.getExtensionOfferFacilitySettingsFailure,
        (state, action): TravelersExtensionOfferState => ({
            ...state,
            settings: null,
            settingsLoading: LoadingTypes.FAIL,
            settingsLoadingError: action.error
        })
    )
);

export const reducer = (state: TravelersExtensionOfferState | undefined, action: Action) =>
    travelersExtensionOfferReducer(state, action);
