<ng-container *ngIf="education$ | async as education">
    <mat-card class="education-card" data-qa-id="ac_client_smd_details_education" *ngIf="education.length > 0">
        <mat-card-title class="title"><span>Education</span></mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
                <table mat-table [dataSource]="education" class="education-table"
                    aria-label="List of candidate's education history">
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Start Date </th>
                        <td mat-cell *matCellDef="let education"> {{education.educationDateStart | stringDate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dateGraduated">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Graduation Date </th>
                        <td mat-cell *matCellDef="let education"> {{education.educationDateEnd | stringDate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="degree">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Degree </th>
                        <td mat-cell *matCellDef="let education"> {{education.degree}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fieldOfStudy">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Field Of Study </th>
                        <td mat-cell *matCellDef="let education"> {{education.fieldOfStudy}} </td>
                    </ng-container>

                    <ng-container matColumnDef="school">
                        <th mat-header-cell *matHeaderCellDef scope="row" class="large-cell"> School </th>
                        <td mat-cell *matCellDef="let education"> {{education.schoolName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef scope="row"> Location </th>
                        <td mat-cell *matCellDef="let education"> {{education.location}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="educationDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: educationDisplayedColumns;"></tr>
                </table>
        </mat-card-content>
    </mat-card>
</ng-container>
