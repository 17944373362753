<mat-toolbar *ngIf="!headerHidden && title" class="portal-grid-header">
    <mat-toolbar-row class="px-0">
        <ng-container *ngIf="headerTemplate; else defaultHeader"
            [ngTemplateOutlet]="headerTemplate"
            [ngTemplateOutletContext]="{ title: title, total: data?.total, filtered: data?.filtered }">
        </ng-container>
        <ng-template #defaultHeader>
            <span class="mat-headline-1 mb-0">{{title}}</span>
            <span>&nbsp;</span>
            <small class="mat-subtitle-1 mb-0 pl-2">
                (<ng-container *ngIf="data?.filtered || data?.filtered === 0">Filtered: {{data.filtered}} / </ng-container>Total: {{data?.total}})
            </small>
        </ng-template>
        <div class="porta-grid-header-quick-filters ml-2">
            <ng-container
                [ngTemplateOutlet]="quickSection"
                [ngTemplateOutletContext]="{
                    filters: quickFiltersHeader
                }">
            </ng-container>
        </div>
        <div class="portal-grid-header-toolbar"
            *ngIf="headerToolbarTemplate">
            <ng-container
                [ngTemplateOutlet]="headerToolbarTemplate">
            </ng-container>
        </div>
    </mat-toolbar-row>
</mat-toolbar>

<ng-container [ngTemplateOutlet]="prefixContainerTemplate" [ngTemplateOutletContext]="{ filters: quickFiltersHeader }">
</ng-container>

<kendo-grid
    class="portal-grid"
    [rowClass]="rowClass"
    [scrollable]="scrollable"
    [loading]="loading"
    [data]="kendoGridData"

    [pageable]="pageable"
    [pageSize]="pageSize"
    [skip]="skip"

    [groupable]="groupable"
    [group]="groups"

    [filterable]="filterable"
    [filter]="filter"

    [sortable]="sortable"
    [sort]="sort"

    [selectable]="selectableSettings"
    [rowSelected]="isRowSelected"

    (sortChange)="onSortChange($event)"
    (pageChange)="onPageChange($event)"
    (filterChange)="onFilterChange($event)"
    (dataStateChange)="onDataStateChange($event)"
    (selectionChange)="onSelection($event)"
    (cellClick)="onCellClick($event)"
    (groupChange)="onGroupChange($event)"
    (click)="onGridClick($event)">

    <!-- Quick filters section -->
    <ng-template #quickSection let-filters="filters">
        <portal-grid-quick-filter
            class="mx-2"
            #quickComponent
            *ngFor="let quick of filters"
            [field]="quick.field"
            [filterService]="filterService"
            [position]="quick.position">
            <ng-container *ngIf="!quick.template">
                <ng-container [ngSwitch]="quick.filterType">
                    <ng-container *ngSwitchCase="GridQuickFilterEnum.MULTISELECT">
                        <ng-container *ngIf="quick.label">
                            <label class="mr-2">{{ quick.label }}</label>
                        </ng-container>
                        <grid-dropdown-multiselect-filter
                            [selectAll]="quick.selectAll"
                            [field]="quick.field"
                            [data]="quick.filterOptions"
                            [valueField]="quick.valueField"
                            [textField]="quick.textField"
                            [filter]="filter"
                            [defaultText]="quick.defaultText"
                            [width]="quick.width"
                            [search]="quick.search"
                            [required]="quick.required"
                            [reverseAllAndNoneSelectedOnChange]="quick.reverseAllAndNoneSelectedOnChange"
                            [defaultToFirstItem]="quick.defaultToFirstItem"
                            [defaultToAllSelected]="quick.defaultToAllSelected">
                        </grid-dropdown-multiselect-filter>
                    </ng-container>

                    <ng-container *ngSwitchCase="GridQuickFilterEnum.GROUPMULTISELECT">
                        <grid-multifield-multiselect-filter
                            [selectAll]="quick.selectAll"
                            [width]="quick.width"
                            [filter]="filter"
                            [field]="group"
                            (filterChanging)="quick.filterChanging.emit($event)"
                            [defaultToAllSelected]="quick.defaultToAllSelected"
                            [defaultText]="quick.defaultText"
                            [data]="quick.filterOptions.data" >
                        </grid-multifield-multiselect-filter>
                    </ng-container>

                    <ng-container *ngSwitchCase="GridQuickFilterEnum.TEXT">
                        <ng-container *ngIf="quick.label">
                            <label>{{ quick.label }}</label>
                            <br>
                        </ng-container>
                        <grid-text-filter
                            [field]="quick.field"
                            [filter]="filter"
                            [uniqueFilterClass]="quick.uniqueFilterClass"
                            [width]="quick.width"
                            [filterDebounceTime]="quick.filterDebounceTime"
                            (filterChanging)="quick.filterChanging.emit($event)"
                        ></grid-text-filter>
                    </ng-container>
                    <ng-container *ngSwitchCase="GridQuickFilterEnum.SWITCH">
                        <grid-switch-filter
                            [matTooltip]="quick.filterTooltip"
                            [field]="quick.field"
                            [filter]="filter"
                            [label]="quick.label"
                            [toggleValue]="quick.toggleValue"
                            [uniqueFilterClass]="quick.uniqueFilterClass">
                        </grid-switch-filter>
                    </ng-container>
                    <ng-container *ngSwitchCase="GridQuickFilterEnum.DATE">
                        <ng-container *ngIf="quick.label">
                            <label>{{ quick.label }}</label>
                            <br>
                        </ng-container>
                        <grid-date-filter
                            [field]="quick.field"
                            [filter]="filter"
                            [logic]="quick.filterLogic"
                            [options]="quick.options"
                            [uniqueFilterClass]="quick.uniqueFilterClass">
                        </grid-date-filter>
                    </ng-container>
                    <ng-container *ngSwitchCase="GridQuickFilterEnum.TIME">
                        <ng-container *ngIf="quick.label">
                            <label>{{ quick.label }}</label>
                            <br>
                        </ng-container>
                        <grid-time-filter
                            [field]="quick.field"
                            [filter]="filter"
                            [uniqueFilterClass]="quick.uniqueFilterClass">
                        </grid-time-filter>
                    </ng-container>
                    <ng-container *ngSwitchCase="GridQuickFilterEnum.DROPDOWN">
                        <ng-container *ngIf="quick.label">
                            <label>{{ quick.label }}</label>
                            <br>
                        </ng-container>
                        <grid-dropdown-filter
                            [field]="quick.field"
                            [data]="quick.filterOptions"
                            [valueField]="quick.valueField"
                            [textField]="quick.textField"
                            [filter]="filter"
                            [defaultText]="quick.defaultText"
                            [defaultToFirstItem]="quick.defaultToFirstItem"
                            [uniqueFilterClass]="quick.uniqueFilterClass"
                            [width]="quick.width">
                        </grid-dropdown-filter>
                    </ng-container>
                    <ng-container *ngSwitchCase="GridQuickFilterEnum.COMBOBOX">
                        <ng-container *ngIf="quick.label">
                            <label>{{ quick.label }}</label>
                            <br>
                        </ng-container>
                        <grid-combobox-filter
                            [field]="quick.field"
                            [data]="quick.filterOptions"
                            [valueField]="quick.valueField"
                            [textField]="quick.textField"
                            [disabled]="quick.filterDisabled"
                            [matTooltip]="quick.filterTooltip"
                            [filter]="filter"
                            [defaultText]="quick.defaultText"
                            [filterSettings]="quick.dropDownFilterSettings"
                            (filterChanging)="quick.filterChanging.emit($event)"
                            [width]="quick.width"
                            [uniqueFilterClass]="quick.uniqueFilterClass">
                        </grid-combobox-filter>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="quick.template"
                [ngTemplateOutlet]="quick.template"
                [ngTemplateOutletContext]="{
                    filterValue: quickComponent.filterValue$ | async,
                    filterChange: quickComponent.filterChange,
                    title: title,
                    total: data?.total
                }">
            </ng-container>
        </portal-grid-quick-filter>
    </ng-template>


    <ng-template *ngIf="!toolbarHidden" kendoGridToolbarTemplate>

        <ng-container
            [ngTemplateOutlet]="quickSection"
            [ngTemplateOutletContext]="{
                filters: quickFiltersToolbar
            }">
        </ng-container>

        <span *ngIf="!hideToolbarSpacer" class="toolbar-spacer"></span>
        <div class="toolbar-actions" [class.toolbar-actions-no-spacer]="hideToolbarSpacer">
            <ng-container
                [ngTemplateOutlet]="toolbarTemplate">
            </ng-container>
            <div *ngIf="saveState">
                <button mat-icon-button [matMenuTriggerFor]="gridMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #gridMenu="matMenu">
                    <button mat-menu-item (click)="onSaveState()">
                        <mat-icon>save</mat-icon>
                        <span>Save state</span>
                    </button>
                    <button mat-menu-item (click)="onResetState()">
                        <mat-icon>refresh</mat-icon>
                        <span>Reset state</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </ng-template>

    <kendo-grid-checkbox-column
        *ngIf="selectableEnabled"
        [showSelectAll]="true"
        [width]="35">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem="dataItem">
            <input class="k-checkbox"
                type="checkbox"
                (change)="individualItemChange()"
                [kendoGridSelectionCheckbox]="rowIndex"
                [ngClass]="{ 'radio-style': !isSelectableMultipleMode }"
                *ngIf="selectableResolver(dataItem)"/>
        </ng-template>
        <ng-template kendoGridHeaderTemplate>
            <ng-container *ngIf="isSelectableMultipleMode">
            <input type="checkbox" [id]="'selectAllCheckbox_' + name"
                class="k-checkbox"
                [checked]="allRowsSelected"
                (change)="onSelectAllChange($event)" />
            </ng-container>
        </ng-template>
    </kendo-grid-checkbox-column>


    <kendo-grid-column *ngFor="let column of columns"
        [field]="column.sortField ? column.sortField : column.field"
        [title]="column.title"
        [sortable]="column.sortable"
        [hidden]="column.hidden"
        [width]="column.width"
        [class]="column.class"
        [style]="column.style"
        [headerStyle]="column.headerStyle"
        [headerClass]="column.headerClass"
        [format]="kendoColumnFormat[column.format]"
        [filter]="column.filterType">

        <!-- built-in cell templates -->
        <ng-container *ngIf="!column.template" [ngSwitch]="column.format">
            <ng-template *ngSwitchCase="GridColumnFormatEnum.DATE" kendoGridCellTemplate let-dataItem>
                {{dataItem[column.field] | date: 'shortDate'}}
            </ng-template>
            <ng-template *ngSwitchCase="GridColumnFormatEnum.TIME" kendoGridCellTemplate let-dataItem>
                {{dataItem[column.field] | date: 'hh:mm a'}}
            </ng-template>
            <ng-template *ngSwitchCase="GridColumnFormatEnum.CURRENCY" kendoGridCellTemplate let-dataItem>
                {{dataItem[column.field] | currency}}
            </ng-template>
            <ng-template *ngSwitchCase="GridColumnFormatEnum.BOOLEAN" kendoGridCellTemplate let-dataItem>
                {{dataItem[column.field] ? 'Yes' : 'No'}}
            </ng-template>
        </ng-container>

        <ng-template *ngIf="column.columnHeaderTemplate" kendoGridHeaderTemplate let-dataItem let-rowIndex>
            <ng-container
                [ngTemplateOutlet]="column.columnHeaderTemplate"
                [ngTemplateOutletContext]="{ dataItem: dataItem, rowIndex: rowIndex, column: column }"></ng-container>
        </ng-template>

        <!-- custom cell template -->
        <ng-template *ngIf="column.template" kendoGridCellTemplate let-dataItem let-rowIndex>
            <ng-container
                [ngTemplateOutlet]="column.template"
                [ngTemplateOutletContext]="{ dataItem: dataItem, rowIndex: rowIndex, column: column }">
            </ng-container>
        </ng-template>

        <!-- group template -->
        <ng-template *ngIf="column.groupTemplate" kendoGridGroupHeaderTemplate
            let-value="value" let-field="field"
            let-aggregates="aggregates" let-group="group">
            <ng-container
                [ngTemplateOutlet]="column.groupTemplate"
                [ngTemplateOutletContext]="{ value: value, field: field, aggregates: aggregates, group: group }">
            </ng-container>
        </ng-template>

        <!-- built-in filter templates -->
        <ng-container *ngIf="!column.filterMenuTemplate" [ngSwitch]="column.filterType">
            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.NONE"
                kendoGridFilterCellTemplate>
            </ng-template>
            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.TEXT"
                kendoGridFilterCellTemplate
                >
                <grid-text-filter
                    [field]="column.field"
                    [placeholder]="column.defaultText"
                    [filter]="filter"
                    [disabled]="column.filterDisabled"
                    [widthPercentage]="100"
                    [width]="column.width"
                    [uniqueFilterClass]="column.uniqueFilterClass"
                    [filterDebounceTime]="column.filterDebounceTime"
                    (filterChanging)="column.filterChanging.emit($event)">
                </grid-text-filter>
            </ng-template>

            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.CHECKBOX"
                kendoGridFilterMenuTemplate
                let-filterService="filterService">
                <grid-checkbox-filter
                    [field]="column.field"
                    [options]="column.filterOptions"
                    [filter]="filter"
                    [filterService]="filterService"
                    [filterStyle]="column.filterStyle">
                </grid-checkbox-filter>
            </ng-template>

            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.MULTISELECT"
                kendoGridFilterCellTemplate
                kendoGridFilterMenuTemplate>
                <grid-multiselect-filter
                    [field]="column.field"
                    [data]="column.filterOptions"
                    [valueField]="column.valueField"
                    [textField]="column.textField"
                    [disabled]="column.filterDisabled"
                    [filter]="filter"
                    [width]="column.width"
                    [defaultText]="column.defaultText"
                    [addMoreText]="column.addMoreText"
                    [uniqueFilterClass]="column.uniqueFilterClass"
                    [singleLine]="column.singleLine"
                    [filterSettings]="column.dropDownFilterSettings"
                    (filterChanging)="column.filterChanging.emit($event)">
                </grid-multiselect-filter>
            </ng-template>

            <ng-template *ngSwitchCase="GridQuickFilterEnum.GROUPMULTISELECT"
                kendoGridFilterCellTemplate>
                <grid-multifield-multiselect-filter
                    [field]="column.field"
                    [selectAll]="column.selectAll"
                    [width]="column.width"
                    [filter]="filter"
                    [textFilter]="column.textFilter"
                    (filterChanging)="column.filterChanging.emit($event)"
                    [defaultToAllSelected]="column.defaultToAllSelected"
                    [defaultText]="column.defaultText"
                    [required]="column.required"
                    [uniqueFilterClass]="column.uniqueFilterClass"
                    [data]="column.filterOptions">
                </grid-multifield-multiselect-filter>
            </ng-template>


            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.DROPDOWN"
                kendoGridFilterCellTemplate>
                <grid-dropdown-filter
                    [field]="column.field"
                    [data]="column.filterOptions"
                    [valueField]="column.valueField"
                    [textField]="column.textField"
                    [disabled]="column.filterDisabled"
                    [matTooltip]="column.filterTooltip"
                    [width]="column.width"
                    [filter]="filter"
                    [defaultText]="column.defaultText"
                    [uniqueFilterClass]="column.uniqueFilterClass"
                    [customDropdownListTemplate]="column.filterDropdownListTemplate ? column.filterDropdownListTemplate : undefined"
                    [customDropdownListItemTemplate]="column.filterDropdownValueTemplate ? column.filterDropdownValueTemplate : undefined"
                    (filterChanging)="column.filterChanging.emit($event)">
                </grid-dropdown-filter>
            </ng-template>

            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.COMBOBOX"
                kendoGridFilterCellTemplate>
                <grid-combobox-filter
                    [field]="column.field"
                    [data]="column.filterOptions"
                    [valueField]="column.valueField"
                    [textField]="column.textField"
                    [disabled]="column.filterDisabled"
                    [matTooltip]="column.filterTooltip"
                    [filter]="filter"
                    [defaultText]="column.defaultText"
                    [width]="column.width"
                    [uniqueFilterClass]="column.uniqueFilterClass"
                    [filterSettings]="column.dropDownFilterSettings"
                    (filterChanging)="column.filterChanging.emit($event)">
                </grid-combobox-filter>
            </ng-template>

            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.DATE"
                kendoGridFilterCellTemplate>
                <grid-date-filter
                    [field]="column.field"
                    [filter]="filter"
                    [disabledKeyboard]="column.disabledKeyboard"
                    [uniqueFilterClass]="column.uniqueFilterClass">
                </grid-date-filter>
            </ng-template>

            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.TIME"
                kendoGridFilterCellTemplate>
                <grid-time-filter
                    [field]="column.field"
                    [filter]="filter"
                    [uniqueFilterClass]="column.uniqueFilterClass">
                </grid-time-filter>
            </ng-template>

            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.IDENTIFIER"
                kendoGridFilterCellTemplate
                let-column="column">
                <kendo-grid-numeric-filter-cell
                    [column]="column"
                    [filter]="filter"
                    format="0"
                    [spinners]="false"
                    [uniqueFilterClass]="column.uniqueFilterClass">
                </kendo-grid-numeric-filter-cell>
            </ng-template>

            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.GREATERLESSEQUALS"
                kendoGridFilterCellTemplate>
                    <greater-than-less-than-equals-cell
                        [field]="column.field"
                        [filter]="filter"
                        [uniqueFilterClass]="column.uniqueFilterClass"
                    ></greater-than-less-than-equals-cell>
            </ng-template>
            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.CUSTOM"
                kendoGridFilterCellTemplate>
                <ng-container
                    [ngTemplateOutlet]="column.headerTemplate"
                    [ngTemplateOutletContext]="{ value: value }">
                </ng-container>
            </ng-template>

            <ng-template
                *ngSwitchCase="GridColumnFilterEnum.NUMERIC"
                kendoGridFilterCellTemplate
                let-column="column">
                <kendo-grid-numeric-filter-cell
                    [column]="column"
                    [filter]="filter"
                    format="0"
                    [min]="0"
                    [uniqueFilterClass]="column.uniqueFilterClass">
                </kendo-grid-numeric-filter-cell>
            </ng-template>

            <ng-container
                *ngSwitchCase="GridColumnFilterEnum.DATERANGE">

                <ng-template
                    kendoGridFilterCellTemplate
                    let-filterService="filterService">
                    <grid-date-range-filter-popup
                        [field]="column.field"
                        [filter]="filter"
                        [filterService]="grid.filterService"
                        [uniqueFilterClass]="column.uniqueFilterClass">
                    </grid-date-range-filter-popup>
                </ng-template>

                <ng-template
                    kendoGridFilterMenuTemplate
                    let-filterService="filterService">
                    <grid-date-range-filter
                        [field]="column.field"
                        [filter]="filter"
                        [filterService]="filterService">
                    </grid-date-range-filter>
                </ng-template>

            </ng-container>


        </ng-container>

        <!-- custom filter menu template -->
        <ng-template *ngIf="column.filterMenuTemplate" kendoGridFilterMenuTemplate let-filterService="filterService">
            <ng-container
                [ngTemplateOutlet]="column.filterMenuTemplate"
                [ngTemplateOutletContext]="{ column: column, filter: filter, filterService: filterService }">
            </ng-container>
        </ng-template>

        <!-- custom filter cell template -->
        <ng-template *ngIf="column.filterCellTemplate" kendoGridFilterCellTemplate >
            <ng-container
                [ngTemplateOutlet]="column.filterCellTemplate"
                [ngTemplateOutletContext]="{ column: column, filter: filter }">
            </ng-container>
        </ng-template>

    </kendo-grid-column>

    <kendo-grid-pdf fileName="documents.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
                <div class="header">
                    <ng-container *ngIf="headerTemplate && useHeaderInPdf && pageNum === 1"
                        [ngTemplateOutlet]="headerTemplate"
                        [ngTemplateOutletContext]="{ title: title, total: data?.total }">
                    </ng-container>
                    <div style="float: right">Page {{ pageNum }} of {{ totalPages }}</div>
                </div>
            <div class="footer">
                Page {{ pageNum }} of {{ totalPages }}
            </div>
            </div>
        </ng-template>
    </kendo-grid-pdf>

    <!-- detail view -->
    <ng-template *ngIf="needDetailView && detailViewTemplate" kendoGridDetailTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="detailViewTemplate"
            [ngTemplateOutletContext]="{ dataItem: dataItem, column: column }"></ng-container>
    </ng-template>

    <ng-template kendoGridNoRecordsTemplate *ngIf="customNoRecordsMessage">
        <div class="no-records" data-qa-id="NoRecordsMessage">
            <p>{{customNoRecordsMessage}}</p>
        </div>
    </ng-template>
</kendo-grid>
