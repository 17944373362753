import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textFormat'
})
export class TextFormatPipe implements PipeTransform {
    transform(value: string): string {
        return value.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    }
}
