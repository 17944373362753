import { createAction, props } from '@ngrx/store';
import { JobsCount } from '../../models';

export const loadJobsCount = createAction('[Dashboard Page] Load Jobs Count');

export const loadJobsCount2 = createAction('[Jobs Page] Load Jobs Count');

export const loadJobsCountSuccess = createAction('[Dashboard] Load Jobs Count Success', props<{ count: JobsCount }>());

export const loadJobsCountFail = createAction('[Dashboard] Load Jobs Count Fail', props<{ error: any }>());

export const clearJobsCount = createAction('[Dashboard] Clear Jobs Count');
