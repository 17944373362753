<ng-container *ngIf="activities$ | async as activities" terminology>
    <mat-card class="activity-card" data-qa-id="ac_client_smd_details_activities" *ngIf="activities.length > 0">
        <mat-card-title class="title">
            <span>Activity</span>
            <mat-icon *ngIf="isPendingOverdue$ | async"
                      matTooltip="{{'Submittal has been pending review for over 2 days' | terminology}}"
                      color="error"
                      class="mx-2">
                error
            </mat-icon>
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
            <table mat-table [dataSource]="activities" class="activity-table" aria-label="List of submittal activities">
                <ng-container matColumnDef="activityDate">
                    <th mat-header-cell *matHeaderCellDef scope="row"> Date </th>
                    <td mat-cell *matCellDef="let activity">
                        <div>{{ activity.activityDate | date : 'MM/dd/yyyy' }}</div>
                        <div>{{ activity.activityDate + 'Z' | date : 'HH:mm' }} {{localTimeZoneName}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="activityOwnerFullName">
                    <th mat-header-cell *matHeaderCellDef scope="row" class="large-cell"> Action Taken By </th>
                    <td mat-cell *matCellDef="let activity"> {{ activity.activityOwnerFullName }} </td>
                </ng-container>

                <ng-container matColumnDef="statusId">
                    <th mat-header-cell *matHeaderCellDef scope="row" class="large-cell"> Status </th>
                    <td mat-cell *matCellDef="let activity">
                        <div>{{ activity.status }} </div>
                        <div class="sub-status-display" *ngIf="activity.subStatus">{{ activity.subStatus }}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="notes">
                    <th mat-header-cell *matHeaderCellDef scope="row" class="large-cell"> Notes </th>
                    <ng-container *ifLdFeature="featureFlag.SetSendToClientEmailBodyAsStatusChangeNoteFromLiveList; enabled: false">
                        <td mat-cell *matCellDef="let activity"> {{ activity.notes }} </td>
                    </ng-container>
                    <ng-container *ifLdFeature="featureFlag.SetSendToClientEmailBodyAsStatusChangeNoteFromLiveList">
                        <td mat-cell *matCellDef="let activity" [innerHTML]="activity.notes || ''"> </td>
                    </ng-container>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="activitiesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: activitiesDisplayedColumns;"></tr>
            </table>
        </mat-card-content>
    </mat-card>
</ng-container>
