<ng-container *ngFor="let item of items; let last = last">
    <ng-container *ngIf="item.url; else current">
        <a (click)="navigate(item.url)" class="breadcrumb-link link-color">{{ item.label | terminology }}</a>
    </ng-container>

    <ng-template #current>
        <span class="breadcrumb-current">{{ item.label | terminology }}</span>
    </ng-template>

    <span class="divider" *ngIf="!last"></span>
</ng-container>

<ng-content></ng-content>

