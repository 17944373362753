<div [formGroup]="formGroup">
    <mat-form-field appearance="outline">
        <mat-chip-list #chipList [attr.aria-label]="chipTitle + ' selection'" [formControlName]="listControl">
            <mat-chip *ngFor="let chip of formGroup.get(listControl).value" (removed)="removeChip(chip)">
                {{chip.name}}
                <button matChipRemove>
                    <mat-icon>close</mat-icon>
                </button>
            </mat-chip>

            <input #chipsInput matInput [placeholder]="'Select ' + chipTitle + '...'" [formControlName]="inputControl" [matAutocomplete]="chipAuto" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addChip($event)" #chipTrigger="matAutocompleteTrigger"
                (click)="chipTrigger.openPanel()" (blur)="onChipInputBlur()">
        </mat-chip-list>
        <mat-autocomplete #chipAuto="matAutocomplete" (optionSelected)="addChip($event)">
            <mat-option *ngFor="let chip of filteredChips" [value]="chip" [ngClass]="{'no-click': chip.name.includes('No results match')}">
                {{chip.name}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="formGroup.get(listControl).hasError('required')">
            Please enter a {{chipTitle.toLowerCase()}}.
        </mat-error>
    </mat-form-field>
</div>
