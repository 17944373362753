<form [formGroup]="form">
    <mat-accordion>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: candidateProfileForm?.form?.invalid }"
            >
                <mat-panel-title>
                    Candidate Profile
                    <mat-icon
                        class="error-icon"
                        *ngIf="candidateProfileForm?.form?.invalid"
                        >error</mat-icon
                    >
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-candidate-profile-form
                    [vendors]="vendors$ | async"
                    [isAdminForm]="true"
                    [isNew]="isNew"
                    [vendorContacts]="vendorContacts"
                    [candidateId]="candidateId"
                    [additionalValidation]="additionalValidation"
                    [otherInfo]="otherCandidateInfo$ | async"
                    (onChangeMade)="onValueChanged()"
                    (onVendorChanged)="refreshVendorContacts($event)"
                    (getSsnValue)="getOtherInfo()"
                ></ayac-candidate-profile-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: phonesForm?.formArray?.invalid }"
            >
                <mat-panel-title>
                    Phones
                    <mat-icon
                        class="error-icon"
                        *ngIf="phonesForm?.formArray?.invalid"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-phones-form [candidateId]="candidateId" [additionalValidation]="additionalValidation"
                [phoneTypes$]="phoneTypes$" (onChangeMade)="onPhoneValueChanged()">
            </ayac-phones-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: expertiseForm?.form?.invalid }"
            >
                <mat-panel-title>
                    Specialty
                    <mat-icon
                        class="error-icon"
                        *ngIf="expertiseForm?.form?.invalid"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-expertise-form (onChangeMade)="onSpecialtyValueChanged()" [candidateId]="candidateId">
                </ayac-expertise-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: certificationsForm?.formArray?.invalid }"
            >
                <mat-panel-title>
                    Certifications
                    <mat-icon
                        class="error-icon"
                        *ngIf="certificationsForm?.formArray?.invalid"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-certifications-form
                (onChangeMade)="onCertificationsValueChanged()"
                [candidateId]="candidateId"
                [certifications]="certifications$ | async"
            ></ayac-certifications-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: backgroundHistoryForm?.formArray?.invalid }"
            >
                <mat-panel-title>
                    Background History
                    <mat-icon
                        class="error-icon"
                        *ngIf="backgroundHistoryForm?.formArray?.invalid"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-background-history-form
                [isAdminForm]="true"
                (onChangeMade)="onBackgroundValueChanged()"
                [candidateId]="candidateId"
            ></ayac-background-history-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: licensesForm?.formArray?.invalid }"
            >
                <mat-panel-title>
                    Licensure
                    <mat-icon
                        class="error-icon"
                        *ngIf="licensesForm?.formArray?.invalid"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-licenses-form
                [isAdminForm]="true"
                (onChangeMade)="onLicensureValueChanged()"
                [candidateId]="candidateId"
                [jobId]="jobId">
                </ayac-licenses-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel" *ngIf="!isNew">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Shifts App
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-candidate-shifts-app
                    [candidateId]="candidateId"
                    (onChangeMade)="onValueChanged()">
                </ayac-candidate-shifts-app>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</form>
