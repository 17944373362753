<loading-indicator *ngIf="isLoading$ | async"></loading-indicator>
<ayac-submittals-modal title="Rescind Offer"
                       loading="false"
                       actionButtonName="Rescind"
                       actionButtonColor="accent"
                       (actionButtonClick)="rescindOffer()"
                       [actionButtonDisabled]="!form.valid || noteItemIsNotValid"
                       [displayRedActionButton]="true">
    <form [formGroup]="form">
        <div class="warning-container">
                <div class="align-center">
                    <mat-icon>warning</mat-icon>
                </div>
                <div>
                    {{ 'This submittal will no longer be accessible after rescinding the offer. This action cannot be undone.' | terminology }}
                </div>
            </div>
        <div class="rescind-offer-spacer">Select a reason for rescinding this offer:</div>
        <div class="rescind-offer-container">
            <div>
                <strong>Reason <span class="k-required">*</span></strong>
            </div>
            <mat-form-field appearance="outline">
                <mat-select data-qa-id="rescind-offer-reason-select" formControlName="reason">
                    <mat-option *ngFor="let rescindReason of rescindReasons" [value]="rescindReason.id">
                        {{rescindReason.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="isOtherSelected">
                <strong data-qa-id="rescind-offer-note-text" id="rescind-offer-note-text">Notes <span class="k-required">*</span></strong>
                <ayac-text-area-with-counter
                                            data-qa-id="rescind-offer-notes-field"
                                            formControlName="note"
                                            placeholder="Note"
                                            [editor]="editor"
                                            [isVendorClientCommunicationEnabled]="isVendorClientCommunicationEnabled"
                                            (formItemValidationChanged)="onNoteItemValidationChanged($event)">
                </ayac-text-area-with-counter>
            </ng-container>
        </div>
    </form>
</ayac-submittals-modal>
