import { createAction, props } from '@ngrx/store';
import { AyaLeadApplicationInfo } from 'src/app/aya-leads/models/aya-lead-application-info.model';
import { AyaLeadResumeDocumentInfo } from 'src/app/aya-leads/models/aya-lead-resume-document-info.model';
import { AyaLeadsApplicationStatusDetails } from 'src/app/aya-leads/models/aya-leads-application-status-details.model';

export const rejectAyaLeadApplicantSuccess = createAction('[Aya Leads API] Reject Aya Lead Applicant Success');

export const rejectAyaLeadApplicantFailure = createAction(
    '[Aya Leads API] Reject Aya Lead Applicant Fail',
    props<{ error: any }>()
);

export const updateAyaLeadApplicationReviewStatusSuccess = createAction(
    '[Aya Leads API] Update Review Status for Aya Lead Application Success'
);
export const updateAyaLeadApplicationReviewStatusFail = createAction(
    '[Aya Leads API] Update Review Status for Aya Lead Application Fail',
    props<{ error: any }>()
);

export const loadAyaLeadApplicationInfoSuccess = createAction(
    '[Aya Leads API] Get Aya Lead Application Info Success',
    props<{ details: AyaLeadApplicationInfo; canUpdateStatus?: boolean }>()
);
export const loadAyaLeadApplicationInfoFail = createAction(
    '[Aya Leads API] Get Aya Lead Application Info Fail',
    props<{ error: any }>()
);

export const loadAyaLeadApplicationInfoFromApi = createAction(
    '[Aya Leads API] Get Aya Lead Application Info',
    props<{ id: number }>()
);

export const loadAyaLeadResumeInfoSuccess = createAction(
    '[Aya Leads API] Get Aya Lead Resume Info Success',
    props<{ result: AyaLeadResumeDocumentInfo[] }>()
);

export const loadAyaLeadResumeInfoFail = createAction(
    '[Aya Leads API] Get Aya Lead Resume Info Fail',
    props<{ error: any }>()
);

export const downloadResumeSuccess = createAction('[Aya Leads API] Download Aya Lead Resume Success');

export const downloadResumeFail = createAction(
    '[Aya Leads API] Download Aya Lead Resume Fail',
    props<{ error: any }>()
);

export const loadAyaLeadResumePreviewSuccess = createAction(
    '[Aya Leads API] Load Aya Lead Resume Preview Success',
    props<{ blobUrl: string }>()
);

export const loadAyaLeadResumePreviewFail = createAction(
    '[Aya Leads API] Load Aya Lead Resume Preview Fail',
    props<{ error: any }>()
);

export const loadAyaLeadResumePreviewNotFound = createAction('[Aya Leads API] Load Aya Lead Resume Preview Not Found');

export const recordAyaLeadsStatisticsSuccess = createAction('[Report Api] Record Aya Leads Statistics Success');

export const recordAyaLeadsStatisticsFailure = createAction(
    '[Report Api] Record Aya Leads Statistics Fail',
    props<{ error: any }>()
);

export const loadAyaLeadApplicationStatusesSuccess = createAction(
    '[Aya Leads API] Load Aya Lead Application Statuses Success',
    props<{ ayaLeadsApplicationStatuses: AyaLeadsApplicationStatusDetails[]; loadTimeMs: number }>()
);

export const loadAyaLeadApplicationStatusesFailure = createAction(
    '[Aya Leads API] Load Aya Lead Application Statuses Fail',
    props<{ error: any }>()
);
