<div *ngIf="showMenu && menu" class="sidebar">
    <div class="sidebar-filler"></div>
    <div class="sidebar-dropdown"><a href="#">Menu</a></div>
    <div class="sidebar-inner">
        <div id="MainMenu" class="navi">
            <div class="list-group panel navi" *ngFor="let menuItem of menu; let idx = index;">
                <button class="list-group-menu" (click)="toggleMenuOpen(idx)">
                    <em class="{{menuItem.icon}}"></em> {{menuItem.menuName}}
                </button>
                <div class="collapse navi" [ngClass]="{ 'in': menuItem | isActive : currentState }"
                    id="sidebar-{{idx}}">
                    <span *ngFor="let s of menuItem?.states" class="navi">

                        <a *ngIf="s.urlState" [href]="'#' + s.urlState" class="list-group-item navi"
                            [ngClass]="{'active': s | isSubMenuActive : currentState, 'subitem' : s.isSubItem }">
                            <ng-container *ngIf="s?.matIcon; then matIcon; else emIcon">
                            </ng-container>
                        </a>

                        <a *ngIf="!s.urlState" [href]="'#' + s.state.replace('.','/')" class="list-group-item navi"
                            [ngClass]="{'active': s | isSubMenuActive : currentState, 'subitem' : s.isSubItem }">
                            <ng-container *ngIf="s?.matIcon; then matIcon; else emIcon">
                            </ng-container>
                        </a>

                        <ng-template #emIcon>
                            <em class="{{s?.icon}} navi-icon"></em> {{s?.name}}
                        </ng-template>
                        <ng-template #matIcon>
                            <mat-icon [svgIcon]="s?.matIcon" class="mat-navi-icon"></mat-icon> {{s?.name}}
                        </ng-template>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <footer class="sidebar-absolute-footer" *ngIf="showMenu">
        <div *ngIf="nonVendorLogin">
            <p>&copy;&nbsp;{{ footerText }}</p>
            <a [href]="privacyUrl" rel="noreferrer noopener" target="_blank">Privacy Policy</a>
            &nbsp;<span class="link-color">|</span>&nbsp;
            <a [href]="terms" rel="noreferrer noopener" target="_blank">Terms of Use</a>
            <br/>
            <a *ngIf="showConsent" [href]="consent" rel="noreferrer noopener" target="_blank">Electronic Consent</a>
        </div>
    </footer>
</div>
