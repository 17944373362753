import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface MenuItem {
    label: string;
    url: string;
}

@Component({
    selector: 'ayac-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
    menuItems$: Observable<MenuItem[]>;
    @Input() items: MenuItem[] = [];
    @Output() navigateToRoute: EventEmitter<string> = new EventEmitter();
    static ROUTE_DATA_BREADCRUMB: string = 'title';

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.menuItems$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.createBreadcrumbs(this.activatedRoute.root))
        );
    }

    navigate(route: string) {
        const observers = this.navigateToRoute.observed;
        if (observers) {
            this.navigateToRoute.emit(route);
        } else {
            this.router.navigateByUrl(route);
        }
    }

    private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: MenuItem[] = []): MenuItem[] {
        const children: ActivatedRoute[] = route.children;

        for (const child of children) {
            const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
            if (routeURL !== '') {
                url += `/${routeURL}`;
            }

            const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
            if (label) {
                breadcrumbs.push({ label, url });
            }

            return this.createBreadcrumbs(child, url, breadcrumbs);
        }

        return breadcrumbs;
    }
}
