<ng-container [ngSwitch]="env">
    <!-- Example how to add branded loading indicator -->
    <!-- <div
        *ngSwitchCase="'AYA'"
        [ngClass]="className"
        class="loading-indicator loading-indicator-middle loading-indicator-aya">
    </div> -->
    <div
        *ngSwitchDefault
        [ngClass]="className"
        class="loading-indicator loading-indicator-middle loading-indicator-none">
        <mat-spinner
            diameter="60"
            color="accent"
            strokeWidth="6">
        </mat-spinner>
    </div>
</ng-container>