import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ClientGridSearch } from 'src/app/clinical/models/client-grid-search.model';
import { ClientUnitsGridSearch } from 'src/app/clinical/models/client-units-grid-search.model';
import { UnitOtherRequirement } from 'src/app/clinical/models/unit-other-requirement.model';
import { UnitStateControlledSubstances } from 'src/app/clinical/models/unit-state-controlled-substances';
import { interpolateParams } from 'src/app/core/utils';
import { renameKeys } from 'src/app/core/utils/rename-keys';
import { ExpertiseLookup } from 'src/app/jobs/models';
import { StateLookup } from 'src/app/jobs/models/lookups/state-lookup';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { SortTypes } from 'src/app/shared/models';
import { APP_CONFIG, Settings } from '../../config/settings';
import { combine } from '../../shared/utilities/route';
import { ClinicalClient } from '../models/client.model';
import { ClinicalManager } from '../models/clinical-manager.model';
import { LookupUnitItem } from '../models/lookup-unit-item-model';
import { NyuLookup } from '../models/nyu-lookup.model';
import { ClinicalTrackRequirements, UnitSkills } from 'src/app/clinical/models';
import { UnitBoardCertification } from '../models/unit-board-certification.model';
import { UnitFacilityInformation } from '../models/unit-facility-information.model';
import { UnitStateLicense } from '../models/unit-state-license.model';
import { Unit, UnitDescriptionEmail } from '../models/unit.model';
import { FeatureFlag, FeatureFlags } from 'src/app/shared/models/enums/feature-flag.enum';
import { UDOnlyLinkSecurityDto } from '../models/aya-link-security';
import { UnitDescriptionShareModalDto } from '../models/aya-ud-share-modal.model';

@Injectable({
    providedIn: 'root'
})
export class ClinicalService {
    private readonly coreApiPath: string;
    private readonly coreApiPathNova: string;
    private readonly coreApiUdSecurityPath: string;

    constructor(
        private readonly http: HttpClient,
        private readonly ldFeatureManager: LDFeatureManager,
        @Inject(APP_CONFIG) settings: Settings
    ) {
        this.coreApiPath = combine(settings.CORE, 'ayaconnect');
        this.coreApiPathNova = combine(settings.CORE, 'ayaNova');
        this.coreApiUdSecurityPath = combine(settings.CORE, 'udOnlyLinkSecurity');
    }

    getClient(id: number): Observable<ClinicalClient> {
        return this.http.get<ClinicalClient>(`${this.coreApiPath}/clinical/client/${id}`);
    }

    getClients(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<ClientGridSearch> {
        const argsSortObj = sortArgs;
        let matchArgsObj = {};

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        argsSortObj['sort.field'] = argsSortObj.sortField;
        argsSortObj['sort.type'] = argsSortObj.sortType === 'desc' ? 'descending' : 'asc';
        delete argsSortObj.sortField;
        delete argsSortObj.sortType;

        const params = interpolateParams({
            ...pagination,
            ...argsSortObj,
            ...matchArgsObj
        });

        return this.http.get<ClientGridSearch>(`${this.coreApiPath}/clinical/clinical-clients?${params}`);
    }

    getClinicalManagers(): Observable<ClinicalManager[]> {
        return this.http.get<ClinicalManager[]>(`${this.coreApiPath}/clinical/clinical-clients/clinical-managers`);
    }

    setClinicalManagers(managerId: number, facilityIds: number[]): Observable<number[]> {
        return this.http.post<number[]>(`${this.coreApiPath}/clinical/clinical-clients/clinical-managers`, {
            managerId,
            facilityIds
        });
    }

    removeClinicalManagers(facilityIds: number[]): Observable<any> {
        return this.http.post<void>(`${this.coreApiPath}/clinical/clinical-clients/remove-clinical-managers`, {
            facilityIds
        });
    }

    getClientUnits(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<ClientUnitsGridSearch> {
        const argsSortObj = sortArgs;
        let matchArgsObj = {};

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        argsSortObj['sort.field'] = argsSortObj['sortField'];
        argsSortObj['sort.type'] = argsSortObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete argsSortObj.sortField;
        delete argsSortObj.sortType;

        const params = interpolateParams({
            ...pagination,
            ...argsSortObj,
            ...matchArgsObj
        });

        return this.http.get<ClientUnitsGridSearch>(`${this.coreApiPath}/clinical/client/client-units?${params}`);
    }

    updateUnitStatus(unitId: any, status: any): Observable<any> {
        const url = `${this.coreApiPath}/clinical/client/${unitId}/update-unit-status`;

        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        return this.http.put(url, JSON.stringify(status), httpOptions);
    }

    deleteUnit(unitId: any): Observable<any> {
        const url = `${this.coreApiPath}/clinical/client/${unitId}/delete-unit`;
        return this.http.delete(url);
    }

    getUnitSkills(specialtyProfessionId: number, unitId: number | null): Observable<UnitSkills[]> {
        const url = `${this.coreApiPathNova}/UnitDescription/skills/${specialtyProfessionId}/${unitId ?? 0}`;
        return this.http.get<UnitSkills[]>(url);
    }

    getClinicalTrackRequirements(clinicalTrackId: number): Observable<ClinicalTrackRequirements> {
        const url = `${this.coreApiPathNova}/UnitDescription/clinical-track-requirements/${clinicalTrackId}`;
        return this.http.get<ClinicalTrackRequirements>(url);
    }

    getUnitBoardCertifications(unitId: string): Observable<UnitBoardCertification[]> {
        unitId = unitId || '';
        const url = `${this.coreApiPathNova}/UnitDescription/${unitId}/unit-board-certifications`;
        return this.http.get<UnitBoardCertification[]>(url);
    }

    getUnitStateLicenses(unitId: string): Observable<UnitStateLicense[]> {
        unitId = unitId || '';
        const url = `${this.coreApiPathNova}/UnitDescription/${unitId}/unit-state-licenses`;
        return this.http.get<UnitStateLicense[]>(url);
    }

    getUnitStateControlledSubstances(unitId: string): Observable<UnitStateControlledSubstances[]> {
        unitId = unitId || '';
        const url = `${this.coreApiPathNova}/UnitDescription/${unitId}/unit-state-controlled-substances`;
        return this.http.get<UnitStateControlledSubstances[]>(url);
    }

    getStates(): Observable<StateLookup[]> {
        const url = `${this.coreApiPathNova}/UnitDescription/GetCachedStates`;
        return this.http.get<StateLookup[]>(url);
    }

    getUnitInfo(unitId: string, hospId: string, token: string, formId: string): Observable<UnitFacilityInformation> {
        unitId = unitId || '';
        hospId = hospId || '';
        token = token || '';
        formId = formId || '';
        let headers = new HttpHeaders();
        headers = headers.append('skip', 'true');
        let url: string;
        this.ldFeatureManager
            .isEnabled(FeatureFlag.UseAyaConnectToCallUnitDescriptionApis)
            .subscribe((flagIsEnabled) => {
                if (flagIsEnabled) {
                    url = `${this.coreApiPath}/compliance/UnitDescription?unitId=${unitId}&hospId=${hospId}`;
                } else {
                    if (this.ldFeatureManager.isEnabled(FeatureFlags.AccessSecureUDLinks)) {
                        headers = this.createUnitDescriptionCallHeaders(headers);
                        url = `${this.coreApiPathNova}/UnitDescription/get?unitId=${unitId}&hospId=${hospId}&formId=${formId}&token=${token}`;
                    } else {
                        url = `${this.coreApiPathNova}/UnitDescription/get?unitId=${unitId}&hospId=${hospId}`;
                    }
                }
            });

        return this.http.get<UnitFacilityInformation>(url, { headers });
    }

    getNyuFloors(buildingId: number): Observable<NyuLookup[]> {
        const url = `${this.coreApiPath}/client/integration/nyu/buildings/${buildingId}/floors`;
        return this.http.get<NyuLookup[]>(url);
    }

    getNyuDepartments(): Observable<NyuLookup[]> {
        const url = `${this.coreApiPath}/client/integration/nyu/departments`;
        return this.http.get<NyuLookup[]>(url);
    }

    getNyuBuildings(): Observable<NyuLookup[]> {
        const url = `${this.coreApiPath}/client/integration/nyu/buildings`;
        return this.http.get<NyuLookup[]>(url);
    }

    getPhysicianSpecialties(): Observable<ExpertiseLookup[]> {
        const url = `${this.coreApiPath}/vendors/submittals/59/profession-expertises`;
        return this.http.get<ExpertiseLookup[]>(url);
    }

    getOtherRequirements(): Observable<LookupUnitItem[]> {
        const url = `${this.coreApiPathNova}/unitDescription/GetOtherRequirements`;
        return this.http.get<LookupUnitItem[]>(url);
    }

    getUnitOtherRequirements(unitId: string): Observable<UnitOtherRequirement[]> {
        unitId = unitId || '';
        const url = `${this.coreApiPathNova}/UnitDescription/${unitId}/unit-other-requirements`;
        return this.http.get<UnitOtherRequirement[]>(url);
    }

    saveUnit(unit: Unit): Observable<Unit> {
        const params = new HttpParams().set('token', unit.token).set('formId', unit.formId);
        const form = { url: unit.url, form: unit };
        let headers = new HttpHeaders();
        headers = this.createUnitDescriptionCallHeaders(headers);
        return this.ldFeatureManager.isEnabled(FeatureFlags.AccessSecureUDLinks).pipe(
            switchMap((isEnabled) => {
                if (isEnabled) {
                    return this.http.post<Unit>(`${this.coreApiPathNova}/unitDescription/create-unit`, form, {
                        params,
                        headers
                    });
                } else {
                    return this.http.post<Unit>(`${this.coreApiPathNova}/unitDescription/create-unit`, form, {
                        params
                    });
                }
            })
        );
    }
    public createUnitDescriptionCallHeaders(headers: HttpHeaders): HttpHeaders {
        const novaAccessToken = localStorage.getItem('nova_access_token');
        const connectAccessTicket = localStorage.getItem('access_ticket');

        if (novaAccessToken) {
            headers = headers.set('nova_access_token', novaAccessToken);
        }

        if (connectAccessTicket) {
            headers = headers.set('connect_access_ticket', connectAccessTicket);
        }

        return headers;
    }
    saveUnitExtraFields(unitId: number, departmentId: number, buildingId: number, floorId: number): Observable<any> {
        if (!unitId) {
            return of(false);
        }

        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };

        return this.http.put(
            `${this.coreApiPath}/client/integration/nyu/units/${unitId}`,
            JSON.stringify({
                unitId: unitId,
                departmentId: departmentId,
                buildingId: buildingId,
                floorId: floorId
            }),
            httpOptions
        );
    }

    sendExpirationNotification(request: UnitDescriptionEmail): Observable<void> {
        return this.http.post<void>(`${this.coreApiPathNova}/unitDescription/expiration-notification`, request);
    }

    saveUDOnlyLinkSecurityToken(udOnlyLinkSecurityData: UDOnlyLinkSecurityDto): Observable<string> {
        const url = `${this.coreApiUdSecurityPath}/token`;
        let headers = new HttpHeaders();
        headers = this.createUnitDescriptionCallHeaders(headers);
        return this.http.post<string>(url, udOnlyLinkSecurityData, { responseType: 'text' as 'json', headers });
    }

    getUnitDescriptionShareModalProperties(formId: string): Observable<UnitDescriptionShareModalDto> {
        const url = `${this.coreApiUdSecurityPath}/unit-descriptions/${encodeURIComponent(formId)}/share-modal-properties`;
        return this.http.get<UnitDescriptionShareModalDto>(url);
    }
}
