import { DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { PermJobsState, permJobsStoreKey } from '../perm-jobs.state';
import { PermJobsGridQueryState } from '../reducers/perm-jobs-grid-query.reducer';
import { LoadingTypes } from '../../../shared/models';
import { selectPermJobsLookupFacilities, selectPermJobsLookupUnits } from './perm-jobs-lookups.selectors';
import { GridLookup, GridSearchQuery } from 'src/app/shared/grid/models';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { searchFacilityIdFilterName } from 'src/app/shared/kendo/kendo-filter.constants';
import { UnitLookupConcise } from 'src/app/shared/models/lookups';
import { KendoFindFilter } from 'src/app/shared/kendo/kendo-find-filter.model';
import { filterUnitsByFacility } from 'src/app/shared/utilities/filter-units-by-facility';

export const selectFeature = createFeatureSelector<PermJobsState>(permJobsStoreKey);

export const selectResults = createSelector(selectFeature, (state) => state.results);

export const selectPermJobsData = createSelector(selectResults, (state) => ({
    data: state.permJobs,
    total: state.permJobsTotal
}));

export const selectLoadingState = createSelector(selectResults, (state) => ({
    loadingState: state.loading
}));

export const selectIsLoading = createSelector(
    selectLoadingState,
    (state) => state.loadingState === LoadingTypes.LOADING
);

export const selectPermJobsQuery = createSelector(
    selectFeature,
    (state: PermJobsState): PermJobsGridQueryState => state.query
);

export const selectPermJobsFacilityFilterValue: MemoizedSelector<
    object,
    number | null,
    DefaultProjectorFn<number | null>
> = createSelector(
    selectPermJobsLookupFacilities,
    selectPermJobsQuery,
    (_: GridLookup[], query: GridSearchQuery): number | null => {
        if (!query?.filter?.filters) {
            return null;
        }

        const filters = query.filter.filters as FilterDescriptor[];
        const filter = filters.find((f) => f.field === searchFacilityIdFilterName);
        return filter ? filter.value : null;
    }
);

export const selectIsFacilitySelected = createSelector(
    selectPermJobsFacilityFilterValue,
    (filterValue: number | null): boolean => !!filterValue
);

export const selectFacilityFilterDefaultText = createSelector(
    selectPermJobsLookupFacilities,
    selectIsFacilitySelected,
    (facilities: GridLookup[], facilityIsSelected: boolean): string => {
        return facilities && facilities.length === 1 && facilityIsSelected ? facilities[0].name : 'All facilities';
    }
);

export const selectFilteredUnits = createSelector(
    selectPermJobsQuery,
    selectPermJobsLookupUnits,
    (query: GridSearchQuery, units: UnitLookupConcise[]): UnitLookupConcise[] => {
        if (!query?.filter?.filters) {
            return [];
        }

        const filters: Array<FilterDescriptor | CompositeFilterDescriptor> = query.filter.filters;

        const facilityFilter: KendoFindFilter = filters.find(
            (filterDescriptor) => (filterDescriptor as FilterDescriptor).field === searchFacilityIdFilterName
        );

        if (!facilityFilter) {
            return [];
        }

        const filteredUnitsByFacility: UnitLookupConcise[] = filterUnitsByFacility(
            facilityFilter as FilterDescriptor,
            units
        );

        return filteredUnitsByFacility;
    }
);

export const selectUnitFilterTooltip = createSelector(
    selectIsFacilitySelected,
    (facilityIsSelected: boolean): string => (facilityIsSelected ? null : 'Please select a facility first')
);
