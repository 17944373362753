import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError, tap, exhaustMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubmittalsInternalService } from 'src/app/submittals/services/submittals-internal.service';
import * as submittalActions from '../actions';
import { SubmittalsBaseEffect } from './submittals-base.effect';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { IrpSubmittalUpdateStatusRequest } from 'src/app/shared/models/submittals/requests/irp-submittal-update-status.request';

@Injectable()
export class SubmittalsDetailsInternalEffect extends SubmittalsBaseEffect {
    getIRPSubmittalDetails$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadIRPSubmittalDetails),
            map((action) => action.id),
            exhaustMap((id: number) =>
                this.submittalsInternalService.getIRPSubmittalDetails(id).pipe(
                    map((res) => submittalActions.loadIRPSubmittalDetailsSuccess({ profile: res })),
                    catchError((error) =>
                        of(
                            submittalActions.loadIRPSubmittalDetailsFail({
                                error: error.length ? error[0] : 'Failed to load submittal details.'
                            })
                        )
                    )
                )
            )
        )
    );

    getIRPSubmittalDetailsSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadIRPSubmittalDetailsSuccess),
                tap((res) => {
                    this.logData('Submittal details loaded.');
                })
            ),
        { dispatch: false }
    );

    getIRPSubmittalDetailsFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadIRPSubmittalDetailsFail),
                map((action) => action.error),
                tap((error) => {
                    this.handleError(error);
                })
            ),
        { dispatch: false }
    );

    getIRPSubmittalJobs$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadIRPSubmittalJobs),
            map((action) => action.id),
            exhaustMap((id: number) =>
                this.submittalsInternalService.getIRPSubmittalJobs(id).pipe(
                    map((res) => submittalActions.loadIRPSubmittalJobsSuccess({ jobs: res })),
                    catchError((error) => of(submittalActions.loadIRPSubmittalJobsFail({ error })))
                )
            )
        )
    );

    getIRPSubmittalJobsSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadIRPSubmittalJobsSuccess),
                tap((res) => {
                    this.logData('Submittal jobs loaded.');
                })
            ),
        { dispatch: false }
    );

    getIRPSubmittalJobsFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadIRPSubmittalJobsFail),
                map((action) => action.error),
                tap((error) => {
                    this.handleError(error);
                })
            ),
        { dispatch: false }
    );

    updateSubmittalStatus$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.updateIRPSubmittalStatus),
            switchMap((action) => {
                const request: IrpSubmittalUpdateStatusRequest = {
                    submittalId: action.submittalId,
                    statusId: action.statusId,
                    note: action.note,
                    declineReasonId: action.declineReasonId
                };

                return this.submittalsInternalService.updateIRPSubmittalStatus(request).pipe(
                    mergeMap((res) => [submittalActions.updateIRPSubmittalStatusSuccess({ profile: res })]),
                    catchError((error) => of(submittalActions.updateIRPSubmittalStatusFail({ error })))
                );
            })
        )
    );

    updateSubmittalStatusSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.updateIRPSubmittalStatusSuccess),
                tap((res) => {
                    this.toasterService.success('Candidate status successfully updated.');
                    this.logData('Submittal status successfully updated.');
                })
            ),
        { dispatch: false }
    );

    updateSubmittalStatusFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.updateIRPSubmittalStatusFail),
                tap((action) => this.handleError(action.error))
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions: Actions,
        private readonly submittalsInternalService: SubmittalsInternalService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
