<div class="multi-select-calendar-header">
    <button mat-icon-button (click)="previousClicked('month')" [disabled]="prevDisabled">
     <mat-icon>keyboard_arrow_left</mat-icon>
   </button>

   <span class="multi-select-calendar-header-label">{{periodLabel}}</span>
   
   <button mat-icon-button (click)="nextClicked('month')">
     <mat-icon>keyboard_arrow_right</mat-icon>
   </button>
 </div>