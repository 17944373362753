<ng-container *ngIf="document$ | async">
    <ngx-extended-pdf-viewer #pdfViewer
                             [showToolbar]="showToolbar"
                             [sidebarVisible]="sidebarVisible"
                             [showSidebarButton]="showSidebarButton"
                             [src]="document$ | async"
                             [showUnverifiedSignatures]="true"
                             [useBrowserLocale]="true"
                             [(zoom)]="zoom">
    </ngx-extended-pdf-viewer>
</ng-container>

<ng-container *ngIf="(document$ | async) === null">
    <div class="preview-unavailable" data-qa-id="doc-preview-prev-unavailable-container">
        <div class="preview-unavailable-text" data-qa-id="doc-preview-prev-unavailable-text">
            Preview Unavailable
        </div>
    </div>
</ng-container>

<!-- <ng-template #toolbarTemplate>
    <div id="toolbarViewer" *ngIf="showToolbar">
        <div id="toolbarViewerLeft" class="toolbar-view">
            <pdf-toggle-sidebar *ngIf="showSidebarButton"></pdf-toggle-sidebar>
            <span class="material-icons" onclick="window.onRotatePage()">
                rotate_right
            </span>
        </div>
        <pdf-zoom-toolbar></pdf-zoom-toolbar>
    </div>
</ng-template> -->

<!-- <ng-template #checkboxThumbnail>
    <a class="pdf-viewer-template">
        <div class="thumbnail nova__link" data-qa-id="thumbnailView" data-page-number="PAGE_NUMBER"
            style="border: none; margin-bottom: 35px;">
            <input *ngIf="allowPageSelection" id="checkbox_PAGE_NUMBER" type="checkbox" class="thumbnail-checkbox"
                onclick="window.updateThumbnailSelection(PAGE_NUMBER)">Page PAGE_NUMBER
            <div class="thumbnailSelectionRing image-container"
                style="width: var(--thumbnail-width); height: var(--thumbnail-height);">
                <div class="thumbnail-text"
                    style="width: WIDTH_OF_RING; height: HEIGHT_OF_RING;margin-bottom: -HEIGHT_OF_RING">
                </div>
                <img class="thumbnailImage" alt="PDF Thumbnail" />
            </div>
        </div>
    </a>
</ng-template> -->
