import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities';
import { CreateTravelerExtension, TravelerExtensionModel } from 'src/app/travelers/extension/traveler-extension.model';
import { TravelerPostponeExtensionResult } from 'src/app/shared/models/travelers/traveler-postpone-extension-result.model';
import { OriginalJob } from 'src/app/jobs/models/original-job.model';
import { JobExtension, ExtensionOfferFacilitySettings } from 'src/app/travelers/models';

@Injectable({
    providedIn: 'root'
})
export class TravelersExtensionService {
    private readonly imagesPath: string;
    private readonly extensionsLandingPath: string;
    private readonly extensionsPath: string;
    private readonly extensionOfferPath: string;

    constructor(
        @Inject(APP_CONFIG) private readonly settings: Settings,
        @Inject(LOCALE_ID) private readonly locale: string,
        private readonly http: HttpClient
    ) {
        this.imagesPath = combine(settings.CORE, 'images', 'job-email-templates');
        this.extensionsLandingPath = combine(settings.CORE, 'ExtensionLanding');
        this.extensionsPath = combine(settings.CORE, 'AyaConnect', 'Client', 'Traveler', 'Extension');
        this.extensionOfferPath = combine(settings.CORE, 'AyaConnect', 'Client', 'Traveler', 'ExtensionOffer');
    }

    getLogo(fileName: string) {
        const url = `${this.imagesPath}/${fileName}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    getExtensionDetails(travelerId: number): Observable<TravelerExtensionModel> {
        const url = `${this.extensionsLandingPath}/${travelerId}`;
        return this.http.get<TravelerExtensionModel>(url);
    }

    postponeExtensionReminder(travelerId: number, email: string) {
        const url = `${this.extensionsPath}/${travelerId}/postpone`;
        const body = {
            email
        };
        return this.http.post<TravelerPostponeExtensionResult>(url, body);
    }

    completeRequest(travelerId: number, token: string) {
        const url = `${this.extensionsPath}/${travelerId}/complete`;
        const body = {
            token
        };
        return this.http.post(url, body);
    }

    initRequest(travelerId: number) {
        const url = `${this.extensionsPath}/${travelerId}/init`;
        return this.http.post(url, {});
    }

    requestExtensionToAM(travelerId: number) {
        const url = `${this.extensionsPath}/${travelerId}/request-am`;
        return this.http.post(url, {});
    }

    createExtension(
        travelerId: number,
        durationWeeks: number,
        startDate: Date,
        endDate: Date,
        jobTitle: string,
        billingRate: number,
        additionalExpectations: string,
        extensionDetails: string
    ): Observable<JobExtension> {
        const url = `${this.extensionsPath}/${travelerId}/request`;
        const params = {
            travelerId,
            durationWeeks,
            startDate: startDate.toLocaleString(this.locale),
            endDate: endDate.toLocaleString(this.locale),
            jobTitle,
            billingRate,
            additionalExpectations,
            extensionDetails
        };
        return this.http.post<JobExtension>(url, params);
    }

    createVMSExtension(extension: CreateTravelerExtension): Observable<JobExtension> {
        const url = `${this.extensionsPath}/${extension.travelerId}/request`;
        const params = {
            travelerId: extension.travelerId,
            durationWeeks: extension.duration,
            startDate: extension.startDate.toLocaleString(this.locale),
            endDate: extension.endDate.toLocaleString(this.locale),
            jobTitle: extension.jobTitle,
            billingRate: extension.billingRate,
            additionalExpectations: extension.additionalExpectations,
            extensionDetails: extension.extensionDetails,
            isVMS: true
        };
        return this.http.post<JobExtension>(url, params);
    }

    getOriginalJob(jobId: number): Observable<OriginalJob> {
        const url = `${this.extensionsPath}/${jobId}/job`;
        return this.http.get<OriginalJob>(url);
    }

    getExtensionOfferFacilitySettings(contractId: number): Observable<ExtensionOfferFacilitySettings> {
        const url = `${this.extensionOfferPath}/${contractId}/facility-settings`;
        return this.http.get<ExtensionOfferFacilitySettings>(url);
    }
}
