import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubmittalsService } from 'src/app/submittals/services/submittals.service';
import {
    downloadSubmittalsApplication,
    downloadSubmittalsApplicationSuccess,
    downloadSubmittalsApplicationFail
} from 'src/app/submittals/store/submittals.actions';
import { SubmittalsBaseEffect } from './submittals-base.effect';
import { ToasterService } from 'src/app/core/services/toaster.service';

@Injectable()
export class SubmittalsApplicationEffect extends SubmittalsBaseEffect {
    downloadApplication$ = createEffect(() =>
        this.actions.pipe(
            ofType(downloadSubmittalsApplication),
            mergeMap((action) =>
                this.submittalsService.downloadFile(action.submittalId).pipe(
                    map((response) =>
                        downloadSubmittalsApplicationSuccess({ submittalId: action.submittalId, payload: response })
                    ),
                    catchError((error) =>
                        of(downloadSubmittalsApplicationFail({ submittalId: action.submittalId, error: error }))
                    )
                )
            )
        )
    );

    downloadApplicationSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(downloadSubmittalsApplicationSuccess),
                map((action) => {
                    this.saveFile(action.payload);
                })
            ),
        { dispatch: false }
    );

    downloadApplicationFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(downloadSubmittalsApplicationFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions: Actions,
        private readonly submittalsService: SubmittalsService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
