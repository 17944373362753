import { NgModule } from '@angular/core';
import { FormLineComponent } from './form-line/form-line.component';
import { MaterialModule } from '../material/material.module';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from './widget/widget.component';

const COMPONENTS = [FormLineComponent, WidgetComponent];

@NgModule({
    declarations: [...COMPONENTS],
    providers: [],
    imports: [CommonModule, MaterialModule],
    exports: [...COMPONENTS]
})
export class CustomModule {}
