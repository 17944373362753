import { InvalidContactChange } from '../../models/invalid-contact-change.model';
import { businessEmailRequired } from './business-email.validator';
import { VendorContactChange } from '../vendor-contacts-change.model';

export function setInvalidChanges(
    changedContact: VendorContactChange,
    invalidChange: InvalidContactChange
): InvalidContactChange {
    if (changedContact.changeType === 'profile') {
        invalidChange.profile = changedContact.isInvalidChange;
    } else if (changedContact.changeType === 'phone') {
        invalidChange.phone = changedContact.isInvalidChange;
    } else {
        invalidChange.email = changedContact.isInvalidChange;
    }

    invalidChange.businessEmail = businessEmailRequired(changedContact.vendorContact) !== null;

    return invalidChange;
}
