import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import {
    loadPendingSubmittals,
    loadPendingSubmittalsFail,
    loadPendingSubmittalsSuccess
} from '../actions/pending-submittals.actions';

export interface PendingSubmittalsState {
    total: number;
    agingCount: number;
    agingDays: number;
    loading: LoadingTypes;
    error: string;
}

export const initialPendingSubmittalsState: PendingSubmittalsState = {
    total: 0,
    agingCount: 0,
    agingDays: 2,
    loading: LoadingTypes.INIT,
    error: ''
};

export function pendingSubmittalsReducer(state: PendingSubmittalsState, action: Action) {
    return createReducer(
        initialPendingSubmittalsState,
        on(loadPendingSubmittals, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(loadPendingSubmittalsSuccess, (state, action) => ({
            ...state,
            total: action.total,
            agingCount: action.agingCount,
            loading: LoadingTypes.LOADED
        })),

        on(loadPendingSubmittalsFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: PendingSubmittalsState | undefined, action: Action) {
    return pendingSubmittalsReducer(state, action);
}
