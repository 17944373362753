import { HttpParams } from '@angular/common/http';
import { SortTypes } from 'src/app/shared/models';

export interface PaginationParameters {
    pageSize: number;
    skip: number;
}

export interface SortParameters {
    sortField?: string;
    sortType?: SortTypes;
}

export interface FilterParameters {
    [key: string]: string;
}

export function interpolateParams(params: object = {}): HttpParams {
    if (params instanceof HttpParams) {
        return params;
    }

    function formatValue(val) {
        switch (true) {
            case typeof val === 'number':
            case typeof val === 'string': {
                return String(val);
            }
            default:
                return JSON.stringify(val);
        }
    }

    return Object.keys(params)
        .filter((key) => params[key] != null)
        .reduce((p, key) => {
            const val = params[key];
            if (Array.isArray(val)) {
                return val.filter((k) => k != null).reduce((_p, value) => _p.append(key, formatValue(value)), p);
            }

            return p.set(key, formatValue(val));
        }, new HttpParams());
}
