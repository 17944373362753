<dialog-layout title="{{title}}">
    <div mat-dialog-content>
        <div class="modal-content-container">
            <ng-content></ng-content>
        </div>
    </div>
    <hr>
    <div mat-dialog-actions>
        <span *ngIf="loading" class="loading footer">{{loadingText}}</span>
        <button mat-flat-button
                color="{{actionButtonColor}}"
                [ngClass]="{'red-action-button': displayRedActionButton}"
                data-qa-id="ac_client_smdc_decline_button"
                (click)="actionButtonClick.emit($event)"
                [disabled]="actionButtonDisabled">
            {{actionButtonName}}
        </button>
        <button mat-stroked-button
                data-qa-id="ac_client_smdc_canel_button"
                mat-dialog-close cdkFocusInitial>
            Cancel
        </button>
    </div>
</dialog-layout>
