import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayEnumStringValue',
    standalone: true
})
export class EnumStringValuePipe implements PipeTransform {
    transform(value: number, enumType: unknown): string {
        const enumString = enumType[value] as unknown as string;
        if (!enumString) {
            return enumString;
        }

        return enumString
            .replace(/_/g, '-')
            .split(/(?<!-)(?=[A-Z])/)
            .join(' ')
            .replace(/,/g, ' ');
    }
}
