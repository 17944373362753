<dialog-layout [title]="title">
    <div id="dialogContent" mat-dialog-content>
        <span>Edit the requested time off for this submittal.</span> <br />
        <span>Note: Any changes will not change the candidates RTO on their profile.</span>

        <form [formGroup]="form" class="form">
            <div class="available-dates-field datepicker-field">
                <label>Available Date</label>
                <div class="datepicker-container">
                    <datepicker-multiple
                        #availableDatepicker
                        formControlName="availableStartDate"
                        [title]="'Select Available Dates'"
                        [min]="minDate"
                        [multi]="false"
                        >
                    </datepicker-multiple>
                </div>
            </div>

            <div class="preferred-shift-field">
                <label>Preferred Shift</label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="preferredShifts" data-qa-id="submittalRtoDialogPreferredShifts" multiple>
                        <mat-option *ngFor="let shift of shiftTypes$ | async" [value]="shift.id">
                            {{ shift.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-radio-group formControlName="rtoNeeded" class="rto-needed-radio-group">
                <mat-radio-button [value]="true">YES, RTO NEEDED</mat-radio-button>
                <mat-radio-button [value]="false">NO, RTO NOT NEEDED</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="form.get('rtoNeeded').value" class="rto-datepicker-field datepicker-field">
                <label>RTO Dates</label>
                <div class="datepicker-container">
                    <datepicker-multiple
                        #rtoDatepicker
                        formControlName="requestTimeOffDates"
                        [title]="'Select RTO dates'"
                        [format]="requestTimeOffDatesFormat"
                        [min]="minDate"
                        [validateField]="true"
                        >
                    </datepicker-multiple>
                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions>
        <button mat-flat-button color="accent" (click)="saveRto()">Save</button>
        <button mat-stroked-button color="primary" [mat-dialog-close]="false">Cancel</button>
    </div>
</dialog-layout>
