import { FilterDescriptor } from '@progress/kendo-data-query';

export function mapFilters(filters, mapping) {
    if (!filters) {
        return filters;
    }

    return filters.map((fd) => {
        if (fd.field && mapping[fd.field] !== undefined) {
            const f = fd as FilterDescriptor;
            return { ...f, field: mapping[fd.field] };
        } else {
            return fd;
        }
    });
}
