import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    CopyReleaseSettingsFromModalState,
    getInitialCopyReleaseSettingsFromModalState
} from 'src/app/facilities/store/state/copy-release-settings-from-modal.state';
import * as actions from 'src/app/facilities/store/actions/copy-release-settings-from-modal.actions';

export function copyReleaseSettingsFromModalReducer(state: CopyReleaseSettingsFromModalState, action: Action) {
    return createReducer(
        getInitialCopyReleaseSettingsFromModalState(),

        on(actions.loadCopyFromFacilities, (state) => ({
            ...state,
            facilities: [],
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.loadCopyFromFacilitiesSuccess, (state, action) => ({
            ...state,
            facilities: action.facilities,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.loadCopyFromFacilitiesFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL }))
    )(state, action);
}
