import { createAction, props } from '@ngrx/store';
import { SubmittalJob } from 'src/app/shared/models/submittals/submittal-job.model';
import { ShiftSumitalStatuses } from 'src/app/shifts-submittals/models';
import { CandidateJobSubmittalProfile } from '../../models';

export const loadIRPSubmittalDetails = createAction('[Submittals] Get Internal Details', props<{ id: number }>());

export const loadIRPSubmittalDetailsSuccess = createAction(
    '[Submittals] Get Internal Details Success',
    props<{ profile: CandidateJobSubmittalProfile }>()
);

export const loadIRPSubmittalDetailsFail = createAction(
    '[Submittals] Get Internal Details Fail',
    props<{ error: any }>()
);

export const loadIRPSubmittalJobs = createAction('[Submittals] Get Internal Submittal Jobs', props<{ id: number }>());

export const loadIRPSubmittalJobsSuccess = createAction(
    '[Submittals] Get Internal Submittal Jobs Success',
    props<{ jobs: Array<SubmittalJob> }>()
);

export const loadIRPSubmittalJobsFail = createAction(
    '[Submittals] Get Internal Submittal Jobs Fail',
    props<{ error: any }>()
);

export const updateIRPSubmittalStatus = createAction(
    '[Submittals] Update Internal Submittal Status',
    props<{
        submittalId: number;
        statusId: ShiftSumitalStatuses;
        note: string;
        declineReasonId: number;
    }>()
);

export const updateIRPSubmittalStatusSuccess = createAction(
    '[Submittals] Update Internal Submittal Status Success',
    props<{ profile: CandidateJobSubmittalProfile }>()
);

export const updateIRPSubmittalStatusFail = createAction(
    '[Submittals] Update Internal Submittal Status Failed',
    props<{ error: any }>()
);

export const clearIRPSubmittals = createAction('[Submittals] Clear Internal Submittal');
