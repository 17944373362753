<div class="panel-body">
    <form [formGroup]="certificationForm" class="form-horizontal">
        <fieldset>
            <legend>Certifications</legend>
        </fieldset>
        <div class="flex-container">
            <!-- Standard Certifications -->
            <div *ngFor="let certification of certifications?.standard">
                <div class="margin-left-right25 flex33">
                    <ayac-vendor-profile-certifications-group
                        [vendorId]="vendorId"
                        [certification] = "certification"
                        [vendorCertifications]="changeTracking?.certifications?.data[certification.id]"
                        (vendorCertificationsChange)="updateCertificationChange(certification.id, $event)"
                        (updateVendorCertification)="updateVendorCertification($event)">
                    </ayac-vendor-profile-certifications-group>
                </div>
            </div>
        </div>
        <div class="flex-buttons-container">
            <button mat-raised-button color="basic"
                (click)="resetCertifications()"
                [disabled]="!changeTracking?.certifications?.changed">
                <em class="fa fa-undo"></em> Discard
            </button>
            <button mat-raised-button color="basic" class="mx-1"
                (click)="exportCertifications()">
                <em class="fa fa-arrow-down"></em> Export
            </button>
            <button mat-raised-button color="accent"
                (click)="saveCertifications()"
                [disabled]="!changeTracking?.certifications?.changed || !isFormValid">
                <em class="fa fa-save"></em> Save Changes
            </button>
        </div>
        <!-- SmallBusiness Certifications -->
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>Diversity</strong>
                        ({{changeTracking?.certifications?.smallBusinessSelected}})
                        <em class="pull-right glyphicon"></em>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex-container">
                    <div *ngFor="let group of groupedCertifications" class="margin-left-right25 flex33">
                        <h4 class="text-uppercase .h4padding">
                            {{group?.name}}
                        </h4>
                        <div *ngFor="let certification of group.certifications">
                            <ayac-vendor-profile-certifications-group
                                [vendorId]="vendorId"
                                [certification] = "certification"
                                [vendorCertifications]="changeTracking?.certifications?.data[certification.id]"
                                (vendorCertificationsChange)="updateCertificationChange(certification.id, $event)"
                                [locationLevels]="locationLevels"
                                (updateVendorCertification)="updateVendorCertification($event)"
                                [ethnicityTypes]="ethnicityTypes"
                                [isDiversity]="true"
                                (downloadFile)="downloadFile($event)">
                            </ayac-vendor-profile-certifications-group>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</div>
