import { ValidatorFn } from '@angular/forms';

export function emptyValidator(): ValidatorFn {
    return function (control) {
        if (control.value === null) {
            return { empty: null };
        }

        return null;
    };
}

export function notWhiteSpaceValidator(): ValidatorFn {
    return function (control) {
        let isWhitespace = (control.value || '').trim().length === 0;
        return isWhitespace ? { whitespace: 'Value is only whitespace' } : null;
    };
}

export function hasWhiteSpaceValidator(): ValidatorFn {
    return function (control) {
        const hasWhitespace = (control.value || '').indexOf(' ') >= 0;
        return hasWhitespace ? { hasWhitespaces: 'Value cannot have whitespaces' } : null;
    };
}
