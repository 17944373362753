<ayac-submittals-modal title="Offer Submittal"
                       [loading]="loading || submitting"
                       [loadingText]="submitting ? 'Offer being created...' : 'Job information is loading...'"
                       actionButtonName="Offer"
                       actionButtonColor="accent"
                       (actionButtonClick)="onSave()"
                       [actionButtonDisabled]="loading || noteItemIsNotValid || submitting || formIsInvalid"
                       *ifLdFeature="featureFlag.ConnectClientDisplayNewSubmittalStatus; enabled: true">
    <form [formGroup]="form">
        <ng-container *ngIf="standaloneVMSOfferChipEnabled">
            <div class="warning-container">
                <div>
                    <mat-icon>warning</mat-icon>
                </div>
                <div>
                    Once the offer is submitted, the status cannot be changed.
                </div>
            </div>
        </ng-container>
        <div class="grid">
            <div class="flex-row items-center">
                <strong>Clinician's Name</strong>
            </div>
            <span>{{ userName }}</span>

            <div class="flex-row items-center">
                <strong>Job ID</strong>
            </div>
            <span>{{ jobId }}</span>

            <ng-container *ngIf="standaloneVMSClientOfferFlowEnabled">
                <div>
                    <strong>Vendor</strong>
                </div>
                <span>{{ vendorName }}</span>
            </ng-container>

            <div class="flex-row items-center">
                <strong>Facility</strong>
            </div>
            <span>{{ facilityName }}</span>

            <div class="flex-row items-center">
                <strong>Specialty</strong>
            </div>
            <span>{{ specialty }}</span>

            <ng-container *ngIf="readOnly; else offerForm">
                <div class="flex-row items-center">
                    <strong>Shift</strong>
                </div>
                <span>{{ standaloneVMSClientOfferFlowEnabled ? shiftLongFormat : shiftText }}</span>

                <div class="flex-row items-center">
                    <strong>Start Date</strong>
                </div>
                <span>{{ startDate  | date : 'MM/dd/yyyy' : 'UTC' }}</span>

                <div class="flex-row items-center">
                    <strong>Contract Duration</strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <span *ngIf="!loading">{{ contractDuration }}</span>

                <div class="flex-row items-center">
                    <strong>RTO Dates</strong>
                </div>
                <span>{{ submittal.requestTimeOffDates  | requestTimeOff }}</span>
            </ng-container>

            <ng-template #offerForm>
                <div class="flex-row items-center">
                    <strong>Start Date <span class="k-required">*</span></strong>
                </div>
                <datepicker-multiple
                    formControlName="startDate"
                    [multi]="false"
                    [min]="minStartDate"
                    [validateField]="true"
                    data-qa-id="ac_client_os_startdate">
                </datepicker-multiple>

                <div class="flex-row items-center">
                    <strong>End Date <span class="k-required">*</span></strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <datepicker-multiple *ngIf="!loading"
                    formControlName="endDate"
                    [multi]="false"
                    [min]="minStartDate"
                    [validateField]="true"
                    data-qa-id="ac_client_os_startdate">
                </datepicker-multiple>

                <div class="flex-row items-center">
                    <strong>Weeks Length <span class="k-required">*</span></strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <span *ngIf="!loading">
                    <div class="flex-col">
                        <kendo-numerictextbox
                            formControlName="weeksLength"
                            [autoCorrect]="true"
                            [min]="0"
                            format="n0"
                            decimals="0"
                            [spinners]="true"
                            (valueChange)="calculateContractDates('weeksLengthChanged')"
                            data-qa-id="ac_client_os_weekslength">
                        </kendo-numerictextbox>
                        <validation-message
                            [control]="form.get('weeksLength')"
                            data-qa-id="ac_client_os_weekslength_error"
                            label="Weeks Length"
                            orientation="bottom"
                            [noHover]="true">
                        </validation-message>
                    </div>
                </span>

                <div class="flex-row items-center">
                    <strong>Days Length <span class="k-required">*</span></strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <span *ngIf="!loading">
                    <div class="flex-col">
                        <kendo-numerictextbox
                            formControlName="daysLength"
                            [autoCorrect]="true"
                            [min]="1"
                            format="n0"
                            decimals="0"
                            [spinners]="true"
                            (valueChange)="calculateContractDates('daysLengthChanged')"
                            data-qa-id="ac_client_os_dayslength">
                        </kendo-numerictextbox>
                        <validation-message [control]="form.get('daysLength')"
                            data-qa-id="ac_client_os_dayslength_error"
                            label="Days Length"
                            orientation="bottom"
                            [noHover]="true">
                        </validation-message>
                    </div>
                </span>

                <div class="flex-row items-center">
                    <strong>Shift Type <span class="k-required">*</span></strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <kendo-dropdownlist *ngIf="!loading"
                    [data]="shiftTypeOptions"
                    [valuePrimitive]="true"
                    data-qa-id="ac_client_os_shiftType"
                    valueField="id"
                    textField="name"
                    formControlName="shiftTypeId">
                </kendo-dropdownlist>

                <div class="flex-row items-center">
                    <strong>Number of Shifts <span class="k-required">*</span></strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <div *ngIf="!loading" class="flex-row gap-10">
                    <span class="flex-100">
                        <kendo-dropdownlist
                            class="self-start"
                            data-qa-id="ac_client_os_shifts"
                            [data]="hoursDropdownOptions"
                            formControlName="shifts">
                        </kendo-dropdownlist>
                    </span>
                    <div *ngIf="showAlternatingFields" class="flex-50">
                        <div class="flex-col">
                            <kendo-dropdownlist
                                class="self-start"
                                data-qa-id="ac_client_os_shiftsAlt"
                                [data]="hoursDropdownOptions"
                                formControlName="shiftsAlt">
                            </kendo-dropdownlist>
                            <validation-message [control]="form.get('shiftsAlt')"
                                data-qa-id="ac_client_os_shiftsAlt_error"
                                label="Alt Shifts"
                                orientation="bottom"
                                [noHover]="true">
                            </validation-message>
                        </div>
                    </div>
                </div>

                <div class="flex-row items-center">
                    <strong>Shift Length (Hrs) <span class="k-required">*</span></strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <div *ngIf="!loading" class="flex-row gap-10">
                    <span class="flex-100">
                        <kendo-dropdownlist
                            class="self-start"
                            [data]="shiftLengthOptions"
                            [valuePrimitive]="true"
                            data-qa-id="ac_client_os_shiftLength"
                            valueField="id"
                            textField="name"
                            formControlName="hours">
                        </kendo-dropdownlist>
                    </span>
                    <div *ngIf="showAlternatingFields" class="flex-50">
                        <div class="flex-col">
                            <kendo-dropdownlist
                                class="self-start"
                                [data]="shiftLengthOptions"
                                [valuePrimitive]="true"
                                data-qa-id="ac_client_os_shiftAltLength"
                                valueField="id"
                                textField="name"
                                formControlName="hoursAlt">
                            </kendo-dropdownlist>
                            <validation-message [control]="form.get('hoursAlt')"
                                data-qa-id="ac_client_os_hoursAlt_error"
                                label="Alt Shift Hours"
                                orientation="bottom"
                                [noHover]="true">
                            </validation-message>
                        </div>
                    </div>
                </div>

                <div class="flex-row items-center">
                    <strong>Shift Time of Day</strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <div *ngIf="!loading" class="flex-row gap-10">
                    <div  [class.flex-50]="showAlternatingFields || showRotatingFields">{{ shiftTimeOfDayText }}</div>
                    <div *ngIf="showAlternatingFields || showRotatingFields" class="flex-50">
                        {{ shiftAltTimeOfDayText }}
                    </div>
                </div>

                <div class="flex-row items-center">
                    <strong>Shift Time Start <span class="k-required">*</span></strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <div *ngIf="!loading" class="flex-row gap-10">
                    <span class="flex-100">
                        <div class="flex-col">
                            <kendo-timepicker
                                formControlName="shiftTimeStart"
                                data-qa-id="ac_client_os_shiftTimeStart"
                                [nowButton]="false"
                                [steps]="timeSteps"
                                format="HH:mm"
                                formatPlaceholder="short">
                            </kendo-timepicker>
                            <validation-message
                                [control]="form.get('shiftTimeStart')"
                                data-qa-id="ac_client_os_shiftTimeStart_error"
                                label="Shift Time Start"
                                orientation="bottom"
                                [noHover]="true">
                            </validation-message>
                        </div>
                    </span>
                    <div *ngIf="showAlternatingFields || showRotatingFields" class="flex-50">
                        <div class="flex-col">
                            <kendo-timepicker
                                formControlName="shiftAltTimeStart"
                                data-qa-id="ac_client_os_shiftAltTimeStart"
                                [nowButton]="false"
                                [steps]="timeSteps"
                                format="HH:mm"
                                formatPlaceholder="short">
                            </kendo-timepicker>
                            <validation-message
                                [control]="form.get('shiftAltTimeStart')"
                                data-qa-id="ac_client_os_shiftAltTimeStart_error"
                                label="Alt Shift Time Start"
                                orientation="bottom"
                                [noHover]="true">
                            </validation-message>
                        </div>
                    </div>
                </div>

                <div class="flex-row items-center">
                    <strong>Shift Time End <span class="k-required">*</span></strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <div *ngIf="!loading" class="flex-row gap-10">
                    <span class="flex-100">
                        <div class="flex-col">
                            <kendo-timepicker
                                formControlName="shiftTimeEnd"
                                data-qa-id="ac_client_os_shiftTimeEnd"
                                [nowButton]="false"
                                [steps]="timeSteps"
                                format="HH:mm"
                                formatPlaceholder="short">
                            </kendo-timepicker>
                            <validation-message
                                [control]="form.get('shiftTimeEnd')"
                                data-qa-id="ac_client_os_shiftTimeEnd_error"
                                label="Shift Time End"
                                orientation="bottom"
                                [noHover]="true">
                            </validation-message>
                        </div>
                    </span>
                    <div *ngIf="showAlternatingFields || showRotatingFields" class="flex-50">
                        <div class="flex-col">
                            <kendo-timepicker
                                formControlName="shiftAltTimeEnd"
                                data-qa-id="ac_client_os_shiftAltTimeEnd"
                                [nowButton]="false"
                                [steps]="timeSteps"
                                format="HH:mm"
                                formatPlaceholder="short">
                            </kendo-timepicker>
                            <validation-message
                                [control]="form.get('shiftAltTimeEnd')"
                                data-qa-id="ac_client_os_shiftAltTimeEnd_error"
                                label="Alt Shift Time End"
                                orientation="bottom"
                                [noHover]="true">
                            </validation-message>
                        </div>
                    </div>
                </div>

                <div class="flex-row items-center">
                    <strong>RTO Approved</strong>
                </div>
                <span>
                    <mat-checkbox formControlName="requestedTimeOffIsApproved" data-qa-id="ac_client_od_rto_approval"></mat-checkbox>
                </span>

                <div class="flex-row items-center">
                    <strong>RTO Dates<span *ngIf="form.get('requestedTimeOffIsApproved').value" class="k-required">*</span></strong>
                </div>
                <span>
                    <div class="flex-col">
                        <datepicker-multiple class="rto-dates"
                            formControlName="rtoDates"
                            [multi]="true"
                            [format]="rtoDatesFormat"
                            data-qa-id="ac_client_os_rtodates">
                        </datepicker-multiple>
                        <validation-message [control]="form.get('requestedTimeOffIsApproved')"
                            data-qa-id="ac_client_os_rto_dates_error"
                            label="RTO Dates"
                            orientation="bottom"
                            [noHover]="true">
                        </validation-message>
                    </div>
                </span>
                <ng-container *ngIf="canEditBillRate">
                    <div class="flex-row items-center">
                        <strong>Bill Rate</strong>
                    </div>
                    <span *ngIf="loading" class="loading">loading</span>
                    <span *ngIf="!loading">
                        <div class="flex-col">
                            <kendo-numerictextbox
                                formControlName="billRate"
                                [autoCorrect]="true"
                                [min]="0"
                                [max]="10000"
                                format="n2"
                                decimals="2"
                                [spinners]="true"
                                data-qa-id="ac_client_os_billrate">
                            </kendo-numerictextbox>
                            <validation-message [control]="form.get('billRate')"
                                data-qa-id="ac_client_os_billrate_error"
                                label="Bill Rate"
                                orientation="bottom"
                                customMessage="Bill Rate must be between $0 and $10,000"
                                [noHover]="true">
                            </validation-message>
                        </div>
                    </span>
                </ng-container>
            </ng-template>

            <ng-container *ngIf="canViewBillRate && !canEditBillRate">
                <div class="flex-row items-center">
                    <strong>Bill Rate</strong>
                </div>
                <span *ngIf="loading" class="loading">loading</span>
                <span *ngIf="!loading" data-qa-id="ac_client_os_billrate_readonly">{{ standaloneVMSClientOfferFlowEnabled ? (billRate | currency:'USD':'symbol':'.0-2') : billRate }}</span>
            </ng-container>

            <ng-container *ngIf="!standaloneVMSClientOfferFlowEnabled">
                <div class="flex-row items-center">
                    <strong>Suggest Bill Rate</strong>
                </div>
                <input matInput
                       type="text"
                       id="suggestedBillRate"
                       formControlName="suggestedBillRate"
                       class="hide-border"
                       [readonly]="disableAll || loading" />

                <div class="flex-row items-center">
                    <strong>PO Number</strong>
                </div>
                <input matInput
                       type="text"
                       id="PONumber"
                       formControlName="PONumber"
                       class="hide-border"
                       [readonly]="disableAll" />
            </ng-container>

            <div>
                <strong>Offer Details</strong>
            </div>
            <ayac-text-area-with-counter placeholder="Note"
                                         formControlName="note"
                                         [editor]="editor"
                                         [isVendorClientCommunicationEnabled]="isVendorClientCommunicationEnabled"
                                         [data-qa-id]="offer-note"
                                         [displayBorderBottomOnly]="true"
                                         (formItemValidationChanged)="onNoteItemValidationChanged($event)">
            </ayac-text-area-with-counter>
        </div>
    </form>

</ayac-submittals-modal>


<dialog-layout title="Offer Submittal" *ifLdFeature="featureFlag.ConnectClientDisplayNewSubmittalStatus; enabled: false">
    <div mat-dialog-content>
        <div class="k-form-inline" [formGroup]="form">

            <div class="k-form-field k-form-field-static">
                <span class="title">Clinician's Name</span>
                <span>{{ userName }}</span>
            </div>

            <div class="k-form-field k-form-field-static">
                <span class="title">Job ID</span>
                <span>{{ jobId }}</span>
            </div>

            <div *ngIf="standaloneVMSClientOfferFlowEnabled" class="k-form-field k-form-field-static">
                <span class="title">Vendor</span>
                <span>{{ vendorName }}</span>
            </div>

            <div class="k-form-field k-form-field-static">
                <span class="title">Facility</span>
                <span>{{ facilityName }}</span>
            </div>

            <div class="k-form-field k-form-field-static">
                <span class="title">Specialty</span>
                <span>{{ specialty }}</span>
            </div>

            <div class="k-form-field k-form-field-static">
                <span class="title">{{ standaloneVMSClientOfferFlowEnabled? 'Shifts' : 'Shift' }}</span>
                <span *ngIf="loading" class="loading">loading</span>
                <span *ngIf="!loading">{{ standaloneVMSClientOfferFlowEnabled? shiftLongFormat : shiftText }}</span>
            </div>

            <div class="k-form-field k-form-field-static">
                <span class="title">Start Date</span>
                <span>{{ startDate  | date : 'MM/dd/yyyy' : 'UTC' }}</span>
            </div>

            <div class="k-form-field k-form-field-static">
                <span class="title">{{ standaloneVMSClientOfferFlowEnabled? 'Weeks Length' : 'Contract Duration' }}</span>
                <span>
                    <span *ngIf="loading" class="loading">loading</span>
                    <span *ngIf="!loading">{{ contractDuration }}</span>
                </span>
            </div>

            <div class="k-form-field k-form-field-static">
                <span class="title">Bill Rate</span>
                <span>
                    <span *ngIf="loading" class="loading">loading</span>
                    <span *ngIf="!loading">{{ standaloneVMSClientOfferFlowEnabled ? (billRate | currency:'USD':true:'.0-2') : billRate }}</span>
                </span>
            </div>

            <ng-container *ngIf="!standaloneVMSClientOfferFlowEnabled">
                <div class="k-form-field k-form-field-static">
                    <label for="suggestedBillRate">Suggest Bill Rate</label>
                    <mat-form-field>
                        <input matInput
                            type="text"
                            id="suggestedBillRate"
                            formControlName="suggestedBillRate"
                            [readonly]="disableAll || loading"
                        />
                    </mat-form-field>
                </div>

                <div class="k-form-field k-form-field-static">
                    <label for="PONumber">PO Number</label>
                    <mat-form-field>
                        <input matInput
                            type="text"
                            id="PONumber"
                            formControlName="PONumber"
                            [readonly]="disableAll"
                        />
                    </mat-form-field>
                </div>
            </ng-container>

            <div class="k-form-field k-form-field-static">
                <label for="note">{{ standaloneVMSClientOfferFlowEnabled? 'Additional Details' : 'Offer Details' }}</label>
                <mat-form-field>
                    <ayac-text-area-with-counter id="note"
                                                 formControlName="note"
                                                 [editor]="editor"
                                                 [isVendorClientCommunicationEnabled]="isVendorClientCommunicationEnabled"
                                                 dataQaId="offer-status-note"
                                                 (formItemValidationChanged)="onNoteItemValidationChanged($event)">
                    </ayac-text-area-with-counter>
                </mat-form-field>
            </div>

        </div>
    </div>

    <div mat-dialog-actions>
        <span *ngIf="loading" class="loading footer">Job information is loading...</span>
        <button
            mat-flat-button
            color="accent"
            (click)="onSave()"
            style="width:fit-content"
            [disabled]="loading || submitting"
        >
               Offer
        </button>
        <button
            mat-stroked-button
            color="basic"
            (click)="onClose()"
            style="width:fit-content">
                Cancel
        </button>
    </div>
</dialog-layout>
