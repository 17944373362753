import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { SystemLookup } from 'src/app/shared/models/lookups/system-lookup.model';
import { UnitLookup } from 'src/app/shared/models/lookups/unit-lookup.model';
import * as StaffingAdvisoryActions from 'src/app/travelers/state/actions/staffing-advisory.actions';

export interface StaffingAdvisorySecurityLookupsState {
    system: SystemLookup;
    systems: SystemLookup[];
    facilities: FacilityLookup[];
    units: UnitLookup[];
    error: string;
    loading: LoadingTypes;
}

export const initialState: StaffingAdvisorySecurityLookupsState = {
    system: null,
    systems: [],
    facilities: [],
    units: [],
    error: '',
    loading: LoadingTypes.INIT
};

export function staffingAdvisorySecurityLookupsReducer(state: StaffingAdvisorySecurityLookupsState, action: Action) {
    return createReducer(
        initialState,
        on(StaffingAdvisoryActions.loadStaffingAdvisoriesSecurityLookups, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(StaffingAdvisoryActions.loadStaffingAdvisoriesSecurityLookupsSuccess, (state, action) => ({
            ...state,
            system: action.system,
            systems: action.systems,
            facilities: action.facilities,
            units: action.units,
            loading: LoadingTypes.LOADED
        })),

        on(StaffingAdvisoryActions.loadStaffingAdvisoriesSecurityLookupsFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: StaffingAdvisorySecurityLookupsState | undefined, action: Action) {
    return staffingAdvisorySecurityLookupsReducer(state, action);
}
