import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as StaffingAdvisoryActions from 'src/app/travelers/state/actions/staffing-advisory.actions';

export interface StaffingAdvisoryEditState {
    error: string;
    loading: LoadingTypes;
}

export const initialState: StaffingAdvisoryEditState = {
    error: '',
    loading: LoadingTypes.INIT
};

export function staffingAdvisoryEditReducer(state: StaffingAdvisoryEditState, action: Action) {
    return createReducer(
        initialState,
        on(StaffingAdvisoryActions.saveStaffingAdvisory, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(StaffingAdvisoryActions.saveStaffingAdvisorySuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(StaffingAdvisoryActions.saveStaffingAdvisoryFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        })),

        on(StaffingAdvisoryActions.clearStaffingAdvisory, (state, action) => ({
            ...initialState
        }))
    )(state, action);
}

export function reducer(state: StaffingAdvisoryEditState | undefined, action: Action) {
    return staffingAdvisoryEditReducer(state, action);
}
