<mat-accordion>
    <mat-expansion-panel data-qa-id="notesExpansionPanel" class="notes-expansion" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                NOTES
            </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <div class="notes" [formGroup]="noteForm">
                <mat-form-field [class.notes--small]="smallerScreen" class="vendor-notes-form-field" appearance="outline" style="width: 100%;">
                    <textarea data-qa-id="vendorNoteInput" matInput formControlName="noteText" rows="5" maxlength="5000"></textarea>
                    <mat-hint align="start">
                        <span>Additional note info would go in this location.</span>
                    </mat-hint>
                    <mat-hint align="end">{{ noteText?.length }} / 5000</mat-hint>
                </mat-form-field>

                <div [class.notes--small]="smallerScreen">
                    <mat-form-field [class.notes--small]="smallerScreen" class="vendor-notes-form-field notes-select" floatLabel="never" appearance="outline">
                        <mat-select data-qa-id="noteTypeSelect" placeholder="Select note type *" formControlName="noteType">
                            <mat-option *ngFor="let type of noteTypes$ | async" [value]="type.id" [attr.data-qa-id]="type.id">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <button color="accent" data-qa-id="addNoteButton" mat-flat-button [disabled]="noteForm.invalid" (click)="addNote()">Add</button>
                </div>
    
                <article class="note-container" *ngFor="let note of notes$ | async; trackBy: vendorNoteTrackBy">
                    <div class="note-heading">
                        <span>{{ note.modifiedEnteredOn | date:'MM/dd/yyyy hh:mm a':'UTC' }}</span>
                        <span>{{ note.enteredByName }}</span>
                    </div>
        
                    <p [innerHtml]="note.noteDescription"></p>
        
                    <div class="note-label">{{ note.noteTypeId | noteTypeLabel }}</div>
                </article>
            </div>
        </ng-template>

    </mat-expansion-panel>
</mat-accordion>