import { Action, createReducer, on } from '@ngrx/store';
import { UploadingTypes } from 'src/app/shared/models';
import { ImportTravelerFileResult } from '../../models/import-traveler-file-result';
import * as importTravelersCustomFields from '../actions/travelers-import-custom-fields.actions';

export interface TravelersImportCustomFieldsState {
    importing: UploadingTypes;
    fileName: string;
    errors: ImportTravelerFileResult[];
}

const initialState: TravelersImportCustomFieldsState = {
    importing: UploadingTypes.INIT,
    fileName: null,
    errors: []
};

export const travelersImportCustomFieldsReducer = (
    reducerState: TravelersImportCustomFieldsState,
    reducerAction: Action
): TravelersImportCustomFieldsState => {
    return createReducer(
        initialState,
        on(
            importTravelersCustomFields.importTravelersCustomFields,
            (state): TravelersImportCustomFieldsState => ({
                ...state,
                ...initialState,
                importing: UploadingTypes.UPLOADING
            })
        ),
        on(
            importTravelersCustomFields.importTravelersCustomFieldsFail,
            (state, action): TravelersImportCustomFieldsState => ({
                ...state,
                importing: UploadingTypes.FAIL,
                errors: action.lines,
                fileName: action.fileName
            })
        ),
        on(
            importTravelersCustomFields.importTravelersCustomFieldsSuccess,
            (state): TravelersImportCustomFieldsState => ({
                ...state,
                ...initialState,
                importing: UploadingTypes.UPLOADED
            })
        ),
        on(
            importTravelersCustomFields.importTravelersCustomFieldsReset,
            (state): TravelersImportCustomFieldsState => ({
                ...state,
                ...initialState
            })
        )
    )(reducerState, reducerAction);
};

export const reducer = (state: TravelersImportCustomFieldsState | undefined, action: Action) => {
    return travelersImportCustomFieldsReducer(state, action);
};
