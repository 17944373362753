import { Injectable } from '@angular/core';
import { LDFeatureManager } from '../feature-management/ld-feature-manager';
import { FeatureFlag } from '../models/enums/feature-flag.enum';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SubmittalCommunicationService {
    constructor(private _ldFeatureManager: LDFeatureManager) {}

    isCommunicationEnabled(useAyaAccountManagement: boolean): Observable<boolean> {
        const enableApnSubmittalsCommunication$ = this._ldFeatureManager.isEnabled(
            FeatureFlag.VendorClientCommunicationForApnDirectSubmittals
        );

        const enableNonVMSSubmittalsCommunication$ = this._ldFeatureManager.isEnabled(
            FeatureFlag.VendorClientCommunicationForNonVMSVendorSubmittals
        );

        return combineLatest([enableApnSubmittalsCommunication$, enableNonVMSSubmittalsCommunication$]).pipe(
            map(([enableApnSubmittalsCommunication, enableNonVMSSubmittalsCommunication]) => {
                return (
                    (enableApnSubmittalsCommunication && !useAyaAccountManagement) ||
                    (enableNonVMSSubmittalsCommunication && useAyaAccountManagement)
                );
            })
        );
    }
}
