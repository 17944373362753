<kendo-dropdownlist
    [data]="data"
    [textField]="textField"
    [valueField]="valueField"
    [defaultItem]="defaultToFirstItem ? null : defaultItem"
    [valuePrimitive]="true"
    [value]="selectedValue"
    [attr.disabled]="disabled ? '' : null"
    [popupSettings]="settings"
    [ngClass]="uniqueFilterClass"
    (valueChange)="onChange($event)"
    [style.width.px]="width">

    <ng-template *ngIf="customDropdownListTemplate" kendoDropDownListItemTemplate let-dataItem>
        <ng-container
            [ngTemplateOutlet]="customDropdownListTemplate"
            [ngTemplateOutletContext]="{ dataItem: dataItem }"></ng-container>
    </ng-template>

    <ng-template *ngIf="customDropdownListItemTemplate" kendoDropDownListValueTemplate let-dataItem>
        <ng-container
            [ngTemplateOutlet]="customDropdownListItemTemplate"
            [ngTemplateOutletContext]="{ dataItem: dataItem }"></ng-container>
    </ng-template>
</kendo-dropdownlist>
