<div class="button-bar">
    <button routerLink="/admin/jobs" mat-flat-button class="migrated-icon-button" color="accent">
        <mat-icon class="fa-sharp fa-regular fa-hand-point-left"></mat-icon>
        Back
    </button>
</div>

<div *ngIf="isJobStopped" class="warning-area">
    This job is on hold.
</div>

<div *ngIf="jobDetails$ | async as details" class="board pre2019">
    <div class="board-header">Job: {{ details?.profession }} - {{ details?.expertiseName }} &#64; {{ details?.facility }}</div>

    <div class="board-content">
        <div class="column first-column">
            <a [href]="unitFormLink" target="_blank" class="link-color">Edit Job in Nova</a>

            <address>
                <strong>{{ details?.facility }}</strong><br>
                {{ details?.hospAddress }}<br>
                <abbr title="Phone">P:</abbr> {{ details?.hospPhone }}
            </address>

            <div>
                <span><strong>Title:</strong></span>
                {{ details?.title }}
            </div>

            <div>
                <span><strong># of Positions:</strong></span>
                {{ details | positionsAmountText }}
            </div>

            <div>
                <span><strong>Profession:</strong></span>
                {{ details?.profession }}
            </div>

            <div>
                <span><strong>Specialty:</strong></span>
                {{ details?.expertiseName }}
            </div>

            <div>
                <span><strong>Unit:</strong></span>

                <ng-container *ngIf="details?.unitFormIsLinked; else notLinked">
                    <a class="link-color" [routerLink]="['/units']" [queryParams]="{ userId: userId, formId: details?.unitFormId, preview: true }" target="_blank">{{ details?.unit }}</a>
                </ng-container>

                <ng-template #notLinked>
                    {{ details?.unit }}
                </ng-template>
            </div>

            <div>
                <span><strong>Shift:</strong></span>
                {{ details?.shiftName }}
            </div>

            <div>
                <span><strong>Start Date:</strong></span>
                {{ details?.startDate | date:'mediumDate':'UTC' }}
            </div>

            <div>
                <span><strong>Facility Base Rate:</strong></span>
                {{ details?.billingRate | currency }}{{payFormat(details?.payPeriodTypeId)}}
            </div>

            <div>
                <span><strong>Vendor Rate:</strong></span>
                {{ details?.vendorRate | currency }}{{payFormat(details?.payPeriodTypeId)}}
            </div>

            <div>
                <span><strong>Vendor Rate Override:</strong></span>

                <ng-container *ngIf="canEditVendorRate$ | async; else rateNotEditable">
                    <ng-container *ngIf="!isEditing; else notEditing">
                        <span class="vendor-rate-display">
                            {{ details?.vendorRate | currency }}
                            <mat-icon (click)="isEditing = !isEditing" inline="true">edit</mat-icon>
                        </span>
                    </ng-container>

                    <ng-template #notEditing>
                        <span class="edit-container">
                            <mat-form-field appearance="outline">
                                <span matPrefix>$</span>
                                <input matInput [formControl]="vendorRateCtrl" type="number">
                            </mat-form-field>
                            <mat-icon inline="true" (click)="updateVendorBaseRate(details?.id)" matTooltip="Confirm vendor base rate override">done</mat-icon>
                            <mat-icon inline="true" (click)="isEditing = false" matTooltip="Cancel editing vendor rate">close</mat-icon>
                        </span>
                    </ng-template>
                </ng-container>

                <ng-template #rateNotEditable>
                    {{ details?.vendorRate | currency }}
                </ng-template>
            </div>

            <div>
                <span><strong>Facility Contract Fees:</strong></span>
                {{ details?.contractFee | percent }}
            </div>

            <div>
                <span><strong>Over Time Rules:</strong></span>
                {{ details?.overTimeRule }}
            </div>

            <div>
                <span><strong>Non-Bill. Orient. Hours:</strong></span>
                {{ details?.nonBillableOrientationHours }} Hours
            </div>

            <div>
                <span><strong>Job Description:</strong></span>
                <span [innerHtml]="details?.jobDescription | textFormat"></span>
            </div>

            <div>
                <span><strong>Reference Number:</strong></span>
                {{ details?.reference }}
            </div>

            <div>
                <span><strong>Date Added:</strong></span>
                {{ details?.enteredTimeUtc | date:'mediumDate':'UTC' }}
            </div>

            <div>
                <span><strong>Account Manager:</strong></span>
                {{ details?.accountManager?.firstName }} {{ details?.accountManager?.lastName }}
            </div>

            <div>
                <span><strong>Email:</strong></span>
                <a href="mailto:{{details?.accountManager?.email}}">{{details?.accountManager?.email}}</a>
            </div>

            <div *ngIf="details?.submittalInstructions">
                <span><strong>Submittal Instructions</strong></span>
                <br>
                <span [innerHtml]="details?.submittalInstructions"></span>
            </div>
        </div>

        <div class="column second-column">

            <div>
                <span>
                    <strong>Status:</strong>
                </span>
                {{ details?.approvalStatus ? details?.approvalStatus : 'N/A' }}
            </div>

            <div>
                <span>
                    <strong>Approval Date:</strong>
                </span>
                {{ details?.approvalDate | date:'mediumDate':'UTC' }}
            </div>

            <div class="position-releases" *ngIf="showPositionReleaseTable">
                <hr class="ajd-break">
                <caption *ngIf="showApprovalRequiredWarning" class="positions-table-caption">Pending Approval, Schedule details will be displayed once the job is approved</caption>
                <ng-container *ngIf="!showApprovalRequiredWarning">
                    <button *ngIf="details?.canRelease" mat-flat-button color="accent" class="release-all-pos-btn"
                        (click)="releaseJobPositions(details, true)" data-qa-id="JobDetailsReleaseAllPositionsButton">Release All Positions
                        </button>
                    <table data-qa-id="JobDetailsReleasePositionsTable" class="table table-condensed table-hover table-striped">
                    <caption class="positions-table-caption">Open Positions({{ openPositions.length }})</caption>
                        <thead>
                            <tr class="positions-table-header">
                                <th>ID #</th>
                                <th>Date Added</th>
                                <th>Scheduled</th>
                                <th>Release History</th>
                                <th>Release Position</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of openPositions">
                                <td data-qa-id="JobDetailsReleasePositionsTablePositionId">{{ item.id }}</td>
                                <td data-qa-id="JobDetailsReleasePositionsTableDateAdded">{{ item.createdDate | date : 'MM/dd/yyyy' : 'UTC' }}</td>
                                <td data-qa-id="JobDetailsReleasePositionsTableScheduled">
                                    <span *ngIf="details?.releaseSchedulingEnabled">{{ item.scheduledReleaseTime | releaseDayPipe }}</span>
                                </td>
                                <td data-qa-id="JobDetailsReleasePositionsTableViewHistoryLink">
                                    <a (click)="viewPositionReleaseHistory(details, item.id)">
                                        {{ item.releasedVendorsCount }} Vendors
                                    </a>
                                </td>
                                <td>
                                    <a *ngIf="details?.releaseStatus && item.releaseStatusId && item.releaseStatusId !== 2" (click)="releaseJobPositions(details, false, item.id)"
                                    [attr.data-qa-id]="item.id + 'JobDetailsReleasePositionsTableReleaseLink'">
                                        Release Position
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>
            <div *ngIf="hasJobAttachments$ | async">
                <span>
                    <strong>Attachments:</strong>
                </span>
                <ayac-file-attachments-list [editMode]="true" [attachments]="jobAttachments$ | async" [style]="fileAttachmentStyle.ListOnly" (preview)="attachmentToPreview($event, details)"></ayac-file-attachments-list>
            </div>
        </div>
    </div>

    <ayac-file-attachments-preview
        [file]="currentFile"
        [preview]="filePreview$ | async"
        (download)="downloadFile($event, details)"
        (close)="closePreview()">
    </ayac-file-attachments-preview>

</div>
