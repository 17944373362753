import { Directive, Input, ContentChildren, QueryList, OnInit } from '@angular/core';

@Directive({
    selector: 'ayac-menu-item'
})
export class MenuItemDirective implements OnInit {
    @ContentChildren(MenuItemDirective) private items: QueryList<MenuItemDirective>;
    url = '';
    isVisible = true;
    @Input() name = '';
    @Input() path: string;
    @Input() queryParams: any;
    @Input() title: string;
    @Input() icon: string;
    @Input() defaultItem: string;
    @Input() bageText: string;

    //@ContentChildren() includes the host element; this is a known bug:  https://github.com/angular/angular/issues/10098
    get subItems(): MenuItemDirective[] {
        return this.items.filter((item) => item !== this);
    }

    ngOnInit() {
        this.path = this.path || this.name;
    }
}
