import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { PerDiemCancelReason } from 'src/app/shared/models/shifts/client-lookups.model';
import { loadClientLookups, loadClientLookupsSuccess, loadClientLookupsFail } from '../actions';

export interface ClientLookupsState {
    perDiemCancelReasonsByNurse: Array<PerDiemCancelReason>;
    perDiemCancelReasonsByFacility: Array<PerDiemCancelReason>;
    loading: LoadingTypes;
}

export const initialClientLookupsState: ClientLookupsState = {
    perDiemCancelReasonsByNurse: [],
    perDiemCancelReasonsByFacility: [],
    loading: LoadingTypes.INIT
};

export function clientLookupsReducer(state: ClientLookupsState, action: Action) {
    return createReducer(
        initialClientLookupsState,
        on(loadClientLookups, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(loadClientLookupsSuccess, (state, action) => ({
            ...state,
            perDiemCancelReasonsByNurse: action.perDiemCancelReasonsByNurse,
            perDiemCancelReasonsByFacility: action.perDiemCancelReasonsByFacility,
            loading: LoadingTypes.LOADED
        })),

        on(loadClientLookupsFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: ClientLookupsState | undefined, action: Action) {
    return clientLookupsReducer(state, action);
}
