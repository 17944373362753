<div class="row">
    <mat-checkbox id="checkBox{{certification.id}}" (change)="toggle()" [checked]="isChecked"
                  title="{{certification?.description}}">{{certification?.name}}</mat-checkbox>

    <div *ngFor="let item of vendorCertifications; let i = index">

        <div class="flex-row-container">
            <ayac-vendor-profile-certifications-detail
                [vendorId]="vendorId"
                [certification]="certification"
                [locationLevels]="locationLevels"
                [ethnicityTypes]="ethnicityTypes"
                [(vendorCertification)]="vendorCertifications[i]"
                [isDiversity]="isDiversity"
                (updateVendorCertification)="updateVendorCertification.emit($event)"
                (downloadFile)="downloadFile.emit($event)">
            </ayac-vendor-profile-certifications-detail>

            <div *ngIf="vendorCertifications.length > 1 && isDiversity">
                <button class="delete-button" mat-icon-button (click)="removeCertificate(item)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>

        <div *ngIf="isChecked && isDiversity && vendorCertifications.length === i + 1" class="add-certificate">
            <a (click)="addCertification()">+ Add Additional Document</a>
        </div>
    </div>

</div>

