export enum ReportType {
    TravelJobsOpen = 1,
    TravelJobHistory = 2,
    TravelAssignmentsCurrent = 3,
    TravelAssignmentsFuture = 4,
    TravelAssignmentsInactive = 5,
    TravelAssignmentsCompleted = 6,
    TravelAssignmentsCancellations = 7,
    PerDiemPoolActive = 8,
    PerDiemShiftsOpen = 9,
    PerDiemShiftHistory = 10,
    SpendingByMonth = 11,
    SpendingByLocation = 12,
    SpendingByUnit = 13,
    SpendingBySpecialty = 14,
    SpendingByCostCenter = 15,
    SpendingBySupplier = 16
}
