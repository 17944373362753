import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/admin/store/actions/vendor-candidates-detail-files.actions';
import {
    getInitialVendorCandidateFileState,
    VendorCandidateFileState
} from 'src/app/admin/store/state/vendor-candidates-detail-files.state';
import { LoadingTypes } from 'src/app/shared/models';

export function vendorCandidateDetailFileReducer(state: VendorCandidateFileState, action: Action) {
    return createReducer(
        getInitialVendorCandidateFileState(),

        on(actions.loadVendorCandidateDetailFiles, (state, action) => ({
            ...state,
            candidateId: action.candidateId,
            loading: LoadingTypes.LOADING
        })),

        on(actions.loadVendorCandidateDetailFilesSuccess, (state, action) => ({
            ...state,
            candidateFiles: action.candidateFiles,
            candidateId: action.candidateId,
            loading: LoadingTypes.LOADED
        })),

        on(actions.loadVendorCandidateDetailFilesFailure, (state, action) => ({
            ...state,
            candidateFiles: null,
            loading: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })),

        on(actions.loadFileTypes, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(actions.loadFileTypesSuccess, (state, action) => ({
            ...state,
            fileTypes: action.fileTypes,
            loading: LoadingTypes.LOADED
        })),

        on(actions.setVendorCandidateDetailFilesSearchQuery, (state, action) => ({
            ...state,
            gridSearchQuery: action.searchQuery,
            loading: LoadingTypes.LOADING
        })),

        on(actions.deleteVendorCandidateDetailFiles, (state, action) => ({
            ...state,
            deletingFileIds: action.fileIds,
            isDeleting: true,
            isDeleteSuccess: undefined
        })),

        on(actions.deleteVendorCandidateDetailFilesSuccess, (state, action) => ({
            ...state,
            deletingFileIds: [],
            isDeleting: false,
            isDeleteSuccess: true
        })),

        on(actions.deleteVendorCandidateDetailFilesFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error)
        }))
    )(state, action);
}

export function reducer(state: VendorCandidateFileState | undefined, action: Action) {
    return vendorCandidateDetailFileReducer(state, action);
}
