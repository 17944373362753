import { Action, createReducer, on } from '@ngrx/store';

import { clearJobDetails, loadJobDetails, loadJobDetailsFail, loadJobDetailsSuccess } from '../actions';

import { LoadingTypes } from 'src/app/shared/models';
import { Job } from 'src/app/jobs/models';

export interface SubmittalJobDetailsState {
    job: Job;
    error: string;
    loading: LoadingTypes;
}

const initialState: SubmittalJobDetailsState = {
    job: null,
    error: '',
    loading: LoadingTypes.INIT
};

export function submittalJobDetailsReducer(state: SubmittalJobDetailsState, action: Action) {
    return createReducer(
        initialState,
        on(loadJobDetails, (state, action) => ({
            ...state,
            job: null,
            loading: LoadingTypes.LOADING
        })),
        on(loadJobDetailsSuccess, (state, action) => ({
            ...state,
            job: action.job,
            error: '',
            loading: LoadingTypes.LOADED
        })),
        on(loadJobDetailsFail, (state, action) => ({
            ...state,
            job: null,
            error: action.error,
            loading: LoadingTypes.LOADED
        })),
        on(clearJobDetails, (state, action) => ({
            ...initialState
        }))
    )(state, action);
}

export function reducer(state: SubmittalJobDetailsState | undefined, action: Action) {
    return submittalJobDetailsReducer(state, action);
}
