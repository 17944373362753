<mat-card class="offer-notes">
    <mat-card-title>
        <span>Activity & Comments</span>
        <button mat-flat-button color="accent"
            data-qa-id="ac_client_od_add_offer_note_button"
            (click)="addOfferNote()"
            [disabled]="!canAddComment">
            Add Comment
        </button>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content class="offer-notes-content">
        <ng-container *ngFor="let note of (offerNotes$ | async); last as isLast">
            <div class="offer-note">
                <span class="offer-note-created-date">{{ note.createdDate | date: 'MMMM d, y h:mm a'}}</span>
                <ng-container *ifLdFeature="featureFlag.VmsOfferCommentsTitleFormat; enabled false">
                    <span class="offer-note-title" data-qa-id="vms_offer_notes_title">{{ note.title ? note.title : 'Comment from ' + note.creatorRole }}</span>
                </ng-container>
                <ng-container *ifLdFeature="featureFlag.VmsOfferCommentsTitleFormat; enabled true">
                    <span class="offer-note-title" data-qa-id="vms_offer_notes_title">{{ note.title }}</span>
                </ng-container>
                <span [innerHtml]="note.note"></span>
            </div>
            <mat-divider class="offer-note-divider" *ngIf="!isLast"></mat-divider>
        </ng-container>
    </mat-card-content>
</mat-card>
