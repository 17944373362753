import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap, map, filter, catchError, tap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { JobsRepositoryService } from '../../../jobs/services/jobs-repository.service';
import { ToasterService } from 'src/app/core/services';
import * as submittalActions from '../actions';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BookCandidateModalComponent } from '../../components/book-candidate-modal/book-candidate-modal.component';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { SubmittalsInternalService } from '../../services/submittals-internal.service';
import { CandidateJobBook } from 'src/app/submittals/models';

@Injectable()
export class SubmittalsBookEffect extends BaseEffect {
    showBookModal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(submittalActions.showBookCandidateModal),
            switchMap((action) => {
                const data = { profile: action.profile };
                this.dialog.dialog.closeAll();
                return this.dialog
                    .openDialog(BookCandidateModalComponent, { width: '700px', data })
                    .beforeClosed()
                    .pipe(
                        filter((result) => result != null),
                        map((result: CandidateJobBook) => {
                            return submittalActions.bookCandidate({
                                submittalId: action.profile.id,
                                startDate: result.startDate,
                                endDate: result.endDate,
                                shiftDuration: result.shiftDuration,
                                days: result.workDays
                            });
                        })
                    );
            })
        )
    );

    bookCandidate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(submittalActions.bookCandidate),
            switchMap((action) => {
                return this.submittalsInternalService
                    .bookCandidate({
                        submittalId: action.submittalId,
                        days: action.days,
                        startDate: action.startDate,
                        shiftDuration: action.shiftDuration,
                        endDate: action.endDate
                    })
                    .pipe(
                        mergeMap((response) => [
                            submittalActions.bookCandidateSuccess(),
                            submittalActions.loadIRPSubmittalDetails({ id: action.submittalId })
                        ]),
                        catchError((error) => of(submittalActions.bookCandidateFail({ error })))
                    );
            })
        )
    );

    bookCandidateSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(submittalActions.bookCandidateSuccess),
                tap((action) => {
                    this.toasterService.success('Shift(s) created');
                    this.logData('Shift(s) created');
                })
            ),
        { dispatch: false }
    );

    bookCandidateFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(submittalActions.bookCandidateFail),
                map((action) => action.error),
                tap((error) => {
                    this.handleError(error);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{}>,
        private readonly jobsRepository: JobsRepositoryService,
        private readonly submittalsInternalService: SubmittalsInternalService,
        private readonly toaster: ToasterService,
        private readonly dialog: DialogService
    ) {
        super(toaster);
    }
}
