import { Pipe, PipeTransform } from '@angular/core';
import { JobDetail } from 'src/app/admin/jobs/models/job-detail.model';
import { isEmpty } from 'src/app/shared/utilities';

@Pipe({
    name: 'positionsAmountText'
})
export class PositionsAmountTextPipe implements PipeTransform {
    transform(item: JobDetail) {
        if (isEmpty(item)) {
            return item.positions;
        }

        if (item.jobPositions) {
            if (item.jobPositions.length === 0) {
                if (item.positions > item.contractsAmount) {
                    return `${item.positions - item.contractsAmount} open / ${item.positions} total`;
                } else {
                    return `0 open / ${item.positions} total`;
                }
            }
        }

        let openPositionsAmount = 0;
        let closedPositionsAmount = 0;

        for (const position of item.jobPositions) {
            if (position.jobPositionStatus === 0) {
                openPositionsAmount++;
            } else if (position.jobPositionStatus === 1 && position.jobPositionCloseReason === 1) {
                closedPositionsAmount++;
            }
        }

        return `${openPositionsAmount} open / ${openPositionsAmount + closedPositionsAmount} total`;
    }
}
