import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './resource-list-dialog.component.html',
    styleUrls: ['./resource-list-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceListDialogComponent {
    title = `${this.data.resourceName} - ${this.data.listTitle}`;

    constructor(
        public dialogRef: MatDialogRef<ResourceListDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            resourceName: string;
            listTitle: string;
            list: string[];
        }
    ) {}
}
