import { NgModule } from '@angular/core';

import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { ButtonModule, ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
    declarations: [],
    providers: [],
    imports: [],
    exports: [
        GridModule,
        ButtonModule,
        ExcelModule,
        GridModule,
        PDFModule,
        PopupModule,
        DropDownsModule,
        DateInputsModule,
        ButtonsModule,
        TimePickerModule,
        InputsModule,
        ChartsModule,
        TextBoxModule,
        TooltipModule
    ]
})
export class KendoModule {}
