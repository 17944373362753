<div class="panel panel-default">
    <div class="panel-body">
        <fieldset>
            <legend>Job Rules</legend>
        </fieldset>

        <mat-accordion>
            <span *ngIf="isNew || !jobRules" class="no-rules-display-text" data-qa-id="ad_job_rules_no_rules_text">No rules have been added yet.</span>

            <mat-expansion-panel *ngFor="let rule of jobRules" (opened)="accordionOpened(rule.jobRuleId)">
                <mat-expansion-panel-header>
                    <div class="header-container">
                        <div class="header-rule-name">
                            <span class="label">Name</span>
                            <ng-container *ngIf="hasUserPermissions; else plainTextRuleName">
                                <a class="aya__link rule-name-link" data-qa-id="ad_job_rules_name_link" [routerLink]="['/admin/vendors/job-rules', rule.jobRuleId, 'edit']">{{ rule.jobRuleName }}</a>
                            </ng-container>
                            <ng-template #plainTextRuleName>
                                <span>{{ rule.jobRuleName }}</span>
                            </ng-template>
                        </div>
                        <div class="header-status">{{ rule.isActive ? 'Active' : 'Inactive' }}</div>
                    </div>
                </mat-expansion-panel-header>
                <div class="rule-details-container" *ngIf="!isLoading">
                    <div class="detail-item">
                        <span class="label">Description</span>
                        <div class="display-text">{{ jobRuleDetails?.description }}</div>
                    </div>
                    <div class="detail-item">
                        <span class="label">States</span>
                        <div class="display-text">
                            <span *ngFor="let state of jobRuleDetails?.states; let last = last">
                                {{ state.name }}{{ !last ? ', ' : '' }}
                            </span>
                        </div>
                    </div>
                    <div class="detail-item">
                        <span class="label">Employment Types</span>
                        <div class="display-text">
                            <span *ngFor="let employment of jobRuleDetails?.employmentTypes; let last = last">
                                {{ employment.name }}{{ !last ? ', ' : '' }}
                            </span>
                        </div>
                    </div>
                    <div class="detail-item full-width">
                        <span class="label">Exclude Profession/Specialties</span>
                        <div class="field-container full-width">
                            <shared-nested-checkbox-tree *ngIf="professionTypeTree"
                                [uniqueClass]="'panel-padding'"
                                [professionTypes]="professionTypeTree.professionTypes"
                                [preselectedOptions]="professionTypeValues"
                                [isReadOnly] = "isReadOnly">
                            </shared-nested-checkbox-tree>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
