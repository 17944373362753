import { createAction, props } from '@ngrx/store';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { State } from 'src/app/shared/grid/models/state.model';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { Expertise } from 'src/app/shared/models/shifts/expertise.model';
import { VendorCandidate } from 'src/app/admin/vendor-candidates/vendor-candidate.model';
import { ListItem } from 'src/app/shared/models/list-item';

export const loadAdminVendorCandidateExpertises = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Load Admin Vendor Candidates List Expertises'
);
export const loadAdminVendorCandidatesExpertisesSuccess = createAction(
    '[EXPERTISE LOOKUPS API] Load Expertises Success',
    props<{ expertises: Expertise[] }>()
);
export const loadAdminVendorCandidatesExpertiseFailure = createAction(
    '[EXPERTISE LOOKUPS API] Load Expertises Failure',
    props<{ error: any }>()
);

export const loadAdminVendorCandidatesList = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Load Vendor Candidates List'
);
export const loadAdminVendorCandidatesListSuccess = createAction(
    '[ADMIN VENDOR CANDIDATES LIST API] Load Vendor Candidates List Success',
    props<{ candidates: PagingToken<VendorCandidate[]> }>()
);
export const loadAdminVendorCandidatesListFailure = createAction(
    '[ADMIN VENDOR CANDIDATES LIST API] Load Vendor Candidates List Failure',
    props<{ error: any }>()
);
export const setVendorCandidatesListSearchQuery = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Set Vendor Candidates Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);
export const clearAdminVendorCandidatesList = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Clear Vendor Candidates List'
);
export const exportAdminVendorCandidatesList = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Export Vendor Candidates List'
);
export const exportAdminVendorCandidatesListSuccess = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Export Vendor Candidates List Success'
);
export const exportAdminVendorCandidatesListFailure = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Export Vendor Candidates List Failure',
    props<{ error: any }>()
);

export const loadAdminVendorCandidateVendors = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Load Admin Vendor Candidates List Vendors'
);
export const loadAdminVendorCandidatesVendorsSuccess = createAction(
    '[VENDORS LOOKUPS API] Load Vendors Success',
    props<{ vendors: ListItem[] }>()
);
export const loadAdminVendorCandidatesVendorsFailure = createAction(
    '[VENDORS LOOKUPS API] Load Vendors Failure',
    props<{ error: any }>()
);

export const loadAdminVendorCandidateProfessions = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Load Admin Vendor Candidates List Professions'
);
export const loadAdminVendorCandidatesProfessionsSuccess = createAction(
    '[PROFESSIONS LOOKUPS API] Load Professions Success',
    props<{ professions: ListItem[] }>()
);
export const loadAdminVendorCandidatesProfessionsFailure = createAction(
    '[PROFESSIONS LOOKUPS API] Load Professions Failure',
    props<{ error: any }>()
);

export const loadAdminVendorCandidateStates = createAction(
    '[ADMIN VENDOR CANDIDATES LIST COMPONENT] Load Admin Vendor Candidates List States'
);
export const loadAdminVendorCandidateStatesSuccess = createAction(
    '[STATE LOOKUPS API] Load States Success',
    props<{ states: State[] }>()
);
export const loadAdminVendorCandidateStatesFailure = createAction(
    '[STATE LOOKUPS API] Load States Failure',
    props<{ error: any }>()
);
