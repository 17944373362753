import { createAction, props } from '@ngrx/store';
import { ShiftReleaseSetting, ShiftReleaseSettingTemplate } from 'src/app/shifts/models';

export const loadShiftReleaseSettingTemplate = createAction('[SHIFTS] Load Shift Release Settings Template');

export const loadShiftReleaseSettingTemplateFail = createAction(
    '[SHIFTS] Load Shift Release Settings Template Fail',
    props<{ error: any }>()
);

export const loadShiftReleaseSettingTemplateSuccess = createAction(
    '[SHIFTS] Load Shift Release Settings Template Success',
    props<{ settings: ShiftReleaseSettingTemplate }>()
);

export const saveShiftReleaseSettingTemplate = createAction(
    '[SHIFTS] Save Shift Release Settings Template',
    props<{ template: ShiftReleaseSettingTemplate }>()
);

export const saveShiftReleaseSettingTemplateFail = createAction(
    '[SHIFTS] Save Shift Release Settings Template Fail',
    props<{ error: any }>()
);

export const saveShiftReleaseSettingTemplateSuccess = createAction(
    '[SHIFTS] Save Shift Release Settings Template Success'
);

export const clearShiftReleaseSettingTemplate = createAction('[SHIFTS] Clear Shift Release Settings Template');

export const calculateShiftTiers = createAction(
    '[SHIFTS] Calculate Shift Release Tiers',
    props<{ shiftDates: string[]; facilityId: number; template: ShiftReleaseSettingTemplate }>()
);

export const calculateShiftTiersSuccess = createAction(
    '[SHIFTS] Calculate Shift Release Tiers Success',
    props<{ settings: ShiftReleaseSetting[] }>()
);

export const calculateShiftTiersFail = createAction(
    '[SHIFTS] Calculate Shift Release Tiers Fail',
    props<{ error: any }>()
);
