import { ChangeDetectorRef, Component, Inject, OnInit, Input } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { SubmittalEmailData, SubmittalExpanded } from 'src/app/shared/models/submittals';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { SendOfferRequest } from 'src/app/shared/models/submittals/requests/send-offer.request';
import { DateChangeType, SubmittalHelper } from 'src/app/submittals/shared/submittal-helper';
import dayjs from 'dayjs';
import { SubmittalsService } from 'src/app/submittals/services/submittals.service';
import { createOffer, loadJobDetails } from 'src/app/submittals/store/actions';
import { selectSubmittalOfferCreating, selectSubmittalJobDetails } from 'src/app/submittals/store/selectors';
import { getSubmittalEmailData } from 'src/app/submittals/store/submittals.selectors';
import { CandidateOfferShiftType, OfferCreate } from 'src/app/submittals/models';
import { Job } from 'src/app/jobs/models';
import { ShiftTypeEnum } from 'src/app/shifts/models/shift-type.enum';
import { LoadingTypes } from 'src/app/shared/models';
import { DateHelper } from 'src/app/shared/utilities';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { Editor } from 'ngx-editor';

const RtoApprovalValidator: ValidatorFn = (formControl: FormControl) => {
    if (!formControl.parent) {
        return null;
    }
    const rtoDates = formControl.parent.get('rtoDates').value;
    const isRtoApproved = formControl.value;
    return !rtoDates.length && isRtoApproved ? { rtoApproval: true } : null;
};
@Component({
    selector: 'ayac-offer-candidate-modal',
    templateUrl: './offer-candidate-modal.component.html',
    styleUrls: ['./offer-candidate-modal.component.scss']
})
export class OfferCandidateModalComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() calledFromTheWizardDialog: boolean;
    @Input() submittalFromTheWizardDialog: SubmittalExpanded;
    form: UntypedFormGroup;
    disableAll: false;
    featureFlag = FeatureFlag;
    noteItemIsNotValid: boolean = false;
    standaloneVMSClientOfferFlowEnabled: boolean;
    standaloneVMSOfferChipEnabled: boolean;

    minStartDate = new Date();
    minEndDate: Date;
    jobDetails: Job;
    submittal: SubmittalExpanded;
    shiftTimeOfDayText: string;
    shiftAltTimeOfDayText: string;

    hoursDropdownOptions = [1, 2, 3, 4, 5, 6];
    shiftLengthOptions = [7.5, 8, 9.5, 10, 11.5, 12];
    shiftTimeOfDayOptions = [
        { id: ShiftTypeEnum.Day, name: 'Day' },
        { id: ShiftTypeEnum.Evening, name: 'Evening' },
        { id: ShiftTypeEnum.Night, name: 'Night' }
    ];
    shiftTypeOptions = [
        { id: CandidateOfferShiftType.Standard, name: 'Standard' },
        { id: CandidateOfferShiftType.Alternating, name: 'Alternating' },
        { id: CandidateOfferShiftType.Rotating, name: 'Rotating' }
    ];
    timeSteps = { hour: 1, minute: 1 };
    editor!: Editor;
    isVendorClientCommunicationEnabled = false;

    contractDuration: number;
    billRate: number;
    connectVmsOfferBillRateUsePermissions = false;
    canViewBillRate = false;
    canEditBillRate = false;
    shiftLongFormat: string;

    loading: boolean;
    submitting: boolean;

    get userName(): string {
        return this.submittal.userName;
    }

    get jobId(): number {
        return this.submittal.jobId;
    }

    get facilityName(): string {
        return this.submittal.facilityName;
    }

    get specialty(): string {
        return this.submittal.vendorCandidateSpecialties || this.submittal.specialty;
    }

    get shiftText(): string {
        return this.submittal.shiftText;
    }

    get startDate(): Date {
        return this.submittal.startDate;
    }

    get vendorName(): string {
        return this.submittal.isSubContractor ? this.submittal.agencyName : 'Aya';
    }

    get showRotatingFields(): boolean {
        return this.form.get('shiftTypeId').value === CandidateOfferShiftType.Rotating;
    }

    get showAlternatingFields(): boolean {
        return this.form.get('shiftTypeId').value === CandidateOfferShiftType.Alternating;
    }

    get readOnly(): boolean {
        return (
            !this.standaloneVMSClientOfferFlowEnabled ||
            !(!this.submittal.useAyaAccountManagement && this._permissionsService.submittalOffer())
        );
    }

    get managerName(): string {
        return (
            this.submittalEmailData?.programManagerName?.trim() ||
            this.submittalEmailData?.accountManagerName?.trim() ||
            ''
        );
    }

    get formIsInvalid(): boolean {
        return this.standaloneVMSClientOfferFlowEnabled && !this.submittal.useAyaAccountManagement && this.form.invalid;
    }

    private submittalEmailData: SubmittalEmailData;

    constructor(
        private readonly dialogRef: MatDialogRef<OfferCandidateModalComponent>,
        private readonly toaster: ToasterService,
        private readonly service: SubmittalsService,
        @Inject(MAT_DIALOG_DATA)
        private readonly data: { submittal: SubmittalExpanded; facilityDirectApplyEnabled: boolean },
        private readonly store: Store,
        private readonly fb: UntypedFormBuilder,
        private readonly _featureManager: LDFeatureManager,
        protected changeDetectorRef: ChangeDetectorRef,
        private readonly _permissionsService: PermissionsService
    ) {
        super();
        if (this.calledFromTheWizardDialog) {
            this.submittal = this.submittalFromTheWizardDialog;
        } else {
            this.submittal = data.submittal;
        }
    }

    private createForm(): void {
        this.form = this.fb.group({
            suggestedBillRate: [0],
            billRate: [0, [Validators.required, Validators.min(0), Validators.max(10000)]],
            PONumber: [''],
            note: [''],
            startDate: [this.submittal.startDate, [Validators.required]],
            endDate: [new Date().toISOString(), [Validators.required]],
            weeksLength: [0, [Validators.required]],
            daysLength: [0, [Validators.required]],
            requestedTimeOffIsApproved: [false, [RtoApprovalValidator]],
            rtoDates: [this.submittal.requestTimeOffDates ?? []],
            shiftTypeId: [CandidateOfferShiftType.Standard, [Validators.required]],
            shifts: ['', [Validators.required]],
            hours: ['', [Validators.required]],
            shiftTimeStart: [new Date(), [Validators.required]],
            shiftTimeEnd: [new Date(), [Validators.required]],
            shiftsAlt: [null],
            hoursAlt: [null],
            shiftAltTimeStart: [null],
            shiftAltTimeEnd: [null]
        });

        this.form
            .get('startDate')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe(() => this.calculateContractDates('startDateChanged'));

        this.form
            .get('endDate')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe(() => this.calculateContractDates('endDateChanged'));

        this.form
            .get('rtoDates')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe(() => this.form.get('requestedTimeOffIsApproved').updateValueAndValidity());

        this.form
            .get('hours')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe(() => {
                this.refreshShiftEndTime();
                this.refreshShiftEndTimeAlt();
            });

        this.form
            .get('hoursAlt')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe(() => this.refreshShiftEndTimeAlt());

        this.form
            .get('shiftTimeStart')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((value) => this.onShiftTimeStartChange(value));

        this.form
            .get('shiftAltTimeStart')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((value) => this.onShiftAltTimeStartChange(value));

        this.form
            .get('shiftTypeId')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((shiftType: CandidateOfferShiftType) => {
                switch (shiftType) {
                    case CandidateOfferShiftType.Standard:
                        this.form.get('shiftsAlt').removeValidators([Validators.required]);
                        this.form.get('hoursAlt').removeValidators([Validators.required]);
                        this.form.get('shiftAltTimeStart').removeValidators([Validators.required]);
                        this.form.get('shiftAltTimeEnd').removeValidators([Validators.required]);
                        this.form.get('shiftsAlt').setValue(null);
                        this.form.get('hoursAlt').setValue(null);
                        this.form.get('shiftAltTimeStart').setValue(null);
                        this.form.get('shiftAltTimeEnd').setValue(null);
                        break;
                    case CandidateOfferShiftType.Alternating:
                        this.form.get('shiftsAlt').addValidators([Validators.required]);
                        this.form.get('hoursAlt').addValidators([Validators.required]);
                        this.form.get('shiftAltTimeStart').addValidators([Validators.required]);
                        this.form.get('shiftAltTimeEnd').addValidators([Validators.required]);
                        break;
                    case CandidateOfferShiftType.Rotating:
                        this.form.get('shiftsAlt').removeValidators([Validators.required]);
                        this.form.get('hoursAlt').removeValidators([Validators.required]);
                        this.form.get('shiftAltTimeStart').addValidators([Validators.required]);
                        this.form.get('shiftAltTimeEnd').addValidators([Validators.required]);
                        break;
                }
                this.onShiftTimeStartChange(this.form.get('shiftTimeStart').value);
                this.onShiftAltTimeStartChange(this.form.get('shiftAltTimeStart').value);
                this.changeDetectorRef.detectChanges();
                this.form.updateValueAndValidity();
            });

        this.form.updateValueAndValidity();
    }

    private loadJobDetails(): void {
        this.loading = true;

        combineLatest([this.store.select(selectSubmittalJobDetails), this.store.select(getSubmittalEmailData)])
            .pipe(
                filter(([job, emailData]) => !!job && !!emailData && !!this.form),
                tap(([job, emailData]) => {
                    this.jobDetails = job;
                    this.submittalEmailData = emailData;
                    this.contractDuration = job.durationWeeks;
                    this.billRate = job.billRate;
                    this.form.patchValue({
                        billRate: job.billRate,
                        suggestedBillRate: job.billRate
                    });
                    this.shiftLongFormat = job.longShiftFormat;
                    this.form.patchValue({
                        endDate: dayjs(this.submittal.startDate).add(job.durationWeeks, 'weeks').format('YYYY-MM-DD'),
                        shiftTypeId:
                            job.altShift_Fl === 1
                                ? CandidateOfferShiftType.Alternating
                                : CandidateOfferShiftType.Standard,
                        shifts: job.shifts,
                        shiftsAlt: job.shiftsAlt,
                        hours: job.hours,
                        hoursAlt: +job.hoursAlt === 0 ? null : +job.hoursAlt,
                        shiftTimeStart: dayjs().set('hour', job.shiftH).set('minute', job.shiftM).toDate(),
                        shiftTimeEnd: dayjs().set('hour', job.shiftToH).set('minute', job.shiftToM).toDate(),
                        shiftAltTimeStart: dayjs().set('hour', job.shiftAltH).set('minute', job.shiftAltM).toDate(),
                        shiftAltTimeEnd: dayjs().set('hour', job.shiftAltToH).set('minute', job.shiftAltToM).toDate()
                    });
                    this.calculateContractDates('endDateChanged');
                    this.loading = false;
                })
            )
            .subscribe();
        this.store.dispatch(loadJobDetails({ jobId: this.submittal.jobId }));

        this.store
            .select(selectSubmittalOfferCreating)
            .pipe(takeUntil(this.d$))
            .subscribe((creating) => {
                this.submitting = creating === LoadingTypes.LOADING;
                if (creating === LoadingTypes.LOADED) {
                    this.dialogRef.close(true);
                }
            });
    }

    ngOnInit(): void {
        this._featureManager
            .isEnabled(this.featureFlag.ConnectVMSClientCreateOffer)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.standaloneVMSClientOfferFlowEnabled = flagIsEnabled;
            });

        this._featureManager
            .isEnabled(this.featureFlag.ConnectVMSOfferChip)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.standaloneVMSOfferChipEnabled = flagIsEnabled;
            });

        this._featureManager
            .isEnabled(FeatureFlag.VendorClientCommunicationForApnDirectSubmittals)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.isVendorClientCommunicationEnabled = isEnabled && this.data.facilityDirectApplyEnabled;
                if (this.isVendorClientCommunicationEnabled) {
                    this.editor = new Editor();
                }
            });
        this._featureManager
            .isEnabled(this.featureFlag.ConnectVmsOfferBillRateUsePermissions)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.connectVmsOfferBillRateUsePermissions = flagIsEnabled;
                if (!this.connectVmsOfferBillRateUsePermissions) {
                    this.canViewBillRate = true;
                    this.canEditBillRate = false;
                } else {
                    this.canViewBillRate = this._permissionsService.billRateView();
                    this.canEditBillRate = this.canViewBillRate && this._permissionsService.billRateEdit();
                }
            });

        this.createForm();
        this.loadJobDetails();
    }

    onSave() {
        this.submitting = true;

        if (this.standaloneVMSClientOfferFlowEnabled) {
            const shiftTimeStart = this.form.get('shiftTimeStart').value as Date;
            const shiftTimeEnd = this.form.get('shiftTimeEnd').value as Date;
            const shiftAltTimeStart = this.form.get('shiftAltTimeStart').value as Date;
            const shiftAltTimeEnd = this.form.get('shiftAltTimeEnd').value as Date;
            const offer: OfferCreate = {
                submittalId: this.submittal.id,
                offerDetails: this.form.get('note').value,
                startDate: Array.isArray(this.form.get('startDate').value)
                    ? this.form.get('startDate').value[0]
                    : this.form.get('startDate').value,
                endDate: Array.isArray(this.form.get('endDate').value)
                    ? this.form.get('endDate').value[0]
                    : this.form.get('endDate').value,
                requestedTimeOffIsApproved: this.form.get('requestedTimeOffIsApproved').value,
                rtoDates: this.form.get('rtoDates').value,
                shiftTypeId: this.form.get('shiftTypeId').value,
                shifts: this.form.get('shifts').value,
                shiftH: shiftTimeStart.getHours(),
                shiftM: shiftTimeStart.getMinutes(),
                shiftToH: shiftTimeEnd.getHours(),
                shiftToM: shiftTimeEnd.getMinutes(),
                hours: this.form.get('hours').value,
                shiftsAlt: this.form.get('shiftsAlt').value,
                hoursAlt: this.form.get('hoursAlt').value,
                shiftAltH: shiftAltTimeStart?.getHours(),
                shiftAltM: shiftAltTimeStart?.getMinutes(),
                shiftAltToH: shiftAltTimeEnd?.getHours(),
                shiftAltToM: shiftAltTimeEnd?.getMinutes(),
                billRate: this.form.get('billRate').value
            };
            this.store.dispatch(
                createOffer({ offer, useAyaAccountManagement: this.submittal.useAyaAccountManagement })
            );
        } else {
            const formValue: SendOfferRequest = {
                submittalId: this.submittal.id,
                offerDetails: this.form.get('note').value,
                poNumber: this.form.get('PONumber').value,
                suggestedBillRate: this.form.get('suggestedBillRate').value
            };
            this.service.sendSubmittalOffer(formValue).subscribe({
                next: () => {
                    this.toaster.success(`Offer submitted to ${this.managerName}`);
                    this.submitting = false;
                    this.dialogRef.close(true);
                },
                error: (error) => {
                    console.error(error);
                    this.toaster.fail('Offer submission failed!');
                    this.submitting = false;
                    this.dialogRef.close(false);
                }
            });
        }
    }

    onClose() {
        this.dialogRef.close(false);
    }

    onNoteItemValidationChanged(isValid: boolean) {
        this.noteItemIsNotValid = !isValid;
    }

    rtoDatesFormat = (date: Date[]) => {
        return DateHelper.rtoDatesToString(date, 'MM/DD/YYYY');
    };

    updateMinEndDate(date: Date): void {
        this.minEndDate = dayjs(date).subtract(1, 'day').toDate();
    }

    calculateContractDates(changeType: DateChangeType): void {
        SubmittalHelper.calculateContractDates(changeType, this.form, null, this.updateMinEndDate.bind(this));
    }

    hasError = (controlName: string, errorName: string) => {
        return this.form.controls[controlName].hasError(errorName);
    };

    onShiftTimeStartChange(shiftTimeStart: Date) {
        this.shiftTimeOfDayText = this.shiftTimeOfDayOptions.find(
            (x) => x.id === SubmittalHelper.getShiftTimeTypeId(shiftTimeStart?.getHours() ?? 0)
        ).name;
        this.refreshShiftEndTime();
    }

    onShiftAltTimeStartChange(shiftAltTimeStart: Date) {
        this.shiftAltTimeOfDayText = this.shiftTimeOfDayOptions.find(
            (x) => x.id === SubmittalHelper.getShiftTimeTypeId(shiftAltTimeStart?.getHours() ?? 0)
        ).name;
        this.refreshShiftEndTimeAlt();
    }

    refreshShiftEndTime() {
        const hours = this.form.get('hours').value;
        const shiftTimeStart = this.form.get('shiftTimeStart').value;
        if (hours && shiftTimeStart) {
            const endTime = SubmittalHelper.getShiftEndTime(hours, shiftTimeStart);
            this.form.patchValue({ shiftTimeEnd: endTime });
        }
    }

    refreshShiftEndTimeAlt() {
        const shiftType = this.form.get('shiftTypeId').value;
        const hoursAlt =
            shiftType === CandidateOfferShiftType.Alternating
                ? this.form.get('hoursAlt').value
                : this.form.get('hours').value;
        const shiftAltTimeStart = this.form.get('shiftAltTimeStart').value;
        if (hoursAlt && shiftAltTimeStart) {
            const endTimeAlt = SubmittalHelper.getShiftEndTime(hoursAlt, shiftAltTimeStart);
            this.form.patchValue({ shiftAltTimeEnd: endTimeAlt });
        }
    }
}
