import { createAction, props } from '@ngrx/store';
import { VendorJobDetails } from 'src/app/shared/models/jobs';
import {
    CandidateInfo,
    NewSubmittal,
    NewSubmittalNote,
    SubmittalPreferences,
    SubmittalProfessionSpecialties,
    SubmittalStageEntry,
    VendorSubmittalDetails
} from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';
import { SubmittalActivity } from 'src/app/submittals/models';

export const loadSubmittalsDetails = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTAL DETAILS] Load Vendor Submittals Details',
    props<{ submittalId: number }>()
);

export const loadSubmittalsDetailsSuccess = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTAL DETAILS SUCCESS] Vendor Submittals Load Details Success',
    props<{ details: VendorSubmittalDetails }>()
);

export const loadSubmittalsDetailsFail = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTAL DETAILS FAILURE] Vendor Submittals Load Details Failure',
    props<{ error: any }>()
);

export const createNewSubmittal = createAction(
    '[VENDOR SUBMITTALS CREATE SUBMITTAL] Create Vendor Submittal',
    props<{ submittalParams: NewSubmittal }>()
);

export const createNewSubmittalSuccess = createAction(
    '[VENDOR SUBMITTALS CREATE SUBMITTAL SUCCESS] Create Vendor Submittal Success',
    props<{ submittalId: number }>()
);

export const createNewSubmittalFailure = createAction(
    '[VENDOR SUBMITTALS CREATE SUBMITTAL FAILURE] Create Vendor Submittal Failure',
    props<{ error: any }>()
);

export const updateSubmittalNote = createAction(
    '[VENDOR SUBMITTALS UPDATE SUBMITTAL NOTE] Update Vendor Submittal Note',
    props<{ submittalId: number; note: string }>()
);

export const updateSubmittalNoteSuccess = createAction(
    '[VENDOR SUBMITTALS UPDATE SUBMITTAL NOTE SUCCESS] Update Vendor Submittal Note Success',
    props<{ submittalId: number }>()
);

export const updateSubmittalNoteFailure = createAction(
    '[VENDOR SUBMITTALS UPDATE SUBMITTAL NOTE FAILURE] Update Vendor Submittal Note Failure',
    props<{ error: any }>()
);

export const updateSubmittalPreferences = createAction(
    '[VENDOR SUBMITTALS UPDATE SUBMITTAL PREFERENCES] Update Vendor Submittal Preferences',
    props<{ submittalId: number; preferences: SubmittalPreferences }>()
);

export const updateSubmittalPreferencesSuccess = createAction(
    '[VENDOR SUBMITTALS UPDATE SUBMITTAL PREFERENCES SUCCESS] Update Vendor Submittal Preferences Success'
);

export const updateSubmittalPreferencesFailure = createAction(
    '[VENDOR SUBMITTALS UPDATE SUBMITTAL PREFERENCES FAILURE] Update Vendor Submittal Preferences Failure',
    props<{ error: any }>()
);

export const updateStageHistoryEntry = createAction(
    '[VENDOR SUBMITTALS UPDATE STAGE HISTORY ENTRY] Update Stage History Entry',
    props<{ entry: SubmittalStageEntry }>()
);

export const updateStageHistoryEntrySuccess = createAction(
    '[VENDOR SUBMITTALS UPDATE STAGE HISTORY ENTRY] Update Stage History Entry Success',
    props<{ submittalId: number; successToasterMessage: string }>()
);

export const updateStageHistoryEntryFailure = createAction(
    '[VENDOR SUBMITTALS UPDATE STAGE HISTORY ENTRY] Update Stage History Entry Failure',
    props<{ error: any }>()
);

export const getCandidateProfessionSpecialties = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTAL PROFESSION AND SPECIALTIES] Load Vendor Submittals Profession and Specialites',
    props<{ candidateId: number }>()
);

export const getCandidateProfessionSpecialtiesSuccess = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTAL PROFESSION AND SPECIALTIES SUCCESS] Vendor Submittals Load Profession and Specialites Success',
    props<{ professionSpecialties: SubmittalProfessionSpecialties[] }>()
);

export const getCandidateProfessionSpecialtiesFail = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTAL PROFESSION AND SPECIALTIES FAILURE] Vendor Submittals Load Profession and Specialites Failure',
    props<{ error: any }>()
);

export const loadVendorJob = createAction(
    '[VENDOR SUBMITTALS LOAD VENDOR JOB] Load Vendor Submittals Vendor Job',
    props<{ jobId: number }>()
);

export const loadVendorJobSuccess = createAction(
    '[VENDOR SUBMITTALS LOAD VENDOR JOB SUCCESS] Vendor Submittals Load Vendor Job Success',
    props<{ jobDetails: VendorJobDetails }>()
);

export const loadVendorJobFail = createAction(
    '[VENDOR SUBMITTALS LOAD VENDOR JOB FAILURE] Vendor Submittals Load Vendor Job Failure',
    props<{ error: any }>()
);

export const loadCandidateInfo = createAction(
    '[VENDOR SUBMITTALS LOAD CANDIDATE INFO] Load Vendor Submittals Candidate Info',
    props<{ candidateId: number }>()
);

export const loadCandidateInfoSuccess = createAction(
    '[VENDOR SUBMITTALS LOAD CANDIDATE INFO SUCCESS] Vendor Submittals Load Candidate Info Success',
    props<{ candidateInfo: CandidateInfo }>()
);

export const loadCandidateInfoFail = createAction(
    '[VENDOR SUBMITTALS LOAD CANDIDATE INFO FAILURE] Vendor Submittals Load Candidate Info Failure',
    props<{ error: any }>()
);

export const setChangeState = createAction(
    '[VENDOR SUBMITTALS SET CHANGE STATE] Vendor Submittals Set Change State',
    props<{ hasChanges: boolean }>()
);

export const clearSubmittalDetails = createAction('[VENDOR SUBMITTAL] Vendor Submittals Clear State');

export const addSubmittalNote = createAction(
    '[VENDOR SUBMITTALS COMPONENT] Add Submittal Note',
    props<{ submittalId: number; newSubmittalNote: NewSubmittalNote }>()
);

export const addSubmittalNoteSuccess = createAction(
    '[VENDOR SUBMITTALS API] Add Submittal Note Success',
    props<{ submittalId: number }>()
);

export const addSubmittalNoteFail = createAction(
    '[VENDOR SUBMITTALS API] Add Submittal Note Fail',
    props<{ error: any }>()
);

export const loadSubmittalActivities = createAction(
    '[VENDOR SUBMITTALS LOAD ACTIVITY] Load Vendor Submittals Activity',
    props<{ submittalId: number }>()
);

export const loadSubmittalActivitiesSuccess = createAction(
    '[VENDOR SUBMITTALS LOAD ACTIVITY SUCCESS] Vendor Submittals Load Activity Success',
    props<{ activities: SubmittalActivity[] }>()
);

export const loadSubmittalActivitiesFail = createAction(
    '[VENDOR SUBMITTALS LOAD ACTIVITY FAILURE] Vendor Submittals Load Activity Failure',
    props<{ error: any }>()
);

export const setActivitiesSortBy = createAction(
    '[VENDOR SUBMITTALS COMPONENT] Set Activities Sort By',
    props<{ submittalId: number; sortActivitiesBy: string | null }>()
);

export const loadSubmittalCommunicationEnabled = createAction(
    '[VENDOR SUBMITTALS COMPONENT] Load Submittal Communication Enabled',
    props<{ useAyaAccountManagement: boolean }>()
);

export const loadSubmittalCommunicationEnabledSuccess = createAction(
    '[VENDOR SUBMITTALS COMPONENT] Load Submittal Communication Enabled Success',
    props<{ communicationEnabled: boolean }>()
);

export const loadSubmittalCommunicationEnabledFail = createAction(
    '[VENDOR SUBMITTALS COMPONENT] Load Submittal Communication Enabled Failure',
    props<{ error: any }>()
);
