import {
    ApprovalProcessTier,
    ApprovalProcessTierEdit,
    JobApprovalProcess,
    JobApprovalProcessEdit
} from 'src/app/jobs/approvals/models/approvals-dto.models';
import { ApprovalProcessType } from 'src/app/jobs/approvals/models/approvals-dto.enums';

export const changeFacilityProcessForSaving = (process: JobApprovalProcess): JobApprovalProcessEdit =>
    process != null
        ? ({
              id: process.id,
              facilityProfileGroupIds:
                  process.facilityProfileGroups && process.facilityProfileGroups.length > 0
                      ? process.facilityProfileGroups.map((x) => x.id)
                      : [],
              level: process.level,
              type: process.type ? process.type : ApprovalProcessType.None,
              tiers: process.tiers ? process.tiers.map(changeTiersForSaving) : [],
              jobOpenReasonId: process.jobOpenReasonId
          } as JobApprovalProcessEdit)
        : null;

export const changeUnitProcessForSaving = (process: JobApprovalProcess): JobApprovalProcessEdit =>
    process != null
        ? ({
              id: process.id,
              level: process.level,
              type: process.type ? process.type : ApprovalProcessType.None,
              unitIds:
                  process.facilityProfileGroups &&
                  process.facilityProfileGroups.length > 0 &&
                  process.facilityProfileGroups.some((x) => x.units && x.units.length > 0)
                      ? process.facilityProfileGroups
                            .map((x) => x.units || [])
                            .reduce((prev, cur) => prev.concat(cur), [])
                            .map((x) => x.id)
                      : [],
              tiers: process.tiers ? process.tiers.map(changeTiersForSaving) : [],
              jobOpenReasonId: process.jobOpenReasonId
          } as JobApprovalProcessEdit)
        : null;

export const changeTiersForSaving = (tier: ApprovalProcessTier): ApprovalProcessTierEdit =>
    tier != null
        ? {
              id: typeof tier.id === 'string' ? 0 : tier.id,
              tierType: tier.tierType,
              tierNumber: tier.tierNumber,
              tierApproverIds:
                  tier.tierApprovers && tier.tierApprovers.length > 0
                      ? tier.tierApprovers.filter((x) => !x.isIneligible).map((x) => x.userId)
                      : []
          }
        : null;
