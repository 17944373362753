import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityService } from 'src/app/shared/services/identity.service';

@Injectable()
export class ConnectAuthGuard {
    constructor(
        private readonly _identityService: IdentityService,
        private readonly _router: Router
    ) {}

    canActivate(): boolean {
        if (!this._identityService.isSignedIn()) {
            this._router.navigate(['/signin']);
            return false;
        }

        return true;
    }
}
