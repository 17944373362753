import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DateHelper } from 'src/app/shared/utilities';

interface CalendarDayItem {
    id: number;
    date: Date;
    dayNumber: number;
    isCurrentMonth: boolean;
    className: string;
    isDisabled: boolean;
    text: string[];
    isToday: boolean;
}

interface CalendarEventItem {
    event: CalendarEvent;
    isStart: boolean;
    isEnd: boolean;
    isRange: boolean;
    isHolder: boolean;
    position: number;
}

export interface CalendarEvent {
    title: string;
    startDate: Date;
    endDate: Date;
    color?: String;
}

@Component({
    selector: 'ayac-calendar-schedule',
    templateUrl: './calendar-schedule.component.html',
    styleUrls: ['./calendar-schedule.component.scss']
})
export class CalendarScheduleComponent implements OnInit {
    @Input() dateClass: (d: Date) => string;
    @Input() dateDisabled: (d: Date) => boolean;
    @Input() dateText: (d: Date) => string | string[];
    @Input() currentDate?: Date = new Date();

    @Input() events?: CalendarEvent[];

    date = new Date();
    days: CalendarDayItem[] = [];
    _dayEvents: { [key in number]: CalendarEventItem[] } = {};

    constructor() {}

    ngOnInit() {
        this.fillMonth();
        this.fillEvents();
    }

    ngOnChanges(changes) {
        if (
            changes.currentDate &&
            new Date(changes.currentDate.currentValue).getTime() !==
                new Date(changes.currentDate.previousValue).getTime()
        ) {
            this.date = this.currentDate;
            this.fillMonth();
            this.fillEvents();
        }

        if (changes.events) {
            this.fillEvents();
        }
    }

    public fillMonth() {
        this.days.length = 0;
        const start = moment(this.date).startOf('month').startOf('week');
        const end = moment(this.date).endOf('month').endOf('week').add(1, 'day');

        while (start.diff(end, 'day') < 0) {
            const currentDate = start.toDate();
            const className = typeof this.dateClass === 'function' ? this.dateClass(currentDate) : '';

            const isDisabled = typeof this.dateDisabled === 'function' ? this.dateDisabled(currentDate) : false;

            const text = typeof this.dateText === 'function' ? this.dateText(currentDate) : '';

            this.days.push({
                id: Number(currentDate),
                date: currentDate,
                dayNumber: start.date(),
                isCurrentMonth: start.isSame(this.date, 'month'),
                className,
                isDisabled,
                text: Array.isArray(text) ? text : [text],
                isToday: start.isSame(new Date(), 'day')
            });
            start.add(1, 'day');
        }
    }

    prevMonth() {
        this.date = moment(this.date).add(-1, 'month').toDate();
        this.fillMonth();
        this.fillEvents();
    }
    nextMonth() {
        this.date = moment(this.date).add(1, 'month').toDate();
        this.fillMonth();
        this.fillEvents();
    }

    trackByFn(i: number, item: CalendarDayItem) {
        return String(item.id);
    }

    fillEvents() {
        this._dayEvents = {};

        for (let day of this.days) {
            const events = this.events?.filter((e) => DateHelper.inRangeDay(day.date, e.startDate, e.endDate)) ?? [];
            const key = day.id;
            const dayEvents = [];
            const holderEvent: CalendarEventItem = {
                event: undefined,
                isStart: false,
                isEnd: false,
                isRange: false,
                position: 0,
                isHolder: true
            };

            for (let event of events) {
                dayEvents.push({
                    event: event,
                    isStart: DateHelper.isSameDay(event.startDate, day.date),
                    isEnd: DateHelper.isSameDay(event.endDate, day.date),
                    isRange: !DateHelper.isSameDay(event.startDate, event.endDate),
                    position: this.eventPosition(event),
                    isHolder: false
                });
            }
            if (!dayEvents.length) {
                continue;
            }
            const maxPosition = Math.max.apply(null, dayEvents.map((e) => e.position).concat(0));

            this._dayEvents[key] = new Array(maxPosition + 1)
                .fill(null)
                .map((_, i) => dayEvents.find((e) => e.position == i) ?? holderEvent);
        }
    }

    eventPosition(event: CalendarEvent): number {
        const positions = [];
        const events = this.events
            .slice()
            .sort((a, b) => DateHelper.dayNumber(a.startDate) - DateHelper.dayNumber(b.startDate));

        for (let day of this.days) {
            const dayEvents = events.filter((e) => DateHelper.inRangeDay(day.date, e.startDate, e.endDate));

            for (let dayEvent of dayEvents) {
                const dayEventIndex = dayEvents.indexOf(dayEvent);
                const eventIndex = events.indexOf(dayEvent);
                const prevEvent = dayEvents[dayEventIndex - 1];
                const prevEventIndex = events.indexOf(prevEvent);

                if (eventIndex in positions) {
                    continue;
                }

                positions[eventIndex] = positions[prevEventIndex] != null ? positions[prevEventIndex] + 1 : 0;
            }
        }

        return positions[events.indexOf(event)];
    }

    eventsForDay(day: CalendarDayItem): CalendarEventItem[] {
        return this._dayEvents[day.id] ?? [];
    }

    trackEventFn = (i: number, item: CalendarEvent) => item.title;
}
