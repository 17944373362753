import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as ExtensionActions from '../actions/travelers-extension.actions';

export interface CreateExtensionsState {
    jobId: number;
    clientSubmittalId: number;
    error: string;
    loading: LoadingTypes;
}

const initialState: CreateExtensionsState = {
    jobId: null,
    clientSubmittalId: null,
    error: null,
    loading: LoadingTypes.INIT
};

export function createExtensionReducer(state: CreateExtensionsState, action: Action) {
    return createReducer(
        initialState,
        on(ExtensionActions.createExtension, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(ExtensionActions.createClientExtensionOffer, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(ExtensionActions.createExtensionSuccess, (state, action) => ({
            ...state,
            jobId: action.jobId,
            loading: LoadingTypes.LOADED
        })),

        on(ExtensionActions.createClientExtensionOfferSuccess, (state, action) => ({
            ...state,
            clientSubmittalId: action.clientSubmittalId,
            loading: LoadingTypes.LOADED
        })),

        on(ExtensionActions.createExtensionFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        })),

        on(ExtensionActions.createClientExtensionOfferFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        })),

        on(ExtensionActions.clearExtensionJobId, (state, action) => ({
            ...state,
            jobId: null
        })),

        on(ExtensionActions.clearExtensionClientSubmittalId, (state, action) => ({
            ...state,
            clientSubmittalId: null
        }))
    )(state, action);
}

export function reducer(state: CreateExtensionsState | undefined, action: Action) {
    return createExtensionReducer(state, action);
}
