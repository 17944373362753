<mat-card class="files-tab-card">
    <mat-card-content>
        <div
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="space-around center"
            fxLayoutAlign.lt-lg="space-around start"
        >
            <div fxFlex>
                <strong>Required Documents for Candidate Submittals:</strong>
                <ul>
                    <li>
                        Application for all Nursing Positions. Don't have an
                        application? Click
                        <a
                            href="../../../../assets/forms/Candidate Application.pdf"
                            target="_blank"
                            ><strong>here</strong></a
                        >.
                    </li>
                    <li>Resume – for Allied Positions</li>
                    <li>Skills Checklist</li>
                    <li>
                        2 Professional References from Supervisor within the
                        past 2 years
                    </li>
                    <li class="red-text">
                        <strong>
                            Application and references need to be LOGO
                            free</strong
                        >
                    </li>
                </ul>
                <span class="file-size-note">
                    <u>NOTE</u>: *Uploaded files must total less than 20 MB.
                    <span *ngIf="totalFileSize$ | async">
                        <strong> The current sum of uploaded files is {{ totalFileSize$ | async | byteFormatter }}</strong>
                    </span>
                </span>

            </div>
            <div>
                <button
                    mat-flat-button
                    color="accent"
                    class="migrated-btn-info-brdr"
                    data-qa-id="ac_vendor_candidate_refresh_button"
                    (click)="refresh()"
                    [disabled]="isLoading$ | async"
                    [class.spinner]="isLoading$ | async"
                >
                    <mat-icon>refresh</mat-icon> Refresh
                </button>

                <button
                    mat-flat-button
                    color="accent"
                    class="migrated-btn-info-brdr"
                    data-qa-id="ac_vendor_candidate_add_files_button"
                    [disabled]="isUploading || isNew"
                    [class.spinner]="isUploading"
                    [matMenuTriggerFor]="menu"
                >
                    <mat-icon>add</mat-icon> Add Files
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="fileUpload.click()">
                        Submission Packet
                    </button>
                </mat-menu>
                <input
                    type="file"
                    class="file-input"
                    #fileUpload
                    multiple
                    (change)="onFileSelected($event, 1, 'Submission Packet')"
                />
            </div>
        </div>

        <div class="grid-container">
            <portal-grid
                class="portal-grid-scroll-auto highlighted-portal-grid"
                [data]="gridData"
                [query]="request"
                [selectable]="false"
                [filterable]="false"
                (dataStateChange)="onDataStateChange($event)"
            >
                <portal-grid-column
                    title="Name"
                    field="name"
                    [style]="{ width: '40%' }"
                    [headerStyle]="{ width: '40%' }"
                >
                    <ng-template #cell let-dataItem="dataItem">
                        <span
                            (click)="onDownloadFile(dataItem)"
                            class="file-name"
                            >{{ dataItem.file.name }}</span
                        >
                    </ng-template>
                </portal-grid-column>

                <portal-grid-column
                    title="Type"
                    field="type"
                    [style]="{ width: '30%' }"
                    [headerStyle]="{ width: '30%' }"
                >
                    <ng-template #cell let-dataItem="dataItem">
                        {{ dataItem.fileType.type }}
                    </ng-template>
                </portal-grid-column>

                <portal-grid-column
                    title="Size"
                    field="size"
                    [style]="{ width: '30%' }"
                    [headerStyle]="{ width: '30%' }"
                >
                    <ng-template #cell let-dataItem="dataItem">
                        {{ dataItem.file.size | byteFormatter }}
                    </ng-template>
                </portal-grid-column>

                <portal-grid-column title="">
                    <ng-template #cell let-dataItem="dataItem">
                        <button
                            (click)="onDeleteFile(dataItem)"
                            mat-mini-fab
                            color="warn"
                            [disabled]="isDeleting$ | async"
                            aria-label="Delete file"
                            class="delete-btn"
                            [class.spinner]="
                                deletingFileIds.indexOf(dataItem.fileId) > -1
                            "
                        >
                            <mat-icon class="delete-btn-icon">delete</mat-icon>
                        </button>
                    </ng-template>
                </portal-grid-column>
            </portal-grid>
        </div>
    </mat-card-content>
</mat-card>
