import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { TravelerPostponeExtensionResult } from 'src/app/shared/models/travelers/traveler-postpone-extension-result.model';
import * as ExtensionActions from 'src/app/travelers/state/actions/travelers-extension.actions';

export interface ExtensionPostponeState {
    postponeResult: TravelerPostponeExtensionResult;
    error: string;
    loading: LoadingTypes;
}

const initialState: ExtensionPostponeState = {
    postponeResult: null,
    error: '',
    loading: LoadingTypes.INIT
};

export function extensionPostponeReducer(state: ExtensionPostponeState, action: Action) {
    return createReducer(
        initialState,
        on(ExtensionActions.postponeExtensionReminder, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(ExtensionActions.postponeExtensionReminderSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,
            postponeResult: action.postponeResult
        })),

        on(ExtensionActions.postponeExtensionReminderFail, (state, action) => ({
            ...state,
            error: action.error,
            postponeResult: null,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: ExtensionPostponeState | undefined, action: Action) {
    return extensionPostponeReducer(state, action);
}
