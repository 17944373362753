import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';
import { PerdiemSchedulerService } from '../../services/perdiemscheduler.service';
import { loadDefaultDateFrom, loadDefaultDateFromSuccess } from '../perdiemscheduler.actions';
@Injectable()
export class PerdiemSchedulerDefaultDateFromEffect {
    getPerdiemSchedulerDefaultDateFrom$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDefaultDateFrom),
            switchMap((action) => {
                return this.shiftRepository.getPerdiemSchedulerDefaultDateFrom().pipe(
                    map((response) => {
                        return loadDefaultDateFromSuccess({ minDate: response });
                    })
                );
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly shiftRepository: PerdiemSchedulerService
    ) {}
}
