export function interpolateUrl(url: string, params: any): string {
    const newUrl = Object.keys(params)
        .filter((key) => key != null)
        .reduce((newUrl, key: string) => {
            return newUrl.replace('{' + key + '}', (params[key] || '').toString());
        }, url);

    if (/{[^}]*}/.test(newUrl)) {
        throw new Error(`Missed interpolated params in url \n    ${url}`);
    }
    return newUrl;
}
