import { GridSearchQuery } from 'src/app/shared/grid/models/grid-search-query.model';
import { Action, createReducer, on } from '@ngrx/store';
import { PermJobGridActions } from '../actions';
import { SortTypes } from 'src/app/shared/models';
import { PermJobsSortTypes } from 'src/app/permanent-jobs/models/perm-jobs-sort-types.enum';

export type PermJobsGridQueryState = GridSearchQuery;

export const initialState: PermJobsGridQueryState = {
    sort: [{ field: PermJobsSortTypes.DateAdded, dir: SortTypes.DESC }],
    take: 10,
    skip: 0,
    filter: {
        logic: 'and',
        filters: []
    }
};

type SetQueryAndGetPermJobsActionType = ReturnType<typeof PermJobGridActions.setQueryAndGetPermJobs>;

export const permJobsQueryReducer = createReducer(
    initialState,
    on(
        PermJobGridActions.setQueryAndGetPermJobs,
        (state, action: SetQueryAndGetPermJobsActionType): PermJobsGridQueryState => {
            if (action.query === undefined) {
                return state;
            }
            return {
                ...state,
                ...action.query
            };
        }
    )
);

export function reducer(state: PermJobsGridQueryState | undefined, action: Action) {
    return permJobsQueryReducer(state, action);
}
