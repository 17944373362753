export enum DomainConfigurationKeys {
    AGENCY_QUESTIONS_EMAIL = "AGENCY_QUESTIONS_EMAIL",
    CLIENT_MENU_NAME = "CLIENT_MENU_NAME",
    CLINICAL_MENU_NAME = "CLINICAL_MENU_NAME",
    CONNECT_URL = "CONNECT_URL",
    DEV_CORE_URL = "DEV_CORE_URL",
    DEV_SIGN_OUT_URL = "DEV_SIGN_OUT_URL",
    ELECTRONIC_CONSENT = "ELECTRONIC_CONSENT",
    EMPLOYMENT_SHOW = "EMPLOYMENT_SHOW",
    FACILITIES_ACCEPTED_ONLY = "FACILITIES_ACCEPTED_ONLY",
    FACILITIES_VENDOR_OFFER = "FACILITIES_VENDOR_OFFER",
    FOOTER_COPY = "FOOTER_COPY",
    FORMS = "FORMS",
    HOT_CORE_URL = "HOT_CORE_URL",
    HOT_SIGN_OUT_URL = "HOT_SIGN_OUT_URL",
    INT_CORE_URL = "INT_CORE_URL",
    INT_SIGN_OUT_URL = "INT_SIGN_OUT_URL",
    LOCAL_CORE_URL = "LOCAL_CORE_URL",
    JOB_VENDOR_BILL = "JOB_VENDOR_BILL",
    NOTE_LABEL = "NOTE_LABEL",
    PORTAL_NAME = "PORTAL_NAME",
    POSITIONS_SHOW = "POSITIONS_SHOW",
    PRIVACY_URL = "PRIVACY_URL",
    PROD_CORE_URL = "PROD_CORE_URL",
    QUESTION_SHOW = "QUESTION_SHOW",
    SHORT_PORTAL_NAME = "SHORT_PORTAL_NAME",
    SIGN_OUT_URL = "SIGN_OUT_URL",
    SPINNER_COLOR = "SPINNER_COLOR",
    STYLES_AND_META = "STYLES_AND_META",
    SUBMITTALS_SUBJECT_TXT = "SUBMITTALS_SUBJECT_TXT",
    SUPPORT_EMAIL = "SUPPORT_EMAIL",
    SUPPORT_PHONE_NUMBER = "SUPPORT_PHONE_NUMBER",
    TERMS_OF_USE_URL = "TERMS_OF_USE_URL",
    TITLE = "TITLE",
    VENDOR_FACILITIES_EMAIL = "VENDOR_FACILITIES_EMAIL",
    VENDOR_MENU_NAME = "VENDOR_MENU_NAME",
    VMS_SEPARATE = "VMS_SEPARATE",
    VMS_SHOW = "VMS_SHOW"
}
