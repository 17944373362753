<ng-container *ngFor="let certification of certifications">
    <strong>{{ certification.name }}</strong>
    <div *ngIf="certification.expirationDate">
        <span>Expiration: <strong>{{ certification.expirationDate | date: 'MM/dd/yyyy' }}</strong></span>
    </div>
    <div *ngIf="certification.level">
        <span>Level: <strong>{{ certification.level }}</strong></span>
    </div>
    <div *ngIf="certification.location">
        <span>Location: <strong>{{ certification.location }}</strong></span>
    </div>
    <div *ngIf="certification.organization">
        <span>Certification Organization: <strong>{{ certification.organization }}</strong></span>
    </div>
    <div *ngIf="certification.ethnicity">
        <span>Ethnicity: <strong>{{ certification.ethnicity }}</strong></span>
    </div><br>
</ng-container>
