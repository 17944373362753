export enum AyaKiddingMeFeatureFlags {
    ConnectClientSubmittalEnableActivity = 'CONNECT_CLIENT_SUBMITTAL_ENABLE_ACTIVITY',
    ConnectClientMessageCenter = 'CONNECT_CLIENT_MESSAGE_CENTER',
    ConnectReportingRedesign = 'CONNECT_CLIENT_REPORTING_REDESIGN',
    LotusOneFlag = 'lotusone-url-references',
    ConnectAdminRoleRename = 'connect-admin-role-rename',
    VmsOfferCommentsTitleFormat = 'vms-offer-comments-title-format',
    ConnectClientSubmittalRemovedFromLiveList = 'connect-client-submittal-removed-from-live-list',
    ConnectUnifySubmittalDetailsChip = 'connect-unify-submittal-details-chip',
    LotusOneUnitComplete = 'lotus-one-unit-complete',
    LotusOneNewHomepage = 'lotus-one-new-homepage',
    LotusOneWorkersAddProfessionColumn = 'lotusone-workers-add-profession-column',
    JobsApprovalMultiSelect = 'jobs-approval-multiselect',
    LotusOneAddLaborTypeDropdown = 'lotusone-add-labor-type-dropdown',
    LotusOneLineOfBusinessUnitAccessFix = 'lotusone-line-of-business-unit-access-fix'
}
