<mat-card data-qa-id="UsernameToEmailWarning" class="toaster-fail" *ngIf="userNameToEmailWarning">
    <mat-card-content>Username '{{originalUsername}}' will be changed to email '{{contactProfileForm.get('emailAddress')?.value}}' on saving</mat-card-content>
</mat-card>
<form class="k-form-inline contact-form" [formGroup]="contactProfileForm">

    <div class="form-row" *ngIf="systemsOrClients && systemsOrClients?.length">
        <span class="form-label" [attr.aria-label]="title">{{ title }} <span class="k-required">*</span></span>
        <aya-input-dropdown controlName="systemOrClient"
            [placeholder]="'Select or search a ' + title + ' from the list…'" [requiredMessage]="title + ' is required'"
            [options]="systemsOrClients">
        </aya-input-dropdown>
    </div>

    <div class="form-row" *ngIf="contact.user?.isActive && !isSystemContact">
        <span class="form-label">Client Facilities: <span class="k-required">*</span></span>
        <ayac-chips-autocomplete listControl="facilities" [chipListItems]="facilities" inputControl="facilityInput"
            chipTitle="facility">
        </ayac-chips-autocomplete>
    </div>

    <div class="form-row">
        <span class="form-label" aria-label="Contact first name">First Name: <span class="k-required">*</span></span>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="firstName" placeholder="First name" />
            <mat-error *ngIf="contactProfileForm.get('firstName').hasError('required')">
                Please enter a first name.
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-row">
        <span class="form-label">
            Last Name: <span class="k-required">*</span>
        </span>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="lastName" placeholder="Last name" />
            <mat-error *ngIf="contactProfileForm.get('lastName').hasError('required')">
                Please enter a last name.
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-row">
        <span class="form-label">
            Email Address: <span *ngIf="contactProfileForm.get('portalSignIn').value" class="k-required">*</span>
        </span>
        <mat-form-field appearance="outline">
            <input matInput type="text" formControlName="emailAddress" placeholder="Email Address" (focusout)="refreshPossibleParentLinkedProfiles(true)" />
            <mat-error *ngIf="contactProfileForm.get('emailAddress').hasError('required')">
                Please enter an email address.
            </mat-error>
            <mat-error *ngIf="contactProfileForm.get('emailAddress').hasError('email')">
                Please enter an valid email address.
            </mat-error>
        </mat-form-field>
        <mat-checkbox class="p8" formControlName="showEmailExternally" placeholder="Show Externally">Show
            Externally</mat-checkbox>
    </div>

    <div class="form-row">
        <span class="form-label">
            Phone:
        </span>
        <mat-form-field appearance="outline">
            <input matInput type="tel" formControlName="phone" [specialCharacters]="['(',')',' ','-','e','x','t','.']"
                mask="(000) 000-0000||(000) 000-0000 ext. 9999" />
            <mat-error *ngIf="contactProfileForm.get('phone').hasError('required')">
                Please enter a phone number.
            </mat-error>
            <mat-error *ngIf="contactProfileForm.get('phone').hasError('mask')">
                Not a valid phone number.
            </mat-error>
        </mat-form-field>
    </div>


    <div class="form-row">
        <span class="form-label">Tags:</span>
        <ayac-chips-autocomplete listControl="tags" [chipListItems]="contactTypeTags" inputControl="tagInput"
            chipTitle="Tag">
        </ayac-chips-autocomplete>
    </div>

    <div class="form-row">
        <span class="form-label">Portal Sign-In: </span>
        <mat-button-toggle-group name="portalSignIn" aria-label="Add or remove user portal sign in"
            formControlName="portalSignIn">
            <mat-button-toggle [value]="true" (click)="addUser()">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false" (click)="removeUser()">No</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div class="form-column" *ngIf="contactProfileForm.get('user') && contactProfileForm.get('portalSignIn')?.value"
        formGroupName="user">
        <div class="form-row-sso">
            <span class="indent form-label">SSO User: </span>
            <mat-button-toggle-group name="ssoUser" aria-label="Add or remove user portal SSO"
                formControlName="ssoUser">
                <mat-button-toggle [value]="true" (click)="addSSO()">Yes</mat-button-toggle>
                <mat-button-toggle [value]="false" (click)="removeSSO()">No</mat-button-toggle>
            </mat-button-toggle-group>
            <ng-container>
                <div class="form-row"
                    *ngIf="hasSso">
                    <span class="indent sso-role-label">SSO: <span class="k-required">*</span></span>
                    <mat-form-field class="p8" appearance="outline">
                        <mat-select formControlName="identityProvider">
                            <mat-option>Select one</mat-option>
                            <mat-option *ngFor="let identityProvider of identityProviders" [value]="identityProvider.name">
                                {{identityProvider.name}} {{identityProvider.description ? '(' + identityProvider.description + ')': null }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
        <div class="form-row">
            <span class="indent form-label">Username<span *ngIf="!hasParentProfile || hasSso"> (from email)</span>: <span class="k-required">*</span></span>
            <mat-form-field appearance="outline"
                [class.multiple-errors]="contactProfileForm.get('user')?.get('username').hasError('usernameUnavailable')">
                <input data-qa-id="ContactUserName" matInput id="username-input" type="text" formControlName="username" placeholder="{{!hasParentProfile || hasSso ? 'Will be sourced from user\'s Email' : 'Username'}}" [readonly]="!hasParentProfile || hasSso" />
                <mat-error *ngIf="contactProfileForm.get('user').get('username').hasError('required')">
                    User name cannot be empty.
                </mat-error>
                <mat-error *ngIf="contactProfileForm.get('user').get('username').hasError('hasWhitespaces')">
                    {{ contactProfileForm.get('user').get('username').errors['hasWhitespaces']}}
                </mat-error>
                <mat-error *ngIf="contactProfileForm.get('user').get('username').hasError('usernameUnavailable')">
                    This user name is not available.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row" *ngIf="showPasswordField">
            <span class="indent form-label">Password: <span class="k-required">*</span></span>
            <mat-form-field appearance="outline">
                <input data-qa-id="ContactPassword" matInput type="text" formControlName="password" placeholder="Password" />
                <mat-error *ngIf="contactProfileForm.get('user')?.get('password')?.hasError('required')">
                    Please enter a password.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row" *ngIf="canManageLinkedProfiles && !hasSso">
            <span class="indent form-label">Link to parent profile:</span>
            <button data-qa-id="LinkedToParentCloseButton" *ngIf="hasParentProfile"
                mat-icon-button color="accent" (click)="parentProfileChanged()"
                name="linkedToParentButton" aria-label="Link user to parent profile">
                {{linkedToParentProfile?.name}} <mat-icon [inline]="true">close</mat-icon>
            </button>
            <mat-form-field *ngIf="!hasParentProfile" floatLabel="never" appearance="outline">
                <input data-qa-id="LinkedToParentSelectEdit"
                        (input)="searchProfilesText$.next($event.target.value)" matInput type="text"
                        placeholder="Select or search the parent profile from the list..."
                        [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true" (optionSelected)="parentProfileChanged($event.option)">
                    <mat-option *ngFor="let profile of (filteredProfiles$ | async)" [value]="profile.name" [id]="profile.id" [attr.data-qa-id]="'LinkedToParentSelectOption-' + profile.id">
                        {{ profile.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="form-row" *ngIf="!canManageLinkedProfiles && hasParentProfile && !hasSso">
            <span class="indent form-label">Link to parent profile:</span>
            <span data-qa-id="LinkedToParentSelectView" class="parent-linked-profile-label">{{linkedToParentProfile?.name}}</span>
        </div>
        <div class="form-row" *ngIf="contact.user?.coreUserId">
            <span class="indent form-label"></span>
            <button data-qa-id="ContactImpersonate" *ngIf="canImpersonate" (click)="impersonate()" class="impersonate-button" mat-raised-button color="accent">
                <span class="fa fa-user"></span>&nbsp;
                Impersonate
            </button>
        </div>
        <div class="form-row">
            <span class="form-label">Role: </span>
            <aya-input-dropdown controlName="role" data-qa-id="SystemClientContactsSearchRole"
                                placeholder="Select or search role from the list"
                                [options]="roles">
            </aya-input-dropdown>
        </div>
    </div>

    <div class="form-row">
        <span class="form-label">Note: </span>
        <mat-form-field class="left-field" appearance="outline">
            <textarea matInput rows="5" type="text" formControlName="note"></textarea>
        </mat-form-field>
    </div>
</form>
