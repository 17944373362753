import { createAction, props } from '@ngrx/store';
import { SystemContactType } from 'src/app/admin/system-contacts/models/system-contact-type.model';
import { SystemContact } from 'src/app/admin/system-contacts/models/system-contact.model';
import { ListItem } from 'src/app/shared/models/list-item';
import { IdentityProvider } from 'src/app/shared/models/sso/identity-provider.model';

export const createNewSystemContactAndLoadRelatedData = createAction(
    `[SYSTEM CONTACTS PROFILE COMPONENT] Create New System Contacts Profile`
);

export const loadSystemContactAndRelatedData = createAction(
    '[SYSTEM CONTACTS PROFILE COMPONENT] Load System Contacts Profile By systemContact Id And Related Data',
    props<{ systemContactId: number }>()
);

export const loadSystemContactAndRelatedDataSuccess = createAction(
    '[SYSTEM CONTACTS PROFILE API] Load System Contacts Profile By SystemContacts Id And Related Data Success',
    props<{
        systemContact: SystemContact;
        hospitalSystems: ListItem[];
        systemContactTypes: SystemContactType[];
        identityProviders: IdentityProvider[];
    }>()
);

export const loadSystemContactAndRelatedDataFailure = createAction(
    '[SYSTEM CONTACTS PROFILE API] Load System Contacts Profile By SystemContacts Id And Related Data Failure',
    props<{ error: unknown }>()
);

export const updateSystemContactsProfile = createAction(
    '[SYSTEM CONTACTS PROFILE COMPONENT] Update System Contacts Profile',
    props<{ systemContact: SystemContact }>()
);

export const updateSystemContactsProfileSuccess = createAction(
    '[SYSTEM CONTACTS PROFILE API] Update System Contacts Profile Successfully',
    props<{ systemContact: SystemContact }>()
);

export const updateSystemContactsProfileFailure = createAction(
    '[SYSTEM CONTACTS PROFILE API] Update System Contacts Profile Failure',
    props<{ error: unknown }>()
);

export const saveSystemContactsProfile = createAction(
    '[SYSTEM CONTACTS PROFILE COMPONENT] Save SystemContacts Profile'
);

export const saveSystemContactsProfileSuccess = createAction(
    '[SYSTEM CONTACTS PROFILE API] Save SystemContacts Profile Successfully',
    props<{ systemContactId: number | null; contact: SystemContact }>()
);

export const saveSystemContactsProfileFailure = createAction(
    '[SYSTEM CONTACTS PROFILE API] Save SystemContacts Profile Failure',
    props<{ error: unknown; contact: SystemContact }>()
);
