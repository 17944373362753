import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Inject } from '@angular/core';
import { MenuItemDirective } from './menu-item.directive';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { UnsubscribeOnDestroy } from '../utils';
import { Router } from '@angular/router';
import { APP_CONFIG, Settings } from 'src/app/config/settings';

@Component({
    selector: 'ayac-nav-menu-link',
    templateUrl: './nav-menu-link.component.html',
    styleUrls: ['./nav-menu-link.component.scss']
})
export class NavMenuLinkComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @Input() isActive = false;
    @Input() linkData: MenuItemDirective;
    @Input() popupPosition = 'below';
    @Input() isExpanded: boolean;
    @Output() moduleClick = new EventEmitter<string>();
    showHoverMenu: boolean;
    hoverMenuActive = false;
    hoveredModule: any;
    linkOptions = { exact: true };
    featureFlag = FeatureFlag;

    constructor(
        private readonly router: Router,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {
        super();
    }

    ngOnInit() {}

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    onMouseOverModule(hover: boolean) {
        if (hover) {
            this.showHoverMenu = hover;
        } else {
            setTimeout(() => {
                if (!this.hoverMenuActive) {
                    this.showHoverMenu = this.hoverMenuActive;
                }
            }, 1);
        }
    }

    keepActive(active: boolean) {
        this.hoverMenuActive = active;
        this.onMouseOverModule(active);
    }

    handleModuleClick() {
        this.keepActive(!this.showHoverMenu);
    }

    onItemClick(item) {
        this.keepActive(false);
    }

    onItemClick2(linkData: MenuItemDirective) {
        if (linkData.title === 'Flexwise') {
            window.open(this._settings.FLEXWISE_URL, '_blank');
        } else if (linkData.title === 'Polaris') {
            window.open(this._settings.POLARIS_URL, '_blank');
        } else if (linkData.title === 'DocCafe') {
            window.open(this._settings.DOCCAFE_URL, '_blank');
        } else {
            this.router.navigate([linkData.path]);
        }
    }
}
