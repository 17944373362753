<div class="worker-field-template">
    <ng-container *ngIf="!editMode">
        <div class="worker-display">
            <div class="field-title">{{ to.label }}
                <ng-container *ngIf="field.props.required">
                    <span class="k-required">*</span>
                </ng-container>
                <formly-help-text [helpText]="props.helpText"></formly-help-text>
            </div>
            <div class="field-value">
                {{ model[field.key]['value'] || 'N/A' }}
                <button mat-icon-button color="accent" (click)="toggleEditMode()">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="editMode">
        <label class="k-form-field">
            <span>{{ to.label }}
                <ng-container *ngIf="field.props.required">
                    <span class="k-required">*</span>
                </ng-container>
                <formly-help-text [helpText]="props.helpText"></formly-help-text>
            </span>
            <div>
                <kendo-dropdownlist class="formly-kendo-dropdown" [formControl]="formControl" [data]="data$ | async"
                    valueField="value" textField="label" [loading]="isLoading" [filterable]="true"
                    (filterChange)="handleFilter($event)">
                </kendo-dropdownlist>
                <button [disabled]="formControl.invalid" mat-icon-button color="accent" (click)="onSubmit()">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </label>
        <validation-message class="validation-message" [label]="to.label" [control]="formControl">
        </validation-message>
    </ng-container>
</div>
