<form [formGroup]="form">
    <mat-accordion class="profile-tab-accordion">
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: form?.get('profile')?.invalid }"
            >
                <mat-panel-title>
                    Candidate Profile
                    <mat-icon
                        class="error-icon"
                        *ngIf="form?.get('profile')?.invalid"
                        >error</mat-icon
                    >
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-candidate-profile-form
                    [vendorContacts]="vendorContacts$ | async"
                    [candidateId]="candidateId"
                    [additionalValidation]="additionalValidation"
                    [otherInfo]="otherCandidateInfo$ | async"
                    (onChangeMade)="onValueChanged()"
                    (getSsnValue)="getOtherInfo()"
                ></ayac-candidate-profile-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: phonesForm?.formArray?.invalid }"
            >
                <mat-panel-title>
                    Phones
                    <mat-icon
                        class="error-icon"
                        *ngIf="phonesForm?.formArray?.invalid"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-phones-form
                    [candidateId]="candidateId"
                    [additionalValidation]="additionalValidation"
                    (onChangeMade)="onPhoneValueChanged()"
                    [phoneTypes$]="phoneTypes$"
                ></ayac-phones-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: form.get('expertise')?.invalid }"
            >
                <mat-panel-title>
                    Specialty
                    <mat-icon
                        class="error-icon"
                        *ngIf="form.get('expertise')?.invalid"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-expertise-form
                    (onChangeMade)="onSpecialtyValueChanged()"
                    [candidateId]="candidateId"
                ></ayac-expertise-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: form.get('certifications')?.invalid }"
            >
                <mat-panel-title>
                    Certifications
                    <mat-icon
                        class="error-icon"
                        *ngIf="form.get('certifications')?.invalid"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-certifications-form
                    (onChangeMade)="onCertificationsValueChanged()"
                    [candidateId]="candidateId"
                    [certifications]="certifications$ | async"
                ></ayac-certifications-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: form.get('backgroundHistory')?.invalid }"
            >
                <mat-panel-title>
                    Background History
                    <mat-icon
                        class="error-icon"
                        *ngIf="form.get('backgroundHistory')?.invalid"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">
                <ayac-background-history-form
                    (onChangeMade)="onBackgroundValueChanged()"
                    [candidateId]="candidateId"
                ></ayac-background-history-form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="profile-tab-expansion-panel">
            <mat-expansion-panel-header
                [ngClass]="{ error: form.get('licenses')?.invalid || !isNpiValid && userChangedNpi }"
            >

                <mat-panel-title>
                    Licensure
                    <mat-icon
                        class="error-icon"
                        *ngIf="form.get('licenses')?.invalid || !isNpiValid && userChangedNpi"
                        >error</mat-icon
                    ></mat-panel-title
                >
            </mat-expansion-panel-header>
            <div class="container-content">

                <ayac-licenses-form
                    (onChangeMade)="onLicensureValueChanged()"
                    (npiValidityChanged)="onNpiValidityChanged($event)"
                    (showNpiNotClearedBanner)="npiNameNotClearedChanged($event)"
                    (userChangedNpiChange)="onUserChangedNpiChanged($event)"
                    [candidateId]="candidateId"
                    [enableNpiRequiredValidation]="npiRequired"
                    [jobId]="jobId"
                    [vendorId]="vendorId"
                ></ayac-licenses-form>
            </div>
        </mat-expansion-panel>

        <ng-container>
            <mat-expansion-panel class="profile-tab-expansion-panel"
                *ngIf="hasAccessShiftsApp$ | async">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Shifts App
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="container-content">
                    <ayac-candidate-shifts-app *ngIf="!isNew"
                        [candidateId]="candidateId"
                        (onChangeMade)="onValueChanged()">
                    </ayac-candidate-shifts-app>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</form>
