import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { DashboardService } from '../../services/dashboard.service';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as dashboardActions from '../actions';
import { Store } from '@ngrx/store';
import { getFilter, getSubmittalsAgingHours } from '../selectors';

@Injectable()
export class JobsEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{}>,
        private readonly service: DashboardService
    ) {}

    getJobsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardActions.loadJobsCount),
            withLatestFrom(this.store.select(getFilter), this.store.select(getSubmittalsAgingHours)),
            switchMap(([action, query, submittalsAgingHours]) => {
                const filter = {
                    facilityId: query.facility.id > 0 ? query.facility.id : null,
                    facilityProfileGroupId: query.facility.profileGroupId > 0 ? query.facility.profileGroupId : null,
                    unitId: query.unit.id > 0 ? query.unit.id : null,
                    shiftTypeId: query.shiftType.id > 0 ? query.shiftType.id : null,
                    lineOfBusinessId: query.lineOfBusiness.id > 0 ? query.lineOfBusiness.id : null,
                    submittalsAgingHours: submittalsAgingHours
                };
                return this.service.getJobsCount(filter).pipe(
                    map((response) =>
                        dashboardActions.loadJobsCountSuccess({
                            count: response
                        })
                    ),
                    catchError((error) => of(dashboardActions.loadJobsCountFail({ error })))
                );
            })
        )
    );
}
