
<section class="migrated-section-container">
    <fieldset>
        <legend>Locations</legend>

        <div>
            <form class="container" [formGroup]="locationsForm">
                <mat-accordion  *ngIf="vendorLocations$ | async" formArrayName="locations">
                    <ng-container
                        *ngFor="
                            let locationForm of locationsFormArray.controls;
                            let i = index
                        "
                        [formGroupName]="i"
                    >
                        <mat-expansion-panel
                            class="phones-expansion-panel mat-elevation-z0"
                            [formGroup]="locationForm"
                            [attr.data-qa-id]="'locationExpansionPanel' + (i + 1)"
                        >
                        
                            <mat-expansion-panel-header class="phone-header">
                                <mat-panel-title>
                                    {{getLocationTypeName(locationsFormArray.at(i).get('vendorLocationTypeId').value )}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class="container-content">
                                <input  hidden formControlName="id">
                                <div
                                class="row-flex-container"
                                >
                                    <div class="flex25">
                                        <label class="control-label p12">
                                            Type: <span class="k-required">*</span>
                                        </label>
                                    </div>
                                    <div class="flex75">
                                        <mat-form-field
                                            class="w-100"
                                            appearance="outline"
                                        >
                                            <mat-select
                                                formControlName="vendorLocationTypeId"
                                            >
                                                <mat-option
                                                    *ngFor="let locationType of locationTypes$ | async"
                                                    [value]="locationType.id"
                                                >
                                                    {{ locationType.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div
                                class="row-flex-container"
                                >
                                    <div class="flex25">
                                        <label class="control-label p12">
                                            Address1: <span class="k-required">*</span>
                                        </label>
                                    </div>
                                    <div class="flex75">
                                        <mat-form-field
                                            class="w-100"
                                            appearance="outline"
                                        >
                                        <input matInput formControlName="address1" placeholder="Address1">
                                        <mat-error *ngIf="locationsFormArray.at(i).get('address1').hasError('required')">
                                            Please enter an address.
                                        </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div
                                class="row-flex-container"
                                >
                                    <div class="flex25">
                                        <label class="control-label p12">
                                            Address2:
                                        </label>
                                    </div>
                                    <div class="flex75">
                                        <mat-form-field
                                            class="w-100"
                                            appearance="outline"
                                        >
                                        <input matInput formControlName="address2" placeholder="Address2">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div
                                class="row-flex-container"
                                >
                                    <div class="flex25">
                                        <label class="control-label p12">
                                            City: <span class="k-required">*</span>
                                        </label>
                                    </div>
                                    <div class="flex75">
                                        <mat-form-field
                                            class="w-100"
                                            appearance="outline"
                                        >
                                        <input matInput formControlName="city" placeholder="City">
                                        <mat-error *ngIf="locationsFormArray.at(i).get('city').hasError('required')">
                                            Please enter a city.
                                        </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div
                                class="row-flex-container"
                                >
                                    <div class="flex25">
                                        <label class="control-label p12">
                                            State: <span class="k-required">*</span>
                                        </label>
                                    </div>
                                    <div class="flex75">
                                        <mat-form-field
                                            class="w-100"
                                            appearance="outline"
                                        >
                                            <mat-select
                                                formControlName="stateId"
                                            >
                                                <mat-option
                                                    *ngFor="let state of statesLookup$ | async"
                                                    [value]="state.id"
                                                >
                                                    {{ state.name }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="locationsFormArray.at(i).get('stateId').hasError('required')">
                                                Please select state.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div
                                class="row-flex-container"
                                >
                                    <div class="flex25">
                                        <label class="control-label p12">
                                            Zip: <span class="k-required">*</span>
                                        </label>
                                    </div>
                                    <div class="flex75">
                                        <mat-form-field
                                            class="w-100"
                                            appearance="outline"
                                        >
                                        <input matInput formControlName="zipCode" placeholder="Zip">
                                        <mat-error *ngIf="locationsFormArray.at(i).get('zipCode').hasError('pattern')">Zipcode has to be 5 characters
                                            long.</mat-error>
                                        <mat-error *ngIf="locationsFormArray.at(i).get('zipCode').hasError('required')">
                                            Please enter a zipcode.
                                        </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div
                                class="row-flex-container"
                                >
                                    <div class="flex25">
                                        <label class="control-label p12">
                                            Note:
                                        </label>
                                    </div>
                                    <div class="flex75">
                                        <mat-form-field
                                            class="w-100"
                                            appearance="outline"
                                        >
                                        <textarea matInput formControlName="note" placeholder="Note"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div 
                                class="row-flex-container">
                                    <button
                                        class="delete-button"
                                        mat-icon-button
                                        matTooltip="Remove location"
                                        (click)="removeLocation(i)"
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>

                <button color="accent" 
                        class="vendor-add-button" 
                        matTooltip="Add New Location" 
                        mat-flat-button 
                        (click)="addNewLocation()">
                    <mat-icon>add</mat-icon>
                    Add Location
                </button>
            </form>
        </div>
    </fieldset>
</section>

