import { Compiler, Injectable, Injector, NgModuleFactory } from '@angular/core';
import { adminModuleRoutes } from 'src/app/admin/admin-routing.module';

@Injectable()
export class LoaderService {
    constructor(
        private readonly injector: Injector,
        private readonly compiler: Compiler
    ) {}

    async loadClientModule(): Promise<Injector> {
        const clientModule = await import('src/app/client/client.module').then((m) => m.ClientModule);
        const moduleFactory =
            clientModule instanceof NgModuleFactory
                ? // For AOT
                  clientModule
                : // For JIT
                  await this.compiler.compileModuleAsync(clientModule);
        return moduleFactory.create(this.injector).injector;
    }

    async loadFacilitiesModule(): Promise<Injector> {
        const adminFacilitiesFactory = adminModuleRoutes.find((x) => x.path === 'facilities')
            .loadChildren as () => Promise<any>;
        const adminFacilitiesModule = await adminFacilitiesFactory();
        const moduleFactory =
            adminFacilitiesModule instanceof NgModuleFactory
                ? // For AOT
                  adminFacilitiesModule
                : // For JIT
                  await this.compiler.compileModuleAsync(adminFacilitiesModule);
        return moduleFactory.create(this.injector).injector;
    }

    async loadAdminModule(): Promise<Injector> {
        const adminModule = await import('src/app/admin/admin.module').then((m) => m.AdminModule);
        const moduleFactory =
            adminModule instanceof NgModuleFactory
                ? // For AOT
                  adminModule
                : // For JIT
                  await this.compiler.compileModuleAsync(adminModule);
        return moduleFactory.create(this.injector).injector;
    }

    async loadClinicalModule(): Promise<Injector> {
        const clinicalModule = await import('src/app/clinical/clinical.module').then((m) => m.ClinicalModule);
        const moduleFactory =
            clinicalModule instanceof NgModuleFactory
                ? // For AOT
                  clinicalModule
                : // For JIT
                  await this.compiler.compileModuleAsync(clinicalModule);
        return moduleFactory.create(this.injector).injector;
    }
}
