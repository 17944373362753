import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';

@Injectable({
    providedIn: 'root'
})
export class VendorInsuranceService {
    constructor(
        private readonly _httpClient: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {}

    exportVendorInsurance(vendorId: number) {
        return this._httpClient.get(`${this._settings.CORE}/AyaConnect/Vendors/Insurance/Export/Excel/${vendorId}`, {
            responseType: 'blob'
        });
    }
}
