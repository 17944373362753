import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as TravelerDetailsActions from 'src/app/travelers/state/actions/travelers-details.actions';
import * as actions from 'src/app/shared/components/custom-form/custom-form.actions';

@Injectable()
export class CustomFormEffect {
    constructor(private actions$: Actions) {}

    refreshWorkerValues$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TravelerDetailsActions.travelerJobSaved),
            map((_) => actions.refreshWorkerValues())
        );
    });
}
