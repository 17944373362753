import { Observable } from 'rxjs';

export function readBlob(blob: Blob): Observable<string>;
export function readBlob(blob: Blob, readAs: 'dataUrl'): Observable<string>;
export function readBlob(blob: Blob, readAs: 'arrayBuffer'): Observable<ArrayBuffer>;
export function readBlob(blob: Blob, readAs: 'binaryString'): Observable<ArrayBuffer>;
export function readBlob(blob: Blob, readAs: 'text'): Observable<string>;

export function readBlob(
    blob: Blob,
    readAs: 'arrayBuffer' | 'binaryString' | 'text' | 'dataUrl' = 'dataUrl'
): Observable<string | ArrayBuffer> {
    return new Observable((subscriber) => {
        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                subscriber.next(reader.result);
                subscriber.complete();
            },
            false
        );

        if (blob) {
            switch (readAs) {
                case 'binaryString':
                    reader.readAsBinaryString(blob);
                    break;
                case 'arrayBuffer':
                    reader.readAsArrayBuffer(blob);
                    break;
                case 'text':
                    reader.readAsText(blob);
                    break;
                case 'dataUrl':
                default:
                    reader.readAsDataURL(blob);
                    break;
            }
        }
    });
}
