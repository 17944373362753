<div [formGroup]="form">
    <mat-radio-group
        formControlName="scheduleType"
        (change)="onScheduleTypeChanged($event)">
        <div class="mb-3">

            <mat-radio-button [value]="scheduleTypes.settings"
                [attr.data-qa-id]="'UseShiftSettingsRadioButton'">
                Use shift settings for release preferences
            </mat-radio-button>

            <div class="checkbox-caption" *ngIf="scheduleType === scheduleTypes.settings">
                <div *ngIf="hasPastDates" class="mr-4 mb-2">
                    <div class="banner banner-accent-light text-left">
                        <div class="d-flex align-items-center">
                            <mat-icon class="mr-2 flex-no-shrink">info</mat-icon>
                            <ng-container
                                *ngIf="settings?.length > 1;
                                then pastDatesMultiMessage;
                                else pastDatesSingleMessage">
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div *ngFor="let settingFormat of globalSettingsFormat">
                    {{settingFormat}}
                    {{ bonusAssignmentPermission && bonusSelected && settingFormat.includes('Agency')
                        ? '(shift bonuses will not apply)' : '' }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <mat-radio-button [value]="scheduleTypes.internal"
                [attr.data-qa-id]="'ReleaseImmediatelyToInternalRadioButton'">
                Release immediately to Internal (Core and Float)
            </mat-radio-button>
            <ng-container *ngIf="scheduleType == scheduleTypes.internal">
                <div class="checkbox-caption">
                    <ng-container *ngIf="allowBookCandidate">
                        <a class="link-color" (click)="$event.preventDefault(); toggleShowCandidates();">
                            Book a Worker (Optional)
                        </a>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <div>
              <mat-radio-button data-testid="radio_button_visibility_agency" [value]="scheduleTypes.agency" >
                Release immediately to Agency
            </mat-radio-button>
        </div>
        <span *ngIf="bonusAssignmentPermission && bonusSelected" class="ml-4 d-flex align-items-center">
            <mat-icon class="material-icons-outlined color-neutral mr-1">warning_amber</mat-icon>
            If a bonus is currently set, selecting Agency will remove the bonus you have added.
        </span>
    </mat-radio-group>
</div>

<ng-template #pastDatesSingleMessage>
    The shift starts before the release preferences defined in global shift settings. This shift will release immediately to {{releaseImmediatelyFormat}}.
</ng-template>

<ng-template #pastDatesMultiMessage>
    One or more shift starts before the release preferences defined in global shift settings. These shifts will release immediately to {{releaseImmediatelyFormat}}.
</ng-template>
