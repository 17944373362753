import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, Settings } from '../../config/settings';
import { Observable, firstValueFrom, map, of } from 'rxjs';
import { CustomSystemFieldLookup } from '../../core/models/system-custom-field.model';
import { interpolateUrl } from '../../core/utils';
import { ContractGroupDefinition } from '../models/custom-fields/contract-group-definition.model';
import { ContractGroupEntityValueDto } from '../models/custom-fields/contract-group-entity-value-dto.model';
import { CustomFieldModule } from '../models/enums/custom-field-module.enum';
import { RichDropdownOption } from '../models/custom-fields/rich-dropdown-option.model';
import { ChildDropdownOption } from '../models/custom-fields/child-dropdown-option.model';
import { DropdownOption } from '../models/custom-fields/dropdown-option.model';
import { CustomFieldValueType } from '../models/custom-fields/custom-field-value-type.enum';
import { RichDropdownOptionsImport } from '../models/custom-fields/rich-dropdown-options-import.model';
import { RichDropdownOptionsImportResponse } from '../models/custom-fields/rich-dropdown-options-import-error.model';

export enum CustomFieldUrls {
    CUSTOM_FIELDS_BY_SYSTEM = '/CustomFields/{systemId}'
}

@Injectable()
export class CustomFieldService {
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings
    ) {}

    getCustomSystemFields(systemId: number): Observable<Array<CustomSystemFieldLookup>> {
        const urlParams = {
            systemId: systemId
        };

        const url = interpolateUrl(CustomFieldUrls.CUSTOM_FIELDS_BY_SYSTEM, urlParams);
        return this.http.get<Array<CustomSystemFieldLookup>>(`${this.settings.CORE}${url}`);
    }

    /**
     * Used on Admin custom fields grid to get all field definitions
     */
    getFieldsByContractGroupId(contractGroupId: number): Observable<ContractGroupDefinition[]> {
        return this.http.get<ContractGroupDefinition[]>(
            `${this.settings.CORE}/ayaconnect/customfields/${contractGroupId}/definitions`
        );
    }

    /**
     * Used on Client, entity module pages to get all custom field values
     * associated with the module and entity (and contract group)
     */
    getValuesByEntity(module: CustomFieldModule, entityId: number): Observable<ContractGroupEntityValueDto[]> {
        return this.http
            .get<
                ContractGroupEntityValueDto[]
            >(`${this.settings.CORE}/ayaconnect/customfields/values/${module}/${entityId}`)
            .pipe(
                map((customFields) => {
                    return customFields.map((customField) => this.addNoneValueToNonRequiredDropdowns(customField));
                })
            );
    }

    /**
     * Used on Client, on entity creation
     */
    getValuesByFacility(module: CustomFieldModule, facilityId: number): Observable<ContractGroupEntityValueDto[]> {
        return this.http
            .get<
                ContractGroupEntityValueDto[]
            >(`${this.settings.CORE}/ayaconnect/customfields/values/${module}/facility/${facilityId}`)
            .pipe(
                map((customFields) => {
                    return customFields.map((customField) => this.addNoneValueToNonRequiredDropdowns(customField));
                })
            );
    }

    /**
     * Used on Admin when editing an existing field to get definition info
     */
    getFieldById(fieldId: number): Observable<ContractGroupDefinition> {
        return this.http.get<ContractGroupDefinition>(
            `${this.settings.CORE}/ayaconnect/customfields/${fieldId}/definition`
        );
    }

    /**
     * Used on Admin when creating/updating a field definition
     */
    saveFieldDefinition(field: ContractGroupDefinition, contractGroupId: number): Observable<number> {
        return this.http.put<number>(
            `${this.settings.CORE}/ayaconnect/customfields/${contractGroupId}/definition`,
            field
        );
    }

    /**
     * Used on Admin to delete a field definition
     */
    deleteFieldDefinition(field: ContractGroupDefinition, contractGroupId: number): Observable<number> {
        return this.http.delete<number>(
            `${this.settings.CORE}/ayaconnect/customfields/${contractGroupId}/definition/${field.id}`
        );
    }

    /**
     * Used on Client when saving values on Entity Module page
     */
    saveValues(
        values: ContractGroupEntityValueDto[],
        contractGroupId: number,
        module: CustomFieldModule,
        entityId: number
    ): Observable<number> {
        this.replaceNoneValueWithEmptyString(values);
        return this.http.put<number>(
            `${this.settings.CORE}/ayaconnect/customfields/${contractGroupId}/values/${module}/${entityId}`,
            values
        );
    }

    /**
     * Used on Admin when editing an existing field.
     * If the field has entity values then we are likely disabling some editing abilities.
     */
    checkFieldHasValues(definitionId: number): Observable<boolean> {
        return this.http.get<boolean>(
            `${this.settings.CORE}/ayaconnect/customfields/definition/values/${definitionId}`
        );
    }

    /**
     * Used for searching rich dropdown options.
     */
    getRichOptions(definitionId: number, searchTerm: string, descriptionOnly?: boolean): Observable<DropdownOption[]> {
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        return this.http
            .get<
                RichDropdownOption[]
            >(`${this.settings.CORE}/ayaconnect/customfields/definition/${definitionId}/rich-options?searchTerm=${encodedSearchTerm}`)
            .pipe(
                map((results) =>
                    results.map((item) => ({
                        value: item.value,
                        label: descriptionOnly ? item.description : `${item.value} - ${item.description}`
                    }))
                )
            );
    }

    /**
     * Used for searching child dropdown options.
     */
    getChildOptions(
        definitionId: number,
        searchTerm: string,
        parentOption: string,
        descriptionOnly?: boolean
    ): Observable<DropdownOption[]> {
        if (parentOption == null) {
            return of([]);
        }
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        const encodedParentOption = encodeURIComponent(parentOption);

        return this.http
            .get<
                ChildDropdownOption[]
            >(`${this.settings.CORE}/ayaconnect/customfields/definition/${definitionId}/child-options?searchTerm=${encodedSearchTerm}&parentOption=${encodedParentOption}`)
            .pipe(
                map((results) =>
                    results.map((item) => {
                        var label = '';
                        if (descriptionOnly) {
                            label = item.childOptionDescription;
                        } else if (item.childOptionDescription) {
                            label = `${item.childOption} - ${item.childOptionDescription}`;
                        } else {
                            label = item.childOption;
                        }
                        return {
                            value: item.childOption,
                            label: label
                        };
                    })
                )
            );
    }

    async importRichDropdownOptions(
        importModel: RichDropdownOptionsImport
    ): Promise<RichDropdownOptionsImportResponse> {
        const formData = new FormData();
        formData.append('contractGroupId', `${importModel.contractGroupId}`);
        formData.append('entityType', `${importModel.entityType}`);
        formData.append('name', importModel.name);
        formData.append('file', importModel.file);
        const response$ = this.http.post<RichDropdownOptionsImportResponse>(
            `${this.settings.CORE}/ayaconnect/customfields/import-richdropdown-options`,
            formData
        );
        const response = await firstValueFrom(response$);
        return response;
    }

    replaceNoneValueWithEmptyString(customFields: ContractGroupEntityValueDto[]) {
        for (const customField of customFields || []) {
            if (
                customField.valueType === CustomFieldValueType.Dropdown &&
                customField.required === false &&
                customField.value === 'None'
            ) {
                customField.value = '';
            }
        }
    }

    addNoneValueToNonRequiredDropdowns(customField: ContractGroupEntityValueDto): ContractGroupEntityValueDto {
        if (
            customField.valueType === CustomFieldValueType.Dropdown &&
            !customField.required &&
            customField.entityType !== CustomFieldModule.JobTemplate
        ) {
            customField.options.unshift('None');
        }
        return customField;
    }
}
