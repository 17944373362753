import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as shiftsActions from '../actions';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { Shift } from 'src/app/shared/models/shifts/shift.model';
import { FilterParams, ShiftFacilityFeature, ViewByTypes } from 'src/app/shared/models/shifts';
import { deepEqual } from 'src/app/core/utils/deep-compare';

export interface ShiftsListState {
    error: string;
    shifts: PagingToken<Shift[]>;
    selectedShift: Shift;
    shiftsLoadStatus: LoadingTypes;
    filterParams: FilterParams;
    shiftFacilityFeatures: ShiftFacilityFeature;
}

export const initialState: ShiftsListState = {
    error: '',
    shifts: null,
    selectedShift: null,
    shiftsLoadStatus: LoadingTypes.INIT,
    filterParams: new FilterParams(new Date(), new Date(), ViewByTypes.Week),
    shiftFacilityFeatures: null
};

const reducer = createReducer(
    initialState,
    on(shiftsActions.loadShiftsLookupsFail, (state, { error }) => ({
        ...state,
        error: error
    })),
    on(shiftsActions.loadShifts, (state) => ({
        ...state,
        shiftsLoadStatus: LoadingTypes.LOADING
    })),
    on(shiftsActions.loadShiftsSuccess, (state, { shifts }) => ({
        ...state,
        shifts: shifts,
        shiftsLoadStatus: LoadingTypes.LOADED,
        error: ''
    })),
    on(shiftsActions.loadShiftsFail, (state, { error }) => ({
        ...state,
        shifts: null,
        shiftsLoadStatus: LoadingTypes.FAIL,
        error: error
    })),
    on(shiftsActions.loadShiftFacilityFeatures, (state) => ({
        ...state,
        shiftsLoadStatus: LoadingTypes.LOADING
    })),
    on(shiftsActions.loadShiftFacilityFeaturesSuccess, (state, { shiftFacilityFeatures }) => ({
        ...state,
        shiftFacilityFeatures: shiftFacilityFeatures,
        shiftsLoadStatus: LoadingTypes.LOADED,
        error: ''
    })),
    on(shiftsActions.loadShiftFacilityFeaturesFail, (state, { error }) => ({
        ...state,
        shiftFacilityFeatures: null,
        shiftsLoadStatus: LoadingTypes.FAIL,
        error: error
    })),
    on(shiftsActions.setShiftsQuery, (state, { filterParams }) => ({
        ...state,
        filterParams: filterParams
    })),
    on(shiftsActions.resetShiftsQuery, (state) => ({
        ...state,
        shifts: null,
        filterParams: { ...initialState.filterParams }
    })),
    on(shiftsActions.setDashboardShiftsSearchQuery, (state, action) => {
        const filterParams = { ...state.filterParams };

        if (Number(action.statusId)) {
            filterParams.statuses = [
                {
                    id: Number(action.statusId),
                    name: action.statusName
                }
            ];
        }
        if (Number(action.facilityId)) {
            filterParams.facilities = [
                {
                    id: Number(action.facilityId),
                    name: action.facilityName
                }
            ];
        } else {
            filterParams.facilities = [
                {
                    id: 0,
                    name: 'ALL'
                }
            ];
        }
        if (Number(action.unitId)) {
            filterParams.units = [
                {
                    id: Number(action.unitId),
                    name: action.unitName
                }
            ];
        }

        if (action.viewByValue && deepEqual(state.filterParams, initialState.filterParams)) {
            filterParams.viewByValue = Number(action.viewByValue);
        }

        if (action.dateTo) {
            filterParams.dateTo = new Date(action.dateTo);
        } else {
            filterParams.dateTo = null;
        }

        if (action.dateFrom) {
            filterParams.dateFrom = new Date(action.dateFrom);
        }

        return {
            ...state,
            filterParams
        };
    }),
    on(shiftsActions.setSelectedShift, (state, { selectedShift }) => ({
        ...state,
        selectedShift: selectedShift
    }))
);

export function shiftsListReducer(state: ShiftsListState | undefined, action: Action) {
    return reducer(state, action);
}
