import { Component, Input, OnInit } from '@angular/core';
import * as PDFObject from 'pdfobject';

@Component({
    selector: 'ayac-documents-viewer',
    templateUrl: './documents-viewer.component.html',
    styleUrls: ['./documents-viewer.component.scss']
})
export class DocumentsViewerComponent implements OnInit {
    @Input() set url(value: string) {
        if (value && value.length && PDFObject) {
            PDFObject.embed(value, '#pdf');
        }
    }

    constructor() {}

    ngOnInit(): void {}
}
