<dialog-layout [title]="data?.title" data-qa-id="ModalDialogContent">
    <ng-container mat-dialog-content *ngIf="data?.isHtml; then htmlContent; else textContent">
    </ng-container>
    <ng-template #htmlContent>
        <div mat-dialog-content class="form-group" [innerHtml]="data?.text"></div>
    </ng-template>

    <ng-template #textContent>
        <div mat-dialog-content class="form-group">
            {{data?.text}}
        </div>
    </ng-template>
    <div mat-dialog-actions>
        <button mat-flat-button data-qa-id="ModalConfirmButton" [color]="confirmButtonColor" type="submit" (click)="close(true)">{{confirmButtonText}}</button>
        <button mat-stroked-button data-qa-id="ModalCancelButton" color="primary" (click)="close()">{{cancelButtonText}}</button>
    </div>
</dialog-layout>
