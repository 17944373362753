import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ControlContainer,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    FormGroupDirective,
    Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ListItem } from 'src/app/shared/models/list-item';
import { Phone } from 'src/app/shared/models/candidate/phone.model';

@Component({
    selector: 'ayac-phones-form',
    templateUrl: './phone-form.component.html',
    styleUrls: ['./phone-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class PhoneFormComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() formGroupName = 'phones';
    @Input() countryCode = '1';
    @Input() candidateId?: number;
    @Input() phoneTypes$: Observable<ListItem[]>;
    @Input() set additionalValidation(value: boolean) {
        this._additionalValidation = value;
    }

    get additionalValidation(): boolean {
        return this._additionalValidation;
    }

    @Output() onChangeMade = new EventEmitter();

    formArray: UntypedFormArray;
    private _additionalValidation: boolean;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly formGroupDirective: FormGroupDirective
    ) {
        super();
        this.formArray = this.formBuilder.array([]);
    }

    ngOnInit(): void {
        this.formGroupDirective.form.addControl(this.formGroupName, this.formArray);
        this.formArray.setParent(this.formGroupDirective.form);
    }

    deletePhone(index: number): void {
        this.formArray.removeAt(index);
    }

    addPhone(phone?: Phone): void {
        const form = this.formBuilder.group({
            id: this.formBuilder.control(phone?.id),
            type: this.formBuilder.control(phone?.vendorCandidatePhoneNumberTypeId, Validators.required),
            phone: this.formBuilder.control(
                phone ? `${phone.areaCode}${phone.exchange}${phone.station}${phone.extension}` : '',
                Validators.required
            )
        });

        this.formArray.push(form);
    }

    populate(phones: Phone[], numberRequired: boolean = false): void {
        this.formArray.clear();

        if (phones?.length > 0) {
            phones?.forEach((phone) => {
                this.addPhone(phone);
            });
        } else if (numberRequired) {
            this.addPhone();
        }

        this.formArray.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => {
            this.onChangeMade.emit();
        });
    }

    update(phones: Phone[]): void {
        this.formArray.clear();
        phones.forEach((phone) => {
            this.addPhone(phone);
        });
    }

    getPhones(): Phone[] {
        const result = new Array<Phone>();

        this.formArray.controls.forEach((phoneForm: UntypedFormGroup) => {
            const phoneNumber: string = phoneForm.get('phone').value?.replace('t', '');
            const phoneType: number = phoneForm.get('type').value;
            if (phoneNumber?.length < 10 || !phoneType) {
                return;
            }

            const phone: Phone = {
                id: phoneForm.get('id').value,
                vendorCandidateId: this.candidateId,
                vendorCandidatePhoneNumberTypeId: phoneType,
                note: '',
                countryCode: this.countryCode,
                areaCode: phoneNumber.substring(0, 3),
                exchange: phoneNumber.substring(3, 6),
                station: phoneNumber.substring(6, 10),
                extension: phoneNumber?.length > 10 ? phoneNumber.substring(10, 14) : ''
            };

            result.push(phone);
        });

        return result;
    }
}
