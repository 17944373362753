import { Component, Input } from '@angular/core';
import { DomainService } from '../../services/domain.service';

@Component({
    selector: 'loading-indicator',
    templateUrl: 'loading-indicator.component.html',
    styleUrls: ['loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {
    env = this.domain.environment();
    // Enable background overlay
    @Input() bgOverlay = true;
    constructor(private readonly domain: DomainService) {}

    get className() {
        return {
            'loading-indicator-bgoverlay': this.bgOverlay,
            'loading-indicator-transparent-click': !this.bgOverlay
        };
    }
}
