import { CopyFromFacility } from 'src/app/facilities/models/copy-from-facility.lookup';
import { LoadingTypes } from 'src/app/shared/models';

export interface CopyReleaseSettingsFromModalState {
    facilitiesLoadingState: LoadingTypes;
    facilities: CopyFromFacility[];
}

export const getInitialCopyReleaseSettingsFromModalState = (): CopyReleaseSettingsFromModalState => ({
    facilitiesLoadingState: LoadingTypes.INIT,
    facilities: []
});
