<form class="contact-form" [formGroup]="contactEmailAddressForm">
    <ng-container formArrayName="emails">
        <div *ngFor="let email of emails.controls; let i = index;">
            <ng-container [formGroupName]="i">
                <div class="email-input-wrapper">
                    <mat-form-field class="left-field" appearance="outline">
                        <mat-select [id]="'email-type-select-' + i" formControlName="emailTypeId">
                            <mat-option *ngFor="let type of emailTypes" [value]="type.id">
                                {{type.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="right-field" appearance="outline">
                        <input matInput [id]="'email-address-' + i" type="email" formControlName="emailAddress" placeholder="Email address" />
                        <mat-error *ngIf="email.get('emailAddress').hasError('required')">
                            Please enter an email address.
                        </mat-error>
                        <mat-error *ngIf="email.get('emailAddress').hasError('email')">
                            Invalid email address.
                        </mat-error>
                    </mat-form-field>

                    <button class="delete-button" [id]="'delete-email-button-' + i" mat-icon-button matTooltip="Remove email" (click)="removeEmail(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>

                <div class="email-checkbox-wrapper" [class.align-under-input]="email.get('emailAddress').valid">
                    <div class="left-field"></div>
                    <mat-checkbox class="right-field" [id]="'show-externally-checkbox-' + i" formControlName="showExternally">Show Externally</mat-checkbox>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <div class="actions">
        <button id="add-email-button" mat-flat-button color="accent" class="migrated-icon-button" matTooltip="Add new email address" (click)="addEmail()">
            <mat-icon>add</mat-icon>
            Add Email
        </button>
    </div>
</form>