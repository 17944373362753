<div class="profile-tab-container">
    <div class="active-controls">
        <div class="grid-controls">
            <div class="top-row">
                <div>
                    <button mat-stroked-button
                            class="grid-buttons"
                            data-qa-id="vendor_facilities_refresh_button"
                            (click)="refreshGrid()">
                        <span>
                            <mat-icon fontSet="fas"
                                      fontIcon="fa-refresh"></mat-icon>&nbsp;Refresh
                        </span>
                    </button>
                    <button mat-stroked-button
                            color="accent"
                            data-qa-id="vendor_facilities_count_section"
                            class="grid-buttons filter-totals">
                        <ng-container *ngIf="facilities$ | async as facilities">
                            <span class="filtered-text">Filtered {{ facilities.total }} from {{
                                facilities.nonFilteredTotal
                                }}</span>
                        </ng-container>
                    </button>
                </div>
                <div class="right-buttons">
                    <div>
                        <button mat-stroked-button
                                class="grid-buttons"
                                data-qa-id="vendor_facilities_select_all_button"
                                (click)="toggleAllSelection()">
                            <div *ngIf="!isAllSelected; else elseNotSelected">
                                <em class="fa fa-reply-all"></em>&nbsp;Select All
                            </div>
                            <ng-template #elseNotSelected>
                                <div>
                                    <em class="fa fa-reply-all"></em>&nbsp;Deselect All
                                </div>
                            </ng-template>
                        </button>
                    </div>
                    <div>
                        <button mat-stroked-button
                                color="accent"
                                class="grid-buttons"
                                data-qa-id="vendor_facilities_update_status_dropdown"
                                [matMenuTriggerFor]="statusMenu"
                                aria-label="Open Facilities offers menu dropdown">
                            Update Status
                            <span *ngIf="selectedCount">({{selectedCount}})</span>
                        </button>
                        <mat-menu #statusMenu="matMenu">
                            <button mat-menu-item
                                    data-qa-id="vendor_facilities_update_status_offer"
                                    (click)="handleFacilitiesStatusUpdate(vendorFacilityStatusEnum.Offer)">
                                <em class="glyphicon glyphicon-paperclip"></em>&nbsp;Offer
                                <span *ngIf="selectedCount">({{selectedCount}})</span>
                            </button>
                            <button mat-menu-item
                                    data-qa-id="vendor_facilities_update_status_exclude"
                                    (click)="openExcludeDialog()">
                                <em class="glyphicon glyphicon-compressed"></em>&nbsp;Exclude
                                <span *ngIf="selectedCount">({{selectedCount}})</span>
                            </button>
                            <button mat-menu-item
                                    data-qa-id="vendor_facilities_update_status_accepted"
                                    (click)="handleFacilitiesStatusUpdate(vendorFacilityStatusEnum.Accepted)">
                                <em class="glyphicon glyphicon-paperclip"></em>&nbsp;Accepted
                                <span *ngIf="selectedCount">({{selectedCount}})</span>
                            </button>
                        </mat-menu>
                    </div>

                    <div>
                        <button mat-stroked-button
                                color="accent"
                                class="grid-buttons"
                                data-qa-id="vendor_facilities_update_term_sheet_dropdown"
                                [matMenuTriggerFor]="termSheetMenu"
                                aria-label="Open Facilities term sheet menu dropdown">
                            Update Term Sheet
                            <span *ngIf="selectedCount">({{selectedCount}})</span>
                        </button>
                        <mat-menu #termSheetMenu="matMenu">
                            <button mat-menu-item
                                    data-qa-id="vendor_facilities_update_term_sheet_yes"
                                    (click)="updateCtsSigned(true)">
                                <em class="glyphicon glyphicon-pencil"></em>&nbsp;CTS Signed - Yes
                                <span *ngIf="selectedCount">({{selectedCount}})</span>
                            </button>
                            <button mat-menu-item
                                    data-qa-id="vendor_facilities_update_term_sheet_no"
                                    (click)="updateCtsSigned(false)">
                                <em class="glyphicon glyphicon-pencil"></em>&nbsp;CTS Signed - No
                                <span *ngIf="selectedCount">({{selectedCount}})</span>
                            </button>
                        </mat-menu>
                    </div>
                    <div>
                        <button mat-stroked-button
                                color="accent"
                                data-qa-id="vendor_facilities_export_dropdown"
                                [matMenuTriggerFor]="exportMenu"
                                class="grid-buttons"
                                aria-label="Open Facilities export menu dropdown">
                            <em class="fa fa-arrow-down"></em>&nbsp;Export
                        </button>
                        <mat-menu #exportMenu="matMenu">
                            <button mat-menu-item
                            data-qa-id="vendor_facilities_export_all"
                                    (click)="exportAll()">
                                <em class="fa fa-arrow-down"></em> Export All
                                <span *ngIf="facilityIds">({{total}})</span>
                            </button>
                            <button *ngIf="selectedCount"
                                    mat-menu-item
                                    data-qa-id="vendor_facilities_export_selected"
                                    (click)="exportSelected()">
                                <em class="fa fa-arrow-down"></em>&nbsp;Export Selected
                                <span *ngIf="selectedCount">({{selectedCount}})</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row top-10">
        <div class="form-group">
            <label for="includeExcludeNotes"
                   class="control-label">Include/Exclude Notes</label>
            <div>
                <input class="form-control"
                       type="text"
                       placeholder="Include: Per Diem"
                       [formControl]="includeExcludeNotes">
            </div>
        </div>
    </div>

    <portal-grid data-title=""
                 class="portal-grid-scroll-auto grid-container"
                 [data]="facilities$ | async"
                 [loading]="isLoading$ | async"
                 [query]="searchQuery$ | async"
                 [selectable]="true"
                 [toolbarHidden]="true"
                 selectableMode="multiple"
                 [selectable]="true"
                 (rowSelected)="onRowSelected($event)"
                 (dataStateChange)="onDataStateChange($event)">
        <portal-grid-column title="Name"
                            field="name"
                            filterType="text"
                            [sortable]="true"
                            width="180">
        </portal-grid-column>
        <portal-grid-column title="City"
                            field="city"
                            filterType="text"
                            width="90">
        </portal-grid-column>
        <portal-grid-column title="State"
                            field="state"
                            filterType="text"
                            [filterOptions]="statesLookup$ | async"
                            width="50">
        </portal-grid-column>
        <portal-grid-column title="VMS/MSP"
                            field="vmsProviderName"
                            [sortable]="true"
                            filterType="text">
        </portal-grid-column>
        <portal-grid-column title="Association"
                            field="contractGroupName"
                            [sortable]="true"
                            filterType="text">
        </portal-grid-column>
        <portal-grid-column title="CTS Required"
                            field="termSheetRequired"
                            filterType="dropdown"
                            valueField="value"
                            defaultText="Any"
                            [filterOptions]="yesNoOptions">
            <ng-template #cell
                         let-dataItem="dataItem">
                {{getCtsRequiredText(dataItem.termSheetRequired)}}
            </ng-template>
        </portal-grid-column>
        <portal-grid-column title="CTS Signed"
                            field="termSheetSigned"
                            valueField="value"
                            filterType="dropdown"
                            defaultText="Any"
                            [filterOptions]="yesNoOptions">
            <ng-template #cell
                         let-dataItem="dataItem">
                <kendo-dropdownlist [data]="yesNoOptions"
                                    textField="name"
                                    valueField="value"
                                    [valuePrimitive]="true"
                                    [(ngModel)]="dataItem.termSheetSigned"
                                    (selectionChange)="ctsSignedChange($event, dataItem)"></kendo-dropdownlist>
            </ng-template>
        </portal-grid-column>
        <portal-grid-column title="Status"
                            field="statusIds"
                            filterType="multiselect"
                            defaultText="All"
                            [filterOptions]="statusesLookup$ | async">
            <ng-template #cell
                         let-dataItem="dataItem">
                <a (click)="openStatusHistory(dataItem)">{{dataItem.status}}</a>
            </ng-template>
        </portal-grid-column>
        <portal-grid-column title="Status Date"
                            field="statusDate"
                            filterType="date"
                            [disabledKeyboard]="true">
            <ng-template #cell
                         let-dataItem="dataItem">
                {{ dataItem.statusDate | date : 'MM/dd/yyy' }}
            </ng-template>
        </portal-grid-column>
    </portal-grid>
</div>
