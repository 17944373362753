<ayac-breadcrumb
    [items]="[
        {label: 'All Reports', url: getReportsUrl() },
        {label: reportName }
    ]">
</ayac-breadcrumb>
<section [spinner]="isLoading$ | async">
    <mat-toolbar>
        <div class="spacer"></div>
        <button mat-button aria-label="Back" data-qa-id="ac_pbi_back_button" (click)="goBackToReportPage()">
            <mat-icon [inline]="true">keyboard_backspace</mat-icon> Back to reports
        </button>
        <button mat-button aria-label="Load" data-qa-id="ac_pbi_reset_button" (click)="setDefaultSettings()" *ngIf="canSaveFilters$ | async" [disabled]="isSettingsSaving$ | async">
            <mat-icon [inline]="true">replay</mat-icon> Reset Defaults
        </button>
        <button mat-button color="primary" data-qa-id="ac_pbi_save_button" aria-label="Save" (click)="save()" *ngIf="canSaveFilters$ | async" [disabled]="isSettingsSaving$ | async">
            <mat-icon [inline]="true">save</mat-icon> Save settings
        </button>
        <button mat-button color="primary" data-qa-id="ac_pbi_export_button" aria-label="Export" (click)="exportToFile()"
            *ngIf="(showReportExportProgress$ | async) === false"
            [disabled]="(canExport$ | async) === false"
            [title]="exportButtonTooltip$ | async">
            <mat-icon [inline]="true">file_download</mat-icon> Export To File
        </button>
        <span class="report-export-spinner-title mat-button" *ngIf="showReportExportProgress$ | async">
            Exporting...
        </span>
        <mat-progress-spinner
            *ngIf="showReportExportProgress$ | async"
            class="report-export-spinner"
            color="primary"
            mode="determinate"
            diameter="20"
            [value]="reportExportPercentComplete$ | async">
        </mat-progress-spinner>
    </mat-toolbar>
    <div class="report-canvas" #pbi_container></div>
</section>
