import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, tap } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { TravelersImportCustomFieldsService } from '../../services/travelers-import-custom-fields.service';
import * as importTravelersCustomFields from '../actions/travelers-import-custom-fields.actions';

@Injectable()
export class TravelersImportCustomFieldsEffect extends BaseEffect {
    readonly failed = 'Workers  import failed!';

    importJobs$ = createEffect(() => {
        return this.actions.pipe(
            ofType(importTravelersCustomFields.importTravelersCustomFields),
            exhaustMap((action) => {
                return this.service.importCustomFields(action.fileToUpload).pipe(
                    tap((res) => {
                        if (res.success) {
                            this.handleSuccess(
                                `Workers custom fields file ${action.fileToUpload.name} has been imported`
                            );
                        } else {
                            this.showError();
                        }
                    }),
                    concatMap((res) => {
                        return res.success
                            ? of(importTravelersCustomFields.importTravelersCustomFieldsSuccess())
                            : of(
                                  importTravelersCustomFields.importTravelersCustomFieldsFail({
                                      fileName: res.fileName,
                                      lines: res.lines
                                  })
                              );
                    }),
                    catchError(() => {
                        return of(
                            importTravelersCustomFields.importTravelersCustomFieldsFail({
                                fileName: action.fileToUpload.name,
                                lines: []
                            })
                        );
                    })
                );
            })
        );
    });

    constructor(
        private readonly actions: Actions,
        private readonly service: TravelersImportCustomFieldsService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    private showError = () => this.handleStringError(this.failed);
}
