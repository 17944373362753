<div class="job-searchable-dropdown">
  <label class="k-form-field">
    <span>
      {{ to.label }}
      <ng-container *ngIf="field.props.required">
        <span class="k-required">*</span>
      </ng-container>
      <formly-help-text [helpText]="props.helpText"></formly-help-text>    
    </span>
    <span id="dropdown">
      <kendo-dropdownlist 
        class="formly-kendo-dropdown" 
        [formControl]="formControl" 
        [data]="data$ | async"
        valueField="value"
        textField="label"
        [loading]="isLoading"
        [filterable]="true"
        (filterChange)="handleFilter($event)">
      </kendo-dropdownlist>
      <validation-message
        class="validation-message"
        [label]="to.label" 
        [control]="formControl" 
        [useConstantCustomMessage]="false">
      </validation-message>
    </span>
  </label>
</div>
