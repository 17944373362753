import { createSelector, createFeatureSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { AppState } from 'src/app/store/state/App.state';
import { PowerBiReportState, powerBiStoreKey } from './powerbi-report.state';

export const selectFeature = createFeatureSelector<PowerBiReportState>(powerBiStoreKey);

export const selectIsLoading = createSelector(selectFeature, (state) =>
    [state.accessConfig.loading, state.settings.loading].some((x) => x === LoadingTypes.LOADING)
);

export const selectIsSettingsLoaded = createSelector(selectFeature, (state) =>
    [state.accessConfig.loading, state.settings.loading].every((x) => x === LoadingTypes.LOADED)
);

export const selectAccessConfig = createSelector(selectFeature, (state) => state.accessConfig.config);

export const selectSettings = createSelector(selectFeature, (state) => state.settings.settings);

export const selectIsSettingsSaving = createSelector(
    selectFeature,
    (state) => state.settings.saving === LoadingTypes.LOADING
);

export const selectIsSettingsSavedSuccessfully = createSelector(
    selectFeature,
    (state) => state.settings.saving === LoadingTypes.LOADED
);

export const selectPowerBiReportExportStatus = createSelector(selectFeature, (state) => state.export.status);

export const selectPowerBiReportExportIsLoading = createSelector(
    selectFeature,
    (state) => state.export.loading === LoadingTypes.LOADING
);
