export function buildExportTable(titles, data) {
    var table = '<table width="100%">';

    table += '<thead>';
    table += '<tr>';
    for (let i = 0, cell; i < titles.length; i++) {
        table += `<th>${titles[i]}</th>`;
    }
    table += '</tr>';
    table += '</thead>';

    table += '<tbody>';
    for (let i = 0; i < data.length; i++) {
        table += '<tr>';
        for (let j = 0; j < data[i].length; j++) {
            table += `<td>${data[i][j]}</td>`;
        }
        table += '</tr>';
    }
    table += '</tbody>';
    table += '</table>';

    const blob = new Blob([table], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    });

    return blob;
}
