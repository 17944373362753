<ng-container [ngSwitch]="currentStep">
    <ayac-select-increase-job-position-option-dialog *ngSwitchCase="'selectOption'"
        (createOfferStep)="setCurrentStep($event)"
        [submittalFromTheWizardDialog]="submittal">
    </ayac-select-increase-job-position-option-dialog>
    <ayac-rescind-offer-dialog *ngSwitchCase="'rescindOffer'"
        (createOfferStep)="setCurrentStep($event)"
        [calledFromTheWizardDialog]=true>
    </ayac-rescind-offer-dialog>
    <ayac-offer-candidate-modal *ngSwitchCase="'createOffer'"
        [calledFromTheWizardDialog]=true
        [submittalFromTheWizardDialog]="submittal">
    </ayac-offer-candidate-modal>
</ng-container>
