import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import * as DashboardActions from 'src/app/lookups/state/actions';

export interface ShiftTypesState {
    shiftTypes: Array<ListItem>;
    loading: LoadingTypes;
    error: string;
}

export const initialShiftTypesState: ShiftTypesState = {
    shiftTypes: [],
    loading: LoadingTypes.INIT,
    error: ''
};

export function shiftTypesReducer(state: ShiftTypesState, action: Action) {
    return createReducer(
        initialShiftTypesState,
        on(DashboardActions.loadShiftTypes, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(DashboardActions.loadShiftTypesSuccess, (state, action) => ({
            ...state,
            shiftTypes: action.shiftTypes,
            loading: LoadingTypes.LOADED
        })),

        on(DashboardActions.loadShiftTypesFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: ShiftTypesState | undefined, action: Action) {
    return shiftTypesReducer(state, action);
}
