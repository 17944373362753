import { Component, ViewContainerRef, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import * as CoreSelectors from './core/state/index';
import * as CoreActions from './core/state/core.actions';
import { Store } from '@ngrx/store';
import { UnsubscribeOnDestroy } from './core/utils';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { initPendo } from './core/state/actions';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { ENV, Environment } from 'src/environments/environment.provider';
import { datadogRum } from '@datadog/browser-rum';
import { formatAppVersion } from './shared/services/version-checker.service';
import { Title } from '@angular/platform-browser';
import { DomainService } from 'src/app/shared/services/domain.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    readonly featureFlag = FeatureFlag;

    constructor(
        public vcRef: ViewContainerRef,
        private readonly store: Store,
        @Inject(APP_CONFIG) private readonly _settings: Settings,
        private readonly _ldFeatureManager: LDFeatureManager,
        @Inject(ENV) private readonly env: Environment,
        private readonly _title: Title,
        private readonly _domainService: DomainService,
        @Inject(DOCUMENT) private readonly _document: Document,
        private readonly _router: Router
    ) {
        super();

        this.store
            .select(CoreSelectors.isLoaded)
            .pipe(takeUntil(this.d$))
            .subscribe((loaded) => {
                if (loaded) {
                    this.store.dispatch(new CoreActions.LoginCheck());
                    if (this._settings.ACTIVATE_PENDO) {
                        this.store.dispatch(initPendo());
                    }
                }
            });
    }

    ngOnInit(): void {
        this._title.setTitle(this._domainService.getValue('TITLE'));

        this._domainService.getValue('STYLES_AND_META').forEach((meta) => {
            this._addMeta(meta.key, meta.value);
        });

        this._dataDogRum();
        this.updateShiftsRoutes();
    }

    ngOnDestroy(): void {}

    private _dataDogRum(): void {
        if (this.env.production !== true || this._settings.DATADOG_CLIENT_TOKENRUM === 'fake') {
            return;
        }
        datadogRum.init({
            applicationId: this._settings.DATADOG_APPLICATIONID,
            clientToken: this._settings.DATADOG_CLIENT_TOKENRUM,
            site: 'us3.datadoghq.com',
            service: this._settings.DATADOG_SERVICE,
            env: this._settings.ENV,
            version: formatAppVersion(this.env.appVersion).replace(/-\d*$/, ''),
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            defaultPrivacyLevel: 'mask-user-input',
            trackResources: true,
            trackLongTasks: true,
            trackUserInteractions: true,
            allowedTracingUrls: ['https:'.concat(this._settings.CORE.substring(0, this._settings.CORE.length - 1))],
            beforeSend: (event) => {
                event.view.url = event.view.url.replace('/#', '');
                return true;
            }
        });
        datadogRum.startSessionReplayRecording();
    }

    // Copy how legacy is setup
    private _addMeta(metaName: string, metaValue: unknown): void {
        const meta = this._document.createElement('link');
        meta.setAttribute('data-brand-meta', '');

        Object.keys(metaValue).forEach((key) => {
            meta.setAttribute(key, metaValue[key]);
        });

        this._document.getElementsByTagName('head')[0].appendChild(meta);
    }

    private updateShiftsRoutes() {
        this._router.config.unshift({
            path: 'client/perdiemscheduler',
            redirectTo: 'client/shiftsschedule'
        });
    }
}
