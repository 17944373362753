import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import * as internalPoolSelectors from '../store/selectors';

@Component({
    selector: 'ayac-notes-section',
    templateUrl: './notes-section.component.html',
    styleUrls: ['./notes-section.component.scss']
})
export class NotesSectionComponent extends UnsubscribeOnDestroy implements OnInit {
    resource$: Observable<Resource>;
    updateForm$: Observable<any>;

    public form: UntypedFormGroup;

    @Input('edit')
    editMode = false;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly store: Store<{}>
    ) {
        super();
    }

    ngOnInit() {
        this.form = this.fb.group({
            id: [''],
            systemId: [''],
            notes: ['']
        });

        this.resource$ = this.store.select(internalPoolSelectors.selectCurrentResource);

        this.updateForm$ = combineLatest([this.resource$, this.store.select(internalPoolSelectors.selectSystemId)])
            .pipe(filter(([resource]) => resource != null))
            .pipe(
                tap(([resource, systemId]) => {
                    this.form.markAsPristine();
                    this.form.patchValue({
                        ...resource,
                        systemId
                    });
                })
            );
    }
}
