import { AyaLeadsListResultsEffect } from './aya-leads-list-results.effects';
import { AyaLeadsPageEffects } from './aya-leads-page.effects';
import { AyaLeadsJobDetailsEffect } from './aya-leads-job-details.effects';
import { AyaLeadsLookupsEffects } from './aya-leads-lookups.effects';

export const ayaLeadsEffects = [
    AyaLeadsListResultsEffect,
    AyaLeadsPageEffects,
    AyaLeadsJobDetailsEffect,
    AyaLeadsLookupsEffects
];
