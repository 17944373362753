<div>
    <ng-container *ngFor="let phoneForm of formArray.controls; let i = index">
        <div class="phone-form-row-flex" [formGroup]="phoneForm">

            <div class="row-flex-gt-sm-50">
                <ayac-form-control-wrapper class="phone-type-control" [isCentered]="false">
                    <mat-form-field appearance="outline">
                        <mat-select required formControlName="type" data-qa-id="PhoneType">
                            <mat-option *ngFor="let type of phoneTypes$ | async" [value]="type.id">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="phoneForm.get('type').hasError('required')">Phone type is
                            required.</mat-error>
                    </mat-form-field>
                </ayac-form-control-wrapper>
            </div>

            <div class="row-flex-gt-sm-40">
                <ayac-form-control-wrapper [isCentered]="false" class="phone-control"
                    [showRequiredAsterisk]="additionalValidation">
                    <mat-form-field appearance="outline">
                        <input matInput required placeholder="Phone" formControlName="phone"
                            [attr.data-qa-id]="'Phone' + (i + 1)" [specialCharacters]="[
                                '(',
                                ')',
                                ' ',
                                '-',
                                'e',
                                'x',
                                't',
                                '.'
                            ]" mask="(000) 000-0000||(000) 000-0000 ext. 9999"/>
                        <mat-error *ngIf="phoneForm.get('phone').hasError('required')">Phone is required.</mat-error>
                    </mat-form-field>
                </ayac-form-control-wrapper>
            </div>
            <div class="row-flex-gt-sm-10">
                <ng-container *ngIf="additionalValidation; else regularPhoneValidation">
                    <mat-icon *ngIf="formArray.value.length > 1" (click)="deletePhone(i)" class="delete-phone-btn"
                        data-toggle='tooltip' data-placement="left"
                    title="Delete"
                        color="warn" [attr.data-qa-id]="'delete-phone-button' + (i + 1)">
                        delete_forever</mat-icon>
                </ng-container>

                <ng-template #regularPhoneValidation>
                    <mat-icon (click)="deletePhone(i)" class="delete-phone-btn" color="warn" data-toggle='tooltip' data-placement="left"
                    title="Delete"
                        [attr.data-qa-id]="'delete-phone-button' + (i + 1)">
                        delete_forever</mat-icon>
                </ng-template>

            </div>
            <div class="row-flex-gt-sm-50"></div>
        </div>
    </ng-container>

    <div class="add-button-flex">
        <div>
            <button class="migrated-btn-info-brdr" mat-flat-button color="accent" (click)="addPhone()" title="Add Phone">
                <mat-icon>add</mat-icon>
                Add Phone
            </button>
        </div>
    </div>
</div>
