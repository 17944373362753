import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-expand-button',
    templateUrl: './expand-button.component.html'
})
export class ExpandButtonComponent {
    @Input('expanded') isExpanded: boolean = false;
    @Input() moreText = 'Expand';
    @Input() lessText = 'Collapse';
    @Input() showIcon = true;

    @Output() expandedChange = new EventEmitter<boolean>();

    constructor() {}

    onClick() {
        const expanded = !this.isExpanded;
        this.isExpanded = expanded;
        this.expandedChange.emit(expanded);
    }
}
