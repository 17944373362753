<dialog-layout [title]="title">

    <div mat-dialog-content>
       {{message}}
    </div>

    <mat-divider class="my-1"></mat-divider>

    <div mat-dialog-actions>
        <button mat-flat-button
                (click)="confirmReportAction(true)"
                color="accent"
                id="confirm-report-action"
                data-qa-id="confirm-report-action">
                Confirm
        </button>
        <button mat-stroked-button
                (click)="confirmReportAction(false)"
                cdkFocusInitial
                id="cancel-report-action"
                data-qa-id="cancel-report-action">
                Cancel
        </button>
    </div>

</dialog-layout>
