import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { InternalPoolService } from '../../internal-pool.service';
import * as internalPoolActions from 'src/app/internal-pool/store/actions';
import * as internalPoolSelectors from 'src/app/internal-pool/store/selectors';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { Store } from '@ngrx/store';
import { SortTypes } from 'src/app/shared/models';

@Injectable()
export class ResourceAssignmentsEffect extends BaseEffect {
    constructor(
        private readonly actions: Actions,
        private readonly service: InternalPoolService,
        private readonly store: Store<{}>,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    loadPreviousAssignments$ = createEffect(() =>
        this.actions.pipe(
            ofType(internalPoolActions.loadPreviousAssignments),
            withLatestFrom(
                this.store.select(internalPoolSelectors.selectPreviousAssignmentsQuery),
                this.store.select(internalPoolSelectors.selectSystemId)
            ),
            switchMap(([action, query, systemId]) => {
                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };

                const sortArgs = {
                    sortField: query.sort?.[0]?.field,
                    sortType: query.sort?.[0]?.dir as SortTypes
                };

                return this.service.getResourceAssignments(systemId, action.id, pagination, sortArgs).pipe(
                    map((response) =>
                        internalPoolActions.loadResourcePreviousAssignmentsSuccess({
                            total: response.total,
                            assignments: response.data
                        })
                    ),
                    catchError((error) => of(internalPoolActions.loadPreviousResourceAssignmentsFail({ error })))
                );
            })
        )
    );

    loadPreviousAssignmentsFailed$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(internalPoolActions.loadPreviousResourceAssignmentsFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );

    loadCurrentAssignments$ = createEffect(() =>
        this.actions.pipe(
            ofType(internalPoolActions.loadCurrentResourceAssignments),
            withLatestFrom(
                this.store.select(internalPoolSelectors.selectCurrentAssignmentsQuery),
                this.store.select(internalPoolSelectors.selectSystemId)
            ),
            switchMap(([action, query, systemId]) => {
                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };

                const sortArgs = {
                    sortField: query.sort?.[0]?.field,
                    sortType: query.sort?.[0]?.dir as SortTypes
                };

                return this.service.getCurrentResourceAssignments(systemId, action.id, pagination, sortArgs).pipe(
                    map((response) =>
                        internalPoolActions.loadCurrentResourceAssignmentsSuccess({
                            assignments: response.data,
                            total: response.total
                        })
                    ),
                    catchError((error) => of(internalPoolActions.loadCurrentResourceAssignmentsFail({ error })))
                );
            })
        )
    );

    loadCurrentAssignmentsFailed$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(internalPoolActions.loadCurrentResourceAssignmentsFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );
}
