<nav class="navbar navbar-fixed-top navbar-inverse"
     #navbarCollapse
     id="navbarCollapse"
     *ngIf="showTopMenu">
    <div class="container-fluid">
        <div class="navbar-header">
            <a id="top-nav-logo-white"
               data-qa-id="topNavLogoButton"
               [href]="connectUrl"
               target="_blank"
               rel="noopener noreferrer">
               <div></div>
            </a>
            <button *ngIf="userSignedIn && !isLoginPage" type="button"
                    class="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#portal-navbar"
                    data-qa-id="topNavUserIdentity"
                    aria-expanded="false">
                <span class="dropdown-toggle">
                    <span class="glyphicon glyphicon-user"></span>
                    Hi {{identity?.firstName}}
                    <span class="caret"></span><span *ngIf="serverName === 'DEV' || serverName === 'Local'">
                        ({{identity?.userName}})
                    </span>
                </span>
            </button>

        </div>

        <div class="collapse navbar-collapse"
             id="portal-navbar">
            <ul class="nav navbar-nav navbar-right"
                *ngIf="userSignedIn && !isLoginPage">
                <li>
                    <ng-container *ngIf="nonVendorLogin; else pendoVendorHelp">
                        <a *ngIf="showQuestions"
                           href="#"
                           (click)="goToHelpPage($event)"
                           class="invertable"
                           data-placement="Bottom"
                           data-toggle="tooltip"
                           data-qa-id="topNavHelpButton"
                           title="Help">
                            <mat-icon>help</mat-icon>
                        </a>
                    </ng-container>
                    <ng-template #pendoVendorHelp>
                        <a *ngIf="showQuestions"
                           class="invertable"
                           data-placement="Bottom"
                           data-toggle="tooltip"
                           data-qa-id="topNavHelpButtonVendor"
                           title="Help">
                            <mat-icon [attr.data-qa-id]="'connect-resource-center'">help</mat-icon>
                        </a>
                    </ng-template>
                </li>
                <li class="dropdown">
                    <button mat-button
                            [matMenuTriggerFor]="menu"
                            class="dropdown-toggle invertable"
                            data-qa-id="topNavUserDropdown"
                            type="button">
                        <span class="dropdown-toggle">
                            <mat-icon>person</mat-icon>
                            Hi
                            {{identity?.firstName}}
                            <mat-icon>arrow_drop_down</mat-icon>
                        </span>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [matMenuTriggerFor]="profiles" *ngIf="profilesExist$ | async">
                            <mat-icon fontSet="fas" fontIcon="fa-user" class="icon-lg"></mat-icon>
                            <span>Switch Profile</span>
                        </button>
                        <button mat-menu-item
                                (click)="goToLogin()"
                                data-qa-id="topNavSignInButton"
                                *ngIf="!userSignedIn && !isLoginPage">
                            <em class="glyphicon glyphicon-log-in"></em> Sign-In
                        </button>
                        <button mat-menu-item
                                data-qa-id="topNavChangePasswordButton"
                                (click)="changePassword()">
                                <i class="fa-solid fa-lock" aria-hidden="true"></i> Change Password
                        </button>
                        <button mat-menu-item
                                data-qa-id="topNavSignOutButton"
                                (click)="signOut()">
                                <i class="fa-solid fa-right-from-bracket" aria-hidden="true"></i> Sign-Out
                        </button>
                    </mat-menu>
                    <mat-menu #profiles="matMenu" class="profile-dropdown">
                        <button *ngFor="let profile of profiles$ | async" mat-menu-item
                            class="user-profile"
                            [disabled]="profile.isDefaultProfile"
                            (click)="changeProfile(profile.userId)"
                            [ngClass]="{'active-profile': profile.isDefaultProfile, 'impersonated-profile': profile.isImpersonated}">
                            <mat-icon fontSet="fas" fontIcon="fa-user" class="icon-lg"></mat-icon>&nbsp;
                            <span>{{ profile.userName }} - {{profile.type | replace : 'Employee' : 'Admin'}}</span>
                            <div *ngIf="profile.isImpersonated">IMPERSONATED</div>
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </div>
</nav>
