<ayac-submittals-modal title="Decline Candidate - Not Selected"
                       loading="false"
                       actionButtonName="Decline"
                       actionButtonColor="accent"
                       (actionButtonClick)="declineCandidate()"
                       [actionButtonDisabled]="!form.valid || noteItemIsNotValid"
                       [displayRedActionButton]="true"
                       *ifLdFeature="featureFlag.ConnectClientDisplayNewSubmittalStatus">
    <form [formGroup]="form">
        <div class="grid">
            <div>
                <strong>Reason *</strong>
            </div>
            <kendo-combobox formControlName="reason"
                            [data]="data?.status?.subStatuses"
                            textField="name"
                            valueField="id"
                            placeholder="Select Reason"
                            [valuePrimitive]="true">
            </kendo-combobox>
            <strong data-qa-id="decline-candidate-note-text" id="decline-candidate-note-text">Notes<span *ngIf="isOtherSelected"> *</span></strong>
            <ayac-text-area-with-counter placeholder="Note"
                                         formControlName="note"
                                         dataQaId="decline-candidate-note"
                                         [editor]="editor"
                                         [isVendorClientCommunicationEnabled]="isVendorClientCommunicationEnabled"
                                         (formItemValidationChanged)="onNoteItemValidationChanged($event)">
            </ayac-text-area-with-counter>
        </div>
    </form>

</ayac-submittals-modal>

<dialog-layout data-qa-id="ac_client_smdc_dialog_container" title="Decline Candidate" *ifLdFeature="featureFlag.ConnectClientDisplayNewSubmittalStatus; enabled: false">
    <div mat-dialog-content>
        <form [formGroup]="form">
            <div class="grid">
                <div>
                    <strong>Reason</strong>
                </div>
                <kendo-combobox
                    formControlName="reason"
                    [data]="declineReasons$ | async"
                    textField="reason"
                    valueField="id"
                    placeholder="Select Reason"
                    [valuePrimitive]="true">
                </kendo-combobox>

                <div>
                    <strong>Decline Note</strong>
                </div>
                <div class="textarea-wrapper">
                    <textarea
                        maxlength="620"
                        rows="10"
                        class="k-textarea"
                        placeholder="Note"
                        formControlName="note">
                    </textarea>

                    <span class="text-length">{{noteLength}}/620</span>
                </div>
            </div>
        </form>
    </div>
    <hr>
    <div mat-dialog-actions>
        <div>
            <button mat-flat-button color="accent" data-qa-id="ac_client_smdc_decline_button" (click)="declineCandidate()" [disabled]="!form.valid">Decline</button>
            <button mat-stroked-button data-qa-id="ac_client_smdc_canel_button" mat-dialog-close cdkFocusInitial>Cancel</button>
        </div>
    </div>
</dialog-layout>
