<div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
        <form [formGroup]="form">
            <div>
                <ayac-form-control-wrapper
                    [controlLabel]="professionLabel"
                    [showRequiredAsterisk]="true"
                    class="profession-control"
                >
                    <mat-form-field appearance="outline">
                        <mat-select
                            formControlName="profession"
                            required
                            data-qa-id="Profession"
                        >
                            <mat-option
                                *ngFor="let profession of professions$ | async"
                                [value]="profession.id"
                            >
                                {{ profession.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="professionControl.hasError('required')"
                            >{{ professionLabel }} is required.</mat-error
                        >
                    </mat-form-field>
                </ayac-form-control-wrapper>

                <ayac-form-control-wrapper
                    [controlLabel]="specialtiesLabel"
                    [showRequiredAsterisk]="true"
                    class="specialties-control"
                >
                    <mat-form-field
                        appearance="outline"
                        [ngClass]="{
                            'mat-form-field-invalid':
                                specialtyIdsControl.hasError('required')
                        }"
                    >
                        <mat-chip-list #chipList aria-label="Specialty">
                            <mat-chip
                                *ngFor="
                                    let specialtyId of specialtyIdsControl.value
                                "
                                (removed)="removeSpecialty(specialtyId)"
                            >
                                {{ getSpecialtyNameById(specialtyId) }}

                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input
                                matInput
                                autocomplete="off"
                                placeholder="Specialty..."
                                #specialtyInput
                                [formControl]="specialtyAutoCompleteControl"
                                [matAutocomplete]="auto"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="
                                    separatorKeysCodes
                                "
                                (matChipInputTokenEnd)="
                                    addFilteredSpecialty($event)
                                "
                                #trigger="matAutocompleteTrigger"
                                (click)="trigger.openPanel()"
                            />
                        </mat-chip-list>
                        <mat-autocomplete
                            #auto="matAutocomplete"
                            (optionSelected)="specialtySelected($event)"
                        >
                            <mat-option
                                *ngFor="
                                    let specialty of filteredSpecialties$
                                        | async
                                "
                                [value]="specialty.specialtyId"
                            >
                                {{ specialty.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="specialtyIdsControl.hasError('required') && (specialtyIdsControl.dirty || specialtyIdsControl.touched)"
                        >{{ specialtiesLabel }} is required.</mat-error
                    >
                </ayac-form-control-wrapper>

                <ayac-form-control-wrapper
                    *ngIf="form.get('specialties')?.length > 0"
                    class="years-experience-container"
                >
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Years of Experience</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <ng-container
                                *ngFor="
                                    let specialtyForm of form.get('specialties')
                                        .controls;
                                    let i = index
                                "
                            >
                                <div
                                    class="candidate-profile-control-container specialty-form-row"
                                    [formGroup]="specialtyForm"
                                >
                                    <ayac-form-control-wrapper
                                        [controlLabel]="
                                            specialtyForm.get('name')?.value
                                        "
                                        [showRequiredAsterisk]="true"
                                        class="years-experience-control"
                                    >
                                        <mat-form-field appearance="outline">
                                            <input
                                                matInput
                                                formControlName="yearsExperience"
                                                [attr.data-qa-id]="
                                                    'YearsExperience' + (i + 1)
                                                "
                                                required
                                            />
                                            <mat-error
                                                *ngIf="
                                                    specialtyForm
                                                        .get('yearsExperience')
                                                        .hasError('required')
                                                "
                                                >Years experience is
                                                required.</mat-error
                                            >
                                        </mat-form-field>
                                    </ayac-form-control-wrapper>
                                </div>
                            </ng-container>
                        </mat-card-content>
                    </mat-card>
                </ayac-form-control-wrapper>
            </div>
        </form>
    </div>
</div>
