import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as shiftsActions from 'src/app/shifts/store/actions';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ResourceList } from '../../../shared/models/internal-pool/resource-list.model';
import { BookedCandidates } from '../../models';

export interface ShiftSubmittalCandidatesGridQuery extends GridSearchQuery {
    isAllCandidates?: boolean;
}

export interface ShiftSubmittalCandidatesState {
    candidates: ResourceList[];
    total: number;
    query: ShiftSubmittalCandidatesGridQuery;
    loading: LoadingTypes;
}

export interface ShiftBookedCandidatesState {
    bookedCandidates: BookedCandidates[];
    query: ShiftSubmittalCandidatesGridQuery;
    loading: LoadingTypes;
}

export const initialShiftSubmittalCandidatesState: ShiftSubmittalCandidatesState = {
    candidates: [],
    total: 0,
    query: { take: 100 },
    loading: LoadingTypes.INIT
};

export const initialShiftBookedCandidatesState: ShiftBookedCandidatesState = {
    bookedCandidates: [],
    query: { take: 100 },
    loading: LoadingTypes.INIT
};

export function shiftSubmittalCandidatesReducer(state: ShiftSubmittalCandidatesState, action: Action) {
    return createReducer(
        initialShiftSubmittalCandidatesState,
        on(shiftsActions.loadShiftSubmittalCandidates, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(shiftsActions.loadShiftSubmittalCandidatesSuccess, (state, action) => ({
            ...state,
            candidates: action.candidates,
            total: action.total,
            loading: LoadingTypes.LOADED
        })),

        on(shiftsActions.loadShiftSubmittalCandidatesFail, (state, action) => ({
            ...state,
            submittals: [],
            loading: LoadingTypes.FAIL
        })),

        on(shiftsActions.setShiftSubmittalCandidatesQuery, (state, action) => ({
            ...state,
            query: {
                ...state.query,
                ...action.query
            }
        })),

        on(shiftsActions.clearShiftSubmittalCandidatesQuery, (state) => ({
            ...state,
            query: initialShiftSubmittalCandidatesState.query
        })),
        on(shiftsActions.submitShiftCandidate, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(shiftsActions.submitShiftCandidateSuccess, (state) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),
        on(shiftsActions.submitShiftCandidateFail, (state) => ({
            ...state,
            loading: LoadingTypes.FAIL
        })),
        on(shiftsActions.clearShiftSubmittals, (state) => ({
            ...state,
            ...initialShiftSubmittalCandidatesState
        }))
    )(state, action);
}

export function reducer(state: ShiftSubmittalCandidatesState | undefined, action: Action) {
    return shiftSubmittalCandidatesReducer(state, action);
}
