/**angular */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

/**kendo */
import { GridDataResult } from '@progress/kendo-angular-grid';

/**rxjs */
import { Observable, combineLatest, of } from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';

/*local*/
import { SubmittalAdmin } from 'src/app/admin/submittals/models/submittal-admin.model';
import * as actions from 'src/app/admin/store/actions/submittals.actions';
import * as selectors from 'src/app/admin/store/selectors/submittals.selectors';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';

/**shared */
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { GridComponent } from 'src/app/shared/grid/components/grid.component';
/**ngrx */
import { Store } from '@ngrx/store';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { SubmittalAdminStatusLookup } from '../../lookups/submittals-admin-status-lookup.model';
import { SubmittalAdminSubStatusLookup } from '../../lookups/submittals-admin-substatus-lookup.model';

@Component({
    selector: 'ayac-submittals',
    templateUrl: './submittals.component.html',
    styleUrls: ['./submittals.component.scss']
})
export class SubmittalsComponent extends UnsubscribeOnDestroy implements OnInit {
    submittals$: Observable<GridDataResult>;
    submittalsQuery$: Observable<GridSearchQuery>;
    queryTest: GridSearchQuery;
    isLoading$: Observable<boolean>;

    featureFlags = FeatureFlag;
    selected = [];
    statusLookups$: Observable<SubmittalAdminStatusLookup[]>;
    selectedStatusIds: number[] = [];
    subStatusLookups$: Observable<SubmittalAdminSubStatusLookup[]>;
    selectedSubStatusIds: number[] = [];

    @ViewChild(GridComponent, { static: true }) grid: GridComponent;
    constructor(
        private readonly _store$: Store,
        private readonly _router: Router,
        private readonly _featureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.loadSubmittalStatuses();
        this._store$.dispatch(actions.componentLoaded());
    }

    refreshGrid() {
        this._store$.dispatch(actions.loadSubmittals());
    }

    exportExcel() {
        this._store$.dispatch(actions.exportSubmittals());
    }

    onDataStateChange(state: GridStateChangeEvent) {
        const statusFilters = state.filter?.filters as FilterDescriptor[];
        const statusIdFilter = statusFilters.find((e) => e.field === 'submittalStatusIds');
        if (statusIdFilter && statusIdFilter.value !== this.selectedStatusIds) {
            this.selectedStatusIds = statusIdFilter.value;
            if (this.selectedStatusIds.length === 1) {
                this._store$.dispatch(
                    actions.loadSubmittalsLookupSubStatuses({
                        statusId: statusIdFilter.value[0]
                    })
                );
                this.subStatusLookups$ = this._store$.select(selectors.selectSubmittalAdminLookupsSubStatuses);
            } else {
                this.subStatusLookups$ = of([]);
            }
        }
        this._store$.dispatch(actions.setSubmittalsSearchQuery_V2({ searchQuery: state }));
    }

    onRowClicked(item: SubmittalAdmin) {
        this._router.navigateByUrl(`/admin/submittal/${item.id}`);
    }

    loadSubmittalStatuses(): void {
        //Lookups
        this.statusLookups$ = this._store$.select(selectors.selectSubmittalAdminLookupsStatuses).pipe(
            tap((statusLookups) => {
                this.selectedStatusIds = statusLookups.map((sl) => sl.id);
            })
        );
        this._store$.dispatch(actions.loadSubmittalsLookupStatuses());

        //Query
        this.submittalsQuery$ = combineLatest([
            this._store$.select(selectors.selectAdminSubmittalsSearchQuery),
            this.statusLookups$
        ]).pipe(
            tap(([query, statuses]) => {
                this._querySubmittalStatus(query, statuses);
            }),
            filter(([query]) => query.filter !== null),
            map(([query]) => {
                if (query?.filter) {
                    const facilityFilter = query.filter.filters.find(
                        (x) => (x as FilterDescriptor).field === 'searchFacilityIds'
                    );
                    if (!facilityFilter && facilityFilter !== undefined) {
                        this._store$.dispatch(actions.setSubmittalsSearchQuery_V2({ searchQuery: query }));
                    }
                }
                return query;
            })
        );
        this.submittals$ = this._store$.select(selectors.selectAdminSubmittals);
    }

    private _querySubmittalStatus(query: GridSearchQuery, statuses: SubmittalAdminStatusLookup[]): void {
        if (!query?.filter?.filters || query?.filter?.filters.length === 0) {
            return;
        }
        const submittalStatus: any = query.filter?.filters.find((x: any) => x.field === 'submittalStatusIds');
        const statusValue = submittalStatus?.value;
        if (statusValue?.length === 1) {
            return;
        } else {
            query.filter.filters = query.filter?.filters.filter((x: any) => x.field !== 'submittalSubStatusIds');
        }
    }
}
