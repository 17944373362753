import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, filter, Observable, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { SubmittalExpanded } from 'src/app/shared/models/submittals';
import { SubmittalJobPositionsStatus } from 'src/app/submittals/models/submittal-job-positions-status.model';
import { SubmittalOfferBaseDetails } from 'src/app/shared/models/submittals/submittal-offer-base-details.model';
import * as submittalsActions from 'src/app/submittals/store/submittals.actions';
import { CreateOfferStepType } from 'src/app/submittals/components/submittal-details/create-offer-wizard-dialog/create-offer-wizard-dialog.component';

import { LoadingTypes } from 'src/app/shared/models';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { MatDialogRef } from '@angular/material/dialog';
import {
    selectAddJobPositionsLoadingStatus,
    selectSubmittalJobPositionsStatus,
    selectSubmittalJobPositionsLoadingStatus,
    selectSubmittalRescindOfferList,
    selectSubmittalRescindOfferListLoadingStatus
} from 'src/app/submittals/store/selectors';

export enum AddJobPositionsOptions {
    AddOnePosition = 'addOnePosition',
    RescindOnePosition = 'rescindOnePosition'
}

@Component({
    selector: 'ayac-select-increase-job-position-option-dialog',
    templateUrl: './select-increase-job-position-option-dialog.component.html',
    styleUrls: ['./select-increase-job-position-option-dialog.component.scss']
})
export class SelectIncreaseJobPositionOptionDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() submittalFromTheWizardDialog: SubmittalExpanded;
    @Output() createOfferStep = new EventEmitter<CreateOfferStepType>();
    form: UntypedFormGroup;
    jobPositionsStatus: SubmittalJobPositionsStatus;
    rescindOfferList: SubmittalOfferBaseDetails[];
    jobPositionsStatus$: Observable<SubmittalJobPositionsStatus> = this.store.select(selectSubmittalJobPositionsStatus);
    rescindOfferList$: Observable<SubmittalOfferBaseDetails[]> = this.store.select(selectSubmittalRescindOfferList);

    addJobPositionsOptions = AddJobPositionsOptions;

    jobPositionsLoadingStatus$: Observable<LoadingTypes> = this.store.select(selectSubmittalJobPositionsLoadingStatus);
    rescindOfferListLoadingStatus$: Observable<LoadingTypes> = this.store.select(
        selectSubmittalRescindOfferListLoadingStatus
    );

    isLoading: boolean;
    isInitializing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    canAddJobPosition: boolean;
    showAddPositionLoadingSpinner = false;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly store: Store,
        private readonly _dialogRef: MatDialogRef<SelectIncreaseJobPositionOptionDialogComponent>,
        private readonly _permissionsService: PermissionsService
    ) {
        super();
    }

    get isRescindOfferSelected() {
        return this.form.get('increaseJobPositionsOption').value === this.addJobPositionsOptions.RescindOnePosition;
    }

    get isIncreaseJobPositionsSelected() {
        return this.form.get('increaseJobPositionsOption').value === this.addJobPositionsOptions.AddOnePosition;
    }

    get hasOffersAvailableToRescind() {
        return this.rescindOfferList && this.rescindOfferList.length > 0;
    }

    get displayRescindWarning() {
        return this.form.get('offerId').value !== null;
    }

    ngOnInit(): void {
        this.store.dispatch(
            submittalsActions.getSubmittalJobPositionsStatus({
                clientSubmittalId: this.submittalFromTheWizardDialog.id
            })
        );

        this.store.dispatch(
            submittalsActions.loadSubmittalOfferRescindList({
                clientSubmittalId: this.submittalFromTheWizardDialog.id
            })
        );

        combineLatest([this.jobPositionsLoadingStatus$, this.rescindOfferListLoadingStatus$, this.isInitializing$])
            .pipe(takeUntil(this.d$))
            .subscribe(([jobPositionsLoadingStatus, rescindOfferListLoadingStatus, isInitializing]) => {
                this.isLoading =
                    jobPositionsLoadingStatus === LoadingTypes.LOADING ||
                    rescindOfferListLoadingStatus === LoadingTypes.LOADING ||
                    (isInitializing && jobPositionsLoadingStatus === LoadingTypes.LOADED);
            });

        combineLatest([this.jobPositionsStatus$, this.rescindOfferList$])
            .pipe(filter(([jobPositionsStatus]) => !!jobPositionsStatus))
            .pipe(takeUntil(this.d$))
            .subscribe(([jobPositionsStatus, rescindOfferList]) => {
                this.jobPositionsStatus = jobPositionsStatus;
                this.canAddJobPosition = this._permissionsService.jobEdit() && this.jobPositionsStatus.canAddPosition;

                if (jobPositionsStatus.hasOpenPositions) {
                    this.createOfferStep.emit('createOffer');
                } else if (rescindOfferList) {
                    this.rescindOfferList = rescindOfferList;
                    if (this.hasOffersAvailableToRescind) {
                        this.initForm();
                    }
                    this.isInitializing$.next(false);
                }
            });
    }

    initForm(): void {
        this.form = this._formBuilder.group({
            offerId: [null]
        });

        if (this.canAddJobPosition) {
            this.form.addControl('increaseJobPositionsOption', this._formBuilder.control(null, Validators.required));
            this.form.addValidators(this.rescindOfferIdValidator());

            this.form
                .get('increaseJobPositionsOption')
                ?.valueChanges.pipe(takeUntil(this.d$))
                .subscribe((value) => {
                    if (value === AddJobPositionsOptions.AddOnePosition) {
                        this.form.get('offerId').setValue(null);
                        this.form.get('offerId').updateValueAndValidity();
                    }
                });
        } else {
            this.form.get('offerId').setValidators(Validators.required);
        }
    }

    rescindOfferIdValidator(): ValidatorFn {
        return (): ValidationErrors | null => {
            return this.isRescindOfferSelected && !this.form.get('offerId').value ? { invalidOfferId: true } : null;
        };
    }

    proceed(): void {
        if (
            (this.canAddJobPosition && !this.hasOffersAvailableToRescind) ||
            (this.canAddJobPosition && this.hasOffersAvailableToRescind && this.isIncreaseJobPositionsSelected)
        ) {
            this.store.dispatch(
                submittalsActions.addJobPositions({
                    jobId: this.submittalFromTheWizardDialog.jobId,
                    positionsCount: (this.jobPositionsStatus.totalOpenPositions ?? 0) + 1
                })
            );
            this.store
                .select(selectAddJobPositionsLoadingStatus)
                .pipe(takeUntil(this.d$))
                .subscribe((loadingStatus) => {
                    this.showAddPositionLoadingSpinner = loadingStatus === LoadingTypes.LOADING;
                    if (loadingStatus === LoadingTypes.LOADED) {
                        this.showAddPositionLoadingSpinner = false;
                        this.createOfferStep.emit('createOffer');
                    } else if (loadingStatus === LoadingTypes.FAIL) {
                        this.showAddPositionLoadingSpinner = false;
                        this.close();
                    }
                });
        } else if (
            (this.canAddJobPosition && this.hasOffersAvailableToRescind && this.isRescindOfferSelected) ||
            (!this.canAddJobPosition && this.hasOffersAvailableToRescind)
        ) {
            const offerId = this.form.get('offerId').value as number;
            const matchingOffer = this.rescindOfferList.find((offer) => offer.offerId === offerId);
            if (matchingOffer) {
                this.store.dispatch(
                    submittalsActions.setSelectedRescindOfferDetails({
                        offerId: matchingOffer.offerId,
                        clientSubmittalId: matchingOffer.submittalId
                    })
                );
                this.createOfferStep.emit('rescindOffer');
            }
        }
    }

    close(): void {
        this._dialogRef.close();
    }
}
