import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CandidateEducation } from 'src/app/submittals/models';
import { loadCandidateEducation } from 'src/app/submittals/store/actions/submittals-leads.actions';
import { selectCandidateEducation } from 'src/app/submittals/store/submittals.selectors';

@Component({
    selector: 'aya-submittal-education-section',
    templateUrl: 'submittal-education-section.component.html',
    styleUrls: ['./submittal-education-section.component.scss']
})
export class SubmittalEducationSectionComponent implements OnInit {
    @Input() candidateId: number;
    educationDisplayedColumns = ['startDate', 'dateGraduated', 'degree', 'fieldOfStudy', 'school', 'location'];
    education$: Observable<CandidateEducation[]>;

    constructor(private readonly store: Store) {}

    ngOnInit() {
        this.store.dispatch(loadCandidateEducation({ candidateId: this.candidateId }));
        this.education$ = this.store.select(selectCandidateEducation);
    }
}
