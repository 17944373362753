import { Component, input, Input, InputSignal, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { CandidateQualifications } from 'src/app/submittals/models';
import { loadCandidateQualifications } from 'src/app/submittals/store/actions/submittals-leads.actions';
import { selectCandidateQualifications } from 'src/app/submittals/store/submittals.selectors';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';

@Component({
    selector: 'aya-submittal-certifications-section',
    templateUrl: 'submittal-certifications-section.component.html',
    styleUrls: ['./submittal-certifications-section.component.scss']
})
export class SubmittalCertificationsSectionComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() candidateId: number;
    @Input() isSubContractor: boolean;
    showLicenseIssuance: InputSignal<boolean> = input(false);
    certificationDisplayedColumns = ['name', 'expirationDate'];
    licenseDisplayedColumns = ['state', 'id', 'expirationDate', 'isCompact'];
    boardCertsDisplayedColumns = ['expertiseName', 'boardStatusDescription', 'expirationDate'];
    crnaCertDisplayedColumns = ['name', 'expirationDate'];
    imlcLetterDisplayedColumns = ['name'];
    deaLicensesDisplayedColumns = ['stateName', 'expirationDate'];
    csrCdsLicensesDisplayedColumns = ['stateName', 'expirationDate'];
    qualifications$: Observable<CandidateQualifications>;

    constructor(
        private readonly store: Store,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit() {
        this.ldFeatureManager
            .isEnabled(FeatureFlag.LotusOneApplicantDetailsCertAndLicenseEnhancement)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                if (isEnabled && this.showLicenseIssuance()) {
                    this.licenseDisplayedColumns = ['state', 'id', 'issuanceDate', 'expirationDate', 'isCompact'];
                } else {
                    this.licenseDisplayedColumns = ['state', 'id', 'expirationDate', 'isCompact'];
                }
            });

        this.store.dispatch(
            loadCandidateQualifications({ candidateId: this.candidateId, isSubContractor: this.isSubContractor })
        );
        this.qualifications$ = this.store.select(selectCandidateQualifications);
    }
}
