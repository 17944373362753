/**angular */
import { Injectable } from '@angular/core';
import { downloadBlob } from 'src/app/core/utils';

/**ngrx and rxjs */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

/**local */
import * as actions from '../actions/submittals.actions';
import { SubmittalsService } from 'src/app/admin/submittals/services/submittals.service';
import * as selectors from 'src/app/admin/store/selectors/submittals.selectors';

/**shared */
import { SortTypes } from 'src/app/shared/models';
import { SubmittalsExportAll } from 'src/app/admin/submittals/models/submittals-export-all.model';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { setSubmittalSubStatusFilter } from 'src/app/shared/utilities/submittal-filters';

@Injectable()
export class SubmittalsEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store<{}>,
        private readonly _submittalsService: SubmittalsService
    ) {}

    exportSubmittals$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.exportSubmittals),
            withLatestFrom(this._store$.select(selectors.selectSubmittalsQueryForExport)),
            switchMap(([action, query]) => {
                let filterRequest = {} as SubmittalsExportAll;
                query?.filter?.filters?.forEach((f) => {
                    const filter = f as FilterDescriptor;
                    if (filter.field === 'candidateName') {
                        filterRequest.candidate = filter?.value;
                    } else if (filter.field === 'facility') {
                        filterRequest.facility = filter?.value;
                    } else if (filter.field === 'vendor') {
                        filterRequest.vendor = filter?.value;
                    } else if (filter.field === 'profession') {
                        filterRequest.profession = filter?.value;
                    } else if (filter.field === 'specialty') {
                        filterRequest.expertise = filter?.value;
                    } else if (filter.field === 'stage') {
                        filterRequest.stage = filter?.value;
                    }
                });

                return this._submittalsService.exportAll(filterRequest).pipe(
                    map((blob) => actions.exportSubmittalsSuccess({ result: blob })),
                    catchError((error) => of(actions.exportSubmittalsFailure({ error })))
                );
            })
        )
    );

    downloadSubmittalsReport$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.exportSubmittalsSuccess),
                map((action) => downloadBlob(action.result, 'submittals_export.xlsx'))
            ),
        { dispatch: false }
    );

    loadSubmittals_V2$ = createEffect((): any =>
        this._actions$.pipe(
            ofType(actions.componentLoaded, actions.loadSubmittals, actions.setSubmittalsSearchQuery_V2),
            withLatestFrom(
                this._store$.pipe(select(selectors.selectAdminSubmittalsSearchQuery)),
                this._store$.pipe(select(selectors.selectSubmittalAdminLookupsSubStatusesCount))
            ),
            switchMap(([action, query, subStatusCount]) => {
                const sortCondition =
                    query.sort && query.sort.length
                        ? query.sort.map((q) => {
                              return q;
                          })
                        : query.sort;

                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };
                //on first select there are no sort args so it errors out. Need to load default
                let sortArgs = {};
                if (sortCondition.length === 0) {
                    sortArgs = {
                        sortField: 'submitted',
                        sortType: SortTypes.DESC
                    };
                } else {
                    sortArgs = {
                        sortField: sortCondition && sortCondition[0].field,
                        sortType: sortCondition && (sortCondition[0].dir as SortTypes)
                    };
                }

                //For select all we want to also select the submittals w/out substatuses
                setSubmittalSubStatusFilter(query, subStatusCount);

                const matchArgs = flattenFilter(query.filter);

                return this._submittalsService.getSubmittals_V2(pagination, sortArgs, matchArgs).pipe(
                    map((res) => actions.loadSubmittalsSuccess({ submittals: res })),
                    catchError((error) => of(actions.loadSubmittalsFailure({ error })))
                );
            })
        )
    );

    getLookupsStatuses$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadSubmittalsLookupStatuses),
            exhaustMap((action) =>
                this._submittalsService.getSubmittalStatuses().pipe(
                    map((res) =>
                        res
                            ? actions.loadSubmittalsLookupStatusesSuccess({ statuses: res })
                            : actions.loadSubmittalsLookupStatusesFail({
                                  error: 'Cannot load submittal statuses.'
                              })
                    ),
                    catchError((error) =>
                        of(
                            actions.loadSubmittalsLookupStatusesFail({
                                error
                            })
                        )
                    )
                )
            )
        )
    );

    getLookupsSubStatuses$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadSubmittalsLookupSubStatuses),
            exhaustMap((action) =>
                this._submittalsService.getSubmittalSubStatuses(action.statusId).pipe(
                    map((res) =>
                        res
                            ? actions.loadSubmittalsLookupSubStatusesSuccess({ subStatuses: res })
                            : actions.loadSubmittalsLookupSubStatusesFail({
                                  error: 'Cannot load submittal sub statuses.'
                              })
                    ),
                    catchError((error) =>
                        of(
                            actions.loadSubmittalsLookupSubStatusesFail({
                                error
                            })
                        )
                    )
                )
            )
        )
    );
}
