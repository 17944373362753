import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import { loadInvoiceDocument, loadInvoiceDocumentSuccess, loadInvoiceDocumentFail } from '../invoices.actions';
import { of } from 'rxjs';
import { downloadBlob } from 'src/app/core/utils';
import { ToasterService } from 'src/app/core/services';

@Injectable()
export class InvoicesDocumentEffect {
    loadInvoices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadInvoiceDocument),
            switchMap((action) =>
                this.invoicesRepository.getInvoiceDocument(action.id).pipe(
                    map((response) => {
                        const fileName = response.headers
                            .get('file-name')
                            .replace(/^\"(.+)/g, '$1')
                            .replace(/(.+)\"$/g, '$1');
                        const documentName = fileName.startsWith(' ') ? `${action.brandName}${fileName}` : fileName;
                        return loadInvoiceDocumentSuccess({
                            blob: response.body,
                            filename: documentName
                        });
                    }),
                    catchError((error) => of(loadInvoiceDocumentFail({ error })))
                )
            )
        )
    );

    openInvoiceDocuemnt$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadInvoiceDocumentSuccess),
                tap((action) => {
                    downloadBlob(action.blob, action.filename);
                })
            ),
        { dispatch: false }
    );

    loadInvoicesFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadInvoiceDocumentFail),
                tap((action) => this.toaster.fail('Failed to download document'))
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly invoicesRepository: InvoicesRepositoryService,
        private readonly toaster: ToasterService
    ) {}
}
