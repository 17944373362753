import { createAction, props } from '@ngrx/store';
import {
    AdminFacilityFeatures,
    FacilityAdministration,
    FacilityFeatureItems
} from 'src/app/facilities/models/facility-administration.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { PagingToken } from 'src/app/shared/models/paging-token';

export enum FacilitiesListActionEnum {
    LoadFacilitiesList = '[FacilitiesList] Load Facilities List',
    LoadFacilitiesListSuccess = '[FacilitiesList] Load Facilities List Success',
    LoadFacilitiesListFailure = '[FacilitiesList] Load Facilities List Failure',
    SetFacilitiesListSearchQuery = '[FacilitiesList] Set Facilities List Search Query',
    ClearFacilitiesList = '[FacilitiesList] Clear Facilities List',

    ExportFacilitiesList = '[FacilitiesList] Export Facilities List',
    ExportFacilitiesListSuccess = '[FacilitiesList] Export Facilities List Success',
    ExportFacilitiesListFailure = '[FacilitiesList] Export Facilities List Failure',

    LoadFacilitiesFeatures = '[FacilitiesList] Load Facilities Features',
    LoadFacilitiesFeaturesSuccess = '[FacilitiesList] Load Facilities Features Success',
    LoadFacilitiesFeaturesFailure = '[FacilitiesList] Load Facilities Features Failure'
}

export const loadFacilitiesList = createAction(FacilitiesListActionEnum.LoadFacilitiesList);
export const loadFacilitiesListSuccess = createAction(
    FacilitiesListActionEnum.LoadFacilitiesListSuccess,
    props<{ facilities: PagingToken<FacilityAdministration[]> }>()
);
export const loadFacilitiesListFailure = createAction(
    FacilitiesListActionEnum.LoadFacilitiesListFailure,
    props<{ error: any }>()
);
export const setFacilitiesListSearchQuery = createAction(
    FacilitiesListActionEnum.SetFacilitiesListSearchQuery,
    props<{ searchQuery: GridSearchQuery }>()
);
export const clearFacilitiesList = createAction(FacilitiesListActionEnum.ClearFacilitiesList);

export const exportFacilitiesList = createAction(FacilitiesListActionEnum.ExportFacilitiesList);
export const exportFacilitiesListSuccess = createAction(FacilitiesListActionEnum.ExportFacilitiesListSuccess);
export const exportFacilitiesListFailure = createAction(
    FacilitiesListActionEnum.ExportFacilitiesListFailure,
    props<{ error: any }>()
);

export const loadFacilitiesFeatures = createAction(FacilitiesListActionEnum.LoadFacilitiesFeatures);
export const loadFacilitiesFeaturesSuccess = createAction(
    FacilitiesListActionEnum.LoadFacilitiesFeaturesSuccess,
    props<{ features: FacilityFeatureItems[] }>()
);
export const loadFacilitiesFeaturesFailure = createAction(
    FacilitiesListActionEnum.LoadFacilitiesFeaturesFailure,
    props<{ error: any }>()
);
