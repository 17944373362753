import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UnsubscribeOnDestroy } from '../../core/utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../core/state';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomSystemFieldLookup } from '../../core/models/system-custom-field.model';

@Directive({
    selector: '[hasCustomSystemField]'
})
export class HasCustomSystemFieldDirective extends UnsubscribeOnDestroy implements OnInit {
    input$ = new BehaviorSubject<string | string[]>('');
    inputOperation$ = new BehaviorSubject<'OR' | 'AND'>('AND');

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly store: Store<{}>
    ) {
        super();
    }

    ngOnInit() {
        this.store
            .pipe(
                select(fromCore.getCustomSystemFields),
                switchMap((settings) =>
                    combineLatest([this.input$, this.inputOperation$]).pipe(
                        map(([input, op]) => this.hasField(settings, input, op))
                    )
                ),
                distinctUntilChanged(),
                takeUntil(this.d$)
            )
            .subscribe((hasSettings) => {
                if (hasSettings) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }

    @Input()
    set hasCustomSystemField(val) {
        this.input$.next(val);
    }

    @Input()
    set hasCustomSystemFieldOperation(op) {
        this.inputOperation$.next(op);
    }

    hasField(fields: CustomSystemFieldLookup[], input: string | string[], op: 'OR' | 'AND' | 'NOT') {
        if (!input) {
            return false;
        }

        const inputSettings = Array.isArray(input) ? input : [input];
        const compare = (fieldName) =>
            fields.some((s) => {
                return s.name.toLowerCase() === fieldName.toLowerCase();
            });

        switch (op) {
            case 'OR':
                return inputSettings.some(compare);
            case 'NOT':
                return !inputSettings.some(compare);
            case 'AND':
            default:
                return Boolean(fields.length) && inputSettings.every(compare);
        }
    }
}
