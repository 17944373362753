import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings, APP_CONFIG } from 'src/app/config/settings';
import { Observable } from 'rxjs';
import { ShiftAttachment } from '../models';
import { interpolateParams, interpolateUrl } from 'src/app/core/utils';
import { FileAttachment } from 'src/app/shared/models/attachment';

export interface FileValidationResult {
    name: string;
    isValid?: boolean;
    message?: string;
}

enum ShiftAttachemntsUrls {
    ALL = '/AyaConnect/Client/Shifts/{shiftId}/Attachments',
    ONE = '/AyaConnect/Client/Shifts/{shiftId}/Attachments/{id}',
    PREVIEW = '/AyaConnect/Client/Shifts/{shiftId}/Attachments/preview/{id}',
    TOTAL = '/AyaConnect/Client/Shifts/{shiftId}/Attachments/pageCount/{id}',
    ADD = '/AyaConnect/Client/Shifts/{shiftId}/Attachments',
    UPDATE = '/AyaConnect/Client/Shifts/{shiftId}/Attachments/{id}',
    DELETE = '/AyaConnect/Client/Shifts/{shiftId}/Attachments/{id}',

    ALL_SERIES = '/AyaConnect/Client/Shifts/Series/{seriesId}/Attachments',
    ADD_SERIES = '/AyaConnect/Client/Shifts/Series/{seriesId}/Attachments'
}

@Injectable({
    providedIn: 'root'
})
export class ShiftsAttachmentsService {
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings
    ) {}

    getAttachments(shiftId: number): Observable<ShiftAttachment[]> {
        const url = interpolateUrl(ShiftAttachemntsUrls.ALL, { shiftId });

        return this.http.get<ShiftAttachment[]>(`${this.settings.CORE}${url}`);
    }

    getSeriesAttachments(seriesId: string): Observable<ShiftAttachment[]> {
        const url = interpolateUrl(ShiftAttachemntsUrls.ALL_SERIES, { seriesId });

        return this.http.get<ShiftAttachment[]>(`${this.settings.CORE}${url}`);
    }

    getAttachmentBlob(shiftId: number, id: number): Observable<Blob> {
        const url = interpolateUrl(ShiftAttachemntsUrls.ONE, { shiftId, id });

        return this.http.get(`${this.settings.CORE}${url}`, { responseType: 'blob' });
    }

    getPreviewAttachmentBlob(shiftId: number, id: number, pageNum: number): Observable<Blob> {
        const url = interpolateUrl(ShiftAttachemntsUrls.PREVIEW, { shiftId, id });
        const params = interpolateParams({ pageNum });

        return this.http.get(`${this.settings.CORE}${url}`, { params: params, responseType: 'blob' });
    }

    getTotalNumOfDoc(shiftId: number, id: number): Observable<number> {
        const url = interpolateUrl(ShiftAttachemntsUrls.TOTAL, { shiftId, id });

        return this.http.get<number>(`${this.settings.CORE}${url}`);
    }

    postAttachment(shiftId: number, fileToUpload: any, internalOnly: boolean): Observable<FileValidationResult> {
        const input = new FormData();
        input.append('file', fileToUpload);

        const url = interpolateUrl(ShiftAttachemntsUrls.ADD, { shiftId });
        const params = interpolateParams({ internalOnly });

        return this.http.post<FileValidationResult>(`${this.settings.CORE}${url}`, input, { params });
    }

    postSeriesAttachment(seriesId: string, fileToUpload: any, internalOnly: boolean): Observable<FileValidationResult> {
        const input = new FormData();
        input.append('file', fileToUpload);

        const url = interpolateUrl(ShiftAttachemntsUrls.ADD_SERIES, { seriesId });
        const params = interpolateParams({ internalOnly });

        return this.http.post<FileValidationResult>(`${this.settings.CORE}${url}`, input, { params });
    }

    updateAttachment(shiftId: number, fileToUpload: FileAttachment, internalOnly: boolean): Observable<number> {
        const url = interpolateUrl(ShiftAttachemntsUrls.UPDATE, { shiftId, id: fileToUpload.id });
        const input = new FormData();
        input.append('id', String(fileToUpload.id));
        input.append('fileName', fileToUpload.fileName);
        input.append('internalOnly', String(internalOnly));

        return this.http.put<number>(`${this.settings.CORE}${url}`, input);
    }

    deleteAttachment(shiftId: number, id: number): Observable<unknown> {
        const url = interpolateUrl(ShiftAttachemntsUrls.UPDATE, { shiftId, id });

        return this.http.delete<unknown>(`${this.settings.CORE}${url}`);
    }

    urlPreview(shiftId: number, id: number) {
        return interpolateUrl(ShiftAttachemntsUrls.PREVIEW, { shiftId, id });
    }
}
