import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { configurations } from 'src/app/config/domain-config';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { UserSecurity } from 'src/app/core/auth/models/user-security.model';
import {
    DomainConfiguration,
    Identity,
    Ticket,
    UserType,
    UserIdentity,
    InternalUserType,
    UserLoginDefaults
} from 'src/app/shared/models';
import * as coreActions from 'src/app/core/state/core.actions';
import { Store } from '@ngrx/store';
import { AuthService, GenericError } from '@auth0/auth0-angular';
import { UserProfile } from 'src/app/shared/models/account/user-profile.model';
import { ToasterService } from 'src/app/core/services';
import { TimecardsStatusEnum } from 'src/app/timecards/models';
import { TimecardPermissionsEnum } from 'src/app/timecards/models/enums/timecard-permissions.enum';
import { UDOnlyLinkSecurityDto } from 'src/app/clinical/models/aya-link-security';
import { AuthErrors } from '../models/enums/auth-errors.enum';

const LOCAL_STORAGE_KEY = {
    AccessTicketParamName: 'access_ticket',
    SecurityListParamName: 'security_list',
    ActiveProfile: 'active_profile'
};

@Injectable()
export class IdentityService {
    private _securityList: UserSecurity[] | null = null;
    private _identity: Identity = {
        userId: null,
        role: '',
        type: '',
        vendorId: null,
        clientId: null,
        scope: '',
        appModules: [],
        coreUserId: null,
        userName: '',
        email: '',
        fullname: '',
        firstName: '',
        defaultURL: null,
        oldUserId: null,
        ticket: null
    };

    constructor(
        @Inject('Window') private readonly _window: Window,
        @Inject(APP_CONFIG) private readonly _settings: Settings,
        private readonly _http: HttpClient,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _authService: AuthService,
        private readonly _store: Store,
        private readonly _toasterService: ToasterService
    ) {
        this.initAuthErrorTracker();
        this.loadTicketFromLocalStorage();
    }

    private readonly _allowedAnonymousPages = ['/units', '/eval-form'];
    private readonly _loginPages = ['/sso', '/login', '/signin'];
    initAuthErrorTracker() {
        this._authService.error$
            .pipe(
                map((e: GenericError) => {
                    const pathRoute = this.getCurrentLocationPath().toLowerCase();
                    const isAnonymousPage = this._allowedAnonymousPages
                        .map((p) => p.toLowerCase())
                        .some((p) => pathRoute.startsWith(p));
                    const isLoginPage = this._loginPages
                        .map((p) => p.toLowerCase())
                        .some((p) => pathRoute.startsWith(p));
                    return { error: e.error, isAnonymousPage, isLoginPage };
                }),
                tap(({ error, isAnonymousPage }) => {
                    if (isAnonymousPage && error === AuthErrors.InvalidGrant) {
                        this.clearCachedTokens();
                        this._window.location.reload();
                    }
                }),
                filter(
                    ({ error, isAnonymousPage, isLoginPage }) =>
                        (error === AuthErrors.LoginRequired ||
                            error === AuthErrors.InvalidGrant ||
                            error === AuthErrors.MissingRefreshToken) &&
                        !isAnonymousPage &&
                        !isLoginPage
                ),
                switchMap(({ error }) =>
                    this._authService.isAuthenticated$.pipe(
                        map((isAuthenticated) => {
                            return {
                                loginRequired: error === AuthErrors.LoginRequired || error === AuthErrors.InvalidGrant,
                                unauthorized: !isAuthenticated
                            };
                        })
                    )
                ),
                filter(({ loginRequired, unauthorized }) => loginRequired || unauthorized),
                take(1)
            )
            .subscribe(() => this.redirectToSignInPage());
    }

    get vendorId(): number {
        return this._identity.vendorId;
    }

    get clientId(): number | null {
        return this._identity.clientId;
    }

    get userId(): number | null {
        return this._identity.userId;
    }

    get oldUserId(): number | null {
        return this._identity.oldUserId;
    }

    get userIdentity(): UserIdentity {
        return {
            role: this._identity.role,
            type: this._identity.type,
            vendorId: this._identity.vendorId,
            clientId: this._identity.clientId,
            userId: this._identity.userId,
            coreUserId: this._identity.coreUserId,
            fullname: this._identity.fullname,
            firstName: this._identity.firstName,
            userName: this._identity.userName,
            email: this._identity.email
        };
    }

    get type(): UserType {
        return this._identity.type;
    }

    get userType(): InternalUserType | null {
        return this._identity.userType;
    }

    get userName(): string {
        return this._identity.userName;
    }

    get userFullName(): string {
        return this._identity.fullname;
    }

    get ticket(): Ticket | null {
        return this._identity?.ticket || null;
    }

    get appModules(): string[] {
        return this._identity.appModules;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get defaultURL(): string | null {
        return this._identity.defaultURL;
    }

    set defaultURL(defaultUrl: string) {
        this._identity.defaultURL = defaultUrl;
    }

    authorize(controller: string, actions: string[] | null, skipAuthorization: boolean): boolean {
        let authorized = false;
        controller = controller.replace(/_angjs/g, '');

        if (!this.isSignedIn()) {
            return false;
        } else if (skipAuthorization) {
            return true;
        }

        actions?.forEach((action: string) => {
            if (this._identity.scope.indexOf(`${controller}_${action}`) > -1) {
                authorized = true;
            }
        });

        return authorized;
    }

    inScope(module: string, permission: string): boolean {
        return this._identity.appModules.indexOf(`${module}.${permission}`) !== -1;
    }

    inScopeByModule(module: string): boolean {
        return this._identity.scope.includes(module);
    }

    isSignedIn(): boolean {
        return !!this._identity?.userId;
    }

    isSystemLogin(): boolean {
        return this.type.toLowerCase() === 'system';
    }

    isClientLogin(): boolean {
        return this.type.toLowerCase() === 'client';
    }

    isAdminLogin(): boolean {
        return this.type.toLowerCase() === 'admin';
    }

    isVendorLogin(): boolean {
        return this.type.toLowerCase() === 'vendor';
    }

    getSecurityGroups(): UserSecurity[] {
        if (!this._securityList) {
            this._securityList = JSON.parse(this._window.localStorage.getItem(LOCAL_STORAGE_KEY.SecurityListParamName));
        }
        return this._securityList || [];
    }

    hasUnitLevelOnlyPermissions(): boolean {
        const securityGroups = this.getSecurityGroups();
        const hasUnitIds = securityGroups.some((security) => security.unitIds.length > 0);
        const allFacilityIdsEmpty = securityGroups.every((security) => security.facilityIds.length === 0);
        const allSystemIdsEmpty = securityGroups.every((security) => security.systemIds.length === 0);

        return hasUnitIds && allFacilityIdsEmpty && allSystemIdsEmpty;
    }

    getSecurity(): UserSecurity {
        const securityGroups = this.getSecurityGroups();

        if (securityGroups && securityGroups.length) {
            return securityGroups[0];
        }

        return null;
    }

    getResetTokenCore(username: string, password: string): Promise<any> {
        const request = {
            userName: username.toLowerCase(),
            password
        };

        return this._http.post(`${this._settings.CORE}/Connect/PasswordResetToken`, request).toPromise();
    }

    getSignOutUrl(keepState: boolean = false): string {
        const redirectUrl: string = encodeURIComponent(this._router.url);
        const key = this._settings.TEST_ENVIRONMENT ? `${this._settings.SERVER_NAME}_SIGN_OUT_URL` : 'SIGN_OUT_URL';
        const signOutUrl: string = configurations.find((config: DomainConfiguration) => config.key === key)?.value;
        const url: string = keepState && redirectUrl ? `${signOutUrl}?redirectUrl=${redirectUrl}` : signOutUrl;

        return url;
    }

    hasSecurityPermission(permissionId: number): boolean {
        return this.getSecurityGroups().some((security: UserSecurity) => security.permissions.includes(permissionId));
    }

    hasAllSecurityPermissions(permissionIds: number[]): boolean {
        return this.getSecurityGroups().some((security: UserSecurity) =>
            permissionIds.every((permissionId) => security.permissions.includes(permissionId))
        );
    }

    hasAnySecurityPermissions(permissionIds: number[]): boolean {
        return this.getSecurityGroups().some((security: UserSecurity) =>
            permissionIds.some((permissionId) => security.permissions.includes(permissionId))
        );
    }

    redirectToSignInPage(): void {
        const requestedBy = this._route.snapshot.queryParamMap?.get('requestedBy');
        if (requestedBy) {
            this.signInToAuthorizedPage(requestedBy);
        } else {
            this._navigateToAuth();
        }
    }

    signInToAuthorizedPage(requestedBy: string) {
        const targetPath = this.getCurrentLocationPath();

        const getIdpUrl = `${this._settings.CORE}/sso/identity-providers/user/${requestedBy}`;

        this._http
            .get(getIdpUrl, { responseType: 'text' })
            .pipe(take(1))
            .subscribe((identityProvider) => {
                if (identityProvider) {
                    this._router.navigateByUrl(`/sso/${identityProvider}?target=${encodeURI(targetPath)}`);
                } else {
                    this._navigateToAuth();
                }
            });
    }

    //Clears any expired auth data. Ex: refresh token.
    //For more info please check: "Clear Expired Refresh token before login"
    clearCachedTokens() {
        const keys = Object.keys(this._window.localStorage);
        keys.forEach((key) => {
            if (key.startsWith(`@@auth0`)) {
                this._window.localStorage.removeItem(key);
            }
        });
    }

    getCurrentLocationPath() {
        const path = this._window?.location?.hash?.replace('#', '') ?? this._window?.location?.pathname ?? '';
        return path.split('?')[0];
    }

    signOut(): void {
        this._store.dispatch(new coreActions.Logout());
    }

    goToDefaultUrl(): void {
        const location = new URL(this._window.location.href);
        const url = location.searchParams.get('redirectUrl') || this._identity.defaultURL;
        this._router.navigateByUrl(url);
    }

    delete(): void {
        this.removeAccessTicket();
        this._identity.userId = null;
        this._identity.role = '';
        this._identity.type = '';
        this._identity.vendorId = null;
        this._identity.clientId = null;
        this._identity.scope = '';
        this._identity.appModules = [];
        this._identity.coreUserId = null;
        this._identity.userName = '';
        this._identity.email = '';
        this._identity.fullname = '';
        this._identity.firstName = '';
    }

    saveConnectUserInfoToStorage(info: string): void {
        this._window.localStorage.setItem(LOCAL_STORAGE_KEY.AccessTicketParamName, info);
    }

    saveSecurityList(securityList: UserSecurity[]): void {
        this._window.localStorage.setItem(LOCAL_STORAGE_KEY.SecurityListParamName, JSON.stringify(securityList));
        this._securityList = securityList;
    }

    parseUserInformation(accessData: string): void {
        this._identity = this.parseTicket(accessData);
    }

    loadTicketFromLocalStorage(): void {
        const ticketAccessData = this._window.localStorage.getItem(LOCAL_STORAGE_KEY.AccessTicketParamName);
        let accessTicketIsValid = false;

        if (this.isJSON(ticketAccessData)) {
            this.parseUserInformation(ticketAccessData);
            accessTicketIsValid = !!this._identity?.userId;
        }

        if (!accessTicketIsValid) {
            this.removeAccessTicket();
        }
    }

    removeAccessTicket(): void {
        this._window.localStorage.removeItem(LOCAL_STORAGE_KEY.AccessTicketParamName);
    }

    async oldChangePassword(user): Promise<{ code: number }> {
        return this.changePassword(user).toPromise();
    }

    changePassword(payload: {
        UserName: string;
        OldPassword: string;
        NewPassword: string;
    }): Observable<{ code: number }> {
        return this._http
            .post<{ code: number }>(`${this._settings.CORE}/Connect/UpdatePassword`, {
                username: payload.UserName.toLowerCase(),
                newPassword: payload.NewPassword
            })
            .pipe(
                map(() => {
                    return { code: 200 };
                }),
                catchError(() => {
                    return of({ code: 500 });
                })
            );
    }

    getProfiles(): Observable<UserProfile[]> {
        return this._http.get<UserProfile[]>(`${this._settings.CORE}/AyaConnect/Auth/profiles`);
    }

    listenProfileChanged(activeProfile: string) {
        const localActiveProfile = this._window.localStorage.getItem(LOCAL_STORAGE_KEY.ActiveProfile);
        if (activeProfile !== localActiveProfile) {
            this._window.localStorage.setItem(LOCAL_STORAGE_KEY.ActiveProfile, activeProfile);
        }

        if (this._window.addEventListener) {
            this._window.addEventListener('storage', this._profileChangedEventHandler, false);
        }
    }

    removeProfileChangedListener() {
        this._window.removeEventListener('storage', this._profileChangedEventHandler, false);
    }

    changeProfile(userId: string, impersonate: boolean = false) {
        let url = `${this._settings.CORE}/auth-user/profiles/${userId}`;
        if (impersonate) {
            url += '/impersonate';
        }
        this._http
            .put<string>(url, null, {
                responseType: 'text' as any
            })
            .pipe(
                switchMap(() => this._authService.user$),
                take(1)
            )
            .subscribe(
                (u) => {
                    this.delete();
                    const path =
                        u?.connection && u.connection !== this._settings.AUTH_CONNECTION
                            ? `/sso/${u.connection}`
                            : '/login';
                    this._router.navigateByUrl(path);
                },
                (error) => {
                    const errorMessage =
                        error?.status == 403
                            ? 'Users profile status is not currently active. Please activate users profile status.'
                            : 'Impersonation failed, please contact IT support to resolve the impersonation error.';
                    this._toasterService.fail(errorMessage);
                }
            );
    }

    checkUserLoginDefaults(email: string): Observable<UserLoginDefaults> {
        if (!email) {
            return of({ defaultConnection: true, defaultOrganization: true });
        }
        const url = `${this._settings.CORE}/sso/check-user-login-defaults`;
        const option = { headers: { email } };
        return this._http.get<UserLoginDefaults>(url, option);
    }

    stopImpersonation(mainUserId: string): Observable<any> {
        const url = `${this._settings.CORE}/auth-user/profiles/${mainUserId}`;
        return this._http.put<string>(url, null, {
            responseType: 'text' as any
        });
    }

    getUserUnitPermissionsForInvoicing(): number[] {
        const userSecurityArray = this.getSecurityGroups();
        // Use sets for better performance and to avoid duplicates.
        const unitIdSet = new Set<number>();

        userSecurityArray.forEach((userSecurity) => {
            const childUnitIdArray = userSecurity.unitIds;

            childUnitIdArray.forEach((unitId) => unitIdSet.add(unitId));
        });
        return [...unitIdSet];
    }

    getUserTimecardStatusPermissions(mapToTimecardSTatus: boolean): number[] {
        const userSecurityArray = this.getSecurityGroups();
        const permIdSet = new Set<number>();

        for (const userSecurity of userSecurityArray) {
            // Use sets for better performance and to avoid duplicates. Deconstruct into array.
            for (const perm of userSecurity.permissions) {
                if (Object.values(TimecardPermissionsEnum).includes(perm)) {
                    permIdSet.add(perm);
                }
            }
        }

        if (!mapToTimecardSTatus) {
            return [...permIdSet];
        } else {
            return this.mapDbPermIdsToTimecardStatusEnumIds([...permIdSet]);
        }
    }

    hasWfdPermissionToViewRestrictedFields(facilityId: number): boolean {
        // Check permission for ANY Facility ANY Unit
        const permissionIdForHideWfdRestrictedFields = 9206;
        const hasPermissionToView = this.hasSecurityPermission(permissionIdForHideWfdRestrictedFields);
        const hasPermissionForAllFacilities = this.getSecurityGroups().some(
            (security: UserSecurity) =>
                security.permissions.includes(permissionIdForHideWfdRestrictedFields) &&
                security.facilityIds.length === 0
        );
        const hasPermissionForSomeFacilitiesSomeUnits = this.getSecurityGroups().some(
            (security: UserSecurity) =>
                security.permissions.includes(permissionIdForHideWfdRestrictedFields) &&
                security.facilityIds.includes(facilityId)
        );
        return hasPermissionToView && (hasPermissionForAllFacilities || hasPermissionForSomeFacilitiesSomeUnits);
    }

    async requestTwoFactorAuthenticationCodeCore(username: string): Promise<any> {
        const request = {
            userName: username.toLowerCase()
        };

        return this._http.post(`${this._settings.CORE}/Connect/SendTwoFactorAuthenticationCode`, request).toPromise();
    }

    async checkUserName(username: string): Promise<{ code: number; found: boolean | null }> {
        const url = `${this._settings.CORE}/ayaconnect/auth/connect-user-exists?userName=${username.trim()}`;

        const checkUsername$ = this._http.get<{ code: number; found: boolean | null }>(url).pipe(
            map(() => {
                return { code: 200, found: true };
            })
        );

        return firstValueFrom(checkUsername$);
    }

    private parseTicket(accessData: string | null): Identity | null {
        if (!accessData) {
            throw new Error('Error reading access ticket.');
        }

        const ticket: Ticket = JSON.parse(accessData);
        const type = ticket.type.toLowerCase() as UserType;
        const scope = this.parseScopeItems(ticket.scope, type);

        return {
            ...this._identity,
            ticket,
            scope,
            role: ticket.role,
            type,
            clientId: ticket.clientId,
            vendorId: ticket.vendorId,
            coreUserId: ticket.coreUserId,
            userName: ticket.username,
            email: ticket.email,
            userId: ticket.userId,
            oldUserId: ticket.oldUserId,
            userType: ticket.userType,
            fullname: ticket.fullname,
            firstName: ticket.fullname.split(' ')[0],
            defaultURL: ticket && ticket.defaultURL ? ticket.defaultURL.replace(/^sar\./, 'vendor.') : '/admin/jobs',
            appModules: this.parseAppModules(scope)
        };
    }

    private parseScopeItems(scope: string, type: UserType): string {
        const scopeItems = scope.split(',').map((item) => item.trim().replace(/^sar\./, 'vendor.'));

        if (type === 'client' || type === 'system') {
            scopeItems.push('client.contacts_c');
        }

        return scopeItems.join(',');
    }

    private parseAppModules(scope: string): string[] {
        const scopeItems = scope.split(',');
        return Array.from(
            new Set(
                scopeItems.map((item) =>
                    item
                        .trim()
                        .replace(/^core\./, '')
                        .replace(/_c$/, '')
                        .replace(/_r$/, '')
                        .replace(/_u$/, '')
                        .replace(/_d$/, '')
                )
            )
        ).sort();
    }

    private isJSON(value: string): boolean {
        try {
            return JSON.parse(value) && !!value;
        } catch (e: unknown) {
            throw new Error('Invalid ticket data.');
        }
    }

    private readonly _profileChangedEventHandler = (e) => {
        this._profileChangedEvent(e);
    };

    private _profileChangedEvent(e) {
        if (e.key === LOCAL_STORAGE_KEY.ActiveProfile && e.newValue && e.newValue !== e.oldValue) {
            this._window.removeEventListener('storage', this._profileChangedEventHandler, false);
            this._window.location.href = `${this._window.origin}/#/signin?tab-reload=true`;
        }
    }

    private _navigateToAuth() {
        const targetPath = this._router.url;

        if (targetPath.length > 0) {
            this._router.navigateByUrl(`/login?target=${encodeURIComponent(targetPath)}`);
        } else {
            this._router.navigateByUrl('/login');
        }
    }

    private mapDbPermIdsToTimecardStatusEnumIds(notMappedPermIds: number[]): number[] {
        const mappedIds: number[] = [];

        if (
            notMappedPermIds.includes(TimecardPermissionsEnum.CONNECT_TIMECARDS_VIEW) &&
            notMappedPermIds.includes(TimecardPermissionsEnum.CONNECT_TIMECARDS_DETAIL)
        ) {
            mappedIds.push(TimecardsStatusEnum.UNSUBMITTED);
            mappedIds.push(TimecardsStatusEnum.SUBMITTED);
            mappedIds.push(TimecardsStatusEnum.PROCESSED);
            mappedIds.push(TimecardsStatusEnum.UNCREATED);
            mappedIds.push(TimecardsStatusEnum.APPROVED);

            notMappedPermIds.forEach((id) => {
                switch (id) {
                    case TimecardPermissionsEnum.TIMECARDS_TIERONE_APPROVE:
                        mappedIds.push(TimecardsStatusEnum.PENDING_TIER_1_APPROVAL);
                        break;
                    case TimecardPermissionsEnum.TIMECARDS_TIERTWO_APPROVE:
                        mappedIds.push(TimecardsStatusEnum.PENDING_TIER_2_APPROVAL);
                        break;
                    case TimecardPermissionsEnum.TIMECARDS_TIERONE_REJECT:
                        mappedIds.push(TimecardsStatusEnum.REJECTED_TIER_1);
                        break;
                    case TimecardPermissionsEnum.TIMECARDS_TIERTWO_REJECT:
                        mappedIds.push(TimecardsStatusEnum.REJECTED_TIER_2);
                        break;
                    default:
                        break;
                }
            });
        }

        return mappedIds;
    }

    getUDOnlyLinkSecurityToken(UDOnlyLinkSecurityData: UDOnlyLinkSecurityDto): Observable<string> {
        const url = `${this._settings.CORE}/UDOnlyLinkSecurity/token`;
        let headers = new HttpHeaders();
        headers = headers.set('connect_access_ticket', localStorage.getItem('access_ticket'));
        return this._http.post<string>(url, UDOnlyLinkSecurityData, { responseType: 'text' as 'json', headers });
    }
}
