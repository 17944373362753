import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { timer } from 'rxjs';

@Component({
    selector: 'ayac-chips-autocomplete',
    templateUrl: './chips-autocomplete.component.html',
    styleUrls: ['./chips-autocomplete.component.scss']
})
export class ChipsAutocompleteComponent extends UnsubscribeOnDestroy implements OnInit {
    // This is the only way to properly clear out the autocomplete inputs if attached to a mat-chip-list
    // See here: https://github.com/angular/components/issues/10968
    @ViewChild('chipsInput', { static: true }) chipsInput: ElementRef<HTMLInputElement>;

    @Input() inputControl: string | null = null;
    @Input() listControl: string | null = null;
    @Input() chipTitle: string = 'Chip';
    @Input() chipListItems: any[] = [];
    @Input() uniqueIdentifyingProperty: string = 'id';

    filteredChips: any[] = [];

    formGroup: UntypedFormGroup = new UntypedFormGroup({});
    separatorKeysCodes: number[] = [ENTER, COMMA];

    constructor(public readonly parentForm: FormGroupDirective) {
        super();
    }

    ngOnInit(): void {
        this.formGroup = this.parentForm.form;

        this.watchChipInput();
    }

    addChip(event: MatAutocompleteSelectedEvent): void {
        const chip: any | null = event.option?.value || null;

        if (chip && chip.name.includes('No results match')) {
            this.clearChipInput();
            return;
        }

        if (chip) {
            const chipList = this.formGroup.get(this.listControl)?.value;
            if (chipList) {
                chipList.push(chip);
                this.formGroup.patchValue({ chipList });
            } else {
                this.formGroup.get(this.listControl)?.setValue([chip], { emitEvent: true });
            }
            this.clearChipInput();
        } else {
            console.error(`${this.chipTitle} invalid or not found.`);
        }
    }

    removeChip(chip: any): void {
        const chipIndex: number = this.formGroup
            .get(this.listControl)
            ?.value?.findIndex(
                (existingChip: any) =>
                    existingChip[this.uniqueIdentifyingProperty] === chip[this.uniqueIdentifyingProperty]
            );

        if (chipIndex >= 0) {
            this.clearChipInput();
            this.formGroup.get(this.listControl).value.splice(chipIndex, 1);
            this.formGroup.get(this.listControl).updateValueAndValidity();
            this.formGroup.get(this.listControl).markAsDirty();
        } else {
            console.error(
                `Unable to find ${this.chipTitle} with matching ${this.uniqueIdentifyingProperty} + ' ' + '${
                    chip[this.uniqueIdentifyingProperty]
                }'`
            );
        }
    }

    clearChipInput(): void {
        const inputControl = this.formGroup.get(this.inputControl);
        const listControl = this.formGroup.get(this.listControl);

        inputControl?.setValue(null, { emitEvent: true });
        this.chipsInput.nativeElement.value = '';
        if (listControl.value.length) {
            listControl.setErrors(null);
            inputControl.setErrors({ invalidInput: null });
            inputControl.updateValueAndValidity();
        }
    }

    onChipInputBlur(): void {
        // Timer is required so it runs AFTER addChip() instead of before
        timer(100)
            .pipe(takeUntil(this.d$))
            .subscribe(() => {
                if (
                    this.formGroup.get(this.inputControl).value === undefined ||
                    this.formGroup.get(this.inputControl).value === null
                ) {
                    return;
                }

                if (this.filteredChips.length === 1 && this.filteredChips[0].name.includes('No results match')) {
                    this.formGroup.get(this.listControl).markAsPristine();
                }
                this.formGroup.get(this.inputControl).markAsPristine();
                this.clearChipInput();
            });
    }

    private watchChipInput(): void {
        this.formGroup
            ?.get(this.inputControl)
            .valueChanges.pipe(startWith(null as string), debounceTime(300), takeUntil(this.d$))
            .subscribe((inputValue: string) => {
                this.filteredChips = this.filterChips(inputValue);

                if (inputValue === '' || inputValue === null) {
                    return;
                }

                if (this.filteredChips.length === 0) {
                    this.filteredChips = [{ name: `No results match ${inputValue}` }];
                }
                this.formGroup.get(this.inputControl)?.markAsPristine();
                this.formGroup.get(this.inputControl)?.setErrors({ invalidInput: true });
            });
    }

    private filterChips(inputValue: string | null): any[] {
        const formChips = this.formGroup.get(this.listControl).value;

        if (formChips && this.chipListItems) {
            const uniqueIdentifiers = formChips.map((chip: any) => chip[this.uniqueIdentifyingProperty]);

            if (inputValue && typeof inputValue === 'string') {
                const filterValue = inputValue.toLowerCase();
                return this.chipListItems.filter(
                    (chip: any) =>
                        !uniqueIdentifiers.includes(chip[this.uniqueIdentifyingProperty]) &&
                        chip?.name.toLowerCase().includes(filterValue)
                );
            } else {
                return this.chipListItems.filter(
                    (chip: any) => !uniqueIdentifiers.includes(chip[this.uniqueIdentifyingProperty])
                );
            }
        }

        return this.chipListItems;
    }
}
