import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../services/dashboard.service';
import { withLatestFrom, catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getFilter } from '../selectors/filter.selectors';
import { loadShiftsSuccess, loadShiftsFail, loadShiftsCount } from '../actions';

@Injectable()
export class ShiftsEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<{}>,
        private readonly service: DashboardService
    ) {}

    getShifts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadShiftsCount),
            withLatestFrom(this.store$.select(getFilter)),
            switchMap(([action, query]) => {
                const filter = {
                    lineOfBusinessId: query.lineOfBusiness.id > 0 ? query.lineOfBusiness.id : null,
                    facilityProfileGroupId: query.facility.profileGroupId > 0 ? query.facility.profileGroupId : null,
                    unitId: query.unit.id > 0 ? query.unit.id : null,
                    facilityId: query.facility.id > 0 ? query.facility.id : null,
                    shiftRange: query.shiftRange.id
                };

                return this.service.getShiftsCount(filter).pipe(
                    map((response) =>
                        loadShiftsSuccess({
                            shifts: response
                        })
                    ),
                    catchError((error) => of(loadShiftsFail({ error })))
                );
            })
        )
    );
}
