import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClinicalState, clinicalStoreKey } from '../clinical.state';

export const selectFeature = createFeatureSelector<ClinicalState>(clinicalStoreKey);
export const selectClientUnitsSelectFeature = createSelector(selectFeature, (state) => state.ClientUnitsState);

export const selectClientUnits = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        data: state.clientUnits.data,
        filtered: state.clientUnits.filtered,
        total: state.clientUnits.total
    };
});

export const selectClientUnitsGridSearchQuery = createSelector(
    selectClientUnitsSelectFeature,
    (state) => state.gridSearchQuery
);

export const selectFacilityId = createSelector(selectClientUnitsSelectFeature, (state) => state.facilityId);

export const selectPhysicianSpecialties = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        physicianSpecialties: state.physicianSpecialties
    };
});

export const selectUnitBoardCertifications = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        boardCertifications: state.boardCertifications
    };
});

export const selectUnitInfo = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        unitInfo: state.unitInfo
    };
});

export const selectUnitStateLicenses = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        stateLicenses: state.stateLicenses
    };
});

export const selectUnitStateControlledSubstances = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        selectUnitStateControlledSubstances: state.unitStateControlledSubstances
    };
});

export const selectStates = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        states: state.states
    };
});

export const selectStatesControlledSubstances = createSelector(
    selectClientUnitsSelectFeature,
    selectStates,
    (state, states) => {
        return {
            states: states.states,
            stateControlledSubstances: state.unitStateControlledSubstances
        };
    }
);

export const selectNyuDepartments = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        nyuDepartments: state.nyuDepartments
    };
});

export const selectNyuFloors = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        nyuFloors: state.nyuFloors
    };
});

export const selectNyuBuildings = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        nyuBuildings: state.nyuBuildings
    };
});

export const selectUnitInfoWithLookUps = createSelector(
    selectUnitInfo,
    selectNyuBuildings,
    selectNyuDepartments,
    (unitInfo, nyuBuildings, nyuDepartments) => {
        return {
            unitInfo,
            nyuBuildings,
            nyuDepartments
        };
    }
);

export const selectSaveUnit = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        savedUnitId: state.savedUnitId
    };
});

export const selectSaveUnitExtraFields = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        saved: state.saved
    };
});

export const selectIsLoading = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        isLoading: state.isLoading
    };
});

export const selectUnitOtherRequirements = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        unitOtherRequirements: state.unitOtherRequirements
    };
});

export const selectOtherRequirements = createSelector(selectClientUnitsSelectFeature, (state) => {
    return {
        otherRequirements: state.otherRequirements
    };
});
