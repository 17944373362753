<div class="k-form">
    <label class="k-form-field">
        <span>{{labelAfter}}</span>
        <kendo-datepicker (valueChange)="onStartChange($event)" format="MM/dd/yyyy" [(ngModel)]="start" [max]="max">
        </kendo-datepicker>
    </label>
    <label class="k-form-field">
        <span>{{labelBefore}}</span>
        <kendo-datepicker (valueChange)="onEndChange($event)" format="MM/dd/yyyy" [(ngModel)]="end" [min]="min">
        </kendo-datepicker>
    </label>
</div>