import { Directive, forwardRef, Input, ElementRef } from '@angular/core';
import { MAT_LEGACY_INPUT_VALUE_ACCESSOR as MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/legacy-input';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { isNaN } from 'lodash';

const NON_NUMERIC_REGEX = /[^0-9\.]+/g;

// Brought the same functionality that is in NOVA into Connect.
// https://github.com/AyaHealthcare/Applications/blob/1573a9a83417e17b12d5674fadcabe1a985cdf15/Nova/src/app/shared/directives/mat-input-commified.directive.ts#L26
@Directive({
    selector: 'input[matInputCommified]',
    providers: [
        { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputCommifiedDirective },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MatInputCommifiedDirective),
            multi: true
        }
    ],
    host: {
        '(blur)': 'onBlur()',
        '(focus)': 'onFocus()',
        '(input)': 'onInput($event)',
        '(keypress)': 'onKeypress($event)'
    }
})
export class MatInputCommifiedDirective implements ControlValueAccessor {
    private _value: string | null;

    constructor(private _elementRef: ElementRef<HTMLInputElement>) {
        this._elementRef.nativeElement.tabIndex = 0;
    }

    get value(): string | null {
        return this._value;
    }

    @Input('value')
    set value(value: string | null) {
        this._value = value;
        this.formatValue(value);
    }

    numberWithCommas(x: any): string {
        const n: number = +x;
        if (n === 0 || isNaN(n)) return x;

        const minimumFractionDigits = this.decimalCount(x.toString());
        return n === 0 || isNaN(n)
            ? x
            : n.toLocaleString('en-us', {
                  minimumFractionDigits,
                  maximumFractionDigits: 9
              });
    }

    decimalCount(x: string): number {
        return x.includes('.') ? x.split('.')[1].length : 0;
    }

    onInput(event: unknown) {
        this._value = event['target']['value'].toString().replace(NON_NUMERIC_REGEX, '');
        this.onChange(this._value);
    }

    onKeypress(event: KeyboardEvent): void {
        if (!/^\d+$/.test(event.key) && event.key !== '.' && event.key !== 'Enter') {
            event.preventDefault();
            return;
        }

        if (event.shiftKey) {
            event.preventDefault();
            return;
        }

        if (event.key === 'Enter') {
            this.onBlur();
        }
    }

    onBlur(): void {
        this.formatValue(this._value);
        this.onTouched();
    }

    onFocus(): void {
        this.unFormatValue();
    }

    // Local functions to save the
    // passed in onChange and onTouched methods
    // These are called here to notify changes
    onChange(_value: any): void {}
    onTouched(): void {}

    writeValue(value: any) {
        this._value = value;
        this.formatValue(this._value); // format Value
    }

    registerOnChange(fn: (value: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    private formatValue(value: string | null) {
        if (value !== null && this.isNumberOrDecimal(value)) {
            this._elementRef.nativeElement.value = this.numberWithCommas(value);
        } else {
            this._elementRef.nativeElement.value = '0';
        }
    }

    private isNumberOrDecimal(value) {
        return (typeof value === 'number' && !isNaN(value)) || (typeof value === 'string' && !isNaN(parseFloat(value)));
    }

    private unFormatValue() {
        const value = this._elementRef.nativeElement.value;
        this._value = value.replace(NON_NUMERIC_REGEX, '');
        if (value) {
            this._elementRef.nativeElement.value = this._value;
        } else {
            this._elementRef.nativeElement.value = '';
        }
    }
}
