import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, dashboardStoreKey } from 'src/app/dashboard-new/store/dashboard.state';

export const getPendingJobsTotalCount = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => state.pendingJobs.totalCount
);

export const getPendingJobsAgingDays = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => state.pendingJobs.agingDays
);

export const getPendingJobsWarning = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => {
        return state.pendingJobs.agingCount == 0
            ? ''
            : `${state.pendingJobs.agingCount} jobs pending approval for over ${state.pendingJobs.agingDays} days`;
    }
);
