import { FacilityAssociation } from 'src/app/facilities/models/facility-association.model';
import { LoadingTypes } from 'src/app/shared/models';

export interface FacilityAssociationState {
    facilityAssociation: FacilityAssociation;
    loadingState: LoadingTypes;
}

export const getInitialFacilityAssociationState = (): FacilityAssociationState => ({
    facilityAssociation: null,
    loadingState: LoadingTypes.INIT
});
