import { CoreActions, CoreActionTypes } from '../core.actions';
import { MenuItem, SystemFormField, CustomSystemFieldLookup } from 'src/app/core/models';
import { LoadingTypes } from 'src/app/shared/models';
import { initPendoFail, initPendoSuccess } from '../actions';
import { ConnectPortalUser } from 'src/app/core/auth/models/connect-portal-user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { UserProfile } from 'src/app/shared/models/account/user-profile.model';

export interface CoreState {
    hasAcceptedTermsAndConditions: boolean;
    isAuthenticated: boolean;
    error: string | HttpErrorResponse;
    userId: string;
    securityPermissions: string[];
    menu: MenuItem[];
    featuredLogo: Blob;
    systemId: number;
    systemFormFields: SystemFormField[];
    customSystemFields: CustomSystemFieldLookup[];
    isPendoInitialized: boolean;
    loading: LoadingTypes;
    connectPortalUser: ConnectPortalUser;
    userProfiles: UserProfile[];
}

export const initialCoreState: CoreState = {
    isAuthenticated: false,
    hasAcceptedTermsAndConditions: false,
    error: '',
    userId: '',
    securityPermissions: [],
    menu: [],
    featuredLogo: null,
    systemId: null,
    systemFormFields: [],
    customSystemFields: [],
    isPendoInitialized: false,
    loading: LoadingTypes.INIT,
    connectPortalUser: undefined,
    userProfiles: []
};

export function reducer(state = initialCoreState, action: CoreActions): CoreState {
    switch (action.type) {
        case CoreActionTypes.LoginSuccess: {
            return {
                ...state,
                hasAcceptedTermsAndConditions: false,
                error: ''
            };
        }

        case CoreActionTypes.LoginFail: {
            return {
                ...state,
                isAuthenticated: false,
                error: action.errorMessage
            };
        }

        case CoreActionTypes.RequestPortalInfoSuccess: {
            return {
                ...state,
                isAuthenticated: true,
                hasAcceptedTermsAndConditions: false,
                connectPortalUser: action.response,
                error: undefined
            };
        }

        case CoreActionTypes.RequestPortalInfoFailure: {
            return {
                ...state,
                isAuthenticated: false,
                hasAcceptedTermsAndConditions: false,
                error: action.error
            };
        }

        case CoreActionTypes.GetTermsAndConditionsSuccess: {
            return {
                ...state,
                hasAcceptedTermsAndConditions: !action.response ? true : false,
                error: undefined
            };
        }

        case CoreActionTypes.GetTermsAndConditionsFailure: {
            return {
                ...state,
                hasAcceptedTermsAndConditions: false
            };
        }

        case CoreActionTypes.TermsAndConditionsNotSigned: {
            return {
                ...state,
                hasAcceptedTermsAndConditions: false
            };
        }

        case CoreActionTypes.AcceptTermsAndConditionsSuccess: {
            return {
                ...state,
                hasAcceptedTermsAndConditions: true,
                error: undefined
            };
        }

        case CoreActionTypes.LoadPermissionsSuccess: {
            return {
                ...state,
                securityPermissions: action.payload
            };
        }

        case CoreActionTypes.LoadPermissionsFail: {
            return {
                ...state,
                securityPermissions: [],
                error: action.errorMessage
            };
        }

        case CoreActionTypes.Logout: {
            return {
                ...state,
                connectPortalUser: undefined
            };
        }

        case CoreActionTypes.LoadMenuSuccess:
        case CoreActionTypes.UpdateMenu: {
            return {
                ...state,
                menu: action.menu
            };
        }

        case CoreActionTypes.AppendMenu: {
            return {
                ...state,
                menu: [...state.menu, action.menu]
            };
        }

        case CoreActionTypes.LoadFeaturedLogoWithSystemId: {
            return {
                ...state
            };
        }

        case CoreActionTypes.LoadFeaturedLogoSuccess: {
            return {
                ...state,
                featuredLogo: action.featuredLogo
            };
        }

        case CoreActionTypes.LoadSystemId: {
            return {
                ...state,
                loading: LoadingTypes.LOADING
            };
        }
        case CoreActionTypes.LoadSystemIdSuccess: {
            return {
                ...state,
                systemId: action.systemId,
                loading: LoadingTypes.LOADED
            };
        }

        case CoreActionTypes.LoadSystemIdFail: {
            return {
                ...state,
                loading: LoadingTypes.LOADED,
                error: action.errorMessage
            };
        }

        case CoreActionTypes.LoadSystemFields:
        case CoreActionTypes.ResetPassword:
        case CoreActionTypes.ResetPasswordSuccess: {
            return {
                ...state,
                loading: LoadingTypes.LOADED
            };
        }

        case CoreActionTypes.ResetPasswordFail:
        case CoreActionTypes.LoadSystemFieldsFail: {
            return {
                ...state,
                loading: LoadingTypes.LOADED,
                error: action.errorMessage
            };
        }

        case CoreActionTypes.LoadSystemFieldsSuccess: {
            return {
                ...state,
                systemFormFields: action.formFields,
                loading: LoadingTypes.LOADED
            };
        }

        case initPendoSuccess.type as string: {
            return {
                ...state,
                isPendoInitialized: true
            };
        }

        case CoreActionTypes.LoadCustomSystemFields: {
            return {
                ...state,
                loading: LoadingTypes.LOADED
            };
        }
        case CoreActionTypes.LoadCustomSystemFieldsSuccess: {
            return {
                ...state,
                customSystemFields: action.customSystemFields,
                loading: LoadingTypes.LOADED
            };
        }

        case initPendoFail.type as string: {
            return {
                ...state,
                isPendoInitialized: false
            };
        }

        case CoreActionTypes.LoadUserProfilesSuccess: {
            return {
                ...state,
                userProfiles: action.profiles
            };
        }

        default:
            return state;
    }
}
