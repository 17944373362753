
<mat-toolbar>
    <ng-container>
        <div *ngIf="featuredLogo; else aya_logo">
            <img class="feature-logo" [src]="featuredLogo" alt="logo" data-qa-id="top_nav_feature_logo">
        </div>

        <ng-template #aya_logo>
            <div id="top-nav-logo" data-qa-id="top_nav_logo"><div></div></div>
        </ng-template>
    </ng-container>

    <ayac-app-top-branding></ayac-app-top-branding>

    <div class="top-nav-actions">
        <ng-container *ngIf="messageCenterEnabled$ | async">
            <button class="messages-button" routerLink="client/message-center" mat-icon-button data-qa-id="ac_client_topnav_message_button" class="mx-1" aria-label="Messages">
                <mat-icon [ngClass]="{'custom-badge-content': (unreadMessages$ | async) > 9}" [matBadge]="(unreadMessages$ | async) > 99 ? '99+' : (unreadMessages$ | async)" [matBadgeHidden]="(unreadMessages$ | async) === 0" matBadgeColor="warn" matBadgeSize="small">email</mat-icon>
            </button>
        </ng-container>

        <div class="add-menu">
            <app-auto-open-menu [closeDelay]="200">
                <div trigger>
                    <button mat-icon-button *ngIf="showAddMenu">
                        <mat-icon class="mat-icon-lg">add</mat-icon>
                    </button>
                </div>
                <div content>
                    <button mat-menu-item *ngIf="canAddJob" (click)="addNewJob()">
                        <mat-icon fontSet="fas" fontIcon="fa-toolbox" class="icon-lg"></mat-icon>
                        <span>Add New Job</span>
                    </button>
                    <button mat-menu-item *ngIf="canAddShift" (click)="addNewShift()">
                        <mat-icon fontSet="fas" fontIcon="fa-sliders-h" class="icon-lg"></mat-icon>
                        <span>Add New Shift</span>
                    </button>
                </div>
            </app-auto-open-menu>
        </div>

        <button mat-button data-qa-id="ac_client_topnav_user_button" [matMenuTriggerFor]="profileMenu" class="mx-1">
            <mat-icon fontSet="fa" fontIcon="fa-user" class="icon-lg"></mat-icon>
            <span class="ml-1" data-qa-id="ac_client_topnav_username_readonly">{{ loggedName }}</span>
        </button>

        <mat-menu data-qa-id="ac_client_topnav_user_menu_container" #profileMenu="matMenu" xPosition="before">
            <div mat-menu-item>
                <span data-qa-id="ac_client_topnav_user_menu_greeting_readonly">Hi, {{ loggedName }}!</span>
            </div>

            <mat-divider></mat-divider>

            <button *ifLdFeature="featureFlag.ConnectClientAdminSettingsEnabled" class="admin-settings-button" mat-menu-item data-qa-id="ac_client_topnav_user_menu_admin_settings_button" (click)="onAdminSettings()">
                <mat-icon class="material-icons admin-setting-icon">admin_panel_settings</mat-icon>
                <span>Admin Settings</span>
            </button>

            <button mat-menu-item [matMenuTriggerFor]="profiles" *ngIf="profilesExist$ | async">
                <mat-icon fontSet="fas" fontIcon="fa-user" class="icon-lg"></mat-icon>
                <span>Switch Profile</span>
            </button>

            <button mat-menu-item data-qa-id="ac_client_topnav_user_menu_change_password_button" (click)="onChangePassword()">
                <mat-icon fontSet="fa" fontIcon="fa-lock" class="icon-lg"></mat-icon>
                <span>Change Password</span>
            </button>

            <button *ifLdFeature="featureFlag.ClientEmailSubscriptions" mat-menu-item data-qa-id="ac_client_topnav_user_menu_notification_settings_button" (click)="onNotificationSettings()">
                <mat-icon fontSet="fa" fontIcon="fa-gear" class="icon-lg"></mat-icon>
                <span>Notification Settings</span>
            </button>

            <button mat-menu-item data-qa-id="ac_client_topnav_user_menu_sign_out_button" (click)="onSignout()">
                <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt" class="icon-lg"></mat-icon>
                <span>Sign out</span>
            </button>
        </mat-menu>

        <mat-menu #profiles="matMenu" class="profile-dropdown">
            <button *ngFor="let profile of profiles$ | async" mat-menu-item
                class="user-profile"
                [disabled]="profile.isDefaultProfile"
                (click)="changeProfile(profile.userId)"
                [ngClass]="{'active-profile': profile.isDefaultProfile, 'impersonated-profile': profile.isImpersonated}">
                <mat-icon fontSet="fas" fontIcon="fa-user" class="icon-lg"></mat-icon>&nbsp;
                <span>{{ profile.userName }} - {{profile.type | replace : 'Employee' : 'Admin'}}</span>
                <div *ngIf="profile.isImpersonated">IMPERSONATED</div>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
