import { createAction, props } from '@ngrx/store';
import { CandidateJobSubmittalProfile } from '../../models';

export const showBookCandidateModal = createAction(
    '[JOBS] Show Book Candidate Modal',
    props<{ profile: CandidateJobSubmittalProfile }>()
);

export const bookCandidate = createAction(
    '[JOBS] Book Candidate',
    props<{
        submittalId: number;
        days: number[];
        startDate?: string;
        endDate?: string;
        shiftDuration?: number;
    }>()
);

export const bookCandidateSuccess = createAction('[JOBS] Book Candidate Success');

export const bookCandidateFail = createAction('[JOBS] Book Candidate Fail', props<{ error: any }>());
