import { createAction, props } from '@ngrx/store';
import { TravelerPostponeExtensionResult } from 'src/app/shared/models/travelers/traveler-postpone-extension-result.model';
import { TravelerExtensionModel, CreateTravelerExtension } from 'src/app/travelers/extension/traveler-extension.model';
import { ExtensionOfferFacilitySettings } from 'src/app/travelers/models';

export const requestExtension = createAction('[TRAVELERS DETAIL COMPONENT] Request Extension', props<{ id: number }>());
export const requestExtensionSuccess = createAction(
    '[TRAVELERS EXTENSION SERVICE] Request Extension Success',
    props<{ id: number }>()
);
export const requestExtensionFail = createAction(
    '[TRAVELERS EXTENSION SERVICE] Request Extension Fail',
    props<{ error: any }>()
);

export const loadExtensionLogo = createAction(
    '[TRAVELERS EXTENSION LANDING PAGE] Load Logo',
    props<{ environment: string }>()
);
export const loadExtensionLogoSuccess = createAction(
    '[TRAVELERS EXTENSION SERVICE] Load Logo Success',
    props<{ logo: Blob }>()
);
export const loadExtensionLogoFail = createAction(
    '[TRAVELERS EXTENSION SERVICE] Load Logo Fail',
    props<{ error: string }>()
);

export const loadExtensionDetails = createAction(
    '[TRAVELERS EXTENSION LANDING PAGE] Load Extension Details',
    props<{ travelerId: number }>()
);
export const loadExtensionDetailsSuccess = createAction(
    '[TRAVELERS EXTENSION SERVICE] Load Extension Details Success',
    props<{ extension: TravelerExtensionModel }>()
);
export const loadExtensionDetailsFail = createAction(
    '[TRAVELERS EXTENSION SERVICE] Load Extension Details Fail',
    props<{ error: any }>()
);

export const postponeExtensionReminder = createAction(
    '[TRAVELERS EXTENSION SERVICE] Postpone Extension Reminder',
    props<{ travelerId: number; email: string }>()
);
export const postponeExtensionReminderSuccess = createAction(
    '[TRAVELERS EXTENSION SERVICE] Postpone Extension Reminder Success',
    props<{ travelerId: number; postponeResult: TravelerPostponeExtensionResult }>()
);
export const postponeExtensionReminderFail = createAction(
    '[TRAVELERS EXTENSION SERVICE] Postpone Extension Reminder Fail',
    props<{ error: any }>()
);

export const completeExtensionRequest = createAction(
    '[TRAVELERS EXTENSION LANDING PAGE] Complete Extension Request',
    props<{ travelerId: number; token: string }>()
);
export const completeExtensionRequestSuccess = createAction(
    '[TRAVELERS EXTENSION SERVICE] Complete Extension Request Success',
    props<{ travelerId: number }>()
);
export const completeExtensionRequestFail = createAction(
    '[TRAVELERS EXTENSION SERVICE] Complete Extension Request Fail',
    props<{ error: any }>()
);

export const createExtension = createAction(
    '[TRAVELERS CREATE EXTENSION MODAL] Create Extension',
    props<{
        travelerId: number;
        duration: number;
        startDate: Date;
        endDate: Date;
        jobTitle: string;
        billingRate: number;
        additionalExpectations: string;
        extensionDetails: string;
    }>()
);
export const createExtensionSuccess = createAction(
    '[TRAVELERS EXTENSION SERVICE] Create Extension Success',
    props<{ jobId: number }>()
);
export const createExtensionFail = createAction(
    '[TRAVELERS EXTENSION SERVICE] Create Extension Fail',
    props<{ error: any }>()
);

export const clearExtensionJobId = createAction('[TRAVELERS CREATE EXTENSION MODAL] Clear Extension Job Id');

export const createClientExtensionOffer = createAction(
    '[TRAVELERS CREATE EXTENSION MODAL] Create Client Extension Offer',
    props<{ extension: CreateTravelerExtension }>()
);
export const createClientExtensionOfferSuccess = createAction(
    '[TRAVELERS EXTENSION SERVICE] Create Client Extension Offer Success',
    props<{ clientSubmittalId: number }>()
);
export const createClientExtensionOfferFail = createAction(
    '[TRAVELERS EXTENSION SERVICE] Create Client Extension Offer Fail',
    props<{ error: any }>()
);

export const clearExtensionClientSubmittalId = createAction(
    '[TRAVELERS CREATE EXTENSION MODAL] Clear Extension Client Submittal Id'
);

export const getExtensionOfferFacilitySettings = createAction(
    '[TRAVELERS CREATE EXTENSION MODAL] Get Extension Offer Facility Settings',
    props<{ contractId: number }>()
);
export const getExtensionOfferFacilitySettingsSuccess = createAction(
    '[TRAVELERS EXTENSION SERVICE] Get Extension Offer Facility Settings Success',
    props<{ settings: ExtensionOfferFacilitySettings }>()
);
export const getExtensionOfferFacilitySettingsFailure = createAction(
    '[TRAVELERS EXTENSION SERVICE] Get Extension Offer Facility Settings Failure',
    props<{ error: any }>()
);
