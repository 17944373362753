import { createAction, props } from '@ngrx/store';

export const loadPendingTimecards = createAction('[Dashboard Component] Load Pending Timecards');
export const loadPendingTimecardsSuccess = createAction(
    '[Dashboard Api] Load Pending Timecards Success',
    props<{ totalCount: number; agingCount: number }>()
);
export const loadPendingTimecardsFail = createAction(
    '[Dashboard Api] Load Pending Timecards Fail',
    props<{ error: any }>()
);
