import { TravelersDetailsEffect } from './travelers-details.effects';
import { TravelersListEffect } from './travelers-list.effects';
import { TravelersDocumentsEffect } from './travelers-documents.effects';
import { WorkOrderConfirmationEffect } from './work-order-confirmation.effects';
import { TravelersExtensionEffect } from './travelers-extension.effects';
import { StaffingAdvisoryEffect } from './staffing-advisory.effects';
import { StaffingAdvisoryAttachmentsEffect } from './staffing-advisory-attachments.effects';
import { TravelersImportCustomFieldsEffect } from './travelers-import-custom-fields.effects';
import { CustomFormEffect } from 'src/app/shared/components/custom-form/custom-form.effects';
import { TravelersDocumentDetailsEffect } from './travelers-document-details.effects';
import { TravelersDocumentsHistoryEffect } from './traveler-document-history.effects';

export const travelersEffects = [
    TravelersListEffect,
    TravelersDetailsEffect,
    TravelersDocumentsEffect,
    TravelersDocumentDetailsEffect,
    TravelersDocumentsHistoryEffect,
    WorkOrderConfirmationEffect,
    TravelersExtensionEffect,
    StaffingAdvisoryEffect,
    StaffingAdvisoryAttachmentsEffect,
    TravelersImportCustomFieldsEffect,
    CustomFormEffect
];
