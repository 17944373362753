import { travelersDetailsReducer } from './travelers-details.reducer';
import { travelersListReducer } from './travelers-list.reducers';
import { travelersDocumentsReducer } from './travelers-documents.reducers';
import { workOrderConfirmationReducer } from './work-order-confirmation.reducers';
import { extensionLogoReducer } from './travelers-extension-logo.reducer';
import { extensionDetailsReducer } from './travelers-extension-details.reducer';
import { extensionPostponeReducer } from './travelers-extension-postpone.reducer';
import { staffingAdvisoryReducer } from './staffing-advisory.reducer';
import { staffingAdvisoryAttachmentsReducer } from './staffing-advisory-attachments.reducer';
import { staffingAdvisoryLookupsReducer } from './staffing-advisory-lookups.reducer';
import { staffingAdvisoryEditReducer } from './staffing-advisory-edit.reducer';
import { staffingAdvisorySecurityLookupsReducer } from './staffing-advisory-security-lookups.reducer';
import { staffingAdvisoryDeleteReducer } from './staffing-advisory-delete.reducer';
import { staffingAdvisoryAttachmentsPreviewReducer } from './staffing-advisory-attachments-preview.reducer';
import { createExtensionReducer } from './create-extension.reducers';
import { travelersImportCustomFieldsReducer } from './travelers-import-custom-fields.reducer';
import { travelersDocumentDetailsReducer } from './travelers-document-details.reducer';
import { travelersExtensionOfferReducer } from './travelers-extension-offer.reducer';
import { travelersDocumentHistoryReducer } from './traveler-documents-history.reducer';

export const travelersReducer = {
    list: travelersListReducer,
    details: travelersDetailsReducer,
    documents: travelersDocumentsReducer,
    documentHistory: travelersDocumentHistoryReducer,
    documentDetails: travelersDocumentDetailsReducer,
    workOrderConfirmation: workOrderConfirmationReducer,
    extensionLogo: extensionLogoReducer,
    extensionDetails: extensionDetailsReducer,
    extensionPostpone: extensionPostponeReducer,
    staffingAdvisory: staffingAdvisoryReducer,
    staffingAdvisoryEdit: staffingAdvisoryEditReducer,
    staffingAdvisoryDelete: staffingAdvisoryDeleteReducer,
    staffingAdvisoryAttachments: staffingAdvisoryAttachmentsReducer,
    staffingAdvisoryLookups: staffingAdvisoryLookupsReducer,
    staffingAdvisorySecurityLookups: staffingAdvisorySecurityLookupsReducer,
    staffingAdvisoryAttachmentsPreview: staffingAdvisoryAttachmentsPreviewReducer,
    createExtension: createExtensionReducer,
    importCustomFields: travelersImportCustomFieldsReducer,
    extensionOffer: travelersExtensionOfferReducer
};
