
<dialog-layout [title]="data.title" class="no-padding-top">
    <div mat-dialog-content>
        <div *ngIf="data.description">
            <h3 class="description">{{ data.description }}</h3>
        </div>
        <div class="comment-wrapper" *ngIf="data.activity">
            <div class="comment-header reply">
                <ng-container [ngSwitch]="data.activity.noteType">
                    <ng-container *ngSwitchCase="noteType.Created">
                        <span>Candidate Submitted - {{data.activity.status}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="noteType.StatusChange">
                        <span>Status Change {{data.activity.status ? ' - ' + data.activity.status : null}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="noteType.ReplyNote">
                        <span *ngIf="data.activity.userType !== data.source">Comment from {{data.activity.userType}}</span>
                        <span class="activity-header" *ngIf="data.activity.userType === data.source">Comment to {{data.activity.replyNote?.userType}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="noteType.AddNote">
                        <span *ngIf="data.activity.userType === data.source">Comment from {{data.activity.userType}}</span>
                        <span class="activity-header" *ngIf="data.activity.userType !== data.source">Comment to {{data.activity.replyNote?.userType}}</span>
                    </ng-container>
                </ng-container> -
                {{ data.activity.activityDate | date : 'MMMM d, yyyy' }}
                {{ data.activity.activityDate + 'Z' | date : 'H:mm a' }} {{localTimeZoneName}}
            </div>
            <div class="comment reply" [innerHtml]="data.activity.notes"></div>
        </div>
        <form [formGroup]="editorForm" novalidate>
            <ayac-text-area-with-counter placeholder="Enter comment"
                        formControlName="note"
                        [maxLength]="maxCharacterLength"
                        [editor]="editor">
            </ayac-text-area-with-counter>
        </form>
    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button
            (click)="updateSubmittalStatus(false)"
            data-qa-id="cancel-submittal-status-update">
            Cancel
        </button>
        <button mat-flat-button
                [disabled]="!editorForm.valid"
                (click)="updateSubmittalStatus(true)"
                color="accent"
                id="confirm-report-action"
                data-qa-id="confirm-submittal-status-update">
                {{ data.confirmButtonText }}
        </button>
    </div>

</dialog-layout>
