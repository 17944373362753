import { Action, createReducer, on } from '@ngrx/store';
import { loadWorkersSuccess, loadTravelers } from '../actions';
import { Workers } from '../../models/workers.model';

export interface WorkersState {
    workers: Workers;
    startingWithinDays: number;
    endingWithinDays: number;
}

export const initialWorkersState: WorkersState = {
    workers: null,
    startingWithinDays: 14,
    endingWithinDays: 45
};

export function workersReducer(state: WorkersState, action: Action) {
    return createReducer(
        initialWorkersState,
        on(loadTravelers, (state, action) => ({
            ...state
        })),

        on(loadWorkersSuccess, (state, action) => ({
            ...state,
            workers: action.workers
        }))
    )(state, action);
}

export function reducer(state: WorkersState | undefined, action: Action) {
    return workersReducer(state, action);
}
