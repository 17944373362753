export type UserType = '' | 'vendor' | 'system' | 'admin' | 'client';

export enum UserTypeEnum {
    Direct = 1,
    Employee = 2,
    Subcontractor = 3,
    SystemContact = 4,
    VendorContact = 5,
    ClientContact = 6,
    PortalAdmin = 7,
    IntranetUser = 8
}

export enum InternalUserType {
    Receptionist = 0,
    TravelRecruiter = 1,
    Feed = 2,
    Nurses123SalesCoordinator = 3,
    AccountManager = 4,
    LeadsCoordinator = 5,
    ClinicalLiaison = 6,
    BlastCoordinator = 7,
    HousingManager = 8,
    Nurses123Sales = 9,
    PermRecruiter = 10,
    CustomerServices = 11,
    Referencing = 12,
    DataEntry = 13,
    Marketing = 14,
    PerDiemRecruiter = 15,
    PerDiemAccountManager = 16,
    OutsourcedDataEntry = 17,
    OutsourcedDataEntryApproval = 18,
    CostaRicaQM = 20,
    ReferencingPlusPerDiemRecruiter = 21,
    PredictiveSurveyDataEntry = 23,
    SAS = 24,
    PayrollLiaison = 25,
    ReceptionLead = 26,
    PayrollIssuesManager = 27,
    Billing = 28,
    Collections = 29,
    BenefitsAdministrator = 30,
    QMManager = 31,
    CustomerServiceManager = 32,
    AccidentPacketAdmin = 33,
    ExpiringDocsRep = 34,
    TravelOpsMgr = 35,
    TravelOpsSpec = 36,
    ITSoftwareDeveloper = 37,
    CredentialingMgr = 38,
    CredentialingSpec = 39,
    SubVendor = 40,
    BusinessDevelopment = 41,
    ClientReview = 42,
    AyaBackgroundServices = 43,
    InternalPool = 44
}

export interface UserLoginDefaults {
    defaultConnection: boolean;
    defaultOrganization: boolean;
}
interface IdentityBase {
    role: string;
    type: UserType;
    vendorId: number | null;
    clientId: number | null;
    coreUserId: string | number | null;
    email: string;
    userId: number;
    oldUserId: number | null;
    fullname: string;
    defaultURL: string;
    userType?: InternalUserType;
    scope?: string;
    pendoInfo?: PendoMetadata;
}

export interface Ticket extends IdentityBase {
    username: string;
    exp: string;
}

export interface PendoMetadata {
    isSSOUser: boolean;
    roleName: string;
    system: string;
    brand: string;
    firstName: string;
    lastName: string;
}

export interface Identity extends IdentityBase {
    appModules: string[];
    ticket: Ticket;
    firstName: string | null;
    userName: string;
}
