<dialog-layout title="Edit Preferred Shift">
    <div mat-dialog-content>
        <form [formGroup]="form" class="k-form-inline">
            <ng-container 
                *ngFor="let line of preferredShifts.controls; let i = index;"
                formArrayName="preferredShifts">
                <ng-container [formGroupName]="i">
                    
                    <mat-divider [inset]="false" *ngIf="i > 0" class="my-3"></mat-divider>

                    <div fxLayout="row" fxLayoutAlign="start center" >
                        <div fxFlex>
                            <div class="k-form-field">
                                <label>
                                    Days
                                </label>
                                <kendo-multiselect 
                                    formControlName="days"
                                    [data]="dayLookups$ | async"
                                    [valuePrimitive]="true"
                                    textField="name"
                                    valueField="id">
                                </kendo-multiselect>
                            </div>

                            <div class="k-form-field">
                                <label>
                                    Shift Length
                                </label>
                                <kendo-combobox
                                    class="form-control-shrinked"
                                    formControlName="shiftHours"
                                    textField="name"
                                    valueField="id"
                                    [valuePrimitive]="true"
                                    [data]="shiftLengthLookups$ | async">
                                </kendo-combobox>
                            </div>

                            <div class="k-form-field">
                                <label>
                                    Start Time
                                </label>
                                <timepicker-dropdown
                                    formControlName="startTime"
                                    [steps]="{hour: 1, minute: 15}">
                                </timepicker-dropdown>
                            </div>

                            <div class="k-form-field k-form-field-static">
                                <span><strong>End Time</strong></span>
                                <div>
                                    {{
                                        preferredShifts.controls[i].get('startTime').value
                                        | addDuration : preferredShifts.controls[i].get('shiftHours').value
                                        | date : 'hh:mm a'
                                    }}
                                </div>
                            </div>
                        </div>

                        <button 
                            mat-icon-button
                            (click)="onRemove(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>

                </ng-container>
            </ng-container>

        </form>

        <mat-divider [inset]="false" class="my-3"></mat-divider>

        <button mat-flat-button
            color="accent"
            (click)="onAdd()">
                <mat-icon>add</mat-icon>
                Add Time
        </button>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button
            color="accent"
            (click)="onSave()">
                Save Preferred Shift
        </button>
        
        <button mat-stroked-button
            color="basic"
            (click)="onClose()">
                Close
        </button>
    </div>
</dialog-layout>
