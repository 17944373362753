<div data-qa-id="view-req-details-modal-container">
    <h2 mat-dialog-title class="req-details-modal-title" data-qa-id="view-req-details-modal-title">View Requirement Details</h2>
    <mat-dialog-content>
        <h3 class="req-details-title" data-qa-id="view-req-details-modal-req-title">{{data.title}}</h3>
        <div class="requirements" data-qa-id="view-req-details-modal-req-details" *ngFor="let requirement of data.requirements">
            <p><strong>{{requirement.documentTypeLevel}}:</strong> {{requirement.entityName}}</p>
            <div [innerHtml]="requirement.description | safe:'html'">{{requirement.description}}</div>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button mat-stroked-button mat-dialog-close cdkFocusInitial data-qa-id="view-req-details-modal-close-btn">Close</button>
    </div>
</div>

