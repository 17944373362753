import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { VendorProfileJobRulesActions } from 'src/app/admin/store/actions/vendor-profile-job-rules.actions';
import { VendorProfileService } from '../../vendor-details/profile/vendor-profile.service';

@Injectable()
export class VendorProfileJobRulesEffects {
    getJobRulesList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(VendorProfileJobRulesActions.loadVendorProfileJobRulesList),
            exhaustMap((action) =>
                this._vendorProfileService.getJobRulesList(action.vendorId).pipe(
                    map((result) =>
                        VendorProfileJobRulesActions.loadVendorProfileJobRulesListSuccess({ jobRules: result })
                    ),
                    catchError((error) =>
                        of(VendorProfileJobRulesActions.loadVendorProfileJobRulesListFailure({ error }))
                    )
                )
            )
        )
    );

    getJobRuleDetails$ = createEffect(() =>
        this._actions$.pipe(
            ofType(VendorProfileJobRulesActions.loadVendorProfileJobRuleDetails),
            exhaustMap((action) =>
                this._vendorProfileService.getJobRuleDetails(action.jobRuleId).pipe(
                    map((details) => VendorProfileJobRulesActions.loadVendorProfileJobRuleDetailsSuccess({ details })),
                    catchError((error) =>
                        of(VendorProfileJobRulesActions.loadVendorProfileJobRuleDetailsFailure({ error }))
                    )
                )
            )
        )
    );

    getJobRuleProfessions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(VendorProfileJobRulesActions.loadVendorProfileJobRuleProfessionTypes),
            exhaustMap(() => {
                return this._vendorProfileService.getProfessionTypes().pipe(
                    map((professionTypes) =>
                        VendorProfileJobRulesActions.loadVendorProfileJobRuleProfessionTypesSuccess({ professionTypes })
                    ),
                    catchError((error) =>
                        of(VendorProfileJobRulesActions.loadVendorProfileJobRuleProfessionTypesFailure({ error }))
                    )
                );
            })
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _vendorProfileService: VendorProfileService
    ) {}
}
