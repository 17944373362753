import { createAction, props } from '@ngrx/store';
import { PermJob } from 'src/app/permanent-jobs/models/perm-job.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';

export enum PermJobsActionTypes {
    SetQueryAndGetPermJobs = '[Perm Jobs Component] Set Query And Get Perm Jobs',
    SetQueryAndGetPermJobsSuccess = '[Perm Jobs Api] Set Query And Get Perm Jobs - Success',
    SetQueryAndGetPermJobsFail = '[Perm Jobs Api] Set Query And Get Perm Jobs - Fail',
    ExportPermJobs = '[Perm Jobs Component] Export Perm Jobs',
    ExportPermJobsSuccess = '[Perm Jobs Api] Export Perm Jobs success',
    ExportPermJobsFail = '[Perm Jobs Api] Export Perm Jobs fail'
}

export const setQueryAndGetPermJobs = createAction(
    PermJobsActionTypes.SetQueryAndGetPermJobs,
    props<{ query?: GridSearchQuery }>()
);

export const setQueryAndGetPermJobsSuccess = createAction(
    PermJobsActionTypes.SetQueryAndGetPermJobsSuccess,
    props<{ permJobs: PermJob[]; permJobsTotal: number }>()
);

export const setQueryAndGetPermJobsFail = createAction(PermJobsActionTypes.SetQueryAndGetPermJobsFail);

export const exportPermJobs = createAction(PermJobsActionTypes.ExportPermJobs);

export const exportPermJobsSuccess = createAction(PermJobsActionTypes.ExportPermJobsSuccess);

export const exportPermJobsFailure = createAction(PermJobsActionTypes.ExportPermJobsFail);
