import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { updatePreference, updatePreferenceSuccess } from '../actions';
import { CoreRepositoryService } from '../../services/core-repository.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class CorePreferenceEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly coreRepository: CoreRepositoryService
    ) {}

    updatePreference$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updatePreference),
            switchMap((action) =>
                this.coreRepository.getPreferences(action.id).pipe(
                    switchMap((response) => {
                        if (response.preference) {
                            const preference = {
                                ...JSON.parse(response.preference),
                                ...action.preference
                            };
                            return this.coreRepository.updatePreference(action.id, preference);
                        } else {
                            return this.coreRepository.createPreference(action.id, action.preference);
                        }
                    }),
                    map(() => updatePreferenceSuccess()),
                    catchError(() => EMPTY)
                )
            )
        )
    );
}
