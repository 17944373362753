import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as shiftsActions from '../actions';
import { BulkShift } from 'src/app/shared/models/shifts/';

export interface BulkShiftsListState {
    error: string;
    bulkShifts: BulkShift[];
    bulkShiftsLoadStatus: LoadingTypes;
}

export const initialBulkShiftsListState: BulkShiftsListState = {
    error: '',
    bulkShifts: null,
    bulkShiftsLoadStatus: LoadingTypes.INIT
};

const reducer = createReducer(
    initialBulkShiftsListState,
    on(shiftsActions.loadBulkShiftSeries, (state) => ({
        ...state,
        bulkShiftsLoadStatus: LoadingTypes.LOADING
    })),
    on(shiftsActions.loadBulkShiftSeriesSuccess, (state, { bulkShifts }) => ({
        ...state,
        bulkShifts: bulkShifts,
        bulkShiftsLoadStatus: LoadingTypes.LOADED,
        error: ''
    })),
    on(shiftsActions.loadShiftsFail, (state, { error }) => ({
        ...state,
        bulkShifts: null,
        bulkShiftsLoadStatus: LoadingTypes.FAIL,
        error: error
    }))
);

export function bulkShiftsListReducer(state: BulkShiftsListState | undefined, action: Action) {
    return reducer(state, action);
}
