import { ContentChild, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FilterableSettings } from '@progress/kendo-angular-grid';
import { GridColumnFilterEnum } from '../enums/grid-column-filter.enum';
import { GridColumnFormatEnum } from '../enums/grid-column-format.enum';

@Directive({
    selector: 'portal-grid-column'
})
export class GridColumnDirective {
    @Input() field: string;
    @Input() title: string;
    @Input() sortable = true;
    @Input() hidden = false;
    @Input() class: string | string[] | { [key: string]: any };
    @Input() width: number;
    @Input() defaultText: string;
    @Input() addMoreText: string;
    @Input() uniqueFilterClass = '';
    @Input() sortField?: string;
    @Input() style?: any;
    @Input() headerStyle?: any;
    @Input() headerClass?: string | string[] | Set<string> | {[key: string]: any};
    @Input() headerTemplate: TemplateRef<any>;
    @ContentChild('columnHeaderTemplate', { static: true }) columnHeaderTemplate: TemplateRef<any>;
    @ContentChild('cell', { static: true }) template: TemplateRef<any>;
    @ContentChild('group', { static: true }) groupTemplate: TemplateRef<any>;
    @ContentChild('filterMenu', { static: true }) filterMenuTemplate: TemplateRef<any>;
    @ContentChild('filterCell', { static: true }) filterCellTemplate: TemplateRef<any>;
    @ContentChild('dropdownListTemplate', { static: true }) filterDropdownListTemplate: TemplateRef<any>;
    @ContentChild('dropdownListValueTemplate', { static: true }) filterDropdownValueTemplate: TemplateRef<any>;

    @Input() filterType: GridColumnFilterEnum = GridColumnFilterEnum.NONE;
    @Input() format: GridColumnFormatEnum = GridColumnFormatEnum.TEXT;
    @Input() filterDisabled: boolean;
    @Input() disabledKeyboard = false;
    @Input() filterTooltip: string;
    @Input() filterOptions: any;
    @Input() filterable: FilterableSettings;
    @Input() dropDownFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'startsWith'
    };

    @Input() filterDebounceTime = 500;
    @Input() textFilter?: boolean;
    @Input() selectAll?: string;

    @Input() valueField = 'id';
    @Input() textField = 'name';

    @Input() singleLine: boolean;
    @Input() required?: boolean;
    @Input() defaultToAllSelected?: boolean;

    @Output() public filterChanging = new EventEmitter<any>();
}
