<div class="input-dropdown-wrapper">
    <mat-form-field appearance="outline" [class.mat-form-field-invalid]="showRequiredError" [class.clear-button-shown]="showClearButton && selectedOption">
        <input matInput id="input-dropdown-input" type="text" #controlInput #autocompleteTrigger="matAutocompleteTrigger" [formControlName]="controlName"
            [attr.placeholder]="placeholder" (focus)="onFocus()" (blur)="onBlur()" [matAutocomplete]="autoComplete"/>
        <button mat-icon-button id="combobox-open-btn" matSuffix aria-label="Select" (click)="openAndFocus()">
            <mat-icon>expand_more</mat-icon>
        </button>
        <mat-error *ngIf="showRequiredError">
            {{requiredMessage}}
        </mat-error>
        <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="displayName" (optionSelected)="onAddOption($event.option.value)">
            <mat-option *ngFor="let option of mappedOptions | filterBy:{ name: formGroup.get(controlName)?.value }" [value]="option.value">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <button *ngIf="showClearButton && selectedOption" mat-icon-button id="combobox-clear-btn" type="button" aria-label="Clear" (click)="clearValue()">
        <mat-icon>close</mat-icon>
    </button>
</div>
