import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AyaLeadsService } from 'src/app/aya-leads/services/aya-leads.service';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { AyaLeadsApiActions, AyaLeadsPageActions } from '../actions';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { AyaLeadsLookupsSelectors } from '../selectors';

@Injectable()
export class AyaLeadsLookupsEffects extends BaseEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly ayaLeadsService: AyaLeadsService,
        toasterService: ToasterService,
        private readonly store: Store
    ) {
        super(toasterService);
    }

    getAyaLeadsApplicationStatuses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsPageActions.loadAyaLeadApplicationStatuses),
            withLatestFrom(
                this.store.select(AyaLeadsLookupsSelectors.selectAyaLeadsApplicationStatuses),
                this.store.select(AyaLeadsLookupsSelectors.selectAyaLeadsApplicationStatusesAgeInMinutes)
            ),
            exhaustMap(([action, applicationStatusList, minutesElapsedSinceLastApiFetch]) => {
                if (applicationStatusList && applicationStatusList.length > 0 && minutesElapsedSinceLastApiFetch < 30) {
                    return of(AyaLeadsPageActions.loadAyaLeadApplicationStatusesFromStoreSuccess());
                }

                return this.ayaLeadsService.getAyaLeadApplicationStatuses().pipe(
                    map((ayaLeadsApplicationStatuses) =>
                        AyaLeadsApiActions.loadAyaLeadApplicationStatusesSuccess({
                            ayaLeadsApplicationStatuses,
                            loadTimeMs: new Date().getTime()
                        })
                    ),
                    catchError((error: unknown) =>
                        of(AyaLeadsApiActions.loadAyaLeadApplicationStatusesFailure({ error }))
                    )
                );
            })
        );
    });
}
