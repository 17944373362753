import { createAction, props } from '@ngrx/store';

export const updatePreference = createAction(
    '[CORE] Update Preferences',
    props<{
        id: number;
        preference: { [key: string]: any };
    }>()
);
export const updatePreferenceSuccess = createAction('[CORE] Update Preferences Success');
