<div terminology>
    <div [matTooltip]="disabledReason$ | async" click-stop-propagations>
        <button (click)="onClick()"
                [disabled]="!(canArchive$ | async)"
                color="danger"
                data-qa-id="internalpool-archive-worker-btn" mat-flat-button>
            Archive Worker
        </button>
    </div>
</div>
