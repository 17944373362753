import { LoadingTypes } from 'src/app/shared/models';
import {
    loadOfferDetails,
    loadOfferDetailsSuccess,
    loadOfferDetailsFailure,
    updateOfferDetails,
    updateOfferDetailsSuccess,
    updateOfferDetailsFailure,
    rescindOffer,
    rescindOfferSuccess,
    rescindOfferFailure,
    clearRescindOfferSuccessState,
    clearOfferState,
    loadOfferNotes,
    loadOfferNotesSuccess,
    loadOfferNotesFailure,
    addOfferNote,
    addOfferNoteSuccess,
    addOfferNoteFailure,
    declineOfferChangeRequest,
    declineOfferChangeRequestSuccess,
    declineOfferChangeRequestFailure,
    getSubmittalJobPositionsStatus,
    getSubmittalJobPositionsStatusSuccess,
    loadSubmittalOfferRescindList,
    loadSubmittalOfferRescindListSuccess,
    loadSubmittalOfferRescindListFailure,
    setSelectedRescindOfferDetails,
    setRescindOfferSuccessState,
    getSubmittalJobPositionsStatusFailure,
    addJobPositions,
    addJobPositionsFail,
    addJobPositionsSuccess
} from 'src/app/submittals/store/submittals.actions';
import { createReducer, on, Action } from '@ngrx/store';

import { SubmittalOfferDetails, SubmittalOfferNote } from 'src/app/shared/models/submittals';
import { createOffer, createOfferFailure, createOfferSuccess } from 'src/app/submittals/store/actions';
import { SubmittalJobPositionsStatus } from 'src/app/submittals/models/submittal-job-positions-status.model';
import { SubmittalOfferBaseDetails } from 'src/app/shared/models/submittals/submittal-offer-base-details.model';
import { SubmittalAddJobPositionsState } from '../../models/submittal-add-job-positions-state.model';

export interface SubmittalOfferDetailsState {
    submittalOfferDetails: SubmittalOfferDetails;
    submittalOfferDetailsLoading: LoadingTypes;
    submittalOfferRescinding: LoadingTypes;
    submittalOfferDetailsUpdating: LoadingTypes;
    submittalOfferCreating: LoadingTypes;
    submittalOfferNotes: SubmittalOfferNote[];
    submittalRescindOfferList: SubmittalOfferBaseDetails[];
    submittalRescindOfferListLoading: LoadingTypes;
    submittalSelectedRescindOfferDetails: { offerId: number; clientSubmittalId: number };
    submittalOfferRescindSuccessState: { offerId: number; wasRescindSuccessful: boolean };
    submittalOfferNotesLoading: LoadingTypes;
    submittalOfferNoteAdding: LoadingTypes;
    submittalJobPositionsStatus: SubmittalJobPositionsStatus;
    submittalJobPositionsStatusLoading: LoadingTypes;
    submittalAddJobPositionsState: SubmittalAddJobPositionsState;
}

const initialState: SubmittalOfferDetailsState = {
    submittalOfferDetails: null,
    submittalOfferDetailsLoading: LoadingTypes.INIT,
    submittalOfferRescinding: LoadingTypes.INIT,
    submittalOfferDetailsUpdating: LoadingTypes.INIT,
    submittalOfferCreating: LoadingTypes.INIT,
    submittalOfferNotes: [],
    submittalOfferNotesLoading: LoadingTypes.INIT,
    submittalOfferNoteAdding: LoadingTypes.INIT,
    submittalRescindOfferList: [],
    submittalRescindOfferListLoading: LoadingTypes.INIT,
    submittalSelectedRescindOfferDetails: null,
    submittalOfferRescindSuccessState: null,
    submittalJobPositionsStatus: null,
    submittalJobPositionsStatusLoading: LoadingTypes.INIT,
    submittalAddJobPositionsState: { response: undefined, loading: LoadingTypes.INIT }
};

export const submittalOfferDetailsReducer = (reducerState: SubmittalOfferDetailsState, reducerAction: Action) => {
    return createReducer(
        initialState,
        on(
            loadOfferDetails,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetailsLoading: LoadingTypes.LOADING
            })
        ),
        on(loadOfferDetailsSuccess, (state, action): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalOfferDetails: action.offerDetails,
                submittalOfferDetailsLoading: LoadingTypes.LOADED
            };
        }),
        on(
            loadOfferDetailsFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetails: null,
                submittalOfferDetailsLoading: LoadingTypes.FAIL
            })
        ),
        on(
            rescindOffer,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferRescinding: LoadingTypes.LOADING
            })
        ),
        on(rescindOfferSuccess, (state): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalOfferRescinding: LoadingTypes.LOADED
            };
        }),
        on(
            rescindOfferFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferRescinding: LoadingTypes.FAIL
            })
        ),
        on(
            setRescindOfferSuccessState,
            (state, action): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferRescindSuccessState: {
                    offerId: action.offerId,
                    wasRescindSuccessful: action.wasRescindSuccessful
                }
            })
        ),
        on(
            clearRescindOfferSuccessState,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferRescindSuccessState: null
            })
        ),
        on(
            updateOfferDetails,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetailsUpdating: LoadingTypes.LOADING
            })
        ),
        on(updateOfferDetailsSuccess, (state): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalOfferDetailsUpdating: LoadingTypes.LOADED
            };
        }),
        on(
            updateOfferDetailsFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetailsUpdating: LoadingTypes.FAIL
            })
        ),
        on(
            declineOfferChangeRequest,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetailsLoading: LoadingTypes.LOADING
            })
        ),
        on(declineOfferChangeRequestSuccess, (state): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalOfferDetailsLoading: LoadingTypes.LOADED
            };
        }),
        on(
            declineOfferChangeRequestFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetailsLoading: LoadingTypes.FAIL
            })
        ),
        on(
            createOffer,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferCreating: LoadingTypes.LOADING
            })
        ),
        on(
            createOfferSuccess,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferCreating: LoadingTypes.LOADED
            })
        ),
        on(
            createOfferFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferCreating: LoadingTypes.FAIL
            })
        ),
        on(
            loadOfferNotes,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferNotesLoading: LoadingTypes.LOADING
            })
        ),
        on(loadOfferNotesSuccess, (state, action): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalOfferNotes: action.offerNotes,
                submittalOfferNotesLoading: LoadingTypes.LOADED
            };
        }),
        on(
            loadOfferNotesFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferNotes: [],
                submittalOfferNotesLoading: LoadingTypes.FAIL
            })
        ),
        on(
            addOfferNote,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferNoteAdding: LoadingTypes.LOADING
            })
        ),
        on(addOfferNoteSuccess, (state): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalOfferNoteAdding: LoadingTypes.LOADED
            };
        }),
        on(
            addOfferNoteFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferNoteAdding: LoadingTypes.FAIL
            })
        ),
        on(
            loadSubmittalOfferRescindList,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalRescindOfferList: null,
                submittalRescindOfferListLoading: LoadingTypes.LOADING
            })
        ),
        on(loadSubmittalOfferRescindListSuccess, (state, action): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalRescindOfferList: action.offerRescindList,
                submittalRescindOfferListLoading: LoadingTypes.LOADED
            };
        }),
        on(loadSubmittalOfferRescindListFailure, (state): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalRescindOfferList: [],
                submittalRescindOfferListLoading: LoadingTypes.FAIL
            };
        }),
        on(setSelectedRescindOfferDetails, (state, action): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalSelectedRescindOfferDetails: {
                    offerId: action.offerId,
                    clientSubmittalId: action.clientSubmittalId
                }
            };
        }),
        on(
            getSubmittalJobPositionsStatus,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalJobPositionsStatus: null,
                submittalJobPositionsStatusLoading: LoadingTypes.LOADING
            })
        ),
        on(getSubmittalJobPositionsStatusSuccess, (state, action): SubmittalOfferDetailsState => {
            return {
                ...state,
                submittalJobPositionsStatus: action.submittalJobPositionsStatus,
                submittalJobPositionsStatusLoading: LoadingTypes.LOADED
            };
        }),
        on(
            getSubmittalJobPositionsStatusFailure,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalJobPositionsStatus: null,
                submittalJobPositionsStatusLoading: LoadingTypes.FAIL
            })
        ),
        on(
            addJobPositions,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalAddJobPositionsState: { response: null, loading: LoadingTypes.LOADING }
            })
        ),
        on(
            addJobPositionsSuccess,
            (state, a): SubmittalOfferDetailsState => ({
                ...state,
                submittalAddJobPositionsState: { response: a.response, loading: LoadingTypes.LOADED }
            })
        ),
        on(
            addJobPositionsFail,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalAddJobPositionsState: { response: null, loading: LoadingTypes.FAIL }
            })
        ),
        on(
            clearOfferState,
            (state): SubmittalOfferDetailsState => ({
                ...state,
                submittalOfferDetails: null,
                submittalOfferDetailsLoading: LoadingTypes.INIT,
                submittalOfferDetailsUpdating: LoadingTypes.INIT,
                submittalOfferCreating: LoadingTypes.INIT,
                submittalOfferNotes: [],
                submittalOfferNotesLoading: LoadingTypes.INIT,
                submittalOfferNoteAdding: LoadingTypes.INIT,
                submittalRescindOfferList: [],
                submittalRescindOfferListLoading: LoadingTypes.INIT,
                submittalSelectedRescindOfferDetails: null,
                submittalOfferRescindSuccessState: null,
                submittalJobPositionsStatus: null,
                submittalJobPositionsStatusLoading: LoadingTypes.INIT
            })
        )
    )(reducerState, reducerAction);
};

export const reducer = (state: SubmittalOfferDetailsState | undefined, action: Action) => {
    return submittalOfferDetailsReducer(state, action);
};
