import { createAction, props } from '@ngrx/store';
import { ShiftHistory } from 'src/app/shared/models/shifts/shift-history.model';

// Shift Status History
export const loadShiftCombinedHistory = createAction('[SHIFTS] Load Shift Status History', props<{ id: number }>());
export const loadShiftCombinedHistorySuccess = createAction(
    '[SHIFTS] Load Shift Status History Success',
    props<{ history: ShiftHistory[] }>()
);
export const loadShiftCombinedHistoryFail = createAction(
    '[SHIFTS] Load Shift Status History Fail',
    props<{ error: any }>()
);
export const clearShiftCombinedHistory = createAction('[SHIFTS] Clear Shift Status History');

// Internal Pool Shifts History
export const loadIPShiftStatusHistory = createAction('[SHIFTS] Load IP Shift Status History', props<{ id: number }>());
export const loadIPShiftStatusHistorySuccess = createAction(
    '[SHIFTS] Load IP Shift Status History Success',
    props<{ history: ShiftHistory[] }>()
);
export const loadIPShiftStatusHistoryFail = createAction(
    '[SHIFTS] Load IP Shift Status History Fail',
    props<{ error: any }>()
);
