<div class="details-container">
    <ul class="sub-details sub-details--first">
        <li class="sub-details-item sub-details-item--heading">
            <span class="heading">Job Info</span>
        </li>
        <li class="sub-details-item">
            <label>Job Id:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else noJobId">
                <span class="k-link btn-link" (click)="goToJobDetails(details.jobId)">
                    {{ details?.jobId }}
                </span>
            </div>
            <ng-template #noJobId>
                <div class="item-text">0</div>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>Status:</label>
            <div class="item-text" *ngIf=" details?.jobId > 0 else defailtStatus">
                <strong>{{ details?.jobStatus |uppercase }}</strong>
                <span>{{ details?.isOnHold ? ' (On Hold)' : ''}}</span>
            </div>
            <ng-template #defailtStatus>
                <div class="item-text"><strong>Not Available</strong></div>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>Start Date:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else offerStartDate">
                {{ details?.jobStartDate | date: 'MMM dd, yyyy' }}
            </div>
            <ng-template #offerStartDate>
                <div class="item-text">{{ details?.offer.jobStartDate | date: 'MMM dd, yyyy' }}</div>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>Facility:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else offerFacliityName">{{ details?.facilityName }}</div>
            <ng-template #offerFacliityName>
                <div class="item-text">{{ details?.offer.facilityName }}</div>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>Profession:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else offerProfessionName">
                {{ details?.professionName }}
            </div>
            <ng-template #offerProfessionName>
                <div class="item-text">{{ details?.offer.professionName }}</div>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>Specialty:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else offerExpertiseName">{{ details?.expertiseName }}</div>
            <ng-template #offerExpertiseName>
                <div class="item-text">{{ details?.offer.expertiseName }}</div>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>Shift:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else offerShiftName">{{ details?.shiftName }}</div>
            <ng-template #offerShiftName>
                <div class="item-text">{{ details?.offer.shiftName }}</div>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>Bill Rate:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else offerVendorRate">
                {{ newSubmittal ? details?.jobVendorBillRate : details?.vendorRate | currency }}
            </div>
            <ng-template #offerVendorRate>
                <div class="item-text">{{ details?.offer.vendorRate | currency }}</div>
            </ng-template>
        </li>
        <li class="sub-details-item"
            data-qa-id="submittal-pay-when-paid-row">
            <label>Pay When Paid:</label>
            <div class="item-text">
                {{ details?.payWhenPaid ? 'Yes' : 'No' }}
                <mat-icon matTooltipPosition="right"
                          matTooltip="“Pay when Paid” information is displayed for convenience only. Payments to agency are subject in all cases to the underlying agreement, which shall control in the event of a conflict. Please refer to the official announcement, if any, and underlying agreement for further information. This field may be updated from time to time, with or without notice to agency. Please refer to the announcement for additional specifics.">
                    info
                </mat-icon>
            </div>
        </li>
        <li class="sub-details-item">
            <label>Over Time Rules:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else offerOvertimeRule">{{ details?.overtimeRule }}</div>
            <ng-template #offerOvertimeRule>
                <div class="item-text">{{ details?.offer.overtimeRule }}</div>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>Non-Bill. Orientation:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else offerNonBillableOrientationHours">
                {{
                details?.nonBillableOrientationHours
                }} Hours
            </div>
            <ng-template #offerNonBillableOrientationHours>
                <div class="item-text">{{ details?.offer.nonBillableOrientationHours }} Hours</div>
            </ng-template>
        </li>
        <li *ngIf="isAdmin" class="sub-details-item">
            <label>Orientation Details:</label>
            <div class="item-text">{{ details?.orientDetails }}</div>
        </li>
        <li class="sub-details-item">
            <label>Account Manager:</label>
            <div class="item-text" *ngIf="details?.jobId > 0 else offerAccountManager">
                {{ details?.accountManagerFirstName }} {{ details?.accountManagerLastName }}
                <br> E: <a href="mailto:{{details.accountManagerEmail}}">{{ details.accountManagerEmail}}</a>
                <br> P: <a>{{ details.accountManagerPhone}}</a>
            </div>
            <ng-template #offerAccountManager>
                <div class="item-text">
                    <strong>{{ details?.offer.accountManagerFirstName }} {{ details?.accountManagerLastName }}</strong>
                    <br> E: <a href="mailto:{{details.offer.accountManagerEmail}}">
                        {{
                        details.offer.accountManagerEmail
                        }}
                    </a>
                    <br> P: <a>{{ details.offer.accountManagerPhone}}</a>
                </div>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>Email:</label>
            <div class="item-text">
                <a href="mailto:{{ details?.accountManagerEmail }}">
                    {{ details?.accountManagerEmail }}
                </a>
            </div>
        </li>
    </ul>

    <!-- remove the ngClass condition and order styles when removing FF -->
    <ul class="sub-details" [ngClass]="vendorSubmittalsDetailsMoveStatusToTopRight ? 'sub-details--second' : 'sub-details--third'">
        <li class="sub-details-item sub-details-item--heading">
            <span class="heading">Submittal Info</span>
        </li>
        <li class="sub-details-item">
            <label [ngClass]="{'narrow-label' : vendorSubmittalsDetailsMoveStatusToTopRight}">History:</label>
            <ul class='statuscrumb' kendoTooltip [tooltipTemplate]="template" filter="a.tooltip-text" tooltipWidth="400"
                [ngClass]="{'statuscrumb--new-style' : vendorSubmittalsDetailsMoveStatusToTopRight}">
                <li *ngFor="let submittalStageHistoryEntry of submittalStageHistoryEntries">
                    <a class="tooltip-text" [attr.data-dateEntered]="submittalStageHistoryEntry.dateEntered"
                       [attr.data-reasonName]="submittalStageHistoryEntry.reasonName"
                       [attr.data-note]="submittalStageHistoryEntry.note">{{ submittalStageHistoryEntry?.name }}</a>
                </li>
                <li *ngIf="isAdmin" class="stage-filler"></li>
            </ul>

            <div [formGroup]="form" *ngIf="isAdmin">
                <mat-form-field appearance="outline" floatLabel="never"
                                [ngClass]="{'mat-form-field--new-style' : vendorSubmittalsDetailsMoveStatusToTopRight}">
                    <mat-select formControlName="stageSelect">
                        <mat-option>
                        </mat-option>
                        <mat-option *ngFor="let stage of submittalStages$ | async" [value]="stage">
                            {{ stage.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <button mat-flat-button color="accent" class="migrated-btn-info view-offer-btn" *ngIf="canShowViewOfferButton()"
                    (click)="viewOffer(details.offer.id)">
                View Offer
            </button>
            <div *ngIf="isOfferInContractChangeStatus" style="float: left; margin: 6px 0 0 10px;">
                Contract Change
            </div>
        </li>
        <ng-container *ngIf="!isCommunicationEnabled; else submittalCommunication">
            <li class="sub-details-item">
                <label class="note-label" [ngClass]="{'narrow-label' : vendorSubmittalsDetailsMoveStatusToTopRight}">{{ noteLabel }}:</label>
                <div class="item-text account-manager-note">{{ details?.accountManagerNote }}</div>
            </li>
            <li class="sub-details-item">
                <label [ngClass]="{'narrow-label' : vendorSubmittalsDetailsMoveStatusToTopRight}">Note:</label>
                <form [formGroup]="form">
                    <textarea matInput class="textarea-note" rows="10" cols="50" formControlName="note"
                                placeholder="Note"></textarea>
                </form>
            </li>
        </ng-container>
        <ng-template #submittalCommunication>
            <aya-submittal-activity-and-comments-section
                source="Vendor"
                [activities]="activities"
                [showChangeSortOrder]="true"
                [showReplyButton]="true"
                [isAyaAccountManagement]="details.useAyaAccountManagement"
                (replyToComment)="openReplyToCommentDialog($event)"
                (addComment)="openAddCommentDialog()"
                (changeSortOrder)="changeSortOrderMade($event)">
            </aya-submittal-activity-and-comments-section>
        </ng-template>
    </ul>

    <!-- remove the ngClass condition and order styles when removing FF -->
    <ul class="sub-details" [ngClass]="vendorSubmittalsDetailsMoveStatusToTopRight ? 'sub-details--third' : 'sub-details--second'">
        <li class="sub-details-item sub-details-item--heading">
            <span class="heading heading--candidate-info">
                Candidate Info
                <a class="edit-rto" (click)="editRto()">Edit</a>
            </span>
        </li>
        <li *ngIf="isAdmin" class="sub-details-item">
            <label>Vendor:</label>
            <div class="item-text">
                <a (click)="goToVendorDetails(details.vendorId)">
                    <div>{{ details?.vendorName }}</div>
                </a>
            </div>
        </li>
        <li class="sub-details-item">
            <label>Name:</label>
            <div class="item-text">
                <a (click)="goToCandidateDetails(details.candidateId)">
                    {{ candidateName }}
                </a>
            </div>
        </li>
        <li class="sub-details-item">
            <label>Profession:</label>
            <div class="item-text">{{ details?.professionName }}</div>
        </li>
        <li class="sub-details-item">
            <label>Available to Start:</label>
            <div class="item-text" *ngIf="candidateInfo; else submittalDetailsCandidateStartDate" data-qa-id="submittal-available-start-date">
                <ng-container *ngIf="candidateInfo?.availableStartDate; else candidateJobStartDate">
                    {{ candidateInfo?.availableStartDate | date: 'MMM dd, yyyy' }}
                </ng-container>
                <ng-template #candidateJobStartDate>
                    {{ details?.jobStartDate | date: 'MMM dd, yyyy' }}
                </ng-template>
            </div>
            <ng-template class="item-text" #submittalDetailsCandidateStartDate>
                <ng-container *ngIf="details?.availableStartDate">
                    {{ details?.availableStartDate | date: 'MMM dd, yyyy' }}
                </ng-container>
            </ng-template>
        </li>
        <li class="sub-details-item">
            <label>RTO:</label>
            <div class="item-text">
                    <ng-container *ngIf="candidateInfo?.requestTimeOffDates?.length; else newSubmittal">
                        {{ candidateInfo?.requestTimeOffDates | requestTimeOff:'MMMM DD YYYY':true }}
                    </ng-container>
                    <ng-template #newSubmittal>
                        <ng-container *ngIf="submittalDetails?.requestTimeOffDates?.length; else noRtoMessage">
                            {{ submittalDetails?.requestTimeOffDates | requestTimeOff:'MMMM DD YYYY':true }}
                        </ng-container>
                    </ng-template>
                <ng-template #noRtoMessage>
                    No RTO Requested
                </ng-template>
            </div>
        </li>
        <li class="sub-details-item">
            <label>Preferred Shift:</label>
            <div class="item-text" *ngIf="candidateInfo; else submittalDetailsPreferredShift" data-qa-id="submittal-preferred-shift">
                {{ candidateInfo.preferredShiftTypes | enumListToStringPipe:shiftType }}
            </div>
            <ng-template class="item-text" #submittalDetailsPreferredShift>
                {{ details.preferredShifts | enumListToStringPipe:shiftType }}
            </ng-template>
        </li>
    </ul>

    <ul class="sub-details sub-details--fourth" *ifLdFeature="featureFlag.VendorSubmittalsDetailsMoveStatusToTopRight"></ul>
</div>

<ng-template #template let-anchor>
    <div class="submittal-history-tooltip">
        <div class="tooltip-entry">
            <div class="tooltip-label">Date:</div>
            <div class="tooltip-value">
                {{ anchor.nativeElement.getAttribute('data-dateEntered') | date: 'MMM dd, yyyy' }}
            </div>
        </div>
        <div class="tooltip-entry" *ngIf="anchor.nativeElement.getAttribute('data-reasonName')">
            <div class="tooltip-label">Reason:</div>
            <div class="tooltip-value">{{ anchor.nativeElement.getAttribute('data-reasonName') }}</div>
        </div>
        <div class="tooltip-entry" *ngIf="anchor.nativeElement.getAttribute('data-note')">
            <div class="tooltip-label">Note:</div>
            <div class="tooltip-value">{{ anchor.nativeElement.getAttribute('data-note') }}</div>
        </div>
    </div>
</ng-template>
