import { Action, createReducer, on } from '@ngrx/store';
import * as TravelerDocumentActions from '../actions/travelers-documents.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { DocumentTravelerDetails } from '../../models/document-traveler-details.model';
import { DocumentMetadata } from '../../models/document-metadata.model';

export interface TravelersDocumentDetailsState {
    error: unknown;
    loading: LoadingTypes;
    travelerDetails: DocumentTravelerDetails;
    documentMetadata: DocumentMetadata;
    contractId: number;
    documentId: number;
}

export const initialState: TravelersDocumentDetailsState = {
    error: null,
    loading: LoadingTypes.INIT,
    travelerDetails: null,
    documentMetadata: null,
    contractId: 0,
    documentId: 0,
}

const reducer = createReducer(
    initialState,
    on(TravelerDocumentActions.loadTravelerDetailsForDocuments, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING,
        contractId: action.contractId
    })),
    on(TravelerDocumentActions.loadTravelerDetailsForDocumentsSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        travelerDetails: action.details,
    })),
    on(TravelerDocumentActions.loadTravelerDetailsForDocumentsFail, (state, action) => ({
        ...state,
        loading: LoadingTypes.FAIL,
        travelerDetails: null,
        error: action.error,
    })),
    on(TravelerDocumentActions.loadDocumentMetadata, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING,
        contractId: action.contractId,
        documentId: action.documentId
    })),
    on(TravelerDocumentActions.loadDocumentMetadataSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        documentMetadata: action.metadata
    })),
    on(TravelerDocumentActions.loadDocumentMetadataFail, (state, action) => ({
        ...state,
        loading: LoadingTypes.FAIL,
        documentMetadata: null,
        error: action.error,
    })),
    on(TravelerDocumentActions.approveDocument, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(TravelerDocumentActions.approveDocumentSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED
    })),
    on(TravelerDocumentActions.approveDocumentFail, (state, action) => ({
        ...state,
        error: action.error,
        loading: LoadingTypes.LOADED
    })),
    on(TravelerDocumentActions.declineDocument, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(TravelerDocumentActions.declineDocumentSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED
    })),
    on(TravelerDocumentActions.declineDocumentFail, (state, action) => ({
        ...state,
        error: action.error,
        loading: LoadingTypes.LOADED
    })),
)

export function travelersDocumentDetailsReducer(state: TravelersDocumentDetailsState | undefined, action: Action) {
    return reducer(state, action);
}
