import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings, APP_CONFIG } from 'src/app/config/settings';
import { Observable } from 'rxjs';
import { ResourceDocument } from '../models';
import { interpolateParams, interpolateUrl } from 'src/app/core/utils';
import { FileAttachment, FileValidationResult } from 'src/app/shared/models/attachment';

enum ResourceDocumentsUrls {
    ALL = '/AyaConnect/Client/InternalPool/{resourceId}/Attachments',
    ONE = '/AyaConnect/Client/InternalPool/{resourceId}/Attachments/{id}',
    PREVIEW = '/AyaConnect/Client/InternalPool/{resourceId}/Attachments/preview/{id}',
    TOTAL = '/AyaConnect/Client/InternalPool/{resourceId}/Attachments/pageCount/{id}',
    ADD = '/AyaConnect/Client/InternalPool/{resourceId}/Attachments',
    UPDATE = '/AyaConnect/Client/InternalPool/{resourceId}/Attachments/{id}',
    DELETE = '/AyaConnect/Client/InternalPool/{resourceId}/Attachments/{id}'
}

@Injectable({
    providedIn: 'root'
})
export class ResourceDocumentsService {
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings
    ) {}

    getDocuments(resourceId: number): Observable<ResourceDocument[]> {
        const url = interpolateUrl(ResourceDocumentsUrls.ALL, { resourceId });

        return this.http.get<ResourceDocument[]>(`${this.settings.CORE}${url}`);
    }

    getDocumentBlob(resourceId: number, id: number): Observable<Blob> {
        const url = interpolateUrl(ResourceDocumentsUrls.ONE, { resourceId, id });

        return this.http.get(`${this.settings.CORE}${url}`, { responseType: 'blob' });
    }

    getPreviewDocumentBlob(resourceId: number, id: number, pageNum: number): Observable<Blob> {
        const url = interpolateUrl(ResourceDocumentsUrls.PREVIEW, { resourceId, id });
        const params = interpolateParams({ pageNum });

        return this.http.get(`${this.settings.CORE}${url}`, { params: params, responseType: 'blob' });
    }

    getTotalNumOfDoc(resourceId: number, id: number): Observable<number> {
        const url = interpolateUrl(ResourceDocumentsUrls.TOTAL, { resourceId, id });

        return this.http.get<number>(`${this.settings.CORE}${url}`);
    }

    postDocument(resourceId: number, fileToUpload: any, internalOnly: boolean): Observable<FileValidationResult> {
        const input = new FormData();
        input.append('file', fileToUpload);

        const url = interpolateUrl(ResourceDocumentsUrls.ADD, { resourceId });
        const params = interpolateParams({ internalOnly });

        return this.http.post<FileValidationResult>(`${this.settings.CORE}${url}`, input, { params });
    }

    updateDocument(resourceId: number, fileToUpload: FileAttachment, internalOnly: boolean): Observable<number> {
        const url = interpolateUrl(ResourceDocumentsUrls.UPDATE, { resourceId, id: fileToUpload.id });
        const input = new FormData();
        input.append('id', String(fileToUpload.id));
        input.append('fileName', fileToUpload.fileName);
        input.append('internalOnly', String(internalOnly));

        return this.http.put<number>(`${this.settings.CORE}${url}`, input);
    }

    deleteDocument(resourceId: number, id: number): Observable<unknown> {
        const url = interpolateUrl(ResourceDocumentsUrls.UPDATE, { resourceId, id });

        return this.http.delete<unknown>(`${this.settings.CORE}${url}`);
    }

    urlPreview(resourceId: number, id: number) {
        return interpolateUrl(ResourceDocumentsUrls.PREVIEW, { resourceId, id });
    }
}
