<ng-container *ngIf="upgradeStyle; else nonUpgradedStyle">
    <mat-drawer-container class="sidenav-container" [class.sidenav-expanded]="menuExpanded" autosize>

        <mat-drawer #drawer class="sidenav" fixedInViewport mode="side" [opened]="true" [disableClose]="true">

            <mat-toolbar class="menu-toggle">
                <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="menuExpanded = !menuExpanded"
                    class="mx-auto">
                    <mat-icon aria-label="Side nav toggle icon">
                        menu
                    </mat-icon>
                </button>
            </mat-toolbar>

            <span class="mb-3"></span>

            <app-side-nav [isExpanded]="menuExpanded"></app-side-nav>

            <span class="fx-spacer"></span>

            <app-footer [isExpanded]="menuExpanded"></app-footer>

        </mat-drawer>
        <mat-drawer-content>

            <app-top-nav></app-top-nav>

            <div class="content-wrapper">
                <router-outlet></router-outlet>
            </div>

        </mat-drawer-content>
    </mat-drawer-container>
</ng-container>

<ng-template #nonUpgradedStyle>
    <header class="clearfix">
        <ayac-top-nav *ngIf="!isShiftResetPassword"></ayac-top-nav>
    </header>

    <section id="content" class="content">
        <ayac-sidebar></ayac-sidebar>

        <div class="main-content" [class.full-screen]="fullScreen">
            <router-outlet></router-outlet>
        </div>
    </section>
</ng-template>
