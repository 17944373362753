import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    loadShiftCombinedHistory,
    loadShiftCombinedHistorySuccess,
    loadShiftCombinedHistoryFail,
    clearShiftCombinedHistory,
    clearShiftDetailsAndHistory
} from 'src/app/shifts/store/actions';
import { ShiftHistory } from 'src/app/shared/models/shifts/shift-history.model';

export interface ShiftCombinedHistoryState {
    history: ShiftHistory[];
    loading: LoadingTypes;
}

export const initialCombinedHistoryState: ShiftCombinedHistoryState = {
    history: [],
    loading: LoadingTypes.INIT
};

export function shiftCombinedHistoryReducer(state: ShiftCombinedHistoryState, action: Action) {
    return createReducer(
        initialCombinedHistoryState,
        on(loadShiftCombinedHistory, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(loadShiftCombinedHistorySuccess, (state, action) => ({
            ...state,
            history: action.history,
            loading: LoadingTypes.LOADED
        })),

        on(loadShiftCombinedHistoryFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL
        })),

        on(clearShiftCombinedHistory, clearShiftDetailsAndHistory, (state, action) => ({
            ...state,
            ...initialCombinedHistoryState
        }))
    )(state, action);
}

export function reducer(state: ShiftCombinedHistoryState | undefined, action: Action) {
    return shiftCombinedHistoryReducer(state, action);
}
