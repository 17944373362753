<loading-indicator *ngIf="isLoading$ | async">
</loading-indicator>

<ayac-widget>
    <div id="client-submittal-details-page">
        <div class="submittal-details-container" *ngIf="submittal$ | async as submittal">
            <div widget-breadcrumb>
                <ayac-breadcrumb>
                    <a routerLink="/client/submittals">{{'All Submittals' | terminology}}</a>
                    <span>&nbsp;>&nbsp;</span>
                    <span>{{'Submittal Details' | terminology}}</span>
                </ayac-breadcrumb>
            </div>
            <div class="candidate-profile" widget-header>
                <div>
                    <ayac-portal-page-header [title]="submittal.userName" [badge]="statusBadge$ | async"
                        [isOneRow]="true" *ngIf="!displayNewSubmittalStatusEnabled">
                        <ng-template #pageHeaderSuffix>
                            <mat-icon *ngIf="isPendingOverdue$ | async"
                                matTooltip="{{'Submittal has been pending review for over 2 days' | terminology}}" color="error"
                                class="mx-2">
                                error
                            </mat-icon>
                        </ng-template>

                        <ng-template #pageHeaderToolbar>
                            <ng-container>
                                <div class="spacer"></div>
                                <button [matMenuTriggerFor]="menu" class="update-status" *ngIf="canUpdateStatus$ | async">
                                    <div class="status-dropdown">
                                        <mat-icon class="material-icons-outlined forward-icon">forward</mat-icon>
                                        Update Status
                                        <mat-icon>arrow_drop_down</mat-icon>
                                    </div>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button *ngFor="let status of statusLookupsMapped$ | async; let i = index" mat-menu-item
                                        (click)="updateCandidateStatus(status.id)"
                                        [ngClass]="i+1 === submittal.statusId ? 'active-status': ''">
                                        {{status.name}}
                                    </button>
                                </mat-menu>

                                <button mat-flat-button class="offer-btn" (click)="offerCandidate(submittal)"
                                    *ngIf="canOffer$ | async"
                                    [disabled]='(isStatusLoading$ | async) || (isLoading$ | async)'>
                                    <mat-icon fontSet="fas" fontIcon="fa-bolt"></mat-icon>
                                    Offer
                                </button>

                                <button mat-flat-button class="decline-btn"
                                    (click)="declineCandidate({declineReasonId: null, notes: null})"
                                    *ngIf="canDecline$ | async"
                                    [disabled]='(isStatusLoading$ | async) || (isLoading$ | async)'>
                                    <mat-icon fontSet="far" fontIcon="fa-thumbs-down"></mat-icon>
                                    Decline
                                </button>
                            </ng-container>
                        </ng-template>
                    </ayac-portal-page-header>

                    <ayac-portal-page-header [title]="submittal.userName" [isOneRow]="true"
                    *ngIf="displayNewSubmittalStatusEnabled" class="submittal-status">
                        <ng-template #pageHeaderSuffix>
                            <mat-icon *ngIf="isPendingOverdue$ | async"
                                matTooltip="{{'Submittal has been pending review for over 2 days' | terminology}}" color="error"
                                class="mx-2">
                                error
                            </mat-icon>
                            <ng-container *ngIf="unifyChipFlagEnabled; else vmsOfferChip">
                                <span *ngIf="isReadOnlyStatus" data-qa-id="client-submittal-details-status-chip"
                                class="status-chip" [class.offer-accepted]="submittalStatus?.submittalStatusId === submittalStatuses.OfferAccepted">
                                    {{statusChipText}}
                                </span>
                            </ng-container>
                            <ng-template #vmsOfferChip>
                                <ng-container *ngIf="showOfferChip">
                                    <span data-qa-id="client-submittal-details-offer-sent-chip" class="offer-sent-chip">
                                        Offer Sent
                                    </span>
                                </ng-container>
                            </ng-template>
                        </ng-template>
                        <ng-template #pageHeaderToolbar>
                            <ng-container *ngIf="showSubmittalButtons else offerTabButtons">
                                <ng-container *ngIf="unifyChipFlagEnabled || !showDeclineView; else declineView">
                                    <ng-container *ngIf="showSubmittalStatusButtons">
                                        <button *ngIf="canUpdateStatus$ | async"
                                                data-qa-id="client-submittal-details-status-button"
                                                mat-flat-button color="accent"
                                                [matMenuTriggerFor]="statusMenu">
                                            {{submittalStatusDisplay}}
                                            <mat-icon class="status-selection-dropdown-arrow arrow-down" fontIcon="arrow_drop_down"></mat-icon>
                                        </button>
                                        <button *ngIf="canDecline$ | async"
                                                mat-stroked-button color="warn"
                                                data-qa-id="client-submittal-details-decline-button"
                                                (click)="declineCandidate({declineReasonId: null, notes: null})">
                                            Decline
                                        </button>
                                    </ng-container>
                                </ng-container>
                                <!-- This node #declineView can be removed along with unifyChipFlagEnabled from flag ConnectUnifySubmittalDetailsChip -->
                                <ng-template #declineView>
                                    <div class="decline-chip-wrapper">
                                        <span class="decline-chip">
                                            {{submittal.submittalStatusName}}
                                        </span>
                                    </div>
                                    <button mat-stroked-button color="accent" [matMenuTriggerFor]="statusMenu"
                                        *ngIf="(canUpdateStatus$ | async) && !submittal.isDeleted && submittal.submittalStatusId !== submittalStatuses.MoreInformationNeeded"
                                        data-qa-id="client-submittal-details-update-status-button">
                                        Update Status
                                        <mat-icon class="status-selection-dropdown-arrow" fontIcon="keyboard_arrow_down"></mat-icon>
                                    </button>
                                </ng-template>

                                <mat-menu class="client-submittal-details-status-menu" #statusMenu="matMenu">
                                    <ng-container *ngFor="let status of statusLookupsMapped$ | async">
                                        <ng-container *ngIf="status.subStatuses && status.subStatuses.length > 0">
                                            <button mat-menu-item *ngFor="let subStatus of status.subStatuses"
                                                (click)="updateStatus(status, subStatus)"
                                                [disabled]="(isStatusLoading$ | async) || (isLoading$ | async) || (submittal.submittalStatusId === status.id && submittal.submittalSubStatusId === subStatus.id)">
                                                {{status.name}} {{subStatus.name}}
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="!status.subStatuses || status.subStatuses.length === 0">
                                            <button mat-menu-item (click)="updateStatus(status)"
                                                [disabled]="(isStatusLoading$ | async) || (isLoading$ | async) || submittal.submittalStatusId === status.id">
                                                {{status.name}}</button>
                                        </ng-container>
                                    </ng-container>
                                    <button mat-menu-item *ngIf="canOffer$ | async" (click)="offerCandidate(submittal)"
                                        [disabled]="(isStatusLoading$ | async) || (isLoading$ | async) || isOfferOptionDisabled"
                                        class="offer-button">
                                        Offer <span *ngIf="isOfferOptionDisabled && offerButtonTitle?.length > 0"
                                            class="offer-tooltip-wrapper"><span class="offer-button-tooltip">{{offerButtonTitle}}</span></span>
                                    </button>
                                </mat-menu>
                            </ng-container>
                            <ng-template #offerTabButtons>
                                <button mat-flat-button color="accent"
                                    *ngIf="standaloneVmsEditOfferFlag && offerInEditMode"
                                    data-qa-id="client-submittal-offer-details-update-button"
                                    (click)="confirmOfferUpdate()"
                                    [disabled]="!isSendOfferDetailsBtnEnabled">
                                    Send Offer Update
                                </button>
                                <button mat-stroked-button color="accent"
                                    *ngIf="canEditOffer"
                                    [disabled]="offerInEditMode"
                                    data-qa-id="client-submittal-offer-details-edit-button"
                                    (click)="editOffer()">
                                    Edit
                                </button>
                                <button mat-stroked-button color="warn"
                                    *ngIf="canDeclineOfferChanges"
                                    data-qa-id="client-submittal-offer-details-decline-changes-button"
                                    (click)="declineChangesRequested()">
                                    Decline Changes
                                </button>
                                <button mat-stroked-button color="warn"
                                    *ngIf="canRescindOffer"
                                    data-qa-id="client-submittal-offer-details-rescind-button"
                                    (click)="rescindOffer()">
                                    Rescind
                                </button>
                                <button mat-stroked-button color="warn"
                                    *ngIf="standaloneVmsEditOfferFlag && offerInEditMode"
                                    data-qa-id="client-submittal-offer-details-cancel-button"
                                    (click)="cancelOfferEdit()">
                                    Cancel
                                </button>
                            </ng-template>
                        </ng-template>
                    </ayac-portal-page-header>
                </div>
            </div>
            <ng-container *ifLdFeature="featureFlag.ConnectStandaloneVMSClientOfferFlow; enabled: false">
                <ng-container [ngTemplateOutlet]="submittalDetails">
                </ng-container>
            </ng-container>
            <ng-container *ifLdFeature="featureFlag.ConnectStandaloneVMSClientOfferFlow; enabled: true">
                <ng-container [ngTemplateOutlet]="tabContainer">
                </ng-container>
            </ng-container>

            <ng-template #submittalDetails>
                <div class="submittal-details-body">
                    <div class="submittal-details-main" [ngClass]="!activitySectionMove || !hasActivities ? 'full-width' : ''">
                    <div class="candidate-profile" widget-header>
                        <div class="grid">
                            <mat-card data-qa-id="ac_client_smd_details_card">
                                <mat-card-title><span>Candidate Profile</span></mat-card-title>
                                <mat-divider></mat-divider>
                                <mat-card-content>
                                    <mat-list data-qa-id="ac_client_smd_details_list">
                                        <mat-list-item *ngIf="submittal.userName">
                                            <div class="field-title">Name</div>
                                            <div class="field-value" data-qa-id="ac_client_smd_name_readonly">
                                                {{submittal.userName}}
                                            </div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf="submittal.npi">
                                            <div class="field-title">NPI</div>
                                            <div class="field-value" data-qa-id="ac_client_smd_npi_readonly">
                                                {{submittal.npi}} <span *ngIf="submittal.showNpiWarning" class="npi-chip ml-2" data-qa-id="ac_client_smd_npi_chip">NC</span>
                                            </div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf="submittal.agencyName">
                                            <ng-container>
                                                <div class="field-title">Agency Name</div>
                                                <div class="field-value" data-qa-id="ac_client_smd_name_readonly">
                                                    {{submittal.agencyName}}
                                                    <div class="diversity-info" *ngIf="submittal.certifications?.length > 0">
                                                        <ayac-rich-tooltip
                                                            [displayTemplate]="diversityInfoTemplate"
                                                            [data]="submittal.certifications"
                                                            width="350">
                                                            <a class="diversity-tooltip" role="button"><mat-icon>group</mat-icon> Diversity Partner</a>
                                                        </ayac-rich-tooltip>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </mat-list-item>
                                        <mat-list-item class="extended-field" *ngIf="submittal.showAgentInfo && submittal.agentName">
                                            <div class="field-title">Agent</div>
                                            <div class="field-value agent-info" data-qa-id="ac_client_smd_agent_info">
                                                {{submittal.agentName}}<br>
                                                <a href="mailto:{{submittal.agentEmail}}">{{submittal.agentEmail}}</a>
                                            </div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf="submittal.availableDate">
                                            <div class="field-title">Available Start Date</div>
                                            <div class="field-value" data-qa-id="ac_client_smd_available_readonly">
                                                {{submittal.availableDate | date : 'MM/dd/yyyy' : 'UTC' }}
                                            </div>
                                        </mat-list-item>
                                        <mat-list-item>
                                            <div class="field-title"><span matTooltip="Requested Time Off">RTO</span></div>
                                            <div class="field-value" data-qa-id="ac_client_smd_rto_readonly">
                                                <ng-container *ngIf="submittal?.requestTimeOffDates?.length; else noRtoMessage">
                                                    {{ submittal?.requestTimeOffDates | requestTimeOff:'MMMM DD YYYY' }} <!-- may need to update? international clients?-->
                                                </ng-container>
                                                <ng-template #noRtoMessage>
                                                    No RTO requested at this time
                                                </ng-template>
                                            </div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf="submittal.resumeProfessionAbbrev">
                                            <div class="field-title">Profession</div>
                                            <div class="field-value" data-qa-id="ac_client_smd_profession_readonly">
                                                {{submittal.resumeProfessionAbbrev}}</div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf="submittal.vendorCandidateSpecialties || submittal.specialty">
                                            <div class="field-title">Specialty</div>
                                            <div class="field-value" data-qa-id="ac_client_smd_specialties_readonly"
                                                *ngIf="submittal.vendorCandidateSpecialties">
                                                {{submittal.vendorCandidateSpecialties}}</div>
                                            <div class="field-value" data-qa-id="ac_client_smd_specialties_readonly"
                                                *ngIf="!submittal.vendorCandidateSpecialties">
                                                {{submittal.specialty}}</div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf="submittal.shift">
                                            <div class="field-title">Preferred Shift</div>
                                            <div class="field-value" data-qa-id="ac_client_smd_shift_readonly">{{submittal.shift
                                                | titlecase}}</div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf="submittal.dayPhone">
                                            <div class="field-title">Phone #</div>
                                            <div class="field-value" data-qa-id="ac_client_smd_dayphone_readonly">
                                                {{submittal.dayPhone}}</div>
                                        </mat-list-item>
                                        <mat-list-item class="extended-field" *ngIf="submittal.address">
                                            <div class="field-title">Address</div>
                                            <div class="field-value" data-qa-id="ac_client_smd_address_readonly">
                                                {{submittal.address}}<br>
                                                {{submittal.city}}, {{submittal.state}} {{submittal.zipCode}}
                                            </div>
                                        </mat-list-item>
                                        <ng-container *ngIf="mspCustomFields$ | async as mspFields">
                                            <ng-container *ngIf="mspFields.Bjc as bjc">
                                                <mat-list-item class="extended-field">
                                                    <div class="field-title">BJC Worker ID</div>
                                                    <div class="field-value" data-qa-id="ac_client_smd_bjc_id_readonly">
                                                        {{bjc.bjcWorkerId || 'null'}}
                                                    </div>
                                                </mat-list-item>
                                            </ng-container>
                                        </ng-container>
                                        <mat-list-item *ngIf="submittal.email">
                                            <div class="field-title">Email</div>
                                            <div class="field-value">
                                                <a data-qa-id="ac_client_smd_email_readonly"
                                                    href="mailto:{{submittal.email}}">{{submittal.email}}</a>
                                            </div>
                                        </mat-list-item>
                                    </mat-list>
                                </mat-card-content>
                            </mat-card>

                            <ng-container *ngIf="(submittal.declineReasonId !== null || submittal.statusId === 5)">
                                <ng-container *ifLdFeature="featureFlag.ConnectClientDisplayNewSubmittalStatus; enabled: false">
                                    <mat-card>
                                        <mat-card-title>
                                            <div class="decline-header">
                                                <span>Decline Reason</span>
                                                <button mat-stroked-button
                                                    (click)="declineCandidate({declineReasonId: submittal.declineReasonId, notes: submittal.declineNotes})"
                                                    color="accent">
                                                    Edit
                                                </button>
                                            </div>
                                        </mat-card-title>
                                        <mat-divider></mat-divider>
                                        <mat-card-content>
                                            <mat-list>
                                                <mat-list-item>
                                                    <div class="field-title">Reason</div>
                                                    <div class="field-value">{{submittal.declineReason}}</div>
                                                </mat-list-item>
                                                <mat-list-item>
                                                    <div class="field-title">Notes</div>
                                                    <div class="field-value decline-notes">{{submittal.declineNotes}}</div>
                                                </mat-list-item>
                                            </mat-list>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div class="mat-card-spacing">
                        <aya-submittal-work-history-section
                            [candidateId]="submittal.userId"></aya-submittal-work-history-section>
                    </div>
                    <div class="mat-card-spacing">
                        <aya-submittal-certifications-section [candidateId]="submittal.userId"
                            [isSubContractor]="submittal.isSubContractor"></aya-submittal-certifications-section>
                    </div>
                    <div class="mat-card-spacing">
                        <aya-submittal-emr-section [candidateId]="submittal.userId"
                            [isSubContractor]="submittal.isSubContractor"></aya-submittal-emr-section>
                    </div>
                    <div class="mat-card-spacing">
                        <aya-submittal-education-section [candidateId]="submittal.userId"></aya-submittal-education-section>
                    </div>
                    <div class="mat-card-spacing" *ngIf="(attachmentsMetaData$ | async) as attachmentsMetaData">
                        <mat-card *ngIf="attachmentsMetaData.length > 0">
                            <mat-card-title><span>Application Documents</span></mat-card-title>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <table mat-table [dataSource]="attachmentsMetaData" class="mat-card-table"
                                    aria-label="Application Documents"
                                    data-qa-id="ac_client_view_attachment_documents_table">
                                    <ng-container matColumnDef="fileName">
                                        <th mat-header-cell *matHeaderCellDef scope="row"> File Name </th>
                                        <td mat-cell *matCellDef="let element"><a
                                                (click)="openAttachment(element.attachmentId)">{{element.fileName}}</a></td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="applicationDocumentsColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: applicationDocumentsColumns;"></tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="candidate-jobs" *ngIf="(jobs$ | async) as jobs">
                        <mat-card *ngIf="jobs.length > 0">
                            <mat-card-title><span>Jobs Applied</span></mat-card-title>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <table mat-table [dataSource]="jobs" class="mat-card-table" aria-label="Jobs List">
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef style="width: 5%;" scope="row"> ID </th>
                                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="facility">
                                        <th mat-header-cell *matHeaderCellDef style="width: 15%;" scope="row"> Facility </th>
                                        <td mat-cell *matCellDef="let element"> {{element.facility}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="profession">
                                        <th mat-header-cell *matHeaderCellDef style="width: 15%;" scope="row"> Profession </th>
                                        <td mat-cell *matCellDef="let element"> {{element.profession}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="expertise">
                                        <th mat-header-cell *matHeaderCellDef style="width: 15%;" scope="row"> Specialty </th>
                                        <td mat-cell *matCellDef="let element"> {{element.expertise}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="startDate">
                                        <th mat-header-cell *matHeaderCellDef style="width: 10%;" scope="row"> Start Date </th>
                                        <td mat-cell *matCellDef="let element"> {{element.startDate | date : 'MM/dd/yyyy' :
                                            'UTC'}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="shiftText">
                                        <th mat-header-cell *matHeaderCellDef style="width: 10%;" scope="row"> Shift </th>
                                        <td mat-cell *matCellDef="let element"> {{element.shiftText | titlecase}}
                                            {{element.shiftStartTime | date:'h:mma'}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="note">
                                        <th mat-header-cell *matHeaderCellDef scope="row"> Notes from AM </th>
                                        <td mat-cell *matCellDef="let element"> {{element.note}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="jobsDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: jobsDisplayedColumns;"></tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <ng-container *ifLdFeature="featureFlag.ClientSubmittalDetailsMoveActivitySection; enabled: false">
                        <div class="mat-card-spacing" *ifLdFeature="featureFlag.ConnectClientSubmittalEnableActivity">
                            <ayac-submittal-activity-section [submittalId]="submittal.id"></ayac-submittal-activity-section>
                        </div>
                    </ng-container>
                    </div>
                    <ng-container *ifLdFeature="featureFlag.ClientSubmittalDetailsMoveActivitySection">
                        <div class="submittal-details-right-sidebar" *ngIf="hasActivities">
                            <ng-container *ngIf="isCommunicationEnabled else activityOnly">
                                <aya-submittal-activity-and-comments-section
                                    source="Client"
                                    [activities]="activities"
                                    [showChangeSortOrder]="submittal.facilityDirectApplyEnabled"
                                    [showReplyButton]="true"
                                    [isPendingOverdue]="isPendingOverdue$ | async"
                                    (changeSortOrder)="changeSortOrder($event)"
                                    (replyToComment)="replyToComment($event)">
                                </aya-submittal-activity-and-comments-section>
                            </ng-container>
                            <ng-template #activityOnly>
                                <ayac-submittal-activity-section [submittalId]="submittal.id"></ayac-submittal-activity-section>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
            </ng-template>

            <ng-template #tabContainer>
                <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="onSelectedTabChange($event)"
                    class="migrated-tab-label-active submittal-details-tab-container" mat-align-tabs="start" animationDuration="0ms">
                    <mat-tab data-qa-id="client-submittal-details-submittal-tab">
                        <ng-template mat-tab-label class="nav-label">
                            {{'Submittal' | terminology}}
                        </ng-template>
                        <div class="submittal-details-tab-child">
                            <ng-container mat-tab-content [ngTemplateOutlet]="submittalDetails">
                            </ng-container>
                        </div>
                    </mat-tab>
                    <mat-tab data-qa-id="client-submittal-details-offer-tab" *ngIf="offer$ | async as offer">
                        <ng-template mat-tab-label class="nav-label">
                            Offer
                            <mat-icon
                                data-qa-id="client-submittal-details-changes-requested-icon"
                                class="changes-requested"
                                *ngIf="offerHasChangeRequested"
                                matTooltip="Changes Requested">
                                    error
                            </mat-icon>
                        </ng-template>
                        <div class="submittal-details-tab-child">
                            <ng-container mat-tab-content>
                                <ayac-submittal-offer-details [offer]="offer"
                                    [offerInEditMode]="offerInEditMode"
                                    [collectOfferDetailsUpdateRequest]="collectOfferDetailsUpdateRequest"
                                    [facilityDirectApplyEnabled]="submittal.facilityDirectApplyEnabled"
                                    (saveOffer)="saveOfferUpdate($event)"
                                    (canUpdate)="canUpdateOffer($event)">
                                </ayac-submittal-offer-details>
                            </ng-container>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </ng-template>

            <ng-template #diversityInfoTemplate let-data="data">
                <ayac-diversity-info-tooltip [certifications]="data">
                </ayac-diversity-info-tooltip>
            </ng-template>
        </div>
    </div>
</ayac-widget>


