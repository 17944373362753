import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as actions from '../invoices.actions';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import { ToasterService } from 'src/app/core/services';

@Injectable()
export class ApproveDraftInvoiceEffect {
    approveDraftInvoice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.approveDraftInvoices),
            switchMap((action) => {
                return this.invoicesRepository.approveDraftInvoices(action.invoiceIds).pipe(
                    mergeMap((_) => {
                        this.toaster.success(`Invoice${action.invoiceIds.length > 1 ? 's' : ''} approved`);
                        return [actions.approveDraftInvoicesSuccess(), actions.approveDraftInvoicesReset()];
                    }),
                    catchError((error) => {
                        const errorMessage =
                            error.status === 404
                                ? 'Invoices not found.'
                                : error.status === 403
                                  ? 'User does not have permissions to approve billable invoice.'
                                  : error.error;
                        return of(
                            actions.approveDraftInvoicesFail({ error: errorMessage }),
                            actions.approveDraftInvoicesReset()
                        );
                    })
                );
            })
        )
    );

    approveDraftInvoicesFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.approveDraftInvoicesFail),
                map((action) => {
                    this.toaster.fail(action.error);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly invoicesRepository: InvoicesRepositoryService,
        private readonly toaster: ToasterService
    ) {}
}
