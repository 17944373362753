import { ListItem } from 'src/app/shared/models/list-item';
import { GridSearchQuery } from '../../grid/models';
import { initialShiftSearchQuery } from './shift-list.model';
import { ClientShiftVisibility } from './enums/shifts-visibility.enum';
import { SpecialtyLookup } from '../lookups/specialty-lookup.model';
import { ShiftReleaseType } from 'src/app/shared/models/shifts';

export class FilterParams {
    shiftsQuery: GridSearchQuery;
    facilities: ListItem[] = [];
    statuses: ListItem[] = [];
    specialties: SpecialtyLookup[] = [];
    professions: ListItem[] = [];
    units: ListItem[] = [];
    dateFrom: Date;
    dateTo: Date;
    viewByValue: number;
    viewShiftDetailsValue: number = null;
    shiftVisibilityValue: number = ClientShiftVisibility.All;
    releases: ShiftReleaseType[] = [];

    constructor(dateFrom: Date, dateTo: Date, viewByValue: number, shiftsQuery?: GridSearchQuery) {
        this.dateFrom = dateFrom || null;
        this.dateTo = dateTo || null;
        this.viewByValue = viewByValue || ViewByTypes.Week;
        this.shiftsQuery = shiftsQuery || initialShiftSearchQuery;
    }
}

export enum ViewByTypes {
    Day = 1,
    Week = 2,
    List = 3
}
