import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, dashboardStoreKey } from 'src/app/dashboard-new/store/dashboard.state';

export const getPendingSubmittalsTotal = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => state.pendingSubmittals.total
);

export const getPendingSubmittalsAgingDays = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => state.pendingSubmittals.agingDays
);

export const getPendingSubmittalsWarning = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => {
        return state.pendingSubmittals.agingCount === 0
            ? ''
            : `${state.pendingSubmittals.agingCount} submittals pending review for over ${state.pendingSubmittals.agingDays} days`;
    }
);
