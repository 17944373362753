import { Action, createReducer, on } from '@ngrx/store';
import * as TravelerDocumentActions from '../actions/travelers-documents.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import {
    ClientRequirementDocumentStatus,
    TravelerDocumentGridItem
} from '../../traveler-details/traveler-documents/grid/traveler-documents-grid.model';

export interface TravelersDocumentsState {
    error: string;
    loading: LoadingTypes;
    travelerDocuments: TravelerDocumentGridItem[];
    filteredTravelerDocuments: TravelerDocumentGridItem[];
    totalTravelerDocuments: number;
    selectedTravelerDocumentIds: number[];
    gridState: DataStateChangeEvent;
    contractId: number;
    clientDocumentStatus: ClientRequirementDocumentStatus;
}

export const initialState: TravelersDocumentsState = {
    error: '',
    loading: LoadingTypes.INIT,
    travelerDocuments: [],
    filteredTravelerDocuments: [],
    totalTravelerDocuments: 0,
    selectedTravelerDocumentIds: [],
    gridState: { skip: 0, take: 100, filter: { logic: 'and', filters: [] } } as DataStateChangeEvent,
    contractId: null,
    clientDocumentStatus: { candidateDocumentId: 0, documentStatusId: null } satisfies ClientRequirementDocumentStatus
};

const pageSize = 100;

const reducer = createReducer(
    initialState,
    on(TravelerDocumentActions.setGridState, (state, { newState }) => ({
        ...state,
        gridState: newState
    })),
    on(TravelerDocumentActions.setGridFilters, (state, { filters }) => ({
        ...state,
        gridState: { ...state.gridState, filter: { logic: 'and', filters: filters } } as DataStateChangeEvent
    })),
    on(TravelerDocumentActions.loadTravelerDocuments, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(TravelerDocumentActions.documentsFilterRequest, (state) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(TravelerDocumentActions.loadAllTravelerDocumentsRequest, (state) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(TravelerDocumentActions.loadSelectedTravelerDocumentsRequest, (state) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(TravelerDocumentActions.updateClearedStatus, (state) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(
        TravelerDocumentActions.updateClearedStatusSuccess,
        TravelerDocumentActions.updateClearedStatusFail,
        (state) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })
    ),
    on(TravelerDocumentActions.documentsLoadSuccess, (state, { documents }) => ({
        ...state,
        travelerDocuments: documents,
        filteredTravelerDocuments: documents.length > pageSize ? documents.slice(0, pageSize - 1) : documents,
        totalTravelerDocuments: documents.length,
        loading: LoadingTypes.LOADED,
        error: ''
    })),
    on(TravelerDocumentActions.documentsLoadError, TravelerDocumentActions.loadDocumentFail, (state, { error }) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        error: error
    })),
    on(TravelerDocumentActions.loadDocumentSuccess, (state) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        error: ''
    })),
    on(TravelerDocumentActions.documentsFilterSuccess, (state, { filteredDocuments }) => ({
        ...state,
        filteredTravelerDocuments: filteredDocuments.data,
        totalTravelerDocuments: filteredDocuments.total,
        loading: LoadingTypes.LOADED,
        error: ''
    })),
    on(TravelerDocumentActions.documentsFilterFail, (state, { error }) => ({
        ...state,
        error: error,
        loading: LoadingTypes.LOADED
    })),
    on(TravelerDocumentActions.documentsSelected, (state, { selectedDocuments }) => ({
        ...state,
        selectedTravelerDocumentIds: selectedDocuments
    })),
    on(TravelerDocumentActions.clearDocumentsGrid, (state) => ({
        ...state,
        ...initialState
    })),
    on(
        TravelerDocumentActions.loadCandidateDeclinedDocumentStatus,
        (state): TravelersDocumentsState => ({
            ...state,
            loading: LoadingTypes.LOADING
        })
    ),
    on(
        TravelerDocumentActions.loadCandidateDeclinedDocumentStatusSuccess,
        (state, { documentStatus }): TravelersDocumentsState => ({
            ...state,
            clientDocumentStatus: documentStatus,
            loading: LoadingTypes.LOADED
        })
    ),
    on(
        TravelerDocumentActions.loadCandidateDeclinedDocumentStatusFailure,
        (state, action): TravelersDocumentsState => ({
            ...state,
            error: action.error as string,
            loading: LoadingTypes.FAIL
        })
    )
);

export function travelersDocumentsReducer(state: TravelersDocumentsState | undefined, action: Action) {
    return reducer(state, action);
}
