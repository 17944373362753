<small class="mat-subtitle-1 mb-0 pl-2">
    (
    <ng-container *ngFor="let item of totals; let last = last;">

        <span *ngIf="item.total != null; else totalLinkTemplate">
            {{item.label}} Total: {{item.total}}
        </span>

        <ng-template #totalLinkTemplate>
            <a [routerLink]="[]"
                (click)="onSelectFilter(item)">
                {{item.label}}
            </a>
        </ng-template>

        <span *ngIf="!last">| </span>
    </ng-container>
    )
</small>