import { Settings } from './settings';
//eventually we will phase out services.config.js; but for now it is the source of truth
const servicesConfig = window['servicesConfig'];
let environmentType = 'ONE';
if (window.location.host.includes('shifts.com')) {
    environmentType = 'SHFT';
}
const domainConfig =
    servicesConfig.domainConfig.find((config) => config.environment === environmentType)?.value ??
    servicesConfig.domainConfig[0].value;

const activatePendo: boolean = servicesConfig.activatePendo === 'true';

export const AppSettings: Settings = {
    CORE: domainConfig.CORE,
    V1: domainConfig.V1,
    QM: domainConfig.QM,
    INTRANET: domainConfig.INTRANET,
    AYA_HEALTHCARE: domainConfig.AYA_HEALTHCARE,
    NOVA: domainConfig.NOVA,
    ENABLE_FEATURE_POLLING: domainConfig.ENABLE_FEATURE_POLLING,
    FEATURE_POLL_INTERVAL: domainConfig.FEATURE_POLL_INTERVAL,
    IDLE_USER_TIMEOUT: domainConfig.IDLE_USER_TIMEOUT,
    CDN_DOMAIN: domainConfig.CDN_DOMAIN,
    ACTIVATE_PENDO: activatePendo,
    APP_NAME: servicesConfig.APP_NAME,
    DOMAIN: servicesConfig.DOMAIN,
    LAUNCHDARKLY_KEY: domainConfig.LAUNCHDARKLY_KEY,
    SERVER_NAME: servicesConfig.serverName,
    DATADOG_CLIENT_TOKEN: domainConfig.DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE: domainConfig.DATADOG_SERVICE,
    ENV: servicesConfig.env,
    DATADOG_CLIENT_TOKENRUM: domainConfig.DATADOG_CLIENT_TOKENRUM,
    DATADOG_APPLICATIONID: domainConfig.DATADOG_APPLICATIONID,
    AUTH_CLIENTID: domainConfig.AUTH_CLIENTID,
    AUTH_ORGANIZATIONID: domainConfig.AUTH_ORGANIZATIONID,
    AUTH_DOMAIN: domainConfig.AUTH_DOMAIN,
    AUTH_CACHE_LOCATION: domainConfig.AUTH_CACHE_LOCATION,
    AUTH_CONNECTION: domainConfig.AUTH_CONNECTION,
    TEST_ENVIRONMENT: servicesConfig.testEnvironment,
    SHIFTS_MARKETING: domainConfig.SHIFTS_MARKETING,
    FLEXWISE_URL: domainConfig.FLEXWISE_URL,
    POLARIS_URL: domainConfig.POLARIS_URL,
    DOCCAFE_URL: domainConfig.DOCCAFE_URL
};
