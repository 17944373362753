<loading-indicator *ngIf="isLoading"></loading-indicator>

<ayac-contact-toolbar [hasChanges]="hasChanges" [disableSaveAndCancel]="!hasChanges || !isFormValid"
    [qaId]="'ad_vendor_contact_detail'" (backClicked)="back()" (saveClicked)="save()" [showCancelButton]="false"
    [showCreateNovaButton]="true" [isProfileV1]="isProfileV1" (createNovaProfileClicked)="createNovaProfileClicked($event)"
    [disableV1Profile]="disableV1Profile">
</ayac-contact-toolbar>

<ng-container>
    <div class="panel panel-warning">
        <div class="panel-heading contact-header">
            Vendor Candidate: {{candidateProfileTabDetails?.candidate?.firstName}}
            {{candidateProfileTabDetails?.candidate?.middleName}}
            {{candidateProfileTabDetails?.candidate?.lastName}}
        </div>
    </div>
    <div class="section-container">
        <mat-card class="mat-elevation-z0">
            <mat-tab-group class="migrated-tab-label-active" mat-align-tabs="start" animationDuration="0ms">
                <mat-tab>
                    <ng-template mat-tab-label class="nav-label">
                        <mat-icon class="nav-label__icon">notifications</mat-icon>
                        Profile
                    </ng-template>
                    <ayac-admin-vendor-candidate-profile-tab *ngIf="!isNewCandidateLoading" [vendorId]="vendorId" [candidateId]="candidateId"
                        (onIsSavingChanged)="onIsSavingChanged($event)" [saveCalled]="saveCalled"
                        (onIsFormValidChanged)="onProfileIsFormValidChanged($event)"
                        (valueChanged)="onProfileFormValueChanged($event)" (onSaveSuccess)="onSaveSuccess($event)"
                        (candidateLoaded)="onCandidateLoaded($event)"
                        (onSaveError)="onSaveError($event)"></ayac-admin-vendor-candidate-profile-tab>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">notifications</mat-icon>
                        Files
                    </ng-template>
                    <ayac-vendor-candidates-detail-files *ngIf="!isNewCandidateLoading"
                    [candidate]="candidateId"></ayac-vendor-candidates-detail-files>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
</ng-container>
