<dialog-layout title='Shift Calendar'>

    <div mat-dialog-content>
        <ayac-shift-schedule-calendar
            [schedules]="schedules">
        </ayac-shift-schedule-calendar>
    </div>
    
    <div mat-dialog-actions>
        <button mat-stroked-button
            color="basic"
            (click)="onClose()">
                Close
        </button>
    </div>
</dialog-layout>