import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, Settings } from '../../config/settings';
import { Observable } from 'rxjs';
import { AyaLeadsUrls } from '../enums/aya-leads-url.enum';
import { AyaLeadsListResponse } from '../models/aya-leads-list.response';
import { AyaLeadsListQueryState } from 'src/app/root-store/aya-leads/reducers/aya-leads-list-query.reducer';
import { constructQueryParameters } from '../../core/utils/construct-query-parameters';
import { TypedAction } from '@ngrx/store/src/models';
import { AyaLeadsListActions } from 'src/app/root-store';
import { AyaLeadsApplicationStatus } from '../enums/aya-leads-application-status.enum';
import { AyaLeadApplicationInfoResponse } from 'src/app/aya-leads/models/aya-lead-application-Info.response';
import { interpolateUrl } from 'src/app/core/utils';
import { AyaLeadResumeDocumentInfo } from '../models/aya-lead-resume-document-info.model';
import { AyaLeadsJobDetailsResponse } from '../models/aya-leads-job-details.response';
import { ApplicationBaseResponse } from '../models/aya-lead-application-base.response';
import { JobsUrl } from 'src/app/jobs/models';
import { AyaLeadsJobApplicantsResponse } from '../models/aya-leads-job-applicants.response';
import { AyaLeadsStatisticsRequest } from '../models/aya-leads-statistics-request';
import { AyaLeadsApplicationStatusDetails } from '../models/aya-leads-application-status-details.model';

@Injectable({
    providedIn: 'root'
})
export class AyaLeadsService {
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {}

    getAyaLeads(query: AyaLeadsListQueryState): Observable<AyaLeadsListResponse> {
        const httpParameters: HttpParams = constructQueryParameters(query);

        return this.http.get<AyaLeadsListResponse>(`${this._settings.CORE}${AyaLeadsUrls.AyaLeadsList}`, {
            params: httpParameters
        });
    }

    export(
        query: AyaLeadsListQueryState,
        action: { submittalIds: number[] } & TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.Export>
    ): Observable<HttpResponse<Blob>> {
        const httpParameters: HttpParams = constructQueryParameters(query, false, (filters: any) => {
            return {
                ...filters,
                submittalIds: action.submittalIds
            };
        });

        return this.http.get(`${this._settings.CORE}${AyaLeadsUrls.AyaLeadsListExport}`, {
            observe: 'response',
            responseType: 'blob',
            params: httpParameters
        });
    }

    rejectAyaLeadApplicant(ayaLeadsApplicationId: number): Observable<Response> {
        return this.http.put<Response>(`${this._settings.CORE}${AyaLeadsUrls.AyaLeadsRejectApplicant}`, {
            ayaLeadsApplicationId
        });
    }

    updateAyaLeadApplicationStatus(
        ayaLeadApplicationId: number,
        newStatus: AyaLeadsApplicationStatus
    ): Observable<Response> {
        return this.http.put<Response>(`${this._settings.CORE}${AyaLeadsUrls.AyaLeadsUpdateApplicationStatus}`, {
            ayaLeadApplicationId,
            newStatus
        });
    }

    getAyaLeadApplicationInfo(ayaLeadApplicationId: number): Observable<AyaLeadApplicationInfoResponse> {
        const urlParams = {
            ayaLeadApplicationId
        };
        const url = interpolateUrl(AyaLeadsUrls.AyaLeadsApplicationDetails, urlParams);
        return this.http.get<AyaLeadApplicationInfoResponse>(`${this._settings.CORE}${url}`);
    }

    getAyaLeadResumeInfo(candidateId: number, facilityId: number): Observable<AyaLeadResumeDocumentInfo[]> {
        const urlParams = {
            candidateId,
            facilityId
        };
        const url = interpolateUrl(AyaLeadsUrls.AyaLeadsResumeInfo, urlParams);
        return this.http.get<AyaLeadResumeDocumentInfo[]>(`${this._settings.CORE}${url}`);
    }

    downloadAyaLeadResume(candidateId: number, facilityId: number): Observable<HttpResponse<Blob>> {
        const urlParams = {
            candidateId,
            facilityId
        };

        const url = interpolateUrl(AyaLeadsUrls.AyaLeadsResume, urlParams);
        return this.http.get(`${this._settings.CORE}${url}`, { responseType: 'blob', observe: 'response' });
    }

    previewAyaLeadResume(candidateId: number, facilityId: number): Observable<HttpResponse<Blob>> {
        const urlParams = {
            candidateId,
            facilityId
        };

        const url = interpolateUrl(AyaLeadsUrls.AyaLeadsResumePreview, urlParams);

        return this.http.get(`${this._settings.CORE}${url}`, {
            headers: { Accept: 'application/pdf' },
            responseType: 'blob',
            observe: 'response'
        });
    }

    public getJobDetails(jobId: number): Observable<AyaLeadsJobDetailsResponse> {
        const urlParams = {
            jobId
        };
        const url = interpolateUrl(AyaLeadsUrls.AyaLeadsJobDetails, urlParams);
        return this.http.get<AyaLeadsJobDetailsResponse>(`${this._settings.CORE}${url}`);
    }

    removeJob(jobId: number): Observable<ApplicationBaseResponse> {
        const requestBody = {
            id: jobId
        };

        return this.http.post<ApplicationBaseResponse>(`${this._settings.CORE}${JobsUrl.JOBS_DELETE}`, requestBody);
    }

    public getJobApplicants(jobId: number): Observable<AyaLeadsJobApplicantsResponse> {
        const urlParams = {
            jobId
        };
        const url = interpolateUrl(AyaLeadsUrls.AyaLeadsJobApplicants, urlParams);
        return this.http.get<AyaLeadsJobApplicantsResponse>(`${this._settings.CORE}${url}`);
    }

    recordAyaLeadsStatistics(request: AyaLeadsStatisticsRequest): Observable<Response> {
        const url = AyaLeadsUrls.RecordAyaLeadsStatistics;

        const params: AyaLeadsStatisticsRequest = {
            ayaLeadsApplicationId: request.ayaLeadsApplicationId,
            statisticType: request.statisticType
        };

        return this.http.post<Response>(`${this._settings.CORE}${url}`, params);
    }

    getAyaLeadApplicationStatuses(): Observable<AyaLeadsApplicationStatusDetails[]> {
        const url = AyaLeadsUrls.AyaLeadsApplicationStatuses;

        return this.http.get<AyaLeadsApplicationStatusDetails[]>(`${this._settings.CORE}${url}`);
    }
}
