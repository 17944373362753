<loading-indicator *ngIf="isLoading || showAddPositionLoadingSpinner"></loading-indicator>
<dialog-layout title='Offer Submittal'>
    <div mat-dialog-content id="increase-job-positions-dialog-content">
        <ng-container *ngIf="!isLoading">
            <div class="no-open-job-positions-warning" data-qa-id="no-open-job-positions-warning">
                <div>
                    <mat-icon>warning</mat-icon>
                </div>
                <div *ngIf="canAddJobPosition && hasOffersAvailableToRescind; else oneOptionOrNoOptionsMessage">
                    <a target="_blank" [routerLink]="['/client', 'jobs', submittalFromTheWizardDialog.jobId]" data-qa-id="increase-job-positions-job-link">{{submittalFromTheWizardDialog.title}}</a>
                    has no open positions available to receive an Offer. How would you like to proceed?
               </div>
               <ng-template #oneOptionOrNoOptionsMessage>
                    <div>
                        <a target="_blank" [routerLink]="['/client', 'jobs', submittalFromTheWizardDialog.jobId]" data-qa-id="increase-job-positions-job-link">{{submittalFromTheWizardDialog.title}}</a>
                        has no open positions available to receive an Offer.
                    </div>
               </ng-template>
            </div>

            <p class="no-open-job-positions-prompt" data-qa-id="no-open-job-positions-prompt" *ngIf="canAddJobPosition && hasOffersAvailableToRescind; else oneOptionPrompt">
                You can add a position in the job or rescind an existing offer to increase the number of open positions.
            </p>
            <ng-template #oneOptionPrompt>
                <p class="no-open-job-positions-prompt" data-qa-id="no-open-job-positions-prompt" *ngIf="canAddJobPosition; else rescindOptionPrompt">
                    Add 1 position to the job to make an open position available for an Offer.
                </p>
            </ng-template>
            <ng-template #rescindOptionPrompt>
                <p class="no-open-job-positions-prompt" data-qa-id="no-open-job-positions-prompt" *ngIf="hasOffersAvailableToRescind; else noOptionsPrompt">
                    You can rescind an existing offer or contact your manager to explore options to increase the number of open positions.
                </p>
            </ng-template>
            <ng-template #noOptionsPrompt>
                <p class="no-open-job-positions-prompt" data-qa-id="no-open-job-positions-prompt">
                    You can contact your manager to explore options to increase the number of open positions.
                </p>
            </ng-template>

            <form [formGroup]="form" data-qa-id="increase-job-positions-form" *ngIf="hasOffersAvailableToRescind">
                <mat-radio-group *ngIf="canAddJobPosition"
                    formControlName="increaseJobPositionsOption"
                    aria-label="Select an option to increase the number of open positions"
                    class="select-option-radio-group flex-col items-start"
                >
                    <mat-radio-button [value]="addJobPositionsOptions.AddOnePosition" data-qa-id="increase-job-positions-add-position-radio" >Add 1 position</mat-radio-button>
                    <mat-radio-button [value]="addJobPositionsOptions.RescindOnePosition" data-qa-id="increase-job-positions-rescind-radio" >Rescind existing offer to open 1 position</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="!canAddJobPosition">
                    <strong>Rescind existing offer to open 1 position</strong>
                </div>
                <ng-container *ngIf="!canAddJobPosition || (canAddJobPosition && isRescindOfferSelected)">
                    <div class="flex-col">
                        <div class="rescind-list-header flex-row gap-12 items-end">
                            <div class="rescind-list-radio-button"></div>
                            <div class="rescind-list-name"><strong>Name</strong></div>
                            <div class="rescind-list-date"><strong>Start Date</strong></div>
                            <div class="rescind-list-date"><strong>End Date</strong></div>
                        </div>
                        <mat-radio-group formControlName="offerId" class="rescind-list-radio-group flex-col">
                            <ng-container *ngFor="let offer of rescindOfferList">
                                <div class="rescind-list-row flex-col justify-center">
                                    <mat-radio-button [attr.data-qa-id]="'increase-job-positions-rescind-offer-radio-' + offer.offerId" [value]="offer.offerId"
                                        class="rescind-list-radio-button">
                                        <div class="flex-row gap-12 items-center rescind-list-radio-label-content">
                                            <div class="rescind-list-name">
                                                    <a [routerLink]="['/client', 'submittals', offer.submittalId, 'offer']"
                                                        [attr.data-qa-id]="'increase-job-positions-rescind-offer-link-' + offer.offerId"
                                                        target="_blank">
                                                    {{ offer.firstName }} {{ offer.lastName }}
                                                </a>
                                            </div>
                                            <div [attr.data-qa-id]="'increase-job-positions-rescind-offer-start-' + offer.offerId"
                                                class="rescind-list-date">
                                                {{ offer.startDate | date: 'MM/dd/yy' }}
                                            </div>
                                            <div [attr.data-qa-id]="'increase-job-positions-rescind-offer-end-' + offer.offerId"
                                                class="rescind-list-date">
                                                {{ offer.endDate | date: 'MM/dd/yy' }}
                                            </div>
                                        </div>
                                    </mat-radio-button>
                                </div>
                            </ng-container>
                        </mat-radio-group>
                        <ng-container *ngIf="displayRescindWarning">
                            <div class="rescind-warning-container flex-row items-center">
                                <div>
                                    <mat-icon>error_outline</mat-icon>
                                </div>
                                <div>Once the rescind is finalized, it cannot be undone and you will be able to begin the Offer Submittal.</div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </form>
        </ng-container>
    </div>

    <div mat-dialog-actions>
        <ng-container *ngIf="!isLoading && (canAddJobPosition || hasOffersAvailableToRescind)">
            <button mat-flat-button
                color="accent"
                [disabled]="form?.invalid || showAddPositionLoadingSpinner"
                (click)="proceed()"
                data-qa-id="increase-job-positions-proceed-btn"
            >
                Proceed
            </button>
        </ng-container>
        <button mat-stroked-button
            color="basic"
            [disabled]="isLoading || showAddPositionLoadingSpinner"
            (click)="close()"
            data-qa-id="increase-job-positions-cancel-btn"
        >
            Cancel
        </button>
    </div>
</dialog-layout>
