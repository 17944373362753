<form [formGroup]="childOptionsForm" class="dropdown-wrapper">
    <mat-form-field appearance="fill">
        <mat-label>Parent Dropdown</mat-label>
        <mat-select formControlName="parentDropdown" (selectionChange)="handleParentDropdownChange($event)">
          <mat-option *ngFor="let parentDropdown of filteredParentDropdowns()" [value]="parentDropdown.parentDropdownDefinitionId">
            {{parentDropdown.parentDropdownName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Parent Dropdown Option</mat-label>
        <mat-select formControlName="parentDropdownOption">
          <mat-option *ngFor="let parentDropdownOption of parentDropdownOptions" [value]="parentDropdownOption">
            {{parentDropdownOption}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <div class="option-input-section">
        <mat-form-field>
            <mat-label>Option Value</mat-label>
            <input matInput formControlName="optionValue" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Option Description</mat-label>
          <input matInput formControlName="optionDescription" />
      </mat-form-field>
        <button mat-stroked-button (click)="add()" type="button" 
        [disabled]="!this.childOptionsForm.get('optionValue').value ||
        !this.childOptionsForm.get('parentDropdownOption').value ||
        !this.childOptionsForm.get('parentDropdown').value">
            <mat-icon>add</mat-icon>&nbsp;Add
        </button>
    </div>
</form>
<div class="options-list">
    <table aria-hidden="true">
        <thead>
          <tr>
            <th>Parent Option</th>
            <th>Child Option</th>
            <th>Child Option Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let option of parentChildOptions">
            <td>
                {{option.parentOption}}
            </td>
            <td>
              <button mat-stroked-button (click)="delete(option)" color="warn" type="button">
                  <mat-icon>clear</mat-icon>&nbsp;&nbsp;
                  <span>{{
                    option.childOption }}
                  </span>
              </button>
            </td>
            <td> 
              <button *ngIf="option.childOptionDescription" mat-stroked-button (click)="delete(option)" color="warn" type="button">
                    <mat-icon>clear</mat-icon>&nbsp;&nbsp;
                  <span>{{
                    option.childOptionDescription }}
                  </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
</div>