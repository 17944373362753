import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, dashboardStoreKey } from '../dashboard.state';

export const selectWorkers = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => state.workers.workers
);

export const selectStartingWithinDays = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => state.workers.startingWithinDays
);

export const selectEndingWithinDays = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => state.workers.endingWithinDays
);

export const selectWorkersChart = createSelector(createFeatureSelector<DashboardState>(dashboardStoreKey), (state) => {
    const workers = state.workers.workers;
    const totalCount = workers.currentlyWorking > 0 ? workers.currentlyWorking - workers.endingCount : 1;
    return [
        { label: 'Ending', count: workers.endingCount, color: '#f3be42' },
        { label: 'Currently Working', count: totalCount, color: '#54565A' }
    ];
});

export const selectEndingPercentage = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => {
        const workers = state.workers.workers;
        return Math.round((workers.endingCount / workers.currentlyWorking) * 100);
    }
);
