import { createAction, props } from '@ngrx/store';

export const loadAwaitingClearanceSuccess = createAction(
    '[Dashboard Api] Load Awaiting Clearance Success',
    props<{ awaitingClearanceCount: number }>()
);
export const loadAwaitingClearanceFail = createAction(
    '[Dashboard Api] Load Awaiting Clearance Fail',
    props<{ error: any }>()
);
