import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TravelersExtensionService } from 'src/app/travelers/services/travelers-extension.service';
import * as extensionActions from 'src/app/travelers/state/actions/travelers-extension.actions';
import { ToasterService } from 'src/app/core/services';

@Injectable()
export class TravelersExtensionEffect {
    getLogo$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(extensionActions.loadExtensionLogo),
            switchMap((action) => {
                let fileName;
                if (action.environment === 'AYA') {
                    fileName = 'aya-logo.png';
                } else {
                    fileName = 'lotusconnect-logo.png';
                }
                return this._service.getLogo(fileName).pipe(
                    map((response) => extensionActions.loadExtensionLogoSuccess({ logo: response })),
                    catchError((error) => of(extensionActions.loadExtensionLogoFail({ error })))
                );
            })
        );
    });

    completeExtensionRequest$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(extensionActions.completeExtensionRequest),
            switchMap((action) =>
                this._service.completeRequest(action.travelerId, action.token).pipe(
                    map(() => extensionActions.completeExtensionRequestSuccess({ travelerId: action.travelerId })),
                    catchError((error) => of(extensionActions.completeExtensionRequestFail({ error })))
                )
            )
        );
    });

    getExtensionDetails$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(
                extensionActions.loadExtensionDetails,
                extensionActions.completeExtensionRequestSuccess,
                extensionActions.postponeExtensionReminderSuccess
            ),
            switchMap((action) =>
                this._service.getExtensionDetails(action.travelerId).pipe(
                    map((response) => extensionActions.loadExtensionDetailsSuccess({ extension: response })),
                    catchError((error) => of(extensionActions.loadExtensionDetailsFail({ error })))
                )
            )
        );
    });

    extensionError$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(
                    extensionActions.loadExtensionDetailsFail,
                    extensionActions.completeExtensionRequestFail,
                    extensionActions.requestExtensionFail
                ),
                map((action) => {
                    const error = action.error;
                    return this._toasterService.fail('ERROR:', `${error.status} ${error.error}`);
                })
            );
        },
        { dispatch: false }
    );

    postponeExtensionReminder$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(extensionActions.postponeExtensionReminder),
            switchMap((action) =>
                this._service.postponeExtensionReminder(action.travelerId, action.email).pipe(
                    map((postponeResult) =>
                        extensionActions.postponeExtensionReminderSuccess({
                            travelerId: action.travelerId,
                            postponeResult
                        })
                    ),
                    catchError((error) => of(extensionActions.postponeExtensionReminderFail({ error })))
                )
            )
        );
    });

    postponeExtensionReminderFail$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(extensionActions.postponeExtensionReminderFail),
                map(() => this._toasterService.fail('Failed to update reminder configuration'))
            );
        },
        { dispatch: false }
    );

    requestExtension$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(extensionActions.requestExtension),
            switchMap((action) => {
                const travelerId = action.id;
                return this._service.requestExtensionToAM(travelerId).pipe(
                    map(() => extensionActions.requestExtensionSuccess({ id: travelerId })),
                    catchError((error) => of(extensionActions.requestExtensionFail({ error })))
                );
            })
        );
    });

    requestExtensionSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(extensionActions.requestExtensionSuccess),
                map(() => this._toasterService.success('Extension Request sent successfully!'))
            );
        },
        { dispatch: false }
    );

    requestExtensionFail$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(extensionActions.requestExtensionFail),
                map(() => this._toasterService.fail('Error sending Extension Notification to Account Manager'))
            );
        },
        { dispatch: false }
    );

    createExtension$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(extensionActions.createExtension),
            switchMap((action) =>
                this._service
                    .createExtension(
                        action.travelerId,
                        action.duration,
                        action.startDate,
                        action.endDate,
                        action.jobTitle,
                        action.billingRate,
                        action.additionalExpectations,
                        action.extensionDetails
                    )
                    .pipe(
                        map((response) => extensionActions.createExtensionSuccess({ jobId: response.jobId })),
                        catchError((error) => of(extensionActions.createExtensionFail({ error })))
                    )
            )
        );
    });

    createExtensionSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(extensionActions.createExtensionSuccess),
                map(() => this._toasterService.success('Job Extension successfully created!'))
            );
        },
        { dispatch: false }
    );

    createExtensionFail$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(extensionActions.createExtensionFail),
                map(() => this._toasterService.fail('Error Creating Job Extension'))
            );
        },
        { dispatch: false }
    );

    createClientExtensionOffer$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(extensionActions.createClientExtensionOffer),
            switchMap((action) =>
                this._service.createVMSExtension(action.extension).pipe(
                    map((response) =>
                        extensionActions.createClientExtensionOfferSuccess({
                            clientSubmittalId: response.clientSubmittalId
                        })
                    ),
                    catchError((error) => of(extensionActions.createClientExtensionOfferFail({ error })))
                )
            )
        );
    });

    createClientExtensionOfferSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(extensionActions.createClientExtensionOfferSuccess),
                map(() => this._toasterService.success('Job Extension Offer successfully created!'))
            );
        },
        { dispatch: false }
    );

    createClientExtensionOfferFail$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(extensionActions.createClientExtensionOfferFail),
                map(() => this._toasterService.fail('Error Creating Job Extension Offer'))
            );
        },
        { dispatch: false }
    );

    getExtensionOfferFacilitySettings$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(extensionActions.getExtensionOfferFacilitySettings),
            exhaustMap((action) =>
                this._service.getExtensionOfferFacilitySettings(action.contractId).pipe(
                    map((settings) => extensionActions.getExtensionOfferFacilitySettingsSuccess({ settings })),
                    catchError((error) => of(extensionActions.getExtensionOfferFacilitySettingsFailure({ error })))
                )
            )
        );
    });

    getExtensionOfferFacilitySettingsFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(extensionActions.getExtensionOfferFacilitySettingsFailure),
                map(() => this._toasterService.fail('Error getting Extension Offer Facility Settings'))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _service: TravelersExtensionService,
        private readonly _toasterService: ToasterService
    ) {}
}
