import { Injectable } from '@angular/core';
import { IconSettingsService, SVGIcon } from '@progress/kendo-angular-icons';
import { chevronDownIcon, chevronUpIcon } from '@progress/kendo-svg-icons';

// See here for how to replace the default icons for master/detail rows in Kendo
// Right now it is targeting Kendo's default icons for the master/detail rows but it can be expanded to include any other icons
// https://www.telerik.com/kendo-angular-ui/components/icons/icon-settings#svg-icons
// https://stackblitz.com/edit/angular-d1edwj?file=src%2Fapp%2Fcategory-details.component.ts,src%2Fapp%2Fmy-icon.service.ts,src%2Fapp%2Fapp.component.ts
@Injectable()
export class LotusOneIconsService extends IconSettingsService {
    private readonly svgDictionary: Record<string, SVGIcon> = {
        minus: chevronUpIcon,
        plus: chevronDownIcon
    };

    getSvgIcon(svgIconName: string): SVGIcon {
        return this.svgDictionary[svgIconName] || super.getSvgIcon(svgIconName);
    }
}
