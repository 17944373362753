import { openLink } from './open-link';

export function openMail(
    recipients: string[] | string,
    options: {
        subject: string;
        body: string;
        cc?: string[] | string;
        bcc?: string[] | string;
    }
) {
    recipients = Array.isArray(recipients) ? recipients : [recipients];

    const address = recipients.join(';');
    const query = Object.keys(options)
        .map((k) => `${k}=${options[k]}`)
        .join('&');

    const link = `mailto:${address}?${query}`;

    openLink(link);
}
