<div class="kendo-grid-lookalike-multiselect"
    [class.kendo-grid-lookalike-multiselect-searchable]="search"
    [class.kendo-grid-lookalike-multiselect-clear]="clearButton">
    <mat-form-field appearance="outline" [style.width]="width + 'px'" class="filter-dropdown-padding">
        <mat-select #select
            (selectionChange)="selectItem($event)"
            [required]="required"
            [placeholder]="defaultText"
            [disableRipple]="true"
            [disableOptionCentering]="true"
            [panelClass]="panelClass"
            panelWidth="auto"
            [formControl]="selectedValuesControl"
            (openedChange)="panelStateChange($event)"
            multiple>

            <mat-select-trigger>
                <span *ngIf="selectedLength === data.length">
                    {{selectAll | replace : "#" : (selectedLength - 1)}}
                </span>
                <span *ngIf="selectedLength === 1">
                    {{firstSelectedItem?.name}}
                </span>
                <span *ngIf="selectedLength < data.length && selectedLength > 1">
                    {{firstSelectedItem?.name.length > charLimit ? (firstSelectedItem?.name | slice:0:charLimit) + '...' : firstSelectedItem?.name}}
                </span>
                <span *ngIf="selectedLength > 1 && selectedLength !== data.length">
                    (+{{selectedLength - 1}} more)
                </span>
                <mat-icon 
                    *ngIf="clearButton"
                    class="clear-btn"
                    (click)="$event.stopPropagation(); onClear()">
                    close
                </mat-icon>
            </mat-select-trigger>

            <div *ngIf="search">
                <div class="search-field mx-2 my-2">
                    <mat-icon>search</mat-icon>
                    <input class="k-input k-input-solid k-input-md" [formControl]="searchInput" placeholder="Search" (keydown)="$event.stopPropagation()" >
                </div>
            </div>

            <div class="checkbox-wrapper" *ngIf="selectAll">
                <mat-checkbox #selectAllCheckbox class="select-all-checkbox" [checked]="selectedAll" [indeterminate]="showIndeterminate" (change)="toggleSelectAll($event.checked)" [disableRipple]="true">Select all</mat-checkbox>
            </div>

            <ng-container *ngIf="search || selectAll">
                <mat-divider></mat-divider>
            </ng-container>
            
            <ng-container *ngIf="filteredData$ | async as filteredData">
                <mat-option *ngFor="let item of filteredData" [value]="item.data[valueField]" [class.hidden]="!item.isVisible">
                    <span [innerHTML]="item.data[textField] | highlight: searchInput.value | sanitizer"></span>
                </mat-option>    
            </ng-container>

            <ng-container *ngIf="filteredDataEmpty$ | async">
                <div class="d-flex justify-content-center my-4 mx-1">
                    <span>No Data Found</span>
                </div>
            </ng-container>            

        </mat-select>
        <kendo-svg-icon class="k-button-icon" matSuffix [icon]="caretDown"></kendo-svg-icon>
    </mat-form-field>
</div>
