<div class="pl-3 py-2"
    [formGroup]="form">
    <div>
        <h4>
            <strong>{{categoryName}}</strong>
        </h4>
    </div>

    <ng-container 
        [ngTemplateOutlet]="featuresView" 
        [ngTemplateOutletContext]="{
            features: features
        }">
    </ng-container>
</div>

<ng-template #featuresView let-features="features">
    <ng-container *ngFor="let feature of features; let last = last;">
        <div [ngClass]="{'mb-3': !last}" fxLayout="row">
            <div class="mr-2">
                <mat-checkbox 
                    [attr.data-qa-id]="generateQaAttribute(feature.name,'Checkbox')"
                    [disabled] = "feature.isModuleDisabled" 
                    [id]="featureNameEnabled(feature.name)"
                    [formControl]="form.get(featureNameEnabled(feature.name))"
                ></mat-checkbox>
            </div>
            <div>
                <ng-container 
                    [ngTemplateOutlet]="featureControlView" 
                    [ngTemplateOutletContext]="{
                        feature: feature,
                        control: form.get(feature.name)
                    }">
                </ng-container>
                

                <ng-container *ngIf="feature.childFeatures && feature.childFeatures.length > 0">
                    <div class="ml-3 mt-3">
                        <ng-container 
                            [ngTemplateOutlet]="featuresView" 
                            [ngTemplateOutletContext]="{
                                features: feature.childFeatures
                            }">
                        </ng-container>
                    </div>
                </ng-container>
                
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #featureControlView let-feature="feature" let-control="control">
    <ng-container [ngSwitch]="feature.valueTypeId">
        <ng-container *ngSwitchCase="FeatureInputTypes.DECIMAL">
            <label [attr.for]="featureNameLabel(feature.name)">
                {{feature.label}}
            </label>
            <div>
                <kendo-numerictextbox [formControl]="control">
                </kendo-numerictextbox>
                <div class="input-hint">{{feature | featureSettingHint}}</div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="FeatureInputTypes.INTEGER">
            <label [attr.for]="featureNameLabel(feature.name)">
                {{feature.label}}
            </label>
            <div>
                <kendo-numerictextbox [formControl]="control" 
                    [min]="0"
                    decimals="0"
                    format="n0">
                </kendo-numerictextbox>                 
                    <validation-message useConstantCustomMessage="true" 
                                        customMessage="Please enter a valid time" 
                                        [control]="control">
                    </validation-message>                  
                 <div class="input-hint">{{feature | featureSettingHint}}</div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="FeatureInputTypes.BOOLEAN">
            <label [attr.for]="featureNameLabel(feature.name)">
                {{feature.label}}
            </label>
            <div>
                <kendo-dropdownlist
                    [data]="[{text: 'Yes', value: true},
                    {text: 'No', value: false}]"
                    textField="text"
                    valueField="value"
                    [valuePrimitive]="true"
                    [formControl]="control">
                </kendo-dropdownlist>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="FeatureInputTypes.DATETIME">
            <label [attr.for]="featureNameLabel(feature.name)">
                {{feature.label}}
            </label>
            <div>
                <kendo-datepicker [formControl]="control"
                    [min]="feature.min"
                    [max]="feature.max">
                </kendo-datepicker>
                <div class="input-hint">{{feature | featureSettingHint}}</div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="FeatureInputTypes.TOGGLE">
            <label [attr.for]="featureNameLabel(feature.name)">
                {{feature.label}}
            </label>
            
        </ng-container>
        <ng-container *ngSwitchCase="FeatureInputTypes.STRING">
            <label [attr.for]="featureNameLabel(feature.name)">
                {{feature.label}}
            </label>
            <div>
              <label>{{feature.value}}</label>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <label [attr.for]="featureNameLabel(feature.name)">
                {{feature.label}}
            </label>
            <div>
                <input kendoTextBox
                    [formControl]="control" />
            </div>
        </ng-container>
    </ng-container>
</ng-template>