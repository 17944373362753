import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemSettingsComponent } from './components/system-settings/system-settings.component';
import { SystemsStoreModule } from 'src/app/systems/systems-store.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FeatureSettingsModule } from 'src/app/shared/feature-settings';

@NgModule({
    declarations: [SystemSettingsComponent],
    exports: [SystemSettingsComponent],
    imports: [CommonModule, SharedModule, SystemsStoreModule, FeatureSettingsModule]
})
export class SystemsModule {}
