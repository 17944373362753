<loading-indicator *ngIf="isLoading$ | async">
</loading-indicator>

<ayac-widget>
    <div widget-breadcrumb>
        <ayac-breadcrumb>
            <a routerLink="/client/submittals">All Submittals</a>
            <span>&nbsp;>&nbsp;</span>
            <span>Submittal Details</span>
        </ayac-breadcrumb>
    </div>

    <div widget-header>
        <ayac-portal-page-header [title]="title$ | async" [badge]="statusBadge$ | async" [isOneRow]="true">
            <ng-template #pageHeaderToolbar>
                <div class="spacer"></div>

                <mat-spinner *ngIf="isStatusLoading$ | async"
                    color="accent"
                    diameter="20"
                    style="align-self: center;">
                </mat-spinner>

                <ng-container *ngIf="profile$ | async as profile">
                    <button mat-stroked-button [matMenuTriggerFor]="menu"
                        color="accent"
                        [disabled]="isStatusLoading$ | async"
                        >
                        <mat-icon class="material-icons-outlined">forward</mat-icon>
                        <span>Update Status</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                            *ngFor="let status of statusLookupsMapped$ | async"
                            [disabled]="profile.statusId === status.id"
                            (click)="updateCandidateStatus(status.id)">
                            {{status.name}}
                        </button>
                    </mat-menu>
                </ng-container>

                <ng-container *ngIf="canBookCandidate && profile$ | async as profile">
                    <button
                        mat-flat-button
                        color="accent"
                        [disabled]="(isStatusLoading$ | async) || profile.isBooked"
                        (click)="onShowBookCandidates(profile)">
                        <mat-icon>thumb_up_off_alt</mat-icon>
                        Book
                    </button>
                </ng-container>

                <button
                    mat-flat-button
                    class="btn-offer"
                    (click)="offerCandidate()"
                    *ngIf="canShowOffer$ | async"
                    [disabled]='isStatusLoading$ | async'>
                        <mat-icon>flash_on</mat-icon>
                        Offer
                </button>

                <ng-container *ifLdFeature="featureFlag.ConnectClientDisplayNewSubmittalStatus; enabled: false">
                    <button
                        mat-flat-button
                        class="btn-decline"
                        (click)="declineCandidate()"
                        *ngIf="canShowDecline$ | async"
                        [disabled]="isStatusLoading$ | async">
                            <mat-icon>thumb_down_off_alt</mat-icon>
                            Decline
                    </button>
                </ng-container>

                <ng-container *ifLdFeature="featureFlag.ConnectClientDisplayNewSubmittalStatus">
                    <button
                        mat-flat-button
                        class="btn-decline"
                        (click)="declineCandidateV2()"
                        *ngIf="canShowDecline$ | async"
                        [disabled]="isStatusLoading$ | async">
                            <mat-icon>thumb_down_off_alt</mat-icon>
                            Decline
                    </button>
                </ng-container>
            </ng-template>
        </ayac-portal-page-header>
    </div>

    <mat-card class="mb-4" [skeleton-overlay]="isLoading$ | async">
        <mat-card-title>
            Candidate Profile
            <span class="label-badge bardge-circle ml-3">Internal Pool</span>
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>

            <div class="k-form-inline" *ngIf="profile$ | async as profile">

                <div class="k-form-field" *ngIf="profile.candidateName">
                    <span>Name</span>
                    <span>{{profile.candidateName}}</span>
                </div>
                <div class="k-form-field" *ngIf="profile.submittalDate">
                    <span>Available Start Date</span>
                    <span>{{profile.submittalDate | date : 'MM/dd/yyyy' : 'UTC' }}</span>
                </div>
                <div class="k-form-field" *ngIf="profile.profession">
                    <span>Profession</span>
                    <span>{{profile.profession}}</span>
                </div>
                <div class="k-form-field" *ngIf="profile.candidateSpecialties?.length || profile.specialty">
                    <span>Specialty</span>
                    <span>{{profile.candidateSpecialties?.length ? profile.candidateSpecialties : profile.specialty}}</span>
                </div>
                <div class="k-form-field" *ngIf="profile.payRate">
                    <span>Rate</span>
                    <span>${{profile.payRate}}</span>
                </div>
                <div class="k-form-field" *ngIf="profile.phone">
                    <span>Phone #</span>
                    <span>{{profile.phone}}</span>
                </div>
                <div class="k-form-field" *ngIf="profile.email">
                    <span>Email</span>
                    <a href="mailto:{{profile.email}}">{{profile.email}}</a>
                </div>
            </div>
        </mat-card-content>
    </mat-card>


    <div class="my-3" *ngIf="profile$ | async as profile">
        <button mat-flat-button color="accent" (click)="onViewApplication(candidateId, profile.candidateName);">
            <mat-icon fontSet="fas" fontIcon="fa-file"></mat-icon> View Application
        </button>
    </div>

    <ng-container *ngIf="(jobs$ | async) as jobs">
        <mat-card class="mb-4" *ngIf="jobs.length > 0" [skeleton-overlay]="isLoading$ | async">
            <mat-card-title>Jobs Applied</mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
                <table mat-table [dataSource]="jobs" class="simple-table" role="presentation">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef scope="col" style="width: 5%;"> ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="facility">
                        <th mat-header-cell *matHeaderCellDef scope="col" style="width: 15%;"> Facility </th>
                        <td mat-cell *matCellDef="let element"> {{element.facility}} </td>
                    </ng-container>

                    <ng-container matColumnDef="profession">
                        <th mat-header-cell *matHeaderCellDef scope="col" style="width: 15%;"> Profession </th>
                        <td mat-cell *matCellDef="let element"> {{element.profession}} </td>
                    </ng-container>

                    <ng-container matColumnDef="expertise">
                        <th mat-header-cell *matHeaderCellDef scope="col" style="width: 15%;"> Specialty </th>
                        <td mat-cell *matCellDef="let element"> {{element.expertise}} </td>
                    </ng-container>

                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef scope="col" style="width: 10%;"> Start Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.startDate | date : 'MM/dd/yyyy' : 'UTC'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="shiftText">
                        <th mat-header-cell *matHeaderCellDef scope="col" style="width: 10%;"> Shift </th>
                        <td mat-cell *matCellDef="let element"> {{element.shiftText | titlecase}}
                            {{element.shiftStartTime | date:'h:mma'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef scope="col"> Notes from AM </th>
                        <td mat-cell *matCellDef="let element"> {{element.note}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="jobsDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: jobsDisplayedColumns;"></tr>
                </table>
            </mat-card-content>
        </mat-card>
    </ng-container>

</ayac-widget>
