import { Action, createReducer, on } from '@ngrx/store';
import { loadPendingJobs, loadPendingJobsSuccess, loadPendingJobsFail } from '../actions';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';

export interface PendingJobsState {
    totalCount: number;
    agingCount: number;
    agingDays: number;
    shiftTypeId: number;
    loading: LoadingTypes;
    error: string;
}

export const initialPendingJobsState: PendingJobsState = {
    totalCount: 0,
    agingCount: 0,
    agingDays: 7,
    shiftTypeId: 0,
    loading: LoadingTypes.INIT,
    error: ''
};

export function pendingJobsReducer(state: PendingJobsState, action: Action) {
    return createReducer(
        initialPendingJobsState,
        on(loadPendingJobs, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(loadPendingJobsSuccess, (state, action) => ({
            ...state,
            totalCount: action.totalCount,
            agingCount: action.agingCount,
            loading: LoadingTypes.LOADED
        })),

        on(loadPendingJobsFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: PendingJobsState | undefined, action: Action) {
    return pendingJobsReducer(state, action);
}
