import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';
import * as StaffingAdvisoryActions from 'src/app/travelers/state/actions/staffing-advisory.actions';

export interface StaffingAdvisoryLookupsState {
    restrictionLevels: ListItem[];
    categories: ListItem[];
    reasons: ListItem[];
    error: string;
    loading: LoadingTypes;
}

export const initialState: StaffingAdvisoryLookupsState = {
    restrictionLevels: [],
    categories: [],
    reasons: [],
    error: '',
    loading: LoadingTypes.INIT
};

export function staffingAdvisoryLookupsReducer(state: StaffingAdvisoryLookupsState, action: Action) {
    return createReducer(
        initialState,
        on(StaffingAdvisoryActions.loadStaffingAdvisoryLookups, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(StaffingAdvisoryActions.loadStaffingAdvisoryLookupsSuccess, (state, action) => ({
            ...state,
            restrictionLevels: action.restrictionLevels,
            categories: action.categories,
            reasons: action.reasons,
            loading: LoadingTypes.LOADED
        })),

        on(StaffingAdvisoryActions.loadStaffingAdvisoryLookupsFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        })),

        on(StaffingAdvisoryActions.clearStaffingAdvisory, (state, action) => ({
            ...initialState
        }))
    )(state, action);
}

export function reducer(state: StaffingAdvisoryLookupsState | undefined, action: Action) {
    return staffingAdvisoryLookupsReducer(state, action);
}
