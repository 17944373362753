import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { FileAttachment, FileValidationResult } from 'src/app/shared/models/attachment';
import {
    clearStaffingAdvisory,
    clearStaffingAdvisoryAttachments,
    deleteStaffingAdvisoryAttachment,
    deleteStaffingAdvisoryAttachmentFail,
    deleteStaffingAdvisoryAttachmentSuccess,
    loadStaffingAdvisoryAttachment,
    loadStaffingAdvisoryAttachmentFail,
    loadStaffingAdvisoryAttachments,
    loadStaffingAdvisoryAttachmentsFail,
    loadStaffingAdvisoryAttachmentsSuccess,
    loadStaffingAdvisoryAttachmentSuccess,
    saveStaffingAdvisoryAttachment,
    saveStaffingAdvisoryAttachmentFail,
    saveStaffingAdvisoryAttachments,
    saveStaffingAdvisoryAttachmentsFail,
    saveStaffingAdvisoryAttachmentsSuccess,
    saveStaffingAdvisoryAttachmentSuccess
} from 'src/app/travelers/state/actions/staffing-advisory.actions';

export interface StaffingAdvisoryAttachmentsState {
    attachments: FileAttachment[];
    fileValidationResults: FileValidationResult;
    loading: LoadingTypes;
}

export const initialState: StaffingAdvisoryAttachmentsState = {
    attachments: null,
    fileValidationResults: null,
    loading: LoadingTypes.INIT
};

export function staffingAdvisoryAttachmentsReducer(state: StaffingAdvisoryAttachmentsState, action: Action) {
    return createReducer(
        initialState,
        on(loadStaffingAdvisoryAttachments, (state) => ({ ...state, ...initialState, loading: LoadingTypes.LOADING })),
        on(loadStaffingAdvisoryAttachmentsSuccess, (state, action) => ({
            ...state,
            attachments: action.staffingAdvisoryAttachments,
            loading: LoadingTypes.LOADED
        })),
        on(loadStaffingAdvisoryAttachmentsFail, (state) => ({ ...state, loading: LoadingTypes.FAIL })),
        on(saveStaffingAdvisoryAttachment, saveStaffingAdvisoryAttachments, (state) => ({
            ...state,
            fileValidationResults: state.fileValidationResults,
            loading: LoadingTypes.LOADING
        })),
        on(saveStaffingAdvisoryAttachmentSuccess, (state) => ({
            ...state,
            fileValidationResults: state.fileValidationResults,
            loading: LoadingTypes.LOADED
        })),
        on(saveStaffingAdvisoryAttachmentFail, (state, action) => ({
            ...state,
            fileValidationResults: action.fileValidationResult,
            loading: LoadingTypes.FAIL
        })),
        on(saveStaffingAdvisoryAttachmentsSuccess, (state) => ({
            ...state,
            fileValidationResults: state.fileValidationResults,
            loading: LoadingTypes.LOADED
        })),
        on(saveStaffingAdvisoryAttachmentsFail, (state) => ({ ...state, loading: LoadingTypes.FAIL })),
        on(loadStaffingAdvisoryAttachment, (state) => ({ ...state, loading: LoadingTypes.LOADING })),
        on(loadStaffingAdvisoryAttachmentSuccess, (state) => ({ ...state, loading: LoadingTypes.LOADED })),
        on(loadStaffingAdvisoryAttachmentFail, (state) => ({ ...state, loading: LoadingTypes.FAIL })),
        on(deleteStaffingAdvisoryAttachment, (state) => ({ ...state, loading: LoadingTypes.LOADING })),
        on(deleteStaffingAdvisoryAttachmentSuccess, (state) => ({ ...state, loading: LoadingTypes.LOADED })),
        on(deleteStaffingAdvisoryAttachmentFail, (state) => ({ ...state, loading: LoadingTypes.FAIL })),
        on(clearStaffingAdvisory, clearStaffingAdvisoryAttachments, () => ({ ...initialState }))
    )(state, action);
}

export function reducer(state: StaffingAdvisoryAttachmentsState | undefined, action: Action) {
    return staffingAdvisoryAttachmentsReducer(state, action);
}
