import { FacilityAssociation } from 'src/app/facilities/models/facility-association.model';
import { createAction, props } from '@ngrx/store';

export const loadFacilityAssociation = createAction(
    '[FACILITIES COMPONENT] Load Facility Association',
    props<{ id: number }>()
);
export const loadFacilityAssociationSuccess = createAction(
    '[FACILITIES API] Load Facility Association Success',
    props<{ association: FacilityAssociation }>()
);
export const loadFacilityAssociationFailure = createAction(
    '[FACILITIES API] Load Facility Association Failure',
    props<{ error: any }>()
);
