import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromCore from 'src/app/core/state/selectors';
import * as coreActions from './core.actions';
import * as coreReducers from './reducers/core.reducer';
import { filter, take } from 'rxjs/operators';
import { loadBranding, loadClientSharedData } from './actions';
import { Observable } from 'rxjs';
import { ClientSharedData } from 'src/app/shared/models/client-shared-data.model';

@Injectable({
    providedIn: 'root'
})
export class CoreStoreFacade {
    isAuthenticated$ = this.store.pipe(select(fromCore.selectIsAuthenticated));
    isLoading$ = this.store.pipe(select(fromCore.isLoading));
    error$ = this.store.pipe(select(fromCore.selectError));
    menu$ = this.store.pipe(select(fromCore.getMenu));
    featuredLogo$ = this.store.pipe(select(fromCore.getFeaturedLogo));

    constructor(private store: Store<coreReducers.CoreState>) {}
    logout() {
        this.store.dispatch(new coreActions.Logout());
    }

    checkLogin() {
        this.store.dispatch(new coreActions.LoginCheck());
    }

    loadFeaturedLogoWithSystemId() {
        this.store.dispatch(new coreActions.LoadFeaturedLogoWithSystemId());
        this.store.dispatch(loadBranding());
    }
    loadMenu() {
        this.store.dispatch(new coreActions.LoadMenu());
    }

    tryLoadMenu() {
        this.menu$.pipe(take(1)).subscribe((menu) => {
            if (menu && menu.length) {
                return;
            }
            this.loadMenu();
        });
    }

    resetPassword(currentPassword: string, newPassword: string) {
        this.store.dispatch(new coreActions.ResetPassword(currentPassword, newPassword));
    }

    forgotPassword(username: string, brand: string, dialogId?: string) {
        this.store.dispatch(new coreActions.ForgotPassword(username, brand, dialogId));
    }

    loadSystemFields() {
        this.store.dispatch(new coreActions.LoadSystemFields());
    }

    loadClientSharedData() {
        this.store.dispatch(loadClientSharedData());
    }

    getClientSharedData(): Observable<ClientSharedData> {
        return this.store.select(fromCore.selectClientSharedData).pipe(
            filter((data) => data !== null && data !== undefined),
            take(1)
        );
    }
}
