export enum ShiftsClientPermissions {
    ShiftEdit = 'perdiemscheduler_Edit',
    ShiftAdd = 'perdiemscheduler_Add',
    ShiftAddBulk = 'perdiemscheduler_Bulk',
    ShiftDetails = 'perdiemscheduler_Detail',
    AttachmentsView = 'shifts_attachments_view',
    AttachmentsEdit = 'shifts_attachments_edit',
    Approve = 'approve',
    ShiftsRelease = 'shifts_release',

    ManageTemplatesView = 'shiftstemplates_view',
    ManageTemplatesEdit = 'shiftstemplates_edit'
}
