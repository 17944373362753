import { createSelector } from '@ngrx/store';
import { selectFeature } from '../submittals.selectors';
import { LoadingTypes } from 'src/app/shared/models';

export const selectSubmittalOffer = createSelector(selectFeature, (state) => state.offer);

export const selectSubmittalOfferDetails = createSelector(selectSubmittalOffer, (state) => state.submittalOfferDetails);

export const selectSubmittalOfferDetailsUpdating = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferDetailsUpdating === LoadingTypes.LOADING
);

export const selectSubmittalOfferCreating = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferCreating
);

export const selectSubmittalOfferNotes = createSelector(selectSubmittalOffer, (state) => state.submittalOfferNotes);

export const selectSubmittalOfferNotesLoading = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferNotesLoading === LoadingTypes.LOADING
);

export const selectSubmittalOfferNotesAdding = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferNoteAdding === LoadingTypes.LOADING
);

export const selectSubmittalRescindOfferList = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalRescindOfferList
);

export const selectSelectedRescindOfferDetails = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalSelectedRescindOfferDetails
);

export const selectRescindOfferState = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferRescindSuccessState
);

export const selectSubmittalRescindOfferListLoadingStatus = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalRescindOfferListLoading
);

export const selectSubmittalOfferIsRescinding = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferRescinding === LoadingTypes.LOADING
);

export const selectSubmittalJobPositionsStatus = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalJobPositionsStatus
);

export const selectSubmittalJobPositionsLoadingStatus = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalJobPositionsStatusLoading
);

export const selectAddJobPositionsLoadingStatus = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalAddJobPositionsState.loading
);
