<loading-indicator *ngIf="isLoading"></loading-indicator>

<ng-container *ngIf="error; else noError">
    <div class="error-panel">{{ error }}</div>
</ng-container>

<ng-template #noError>
    <ng-container *ngIf="blob$ | async as blob">
        <iframe [src]="blob | safe:'resourceUrl'" class="pdf-viewer" title="PDF Document"></iframe>
    </ng-container>
</ng-template>
