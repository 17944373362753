import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    FormControl,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ClientRescindOfferReasons, SubmittalRescindOfferRequest } from 'src/app/shared/models/submittals';
import { CreateOfferStepType } from '../create-offer-wizard-dialog/create-offer-wizard-dialog.component';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { Store } from '@ngrx/store';
import {
    selectRescindOfferState,
    selectSelectedRescindOfferDetails,
    selectSubmittalOfferIsRescinding
} from 'src/app/submittals/store/selectors';

import * as submittalsActions from 'src/app/submittals/store/submittals.actions';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { Observable, takeUntil } from 'rxjs';
import { Editor } from 'ngx-editor';

@Component({
    selector: 'ayac-rescind-offer-dialog',
    templateUrl: './rescind-offer-dialog.component.html',
    styleUrls: ['./rescind-offer-dialog.component.scss']
})
export class RescindOfferDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() calledFromTheWizardDialog: boolean;
    @Output() createOfferStep = new EventEmitter<CreateOfferStepType>();
    form: UntypedFormGroup;

    // This is the selected offer to rescind from the previous step of the wizard.
    selectedRescindOfferDetails: { offerId: number; clientSubmittalId: number };

    isLoading$: Observable<boolean>;

    rescindReasons = [
        { id: ClientRescindOfferReasons.BudgetChanged, name: 'Budget Changed' },
        { id: ClientRescindOfferReasons.CensusDropped, name: 'Census Dropped' },
        { id: ClientRescindOfferReasons.FoundPermCandidate, name: 'Found Perm Candidate' },
        { id: ClientRescindOfferReasons.CandidateUnresponsive, name: 'Candidate Unresponsive' },
        { id: ClientRescindOfferReasons.Other, name: 'Other' }
    ];

    noteLength = 0;
    noteItemIsNotValid = false;
    editor!: Editor;
    isVendorClientCommunicationEnabled = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            facilityDirectApplyEnabled: boolean;
        },
        private readonly _dialogRef: MatDialogRef<RescindOfferDialogComponent>,
        private readonly _store: Store,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    get selectedReason(): ClientRescindOfferReasons {
        return this.form?.get('reason')?.value;
    }

    get note(): string {
        return this.form?.get('note')?.value;
    }

    get isOtherSelected(): boolean {
        return this.selectedReason === ClientRescindOfferReasons.Other;
    }

    ngOnInit() {
        this.isLoading$ = this._store.select(selectSubmittalOfferIsRescinding);

        this.ldFeatureManager
            .isEnabled(FeatureFlag.VendorClientCommunicationForApnDirectSubmittals)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.isVendorClientCommunicationEnabled = isEnabled && this.data.facilityDirectApplyEnabled;
                if (this.isVendorClientCommunicationEnabled) {
                    this.editor = new Editor();
                }
            });

        if (this.calledFromTheWizardDialog) {
            this.setupRescindFromWizardFlow();
        }

        this.form = this._formBuilder.group({
            reason: [null, [Validators.required]],
            note: ['']
        });

        this.form.addValidators(this.customNotesValidator());
    }

    private setupRescindFromWizardFlow() {
        this.isLoading$ = this._store.select(selectSubmittalOfferIsRescinding);

        this._store.dispatch(submittalsActions.clearRescindOfferSuccessState());

        this._store
            .select(selectSelectedRescindOfferDetails)
            .pipe(takeUntil(this.d$))
            .subscribe((details) => {
                this.selectedRescindOfferDetails = details;
            });

        this._store
            .select(selectRescindOfferState)
            .pipe(takeUntil(this.d$))
            .subscribe((rescindOfferState) => {
                if (rescindOfferState?.offerId === this.selectedRescindOfferDetails?.offerId) {
                    if (rescindOfferState.wasRescindSuccessful) {
                        this.createOfferStep.emit('createOffer');
                    } else {
                        this._dialogRef.close();
                    }
                }
            });
    }

    customNotesValidator(): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            return this.isOtherSelected && !this.note ? { invalidNote: true } : null;
        };
    }

    rescindOffer() {
        const reasonId = this.form.get('reason').value;
        const notes = this.form.get('note').value;

        if (this.calledFromTheWizardDialog) {
            this.rescindOfferFromWizard(reasonId, notes);
        } else {
            this._dialogRef.close({ reasonId, notes });
        }
    }

    private rescindOfferFromWizard(reasonId: any, notes: any) {
        const request: SubmittalRescindOfferRequest = {
            reasonId,
            notes,
            offerId: this.selectedRescindOfferDetails.offerId
        };

        this._store.dispatch(
            submittalsActions.rescindOffer({
                clientSubmittalId: this.selectedRescindOfferDetails.clientSubmittalId,
                request
            })
        );
    }

    onNoteItemValidationChanged(isValid: boolean) {
        this.noteItemIsNotValid = !isValid;
    }
}
